import { FC } from 'react'
import Select from 'react-select'
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material'
import { selectCustomStyles } from './selectCustomStyles'
import { AssigneeGroupOption } from '~/utils/userGroups'
import BlockIcon from '@mui/icons-material/Block'
import { PatientUser } from '~/legacy/core'
import UserGroupAvatar from './UserGroupAvatar'
import { AccountCircle } from '@mui/icons-material'

interface Props {
  selectedValue: AssigneeGroupOption | null
  options: AssigneeGroupOption[]
  patient?: PatientUser | null
  className?: string
  onChange: (value: AssigneeGroupOption) => void
  disabled?: boolean
  detailed?: boolean
  groupedOptions?: {
    label: string
    options: AssigneeGroupOption[]
  }[]
}

const UserGroupSelect: FC<Props> = props => {
  const formatOptionLabel = (option: AssigneeGroupOption, optionProps?) => {
    return (
      <Box {...optionProps} key={option.value}>
        {props.detailed ? (
          option.value ? (
            <UserGroupAvatar
              id={option.value}
              patientId={props?.patient?.id}
              size="small"
              avatarStyle={{ margin: 0, display: 'inline-flex' }}
            />
          ) : null
        ) : null}
        <Box paddingLeft={1}>{option.label}</Box>
        {option.disabledReason && (
          <Box display="flex" alignItems="flex-start">
            <Box>
              <BlockIcon />
            </Box>
            <Box paddingLeft={1}>{option.disabledReason}</Box>
          </Box>
        )}
      </Box>
    )
  }

  if (props.detailed) {
    return (
      <Autocomplete
        size="small"
        forcePopupIcon={false}
        componentsProps={{
          paper: {
            sx: { width: '150%' },
          },
        }}
        className="classToStopPropagation"
        style={{ width: 180 }}
        disabled={props.disabled}
        getOptionDisabled={(option: AssigneeGroupOption) => !!option.disabledReason}
        options={props.options}
        groupBy={option => option.category}
        selectOnFocus={true}
        renderInput={params => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <TextField
                {...params}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      {props.selectedValue?.value ? (
                        <UserGroupAvatar
                          id={props.selectedValue.value}
                          patientId={props?.patient?.id}
                          size="small"
                          avatarStyle={{ margin: 0, display: 'inline-flex' }}
                        />
                      ) : props.disabled ? null : (
                        <AccountCircle />
                      )}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& fieldset': { border: 'none' },
                  '&:hover': props.disabled
                    ? {}
                    : { backgroundColor: '#f5f5f5', borderRadius: '10px' },
                }}
              />
            </Box>
          )
        }}
        value={props.selectedValue || null}
        renderOption={(props, option) => {
          return formatOptionLabel(option, props)
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            props.onChange(newValue)
          }
        }}
      />
    )
  }

  return (
    <Select
      className={props.className}
      styles={selectCustomStyles}
      isDisabled={props.disabled}
      isOptionDisabled={(option: AssigneeGroupOption) => !!option.disabledReason}
      onChange={newValue => {
        if (newValue) {
          props.onChange(newValue)
        }
      }}
      options={props.groupedOptions}
      value={props.selectedValue}
      placeholder={'Select Assignee'}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export default UserGroupSelect
