import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import * as MUI from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { useSelector } from 'react-redux'

import { usePrevious } from '~/utils/usePrevious'
import { educationSlice } from '~/redux/slices/education'
import { EducationModalSearch } from './EducationModalSearch'
import { EDUCATION_DRAGGABLE_HANDLE, useEducationModal } from './utils'

export const EducationModalHeader: FC = () => {
  const [showSearch, setShowSearch] = useState(false)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const focus = useSelector(educationSlice.selectors.getFocusedId)
  const focusedType = useSelector(educationSlice.selectors.getFocusedType)

  const previousFocus = usePrevious(focus)
  const previousFocusedType = usePrevious(focusedType)
  const { handler, handleClose, loading, page, returnToIndex } = useEducationModal()
  const classes = useStyles()

  const handleCloseSearch = useCallback(() => setShowSearch(false), [])
  const handleOpenSearch = useCallback(() => setShowSearch(true), [])

  // Hide the button whenever new content is fetched
  useEffect(() => {
    if (loading) setShowRefreshButton(false)
  }, [loading])

  // When a new patient/worklist comes into focus, prompt the user
  useEffect(() => {
    if (
      focus &&
      typeof previousFocus !== 'undefined' &&
      (focus !== previousFocus || focusedType !== previousFocusedType)
    ) {
      setShowRefreshButton(true)
    }
  }, [focus, previousFocus, focusedType, previousFocusedType])

  // Close search when navigating back to index
  useEffect(() => {
    if (page === null) handleCloseSearch()
  }, [page])

  return (
    <MUI.Box
      className={classes.container}
      color={MUI.colors.blueGrey[100]}
      bgcolor={MUI.colors.blueGrey[800]}
      display="flex"
      alignItems="center"
    >
      {showSearch ? (
        <MUI.Box pl={2} py={2} flex={1}>
          <EducationModalSearch onClose={handleCloseSearch} />
        </MUI.Box>
      ) : (
        <>
          {page ? (
            <MUI.IconButton edge="end" color="inherit" onClick={returnToIndex}>
              <Icons.ChevronLeft color="inherit" />
            </MUI.IconButton>
          ) : null}
          <MUI.Box flex={1} p={2} id={EDUCATION_DRAGGABLE_HANDLE} className={classes.handle}>
            <MUI.Typography color="inherit" variant="h6">
              Help
            </MUI.Typography>
          </MUI.Box>
        </>
      )}
      <MUI.Fade unmountOnExit mountOnEnter in={showRefreshButton}>
        <MUI.IconButton edge="end" color="inherit" onClick={handler}>
          <Icons.Refresh color="inherit" />
        </MUI.IconButton>
      </MUI.Fade>
      {showSearch ? null : (
        <MUI.IconButton edge="end" color="inherit" onClick={handleOpenSearch}>
          <Icons.Search color="inherit" />
        </MUI.IconButton>
      )}
      <MUI.IconButton color="inherit" onClick={handleClose}>
        <Icons.Close />
      </MUI.IconButton>
    </MUI.Box>
  )
}

const useStyles = MUI.makeStyles(() => ({
  container: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  handle: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}))

export default EducationModalHeader
