import { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import InterfaceHeader from './InterfaceHeader'
import InterfaceNav from './InterfaceNav'
import InterfaceSwitch from './InterfaceSwitch'
import FFHotkeys from '../FFHotkeys/FFHotkeys'
import { BannerMessage } from '../Notifications/BannerMessage'
class Interface extends PureComponent {
  render() {
    const {
      classes,
      location,
      patients,
      search,
      searchClearResults,
      searchPatients,
      synchronizing,
      me,
      providers,
      preferences,
    } = this.props
    return (
      <FFHotkeys>
        <div className={classes.interface}>
          <BannerMessage me={me} />
          <InterfaceHeader
            patients={patients}
            providers={providers}
            location={location}
            search={search}
            searchClearResults={searchClearResults}
            searchPatients={searchPatients}
            synchronizing={synchronizing}
            me={me}
            preferences={preferences}
          />
          <div className={classes.interfaceBottom}>
            <InterfaceNav />
            <InterfaceSwitch />
          </div>
        </div>
      </FFHotkeys>
    )
  }
}

const styles = theme => ({
  interface: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  interfaceBottom: {
    flex: 1,
    display: 'flex',
    overflow: 'auto',
  },
})

export default withRouter(withStyles(styles)(Interface))
