import { makeStyles } from '@material-ui/core/styles'
import EyeIcon from '@material-ui/icons/RemoveRedEye'
import { formatReadAt } from '~/utils/date'

const useStyles = makeStyles(theme => ({
  patientRead: { width: '100%', display: 'flex', alignItems: 'center' },
  patientReadLine: { flex: 1, borderTop: `1px solid ${theme.palette.divider}` },
  patientReadText: {
    margin: '0.5rem',
    color: theme.palette.grey[500],
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  patientReadIcon: {
    color: theme.palette.grey[300],
    height: 18,
  },
}))

const ChatReadReceipt = props => {
  const classes = useStyles()
  return (
    <div className={classes.patientRead}>
      <div className={classes.patientReadLine} />
      <div className={classes.patientReadText}>
        <EyeIcon className={classes.patientReadIcon} />
        {formatReadAt(new Date(props.readAt))}
      </div>
    </div>
  )
}

export default ChatReadReceipt
