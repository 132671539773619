import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import Moment from 'moment'
import { SteerageProvider, Steerage } from './types'

interface MemberSelectedProps {
  steerageProviders: Array<SteerageProvider> | null
  steerage: Steerage | null
}

export const selectedProvider = (steerageProviders): SteerageProvider => {
  return steerageProviders.find(provider => provider?.memberSelectedAt != null)
}
const getProviderName = (provider): string => {
  if (provider?.firstName != null && provider?.lastName != null)
    return provider.firstName + ' ' + provider.lastName
  return provider.careOrganizationName
}

const MemberSelectedView: FC<MemberSelectedProps> = props => {
  const steerage = props?.steerage
  const [memberSelectedProvider, setMemberSelectedProvider] = useState<SteerageProvider | null>()

  useEffect(() => {
    if (props?.steerageProviders)
      setMemberSelectedProvider(selectedProvider(props?.steerageProviders))
  }, [props?.steerageProviders])

  return (
    <Box color="text.primary">
      <Grid container spacing={2}>
        <Grid item>
          <Typography sx={{ fontSize: '1.2rem', mt: 1 }}>
            Sent to member on{' '}
            {steerage?.lockedAt && Moment(steerage?.lockedAt).format('MMM DD, YYYY')}
          </Typography>
        </Grid>
        {memberSelectedProvider && (
          <Grid item mt={1}>
            {'·'}
          </Grid>
        )}
        {memberSelectedProvider && (
          <Grid item>
            <Typography sx={{ fontSize: '1.2rem', mt: 1 }}>
              Member selected <strong>{getProviderName(memberSelectedProvider)}</strong> on{' '}
              {memberSelectedProvider.memberSelectedAt &&
                Moment(memberSelectedProvider.memberSelectedAt).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default MemberSelectedView
