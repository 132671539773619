import {
  createEntityAdapter,
  combineReducers,
  createSelector,
  configureStore,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit'
import { createContext } from 'react'
import {
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
  TypedUseSelectorHook,
} from 'react-redux'
import { CrudResource } from '~/legacy/core'
import { apiClient } from '../../../api/rest'

import { crudSlice } from '../crud'
import workUnitsSlice from '../workunits'
import worklistPreferencesSlice from '../worklistpreferences'
import { getIsWorklistWithWorkUnits, getItemIsClosed } from '~/models/Worklist'
import { WorklistWorkUnit } from '~/models/PatientTodoItem'

const worklistResource = new CrudResource<Worklist>(apiClient.rest, '/worklists/')
export type WorklistDataSource = 'WORK_UNIT_API' | null

export interface Worklist {
  id: number
  dataSource?: WorklistDataSource
  description: string
  active: boolean
  inactiveReason: boolean
  lastSyncDate?: Date | undefined
  category: string
  subCategory: string
  name: string | null
  itemCount: number
  showNextActionDate: boolean
}

const worklistEntityAdapter = createEntityAdapter<Worklist>()
const { getSelectors } = worklistEntityAdapter
const worklistsSlice = Object.assign(
  crudSlice<Worklist>('worklists', worklistResource, {
    extraReducers: {
      // This reducer gets invoked first and then the patchWorkUnitSuccess reducer
      [workUnitsSlice.actions.patchWorkUnitSuccess.toString()]: (
        state,
        { payload: workUnitPayload }: PayloadAction<{ workUnit: WorklistWorkUnit }>
      ) => {
        if (getItemIsClosed(workUnitPayload.workUnit)) {
          state.byId[workUnitPayload.workUnit.worklist].itemCount--
        }
      },
      [workUnitsSlice.actions.getWorkUnitsSuccess.toString()]: (
        state,
        {
          payload: workUnitPayload,
        }: PayloadAction<{
          worklistId: string
          items: WorklistWorkUnit[]
          count: number
        }>
      ) => {
        const worklist: Worklist | undefined = state.byId[workUnitPayload.worklistId]
        if (worklist && !getIsWorklistWithWorkUnits(worklist)) {
          // Update the item count for Work Unit worklists because the Worklist object itself
          // does not keep this count. The items are fetched dynamically.
          worklist.itemCount = workUnitPayload.count
        }
      },
    },
  }),
  {
    selectors: getSelectors,
  }
)

export default worklistsSlice

const combinedReducers = combineReducers({
  worklists: worklistsSlice.reducer,
  workUnits: workUnitsSlice.reducer,
  worklistPreferences: worklistPreferencesSlice.reducer,
})
export const worklistContext = createContext({} as ReactReduxContextValue<any, AnyAction>)
export const useWorklistDispatch = createDispatchHook(worklistContext)
export const useWorklistSelector = createSelectorHook(worklistContext)

export const worklistStore = configureStore({
  reducer: combinedReducers,
  devTools: { name: 'Lucian/Worklists' },
})
type WorklistState = ReturnType<typeof worklistStore.getState>
export const useTypedWorklistSelector: TypedUseSelectorHook<WorklistState> = useWorklistSelector

export const getWorklists = createSelector(
  (state: WorklistState) => state.worklists,
  worklists => (worklists?.loadedAll ? worklists.byId : null)
)
