import { Box, Grid, Typography, IconButton, Button, TextField } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { apiClient } from '~/api/rest'
import { useMutation } from '~/utils/useMutation'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import patientsSlice from '~/redux/slices/patients'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'
import { Autocomplete } from '@material-ui/lab'

interface EditMedicalEquipmentProps {
  patient: any
}

interface EquipmentItem {
  name: string
  type: string
}

const newEquipmentItem = (): EquipmentItem => {
  return {
    name: '',
    type: '',
  }
}

// TODO: these might eventually come from the backend
// or we could have something like an NDC selection
const TYPE_CHOICES = {
  bp_cuff_or_monitor: 'BP Cuff/Monitor',
  glucometer: 'Glucometer',
  weight_scale: 'Weight Scale',
  spirometer: 'Spirometer',
  fertility_tracker: 'Fertility Tracker',
  sleep_study: 'Sleep Study',
  activity_tracker: 'Activity Tracker',
  ekg: 'EKG',
  ekg_and_stethoscope: 'EKG & Stethoscope',
  pulse_oximeter: 'Pulse Oximeter',
  thermometer: 'Thermometer',
  peak_flow_meter: 'Peak Flow Meter',
  other: 'Other',
}

const NAME_CHOICES = {
  ihealth: 'iHealth',
  other: 'Other',
}

const BP_CUFF_TYPES = {
  cellular: 'Cellular',
  nonCellular: 'Non-Cellular',
  selfSupply: 'Self Supply',
  ihealth: 'iHealth',
  other: 'Other',
}

const equipmentItemsArrayToObject = (
  equipmentArray: EquipmentItem[]
): { [key: number]: EquipmentItem } => {
  const newEquipment = {}
  equipmentArray.forEach((equipmentItem, i) => {
    newEquipment[i] = equipmentItem
  })

  return newEquipment
}

interface EditableEquipmentData {
  [key: number]: EquipmentItem | null
}

const isSaveable = (obj: EquipmentItem | null): boolean => obj != null && !!obj.name && !!obj.type

const EditMedicalEquipment = (props: EditMedicalEquipmentProps) => {
  const [equipmentData, setEquipmentData] = useState<EditableEquipmentData>({})
  const dispatch = useDispatch()

  const { handler: submit, loading: loadingSubmit } = useMutation(
    async (equipmentData: EditableEquipmentData) => {
      try {
        const medicalEquipmentData = Object.values(equipmentData).filter(isSaveable)
        const result = await apiClient.rest.patch(`/user/${props.patient.id}/`, {
          person: {
            medicalEquipmentData,
          },
        })
        dispatch(patientsSlice.actions.getPatientSuccess(result))
        dispatch(queueNotification({ variant: 'success', message: 'Updated medical equipment' }))
        return result
      } catch (e) {
        return dispatch(
          queueNotification({ variant: 'error', message: 'Failed to update medical equipment' })
        )
      }
    }
  )

  useEffect(() => {
    const medicalEquipmentData: EquipmentItem[] | null = props.patient.person.medicalEquipmentData
    if (!medicalEquipmentData) {
      return setEquipmentData({})
    }
    return setEquipmentData(equipmentItemsArrayToObject(medicalEquipmentData))
  }, [props.patient.person.medicalEquipmentData])

  const handleNameChange = (itemId: string, value: string) => {
    const currentItem = equipmentData[itemId]
    setEquipmentData({ ...equipmentData, [itemId]: { ...currentItem, name: value } })
  }

  const handleTypeChange = (itemId: string, value: string) => {
    const currentItem = equipmentData[itemId]
    setEquipmentData({ ...equipmentData, [itemId]: { ...currentItem, type: value } })
  }

  const addNewItem = () => {
    const keys = Object.keys(equipmentData)
    const lastKey = keys[keys.length - 1]
    let newId = 0
    if (lastKey != null) {
      newId = parseInt(lastKey) + 1
    }
    setEquipmentData({ ...equipmentData, [newId]: newEquipmentItem() })
  }

  const removeItem = itemId => {
    setEquipmentData({ ...equipmentData, [itemId]: null })
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" color="inherit">
          Medical Equipment
        </Typography>
      </Box>
      <Box mt={1}>
        {Object.keys(equipmentData).length === 0 && (
          <Box mb={1}>
            <Typography>No equipment added yet</Typography>
          </Box>
        )}
        {Object.keys(equipmentData).map(key => {
          if (equipmentData[key] == null) return null
          return (
            <Box key={key} mb={1}>
              <Grid container spacing={2}>
                <Grid item container xs={11} spacing={2}>
                  <Grid item xs={6}>
                    <Autocomplete
                      autoHighlight
                      value={equipmentData[key].type}
                      onChange={(_, value) => handleTypeChange(key, value)}
                      options={Object.keys(TYPE_CHOICES)}
                      getOptionLabel={option => TYPE_CHOICES[option] || ''}
                      renderInput={params => (
                        <TextField {...params} label="Type" variant="outlined" />
                      )}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      autoHighlight
                      value={equipmentData[key].name}
                      onChange={(_, value) => handleNameChange(key, value)}
                      options={
                        equipmentData[key].type === 'bp_cuff_or_monitor'
                          ? Object.keys(BP_CUFF_TYPES)
                          : Object.keys(NAME_CHOICES)
                      }
                      getOptionLabel={
                        equipmentData[key].type === 'bp_cuff_or_monitor'
                          ? option => BP_CUFF_TYPES[option] || ''
                          : option => NAME_CHOICES[option] || ''
                      }
                      renderInput={params => (
                        <TextField {...params} label="Name" variant="outlined" />
                      )}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <IconButton color="primary" onClick={() => removeItem(key)} title="Remove item">
                    <ClearIcon color="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          )
        })}
        <Grid container spacing={2}>
          <Grid item>
            <IconButton color="primary" onClick={addNewItem} title="Add new item">
              <AddIcon color="inherit" />
            </IconButton>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            onClick={() => submit(equipmentData)}
            disabled={loadingSubmit}
          >
            Save Equipment
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default EditMedicalEquipment
