import { Box, Typography } from '@mui/material'
import ProgramStatusStepper from './ProgramStatusStepper'
import { PROGRAM_STATUS } from '~/constants/programEnrollment'

const ProgramDetail = ({ program, enrollments, patient }) => {
  return (
    <Box mt={3}>
      <Typography variant="body1">{program.displayName}</Typography>
      <Box mt={1}>
        <ProgramStatusStepper
          program={program}
          statuses={PROGRAM_STATUS[program.uid]}
          enrollments={enrollments}
          patient={patient}
        />
      </Box>
    </Box>
  )
}

export default ProgramDetail
