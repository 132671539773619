import { ExpandMore, Visibility, VisibilityOff } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ICarePlan } from '~/models/CarePlan'
import { getUserById, getUserName } from '~/utils'
import Moment from 'moment'
import * as CarePlanService from '~/api/CarePlanService'
import { purple, yellow } from '@mui/material/colors'
import { DataTracking } from './CaseBasedModal/CarePlanDataTracking'

const useStyles = makeStyles()(theme => {
  return {
    contentTitle: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      display: 'inline-flex',
      cursor: 'pointer',
    },
    contentMeta: {
      marginTop: '0.3rem',
      fontSize: '1.2rem',
    },
    containerAction: {
      padding: theme.spacing(0.5),
    },
  }
})

const NewCarePlanHeader: FC<{
  carePlan: ICarePlan
  providers: any
  openModal: any
  setModalCarePlanAction: any
  onCareplanCreateOrUpdate: any
  dispatchNotification: any
  categorizedMeasureCollection: any
}> = props => {
  const {
    carePlan,
    providers,
    openModal,
    setModalCarePlanAction,
    onCareplanCreateOrUpdate,
    dispatchNotification,
    categorizedMeasureCollection,
  } = props

  const { classes } = useStyles()

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  useEffect(() => {}, [])

  const renderHistory = () => {
    if (carePlan.isCaseBasedCarePlan) return 'Updated Nightly'
    /**
     * Shows a different message depending on whether it's been updated.
     */
    const provider = getUserById(providers, carePlan.updatedBy) || null
    const createdAt = Moment(carePlan.createdAt).format('MMM. D, YYYY')
    const updatedAt = Moment(carePlan.updatedAt).format('MMM. D, YYYY')
    let content = `Created ${createdAt}`
    if (provider !== null) content += ` · Last edited ${updatedAt} by ${getUserName(provider)}`
    return content
  }

  const handleOpenCareplan = (_, editClick = false) => {
    setAnchorEl(null)
    openModal(carePlan, editClick)
  }

  const onCarePlanActionChange = async action => {
    setIsUpdating(true)
    try {
      const updatedCarePlan = await CarePlanService.updateCarePlanAction({
        id: parseInt(carePlan.id.toString()),
        action: action,
        tasks: carePlan.tasks,
        cases: carePlan.cases,
        patient: carePlan.patient,
      })
      onCareplanCreateOrUpdate(updatedCarePlan)
    } catch (__) {
      dispatchNotification({
        variant: 'error',
        message: 'Something went wrong. Please try again',
      })
      const updatedCarePlan = await CarePlanService.getCarePlan(carePlan.id)
      onCareplanCreateOrUpdate(updatedCarePlan)
    }
    setIsUpdating(false)
  }

  const handleActionChange = action => {
    const destCategory = carePlan.statesWithCategories?.find(
      stateWithCategory => stateWithCategory.state.name === action.dest
    )
    // When a care plan is marked complete, then all the pending tasks gets deleted
    // and all the pending cases will get delinked from the care plan.
    // So before completing a care plan make sure that there are no pending tasks or cases.
    // If there are pending tasks or cases, then show the list to the user.
    // Only when the user acknowledge it, then mark the care plan as complete.
    if (
      destCategory &&
      (destCategory.category === 'complete' || destCategory.category === 'deferred') &&
      (carePlan.cases.length > 0 || carePlan.tasks.filter(task => !task.isComplete).length > 0)
    ) {
      setModalCarePlanAction({
        carePlan: carePlan,
        action: action.trigger,
        onCarePlanActionChange: onCarePlanActionChange,
      })
    } else {
      onCarePlanActionChange(action.trigger)
    }
  }

  return (
    <AccordionSummary
      aria-label="Expand"
      aria-controls={`categorized-todos-${carePlan.title}-content`}
      id={`categorized-todos-${carePlan.title}-header`}
      style={{ flexGrow: 1 }}
      expandIcon={<ExpandMore />}
      sx={{
        flexDirection: 'row-reverse',
        '.MuiAccordionSummary-expandIconWrapper': {
          transform: 'rotate(-90deg)',
          color: 'grey.400',
        },
        '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(0deg)',
          color: 'grey.400',
        },
      }}
    >
      <Box
        style={{ flexGrow: 1, display: 'flex' }}
        onClick={e => {
          const titleClick = (e.target as HTMLInputElement).classList.value
            .split(' ')
            .filter(cls => cls.includes('contentTitle') || cls.includes('contentMeta'))
          if (titleClick && titleClick.length == 0) {
            e.stopPropagation()
            e.preventDefault()
          }
        }}
      >
        <Box style={{ width: '100%' }}>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography className={classes.contentTitle} style={{ marginLeft: 1 }}>
              {carePlan.title}
            </Typography>
            {carePlan.isCaseBasedCarePlan ? (
              <Box
                sx={{
                  alignSelf: 'center',
                  display: 'inline-flex',
                  flexDirection: 'row',
                  marginLeft: 1,
                  backgroundColor: carePlan.status == 'draft' ? purple[50] : yellow[100],
                  paddingLeft: 1,
                  paddingRight: 1,
                  paddingTop: 0.2,
                  paddingBottom: 0.2,
                }}
              >
                {carePlan.status == 'active' ? (
                  <>
                    <Visibility style={{ fontSize: '1.8rem', marginTop: '0.5' }} />
                    <Typography sx={{ fontSize: '1.4rem', ml: 0.5 }}>{'Member-Facing'}</Typography>
                  </>
                ) : (
                  <>
                    <VisibilityOff style={{ fontSize: '1.8rem', marginTop: '0.5' }} />
                    <Typography sx={{ fontSize: '1.4rem', ml: 0.5 }}>
                      {'Not visible to member'}
                    </Typography>
                  </>
                )}
              </Box>
            ) : null}
          </Box>
          <Typography className={`contentMeta ${classes.contentMeta}`}>
            {renderHistory()}
          </Typography>
        </Box>
        {carePlan.isCaseBasedCarePlan &&
        carePlan.status == 'draft' &&
        Object.keys(categorizedMeasureCollection).length > 0 &&
        (carePlan.carePlanDataTracking == null ||
          carePlan.carePlanDataTracking !== DataTracking.MANUAL) ? (
          <Button
            onClick={handleOpenCareplan}
            variant="contained"
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '25%',
              marginRight: 2,
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>Review and Send</Typography>
          </Button>
        ) : null}
        <Box style={{ flexShrink: 0 }}>
          {!carePlan.isCaseBasedCarePlan ||
          (carePlan.carePlanDataTracking !== null &&
            Object.keys(categorizedMeasureCollection).length > 0) ? (
            <IconButton
              classes={{ root: classes.containerAction }}
              onClick={e => {
                setAnchorEl(e.currentTarget)
              }}
            >
              <MoreVertIcon />
            </IconButton>
          ) : null}
          <Menu
            MenuListProps={{
              disablePadding: true,
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={e => {
              setAnchorEl(null)
            }}
          >
            {carePlan.carePlanDataTracking == 'Automatic' && carePlan.status == 'active' ? (
              <>
                <MenuItem
                  onClick={e => {
                    handleOpenCareplan(e, true)
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem onClick={handleOpenCareplan}>Hide from member</MenuItem>
              </>
            ) : null}
            {carePlan.carePlanDataTracking == 'Manual' ? (
              <MenuItem onClick={handleOpenCareplan}>Make visible to member</MenuItem>
            ) : null}
            {!carePlan.isCaseBasedCarePlan ? (
              <>
                <MenuItem onClick={handleOpenCareplan}>Edit</MenuItem>
                <MenuItem
                  onClick={e => {
                    var actionDetails = { active: 'inactive', inactive: 'active' }
                    var action = {
                      dest: actionDetails[carePlan.status],
                      source: carePlan.status,
                      trigger: actionDetails[carePlan.status],
                    }
                    handleActionChange(action)
                  }}
                  disabled={isUpdating}
                  value={carePlan.status}
                >
                  {carePlan.status === `active` ? `Mark inactive` : `Mark active`}
                </MenuItem>
              </>
            ) : null}
          </Menu>
        </Box>
      </Box>
    </AccordionSummary>
  )
}

export default NewCarePlanHeader
