import { FC, useState } from 'react'
import Loader from '~/components/Loader'

import { Button, Paper, Box, Typography } from '@mui/material'
import PHIAccessFormModal from './PHIAccessFormModal'
import usePHIAccessCheck from '~/utils/usePHIAccessCheck'

interface Props {
  children: any
  userId: number | null
}

// Wraps a component behind a gate which first checks if PHI permissions are required
// If PHI permissions are required and not granted, do not render the child component(s)
const PHIAccessBlock: FC<Props> = props => {
  const { userId } = props
  const [declinedAccess, setDeclinedAccess] = useState<boolean>(false)
  const { pHIAccessQuery, grantPHIAccessMutation } = usePHIAccessCheck(userId)

  // If caller does not pass a user, default to showing the child components
  // This is the case for Worklists, where some Worklist Items have a User and some do not
  if (!userId) return <>{props.children}</>

  if (pHIAccessQuery.isLoading || pHIAccessQuery.isFetching) {
    return <Loader />
  }

  // Two ways we can know if the user has access:
  // 1) the initial check for access comes back positive
  // 2) the initial check is negative, but a request to grant access comes back positive
  const hasAccess = pHIAccessQuery.data?.access || grantPHIAccessMutation.result?.access

  if (hasAccess) {
    return <>{props.children}</>
  }

  if (!declinedAccess) {
    return (
      <PHIAccessFormModal
        cancel={() => setDeclinedAccess(true)}
        request={reason => grantPHIAccessMutation.handler(reason)}
        loading={grantPHIAccessMutation.loading}
      />
    )
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography>PHI access declined (user ID {userId})</Typography>
        <Box>
          <Button onClick={() => setDeclinedAccess(false)} variant="contained">
            Request Access
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default PHIAccessBlock
