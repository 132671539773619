import RestClient from '../rest'

/**
 * Provides a convenient wrapper around a RESTful resource.
 *  @deprecated Use `@fireflyhealth/api` instead.
 */
export class CrudResource<T> {
  resourcePath: string
  rest: RestClient

  constructor(rest: RestClient, resourcePath: string) {
    this.resourcePath = resourcePath
    this.rest = rest
  }

  getAll(params?: any): Promise<T[]> {
    return this.rest.get<T[]>(this.resourcePath, params)
  }

  get(id: string | number): Promise<T> {
    return this.rest.get<T>(`${this.resourcePath}${id}/`)
  }

  create(data: Omit<T, 'id' | 'createdAt' | 'updatedAt'>): Promise<T> {
    return this.rest.post<T>(this.resourcePath, data)
  }

  update(id: string | number, data: Partial<T>): Promise<T> {
    return this.rest.patch<T>(`${this.resourcePath}${id}/`, data)
  }

  delete(id: string | number): Promise<any> {
    return this.rest.delete<T>(`${this.resourcePath}${id}/`)
  }
}
