import { type FC } from 'react'
import { PatientUser } from '~/legacy/core'
import { Box, Typography } from '@mui/material'
import { usePersonSegmentation } from '~/api/UserSegmentationService'
import Loader from '../Loader'
import { format } from 'date-fns'

interface PatientSegmentationInfoProps {
  patient: PatientUser
}

export const PatientSegmentationInfo: FC<PatientSegmentationInfoProps> = props => {
  const { result: personSegmentation, isLoading: isLoadingGetPersonSegmentation } =
    usePersonSegmentation(props.patient.person.id)
  if (isLoadingGetPersonSegmentation) return <Loader />
  return (
    <Box maxHeight={800} overflow="auto" data-testid="patientDetailPopover">
      <Box py={2}>
        <Box mx={2}>
          <Typography mb={2}>Onboarding</Typography>
          <Typography mb={2} variant="caption">
            <strong>Completed At: </strong>
            {personSegmentation?.completedAt &&
              format(personSegmentation?.completedAt, 'MM-DD-YYYY')}
            <br />
          </Typography>
          {personSegmentation &&
            personSegmentation?.userSegmentationResponse.map((value, i) => (
              <Box mt={1} key={i} flex={1}>
                <Typography variant="caption" flex={1} flexDirection="column" display="flex">
                  <strong>{value['question']} </strong>
                </Typography>
                <Typography variant="caption" flex={1} pl={3} flexDirection="column" display="flex">
                  {`\u2022 ${value['answer']}`}
                </Typography>
              </Box>
            ))}
        </Box>
        <Box p={2}></Box>
      </Box>
    </Box>
  )
}
