import type { FC } from 'react'
import { Box } from '@material-ui/core'
import { Question } from '~/legacy/core'
import Markdown from 'markdown-to-jsx'

interface FormTypeOptionsProps {
  question: Question
}

const FormTypeOptions: FC<FormTypeOptionsProps> = props => {
  const { question } = props

  if (question.type === 'choice' || question.type === 'multiple_choice') {
    return (
      <>
        {question.typeOptions.choices.map(choice => (
          <Box my={1} key={choice.value}>
            <div>{choice.label}</div>
            {choice.subtext ? <Box mx={1}>{choice.subtext}</Box> : null}
          </Box>
        ))}
      </>
    )
  }

  if (question.type === 'info' || question.type === 'info_button') {
    return (
      <>
        {'content' in question.typeOptions ? (
          <Markdown>{question.typeOptions.content ?? ''}</Markdown>
        ) : null}
        {'buttons' in question.typeOptions ? (
          <Box>
            <b>Buttons:</b> {question.typeOptions.buttons.map(button => button.label).join(', ')}{' '}
          </Box>
        ) : null}
      </>
    )
  }

  if (question.type === 'text' || question.type === 'long_text') {
    return (
      <>
        {'placeholder' in question.typeOptions ? (
          <Box my={1} mx={1}>
            <b>Placeholder: </b> {question.typeOptions.placeholder}
          </Box>
        ) : null}
      </>
    )
  }

  if (question.type === 'name') {
    return (
      <Box my={1} mx={1}>
        <b>Fields: </b> {question.typeOptions.fields.map(field => field.label).join(', ')}
      </Box>
    )
  }
  return null
}

export default FormTypeOptions
