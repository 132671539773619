import { useEffect, useState, useCallback } from 'react'
import { LimitOffsetPagination } from '~/legacy/core'

type SortOrder = { key: string; direction: 'asc' | 'desc' }

interface PaginationHook<T> {
  page: number
  pageCount: number
  order: SortOrder
  setPage: (page: number) => void
  setOrder: (order: SortOrder) => void
  sortHandler: (key: string) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  data: T[] | null
  loading: boolean
}

interface PaginationOptions {
  defaultOrder: SortOrder
  limit?: number
}

export const usePagination = <T>(
  resourceGetter: (options: any) => Promise<LimitOffsetPagination<T>>,
  paginationOptions: PaginationOptions
): PaginationHook<T> => {
  const limit = paginationOptions.limit || 50

  const [page, setPage] = useState(1)
  const [order, setOrder] = useState(paginationOptions.defaultOrder)
  const [data, setData] = useState<T[] | null>(null)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)

  useEffect(() => {
    const fetchData = async options => {
      setLoading(true)
      const data = await resourceGetter({ ...options, limit })
      setData(data.results)
      setCount(data.count)
      setLoading(false)
    }

    fetchData({ page, order })
  }, [resourceGetter, page, order])

  // Sort handler is usable as a Material UI table header callback.
  const sortHandler = useCallback(
    key => () => {
      if (key === order.key) {
        setOrder({ ...order, direction: order.direction === 'desc' ? 'asc' : 'desc' })
      } else {
        setOrder({ key, direction: 'asc' })
      }
    },
    [order]
  )

  return {
    page,
    pageCount: Math.ceil(count / limit),
    order,
    setPage,
    setOrder,
    sortHandler,
    data,
    loading,
  }
}
