import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'
import { useQuery } from '~/components/Providers/ApiProvider'
import { apiClient } from '~/api/rest'
import { useQueryClient } from 'react-query'

const onboardingResource = apiClient.resource<{ status: string }>('/onboarding/')

const loadOnboardingState = patientId => {
  return onboardingResource.get(patientId)
}

const loadEnrolledPrograms = patientId => {
  return apiClient.rest.get<string[]>(`/programs/enrollment/?patient_id=${patientId}`)
}

export interface PatientDetailState {
  onboardingState: any
  enrolledPrograms: string[]
  id: string | null
  isLoaded: boolean
  setState: Dispatch<SetStateAction<PatientDetailState>>
  refetch: () => Promise<void>
}

export const INITIAL_STATE: PatientDetailState = {
  onboardingState: {},
  enrolledPrograms: [],
  id: null,
  isLoaded: false,
  setState: () => null,
  refetch: () => Promise.resolve(),
}

export const PatientDetailContext = createContext<PatientDetailState>(INITIAL_STATE)

const useLoadPatientDetails = patientId =>
  useQuery(
    ['loadPatientDetail', patientId],
    () => Promise.all([loadEnrolledPrograms(patientId), loadOnboardingState(patientId)]),
    { enabled: !!patientId },
    { error: `Failed to load details for patient ${patientId}` }
  )

export const PatientDetailContextProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE)
  const { data, isFetched } = useLoadPatientDetails(state.id)
  const client = useQueryClient()
  const refetch = () => client.cancelQueries(['loadPatientDetail', state.id])

  useEffect(() => {
    if (!data) return
    const [enrolledPrograms, onboardingState] = data
    setState(state => ({ ...state, enrolledPrograms, onboardingState }))
  }, [data])

  return (
    <PatientDetailContext.Provider
      value={{
        enrolledPrograms: state.enrolledPrograms,
        onboardingState: state.onboardingState,
        isLoaded: !isFetched,
        id: state.id,
        setState,
        refetch,
      }}
    >
      {children}
    </PatientDetailContext.Provider>
  )
}
