import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import orderBy from 'lodash/orderBy'
import { PatientTodo } from '~/models/PatientTodoItem'
import { ICase } from '~/models/Case'
import { Fragment, ReactElement, RefObject, useContext } from 'react'
import { usePersonTodos } from '~/api/PatientToDoService'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material'

import { Add as AddIcon } from '@mui/icons-material'
import { CaseForm } from '../Cases/CaseEditor'
import FFModal from '../FFModal/FFModal'
import Loader from '../Loader'
import type { PatientUser } from '~/types'
import ToDoNotes from './ToDoNotes'
import ToDoTasks from './ToDoTasks'
import { Worklist } from '~/redux/slices/worklists'
import { buildRelatedComponentForModel } from '~/components/Generic/Model'
import { makeStyles } from 'tss-react/mui'
import qs from 'query-string'
import { useHistory } from 'react-router'
import { useWorkLists } from '~/api/WorkListsService'
import { workunitCompleteStates } from '~/models/Case'
import { queueNotification } from '~/redux/actions/notifications'
import {
  useAddCase,
  useCaseCategories,
  useCaseCategoriesById,
  useTaskCollectionPreviewMutation,
} from '~/api/CaseService'
import CategoryHeader from './CategoryHeader'
import CaseCategorySelect from '../Cases/CaseCategorySelect'
import { ITask } from '~/models/Task'
import { format } from 'date-fns'
import { ToDoItem } from './ToDoItem'
import { GREY } from '~/utils/theme'
import { useMutation } from '~/utils/useMutation'
import { apiClient } from '~/api/rest'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIconOutlined from '@mui/icons-material/ThumbUpOutlined'
import ThumbDownIconOutlined from '@mui/icons-material/ThumbDownOutlined'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'
import { logger } from '~/utils/logger'

const MAX_CASE_SUMMARY_CONTENT_LENGTH = 400

const ToDoItems = (props: {
  patient: PatientUser
  scrollRef?: RefObject<HTMLDivElement>
  scrollEventTarget?: EventTarget
}) => {
  const { patient, scrollRef, scrollEventTarget } = props

  const me = useSelector<any, any>(state => state.me)

  const { result: categories, isLoading: isListingCaseCategories } = useCaseCategories()

  const [addCaseClick, setAddCaseClick] = useState<boolean>(false)
  const { setSelectedCaseId } = useContext(SelectedTodoContext)

  const {
    data: patientTodos,
    tab,
    setTab,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    fetchPreviousPage,
    goTo,
    isLoading: isLoadingTodos,
    isFetchingNextPage,
    isFetchingPreviousPage,
    resetFilters,
  } = usePersonTodos(patient.person.id)

  const { result: workLists, isLoading: isLoadingWorklists } = useWorkLists()

  const { result: caseCategoriesById } = useCaseCategoriesById()

  var carePlanCases: PatientTodo[] = []

  for (const todo of patientTodos) {
    if (
      todo.contentType == 'careplan' &&
      todo.contentObject?.cases &&
      todo.contentObject?.cases?.length > 0
    ) {
      for (const careCase of todo.contentObject?.cases) {
        carePlanCases.push(careCase)
      }
    }
  }

  if (carePlanCases) patientTodos.push(...carePlanCases)

  const parentCategorizedPatientTodos = patientTodos.reduce((acc, patientTodo) => {
    let key
    if (patientTodo.contentType == 'careplan') {
      key = 'Care Plan'
    } else if (patientTodo.contentType == 'case' && patientTodo.contentObject) {
      if (patientTodo.contentObject?.category) {
        key = caseCategoriesById[patientTodo.contentObject?.category]?.parent
        if (!key) {
          key = 'Others'
        }
      }
    } else if (patientTodo.contentType == 'worklist' && workLists) {
      const worklist = workLists.find((workList: Worklist) => workList.id === patientTodo.objectId)
      key = worklist.subCategory
    }
    if (key) {
      acc[key] ||= []
      acc[key].push(patientTodo)
    }

    return acc
  }, {})

  // Alphabetical sorting of parent categories
  const sortedParentCategories = Object.keys(parentCategorizedPatientTodos)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = parentCategorizedPatientTodos[key]

      return accumulator
    }, {})

  const { isLoading: isAddingPatientCase, mutateAsync: handleAddPatientCase } = useAddCase()

  const isLoading =
    isLoadingTodos || isLoadingWorklists || isListingCaseCategories || isAddingPatientCase

  const history = useHistory()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [disableEdit, setDisableEdit] = useState<boolean>(false)

  // clear history on tab change
  const clearHistory = () => {
    const existingQS = qs.parse(location.search)
    delete existingQS['caseId']
    delete existingQS['careplanId']
    history.push({
      search: qs.stringify(existingQS),
    })
  }

  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        setAddCaseClick(false)
      }
    },
    [setAddCaseClick]
  )

  const taskCollectionPreviewMutation = useTaskCollectionPreviewMutation()
  const dueDate = format(new Date(), 'YYYY-MM-DD')

  const handleAddCase = async (category: typeof categories[0]) => {
    if (category) {
      var tasks: ITask[] = []
      if (category?.taskCollectionId) {
        const taskData = await taskCollectionPreviewMutation.mutateAsync({
          caseCategoryId: category.id,
          userId: patient.id,
          me: me,
          meAssigneeGroup: me.assigneeGroup,
        })

        if (taskData) tasks = taskData.taskPreviews
      }

      const addedCase = await handleAddPatientCase({
        user: patient.id,
        person: patient.person.id,
        category: category.id,
        tasks: tasks,
        relations: [],
        description: category?.description || '',
        notes: category?.notes || '',
        dueDate: dueDate != '' ? dueDate : undefined,
        isProposed: undefined,
        createdBy: '',
      })

      handleOnAfterAddCase(addedCase)
    }
  }

  // If we are highlighting a case, make sure the selected tab reflects where the case resides.
  const { selectedCaseId, selectedCarePlanId } = useContext(SelectedTodoContext)
  // expand the case if CaseId is present in the params
  const expandCase = selectedCaseId ? true : false
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    if (!selectedCaseId && !selectedCarePlanId) {
      resetFilters()
      return
    }

    if (selectedCarePlanId) {
      goTo({ contentType: 'careplan', objectId: selectedCarePlanId })
    } else if (selectedCaseId) {
      goTo({ contentType: 'case', objectId: selectedCaseId })
    }

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [selectedCaseId, selectedCarePlanId, goTo, escFunction])

  let selectedWorklist: Worklist | undefined

  const parentCategoryHTML: ReactElement[] = []
  const expandParentCategory: string[] = []
  const toDosHTML: Record<string, ReactElement[]> = {}
  const countPatientTodo: Record<string, number> = {}

  for (const [key, value] of Object.entries<PatientTodo[]>(sortedParentCategories)) {
    countPatientTodo[key] = 0
    for (const patientTodo of value) {
      countPatientTodo[key] = countPatientTodo[key] + 1
      const contentType = patientTodo.contentType
      const parentId = patientTodo.objectId
      const isClosed =
        patientTodo.contentType === 'case' &&
        workunitCompleteStates.includes(patientTodo.contentObject?.statusCategory)
      if (contentType === 'worklist' && workLists) {
        selectedWorklist = workLists.find((workList: Worklist) => workList.id === parentId)
      }

      if (
        (selectedCarePlanId && selectedCarePlanId === parentId) ||
        (selectedCaseId && selectedCaseId === parentId)
      )
        expandParentCategory.push(key)
      toDosHTML[key] ||= []
      toDosHTML[key].push(
        <ToDoItem
          parentId={parentId}
          todoContent={patientTodo}
          patient={patient}
          selectedWorklist={selectedWorklist}
          expandCase={expandCase ?? false}
          isClosed={isClosed ?? false}
          disableEdit={disableEdit ?? false}
          parentCategory={key}
          setDisableEdit={setDisableEdit}
          contentType={contentType}
        />
      )

      if (
        contentType === 'careplan' &&
        patientTodo.contentObject?.cases &&
        patientTodo.contentObject?.cases?.length > 0
      ) {
        for (const carePlanCasesTodo of patientTodo.contentObject?.cases) {
          const carePlanCaseParentId = carePlanCasesTodo.objectId

          if (selectedCaseId && selectedCaseId === carePlanCaseParentId)
            expandParentCategory.push(key)
        }
      }
    }
  }

  const [accordionState, _setAccordionState] = useState<Record<string, boolean>>(
    expandParentCategory.reduce((acc, key) => ({ ...acc, [key]: true }), {})
  )
  const setAccordionState = (key: string, value: boolean) =>
    _setAccordionState({ ...accordionState, [key]: value })

  useEffect(() => {
    _setAccordionState(
      expandParentCategory.reduce((acc, key) => ({ ...acc, [key]: true }), accordionState)
    )
  }, [selectedCarePlanId, selectedCaseId, expandParentCategory.join(',')])

  for (const [key, _] of Object.entries<PatientTodo[]>(sortedParentCategories)) {
    parentCategoryHTML.push(
      <Accordion
        expanded={!!accordionState[key]}
        onChange={(_, expanded) => setAccordionState(key, expanded)}
        sx={{
          boxShadow: 'none',
          '&::before': {
            backgroundColor: 'transparent',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
          '&.Mui-expanded .MuiButtonBase-root.MuiAccordionSummary-root.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
          '&.Mui-expanded .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto',
            cursor: 'default',
          },
          '& .MuiButtonBase-root.MuiAccordionSummary-root': {
            minHeight: 'auto',
            cursor: 'default',
          },
          '& .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded':
            {
              margin: '6px 0',
            },
          '& .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content': {
            margin: '6px 0',
            paddingTop: 0.75,
          },
        }}
      >
        <CategoryHeader
          categoryParentTitle={key}
          countPatientTodo={countPatientTodo[key]}
        ></CategoryHeader>
        <AccordionDetails>{toDosHTML[key]}</AccordionDetails>
      </Accordion>
    )
  }

  // Progressively load more To Dos when scrolling.
  const handleScroll = useCallback(() => {
    if (!scrollRef) {
      return
    }

    const element = scrollRef.current
    if (!element) {
      return
    }

    const atBottom = element.scrollTop + element.clientHeight >= element.scrollHeight
    const atTop = element.scrollTop === 0

    if (atTop && hasPreviousPage) {
      fetchPreviousPage()
    } else if (atBottom && hasNextPage) {
      fetchNextPage()
    }
  }, [scrollRef, hasNextPage, fetchNextPage, hasPreviousPage, fetchPreviousPage])

  const handleOnAfterAddCase = (newCase: ICase) => {
    if (newCase) {
      setSelectedCaseId(newCase.id, { type: 'add' })
    } else {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Case Creation Failed',
        })
      )
    }
    setAddCaseClick(false)
  }

  const openModal = () => {
    setAddCaseClick(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    scrollEventTarget?.addEventListener('scroll', handleScroll)

    return () => {
      scrollEventTarget?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollEventTarget, handleScroll])

  return (
    <Box>
      {isLoading && <Loader />}
      <Box display="flex" alignItems="center" justifyContent="flex-start" m={2}>
        <>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={(e, nextTab) => {
              clearHistory()
              setTab(nextTab)
            }}
            size="small"
            aria-label="Status"
            sx={{
              '.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
                padding: '7px 20px',
                textTransform: 'capitalize',
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '.MuiToggleButton-root.Mui-selected': {
                backgroundColor: '#f5f5f5',
                color: '#021A39',
              },
            }}
          >
            <ToggleButton value="active">Open</ToggleButton>
            <ToggleButton value="complete">Closed</ToggleButton>
          </ToggleButtonGroup>
        </>

        <Box
          color="navy.500"
          ml={'auto'}
          width={'34%'}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'flex-start'}
          justifyContent={'flex-end'}
        >
          {addCaseClick ? (
            <Box ml={2} width={'100%'}>
              <CaseCategorySelect
                categories={categories}
                category={null}
                handleAddCase={handleAddCase}
                setCategory={() => {}}
                freeSolo={true}
                onBlur={() => setAddCaseClick(false)}
              />
            </Box>
          ) : (
            <Box alignSelf={'center'}>
              <IconButton
                color="primary"
                onClick={() => openModal()}
                classes={{ root: classes.actionsButton }}
                sx={theme => ({
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.primary[700],
                  },
                  borderRadius: 'unset',
                })}
              >
                <AddIcon color="inherit" sx={{ fontSize: '1.4rem', fontWeight: 500 }} />
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>Add case</Typography>
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      {isFetchingPreviousPage && <LoadingMore />}
      <Fragment key={tab}>{parentCategoryHTML}</Fragment>
      {isFetchingNextPage && <LoadingMore />}

      <FFModal
        open={modalOpen}
        fixedHeight={true}
        keepMounted={false}
        header={
          <>
            <Box m={2}>
              <Typography variant="h5">Add New Case</Typography>
            </Box>
          </>
        }
      >
        <div>
          <CaseForm patient={patient} handleClose={closeModal} onAfterSave={handleOnAfterAddCase} />
        </div>
      </FFModal>
    </Box>
  )
}

interface CaseSummaryReview {
  score: number
  content?: string
}

const useCreateCaseSummaryReview = ({ summaryId }: { summaryId?: number }) => {
  const request = (data: CaseSummaryReview) => {
    if (!summaryId) return Promise.reject()
    return apiClient.rest.post<CaseSummaryReview>(
      `/providers/me/cases/summaries/${summaryId}/reviews/`,
      data
    )
  }

  return useMutation(request)
}

export const PatientTodoTaskComponents = ({
  patient,
  parentId,
  todo,
  worklist,
  isClosed,
  setDisableEdit,
  setIsTodoTaskUpdating,
  setIsTodoNoteUpdating,
}: {
  patient: PatientUser
  parentId: number
  todo: PatientTodo
  worklist: Worklist | undefined
  isClosed: boolean
  setDisableEdit: Function
  setIsTodoTaskUpdating: Function
  setIsTodoNoteUpdating: Function
}) => {
  const showCaseSummary = todo.contentType == 'case' && !!todo.contentObject?.currentSummary?.id
  const [summaryReviewModalOpen, setSummaryReviewModalOpen] = useState(false)
  const [summaryReviewPopperAnchorEl, setSummaryReviewPopperAnchorEl] =
    useState<HTMLButtonElement | null>(null)
  const [showSubmittedScore, setShowSubmittedScore] = useState(false)
  const [newSummaryReview, setNewSummaryReview] = useState<
    Pick<CaseSummaryReview, 'score' | 'content'>
  >({
    score: 0,
  })

  const createCaseSummaryReview = useCreateCaseSummaryReview({
    summaryId: (todo as any).contentObject?.currentSummary?.id,
  })

  const submitCaseSummaryReview = (newSummaryReview: CaseSummaryReview) => {
    setShowSubmittedScore(true)
    setTimeout(() => {
      setShowSubmittedScore(false)
    }, 3000)
    return createCaseSummaryReview.handler(newSummaryReview)
  }

  const summaryReviewScoreControls = ({ isModalControl }) => {
    const handleNewScore = (score, eventTarget) => {
      setNewSummaryReview(current => ({ ...current, score }))
      if (isModalControl && eventTarget) {
        setSummaryReviewPopperAnchorEl(eventTarget)
      }
    }

    const iconDisplayDependsOnScore = summaryReviewModalOpen || showSubmittedScore

    const UpIcon = !iconDisplayDependsOnScore
      ? ThumbUpIconOutlined
      : newSummaryReview.score == 0
      ? ThumbUpIconOutlined
      : ThumbUpIcon
    const DownIcon = !iconDisplayDependsOnScore
      ? ThumbDownIconOutlined
      : newSummaryReview.score == 0
      ? ThumbDownIcon
      : ThumbDownIconOutlined

    return (
      <>
        <Box display="flex">
          <Box>
            <IconButton onClick={event => handleNewScore(1, event.currentTarget)}>
              <UpIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={event => handleNewScore(0, event.currentTarget)}>
              <DownIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Popover
          open={!!summaryReviewPopperAnchorEl}
          onClose={() => setSummaryReviewPopperAnchorEl(null)}
          anchorEl={summaryReviewPopperAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List>
            <ListItemButton
              onClick={() => {
                setSummaryReviewPopperAnchorEl(null)
                setSummaryReviewModalOpen(true)
              }}
            >
              <ListItemText primary="Add a comment" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setSummaryReviewPopperAnchorEl(null)
                submitCaseSummaryReview(newSummaryReview)
              }}
            >
              <ListItemText primary="Send without comment" />
            </ListItemButton>
          </List>
        </Popover>
      </>
    )
  }

  return (
    <>
      {!showCaseSummary ? null : (
        <>
          <Box marginLeft={1} marginBottom={2}>
            <Box
              display="flex"
              width="100%"
              padding={2}
              sx={{ backgroundColor: 'grey.50' }}
              justifyContent="space-between"
            >
              <Box marginRight={1.5} display="flex" flexShrink={0}>
                <Box>
                  <AutoAwesomeIcon
                    fontSize="inherit"
                    sx={{
                      color: 'secondary.dark',
                    }}
                  />
                </Box>
                <Typography variant="caption" style={{ fontWeight: 'bold', color: GREY[600] }}>
                  &nbsp;Chat Summary
                </Typography>
              </Box>

              <Box width="80%">
                <Typography variant="body2">
                  {(() => {
                    logger.info('[Chat Summary] for case Id', todo.contentObject?.id)
                    const summaryContent = todo.contentObject?.currentSummary?.content
                    return summaryContent && summaryContent.length > MAX_CASE_SUMMARY_CONTENT_LENGTH
                      ? 'Summary withheld due to length'
                      : summaryContent
                  })()}
                </Typography>
              </Box>

              <Box>{summaryReviewScoreControls({ isModalControl: true })}</Box>
            </Box>
          </Box>
          <FFModal
            open={summaryReviewModalOpen}
            footer={
              <Box p={1} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={() => setSummaryReviewModalOpen(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={async () => {
                    await submitCaseSummaryReview(newSummaryReview)
                    setSummaryReviewModalOpen(false)
                  }}
                  color="primary"
                >
                  Submit
                </Button>
              </Box>
            }
          >
            <Box m={2}>
              <h1>Add Comment</h1>
              <Box>{summaryReviewScoreControls({ isModalControl: false })}</Box>
              <Box marginTop={2}>
                <TextField
                  value={newSummaryReview.content}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewSummaryReview(current => ({ ...current, content: event.target.value }))
                  }}
                  fullWidth
                  placeholder="Comment (Optional)"
                />
              </Box>
            </Box>
          </FFModal>
        </>
      )}
      <ToDoNotes
        patientTodo={todo}
        isClosed={isClosed}
        setIsTodoNoteUpdating={setIsTodoNoteUpdating}
      />
      <ToDoTasks
        patient={patient}
        parentId={parentId}
        todoContent={todo}
        worklist={worklist}
        isClosed={isClosed}
        setDisableEdit={setDisableEdit}
        setIsTodoTaskUpdating={setIsTodoTaskUpdating}
      />
      <EmbeddedRelations todo={todo} />
    </>
  )
}

const EmbeddedRelations = ({ todo }: { todo: PatientTodo }) => {
  const { classes } = useStyles()
  const sortedRelations = relations => {
    if (!relations) return []

    return orderBy(relations, ['createdAt'], ['desc'])
  }

  // Only supported on cases for now.
  if (todo.contentType !== 'case') {
    return null
  }

  const selectedCase = todo.contentObject
  const sortedCaseRelations = selectedCase?.relations && sortedRelations(selectedCase.relations)

  return (
    <>
      {sortedCaseRelations &&
        sortedCaseRelations.map(relation => {
          // Some content types are special and are not embedded in the generic way.
          if (
            relation.contentType === 'careplan' ||
            relation.contentType === 'chatmessagev2' ||
            relation.contentType === 'phonecall'
          ) {
            return null
          }

          // As steerage can be linked to multiple cases, we need the active case id for navigating
          // from Add Provider button in the steerage view to provider search and back to the active case.
          if (relation.contentType === 'steerage') {
            relation.contentObject.activeCaseId = selectedCase.id
          }

          const Component = buildRelatedComponentForModel(relation.contentType)

          return (
            <Box
              key={`${relation.contentType}_${relation.objectId}`}
              mt={4}
              mb={4}
              className={classes.relatedContent}
            >
              <Component model={relation.contentObject} />
            </Box>
          )
        })}
    </>
  )
}

const LoadingMore = () => (
  <Box paddingLeft={2} paddingTop={2} display="flex" alignItems="center">
    <Typography>Loading more...</Typography>
  </Box>
)

/**
 * PatientCategorizedToDos
 * This component used to render patient Todo section
 */
const PatientCategorizedToDos = (props: {
  patient: PatientUser
  scrollRef?: RefObject<HTMLDivElement>
  scrollEventTarget?: EventTarget
}) => {
  return (
    <Box>
      <ToDoItems {...props} />
    </Box>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    actionsButton: {
      padding: theme.spacing(0.5),
    },
    relatedContent: {
      marginBottom: '0.5rem',
    },
    autoCompleteText: {
      fontSize: '1.4rem',
    },
  }
})

export default PatientCategorizedToDos
