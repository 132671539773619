import { useRef } from 'react'
import type { FC } from 'react'
import {
  Button,
  Grid,
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import { omit } from 'lodash'
import Moment from 'moment'

import { copyToClipboard } from '~/utils/clipboard'

type EditTextFieldProps = TextFieldProps & { label?: string } & { showChangeButton?: boolean } & {
  minDate?: string
}

export const EditField: FC<EditTextFieldProps> = props => {
  const styles = useStyles()
  const ref = useRef<HTMLInputElement>(null)

  // If the EditField field is type of date then set a default past value
  const inputProps = {
    ...(props?.type == 'date' && {
      // If the date field have default minDate value then set that else disable all dates years before 1900
      min: props?.minDate ? props?.minDate : Moment('1900-01-01').format('YYYY-MM-DD'),
    }),
  }

  return (
    <Grid item xs={6} key={props.key}>
      {props.showChangeButton ? (
        <TextField
          fullWidth
          key={props.key}
          name={props.name}
          value={props.value}
          label={props.label}
          disabled={props.InputProps?.readOnly}
          variant={props.variant}
          inputProps={inputProps}
          InputProps={{
            endAdornment: (
              // onClick is expecting an Anchor element but HTMLDiv element is used
              // @ts-ignore
              <Button disabled={false} color="primary" onClick={props.onClick}>
                Change
              </Button>
            ),
          }}
        />
      ) : (
        <TextField
          fullWidth
          key={props.key}
          error={props.error}
          helperText={props.helperText}
          className={styles.textField}
          inputRef={ref}
          variant={props.variant ? props.variant : 'standard'}
          inputProps={inputProps}
          InputProps={
            // Don't append InputAdornment for copy/paste when rendering a <select> list
            !props.select
              ? {
                  classes: {
                    input: `TEST__${props.name}`,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className={styles.copy}>
                        <IconButton onClick={() => copyToClipboard(ref)} size="small">
                          <FilterNoneIcon fontSize="inherit" />
                        </IconButton>
                      </span>
                    </InputAdornment>
                  ),
                  defaultValue: props.value,
                  ...props.InputProps,
                }
              : props.InputProps
          }
          {...(omit(props, ['InputProps']) as TextFieldProps)}
        />
      )}
    </Grid>
  )
}

const useStyles = makeStyles({
  textField: {
    '&:hover span': {
      opacity: 1,
    },
  },
  copy: {
    opacity: 0,
  },
})

export default EditField
