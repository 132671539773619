import { Box, Tabs, Tab } from '@material-ui/core'
import { CREATE_MODES } from './useCarePlanModal'

interface ICreateModeSelectorProps {
  handleCreateModeSelection: (arg0: string) => any
  carePlan: any
  me: any
  createMode: string
  selectedTemplate: any
}

const CreateModeSelector = (props: ICreateModeSelectorProps) => {
  if (props.carePlan || props.selectedTemplate) {
    // If an existing care plan exists
    // then we're not creating a new care plan, and shouldn't be showing these options to edit the creation mode
    // If there's already a selected template, we want the user to back out to template selection instead of being able to directly go back to the custom edit mode
    return <></>
  }

  const handleCreateModeTabSelection = (_: any, newValue) => {
    props.handleCreateModeSelection(newValue)
  }

  return (
    <Box mb={1} padding={0}>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={props.createMode}
        onChange={handleCreateModeTabSelection}
      >
        <Tab value={CREATE_MODES.CUSTOM} label={'New Care Plan'} />
        <Tab value={CREATE_MODES.TEMPLATE} label={'Templates'} />
      </Tabs>
    </Box>
  )
}

export default CreateModeSelector
