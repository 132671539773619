import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import FFModal from '~/components/FFModal/FFModal'
import CloseIcon from '@mui/icons-material/Close'
import { useQuery } from 'react-query'
import { getCancellationReasons } from '~/api/AppointmentService'
import { Appointment } from '.'

export interface CancellationReason {
  id: number
  isPatientFacing: boolean
  label: string
  parent: number | null
  uid: string
  children?: CancellationReason[]
}

export interface CancelData {
  label: string
  children: CancellationReason[]
}

const UidMapping = {
  otherPatient: 'other',
  otherFirefly: 'other_firefly',
}

const CancelAppointmentModal: FC<{
  appointment: Appointment
  otherReasonForPatient: string
  setOtherReasonForPatient: (string) => void
  setOtherReasonForFirefly: (string) => void
  otherReasonForFirefly: string
  cancellationReasonIds: any[]
  setCancellationReasonIds: (val) => void
  onClose: (success: boolean) => void
}> = props => {
  const [saving, setSaving] = useState<boolean>(false)
  // controls display for sub options of "Provider not available"
  const [showSubOptions, setShowSubOptions] = useState<boolean>(false)
  // list of cancellation reasons to be displayed
  const [displayCancellationReasons, setDisplayCancellationReasons] = useState<CancelData[]>([])
  // to remove the suboptions when parent is unchecked
  const [selectedSubOptions, setSelectedSubOptions] = useState<Array<number>>([])
  const [showOtherTextField, setShowOtherTextField] = useState<boolean>(false)
  const [showOtherFireflyTextField, setShowOtherFireflyTextField] = useState<boolean>(false)

  /**
   * onReasonCheckboxClick : Function gets triggered when parent set of reason is un/selected
   * @param
   * event: checkbox event
   * reason: selected reason
   */
  const onReasonCheckboxClick = (event, reason) => {
    if (reason.isPatientFacing && reason.uid == UidMapping.otherPatient) {
      setShowOtherTextField(event.target.checked)
      if (!event.target.checked) {
        props.setOtherReasonForPatient('')
      }
    }
    if (reason.children && reason.children.length > 0) {
      setShowSubOptions(event.target.checked)
    }

    addRemoveReasons(reason, event.target.checked, false)
  }

  /**
   * addRemoveReasons : Function to update the state variable cancellationReason
   * @param
   * reasonId: number highlighting the selected reason Id
   * addItem: boolean to add or remove the item from the list
   * isSubReason : If a sub reason is checked / unchecked
   */
  const addRemoveReasons = (reason, addItem, isSubReason) => {
    if (addItem) {
      props.setCancellationReasonIds([...props.cancellationReasonIds, reason.id])
      if (isSubReason) setSelectedSubOptions([...selectedSubOptions, reason.id])
    } else {
      let filteredData = props.cancellationReasonIds.filter(
        selectedReasonId => selectedReasonId !== reason.id
      )
      let subOptionFilteredData = selectedSubOptions.filter(
        selectedReasonId => selectedReasonId !== reason.id
      )
      if (reason.children && reason.children.length > 0) {
        filteredData = filteredData.filter(
          selectedReasonId => !selectedSubOptions.includes(selectedReasonId)
        )
        subOptionFilteredData = []
      }
      props.setCancellationReasonIds(filteredData)
      if (isSubReason) setSelectedSubOptions(subOptionFilteredData)
    }
  }

  useEffect(() => {
    if (props.cancellationReasonIds.length > 0) {
      setSaving(false)
    } else {
      setSaving(true)
    }
  }, [props.cancellationReasonIds])

  /** API call to fetch list of cancellation reasons */
  const query = useQuery(['getCanUserBookAppointmentsV2'], () => getCancellationReasons(), {
    onSuccess(reasons: CancellationReason[]) {
      const cancellationReasons: CancelData[] = []
      const fireflyReasons = {
        label: 'Select Firefly reason',
        children: [] as CancellationReason[],
      }
      const patientReasons = {
        label: 'Select patient reason',
        children: [] as CancellationReason[],
      }
      reasons.map(reason => {
        let parentValue = reason.isPatientFacing ? patientReasons : fireflyReasons
        parentValue.children.push(reason)
      })
      cancellationReasons.push(fireflyReasons)
      cancellationReasons.push(patientReasons)
      setDisplayCancellationReasons(cancellationReasons)
    },
  })

  if (query.isLoading || query.isIdle) {
    return null
  }
  if (query.isError) {
    console.log({ error: query.error })
    return null
  }

  return (
    <FFModal
      open={true}
      style={{ width: '65%', marginLeft: 'auto', marginRight: 'auto' }}
      header={
        <Box m={2}>
          <Typography variant="h6">{'Cancel Appointment'}</Typography>
        </Box>
      }
      footer={
        <Box m={2}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button
              onClick={() => {
                props.onClose(false)
              }}
              variant="text"
              size="small"
              sx={{
                borderRadius: 10,
                fontSize: '1.4rem',
                maxHeight: '75%',
                width: '20%',
                marginRight: 1,
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Exit'}</Typography>
            </Button>
            <Button
              disabled={saving}
              variant="contained"
              size="small"
              onClick={() => {
                props.onClose(true)
              }}
              sx={{
                borderRadius: 10,
                fontSize: '1.4rem',
                maxHeight: '75%',
                width: '45%',
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
                {'Cancel Appointment'}
              </Typography>
            </Button>
          </Box>
        </Box>
      }
    >
      <>
        <Box m={2}>
          {displayCancellationReasons.map((category, index) => {
            return (
              <Box key={'category-' + index}>
                <Typography variant="subtitle1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                  {category.label}
                </Typography>
                <Box ml={2}>
                  {category.children.map((reason, i) => {
                    return (
                      <FormGroup key={'reason-' + i}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={e => {
                                onReasonCheckboxClick(e, reason)
                              }}
                            />
                          }
                          label={reason.label}
                          sx={{
                            color: 'rgba(0, 0, 0, 0.87)',
                          }}
                        />
                        {reason.isPatientFacing && reason.uid == UidMapping.otherPatient ? (
                          <Box display={showOtherTextField ? 'block' : 'none'}>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              value={props.otherReasonForPatient}
                              onChange={e => props.setOtherReasonForPatient(e.target?.value)}
                              multiline={true}
                              fullWidth
                              placeholder="Add reason (optional)"
                            />
                          </Box>
                        ) : null}
                        <Box ml={2} display={showSubOptions ? 'block' : 'none'}>
                          {reason?.children &&
                            reason?.children?.length > 0 &&
                            reason?.children?.map((subReason, key) => {
                              return (
                                <FormGroup key={'sub-' + key}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={props.cancellationReasonIds.includes(subReason.id)}
                                        onChange={e => {
                                          if (
                                            !subReason.isPatientFacing &&
                                            subReason.uid == UidMapping.otherFirefly
                                          ) {
                                            setShowOtherFireflyTextField(e.target.checked)
                                            if (!e.target.checked) {
                                              props.setOtherReasonForFirefly('')
                                            }
                                          }
                                          addRemoveReasons(subReason, e.target.checked, true)
                                        }}
                                      />
                                    }
                                    label={subReason.label}
                                    sx={{
                                      color: 'rgba(0, 0, 0, 0.87)',
                                    }}
                                  />

                                  {!subReason.isPatientFacing &&
                                  subReason.uid == UidMapping.otherFirefly ? (
                                    <Box display={showOtherFireflyTextField ? 'block' : 'none'}>
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={props.otherReasonForFirefly}
                                        onChange={e =>
                                          props.setOtherReasonForFirefly(e.target?.value)
                                        }
                                        multiline={true}
                                        fullWidth
                                        placeholder="Add reason (optional)"
                                      />
                                    </Box>
                                  ) : null}
                                </FormGroup>
                              )
                            })}
                        </Box>
                      </FormGroup>
                    )
                  })}
                </Box>
              </Box>
            )
          })}
        </Box>
        <Box m={2}>
          <IconButton
            onClick={() => {
              props.onClose(false)
            }}
            sx={{
              position: 'absolute',
              zIndex: 100,
              top: 2,
              right: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    </FFModal>
  )
}

export default CancelAppointmentModal
