/* eslint-disable react/display-name */
import type { FC } from 'react'
import { Document, BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import * as MUI from '@material-ui/core'

import { useEducationModal } from './utils'

interface EducationModalRichTextProps {
  document: Document
}

export const EducationModalRichText: FC<EducationModalRichTextProps> = props => {
  const { includes } = useEducationModal()
  const classes = useStyles()

  // Pass styles to renderer functions; used inline here to better access context values
  const components = documentToReactComponents(props.document, {
    renderNode: {
      // Blocks
      [BLOCKS.HEADING_1]: (_, children) => (
        <MUI.Typography component="h1" className={classes.h1}>
          {children}
        </MUI.Typography>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <MUI.Typography component="h2" className={classes.h2}>
          {children}
        </MUI.Typography>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <MUI.Typography component="h3" className={classes.h3}>
          {children}
        </MUI.Typography>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <MUI.Typography component="h4" className={classes.h4}>
          {children}
        </MUI.Typography>
      ),
      [BLOCKS.HEADING_5]: (_, children) => (
        <MUI.Typography component="h5" className={classes.h5}>
          {children}
        </MUI.Typography>
      ),
      [BLOCKS.HEADING_6]: (_, children) => (
        <MUI.Typography component="h6" className={classes.h6}>
          {children}
        </MUI.Typography>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => <MUI.Typography>{children}</MUI.Typography>,
      [BLOCKS.QUOTE]: (_, children) => (
        <MUI.Typography component="blockquote">{children}</MUI.Typography>
      ),
      [BLOCKS.HR]: () => (
        <MUI.Box mx={-2} my={2}>
          <MUI.Divider />
        </MUI.Box>
      ),
      [BLOCKS.OL_LIST]: (_, children) => <ol className={classes.list}>{children}</ol>,
      [BLOCKS.UL_LIST]: (_, children) => <ul className={classes.list}>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        // Asserting not null here because if a rich content block has a reference to an image asset,
        // the "includes" key will be included in the api response
        const include = includes!.asset[node.data.target.sys.id]
        return include ? (
          <img
            className={classes.image}
            src={include!.fields.file.url}
            alt={include.fields.file.fileName}
          />
        ) : null
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} rel="noopener noreferrer" target="_blank" className={classes.link}>
          {children}
        </a>
      ),
    },
  })
  return <div className={classes.root}>{components}</div>
}

const useStyles = MUI.makeStyles(() => ({
  root: {
    wordBreak: 'break-word',
    '& > p': { margin: '0.5em 0' },
    '& h1, h2, h3, h4, h5, h6': { fontWeight: 'bold', lineHeight: 1, margin: '0.5em 0' },
  },
  h1: { fontSize: '4.25rem' },
  h2: { fontSize: '3.75rem' },
  h3: { fontSize: '3.25rem' },
  h4: { fontSize: '2.75rem' },
  h5: { fontSize: '2.25rem' },
  h6: { fontSize: '1.75rem' },
  image: { maxWidth: '100%' },
  list: {
    paddingLeft: '1em',
    '& ::marker': { fontWeight: '500' },
    '& ul, ol': { paddingLeft: '2em' },
    // Force nested ordered lists to render the same as Contentful
    '& ol': {
      listStyle: 'upper-alpha',
      '& ol': {
        listStyle: 'lower-roman',
        '& ol': {
          listStyle: 'lower-alpha',
        },
      },
    },
  },
  link: { wordBreak: 'break-all' },
}))

export default EducationModalRichText
