import { useState } from 'react'
import {
  Autocomplete,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Paper,
  Box,
  TextField,
} from '@mui/material'
import { titleCase } from '~/utils'
import { apiClient } from '~/api/rest'
import { formatDateWithDay } from '~/utils/date'
import { useMutation } from '~/legacy/core'
import { ChurnedReason } from '~/api/OnboardingService'

interface IPrimaryCareDischargeProps {
  loading: boolean
  pendingDischargeInfo: any
  patientId: number
  loadPendingDischargeInfo: () => void
  dischargeReasons: ChurnedReason[] | undefined
}

const PrimaryCareDischarge = (props: IPrimaryCareDischargeProps) => {
  const [newDischargeReason, setNewDischargeReason] = useState<string>('')

  const dischargePendingReason = props.pendingDischargeInfo?.dischargePendingReason
  const pendingDischargeReason = props.pendingDischargeInfo?.pendingDischargeReason
  const dischargeApproved = props.pendingDischargeInfo?.dischargePendingApprovedAt

  const { loading: startDischargeLoading, handler: startDischarge } = useMutation(async () => {
    if (!newDischargeReason) return
    try {
      await apiClient.rest.patch(`/programs/primary-care/${props.patientId}/pending-discharge/v2`, {
        pendingDischargeReason: newDischargeReason,
      })
      await props.loadPendingDischargeInfo()
    } catch (e) {
      console.error(e)
    }
  })

  const { loading: cancelDischargeLoading, handler: cancelDischarge } = useMutation(async () => {
    try {
      await apiClient.rest.patch(`/programs/primary-care/${props.patientId}/pending-discharge/v2`, {
        pendingDischargeReason: null,
      })

      await props.loadPendingDischargeInfo()
      setNewDischargeReason('')
    } catch (e) {
      console.error(e)
    }
  })

  const innerContent = () => {
    if (props.loading) return <CircularProgress />

    if (dischargePendingReason) {
      return (
        <Grid container direction="row">
          <Grid item sm={8}>
            <Typography variant="subtitle2">Pending</Typography>
            <Box>
              <Typography variant="caption">Reason: {titleCase(dischargePendingReason)}</Typography>
            </Box>
            <Box>
              {dischargeApproved && (
                <Typography variant="caption">
                  Approved {formatDateWithDay(dischargeApproved)}
                </Typography>
              )}
              {!dischargeApproved && <Typography variant="caption">Awaiting approval</Typography>}
            </Box>
          </Grid>
          <Grid item sm={4} style={{ textAlign: 'left' }}>
            <Button
              type="submit"
              disabled={cancelDischargeLoading}
              variant="contained"
              color="primary"
              onClick={cancelDischarge}
            >
              Cancel Discharge
            </Button>
          </Grid>
        </Grid>
      )
    }

    if (pendingDischargeReason) {
      return (
        <Box mt={2} display="flex" alignItems={'center'}>
          <Box ml={1}>
            <Typography variant="subtitle2">Pending</Typography>
            <Box>
              <Typography variant="caption">Reason: {titleCase(pendingDischargeReason)}</Typography>
            </Box>
            <Box>
              {dischargeApproved && (
                <Typography variant="caption">
                  Approved {formatDateWithDay(dischargeApproved)}
                </Typography>
              )}
              {!dischargeApproved && <Typography variant="caption">Awaiting approval</Typography>}
            </Box>
          </Box>
          <Box ml={2}>
            <Button
              type="submit"
              disabled={cancelDischargeLoading}
              variant="contained"
              color="primary"
              onClick={cancelDischarge}
            >
              Cancel Discharge
            </Button>
          </Box>
        </Box>
      )
    }

    return (
      <>
        <Typography variant="body2">Discharge patient for...</Typography>
        <Box>
          {props.dischargeReasons ? (
            <Box mt={2} display="flex" alignItems={'center'}>
              <Box ml={1}>
                <Autocomplete
                  size="small"
                  id="discharge-reasons"
                  value={props.dischargeReasons.find(
                    reason => reason.uniqueKey === pendingDischargeReason
                  )}
                  onChange={(_, value) =>
                    value?.uniqueKey
                      ? setNewDischargeReason(value.uniqueKey)
                      : setNewDischargeReason('')
                  }
                  options={props.dischargeReasons}
                  getOptionLabel={option => option.label}
                  style={{ width: 300 }}
                  renderInput={params => (
                    <TextField {...params} label="Reason for Discharge" variant="outlined" />
                  )}
                />
              </Box>
              <Box ml={2}>
                <Button
                  type="submit"
                  disabled={startDischargeLoading || !newDischargeReason}
                  variant="contained"
                  color="primary"
                  onClick={startDischarge}
                >
                  Begin Discharge
                </Button>
              </Box>
            </Box>
          ) : null}
        </Box>
      </>
    )
  }

  return (
    <>
      <Box display="block">
        <Typography variant="subtitle2">Discharge from Primary Care</Typography>
      </Box>
      <Box mt={1}>
        <Paper elevation={5} style={{ padding: '1rem' }}>
          {innerContent()}
        </Paper>
      </Box>
    </>
  )
}

export default PrimaryCareDischarge
