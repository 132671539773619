import {
  BEHAVIORAL_HEALTH_ROLE,
  CARE_TEAM_ROLE_GROUPS,
  HEALTH_GUIDE_ROLE,
  MD_ROLE,
  NP_ROLE,
  RN_ROLE,
} from '~/constants/careTeamRoles'
import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'

import type { FC } from 'react'
import ProviderSelectionRadioButtonsGroup from './ProviderSelectionRadioButtonsGroup'
import { apiClient } from '~/api/rest'
import { groupBy } from 'lodash/fp'
import { providerCanProvideClinicalCareToPatient } from '~/utils'
import { patientsSlice } from '~/redux/slices/patients'
import { queueNotification } from '~/redux/actions/notifications'
import theme from '~/utils/theme'

interface EditPatientCareTeamProps {
  patient: any
}

export const EditPatientCareTeam: FC<EditPatientCareTeamProps> = props => {
  const providers = useSelector<any, any[]>(state => state.providers)
    .filter(provider => provider.isActive)
    .filter(provider => provider.providerFields.isExternal === false)
  const [behavioralHealthSwitch, setBehavioralHealthSwitch] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedCareTeam, setSelectedCareTeam] = useState<any>({
    md: null,
    np: null,
    rn: null,
    healthGuide: null,
    behavioralHealth: null,
  })
  const dispatch = useDispatch()

  const groupedProviders = useMemo(() => {
    const providersWithStateInfo = providers.map(provider => {
      return {
        displayName: `${provider.firstName} ${provider.lastName}`,
        canProvideCare: providerCanProvideClinicalCareToPatient(provider, props.patient),
        ...provider,
      }
    })
    return groupBy(provider => {
      const groupFound = provider.groups.find(g => CARE_TEAM_ROLE_GROUPS.has(g.name))
      return groupFound ? groupFound.name : 'unknown'
    }, providersWithStateInfo)
  }, [providers, props.patient?.person?.insuranceInfo, providerCanProvideClinicalCareToPatient])

  const findProviderInRole = (careTeam, role) => {
    const foundProvider = careTeam.find(provider => provider.group === role)
    return foundProvider ? foundProvider.id : null
  }

  const reset = async () => {
    const careTeam = await apiClient.rest.get(`/user/${props.patient.id}/care-team/v2/`)
    setSelectedCareTeam({
      ...selectedCareTeam,
      md: findProviderInRole(careTeam, MD_ROLE),
      np: findProviderInRole(careTeam, NP_ROLE),
      rn: findProviderInRole(careTeam, RN_ROLE),
      healthGuide: findProviderInRole(careTeam, HEALTH_GUIDE_ROLE),
      behavioralHealth: findProviderInRole(careTeam, BEHAVIORAL_HEALTH_ROLE),
    })
    setShowCancel(false)
  }

  useEffect(() => {
    setLoading(true)
    const fetchCareTeam = async () => {
      const careTeam = await apiClient.rest.get(`/user/${props.patient.id}/care-team/v2/`)
      setSelectedCareTeam({
        ...selectedCareTeam,
        md: findProviderInRole(careTeam, MD_ROLE),
        np: findProviderInRole(careTeam, NP_ROLE),
        rn: findProviderInRole(careTeam, RN_ROLE),
        healthGuide: findProviderInRole(careTeam, HEALTH_GUIDE_ROLE),
        behavioralHealth: findProviderInRole(careTeam, BEHAVIORAL_HEALTH_ROLE),
      })
      setLoading(false)
    }
    fetchCareTeam()
    setShowCancel(false)
  }, [])

  useEffect(() => {
    setBehavioralHealthSwitch(!!selectedCareTeam.behavioralHealth)
  }, [selectedCareTeam])

  const handleSave = async () => {
    try {
      await apiClient.rest.patch(`/user/${props.patient.id}/care-team/v2/`, {
        careTeam: selectedCareTeam,
      })
      setShowCancel(false)
      dispatch(
        queueNotification({
          variant: 'success',
          message: 'Care team updated',
        })
      )
      dispatch(patientsSlice.thunks.getPatient(props.patient.id))
    } catch (error) {
      dispatch(queueNotification({ variant: 'error', message: 'Problem updating care team' }))
      console.log(error)
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" mt={2}>
        <Typography variant="h6" color="inherit">
          Care Team
        </Typography>
      </Box>
      <Box display="flex" mt={2}>
        <Box flex={1}>
          <ProviderSelectionRadioButtonsGroup
            roleName="md"
            selectedCareTeam={selectedCareTeam}
            title={'Physician'}
            options={groupedProviders[MD_ROLE]}
            setSelectedCareTeam={setSelectedCareTeam}
            setShowCancel={setShowCancel}
            disabled={loading}
          />
        </Box>
        <Box flex={1}>
          <ProviderSelectionRadioButtonsGroup
            roleName="np"
            selectedCareTeam={selectedCareTeam}
            title={'Nurse Practitioner'}
            options={groupedProviders[NP_ROLE]}
            setSelectedCareTeam={setSelectedCareTeam}
            setShowCancel={setShowCancel}
            disabled={loading}
          />
        </Box>
        <Box flex={1}>
          <ProviderSelectionRadioButtonsGroup
            roleName="rn"
            selectedCareTeam={selectedCareTeam}
            title={'Registered Nurse'}
            options={groupedProviders[RN_ROLE]}
            setSelectedCareTeam={setSelectedCareTeam}
            setShowCancel={setShowCancel}
            disabled={loading}
          />
        </Box>
        <Box flex={1}>
          <ProviderSelectionRadioButtonsGroup
            roleName="healthGuide"
            selectedCareTeam={selectedCareTeam}
            title={'Health Guide'}
            options={groupedProviders[HEALTH_GUIDE_ROLE]}
            setSelectedCareTeam={setSelectedCareTeam}
            setShowCancel={setShowCancel}
            disabled={loading}
          />
        </Box>
      </Box>
      <Box ml={-2} color={theme.palette.secondary.dark}>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              color="primary"
              checked={behavioralHealthSwitch}
              onChange={() => {
                if (
                  !behavioralHealthSwitch &&
                  groupedProviders[BEHAVIORAL_HEALTH_ROLE].length === 1
                ) {
                  setSelectedCareTeam({
                    ...selectedCareTeam,
                    behavioralHealth: groupedProviders[BEHAVIORAL_HEALTH_ROLE][0].id,
                  })
                } else if (behavioralHealthSwitch) {
                  setSelectedCareTeam({
                    ...selectedCareTeam,
                    behavioralHealth: null,
                  })
                }
                setShowCancel(true)
                setBehavioralHealthSwitch(!behavioralHealthSwitch)
              }}
            />
          }
          label="Behavioral Health"
        />
      </Box>
      <Box mb={2}>
        {behavioralHealthSwitch ? (
          <ProviderSelectionRadioButtonsGroup
            roleName="behavioralHealth"
            selectedCareTeam={selectedCareTeam}
            options={groupedProviders[BEHAVIORAL_HEALTH_ROLE]}
            disabled={loading || groupedProviders[BEHAVIORAL_HEALTH_ROLE]?.length === 1}
            setSelectedCareTeam={setSelectedCareTeam}
            setShowCancel={setShowCancel}
          />
        ) : null}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        {showCancel ? (
          <Box mr={1}>
            <Button onClick={() => reset()}>Cancel Care Team Changes</Button>
          </Box>
        ) : null}
        <Box>
          <Button type="submit" color="primary" variant="outlined" onClick={handleSave}>
            Save Care Team
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default EditPatientCareTeam
