import { CircularProgress, Box } from '@material-ui/core'

const MoreMessagesLoader = ({ style }) => {
  return (
    <Box style={style} display="flex" alignItems="center" justifyContent="center">
      <CircularProgress size={20} />
      <Box ml={2}>Loading more threads...</Box>
    </Box>
  )
}

export default MoreMessagesLoader
