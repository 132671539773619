import DateAndRelativeDateInput from '../Careplans/DateAndRelativeDateInput'
import type { FC } from 'react'
import FFInput from '~/components/Generic/FFInput'
import { ITask } from '~/models/Task'
import { InputAdornment } from '@mui/material'
import Moment from 'moment'
import PriorityToggle from '../Generic/PriorityToggle'
import UserGroupSelect from '../Generic/UserGroupSelect'
import { makeStyles } from 'tss-react/mui'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useGroupOptions } from '~/utils/useGroupOptions'

const useStyles = makeStyles()(theme => ({
  grid: {
    display: 'flex',
    marginLeft: theme.spacing(-1),
    flexWrap: 'wrap',
  },
  gridCell: {
    display: 'flex',
    paddingLeft: theme.spacing(),
    marginTop: theme.spacing(),
    flex: 1,
    minWidth: 275,
  },
  taskAssigneeSelector: {
    width: '100%',
    height: '100%',
  },
  taskSplitRelativeDate: {
    marginRight: theme.spacing(),
    flex: 0.4,
  },
  taskSplitLeft: {
    flex: 1,
  },
}))

export interface TaskEditorProps {
  task: ITask
  onChange: (task: ITask) => any
  disabled?: boolean
  className?: string
  patient?: any
  canAssignToPatient?: boolean
  autoFocus?: boolean // If true, will autofocus the first input field.
}

const TaskEditor: FC<TaskEditorProps> = props => {
  const { classes } = useStyles()
  const { task, disabled, patient, autoFocus } = props
  const providers = useSelector(state => state.providers)
  const assigneeGroups = useSelector(state => state.assigneeGroups)
  let isTaskAssigneeGroupProvider = providers.find(
    provider => provider.assigneeGroup?.id === task.ownerGroup
  )?.assigneeGroup
  if (!isTaskAssigneeGroupProvider) {
    isTaskAssigneeGroupProvider = assigneeGroups.find(group => group.id === task.ownerGroup)
  }

  const enablePriorityToggle = isTaskAssigneeGroupProvider

  const { selectedCaseOwnerGroupOption, flatOptions, groupedOptions } = useGroupOptions(
    task.ownerGroup || null,
    !!props.canAssignToPatient,
    patient,
    patient.person.careTeam,
    disabled
  )

  return (
    <div className={props.className}>
      <div>
        <FFInput
          inputProps={{
            maxLength: 255,
          }}
          InputProps={{
            autoFocus,
            endAdornment: (
              <InputAdornment color="" position="end">{`${task.title.length}/255`}</InputAdornment>
            ),
          }}
          placeholder="Task Title"
          value={task.title}
          fullWidth
          multiline
          onChange={e => props.onChange({ ...props.task, title: e.target.value })}
          disabled={disabled}
        />
      </div>
      <div className={classes.grid}>
        <div className={classes.gridCell}>
          <DateAndRelativeDateInput
            classes={classes}
            value={Moment(task.dueDate).format('YYYY-MM-DD')}
            onChange={dueDate => props.onChange({ ...props.task, dueDate })}
            disabled={disabled}
          />
        </div>
        <div className={classes.gridCell}>
          {
            <UserGroupSelect
              selectedValue={selectedCaseOwnerGroupOption}
              options={flatOptions}
              groupedOptions={groupedOptions}
              onChange={ownerGroupOption => {
                const ownerGroup = ownerGroupOption.value
                if (ownerGroup) props.onChange({ ...props.task, ownerGroup })
              }}
              patient={patient}
              className={classes.taskAssigneeSelector}
              disabled={props.disabled}
            />
          }
          {enablePriorityToggle ? (
            <PriorityToggle
              priority={task.priority}
              onChange={priority => props.onChange({ ...props.task, priority })}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

TaskEditor.defaultProps = {
  disabled: false,
  canAssignToPatient: true,
}

export default memo(TaskEditor)
