import { Autocomplete } from '@mui/material'
import { CaseOrCategory, Grouped, GroupedCaseOrCategory, isCase } from './types'
import Input from './Input'
import Option from './Option'
import { makeStyles } from 'tss-react/mui'

type Props = {
  options: Grouped<CaseOrCategory>[]
  onChange: (value: CaseOrCategory) => void
  onBlur?: () => void
}

const useStyles = makeStyles()(theme => {
  return {
    autoCompleteText: {
      fontSize: '1.4rem',
    },
  }
})

export default function TriageCaseOrCategorySelect({ onChange, options, onBlur }: Props) {
  const { classes } = useStyles()

  return (
    <Autocomplete<GroupedCaseOrCategory, undefined, undefined, true>
      id="triage-case-category"
      freeSolo
      openOnFocus
      onBlur={onBlur}
      onKeyDown={e => {
        if (onBlur && e.key === 'Escape') {
          onBlur()
          e.stopPropagation()
        }
      }}
      disablePortal
      options={options.filter(caseOrCategory => {
        if (!isCase(caseOrCategory))
          return !caseOrCategory.isSystemOnly && !caseOrCategory.isDeprecated
        else return caseOrCategory
      })}
      groupBy={option => option.group}
      getOptionLabel={option => option.title}
      renderInput={Input}
      renderOption={(props, option) => (
        <Option {...props} option={option} key={option.id} group={option.group} />
      )}
      onChange={(_event, newValue, reason) => {
        if (reason === 'selectOption' && newValue && typeof newValue !== 'string') {
          onChange(newValue)
        }
      }}
      classes={{ input: classes.autoCompleteText, option: classes.autoCompleteText }}
    />
  )
}
