import { FC } from 'react'
import { useQuery } from 'react-query'
import {
  CanUserBookAppointmentsV2,
  VisitReason,
  getCanUserBookAppointmentsV2,
} from '~/api/AppointmentService'
import AppointmentTypes from './AppointmentTypes'

type ApptTypes = 'Medical' | 'Annual' | 'Health Guide' | 'BH'

type SelectAppointmentTypeProps = {
  patientId: number
  visitReason: VisitReason | null
  setVisitReason: (value: VisitReason | null) => void
  setIsLoading: (value) => void
}

export const Mapping: [VisitReason, ApptTypes][] = [
  ['video', 'Medical'],
  ['videoNew', 'Medical'],
  ['annualWellness', 'Annual'],
  ['healthGuideConsult', 'Health Guide'],
  ['behavioralHealth', 'BH'],
]

function firstAvailableVisitReason(userTypes: CanUserBookAppointmentsV2): VisitReason | null {
  const map = Mapping.find(([visitReason]) => !!userTypes[visitReason])
  return map ? map[0] : null
}

const SelectAppointmentType: FC<SelectAppointmentTypeProps> = ({
  patientId,
  setVisitReason,
  visitReason,
  setIsLoading,
}) => {
  const query = useQuery(
    ['getCanUserBookAppointmentsV2', patientId],
    () => getCanUserBookAppointmentsV2(patientId),
    {
      onSuccess(userTypes) {
        if (!visitReason) {
          setVisitReason(firstAvailableVisitReason(userTypes))
          setIsLoading(false)
        }
      },
    }
  )

  if (query.isLoading || query.isIdle) {
    return null
  }
  if (query.isError) {
    console.log({ error: query.error })
    return null
  }

  return (
    <AppointmentTypes
      userTypes={query.data}
      selectedVisitReason={visitReason}
      onChange={setVisitReason}
    />
  )
}

export default SelectAppointmentType
