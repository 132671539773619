import TaskEditorRow from './TaskEditorRow'
import { IconButton, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { ITask } from '~/models/Task'

interface ITasksFormProps {
  patient: any
  tasks: ITask[]
  saving: boolean
  addTask: (any) => void
  updateTask: (i: number, update: any) => void
  removeTask: (any) => void
}

const TasksForm = (props: ITasksFormProps) => {
  const { patient, tasks } = props
  const classes = useStyles()

  const taskEditorRows = tasks.map((task, i) => {
    const { isComplete } = task
    const disabledRemove = props.saving || isComplete
    const disabledEdit = props.saving || isComplete

    return (
      <Box mb={3} key={`task_${i}`}>
        <TaskEditorRow
          i={i}
          task={task}
          disabledEdit={disabledEdit}
          disabledRemove={disabledRemove}
          onChange={props.updateTask}
          onRemove={props.removeTask}
          patient={patient}
          classes={classes}
        />
      </Box>
    )
  })

  const editingLastTask =
    tasks.length && (!tasks[tasks.length - 1].title || !tasks[tasks.length - 1].dueDate)
  const disabledAdd = editingLastTask || props.saving

  return (
    <div className={classes.formGroup}>
      <Typography className={classes.formGroupTitle}>Tasks</Typography>
      {taskEditorRows}
      <IconButton
        disabled={disabledAdd}
        onClick={props.addTask}
        className={classes.formActionBottom}
      >
        <AddIcon />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  formActionBottom: {
    padding: theme.spacing(0.5),
  },
  formGroup: {
    marginTop: theme.spacing(3),
  },
  formGroupTitle: {
    marginBottom: theme.spacing(),
  },
}))

export default TasksForm
