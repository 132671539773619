import axios from 'axios'
import { APIClient } from '~/legacy/core'
import * as qs from 'query-string'
import type { CustomParamsSerializer } from 'axios'

import { throttleRequestInterceptor } from './interceptors'

const url = process.env.REACT_APP_BASE_URL || ''
const getToken = async () => sessionStorage.getItem('sessionToken')
const log = (message, params = '', error) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      '%cAPI',
      `font-size: 9px;background-color: ${
        error ? 'red' : 'green'
      };color: white;padding: 0.35em 0.5em 0.2em;border-radius: 0.25em;`,
      message,
      params
    )
  }
}

export const apiClient = new APIClient({
  url,
  getToken,
  log,
})

export const apiClientWithoutRequestDecamelization = new APIClient({
  url,
  getToken,
  log,
  skipDecamelization: true,
})

/**
 * A helper when you want to serialize some specific parameter keys as JSON but not the rest.
 * This can be a useful pattern, for example, for things like a paginated list with user-selectable filters.
 * Send all the normal pagination params as regular form-encoded parameters, but JSON serialize `filters` to
 * be deserialized on the backend.
 * @param keyOrKeysToJsonify The keys in the parameter object whose values should be JSON serialized (if present)
 * @returns A serializer function you can pass to Axios' `paramsSerializer` option
 */
export function partialJsonParamSerializer(...keysToJsonify: string[]): CustomParamsSerializer {
  return function partialJsonParamSerializerInner(obj) {
    const withJson = { ...obj }

    keysToJsonify.forEach(key => {
      if (obj[key]) {
        withJson[key] = JSON.stringify(obj[key])
      }
    })

    return qs.stringify(withJson, { encode: true })
  }
}

/**
 * Add interceptors to all axios clients
 */
axios.interceptors.request.use(throttleRequestInterceptor)
apiClient.rest.http.interceptors.request.use(throttleRequestInterceptor)
apiClientWithoutRequestDecamelization.rest.http.interceptors.request.use(throttleRequestInterceptor)
