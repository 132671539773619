import type { FC } from 'react'
import { Link } from 'react-router-dom'
import { GridCellParams } from '@mui/x-data-grid-pro'
import { Box, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { Edit, Face } from '@material-ui/icons'

import elationSVG from '~/assets/images/elationSVG.svg'
import contentful from '~/assets/images/contentful.svg'

interface WorklistLinksProps extends GridCellParams {
  onEditTask: () => void
  showEditLink?: boolean
}

export const WorklistLinks: FC<WorklistLinksProps> = props => {
  const classes = useStyles()

  // If there's an elation url, show elation url
  const linksToElation = !!props.row.elationUrl
  // If there's a link to a Case, show link
  const linksToCase = !!props.row.links?.caseUrl
  // If there's a patient id, show patient detail links,
  // unless we're already showing the link to the specific Case
  const linksToPatient = !linksToCase && !!props.row.patientId
  // If there's a link to Contentful, show link
  const linksToContentful = !!props.row.links?.contentUrl

  return (
    <Box display="flex" alignItems="center" color="navy.500">
      {linksToElation ? (
        <Tooltip title="Open in Elation">
          <IconButton
            edge="end"
            component="a"
            href={props.row.elationUrl}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <img src={elationSVG} alt="Open in Elation" className={classes.icon} />
          </IconButton>
        </Tooltip>
      ) : null}
      {linksToPatient ? (
        <Tooltip title="Open Patient">
          <IconButton
            edge="end"
            component={Link}
            to={`/patients/${props.row.patientId}`}
            color="inherit"
          >
            <Face />
          </IconButton>
        </Tooltip>
      ) : null}
      {linksToCase ? (
        <Tooltip title="Open Case">
          <IconButton edge="end" component={Link} to={props.row.links?.caseUrl} color="inherit">
            <Face />
          </IconButton>
        </Tooltip>
      ) : null}
      {linksToContentful ? (
        <Tooltip title="Open Contentful">
          <IconButton
            edge="end"
            component="a"
            href={props.row.links.contentUrl}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <img src={contentful} alt="Open Contentful" className={classes.icon} />
          </IconButton>
        </Tooltip>
      ) : null}
      {!(props.showEditLink === false) && (
        <Tooltip title="Edit Task">
          <IconButton edge="end" onClick={props.onEditTask} color="inherit">
            <Edit titleAccess="Edit Task" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  icon: {
    width: '2.4rem',
    maxHeight: '2.4rem',
  },
}))

export default WorklistLinks
