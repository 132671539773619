import {
  event_network_quality_change,
  event_user_update,
  event_video_statistic_data_change,
} from '@zoom/videosdk'
import { useEffect, useRef, useState } from 'react'
import { logger } from '~/utils/logger'

interface ZoomEventInformation {
  description: string
  advice?: string
  documentationLink?: string
}

type EventInformationKey =
  | 'user-updated-muted'
  | 'user-updated-poor-network-member'
  | 'user-updated-poor-network-self'
  | 'user-updated-no-audio'
  | 'no-video-send-member'
type InformationMap = { [key in EventInformationKey]: ZoomEventInformation }

const ZoomEventNameToZoomEventInformation: InformationMap = {
  'user-updated-muted': {
    description: 'Member muted',
  },
  'user-updated-no-audio': {
    description: 'Member disconnected audio',
    advice: 'Device permissions or Bluetooth connection may be the issue',
  },
  'user-updated-poor-network-member': {
    description: 'The member has a poor network connection',
    advice: 'See if the member is able to connect using WiFi, or able to move to another location',
    documentationLink: 'https://developers.zoom.us/docs/video-sdk/web/quality/#network-quality',
  },
  'user-updated-poor-network-self': {
    description: 'You have a poor network connection',
    advice: 'Try connecting using WiFi or moving to another location',
    documentationLink: 'https://developers.zoom.us/docs/video-sdk/web/quality/#network-quality',
  },
  'no-video-send-member': {
    description: "The member's video is not connected",
    advice:
      'Is the camera allowed in device settings? Is another application also using the camera? Try having the member re-join the meeting or check their hardware (e.g. physical webcam connections)',
    documentationLink: 'https://developers.zoom.us/docs/video-sdk/web/quality/#video-quality',
  },
}

export interface HelpEvent {
  information: ZoomEventInformation
  eventTime: number
}

/**
 * Manages provider-facing help for Zoom client events, e.g. the member being muted or having a poor connection
 */
export const useZoomEventHelp = ({ currentUserId }) => {
  const [helpEvents, setHelpEvents] = useState<HelpEvent[]>([])
  const [newHelpEvent, setNewHelpEvent] = useState(false)
  const newEventTimer = useRef<NodeJS.Timeout | null>(null)
  // A special case:
  // We might get multiple 'video-statistic-data-change' events from different samples
  // If the next sample doesn't represent a functional change, we don't need to add a new Help Event
  // Reset this once we learn the the member *is* sending video, so that we can track any next failure
  const [noVideoSendMemberEvent, setNoVideoSendMemberEvent] = useState(false)

  const addNewEventInfo = (informationKey: EventInformationKey) => {
    const newHelpEvents = helpEvents
    if (newHelpEvents.length > 2) {
      newHelpEvents.splice(-1)
    }
    const information = ZoomEventNameToZoomEventInformation[informationKey]
    const newHelpEvent: HelpEvent = { information: information, eventTime: Date.now() }
    newHelpEvents.unshift(newHelpEvent)
    setHelpEvents(newHelpEvents)
    if (newEventTimer.current) {
      clearTimeout(newEventTimer.current)
    }
    setNewHelpEvent(true)
    newEventTimer.current = setTimeout(() => setNewHelpEvent(false), 5000)
    logger.info('Showed new Help Event', newHelpEvent)
  }

  useEffect(() => {
    if (noVideoSendMemberEvent) {
      addNewEventInfo('no-video-send-member')
    }
  }, [noVideoSendMemberEvent])

  const handleEvent = function ({ eventName, eventPayload }) {
    // No current user ID yet? Call is just initializing and we can't tell which user events correspond to
    if (!currentUserId) return null
    switch (eventName) {
      case 'user-updated': {
        const typedPayload = eventPayload as Parameters<typeof event_user_update>[0]
        if (!typedPayload[0].userId) return null
        if (currentUserId != typedPayload[0].userId) {
          if (typedPayload[0].muted) {
            addNewEventInfo('user-updated-muted')
          }
          if (typedPayload[0].audio == '') {
            addNewEventInfo('user-updated-no-audio')
          }
        }
        break
      }
      case 'network-quality-change': {
        const typedPayload = eventPayload as Parameters<typeof event_network_quality_change>[0]
        if (typedPayload.level < 2) {
          if (currentUserId != typedPayload.userId) {
            addNewEventInfo('user-updated-poor-network-member')
          } else {
            addNewEventInfo('user-updated-poor-network-self')
          }
        }
        break
      }
      case 'video-statistic-data-change': {
        const typedPayload = eventPayload as Parameters<typeof event_video_statistic_data_change>[0]
        if (typedPayload.data.encoding == false) {
          // These are metrics for received video
          // https://developers.zoom.us/docs/video-sdk/web/quality/#video-quality
          if (typedPayload.data.fps == 0) {
            setNoVideoSendMemberEvent(true)
          } else if (typedPayload.data.fps > 0) {
            setNoVideoSendMemberEvent(false)
          }
        }
        break
      }
    }
  }

  return { handleEvent, helpEvents, newHelpEvent }
}
