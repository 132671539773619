import type { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TreeView } from '@material-ui/lab'
import { Worklist } from '~/redux/slices/worklists'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import groupBy from 'lodash/fp/groupBy'
import { Box, Typography } from '@material-ui/core'
import { WorklistSubCategory } from './WorklistTreeSubCategory'
interface WorklistCategoryProps {
  categoryItems: Worklist[]
  category: string
  expandingNodes: string[]
  setExpandingNodes: (string) => void
  selectWorklist: (number) => void
  worklistId: number | undefined
}

export const WorklistCategory: FC<WorklistCategoryProps> = (props: WorklistCategoryProps) => {
  const styles = useStyles()
  const subCategories = groupBy('subCategory', props.categoryItems)
  return (
    <div key={props.category}>
      <Box ml={2} mt={2} color="grey.500">
        <Typography variant="subtitle1" className={styles.category}>
          {props.category}
        </Typography>
      </Box>
      {
        <TreeView
          className={styles.treeViewRoot}
          expanded={props.expandingNodes}
          onNodeToggle={(event, nodes) => {
            props.setExpandingNodes(nodes)
          }}
          defaultExpandIcon={<ArrowRightIcon fontSize="small" />}
          defaultCollapseIcon={<ArrowDropDownIcon fontSize="small" />}
        >
          {Object.keys(subCategories)
            .sort()
            .map(subCategory => {
              return (
                <WorklistSubCategory
                  key={subCategory}
                  subCategoryItems={subCategories[subCategory]}
                  category={props.category}
                  subCategory={subCategory}
                  selectWorklist={props.selectWorklist}
                  worklistId={props.worklistId}
                ></WorklistSubCategory>
              )
            })}
        </TreeView>
      }
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  category: {
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  treeViewRoot: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(2),
  },
}))
export default WorklistCategory
