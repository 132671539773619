import { useCallback } from 'react'

import { usePromiseState, PromiseStateConfig, DEFAULT_PROMISE_CONFIG } from './usePromiseState'

export function useMutation<P extends any[], S, E = any>(
  getPromise: (...args: P) => Promise<S>,
  config: PromiseStateConfig = DEFAULT_PROMISE_CONFIG
) {
  const { error, setError, loading, setLoading, result, setResult } = usePromiseState<S, E>(config)

  const handler = useCallback(
    async (...args: P) => {
      try {
        setLoading(true)
        setError(null)
        const data = await getPromise(...args)
        setResult(data)
        return data
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    },
    [getPromise]
  )

  return { error, handler, loading, result }
}
