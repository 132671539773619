import React, { useState } from 'react'
import {
  Avatar,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Box,
  Paper,
  Badge,
  styled,
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import {
  CallEnd,
  KeyboardArrowUp,
  Mic,
  MicOff,
  Support,
  Videocam,
  VideocamOff,
  Minimize as MinimizeIcon,
} from '@mui/icons-material'

import { useZoomSession } from './SessionProvider'
import { useHardwareMenusEls } from './utils'
import { useZoomEventHelp } from './zoomEventInformation'
import theme from '~/utils/theme'

interface SessionToolbarProps {
  onChangeCamera?: (deviceId: string) => void
  onChangeMicrophone?: (deviceId: string) => void
  onChangeSpeaker?: (deviceId: string) => void
  onLeaveCall?: () => void
  onToggleCamera: (isOn: boolean) => void
  onToggleMicrophone: (isMuted: boolean) => void
  helpEvents: ReturnType<typeof useZoomEventHelp>['helpEvents']
  newHelpEvent: boolean
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.secondary[500],
    color: theme.palette.secondary[500],
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

export const SessionToolbar: React.FC<SessionToolbarProps> = props => {
  const {
    activeCamera,
    activeMicrophone,
    activeSpeaker,
    cameras,
    isMuted,
    isVideoOn,
    microphones,
    screen,
    setActiveCameraId,
    setActiveMicrophoneId,
    setActiveSpeakerId,
    speakers,
  } = useZoomSession()

  /**
   * Hardware menus
   */
  const { handleCloseAll, handleOpenAudio, handleOpenVideo, videoMenuProps, audioMenuProps } =
    useHardwareMenusEls()
  const [showHelp, setShowHelp] = useState(false)

  const ACTION_MAP = [
    {
      Icon: CallEnd,
      action: props.onLeaveCall,
      style: {
        backgroundColor: '#FFF',
        color: red['500'],
      },
    },
    {
      Icon: isMuted ? MicOff : Mic,
      action: () => props.onToggleMicrophone(isMuted),
      menuAction: microphones.length ? handleOpenAudio : undefined,
      style: {
        backgroundColor: isMuted ? red['500'] : '#FFF',
        color: isMuted ? '#FFF' : grey['500'],
      },
    },
    {
      Icon: isVideoOn ? Videocam : VideocamOff,
      action: () => {
        props.onToggleCamera(isVideoOn)
      },
      menuAction: cameras.length ? handleOpenVideo : undefined,
      style: {
        backgroundColor: isVideoOn ? '#FFF' : red['500'],
        color: isVideoOn ? grey['500'] : '#FFF',
      },
    },
    {
      Icon: Support,
      action: () => {
        if (!props.helpEvents?.length) {
          return
        }
        setShowHelp(current => !current)
      },
      style: {
        backgroundColor: '#FFF',
        color: props.helpEvents?.length > 0 ? theme.palette.brand.main : grey['500'],
      },
      showBadge: props.newHelpEvent,
      dataTestID: 'zoom-help-open',
    },
  ]

  // If during pre-call, remove the first element (leave call button)
  if (screen === 'precall') {
    ACTION_MAP.shift()
  }
  return (
    <Box position="absolute" zIndex={100} bottom={0} width="100%">
      <Box display="flex" justifyContent="center" alignItems="center">
        {ACTION_MAP.map(({ action, Icon, style, menuAction, showBadge, dataTestID }, i) => {
          return (
            <Box key={i} mx={1} mb={2}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                badgeContent={showBadge ? 1 : 0}
                showZero={false}
              >
                <Avatar
                  sx={theme => ({
                    boxShadow: theme.shadows[3],
                    width: 'unset',
                    background: 'rgba(255,255,255,0.5)',
                    borderRadius: '1em',
                  })}
                  data-testid={dataTestID}
                >
                  <IconButton
                    sx={theme => ({ boxShadow: theme.shadows[3] })}
                    size="medium"
                    onClick={action}
                    style={style}
                  >
                    <Icon width={50} height={50} />
                  </IconButton>
                  {menuAction && screen === 'call' ? (
                    <IconButton onClick={menuAction}>
                      <KeyboardArrowUp />
                    </IconButton>
                  ) : null}
                </Avatar>
              </StyledBadge>
            </Box>
          )
        })}
        <Menu {...audioMenuProps}>
          <ListItem>Microphones</ListItem>
          <Divider />
          {microphones.map(mic => (
            <MenuItem
              selected={activeMicrophone?.deviceId === mic.deviceId}
              key={mic.deviceId}
              onClick={async () => {
                setActiveMicrophoneId(mic.deviceId)
                props.onChangeMicrophone?.(mic.deviceId)
                handleCloseAll()
              }}
            >
              {mic.label}
            </MenuItem>
          ))}
          {microphones.length ? (
            <div>
              <ListItem>Speakers</ListItem>
              <Divider />
              {speakers.map(speaker => (
                <MenuItem
                  selected={activeSpeaker?.deviceId === speaker.deviceId}
                  key={speaker.deviceId}
                  onClick={() => {
                    setActiveSpeakerId(speaker.deviceId)
                    props.onChangeSpeaker?.(speaker.deviceId)
                    handleCloseAll()
                  }}
                >
                  {speaker.label}
                </MenuItem>
              ))}
            </div>
          ) : null}
        </Menu>
        <Menu {...videoMenuProps}>
          <ListItem>Cameras</ListItem>
          <Divider />
          {cameras.map(camera => (
            <MenuItem
              selected={activeCamera?.deviceId === camera.deviceId}
              key={camera.deviceId}
              onClick={() => {
                setActiveCameraId(camera.deviceId)
                props.onChangeCamera?.(camera.deviceId)
                handleCloseAll()
              }}
            >
              {camera.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {showHelp ? (
        <Paper>
          <Box display="flex" flexDirection="row-reverse">
            <IconButton
              color="inherit"
              size="small"
              title="Minimize"
              onClick={() => setShowHelp(false)}
            >
              <MinimizeIcon color="inherit" fontSize="inherit" />
            </IconButton>
          </Box>
          <List>
            {props.helpEvents.map((helpEvent, i) => (
              <ListItem key={i}>
                <ListItemText
                  sx={{ opacity: i == 0 ? 1 : 0.5 }}
                  primary={
                    <Box flex={1} flexDirection="row">
                      <Typography display="inline-block" fontSize="small">
                        {helpEvent.information.description}
                      </Typography>
                      <Typography display="inline-block" fontSize="small">
                        &nbsp;-&nbsp;{new Date(helpEvent.eventTime).toLocaleTimeString()}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box display="flex" flexDirection="row">
                      <Typography variant="body1" fontSize="small" display="inline-block">
                        {helpEvent.information.advice}
                      </Typography>

                      {helpEvent.information.documentationLink ? (
                        <Typography
                          display="inline-block"
                          fontSize="small"
                          sx={{ flexShrink: 0 }}
                          marginLeft={1}
                        >
                          <Link href={helpEvent.information.documentationLink} target="_blank">
                            More info
                          </Link>
                        </Typography>
                      ) : null}
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      ) : null}
    </Box>
  )
}
