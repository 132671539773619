import type { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { Avatar, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { getUserInitials, getUserName } from '~/utils'

import ArrowTooltip from './ArrowTooltip'

interface UserAvatarProps {
  id: number
  size?: 'small' | 'large'
  src?: string

  // FIXME: I'd love to not allow this, but currently we hardcode the margin to 1rem and expect that in a bunch of places.
  // We then override it back to 0 in (so far) TasksTableSingle.tsx
  avatarStyle?: Pick<React.CSSProperties, 'margin' | 'display' | 'order'>
}

export const UserAvatar: FC<UserAvatarProps> = props => {
  const { id, size = 'large', src, avatarStyle } = props

  const classes = useStyles()

  const me = useSelector(state => state.me)
  const user = useSelector(state => {
    return state.users.byId[id]
  })

  if (!user) {
    return null
  }

  const userInitials = getUserInitials(user)
  const userName = getUserName(user)

  let userTypeClass = classes.other
  if (id === me.id) {
    userTypeClass = classes.me
  } else if (user.isProvider) {
    userTypeClass = classes.provider
  } else if (user.isPatient) {
    userTypeClass = classes.patient
  }

  return (
    <ArrowTooltip title={userName}>
      <Avatar
        className={classNames(classes.avatar, classes[size], userTypeClass)}
        style={avatarStyle}
        src={src}
      >
        {userInitials}
      </Avatar>
    </ArrowTooltip>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    textTransform: 'uppercase',
    margin: '1rem',
  },
  small: {
    fontSize: '1rem',
    width: '2.5rem',
    height: '2.5rem',
  },
  large: {
    fontSize: '1.5rem',
    width: '4.5rem',
    height: '4.5rem',
  },
  patient: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  provider: {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.text.primary,
  },
  me: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  other: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary[300]}`,
    color: theme.palette.secondary[400],
  },
}))

export default UserAvatar
