import { ITag } from '~/models/Case'
import CaseTag from './CaseTag'
import { FC } from 'react'
import { Box } from '@mui/material'

const MemberFacingCaseAlert: FC<{
  key: string
  style?: React.CSSProperties
}> = props => {
  // Tags are associated with cases
  // However some case categories can also have the isMemberFacing attribute set
  // this attribute signifies that any cases created within the category
  // will have the member facing tag auto applied to it
  // Create a fake tag
  const fakeMemberFacingTag: ITag = { id: 1, displayName: 'Member-Facing' }
  return (
    <Box>
      <CaseTag tag={fakeMemberFacingTag} key={props.key}></CaseTag>
    </Box>
  )
}

export default MemberFacingCaseAlert
