import type { FC } from 'react'
import Moment from 'moment'
import { makeStyles } from '@material-ui/core'
import { red, yellow } from '@material-ui/core/colors'

const useStyles = makeStyles((theme: any) => ({
  dueStyle: {
    fontWeight: 800,
    paddingRight: '.5em',
  },
  taskOverDue: {
    color: red[400],
  },
  taskDueSoon: {
    color: yellow[700],
  },
}))

// const dueStyle = { fontWeight: 800, paddingRight: '.5em' }

interface TaskDueDateProps {
  dueDate: string | Date
  isComplete: boolean
  withoutDueText?: boolean // TODO: Remove this property and make default behavior false, once we remove showing tasks from chat thread.
}

export const TaskDueDate: FC<TaskDueDateProps> = (props: TaskDueDateProps) => {
  // Given a due date and task completion, returns a due date label
  const formatted = Moment(props.dueDate).format('ddd, MMM. D, Y')
  const diff = Moment(props.dueDate).startOf('day').diff(Moment().startOf('day'), 'days')
  const isToday = Moment(props.dueDate).isSame(Moment(), 'day')
  const classes = useStyles()
  const dueText = props.withoutDueText ? '' : 'Due'
  if (diff < -1)
    return (
      <span {...(!props.isComplete && { className: `${classes.taskOverDue}` })}>
        <span className={classes.dueStyle}>{dueText}</span>
        {`${formatted}`}
      </span>
    )
  if (diff === -1)
    return (
      <span {...(!props.isComplete && { className: `${classes.taskOverDue}` })}>
        <span className={classes.dueStyle}>{dueText}</span>Yesterday
      </span>
    )
  if (isToday)
    return (
      <span {...(!props.isComplete && { className: `${classes.taskDueSoon}` })}>
        <span className={classes.dueStyle}>{dueText}</span>Today
      </span>
    )
  if (diff === 1)
    return (
      <span>
        <span className={classes.dueStyle}>{dueText}</span>Tomorrow
      </span>
    )
  // if (diff > 1)
  return (
    <span>
      <span className={classes.dueStyle}>{dueText}</span>
      {`${formatted}`}
    </span>
  )
}

export default TaskDueDate
