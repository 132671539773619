import {
  parse,
  format,
  addSeconds,
  isToday as dateFnIsToday,
  endOfToday,
  startOfToday,
  isBefore,
  isAfter,
} from 'date-fns'
import { Appointment } from '~/legacy/core'

// Converts hh:mm:ss timestring to seconds
export const getSeconds = (timeString: string) => {
  const [hours = 0, minutes = 0, seconds = 0] = timeString.split(':').map(Number)
  return hours * 60 * 60 + minutes * 60 + seconds
}

export const getDateLabel = (appointment: Appointment) => {
  const date = parse(appointment.start)
  const day = format(date, 'MMMM Do, YYYY')
  const start = format(date, 'h:mmA')
  const end = format(addSeconds(date, getSeconds(appointment.duration)), 'h:mmA')
  return `${day} ⋅ ${start} – ${end}`
}

export const isToday = (appointment: Appointment) => {
  return dateFnIsToday(parse(appointment.start))
}

export const isAfterToday = (appointment: Appointment) => {
  return isAfter(parse(appointment.start), endOfToday())
}

export const isBeforeToday = (appointment: Appointment) => {
  return isBefore(parse(appointment.start), startOfToday())
}

export const getDateTime = (date: Date, now: Date) => {
  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    return new Date()
  } else {
    return date
  }
}
