import ReactLinkify from 'react-linkify'

// Re-export with support for opening in new tab
// https://github.com/tasti/react-linkify/issues/96
const Linkify: React.FC = props => (
  <ReactLinkify
    componentDecorator={(decoratedHref, decoratedText, key) => (
      <a target="blank" href={decoratedHref} key={key}>
        {decoratedText}
      </a>
    )}
    {...props}
  >
    {props.children}
  </ReactLinkify>
)

export default Linkify
