// Component which allows enrolling / unenrolling a patient to / from a program
// Can also show program-specific details

import type { FC } from 'react'
import { Box, Switch } from '@mui/material'
import usePatientDetail from '~/components/PatientDetail/utils/usePatientDetail'
import EditPrimaryCareProgram from './EditPatient/EditPrimaryCareProgram'
import CaptureProgramUnEnrollReason from './EditPatient/CaptureProgramUnenrollReason'
import DisplayBenefitProgramCard from './EditPatient/DisplayBenefitProgramCard'
import { useState } from 'react'
const EXTRA_CONTENT = {
  PrimaryCare: EditPrimaryCareProgram,
  Benefit: DisplayBenefitProgramCard,
}
interface ProgramData {
  uid: string
  displayName: string
}
const CAPTURE_UNENROLLMENT_REASON = {
  DepressionAndAnxiety: true,
  Hypertension: true,
  Diabetes: true,
  AdhdAdd: true,
}

interface ProgramSwitchProps {
  patient: any
  onToggle: (enrolled: boolean, programUid: string) => any
  program: ProgramData
  isLoading: boolean
}

const ProgramSwitch: FC<ProgramSwitchProps> = ({ patient, program, onToggle, isLoading }) => {
  const { patientIsInProgram } = usePatientDetail()

  const _onToggle = event => {
    if (!event.target.checked && CAPTURE_UNENROLLMENT_REASON[program.uid]) {
      setShowReasonComponent(true)
      return
    }
    onToggle(event.target.checked, program.uid)
  }

  const [showReasonComponent, setShowReasonComponent] = useState(false)
  const patientIsEnrolled = patientIsInProgram(program.uid)
  const extraContentProps = { patient }
  let extraContextComponent: null | any = null
  const showExtraContent = patientIsEnrolled && !!EXTRA_CONTENT[program.uid]
  let reasonComponent: null | any = null

  if (showExtraContent) {
    const Component = EXTRA_CONTENT[program.uid]
    extraContextComponent = (
      <Box ml={1} style={{ borderLeft: '5px solid lightgray' }}>
        <Component {...extraContentProps} />
      </Box>
    )
  }

  if (CAPTURE_UNENROLLMENT_REASON[program.uid]) {
    reasonComponent = (
      <Box ml={1} style={{ borderLeft: '5px solid lightgray' }}>
        <CaptureProgramUnEnrollReason
          patientId={patient.id}
          programUid={program.uid}
          onToggle={onToggle}
          setShowReasonComponent={setShowReasonComponent}
        />
      </Box>
    )
  }

  return (
    <Box m={2}>
      <Switch
        onChange={_onToggle}
        edge="start"
        checked={patientIsEnrolled}
        color="primary"
        disabled={isLoading}
      />
      {program.displayName}
      {extraContextComponent}
      {showReasonComponent ? reasonComponent : ''}
    </Box>
  )
}

export default ProgramSwitch
