import { createTheme } from '@mui/material/styles'

import { BLUE, NAVY, YELLOW, GREY } from '~/utils/theme'

export const DEFAULT_THEME = createTheme()

// Material UI customized theme and overrides
export const mui5theme = createTheme({
  typography: {
    // Using index.css to set the <html> font size to 62.5%, resulting in a 10px font-size in most circumstances
    // MUI takes the htmlFontSize property to properly calculate rems
    htmlFontSize: 10,
    body1: {
      fontSize: '1.6rem',
    },
    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, "sans-serif"',
  },
  palette: {
    background: {
      default: '#fff',
      highlight: YELLOW[50],
      external: '#f0e8fc', // Highlights external-facing information
    },
    primary: BLUE,
    secondary: GREY,
    grey: GREY,
    text: {
      primary: NAVY[700],
      secondary: GREY[500],
    },
    divider: GREY[100],
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F3F3F3',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:disabled': {
            // MUI's default opacity for disabled button text is 26%. Increase
            // to 40% for better contrast with the background, but not so opaque
            // that it looks like an active button.
            color: `rgba(0, 0, 0, 0.4)`,
          },
        },
        contained: {
          boxShadow: 'none',
          animation: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderRadius: 24,
        },
        outlinedPrimary: {
          borderColor: BLUE[600],
          '&:hover': {
            borderColor: BLUE[900],
            backgroundColor: BLUE[50],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        clickable: {
          padding: 10,
          '&:hover, &:focus': {
            backgroundColor: GREY[200],
          },
        },
        colorPrimary: {
          backgroundColor: BLUE[50],
          color: BLUE[600],
        },
        clickableColorPrimary: {
          '&:hover, &:focus': {
            backgroundColor: BLUE[100],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&$checked': {
            color: BLUE[500],
          },
          '&$checked + $track': {
            backgroundColor: BLUE[200],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:hover': {
            color: BLUE[500],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `0.1rem solid ${GREY[100]}`,
        },
        scrollButtons: {
          '&.Mui-disabled': {
            display: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.4rem',
        },
      },
    },
  },
})
