import { CSSObject } from 'tss-react'

export const styles: { [key: string]: (theme) => CSSObject } = {
  message: theme => ({
    marginBottom: theme.spacing(2),
  }),
  messageDate: _theme => ({
    width: '100%',
    marginBottom: '0.5rem',
    fontSize: '1.2rem',
    textAlign: 'center',
  }),
}
