import { Fragment, useEffect } from 'react'
import Moment from 'moment'
import { useMutation } from '~/legacy/core'
import { apiClient } from '~/api/rest'
import { useSelector } from 'react-redux'
import { keyBy } from 'lodash/fp'
import { Box, Typography, List } from '@mui/material'
import InlineLoader from '~/components/InlineLoader'
import PushNotificationListItem from './PushNotificationListItem'
import DirectMailerListItem from './DirectMailerListItem'

// For now, this only shows push notification events
const PatientDetailTimeline = props => {
  const providers = useSelector(state => state.providers)
  const providersById = keyBy('id', providers)

  const getProviderName = providerId => {
    let providerName = 'System'
    const provider = providerId ? providersById[providerId] : null
    if (provider) {
      providerName = `${provider.firstName} ${provider.lastName}`
    }
    return providerName
  }

  const getTimestampDate = timestamp => Moment.unix(timestamp)

  const {
    handler: loadEvents,
    loading,
    result: events,
  } = useMutation(async () => {
    try {
      return apiClient.rest.get(
        `/events/logs/?user=${props.patient.id}&type=communication_sent,mailer_status_change`
      )
    } catch (e) {
      console.error(e)
    }
  })

  useEffect(() => {
    loadEvents()
  }, [])

  if (loading || events == null) {
    return <InlineLoader />
  }

  const listItemContents = event => {
    if (
      event.metadata?.communicationType &&
      event.metadata.communicationType === 'push_notification'
    ) {
      return (
        <PushNotificationListItem
          event={event}
          providerName={getProviderName(event.metadata.sentByProviderId)}
          timestampDate={getTimestampDate(event.metadata.timestamp)}
        />
      )
    } else if (event.type === 'mailer_status_change') {
      return (
        <DirectMailerListItem
          event={event}
          providerName={getProviderName(event.metadata.updatedBy)}
          timestampDate={getTimestampDate(event.metadata.timestamp)}
        />
      )
    } else {
      return <></>
    }
  }

  if (events) {
    return (
      <Box p={3} pb={0} data-testid="patient-timeline-events">
        <Typography>
          <strong>Patient Timeline</strong>
          {` (${events.length})`}
        </Typography>
        <Box>
          <List>
            {events.map((event: any, i: number) => (
              <Fragment key={i}>{listItemContents(event)}</Fragment>
            ))}
          </List>
        </Box>
      </Box>
    )
  }
}

export default PatientDetailTimeline
