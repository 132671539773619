import { useEffect, useRef } from 'react'
import type { FC } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import { useAuth0 } from '@auth0/auth0-react'
import { getSessionToken, setIdToken, setSessionToken, useLogoutEffects } from '~/utils/auth'
import { queueNotification } from '~/redux/actions/notifications'
import jwt_decode from 'jwt-decode'
import { logger } from '~/utils/logger'

export const TIMEOUT_NOTIFICATION_KEY = 'TIMEOUT_NOTIFICATION_KEY'
const TOKEN_TTL = 10790000
/**
 * Listens to all on-screen activity, including mouse and keyboard events.
 * Events will trigger a token refresh and reset the timer, but when it expires,
 * the logged in user will be logged out and their token removed from storage.
 * They will be shown a "session expired" message that will persist until they
 * log in again.
 */
export const SessionNotifier: FC = () => {
  const timeoutIdRef = useRef<NodeJS.Timeout>()
  const auth0 = useAuth0()
  const timerRef = useRef<IdleTimer>(null)
  const dispatch = useDispatch()
  const { handler: handleLogout } = useLogoutEffects({ auth0: { localOnly: true } })
  const currentlyOnVideo = !!useSelector(state => state.appointments.video?.appointmentId)
  const userId = useSelector(state => state.me.id)

  const onIdleTimeout = async () => {
    logger.debug('[Session] onIdleTimeout for User', userId, 'currentlyOnVideo', currentlyOnVideo)

    // If there is an active visit, keep the session alive so that we don't force a logout for a clinician
    if (currentlyOnVideo) {
      refreshToken()
      return
    }

    forceLogout()
  }

  const forceLogout = () => {
    batch(() => {
      dispatch(
        queueNotification({
          message: 'Your session has expired',
          variant: 'info',
          persist: true,
          key: TIMEOUT_NOTIFICATION_KEY,
        })
      )
      handleLogout()
    })
  }

  // verify token valid, if invalid force logout
  const onUserAction = async (opts?: { isRetry?: boolean }) => {
    const token = getSessionToken()
    const decodedToken: any = token ? jwt_decode(token) : null

    /**
     * This block should usually not execute because our timer should be refreshing token with 1 min buffer
     */
    if (decodedToken && decodedToken.exp < Math.floor(Date.now() / 1000) - 10) {
      logger.debug('[Session] decodedToken.exp for User', userId, decodedToken)
      if (!opts?.isRetry) {
        // The token is expired, but before forcing a logout, try to refresh the token
        // then, re-try once more to see if we should still be logged out (i.e. if the refresh token was also expired)
        logger.debug('[Session] refreshing token in onUserAction')
        await refreshToken()
        return onUserAction({ isRetry: true })
      } else {
        logger.debug('[Session] skipping attempt to refresh token in onUserAction', {
          isRetry: opts?.isRetry,
        })
      }
      forceLogout()
    }
  }

  /**
   * Refreshes the token every 1 minute before it is set to expire
   */
  const refreshToken = async () => {
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
    const token = getSessionToken()
    if (token) {
      try {
        // Refresh token if it's about to expire
        const decodedToken: any = jwt_decode(token)
        let remainingTimeInSeconds = decodedToken.exp - Math.floor(Date.now() / 1000)
        if (remainingTimeInSeconds <= 60) {
          const newToken = await auth0.getAccessTokenSilently({
            cacheMode: 'off',
          })
          const idToken = await (await auth0.getIdTokenClaims()).__raw
          setSessionToken(newToken)
          setIdToken(idToken)
          const newDecodedToken: any = jwt_decode(newToken)
          remainingTimeInSeconds = newDecodedToken.exp - Math.floor(Date.now() / 1000)
        }

        const timeBeforeNextRefreshInMilliseconds = (remainingTimeInSeconds - 60) * 1000
        timeoutIdRef.current = setTimeout(refreshToken, timeBeforeNextRefreshInMilliseconds)
      } catch (e) {
        logger.error('[Session] refreshToken failure for User', userId, e)
        forceLogout()
      }
    }
  }

  // Start token refresh loop
  useEffect(() => {
    refreshToken()
  }, [])

  return (
    <IdleTimer
      ref={timerRef}
      timeout={TOKEN_TTL}
      onIdle={() => onIdleTimeout()}
      onAction={() => onUserAction()}
      throttle={10000}
    />
  )
}

export default SessionNotifier
