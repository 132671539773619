import React from 'react'
import ZusSessionProvider from '~/components/Providers/ZusSessionProvider'
import ZusAggregatedProfileEmbed from './ZusAggregatedProfileEmbed'
import { PatientDetailTabsProps } from '../PatientDetailTabs'

export interface Props extends PatientDetailTabsProps {}

const ZusAggregatedProfile: React.FC<Props> = props => {
  return (
    <ZusSessionProvider>
      <ZusAggregatedProfileEmbed {...props} />
    </ZusSessionProvider>
  )
}

export default ZusAggregatedProfile
