import { Box, Breadcrumbs, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { apiClient } from '~/api/rest'
import FormOverview from './FormOverview'
import { SimpleForm } from '~/legacy/core'

const formsResource = apiClient.resource<SimpleForm>('/form/')

interface SingleFormViewerParams {
  formId: string
}

const SingleFormViewer = () => {
  const { formId } = useParams<SingleFormViewerParams>()

  const [form, setForm] = useState<any>(null)

  useEffect(() => {
    const fetchForm = async () => {
      if (!formId) return
      const data = await formsResource.get(formId)
      setForm(data)
    }
    fetchForm()
  }, [formId])

  return form ? (
    <>
      <Box display="flex" alignItems="center">
        <Breadcrumbs style={{ flex: 1 }}>
          <Link to={`/settings/form-viewer`}>All Forms</Link>
          <Typography>{form.title}</Typography>
        </Breadcrumbs>
      </Box>

      <FormOverview form={form} />
    </>
  ) : null
}

export default SingleFormViewer
