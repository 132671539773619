import type { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TreeItem } from '@material-ui/lab'
import { Worklist } from '~/redux/slices/worklists'
import { WorklistName } from './WorklistTreeName'
import { useFocusWorklist } from '~/components/Education/utils'
interface WorklistSubCategoryProps {
  subCategoryItems: Worklist[]
  category: string
  subCategory: string
  selectWorklist: (number) => void
  worklistId: number | undefined
}

export const WorklistSubCategory: FC<WorklistSubCategoryProps> = (
  props: WorklistSubCategoryProps
) => {
  const styles = useStyles()
  useFocusWorklist(props.worklistId?.toString())
  return (
    <TreeItem
      key={props.category + ' ' + props.subCategory}
      classes={{
        root: styles.treeItemRoot,
        label: styles.treeItemLabel,
      }}
      nodeId={props.category + ' ' + props.subCategory}
      label={props.subCategory}
    >
      {Object.values(props.subCategoryItems)
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        .map(worklist => (
          <WorklistName
            key={worklist.id}
            worklist={worklist}
            worklistId={props.worklistId}
            selectWorklist={props.selectWorklist}
          ></WorklistName>
        ))}
    </TreeItem>
  )
}
const useStyles = makeStyles(theme => ({
  treeItemRoot: {
    marginTop: theme.spacing(),
  },
  treeItemLabel: {
    fontWeight: 500,
  },
}))
export default WorklistSubCategory
