import { useMemo } from 'react'
import type { FC } from 'react'
import { Box } from '@mui/material'
import FilePresentIcon from '@mui/icons-material/FilePresent'

import { FileWithType } from './FileUpload'

interface FileUploadPreviewProps {
  file: FileWithType
  onClick: () => void
}

const TILE_SIZE = 45
const SIZE_PROPS = { height: TILE_SIZE, width: TILE_SIZE }

export const FileUploadPreview: FC<FileUploadPreviewProps> = props => {
  const preview = useMemo(() => URL.createObjectURL(props.file.data), [props.file.data])
  return (
    <Box
      borderRadius="5px"
      overflow="hidden"
      component="button"
      border={0}
      p={0}
      mr={1}
      onClick={props.onClick}
    >
      {props.file.type === 'image' ? (
        <Box
          {...SIZE_PROPS}
          style={{
            background: `url(${preview}) center no-repeat`,
            backgroundSize: 'cover',
          }}
        />
      ) : (
        <Box
          {...SIZE_PROPS}
          bgcolor="grey.400"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FilePresentIcon />
        </Box>
      )}
    </Box>
  )
}

export default FileUploadPreview
