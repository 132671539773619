import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useFormik } from 'formik'

import { useReasonForException } from '~/api/SteerageService'
import { extractLocationDetails, type ProviderDetailWithAdditionalData } from './ProviderListItem'

interface ProviderListItemReasonModalProps {
  providers: ProviderDetailWithAdditionalData[]
  isSystemSuggested?: boolean
  isProviderSearch: boolean | null
}
export interface ProviderListItemReasonModalHandle {
  chooseReason: () => Promise<string | null>
}

/**
 * This modal UI functions as a promise for a parent component to interact with.
 * Rather than managing state in the parent, the parent invokes a method on the ref
 * to resolve the promise and get the value from the modal.
 * TODO: Is this pattern useful for forms? Should it be broken out from this use case?
 *
 * Usage:
 * ```typescript
 * const modalRef = useRef<ProviderListItemReasonModalHandle>(null)
 * const handleChooseReason = async () => {
 *   const reason = await modalRef.current?.chooseReason()
 * }
 * ```
 */
export const ProviderListItemReasonModal = forwardRef<
  ProviderListItemReasonModalHandle,
  ProviderListItemReasonModalProps
>((props, ref) => {
  const [open, setOpen] = useState(false)
  const [modalPromise, setModalPromise] = useState<{
    resolve: (value: string | null) => void
  } | null>(null)

  const providers = props.providers ?? []
  const variant = props.isProviderSearch ? 'provider' : 'facility'
  const variantPlural = props.isProviderSearch ? 'providers' : 'facilities'

  let title = `Reason for selecting a non-recommended ${variant}`
  if (props.isSystemSuggested) {
    if (providers.length > 1) title = 'Reason for dismissing these suggestions'
    else title = 'Reason for dismissing this suggestion'
  }

  let subtitle = props.isProviderSearch ? 'Providers selected:' : 'Facilities selected:'
  if (props.isSystemSuggested) {
    if (providers.length > 1) subtitle = `Suggested ${variantPlural} selected:`
    else subtitle = `Suggested ${variant} selected:`
  }

  let helper = `Reason for selecting a non-recommended ${variant}`
  if (props.isSystemSuggested) {
    if (providers.length > 1) helper = 'Reason for dismissing these suggestions'
    else helper = 'Reason for dismissing this suggestion'
  }

  // There are no system suggested facilities so far, so let the providerSearch prop take priority
  const { data = [], isLoading } = useReasonForException(
    props.isProviderSearch ? (props.isSystemSuggested ? 'suggestion' : 'default') : 'facility'
  )

  const locationNames: string[] = providers.map(provider => {
    const [locationName] = extractLocationDetails(provider?.locations)
    return locationName
  })

  // Closes the modal and resolves the promise with a "cancel" state null value
  const handleCloseModal = useCallback(() => {
    if (modalPromise) modalPromise.resolve(null)
    setOpen(false)
  }, [modalPromise])

  // Closes the modal and resolves the promise with the "success" state and closes the modal
  const handleSave = useCallback(
    (exception: string) => {
      if (modalPromise) modalPromise.resolve(exception)
      setOpen(false)
    },
    [modalPromise]
  )

  // Opens the modal and returns a promise that resolves with the selected value
  const handleOpenModal = useCallback(() => {
    setOpen(true)
    return new Promise<string | null>(resolve => {
      setModalPromise({ resolve })
    })
  }, [])

  // Expose the chooseReason method to the parent component
  useImperativeHandle(ref, () => ({ chooseReason: handleOpenModal }))

  const formik = useFormik({
    initialValues: {
      exception: '',
    },
    onSubmit: values => {
      handleSave(values.exception)
    },
  })

  return (
    <Modal open={open} disableScrollLock hideBackdrop>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            width: '33%',
            boxShadow: 24,
            backgroundColor: theme => theme.palette.background.paper,
          }}
        >
          <form
            onSubmit={e => {
              formik.handleSubmit()
              // Prevent the page from refreshing
              e.preventDefault()
            }}
          >
            <Box sx={{ p: 3, position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  p: 1,
                }}
              >
                <IconButton onClick={handleCloseModal}>
                  <Close />
                </IconButton>
              </Box>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="subtitle2" my={2}>
                {subtitle}
              </Typography>
              {providers.map((provider, i) => {
                const isLast = i === providers.length - 1
                return (
                  <Box key={provider?.id} sx={{ mb: isLast ? 0 : 4 }}>
                    {providers?.length > 1 ? (
                      <Typography variant="caption" sx={{ lineHeight: 1 }} color="GrayText">
                        Suggested Provider {i + 1}
                      </Typography>
                    ) : null}
                    <Typography variant="subtitle1" fontWeight="500">
                      {provider?.firstName} {provider?.lastName}
                    </Typography>
                    <Typography variant="subtitle2" fontWeight="normal">
                      {locationNames[i]}
                    </Typography>
                  </Box>
                )
              })}
              <FormControl fullWidth sx={{ mt: 4 }}>
                <InputLabel required sx={{ ml: -2 }}>
                  {helper}
                </InputLabel>
                <Select
                  name="exception"
                  variant="standard"
                  fullWidth
                  required
                  disabled={isLoading}
                  value={formik.values.exception}
                  onChange={e => {
                    formik.handleChange({
                      target: {
                        name: 'exception',
                        value: e.target.value,
                      } as any,
                    } as React.ChangeEvent)
                  }}
                >
                  {data.map(reason => (
                    <MenuItem key={reason[0]} value={reason[0]}>
                      {reason[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}
              >
                <Button
                  disabled={isLoading}
                  onClick={handleCloseModal}
                  sx={{ mr: 1 }}
                  variant="text"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained">
                  Save and Continue
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
})

ProviderListItemReasonModal.displayName = 'ProviderListItemReasonModal'
