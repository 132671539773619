import { FC, useState } from 'react'
import { Appointment } from '../PatientDetail/PatientDetailAppointments'
import FFModal from '../FFModal/FFModal'
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import * as AppointmentService from '~/api/AppointmentService'

interface AppointmentFeedbackProps {
  appointment: Appointment
  onClose: () => void
}

export const AppointmentVisitFeedback: FC<AppointmentFeedbackProps> = props => {
  const [radioButtonValue, setRadioButtonValue] = useState<string | null>(null)

  const { data: visitRatings } = AppointmentService.useVisitDurationRatings()

  const handleSubmit = async () => {
    const payload = {
      appointment: props.appointment?.id as number,
      visitDuration: radioButtonValue,
    }

    await AppointmentService.visitDurationRating(payload)
    props.onClose()
  }

  return (
    <FFModal
      open
      header={
        <Box p={1}>
          <Typography variant="h6" align="center">
            How was the appointment duration?
          </Typography>
        </Box>
      }
      footer={
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button
            disabled={radioButtonValue === null}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            <Typography>Done</Typography>
          </Button>
        </Box>
      }
    >
      <Box m={2}>
        <RadioGroup
          sx={{ width: '80%' }}
          value={radioButtonValue}
          onChange={e => {
            setRadioButtonValue(e.target.value)
          }}
        >
          {visitRatings &&
            visitRatings.length > 0 &&
            visitRatings.map(rating => (
              <FormControlLabel
                key={rating.id}
                value={rating.id}
                control={<Radio />}
                label={<Typography>{rating.label}</Typography>}
              />
            ))}
        </RadioGroup>
      </Box>
    </FFModal>
  )
}

export default AppointmentVisitFeedback
