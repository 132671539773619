import { Box, Chip } from '@material-ui/core'

export interface ChipTabsProps<TabValues extends string> {
  tabs: Array<{
    label: string
    value: TabValues
  }>
  selectedTab?: TabValues
  onSelect?: (selectedValue: TabValues) => void
}

export const ChipTabs = <TabValues extends string = string>(props: ChipTabsProps<TabValues>) => {
  const { tabs, selectedTab, onSelect } = props

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {tabs.map(tab => (
        <Box key={tab.value} mr={1}>
          <Chip
            label={tab.label}
            onClick={() => onSelect?.(tab.value)}
            color={selectedTab === tab.value ? 'primary' : 'default'}
          />
        </Box>
      ))}
    </Box>
  )
}
