import {
  PREFERENCES_PATIENTLIST_ORDER,
  PREFERENCES_PATIENTLIST_ORDERBY,
} from '../actions/actionTypes'

export interface PreferencesState {
  PatientList: {
    orderBy: string
    order: string
  }
}

const INITIAL_STATE: PreferencesState = {
  PatientList: {
    orderBy: 'id',
    order: 'desc',
  },
}

const preferences = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PREFERENCES_PATIENTLIST_ORDER: {
      const PatientList = { ...state.PatientList }
      PatientList.order = action.order
      return {
        ...state,
        PatientList,
      }
    }
    case PREFERENCES_PATIENTLIST_ORDERBY: {
      const PatientList = { ...state.PatientList }
      PatientList.orderBy = action.orderBy
      return {
        ...state,
        PatientList,
      }
    }
    default:
      return state
  }
}

export default preferences
