import { sortBy } from 'lodash/fp'
import {
  CircularProgress,
  Box,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Select,
  MenuItem,
} from '@mui/material'
import { useFeatureSwitches, useUpdateFeatureSwitch } from '~/utils/useFeatureSwitch'

const FeatureSwitchSettings = () => {
  const featureSwitches = useFeatureSwitches()
  const { mutateAsync: updateFeatureSwitch, isLoading: updateFeatureSwitchLoading } =
    useUpdateFeatureSwitch()

  if (!featureSwitches?.data) return <CircularProgress />

  const handleFlagChange = id => async event => {
    await updateFeatureSwitch({ id, active: event.target.value === 'true' })
    featureSwitches.refetch()
  }

  return (
    <Box p={2}>
      <p>Please be careful when toggling these on or off.</p>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Active?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy('name', Object.values(featureSwitches.data.featureSwitchesByName)).map(
            featureSwitch => (
              <TableRow key={featureSwitch.id}>
                <TableCell>{featureSwitch.name}</TableCell>
                <TableCell>{featureSwitch.note}</TableCell>
                <TableCell>
                  <Select
                    value={featureSwitch.active.toString()}
                    onChange={handleFlagChange(featureSwitch.id)}
                    disabled={updateFeatureSwitchLoading || featureSwitches.isLoading}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Box>
  )
}

export default FeatureSwitchSettings
