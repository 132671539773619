import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { apiClient } from '~/api/rest'
import { queueNotification } from '~/redux/actions/notifications'
import { useFocusPatient } from './../../Education/utils'
import { PatientDetailContext } from './patientDetailContext'

export const usePatientDetail = () => {
  const { onboardingState, enrolledPrograms, id, setState, refetch } =
    useContext(PatientDetailContext)
  const dispatch = useDispatch()

  // Update focus state to power education modal side effects
  useFocusPatient(id)

  const updateOnboardingState = async updateData => {
    try {
      const data = await apiClient.rest.post<any>(`/onboarding/set_status/${id}/`, updateData)
      // notify only if onboarding status has changed
      if (onboardingState.status !== updateData.status) {
        dispatch(
          queueNotification({
            variant: 'success',
            message: `Moved to ${updateData.status}`,
          })
        )
      }
      setState(state => ({ ...state, onboardingState: data }))
    } catch (error) {
      dispatch(
        queueNotification({
          variant: 'error',
          message: (error as any).data?.detail ?? 'Failed',
        })
      )
    }
  }

  const updateOnboardingData = async updatedState => {
    setState(state => ({ ...state, onboardingState: updatedState }))
  }

  const patientIsInProgram = programUid =>
    !!(enrolledPrograms && enrolledPrograms.includes(programUid))

  const switchPatient = id => {
    setState(state => ({ ...state, id: id }))
  }

  return {
    updateOnboardingState,
    updateOnboardingData,
    patientIsInProgram,
    switchPatient,
    id,
    onboardingState,
    enrolledPrograms,
    refetch,
  }
}

export default usePatientDetail
