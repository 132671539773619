import { FC, useState } from 'react'

import { Button, Box, Typography, TextField } from '@material-ui/core'
import { FFModal } from '~/components/FFModal/FFModal'
import Autocomplete from '@material-ui/lab/Autocomplete'

const ACCESS_REASONS = {
  other: 'Other',
  clinical_care: 'Clinical Care',
  quality: 'Quality',
  billing: 'Billing',
  tech: 'Tech Research / Support',
  operations: 'Operations Support',
  message_triage: 'Message Triage',
}

interface AccessReasonChoice {
  label: string
  value: string | null
  disabled?: boolean
}

const reasonValueAsOption = (reasonValue: string) => {
  return { label: ACCESS_REASONS[reasonValue], value: reasonValue }
}

const ACCESS_REASON_CHOICES: AccessReasonChoice[] =
  Object.keys(ACCESS_REASONS).map(reasonValueAsOption)

interface Props {
  cancel: () => void
  request: (reason: string) => void
  loading: boolean
}

const PHIAccessFormModal: FC<Props> = props => {
  const [accessReason, setAccessReason] = useState<string | null>(null)

  const currentlySelectedReason: AccessReasonChoice = accessReason
    ? reasonValueAsOption(accessReason)
    : { label: '', disabled: true, value: null }

  return (
    <FFModal
      open={true}
      header={
        <Box p={1}>
          <Typography variant="h6" align="center">
            This chart is confidential
          </Typography>
        </Box>
      }
      footer={
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button color="secondary" disabled={props.loading} onClick={() => props.cancel()}>
            Cancel
          </Button>
          <Box ml={1}>
            <Button
              color="primary"
              variant="contained"
              disabled={!accessReason || props.loading}
              onClick={() => accessReason && props.request(accessReason)}
            >
              Request Access
            </Button>
          </Box>
        </Box>
      }
    >
      <Box m={2}>
        <Typography>
          This patient is marked as a VIP. Please help us to keep track of access to their Protected
          Health Information.
        </Typography>
        <Box mt={2}>
          <Autocomplete
            autoHighlight
            getOptionLabel={option => option.label}
            value={currentlySelectedReason}
            getOptionDisabled={option => !!option.disabled}
            onChange={(_, option) => {
              if (option) {
                setAccessReason(option.value)
              }
            }}
            options={ACCESS_REASON_CHOICES}
            renderInput={params => (
              <TextField {...params} label="Choose a reason for access" variant="outlined" />
            )}
            style={{ width: 500 }}
          />
        </Box>
      </Box>
    </FFModal>
  )
}

export default PHIAccessFormModal
