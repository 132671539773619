import axiosRequest from '~/utils/axios'

export const uploadPracticeSuiteInvoice = async (file: FormData) => {
  const response: any = await axiosRequest(
    'POST',
    'multipart/form-data',
    true,
    '/practice-suite/upload/',
    file
  )

  return response.data
}
