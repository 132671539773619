import { BehindProviderFeatureAccessCheck, SimplifedTenantKey } from '~/legacy/core'

import AddReactionIcon from '@mui/icons-material/AddReaction'
import Badge from '@mui/material/Badge'
import { Box } from '@mui/system'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CalendarToday from '@mui/icons-material/CalendarToday'
import type { FC } from 'react'
import FaceIcon from '@mui/icons-material/Face'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { NavLink } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { useLocalStorage } from '~/components/Providers/LocalStorageProvider'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from '~/utils/useFeatureFlag'

export const InterfaceNav: FC = () => {
  const showAddPatient: boolean = useFeatureFlag('feature.AddPatient') ? true : false
  const unreadThreadCount = useSelector(state => state.chat.unreadThreadCount)
  const { classes } = useStyles()
  const featureAccess = useFeatureAccess()
  const me = useSelector(state => state.me)
  const [inboxParams] = useLocalStorage('inboxParams')
  const [messageInboxParams] = useLocalStorage('messageInboxParams')
  const [scheduleParams] = useLocalStorage('scheduleParams')

  return (
    <Box
      className={classes.navBox}
      sx={{
        width: 100,
        paddingTop: 2,
        borderRight: 'solid',
        borderRightWidth: '.2rem',
        overflow: 'auto',
      }}
    >
      <NavLink
        activeClassName="active"
        className={classes.navLink}
        to={messageInboxParams ? `/messages?${messageInboxParams ?? ''}` : '/messages'}
      >
        <Badge color="primary" badgeContent={unreadThreadCount} invisible={unreadThreadCount <= 0}>
          <ChatBubbleOutlineIcon className={classes.navLinkIcon} />
        </Badge>
        Messages
      </NavLink>

      <BehindProviderFeatureAccessCheck feature="tasks" featureAccess={featureAccess}>
        <NavLink
          activeClassName="active"
          className={classes.navLink}
          to={inboxParams ? `/inbox${inboxParams ?? ''}` : '/inbox'}
        >
          <CheckCircleOutlineIcon className={classes.navLinkIcon} />
          To Dos
        </NavLink>
      </BehindProviderFeatureAccessCheck>

      <NavLink activeClassName="active" className={classes.navLink} to="/patients">
        <FaceIcon className={classes.navLinkIcon} />
        Patients
      </NavLink>

      <BehindProviderFeatureAccessCheck feature="worklists" featureAccess={featureAccess}>
        <NavLink activeClassName="active" className={classes.navLink} to="/worklist">
          <FormatListBulletedIcon className={classes.navLinkIcon} />
          Worklists
        </NavLink>
      </BehindProviderFeatureAccessCheck>

      {/*
        TODO: Rendering Add patient in the below way is temporary.
        So adding a check for the provider’s current tenant == ‘simplifed’
      */}
      {(me.providerFields?.tenantKey === SimplifedTenantKey || showAddPatient) && (
        <NavLink activeClassName="active" className={classes.navLink} to="/people/add">
          <AddReactionIcon className={classes.navLinkIcon} />
          Add Patient
        </NavLink>
      )}
      <NavLink
        activeClassName="active"
        className={classes.navLink}
        to={scheduleParams ? `/schedule${scheduleParams ?? ''}` : '/schedule'}
      >
        <CalendarToday className={classes.navLinkIcon} />
        Appointment Calendar
      </NavLink>
    </Box>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    navBox: {
      borderColor: theme.palette.grey[100],
    },
    navLink: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '10rem',
      textDecoration: 'none',
      color: theme.palette.grey[400],
      textAlign: 'center',

      '&:hover, &.active': {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
      },
    },
    navLinkIcon: {
      width: 30,
      height: 30,
      marginBottom: '0.5rem',
    },
  }
})

export default InterfaceNav
