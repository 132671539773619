import ResourceAPI from './base'
import { SettingsPayload } from '../../interfaces'

/** @deprecated Use `@fireflyhealth/api` instead. */
export class SettingsResource extends ResourceAPI {
  getSettings() {
    return this.rest.get('/settings/')
  }
  updateSettings(payload: Partial<SettingsPayload>) {
    return this.rest.patch('/settings/', payload)
  }
  getOnboardingGuides() {
    return this.rest.get('/settings/onboarding/')
  }
}
