import { EXIT_APPOINTMENT, SET_ACTIVE_VIDEO_APPOINTMENT } from '../actions/actionTypes'

export interface AppointmentsState {
  list: any[]
  isLoading: boolean
  video: {
    appointmentId: null | number
  }
}

const INITIAL_STATE: AppointmentsState = {
  list: [],
  isLoading: false,
  video: {
    appointmentId: null,
  },
}

const appointments = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_VIDEO_APPOINTMENT:
      return {
        ...state,
        video: {
          ...state.video,
          appointmentId: action.payload.appointmentId as number,
        },
      }
    case EXIT_APPOINTMENT:
      return {
        ...state,
        video: {
          appointmentId: null,
        },
      }
    default:
      return state
  }
}

export default appointments
