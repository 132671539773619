export const READABLE_PROGRAM_NAMES = {
  PrimaryCare: 'Primary Care',
  UrgentCare: 'Urgent Care',
  ReturnToWork: 'Return To Work',
  ChronicOpioidUse: 'Chronic Opioid Use',
  ComplexPatient: 'Complex Patient',
  WeightLoss: 'Weight Management',
  AdhdAdd: 'A.D.H.D. / A.D.D.',
  DepressionAndAnxiety: 'Depression And Anxiety',
  Benefit: 'Health Plan',
}

export const PRIMARYCARE_PROGRAM_STATUSES = {
  'Not Established': 'Enrolled',
  Established: 'Established',
  Churned: 'Churned',
}

export const PRIMARYCARE_PROGRAM_STATUSES_NEW = {
  'Not Established': 'Not Established',
  Established: 'Established',
  Churned: 'Churned',
}

export const BENEFIT_PROGRAM_STATUSES = {
  Elected: 'Elected',
  Enrolled: 'Enrolled',
  Expired: 'Expired',
}

export const BENEFIT_PROGRAM_STATUSES_NEW = {
  Elected: 'Elected',
  Enrolled: 'Enrolled',
  Terminated: 'Expired',
}

export const PROGRAM_STATUS = {
  Benefit: [
    BENEFIT_PROGRAM_STATUSES.Elected,
    BENEFIT_PROGRAM_STATUSES.Enrolled,
    BENEFIT_PROGRAM_STATUSES.Expired,
  ],
  PrimaryCare: [
    PRIMARYCARE_PROGRAM_STATUSES['Not Established'],
    PRIMARYCARE_PROGRAM_STATUSES.Established,
    PRIMARYCARE_PROGRAM_STATUSES.Churned,
  ],
}

export const ProgramEnrollmentEvents = {
  PROGRAM_ENROLLED: 'PROGRAM_ENROLLED',
  PROGRAM_REENROLLED: 'PROGRAM_REENROLLED',
  PROGRAM_UNENROLLED: 'PROGRAM_UNENROLLED',
  APPOINTMENT_CHECKED_OUT: 'APPOINTMENT_CHECKED_OUT',
  CANCEL_DISCHARGE: 'CANCEL_DISCHARGE',
}

export const PRIMARY_CARE_PROGRAM_UID = 'PrimaryCare'
export const BENEFIT_PROGRAM_UID = 'Benefit'
export const DISCHARGE_IN_PROGRESS_TEXT = '(Discharge in progress)'

export const NO_ENROLLMENT_TEXT = {
  PrimaryCare: 'Member is not enrolled in Firefly primary care',
  Benefit: 'Member is not recognized as eligible for the Firefly health plan',
}
