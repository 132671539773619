import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Box } from '@mui/material'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  arrowButton: {
    alignSelf: 'right',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  arrowContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    textTransform: 'none',
    color: `${theme.palette.grey[500]}`,
  },
}))

export default function MakeshiftDrawer({
  open,
  handleSidebarCollapse,
  sidebar,
  collapseDisabled,
}) {
  const classes = useStyles()
  return (
    <Box height="100%" display="flex" flexDirection="column">
      {collapseDisabled ? (
        <Box mb={2} />
      ) : (
        <div className={classes.arrowContainer}>
          <IconButton className={classes.arrowButton} onClick={() => handleSidebarCollapse()}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
      )}
      <Slide direction="right" in={open || collapseDisabled} mountOnEnter unmountOnExit>
        <div className={classes.root}>{sidebar}</div>
      </Slide>
    </Box>
  )
}
