import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { patientsSlice } from '../../../redux/slices/patients'
import { EditField } from '~/components/Generic/EditField'
import { Box, Typography } from '@mui/material'
import usePatientDetail from '../utils/usePatientDetail'
import { PRIMARY_CARE_PROGRAM_UID } from '~/constants/programEnrollment'

const RISK_SCORE = {
  high: 'High',
  intermediate: 'Intermediate',
  low: 'Low',
}

const PatientRiskScore = ({ patient }) => {
  const { patientIsInProgram } = usePatientDetail()
  const patientIsEnrolled = patientIsInProgram(PRIMARY_CARE_PROGRAM_UID)
  const riskScore =
    patient.person?.primaryCareProgramInfo?.riskScore != null
      ? patient.person?.primaryCareProgramInfo?.riskScore
      : ''
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const updateRiskScore = async (val: string) => {
    setLoading(true)

    try {
      await dispatch(
        patientsSlice.thunks.updatePatient({
          id: patient.id,
          person: {
            primaryCareProgramInfo: {
              riskScore: val,
            },
          },
        })
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }
  // Shortcut for change handlers and values
  const setValues = (name: string, value) => ({
    name,
    onChange: async e => {
      e.persist()
      const targetValue = e.target.value !== '' ? e.target.value : null
      if (name === 'riskScore') {
        await updateRiskScore(targetValue)
      }
    },
    value,
  })
  return (
    <>
      {patientIsEnrolled && (
        <Box mb={4}>
          <Typography variant={'h6'}>Patient Risk Score</Typography>
          <Box m={1} mt={2}>
            <EditField
              {...setValues('riskScore', riskScore)}
              label="Risk Score"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              disabled={loading}
            >
              <option value="">(select)</option>
              {Object.entries(RISK_SCORE).map(([key, val]) => (
                <option key={key} value={key}>
                  {val}
                </option>
              ))}
            </EditField>
          </Box>
        </Box>
      )}
    </>
  )
}

export default PatientRiskScore
