import { FC, useEffect, useRef, useState } from 'react'
import { Tabs, Tab } from '@mui/material'
import { Link, useLocation, useRouteMatch, Switch, Route, useHistory } from 'react-router-dom'
import EditPerson from '~/components/PersonDetail/EditPerson/EditPerson'
import { useFeatureSwitches } from '~/utils/useFeatureSwitch'
import { FeatureAccess, Features, PatientUser, ProviderUser } from '~/legacy/core'
import { useGetPerson } from '~/api/PersonService'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { queueNotification } from '~/redux/actions/notifications'
import { useDispatch } from 'react-redux'
import PatientNotes from '~/components/PatientDetail/PatientNotes'
import { PatientTaskList } from '~/components/Tasks/PatientTaskList'
import PatientDetailTopBar from '~/components/PatientDetail/PatientDetailTopBar'
import { Box, Grid } from '@mui/material'
import PatientDetailChat from '~/components/PatientDetail/PatientDetailChat'
import Navigation from '~/components/PersonDetail/Navigation'
import PriorAuthorization from '~/components/PersonDetail/PriorAuthorization'
import ProviderSearch from '~/components/PersonDetail/ProviderSearch'
import { borderProps } from '~/utils/borderProps'
import ZusAggregatedProfile from '~/components/PatientDetail/ZusAggregatedProfile'
import { useFeatureFlags } from '~/utils/useFeatureFlag'
import { ExpandedModeContext, useExpandedModeControls } from '~/utils/useExpandedMode'

interface PersonDetailProps {
  personId: number
  providers: ProviderUser[]
}

const GridItem = ({ children, ...props }) => (
  <Grid item minWidth={props.minWidth} xs={props.xs} component={Box} position="relative">
    <Box
      position="absolute"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      {...props}
    >
      {children}
    </Box>
  </Grid>
)

const TABS: { [tabName: string]: PersonTab } = {
  'To Dos': {
    component: PatientTaskList,
    to: '/todos',
    feature: 'tasks',
    isUserRequired: true,
  },
  Profile: {
    component: EditPerson,
    to: '/profile',
    isUserRequired: false,
  },
  ZAP: {
    component: ZusAggregatedProfile,
    to: '/zap',
    isUserRequired: true,
  },
  'Provider Search': {
    component: ProviderSearch,
    to: '/providersearch',
    feature: 'carePass',
    isUserRequired: false,
  },
  Navigation: {
    component: Navigation,
    to: '/navigation',
    feature: 'carePass',
    isUserRequired: false,
  },
  'Prior Authorization': {
    component: PriorAuthorization,
    to: '/priorauthorization',
    feature: 'priorAuth',
    isUserRequired: false,
  },
  Notes: {
    component: PatientNotes,
    to: '/notes',
    feature: 'notes',
    isUserRequired: true,
  },
}
const TAB_KEYS = Object.keys(TABS)

// Matches /person/:id/:tabName
const TAB_REGEX = /\/(person|people)\/(\d+)\/([^/]+)/

const matchPathname = location => location.pathname.match(TAB_REGEX)?.[0]

interface PersonTab {
  component: any
  to: string
  feature?: Features
  featureSwitch?: string
  featureFlag?: string
  isUserRequired: boolean
}

const tabIsEnabled = (
  tab: PersonTab,
  featureSwitches,
  featureFlags: { [name: string]: { isActive: boolean } } | undefined,
  featureAccess: FeatureAccess | undefined
) =>
  (tab.featureSwitch ? featureSwitches?.[tab.featureSwitch]?.active : true) &&
  (tab.featureFlag ? featureFlags?.[tab.featureFlag]?.isActive : true) &&
  (tab.feature ? featureAccess?.tenant?.[tab.feature] : true)

export const PersonDetail: FC<PersonDetailProps> = props => {
  const { personId, providers } = props
  const { data: person, error: personFetchError } = useGetPerson({ personId })
  const patientUser: PatientUser = {} as PatientUser
  if (person) {
    patientUser.person = person
    patientUser.firstName = person.firstName
    patientUser.lastName = person.lastName
  }

  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    LefthandGridContainerProps,
    toggleExpandedMode,
    resetExpandedMode,
    expandedModeIsActive,
  } = useExpandedModeControls()

  const navigateToPatientView = (id?: number, personId?: number) => {
    if (id != undefined) {
      // Are we being redirected from a `/people/:id/...` URL? If so, retain the
      // path and query parameters after the ID.
      const url =
        personId && location.pathname.startsWith(`/people/${personId}`)
          ? `${location.pathname.replace(`/people/${personId}`, `/patients/${id}`)}${
              location.search
            }`
          : `/patients/${id}`
      history.push(url)
    } else history.push(`/patients`)
  }

  useEffect(() => {
    if (personFetchError) {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Person does not exist',
        })
      )
      navigateToPatientView()
    }
    if (person?.user != null) navigateToPatientView(person.user, person.id)
  })

  const featureSwitches = useFeatureSwitches()?.data?.featureSwitches
  const featureFlags = useFeatureFlags().data?.featureFlagsByName
  const match = useRouteMatch()

  // Pass down scrolling information to child components that want it, e.g., for pagination.
  const scrollRef = useRef<HTMLDivElement>(null)
  const [scrollEventTarget] = useState(new EventTarget())
  const featureAccess = useFeatureAccess()

  // Filter out any tabs with nonexistent or inactive feature switches
  // Also filter out any tabs which the user should not have feature access to
  const activeTabs = TAB_KEYS.filter(v =>
    tabIsEnabled(TABS[v], featureSwitches, featureFlags, featureAccess)
  )

  return (
    <>
      {person && (
        <ExpandedModeContext.Provider
          value={{ toggleExpandedMode, expandedModeIsActive, resetExpandedMode }}
        >
          <Grid
            container
            component={Box}
            wrap="nowrap"
            position="absolute"
            width="100%"
            height="100%"
          >
            <GridItem {...LefthandGridContainerProps}>
              <PatientDetailTopBar patient={patientUser} providers={providers} />
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                value={matchPathname(location)}
                sx={{
                  minWidth: 80,
                  minHeight: 0,
                }}
              >
                {activeTabs.map((v, i) => (
                  <Tab
                    value={`${match.url}${TABS[v].to}`}
                    label={v}
                    key={i}
                    component={Link}
                    to={`${match.url}${TABS[v].to}`}
                    sx={{
                      minWidth: 80,
                      minHeight: 0,
                    }}
                  />
                ))}
              </Tabs>

              <div
                ref={scrollRef}
                onScroll={e => scrollEventTarget.dispatchEvent(new CustomEvent('scroll', { ...e }))}
                style={{ flex: '1', overflow: 'auto' }}
              >
                <Switch>
                  {activeTabs.map((v, i) => {
                    const Component = TABS[v].component
                    const isUserRequired = TABS[v].isUserRequired
                    return (
                      <Route
                        path={`${match.url}${TABS[v].to}`}
                        render={() => (
                          <Component
                            patient={isUserRequired ? patientUser : person}
                            scrollRef={scrollRef}
                            scrollEventTarget={scrollEventTarget}
                          />
                        )}
                        key={i}
                      />
                    )
                  })}
                </Switch>
              </div>
            </GridItem>
            <GridItem minWidth={400} xs={5} {...borderProps(['borderLeft'])}>
              <PatientDetailChat
                patient={patientUser}
                searchQuery={''}
                lastSearchQuery={''}
                isSearching={false}
                handleSearchBar={() => {}}
                handleSearchQueryChange={undefined}
                handleSearch={() => {}}
                handleClearSearch={undefined}
                handleArrowSearchResults={undefined}
              />
            </GridItem>
          </Grid>
        </ExpandedModeContext.Provider>
      )}
    </>
  )
}

export default PersonDetail
