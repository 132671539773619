import { Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import cx from 'classnames'
import Moment from 'moment'
import FormAnswerDisplay from './FormAnswerDisplay'
import { getFormSubmissionStatus } from './utils'

const FormSubmissionOverview = props => {
  const { submission } = props
  const styles = useStyles()

  return (
    <Box>
      <h2>{submission.form.title}</h2>
      <p>
        {props.user.firstName} {props.user.lastName} - {getFormSubmissionStatus(submission)}
      </p>
      {submission.completedAt ? (
        <p>Completed on {Moment(submission.completedAt).format('LLL')}</p>
      ) : null}
      {submission.form.sections.map(section => (
        <Box mb={2} key={section.id}>
          <h3 className={styles.section}>{section.title}</h3>
          {section.questions.map(question => {
            const submissionData = submission.data[question.uid]
            const boxStyles = cx({
              [styles.greyText]: submissionData === undefined,
              [styles.normalText]: submissionData !== undefined && question.type !== 'info',
              [styles.withNumber]: question.type !== 'info',
            })
            return (
              <Box mb={1} key={`question_${question.id}`}>
                <p>
                  <b className={boxStyles}>{question.text}</b>
                </p>
                <FormAnswerDisplay question={question} answer={submission.data[question.uid]} />
              </Box>
            )
          })}
        </Box>
      ))}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    counterReset: 'section 0',
  },
  greyText: {
    color: theme.palette.grey[500],
    whiteSpace: 'pre-line',
  },
  normalText: {
    color: 'black',
    whiteSpace: 'pre-line',
  },
  withNumber: {
    '&::before': {
      counterIncrement: 'section',
      content: 'counters(section, "") ". "',
    },
  },
}))

export default FormSubmissionOverview
