import { MenuItem, Select } from '@mui/material'
import type { FC } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

interface DistancePickerProps {
  distance: number
  callback: (val: number) => void
}

export const DistancePicker: FC<DistancePickerProps> = (props: DistancePickerProps) => {
  return (
    <Select
      value={props.distance}
      onChange={(event: any) => {
        props.callback(event.target.value)
      }}
      IconComponent={() => <ArrowDropDownIcon color="primary" />}
    >
      <MenuItem value={1}>1 Mile</MenuItem>
      <MenuItem value={10}>10 Miles</MenuItem>
      <MenuItem value={25}>25 Miles</MenuItem>
      <MenuItem value={50}>50 Miles</MenuItem>
      <MenuItem value={100}>100 Miles</MenuItem>
      <MenuItem value={250}>250 Miles</MenuItem>
    </Select>
  )
}

export default DistancePicker
