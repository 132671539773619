import type { FC } from 'react'
import { RouteProps, Route } from 'react-router-dom'
import { BehindProviderFeatureAccessCheck, Features } from '~/legacy/core'
import { useFeatureAccess } from '~/utils/useFeatureAccess'

type FeatureAccessRouteProps = RouteProps & {
  feature: Features
}

// Disables, but doesn't redirect for, a Route
// if the user does not have access to the feature
export const FeatureAccessRoute: FC<FeatureAccessRouteProps> = props => {
  const featureAccess = useFeatureAccess()
  const { feature, children, ...routeProps } = props

  return (
    <Route
      {...routeProps}
      render={() => (
        <BehindProviderFeatureAccessCheck feature={feature} featureAccess={featureAccess}>
          {children}
        </BehindProviderFeatureAccessCheck>
      )}
    />
  )
}

export default FeatureAccessRoute
