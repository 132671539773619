import { useEffect } from 'react'
import type { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import { auth0Authenticate, auth0Deauthenticate } from '~/utils/auth'

/**
 * Exporting a component that can live as the direct child of Auth0Provider
 * that can host side effects from authentication state changes
 *
 * This is a convenience component, since authentication state isn't
 * surfaced with the Auth0Provider component itself
 */
export const Auth0Effects: FC = ({ children }) => {
  const auth0 = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    if (auth0.isAuthenticated) {
      const user = auth0.user
      datadogLogs.setUser({
        email: user.email,
      })
      datadogRum.setUser({
        email: user.email,
      })
      dispatch(auth0Authenticate())
    } else {
      dispatch(auth0Deauthenticate())
    }
  }, [auth0.isAuthenticated])

  return <>{children}</>
}

export default Auth0Effects
