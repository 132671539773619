import {
  SEARCH_PATIENTS,
  SEARCH_PATIENTS_SUCCESS,
  SEARCH_CLEAR_RESULTS,
} from '../actions/actionTypes'

export interface SearchState {
  loading: boolean
  results: any[]
  term: string | null
}

export const INITIAL_STATE: SearchState = {
  loading: false,
  results: [],
  term: null,
}

const results = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_PATIENTS:
      return {
        ...state,
        term: action.term,
        loading: true,
      }
    case SEARCH_PATIENTS_SUCCESS:
      // Only update state if the search results are for the current query.
      if (action.term !== state.term) return state
      return {
        ...state,
        loading: false,
        results: action.results,
      }
    case SEARCH_CLEAR_RESULTS:
      return INITIAL_STATE
    default:
      return state
  }
}

export default results
