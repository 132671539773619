import { FC } from 'react'
import { PatientUser } from '~/legacy/core'
import NavigationList from './NavigationList'

export interface NavigationProps {
  patient: PatientUser
}

const Navigation: FC<NavigationProps> = props => {
  return <NavigationList person={props?.patient.person} />
}

export default Navigation
