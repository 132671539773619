import { createSlice } from '@reduxjs/toolkit'
import * as thunks from './actions'

export interface SettingsState {
  loading: boolean
  initialized: boolean
  serverSettings: any
}

export const INITIAL_STATE: SettingsState = {
  loading: false,
  initialized: false,
  serverSettings: {},
}

const baseSettingsSlice = createSlice({
  name: 'settings',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSettingsStart: state => {
      state.loading = true
    },
    fetchSettingsSuccess: (state, { payload: serverSettings }) => {
      state.serverSettings = serverSettings
      state.loading = false
      state.initialized = true
    },
  },
})

export const settingsSlice = Object.assign(baseSettingsSlice, { thunks })
export default baseSettingsSlice
