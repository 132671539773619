import type { FC } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Box } from '@material-ui/core'
import { selectCustomStyles } from './selectCustomStyles'
import { getAssignableUsers, AssignableUserOption } from '~/utils/users'
import BlockIcon from '@material-ui/icons/Block'

interface Props {
  value: number | null
  canAssignToPatient: boolean
  patient?: any
  careTeam?: number[]
  className?: string
  onChange: (value: number) => any
  disabled?: boolean
}

const UserSelect: FC<Props> = props => {
  const providers = useSelector(state => state.providers)

  const careTeam = props.careTeam ?? []
  const groupedOptions = getAssignableUsers(
    props.patient,
    providers,
    props.canAssignToPatient,
    careTeam
  )

  let selectLabel = 'Select Assignee'
  const provider = providers.find(provider => provider.id === props.value)
  if (provider) {
    selectLabel = `${provider.firstName} ${provider.lastName}`
  }
  if (props.patient && props.patient.id === props.value) {
    selectLabel = `${props.patient.firstName} ${props.patient.lastName}`
  }

  const selectValue = {
    value: props.value,
    label: selectLabel,
  }

  const formatOptionLabel = (option: AssignableUserOption) => (
    <Box>
      {option.label}
      {option.disabledReason && (
        <Box display="flex" alignItems="flex-start">
          <Box>
            <BlockIcon />
          </Box>
          <Box paddingLeft={1}>{option.disabledReason}</Box>
        </Box>
      )}
    </Box>
  )

  return (
    <Select
      className={props.className}
      styles={selectCustomStyles}
      isDisabled={props.disabled}
      isOptionDisabled={(option: AssignableUserOption) => !!option.disabledReason}
      onChange={e => props.onChange(e?.value as number)}
      options={groupedOptions}
      value={selectValue}
      placeholder={'Select Assignee'}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export default UserSelect
