import { PatientUser } from '~/legacy/core'
import { Box, Button, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useProgramEnrollmentEvent } from '~/api/ProgramService'
import FFModal from '~/components/FFModal/FFModal'
import { PRIMARY_CARE_PROGRAM_UID, ProgramEnrollmentEvents } from '~/constants/programEnrollment'

interface EnrollMemberModelProps {
  patient: PatientUser
  handleClose: () => void
  open: boolean
  enrollmentLabel: string
}

const EnrollMemberModel: FC<EnrollMemberModelProps> = props => {
  const date = new Date()
  const [enrollmentDate, setEnrollmentDate] = useState<string>(
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  )
  const { mutateAsync: handleEnrollmentEvent } = useProgramEnrollmentEvent()
  const label = `${props.enrollmentLabel}ment Date`
  const handleTextInputSelection = (event: any) => {
    const dateStr: string = event.target.value
    setEnrollmentDate(dateStr)
  }
  const handleEnrollment = async () => {
    // add patient to new enrollment
    handleEnrollmentEvent({
      programUid: PRIMARY_CARE_PROGRAM_UID,
      event:
        props.enrollmentLabel === 'Re-enroll'
          ? ProgramEnrollmentEvents.PROGRAM_REENROLLED
          : ProgramEnrollmentEvents.PROGRAM_ENROLLED,
      personId: props.patient.person.id,
      eventDate: enrollmentDate,
    })
    props.handleClose()
  }

  return (
    <FFModal open={props.open} style={{ width: '60%' }}>
      <Box>
        <Box display="flex" flexDirection="column" mt={3} ml={4}>
          <Typography variant="h6">{props.enrollmentLabel} Member in Primary Care</Typography>
          <Box mt={4}>
            <TextField
              value={enrollmentDate}
              onChange={handleTextInputSelection}
              label={label}
              variant="outlined"
              style={{ width: '70%' }}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" px={4} py={4}>
          <Box mr={1}>
            <Button disabled={false} onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={false}
              variant="contained"
              color="primary"
              onClick={handleEnrollment}
            >
              {props.enrollmentLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    </FFModal>
  )
}

export default EnrollMemberModel
