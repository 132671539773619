import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { ICarePlan } from '~/models/CarePlan'
import CheckIcon from '@mui/icons-material/Check'
import { useCaseCategoriesById } from '~/api/CaseService'
import * as CarePlanService from '~/api/CarePlanService'

type DeactivateCarePlanProps = {
  carePlanToBeDeactivated: ICarePlan
  closeModal: () => void
  onCareplanCreateOrUpdate
  setUpdatedCarePlan
}

const DeactivateExistingCarePlanModal: FC<DeactivateCarePlanProps> = props => {
  const activeTasks = props.carePlanToBeDeactivated.tasks.filter(task => !task.isComplete)
  const openCases = props.carePlanToBeDeactivated.cases
  const { result: caseCategoriesById } = useCaseCategoriesById()
  const [saving, setSaving] = useState<boolean>(false)

  const handleSaving = async () => {
    setSaving(true)

    const updatedCarePlan = await CarePlanService.updateCarePlanAction({
      id: parseInt(props.carePlanToBeDeactivated.id.toString()),
      action: 'inactive',
      tasks: props.carePlanToBeDeactivated.tasks,
      cases: props.carePlanToBeDeactivated.cases,
      patient: props.carePlanToBeDeactivated.patient,
    })
    props.onCareplanCreateOrUpdate(updatedCarePlan)
    setSaving(false)
    props.setUpdatedCarePlan(updatedCarePlan)
    props.closeModal()
  }

  return (
    <>
      <Box m={2}>
        <Typography variant="h6">{'Deactivate Existing Care Plan'}</Typography>
        <Typography variant="subtitle1" mt={1}>
          {props.carePlanToBeDeactivated.title}
        </Typography>
      </Box>
      <Box m={2}>
        {activeTasks.length > 0 && (
          <Box>
            <Typography variant="h3">
              <Typography variant="subtitle1" fontWeight={'500'}>
                {activeTasks.length} task(s) will be deleted:
              </Typography>
            </Typography>
            <List>
              {activeTasks.map(task => {
                return (
                  <ListItem
                    key={task.id}
                    sx={{
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '35px' }}>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="subtitle1">{task.title}</Typography>
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        )}
      </Box>
      <Box m={2}>
        {openCases.length > 0 && (
          <Box>
            <Typography variant="h3">
              <Typography variant="subtitle1" fontWeight={'500'}>
                {openCases.length} case(s) will be unlinked:
              </Typography>
            </Typography>
            <List>
              {openCases.map(openCase => {
                return (
                  <ListItem
                    key={openCase.id}
                    sx={{
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '35px' }}>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="subtitle1">
                        {caseCategoriesById[openCase.category]?.title ?? ''}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Box>
        )}
      </Box>
      <Box m={2}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button
            onClick={props.closeModal}
            variant="text"
            disabled={saving}
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '20%',
              marginRight: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
          </Button>
          <Button
            disabled={saving}
            onClick={handleSaving}
            variant="contained"
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '30%',
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Deactivate'}</Typography>
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default DeactivateExistingCarePlanModal
