import { PatientUser } from '~/legacy/core'
import { Box, Button, Typography } from '@mui/material'
import { FC } from 'react'
import { useProgramEnrollmentEvent } from '~/api/ProgramService'
import FFModal from '~/components/FFModal/FFModal'
import { PRIMARY_CARE_PROGRAM_UID, ProgramEnrollmentEvents } from '~/constants/programEnrollment'
import { apiClient } from '~/api/rest'

interface ChurnMemberModelProps {
  patient: PatientUser
  handleClose: () => void
  open: boolean
}

const CancelDischrgeModel: FC<ChurnMemberModelProps> = props => {
  const { mutateAsync: handleEnrollmentEvent } = useProgramEnrollmentEvent()

  const handleStopDischarge = async () => {
    // Below call to update primary care program, we can remove this once we stop dual writing
    await apiClient.rest.patch(`/programs/primary-care/${props.patient.id}/pending-discharge/v2`, {
      pendingDischargeReason: null,
    })
    handleEnrollmentEvent({
      programUid: PRIMARY_CARE_PROGRAM_UID,
      event: ProgramEnrollmentEvents.CANCEL_DISCHARGE,
      personId: props.patient.person.id,
    })
    props.handleClose()
  }

  return (
    <FFModal open={props.open} style={{ width: '60%' }}>
      <Box>
        <Box display="flex" flexDirection="column" mt={3} ml={4}>
          <Typography variant="h6">Stop Discharge Process</Typography>
          <Box mt={4} mr={6}>
            <Typography variant="body1">
              Discharge is currently in progress for this member. Do you want to stop the discharge
              process?
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" px={4} py={4}>
          <Box mr={1}>
            <Button disabled={false} onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={false}
              variant="contained"
              color="primary"
              onClick={handleStopDischarge}
            >
              Yes, Stop Discharge
            </Button>
          </Box>
        </Box>
      </Box>
    </FFModal>
  )
}

export default CancelDischrgeModel
