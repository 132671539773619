import { endOfDay } from 'date-fns'
import { FC, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  AvailablePhysicianSlot,
  VisitReason,
  availablePhysiciansWithSlots,
} from '~/api/AppointmentService'
import PhysicianSlotPickerGroups from './PhysicianSlotsPickerGroups'
import { DYNAMIC_SCHEDULE_V2 } from '~/components/Appointment/utils'
import { useFeatureFlag } from '~/utils/useFeatureFlag'

type SelectSlotProps = {
  patientId: number
  visitReason: VisitReason | null
  date: Date
  slot: AvailablePhysicianSlot | null
  setSlot: (value: AvailablePhysicianSlot) => void
  setIsLoading: (e: boolean) => void
}

const SelectSlot: FC<SelectSlotProps> = ({
  patientId,
  visitReason,
  date,
  slot,
  setSlot,
  setIsLoading,
}) => {
  const isDynamicSchedulingV2Enabled: boolean = useFeatureFlag(DYNAMIC_SCHEDULE_V2)

  const query = useQuery(
    ['availablePhysiciansWithSlots', visitReason, date],
    () =>
      availablePhysiciansWithSlots(
        patientId,
        visitReason!,
        date,
        endOfDay(date),
        isDynamicSchedulingV2Enabled
      ),
    {
      onSuccess() {
        setIsLoading(false)
      },
    }
  )

  useEffect(() => {
    if (query.isLoading || query.isIdle) {
      setIsLoading(true)
    }

    if (query.isError) {
      console.log({ error: query.error })
      setIsLoading(false)
    }
  }, [query])

  if (query.isLoading || query.isIdle || query.isError) {
    return null
  }

  return (
    <PhysicianSlotPickerGroups
      slots={isDynamicSchedulingV2Enabled ? query.data.appointments : query.data}
      value={slot}
      onChange={setSlot}
    />
  )
}

export default SelectSlot
