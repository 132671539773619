import { FeatureAccess } from '~/legacy/core'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { apiClient } from '~/api/rest'

const getFeatureAccess = () =>
  apiClient.rest.get<FeatureAccess>('/features/v2/access/?provider_context=true')

// Check and cache feature access for the current tenant
const useFeatureAccess = () => {
  const me = useSelector(state => state.me)
  const { data: featureAccess } = useQuery(['featureAccess'], () => getFeatureAccess(), {
    staleTime: 24 * 60 * 60 * 1000, // 1 day
    enabled: !!me?.providerFields,
  })

  return featureAccess
}

export { useFeatureAccess }
