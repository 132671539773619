import { Component, useEffect, useRef, useState } from 'react'
import type { FC } from 'react'
import { Box, Breadcrumbs, Button, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import { apiClient } from '~/api/rest'
import type { User } from '~/types'
import FormSubmissionOverview from './FormSubmissionOverview'
import FormTasks from './FormTasks'
import EmbeddedFormSubmission from './EmbeddedFormSubmission'

// TODO: Add typings for these resources.
const formSubmissionsResource = apiClient.resource<any>('/form/submission/')

const PRINT_PAGE_STYLE =
  '@page { size: auto;  margin: .5in; } @media print { body { -webkit-print-color-adjust: exact; } }'

// Class-based wrapper for form submission overview for ReactToPrint compatibility.
class PrintableFormSubmissionOverview extends Component<any, {}> {
  render() {
    return <FormSubmissionOverview {...this.props} />
  }
}

interface FormSubmissionProps {
  user: User
}

interface FormSubmissionParams {
  id: string
  submissionId: string
}

const FormSubmission: FC<FormSubmissionProps> = props => {
  const { submissionId, id } = useParams<FormSubmissionParams>()
  const ref = useRef<any>()

  const [submission, setSubmission] = useState<any>(null)

  useEffect(() => {
    const fetchSubmission = async () => {
      if (!submissionId) return
      const data = await formSubmissionsResource.get(submissionId)
      setSubmission(data)
    }
    fetchSubmission()
  }, [submissionId])

  return submission ? (
    <Box overflow="auto">
      <Box display="flex" alignItems="center">
        <Breadcrumbs style={{ flex: 1 }}>
          <Link to={`/patients/${id}/forms`}>All Forms</Link>
          <Typography>{submission.form.title}</Typography>
        </Breadcrumbs>
        {submission.vendor === 'Firefly' ? (
          <ReactToPrint
            trigger={() => <Button>Print</Button>}
            content={() => ref.current}
            pageStyle={PRINT_PAGE_STYLE}
          />
        ) : null}
      </Box>
      {submission.taskRelations ? (
        <FormTasks taskIds={submission.taskRelations.map(r => r.taskId)} />
      ) : null}

      {submission.vendor === 'Firefly' ? (
        <PrintableFormSubmissionOverview ref={ref} submission={submission} user={props.user} />
      ) : (
        <EmbeddedFormSubmission formSubmission={submission} />
      )}
    </Box>
  ) : null
}

export default FormSubmission
