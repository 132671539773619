import RestClient, { RestOptions } from './rest'
import { TaskResource, SettingsResource } from './resources'
import { CrudResource } from './resources/crud'
import { CPTCodeResource } from './resources/codeSystems'
import { ReferralResource } from './resources/referral'
import { FactResource } from './resources/fact'

/** @deprecated Use `@fireflyhealth/api` instead. */
export default class ApiClient {
  rest: RestClient

  cptCode: CPTCodeResource
  fact: FactResource
  settings: SettingsResource
  tasks: TaskResource
  referral: ReferralResource

  constructor(options: RestOptions) {
    this.rest = new RestClient(options)

    this.cptCode = new CPTCodeResource(this.rest)
    this.fact = new FactResource(this.rest)
    this.settings = new SettingsResource(this.rest)
    this.tasks = new TaskResource(this.rest)
    this.referral = new ReferralResource(this.rest)
  }

  resource<T>(path: string) {
    return new CrudResource<T>(this.rest, path)
  }
}
