import type { RefObject } from 'react'
import type { PatientUser } from '~/types'
import PatientCategorizedToDos from '~/components/PatientCategorizedTodos/PatientCategorizedTodos'

export const PatientTaskList = (props: {
  patient: PatientUser
  scrollRef?: RefObject<HTMLDivElement>
  scrollEventTarget?: EventTarget
}) => {
  return (
    <PatientCategorizedToDos
      patient={props.patient}
      scrollRef={props.scrollRef}
      scrollEventTarget={props.scrollEventTarget}
    />
  )
}
