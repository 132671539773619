import {
  PREFERENCES_PATIENTLIST_ORDER,
  PREFERENCES_PATIENTLIST_ORDERBY,
} from '../actions/actionTypes'

export const setPrefsPatientListOrder = (order = 'desc') => ({
  type: PREFERENCES_PATIENTLIST_ORDER,
  order,
})

export const setPrefsPatientListOrderBy = (orderBy = 'id') => ({
  type: PREFERENCES_PATIENTLIST_ORDERBY,
  orderBy,
})
