import { useState } from 'react'
import { ITask } from '~/models/Task'
import Moment from 'moment'
import { useMutation } from '~/legacy/core'
import { apiClient } from '~/api/rest'
import { format } from 'date-fns'

const getDefaultTasks = (providerUser: any) => [
  {
    title: '',
    dueDate: format(new Date(), 'YYYY-MM-DD'),
    ownerGroup: providerUser ? providerUser.assigeeGroup : '',
    priority: 0,
    isComplete: false,
  },
]

export const CREATE_MODES = {
  TEMPLATE: 'TEMPLATE',
  CUSTOM: 'CUSTOM',
}

export interface ICarePlanTemplate {
  id: number
  title: string
  notes: string | null
  typeDescription: string | null
  typeId: number
  episodic: boolean | null
}

export interface ICarePlanTemplatePreview {
  typeDescription: string | null
  taskPreviews: ITask[]
  title: string
  notes: string
}

export const useCarePlanModal = (providerUser: any) => {
  const [title, setTitle] = useState<string>('')
  const [notes, setNotes] = useState<string>('')
  const [tasks, setTasks] = useState<ITask[]>(getDefaultTasks(providerUser))
  const [saving, setSaving] = useState<boolean>(false)
  const [createMode, setCreateMode] = useState<string>(CREATE_MODES.CUSTOM)
  const [readyToSaveFromTemplateId, setReadyToSaveFromTemplateId] = useState<number | null>(null)
  const [allTemplateTasksComplete, setAllTemplateTasksComplete] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] = useState<ICarePlanTemplate | null>(null)

  const resetToDefaultState = () => {
    setTitle('')
    setNotes('')
    setTasks(getDefaultTasks(providerUser))
    setSaving(false)
    setCreateMode(CREATE_MODES.CUSTOM)
    resetTemplateWorkflow()
  }

  const loadCarePlan = carePlan => {
    const { title: newTitle, notes: newNotes } = carePlan
    const newTasks = carePlan.tasks.length
      ? carePlan.tasks.map(task => ({
          ownerGroup: task.ownerGroup,
          id: task.id,
          title: task.title,
          dueDate: Moment(task.dueDate).format('YYYY-MM-DD'),
          isComplete: task.isComplete,
          priority: task.priority,
        }))
      : null

    setTitle(newTitle)
    setNotes(newNotes)
    if (newTasks) {
      setTasks(newTasks)
    }
  }

  const resetTemplateWorkflow = () => {
    setSelectedTemplate(null)
    setReadyToSaveFromTemplateId(null)
  }

  const {
    handler: loadCarePlanTemplates,
    loading: carePlanTemplatesLoading,
    result: carePlanTemplates,
  } = useMutation(() => apiClient.rest.get<ICarePlanTemplate[]>('/care-plan/templates/'), {
    initialLoading: true,
  })

  const {
    handler: loadPreviewData,
    loading: previewDataLoading,
    result: previewData,
    error: previewDataError,
  } = useMutation(
    async (selectedTemplate, patient) => {
      const result = (await apiClient.rest.get(
        `/care-plan/templates/${selectedTemplate.id}/preview/?user_id=${patient.id}`
      )) as ICarePlanTemplatePreview
      setTitle(result.title)
      setNotes(result.notes)
      setReadyToSaveFromTemplateId(selectedTemplate.id)
      return result
    },

    {
      initialLoading: true,
    }
  )

  const selectTemplate = (carePlanTemplateId: number | null, patient: any) => {
    if (!carePlanTemplateId) {
      resetTemplateWorkflow()
      return
    }
    const carePlanTemplate = carePlanTemplates.find(
      el => el.id === carePlanTemplateId
    ) as ICarePlanTemplate
    if (carePlanTemplateId !== selectedTemplate?.id) {
      setReadyToSaveFromTemplateId(null)
    }
    setSelectedTemplate(carePlanTemplate)
    loadPreviewData(carePlanTemplate, patient)
  }

  return {
    title,
    setTitle,
    notes,
    setNotes,
    tasks,
    setTasks,
    createMode,
    setCreateMode,
    readyToSaveFromTemplateId,
    setReadyToSaveFromTemplateId,
    allTemplateTasksComplete,
    setAllTemplateTasksComplete,
    selectedTemplate,
    setSelectedTemplate,
    saving,
    setSaving,
    resetToDefaultState,
    loadCarePlan,
    loadCarePlanTemplates,
    carePlanTemplates,
    carePlanTemplatesLoading,
    loadPreviewData,
    previewDataLoading,
    previewData,
    previewDataError,
    resetTemplateWorkflow,
    selectTemplate,
  }
}
