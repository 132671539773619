import { useState } from 'react'
import { Box, Collapse, Grid, IconButton, Typography, Chip } from '@mui/material'
import { PriorAuthorizationItemType } from './types'
import { apiClient } from '~/api/rest'
import { useQuery } from '~/components/Providers/ApiProvider'
import Loader from '~/components/Loader'
import PriorAuthorizationItem from './PriorAuthorizationItem'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { format } from 'date-fns'

interface Props {
  model: Pick<PriorAuthorizationItemType, 'pk' | 'updatedAt'>
}

const PriorAuthorizationRelatedView = ({ model }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  let collapseChipLabel: string | null = null
  const sentDate = model?.updatedAt
  if (sentDate) {
    collapseChipLabel = `Updated on ${format(new Date(sentDate), 'MMM D, YYYY')} at ${format(
      new Date(sentDate),
      'h:mma'
    )}`
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Grid sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <IconButton edge="start" onClick={() => setIsExpanded(c => !c)}>
            {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          <Typography fontSize={18} fontWeight="500">
            Prior Authorization
          </Typography>
        </Grid>
        {collapseChipLabel ? <Chip color="success" size="small" label={collapseChipLabel} /> : null}
      </Box>
      <Collapse in={isExpanded}>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item xs={12}>
            <PriorAuthorizationItemLazy pk={model.pk} />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default PriorAuthorizationRelatedView

const listPriorAuthorizationKey = 'listPriorAuthorizations'
const usePriorAuthorization = (pk: PriorAuthorizationItemType['pk']) => {
  return useQuery(
    [listPriorAuthorizationKey, pk],
    () => apiClient.rest.get<PriorAuthorizationItemType>(`/referral/prior_authorization/${pk}/`),
    {},
    {
      error: `Failed to fetch prior authorization ${pk}`,
    }
  )
}

// Helper for fetching the full PriorAuthorization prior to rendering the edit form.
const PriorAuthorizationItemLazy = ({ pk }: { pk: PriorAuthorizationItemType['pk'] }) => {
  const { data: priorAuth, isLoading } = usePriorAuthorization(pk)

  if (isLoading || !priorAuth) {
    return <Loader />
  }

  return <PriorAuthorizationItem priorAuth={priorAuth} />
}
