import Moment from 'moment'
import { memo, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  TextField,
  colors,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CheckIcon from '@mui/icons-material/Check'
import { WorklistGridRow } from './WorklistTable'
import { workUnitsSlice } from '~/redux/slices/workunits'
import { patientsSlice } from '~/redux/slices/patients'
import { useWorklistDispatch } from '~/redux/slices/worklists'
import Loader from '../Loader'
import PatientDetailTopBar from '../PatientDetail/PatientDetailTopBar'
import FormLabel from '@mui/material/FormLabel'
import usePatientDetail from '../PatientDetail/utils/usePatientDetail'
import FFModal from '../FFModal/FFModal'
import { IAction } from '~/models/StateMachine'
import Autocomplete from '@mui/material/Autocomplete'
import { WorklistEditorFieldBuilder } from './utils'
import UserGroupSelect from '../Generic/UserGroupSelect'
import { useGroupOptions } from '~/utils/useGroupOptions'

const useStyles = makeStyles()(theme => {
  return {
    container: {
      padding: theme.spacing(2),
      height: '100%',
    },
    fields: {
      marginTop: theme.spacing(2),
    },
    readOnlyFields: {
      marginTop: theme.spacing(2),
      color: colors.common.black,
      '& label.MuiInputLabel-root': {
        color: colors.common.black,
      },
    },
    readOnlyFieldContent: {
      color: colors.common.black,
      '& label.MuiInputLabel-root': {
        color: colors.common.black,
      },
    },
    actions: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    dataFields: {
      padding: theme.spacing(3),
    },
    fieldsContainer: {
      flexGrow: 1,
    },
    formContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }
})

export interface WorklistEditorProps {
  worklistRow: WorklistGridRow
  handleClose: () => void
  isWorklistWithWorkUnits: boolean
}

const WorklistEditor: FC<WorklistEditorProps> = props => {
  const [loading, setLoading] = useState(false)
  const worklistDispatch = useWorklistDispatch()
  const dispatch = useDispatch()
  const { worklistRow, isWorklistWithWorkUnits } = props
  const { switchPatient } = usePatientDetail()
  const patient = useSelector(state => {
    return worklistRow.patientId ? state.patients.byId[worklistRow.patientId] : null
  })
  const [modalData, setModalData] = useState<{
    values: WorklistGridRow
    action: string
    descriptions: string[]
  } | null>(null)
  // Setting the current status and action as the selected action. To show it as the selected action in the dropdown.
  const [selectedAction, setSelectedAction] = useState<IAction>({
    dest: worklistRow.status || '',
    trigger: worklistRow.action,
  })

  useEffect(() => {
    if (worklistRow.patientId != null) {
      dispatch(patientsSlice.thunks.getPatient(worklistRow.patientId))
      switchPatient(worklistRow.patientId)
    }
  }, [worklistRow.patientId])

  const providers = useSelector(state => state.providers)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: worklistRow,
    onSubmit: async values => {
      const descriptions = selectedAction.descriptions || []
      if (descriptions.length > 0) {
        setModalData({
          values: values,
          action: values.action!,
          descriptions: descriptions,
        })
      } else {
        updateWorklistRow(values)
      }
    },
  })

  const closeEditor = async () => {
    setModalData(null)
    props.handleClose()
  }

  const updateWorkUnitItem = async values => {
    await worklistDispatch(
      workUnitsSlice.thunks.updateWorklistWorkUnitItem({
        id: Number(worklistRow.id),
        worklist: worklistRow.worklist.id,
        action: values.action,
        contentType: worklistRow.contentType,
        ...{ ownerGroup: values.ownerGroup },
        ...(values.dueDate && {
          dueDate: Moment(values.dueDate).format(),
        }),
      })
    )
  }
  const updateWorklistRow = async values => {
    setLoading(true)
    try {
      updateWorkUnitItem(values)
    } catch (e) {
      console.error(e)
    }
    formik.resetForm({ values: values })
    closeEditor()
    setLoading(false)
  }

  const localWorklistRow: WorklistGridRow = formik.values
  const setValues = (name: string, value) => ({
    name,
    onChange: e => {
      e.persist()
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    },
    value,
  })

  const readOnlyFields = new WorklistEditorFieldBuilder({
    row: localWorklistRow,
    isWorklistWithWorkUnits: isWorklistWithWorkUnits,
  }).createFields()

  const { selectedCaseOwnerGroupOption, flatOptions, groupedOptions } = useGroupOptions(
    parseInt(formik.values.ownerGroup || '') || null,
    false,
    patient,
    patient?.person?.careTeam,
    false
  )

  const { classes } = useStyles()
  return (
    <div className={classes.container}>
      {modalData == null ? null : (
        <div>
          <FFModal open={true}>
            <>
              <Box m={2}>
                <h1>
                  <b>Review the action(s) that will be automated with this status change.</b>
                </h1>
                <List>
                  {modalData.descriptions.map(description => {
                    return (
                      <ListItem key={description}>
                        <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <b>{description}</b>
                        </ListItemText>
                      </ListItem>
                    )
                  })}
                </List>
                <Box m={2} display="flex" justifyContent="flex-end">
                  <Button onClick={() => setModalData(null)} disabled={loading}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => updateWorklistRow(modalData.values)}
                    disabled={loading}
                    color="primary"
                    variant="contained"
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            </>
          </FFModal>
        </div>
      )}
      <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
        {patient?.onboardingState && Object.keys(patient.onboardingState).length > 0 ? (
          <div>
            <PatientDetailTopBar patient={patient} providers={providers} />
          </div>
        ) : null}
        <div className={classes.dataFields}>
          <Grid container spacing={1} direction="row" className={classes.fieldsContainer}>
            {readOnlyFields.map(field => (
              <Grid item xs={6} key={field.id || field.fieldName}>
                <TextField
                  name={field.displayName}
                  value={
                    localWorklistRow[field.fieldName] ||
                    (field.id ? localWorklistRow[field.id] : '-')
                  }
                  label={field.displayName}
                  className={classes.readOnlyFields}
                  disabled={true}
                  fullWidth
                  multiline
                  key={field.id}
                  InputProps={{
                    classes: { input: classes.readOnlyFieldContent },
                    disableUnderline: true,
                  }}
                ></TextField>
              </Grid>
            ))}
          </Grid>

          {localWorklistRow.dueDate ? (
            <TextField
              {...setValues('dueDate', localWorklistRow.dueDate)}
              type="date"
              label="Next action Date"
              className={classes.fields}
              disabled={loading}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : null}
          {/* Some cases are closed automatically when all the tasks in it are complete.
              Disabled the option to change status if the case is going to be closed automatically.   */}
          {!localWorklistRow.contentObject?.autoComplete ? (
            <Box mt={1}>
              <FormLabel>Status</FormLabel>
              <Autocomplete
                style={{ width: '50%' }}
                disableClearable={true}
                autoHighlight
                value={selectedAction}
                options={localWorklistRow.actions || []}
                getOptionLabel={option =>
                  option.trigger && option.trigger !== option.dest
                    ? `${option.trigger.toUpperCase()} (${option.dest.toUpperCase()})`
                    : option.dest.toUpperCase()
                }
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedAction(newValue)
                  }

                  formik.setFieldValue('action', newValue?.trigger)
                  formik.setFieldTouched('action', true, false)
                }}
                renderInput={params => <TextField {...params} variant="standard" />}
              />
            </Box>
          ) : null}

          <Box mt={1}>
            <FormLabel>Owner</FormLabel>
            {
              <UserGroupSelect
                selectedValue={selectedCaseOwnerGroupOption}
                options={flatOptions}
                groupedOptions={groupedOptions}
                onChange={ownerGroupOption => {
                  const ownerGroup = ownerGroupOption.value
                  formik.setFieldValue('ownerGroup', ownerGroup)
                  formik.setFieldTouched('ownerGroup', true, false)
                }}
                disabled={loading}
                patient={patient}
              />
            }
          </Box>
        </div>
        <div className={classes.actions}>
          {loading && <Loader inline />}
          <Button type="button" disabled={loading} onClick={closeEditor}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading || !formik.dirty}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default memo(WorklistEditor)
