import { useEffect, useState } from 'react'

const STATE_TIMEZONES = {
  AL: 'America/Chicago',
  AK: 'America/Juneau',
  AR: 'America/Chicago',
  AZ: 'America/Phoenix',
  CA: 'America/Los_Angeles',
  CO: 'America/Denver',
  CT: 'America/New_York',
  DE: 'America/New_York',
  DC: 'America/New_York',
  FL: 'America/New_York',
  GA: 'America/New_York',
  HI: 'Pacific/Honolulu',
  ID: 'America/Boise',
  IL: 'America/Chicago',
  IN: 'America/Indiana/Indianapolis',
  IA: 'America/Chicago',
  KS: 'America/Chicago',
  KY: 'America/New_York',
  LA: 'America/Chicago',
  MA: 'America/New_York',
  ME: 'America/New_York',
  MD: 'America/New_York',
  MI: 'America/Detroit',
  MN: 'America/Chicago',
  MS: 'America/Chicago',
  MO: 'America/Chicago',
  MT: 'America/Denver',
  NE: 'America/Chicago',
  NV: 'America/Los_Angeles',
  NH: 'America/New_York',
  NJ: 'America/New_York',
  NM: 'America/Denver',
  NC: 'America/New_York',
  ND: 'America/Chicago',
  NY: 'America/New_York',
  OH: 'America/New_York',
  OK: 'America/Chicago',
  OR: 'America/Los_Angeles',
  PA: 'America/New_York',
  RI: 'America/New_York',
  SC: 'America/New_York',
  SD: 'America/Chicago',
  TN: 'America/Chicago',
  TX: 'America/Chicago',
  UT: 'America/Denver',
  VT: 'America/New_York',
  VA: 'America/New_York',
  WA: 'America/Los_Angeles',
  WV: 'America/New_York',
  WI: 'America/Chicago',
  WY: 'America/Denver',
}

const patientTimezone = (patientState?: string | null) => {
  if (!patientState) return
  return STATE_TIMEZONES[patientState]
}

export const PatientStateAndTimeInfo: React.FC<{
  patientState: string | null | undefined
}> = ({ patientState }) => {
  // Show the patient's state and, if there is a timezone difference
  // between the patient's state and the current user,
  // also show the current time in the patient's timezone
  const [date, setDate] = useState<Date | null>(null)
  const [offsetTimezone, setOffsetTimezone] = useState<string | null>(null)

  useEffect(() => {
    if (!patientState) return
    const newOffsetTimezone = patientTimezone(patientState)
    if (newOffsetTimezone) {
      setOffsetTimezone(newOffsetTimezone)
      setDate(new Date())
      // Once we have a date, we don't need to do any more timezone math
      // so we can just start a clock
      const clockUpdateInterval = setInterval(() => setDate(new Date()), 1000)
      return () => clearInterval(clockUpdateInterval)
    }
  }, [patientState])

  if (!date) return <>{patientState}</>

  // Show a string like "MA (1:00 PM)"
  const timeString = date.toLocaleTimeString('en-US', {
    timeZone: offsetTimezone!,
    timeStyle: 'short',
  })
  return <>{`${patientState} (${timeString})`}</>
}
