import { Box, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import { SymptomItem } from './PrimaryConcern'

type BookProps = {
  apptReason: string
  visitReason: string | null
  setApptReason: (e) => void
  saving: boolean
  symptomsSelected: SymptomItem[]
  otherSymptomsSelected: string[]
}
const VISIT_REASON_BASED_CONTENT = {
  video: { placeholder: 'Additional information (Member-Facing)', isRequired: false },
  focused: { placeholder: 'Additional information (Member-Facing)', isRequired: false },
  videoNew: { placeholder: 'Concerns to discuss (Member-Facing)', isRequired: true },
  annualWellnessVisitEstablished: {
    placeholder: 'Concerns to discuss (Member-Facing)',
    isRequired: true,
  },
  annualWellnessVisitNew: {
    placeholder: 'Concerns to discuss (Member-Facing)',
    isRequired: true,
  },
  behavioralHealth: {
    placeholder: 'Reason for visit (Member-Facing)',
    isRequired: true,
  },
  healthGuideConsult: {
    placeholder: 'Reason for visit (Member-Facing)',
    isRequired: true,
  },
}

const BookingReason: FC<BookProps> = ({
  apptReason,
  setApptReason,
  visitReason,
  saving,
  symptomsSelected,
  otherSymptomsSelected,
}) => {
  const [reasonError, setReasonError] = useState<boolean>(false)
  const enableLucianTriageFlow: boolean = useFeatureFlag('appointments.enableLucianTriageFlow')
  let symptomsList: string[] = symptomsSelected.map(({ label }) => label)
  if (otherSymptomsSelected.length) {
    symptomsList = symptomsList.concat(otherSymptomsSelected)
  }

  const updateApptReason = e => {
    if (e.target.value == '') {
      setReasonError(true)
    }
  }

  const handleUpdateApptReason = (event: any) => {
    if (event) {
      setApptReason(event.target?.value)
      if (event.target.value !== '') {
        setReasonError(false)
      } else {
        setReasonError(true)
      }
    }
  }

  return (
    <Box m={2}>
      {enableLucianTriageFlow && (visitReason == 'video' || visitReason == 'focused') ? (
        <Box my={2}>
          <Typography>
            {'Primary concerns: '}
            {symptomsList.join(', ')}
          </Typography>
        </Box>
      ) : null}
      <TextField
        id="outlined-basic"
        variant="outlined"
        value={apptReason}
        onChange={e => handleUpdateApptReason(e)}
        multiline={true}
        fullWidth
        placeholder={
          enableLucianTriageFlow
            ? VISIT_REASON_BASED_CONTENT[visitReason as keyof typeof VISIT_REASON_BASED_CONTENT]
                .placeholder
            : 'Reason for visit (Member-Facing)'
        }
        disabled={saving}
        error={reasonError}
        helperText={reasonError && 'Reason for visit required'}
        required={
          VISIT_REASON_BASED_CONTENT[visitReason as keyof typeof VISIT_REASON_BASED_CONTENT]
            .isRequired
        }
        onBlur={updateApptReason}
      />
    </Box>
  )
}

export default BookingReason
