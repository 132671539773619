import { CircularProgress, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { ICarePlanTemplate } from './useCarePlanModal'

interface ICarePlanTemplateOption {
  label: string
  disabled: boolean
  id: number | null
}

interface ISelectTemplateProps {
  carePlanTemplates: ICarePlanTemplate[]
  selectedTemplate: ICarePlanTemplate | null
  selectTemplate: (arg0: number | null) => void
  carePlanTemplatesLoading: boolean
  existingCarePlanTypeIds: { [key: number]: boolean }
}

const carePlanTemplateAsOption = (
  carePlanTemplate: ICarePlanTemplate,
  existingCarePlanTypeIds: { [key: number]: boolean }
): ICarePlanTemplateOption => {
  const carePlanOfTypeExists = existingCarePlanTypeIds[carePlanTemplate.typeId]
  let label = carePlanTemplate.typeDescription || ''
  const carePlanExistAndCannotAssignMultiple = carePlanOfTypeExists && !carePlanTemplate.episodic
  if (carePlanExistAndCannotAssignMultiple) {
    label += ' (Already assigned)'
  }
  return {
    label,
    disabled: carePlanExistAndCannotAssignMultiple,
    id: carePlanTemplate.id,
  }
}

const SelectTemplate = (props: ISelectTemplateProps) => {
  const menuOptions: ICarePlanTemplateOption[] = (props.carePlanTemplates || [])
    .map(carePlanTemplate =>
      carePlanTemplateAsOption(carePlanTemplate, props.existingCarePlanTypeIds)
    )
    .sort((a, b) => a.label.localeCompare(b.label))

  const currentlySelectedMenuOption: ICarePlanTemplateOption = props.selectedTemplate
    ? carePlanTemplateAsOption(
        props.selectedTemplate as ICarePlanTemplate,
        props.existingCarePlanTypeIds
      )
    : { label: '', disabled: true, id: null }

  if (props.carePlanTemplatesLoading) return <CircularProgress />
  return (
    <Autocomplete
      autoHighlight
      value={currentlySelectedMenuOption}
      onChange={(_, value) => {
        if (value) {
          props.selectTemplate(value.id)
        }
      }}
      id="care-plan-template-select"
      options={menuOptions}
      getOptionDisabled={option => option.disabled}
      getOptionLabel={option => option.label}
      renderInput={params => <TextField {...params} label="Choose template" variant="outlined" />}
      style={{ width: 500 }}
    />
  )
}

export default SelectTemplate
