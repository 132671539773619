import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { batch, useSelector, useDispatch } from 'react-redux'
import Loader from '~/components/Loader'
import PatientDetailTabs from '~/components/PatientDetail/PatientDetailTabs'
import PatientDetailTopBar from '~/components/PatientDetail/PatientDetailTopBar'
import { borderProps } from '~/utils/borderProps'
import PatientDetailChat from '~/components/PatientDetail/PatientDetailChat'
import usePatientDetail from '~/components/PatientDetail/utils/usePatientDetail'
import { useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router'
import chatSlice from '~/redux/slices/chat'

import { usePubNubUtils } from '~/utils/pubnub'
import { queueNotification } from '~/redux/actions/notifications'
import { getDefaultPatientThread } from '../utils/chat'
import patientsSlice from '~/redux/slices/patients'
import { ExpandedModeContext, useExpandedModeControls } from '~/utils/useExpandedMode'
import { SelectedTodoContext, useManageSelectedTodo } from '~/utils/useManageSelectedTodo'

const GridItem = ({ children, ...props }) => (
  <Grid item minWidth={props.minWidth} xs={props.xs} component={Box} position="relative">
    <Box
      position="absolute"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      {...props}
    >
      {children}
    </Box>
  </Grid>
)

const PatientDetail: React.FC = () => {
  const pubnubClientPresent = !!useSelector(state => state.chat.pubnub)
  const [patientId, setPatientId] = useState<null | string>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [lastSearchQuery, setLastSearchQuery] = useState('')
  const [loaded, setLoaded] = useState(false)
  const match = useRouteMatch<{ id: string }>()
  const history = useHistory()
  const patient = useSelector(state => state.patients.byId[match?.params?.id])
  const isSearching = useSelector(state => state.chat.isSearching)
  const searchResults = useSelector(state => state.chat.searchResults)
  const searchResultIndex = useSelector(state => state.chat.searchResultIndex)
  const providers = useSelector(state => state.providers)
  const patientDetail = usePatientDetail()
  const pubNubUtils = usePubNubUtils()
  const dispatch = useDispatch()
  const {
    LefthandGridContainerProps,
    toggleExpandedMode,
    resetExpandedMode,
    expandedModeIsActive,
  } = useExpandedModeControls()
  const {
    selectedCaseId,
    setSelectedCaseId,
    selectedCarePlanId,
    setSelectedCarePlanId,
    selectPayload,
  } = useManageSelectedTodo(match.params.id)

  useEffect(() => {
    if (match.params?.id) {
      getPatient(match.params.id)
    }
  }, [match.params?.id])

  useEffect(() => {
    // Handle presence events
    if (match.params?.id) {
      const interval = initPresenceHeartbeat()

      return () => {
        pubNubUtils.updatePresence({
          viewing: false,
        })

        clearInterval(interval)
      }
    }
  }, [match.params?.id, pubnubClientPresent])

  const initPresenceHeartbeat = () => {
    // Broadcast that we're viewing this patient
    const presenceHeartbeat = () =>
      pubNubUtils.updatePresence({
        viewing: true,
        patientId: match.params.id,
      })

    presenceHeartbeat()
    return setInterval(presenceHeartbeat, 1000 * 10)
  }

  const getPatient = async (id: string) => {
    setLoaded(false)
    try {
      await patientDetail.switchPatient(id)
      await dispatch(patientsSlice.thunks.getPatient(id))
      setPatientId(id)
      setLoaded(true)
    } catch (e) {
      history.push('/patients')
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Patient does not exist',
        })
      )
    }
  }

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return
    }
    setLastSearchQuery(searchQuery)
    const threadUid = getDefaultPatientThread(patientId)
    return dispatch(chatSlice.thunks.loadMessageSearchResults(threadUid, searchQuery))
  }

  const handleSearchQueryChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchQuery('')
    setLastSearchQuery('')
    batch(() => {
      dispatch(chatSlice.actions.setSearchResultIndex(null))
      dispatch(chatSlice.actions.clearHistoricalMessages())
      dispatch(chatSlice.actions.clearSearchResults())
    })
  }

  const handleSearchBar = () => {
    const newIsSearching = !isSearching
    dispatch(chatSlice.actions.setIsSearching(newIsSearching))
    if (!newIsSearching) {
      handleClearSearch()
    }
  }

  const handleArrowSearchResults = (threadUid: string, offset: number) => {
    if (!searchResults) return Promise.resolve()
    const searchResultKey = `${threadUid},${lastSearchQuery}`
    const newIndex = Math.max(
      0,
      Math.min(searchResults[searchResultKey].total - 1, (searchResultIndex || 0) + offset)
    )
    const msgId = searchResults[searchResultKey].hits[newIndex].id
    return dispatch(chatSlice.thunks.loadThreadHistoryAt(threadUid, msgId, 10, 10, newIndex))
  }

  if (!patient) return null
  if (!loaded) return <Loader />

  return (
    <ExpandedModeContext.Provider
      value={{ toggleExpandedMode, expandedModeIsActive, resetExpandedMode }}
    >
      <SelectedTodoContext.Provider
        value={{
          selectedCaseId,
          selectedCarePlanId,
          setSelectedCaseId,
          selectPayload,
          setSelectedCarePlanId,
        }}
      >
        <Grid
          container
          component={Box}
          wrap="nowrap"
          position="absolute"
          width="100%"
          height="100%"
        >
          <GridItem {...LefthandGridContainerProps}>
            <PatientDetailTopBar patient={patient} providers={providers} />
            <PatientDetailTabs patient={patient} />
          </GridItem>
          <GridItem minWidth={400} xs={5} {...borderProps(['borderLeft'])}>
            <PatientDetailChat
              patient={patient}
              searchQuery={searchQuery}
              lastSearchQuery={lastSearchQuery}
              isSearching={isSearching}
              handleSearchBar={handleSearchBar}
              handleSearchQueryChange={handleSearchQueryChange}
              handleSearch={handleSearch}
              handleClearSearch={handleClearSearch}
              handleArrowSearchResults={handleArrowSearchResults}
            />
          </GridItem>
        </Grid>
      </SelectedTodoContext.Provider>
    </ExpandedModeContext.Provider>
  )
}

export default PatientDetail
