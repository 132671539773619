import { ProviderUser } from '~/legacy/core'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

interface ProviderDataInterface extends ProviderUser {
  displayName: string
  canProvideCare: boolean
}

interface PropsInterface {
  roleName: string
  selectedCareTeam: any
  options: ProviderDataInterface[]
  disabled?: boolean
  setSelectedCareTeam: (arg0: any) => any
  setShowCancel: (arg0: any) => any
  title?: string
}

const ProviderSelectorRadioButtonsGroup = (props: PropsInterface) => {
  const {
    roleName,
    selectedCareTeam,
    options,
    disabled,
    setSelectedCareTeam,
    setShowCancel,
    title,
  } = props

  const { classes } = useStyles()

  const unselectableProviderReason = (
    provider: ProviderDataInterface | undefined
  ): string | null => {
    if (!provider) return 'No provider selected'
    if (!provider.canProvideCare) return `${provider.displayName} is not in-state`
    return null
  }

  const handleClick = event => {
    const providerId = parseInt(event?.target.value)
    // Allow deselecting
    // If the provider is already the choice for the option, unset it
    if (selectedCareTeam[roleName] == providerId) {
      setSelectedCareTeam({
        ...selectedCareTeam,
        [roleName]: null,
      })
    } else {
      const provider = options.find(option => option.id === providerId)
      if (unselectableProviderReason(provider)) return
      setSelectedCareTeam({
        ...selectedCareTeam,
        [roleName]: providerId,
      })
    }

    setShowCancel(true)
  }

  return (
    <FormControl component="fieldset">
      {title && (
        <FormLabel color="primary" component="legend">
          {title}
        </FormLabel>
      )}
      <RadioGroup value={selectedCareTeam[roleName]} onClick={handleClick}>
        {options &&
          options.map(provider => {
            let label = provider.displayName
            // We still allow selecting a provider marked as not accepting new patients
            // so that we don't block any clinical workflows
            // But, we should show a hint here to help with load-balancing when
            // our team does have to do manual care team selection
            if (provider.providerFields.isTakingNewPatients == false) {
              label += ' (Closed)'
            }

            return (
              <FormControlLabel
                key={provider.id}
                value={provider.id}
                control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                label={label}
                disabled={
                  selectedCareTeam[roleName] != provider.id && // always allow de-selecting the chosen provider
                  (disabled || !!unselectableProviderReason(provider))
                }
                title={unselectableProviderReason(provider) || ''}
              />
            )
          })}
      </RadioGroup>
    </FormControl>
  )
}

const useStyles = makeStyles()(theme => ({
  radio: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))

export default ProviderSelectorRadioButtonsGroup
