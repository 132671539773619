import type { FC } from 'react'
import PriorAuthorizationList from '~/components/PatientDetail/PriorAuthorization/PriorAuthorizationList'
import { PatientUser } from '~/legacy/core'

export interface PriorAuthorizationProps {
  patient: PatientUser
}

const PriorAuthorization: FC<PriorAuthorizationProps> = props => {
  return <PriorAuthorizationList person={props.patient.person} />
}

export default PriorAuthorization
