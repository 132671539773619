import { useState } from 'react'

import { apiClient } from '~/api/rest'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'
import usePatientDetail from '~/components/PatientDetail/utils/usePatientDetail'
import { patientsSlice } from '~/redux/slices/patients'
import { Box, Typography } from '@mui/material'
import ProgramSwitch from '../ProgramSwitch'

const ClinicalProgram = ({ programs, patient }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { refetch: loadEnrolledPrograms } = usePatientDetail()
  const dispatch = useDispatch()

  const refreshAfterEnrollmentChanges = async () => {
    try {
      await Promise.all([
        loadEnrolledPrograms(),
        // Reload the patient here because some programs like RTW
        // use patient state to display additional info
        dispatch(patientsSlice.thunks.getPatient(patient.id)),
      ])
    } catch (e) {
      console.error(e)
      dispatch(
        queueNotification({
          message: `Unable to load enrolled programs`,
          variant: 'error',
        })
      )
    }
  }

  const addToProgram = async programUid => {
    setIsLoading(true)
    try {
      const payload = { patient_id: patient.id, program_uid: programUid }
      await apiClient.rest.post(`/programs/add_patient/`, payload)
      await refreshAfterEnrollmentChanges()
      dispatch(
        queueNotification({
          variant: 'success',
          message: `Enrolled in the ${programUid} program`,
        })
      )
    } catch (e) {
      console.error(e)
      dispatch(
        queueNotification({
          message: `Unable to enroll in the ${programUid} program`,
          variant: 'error',
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  const removeFromProgram = async programUid => {
    setIsLoading(true)
    try {
      const payload = { patient_id: patient.id, program_uid: programUid }
      await apiClient.rest.post(`/programs/remove_patient/`, payload)
      await refreshAfterEnrollmentChanges()
      dispatch(
        queueNotification({
          variant: 'success',
          message: `Removed from ${programUid} program`,
        })
      )
    } catch (e) {
      console.error(e)
      dispatch(
        queueNotification({
          variant: 'error',
          message: `Unable to remove from the ${programUid} program`,
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onProgramToggle = (enrolled, programUid) => {
    if (!enrolled) {
      return removeFromProgram(programUid)
    }
    return addToProgram(programUid)
  }

  return (
    <Box>
      <Typography variant={'h6'}>Clinical Programs</Typography>
      {programs.map((program, i) => {
        return (
          <ProgramSwitch
            key={i}
            program={program}
            onToggle={onProgramToggle}
            isLoading={isLoading}
            patient={patient}
          />
        )
      })}
    </Box>
  )
}

export default ClinicalProgram
