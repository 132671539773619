import { useEffect } from 'react'
import type { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '@auth0/auth0-react'

import { removeNotification } from '~/redux/actions/notifications'
import { TIMEOUT_NOTIFICATION_KEY } from './SessionNotifier'

let displayed: number[] = []

/**
 * Redux implementation of Notistack borrowed from their codesandbox example
 * Notistack builds on Material-UI's snackbar components and allows us to
 * easily orchestrate notifications.
 * API: https://iamhosseindhv.com/notistack/api
 */
export const Notifications: FC = () => {
  const { isAuthenticated } = useAuth0()
  const dispatch = useDispatch()
  const notifications = useSelector<any, any[]>(state => state.notifications.notifications)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = id => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)]
  }

  useEffect(() => {
    if (isAuthenticated) closeSnackbar(TIMEOUT_NOTIFICATION_KEY)
  }, [isAuthenticated])

  useEffect(() => {
    notifications.forEach(({ key, message, dismissed = false, ...options }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeNotification(myKey))
          removeDisplayed(myKey)
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}

export default Notifications
