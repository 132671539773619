import type { FC } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

import Notifications from '~/components/Notifications/Notifications'
import AppointmentModal from '~/components/Appointment/AppointmentModal'
import EducationRoot from '~/components/Education'

const Root: FC = () => (
  <>
    <CssBaseline />
    <Notifications />
    <AppointmentModal />
    <EducationRoot />
  </>
)

export default Root
