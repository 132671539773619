import Loader from '~/components/Loader'
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  SvgIconTypeMap,
  Tooltip,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import {
  apiClientWithoutRequestDecamelization as _apiClientWithoutRequestDecamelization,
  apiClient as _apiClient,
} from '~/api/rest'
import { styled } from '@mui/material/styles'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

import {
  CalendarToday,
  VisibilityOff,
  Check,
  ContentCopy,
  Launch,
  Timer,
  Block,
  Info,
} from '@mui/icons-material'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { AppointmentCardMenu } from './AppointmentCardMenu'
import { isToday } from './utils'
import { setActiveAppointment } from '~/redux/actions/appointments'
import { useDispatch } from 'react-redux'
import { membersWebAppUrl } from '~/utils'
import { queueNotification } from '~/redux/actions/notifications'
import { Link } from 'react-router-dom'
import { GREY, YELLOW } from '~/utils/theme'
import {
  Appointment,
  useGetPatientAppointmentsInfo,
  AppointmentCancelation,
  useGetPatientAppointmentCancelationsInfo,
} from '.'
import { useFeatureFlag } from '~/utils/useFeatureFlag'

export const VISIT_REASON_TO_LABEL = {
  Video: 'Established',
  'Video-New': 'New Patient',
  'Behavioral Health': 'BH Visit',
  'Health Guide Consult': 'HG Visit',
  'Annual Wellness Visit Established': 'Est. Annual Wellness',
  'Annual Wellness Visit New': 'New Patient Annual Wellness',
}

const FULL_DATE_STR_FORMAT = 'MMM D, YYYY, h:mmA'
const TIME_OF_DAY_STR_FORMAT = 'h:mmA'
const DATE_STR_FORMAT = 'MMM D, YYYY'

const STATUS_ICON_PROPS: DefaultComponentProps<SvgIconTypeMap<{}, 'svg'>> = {
  fontSize: 'small',
}
const STATUS_ICONS_MAP = {
  'Checked Out': <Check {...STATUS_ICON_PROPS} />,
  'Not Seen': <VisibilityOff {...STATUS_ICON_PROPS} />,
  Scheduled: <CalendarToday {...STATUS_ICON_PROPS} />,
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#21272A',
    backgroundColor: '#C6C6C6',
    fontSize: '12px',
    fontWeight: 400,
  },
})

export const AppointmentCard: React.FC<{
  appointment:
    | Awaited<ReturnType<typeof useGetPatientAppointmentsInfo>>['data']
    | Awaited<ReturnType<typeof useGetPatientAppointmentCancelationsInfo>>['data']
  patientId: number
  selectAppointmentForCancel: (appointment: Appointment | AppointmentCancelation) => void
  isCanceled?: boolean
}> = props => {
  const { appointment, isCanceled } = props
  const isAwvVisitTypeEnabled: boolean = useFeatureFlag('appointment.enableAwvVisitType')

  const canceledBy =
    appointment.canceledBy == 'patient'
      ? 'patient'
      : appointment.canceledByProviderName ?? 'Unknown'

  const dispatch = useDispatch()
  const today = isToday(appointment)
  const showStatus = (!today || appointment.status !== 'Scheduled') && !isCanceled

  const copyMemberVideoLinkToClipboard = async () => {
    await navigator.clipboard.writeText(`${membersWebAppUrl}/visit/${appointment.id}`)
    dispatch(
      queueNotification({ variant: 'success', message: 'Copied member video link to clipboard' })
    )
  }

  const canceledDate = format(new Date(appointment.canceledAt), FULL_DATE_STR_FORMAT)
  const canceledByDetails = `Canceled by ${canceledBy} ${canceledDate}`

  const fireflyReasons = appointment.cancelationReasons?.['fireflyReasons']?.length
    ? `Provider reason: ${appointment.cancelationReasons['fireflyReasons'].join(', ')}`
    : undefined

  const patientReasons = appointment.cancelationReasons?.['patientReasons']?.length
    ? `Patient reason: ${appointment.cancelationReasons['patientReasons'].join(', ')}`
    : undefined

  const toolTipTitle = [canceledByDetails, fireflyReasons, patientReasons].join('\n')
  const label =
    isAwvVisitTypeEnabled &&
    appointment.reason === 'Video-New' &&
    appointment.scheduledAt > '2024-10-08' // Restricting the new label, to be shown only for appointments booked after release date
      ? VISIT_REASON_TO_LABEL[appointment.reason] + ' (No AWV)'
      : VISIT_REASON_TO_LABEL[appointment.reason] || appointment.reason

  return (
    <Box key={appointment.id} marginBottom={2}>
      <Paper variant="outlined">
        <Grid container>
          <Grid item xs={6} paddingY={2}>
            {/* Left side */}
            <Box paddingX={2}>
              {/* Basic info */}
              <Grid
                container
                columnSpacing={1}
                rowSpacing={1}
                columns={20}
                alignItems="top"
                width={500}
              >
                <Grid item xs={2}>
                  <Typography fontSize="1.2rem" lineHeight="1em" sx={{ color: 'text.secondary' }}>
                    Type
                  </Typography>
                </Grid>
                <Grid item xs={18}>
                  <Typography
                    fontSize="1.4rem"
                    lineHeight="1em"
                    fontWeight="bold"
                    sx={{ color: 'text.primary' }}
                  >
                    {label} ({appointment.durationMinutes} min)
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontSize="1.2rem" lineHeight="1em" sx={{ color: 'text.secondary' }}>
                    With
                  </Typography>
                </Grid>
                <Grid item xs={18}>
                  <Typography fontSize="1.4rem" lineHeight="1em" sx={{ color: 'text.primary' }}>
                    {appointment.providerName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography fontSize="1.2rem" lineHeight="1em" sx={{ color: 'text.secondary' }}>
                    For
                  </Typography>
                </Grid>
                <Grid item xs={18}>
                  {appointment.symptomsSummary ? (
                    <Typography
                      fontSize="1.4rem"
                      lineHeight="1em"
                      sx={{ color: 'text.primary' }}
                      marginBottom={1.5}
                    >
                      {appointment.symptomsSummary}
                    </Typography>
                  ) : null}
                  <Typography fontSize="1.4rem" lineHeight="1em" sx={{ color: 'text.primary' }}>
                    {appointment.description || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            paddingTop={1}
            paddingBottom={2}
            display="flex"
            justifyContent="right"
            alignItems="baseline"
          >
            {/* Right side */}
            <Box display="flex" marginBottom={2} paddingX={2} alignItems="center">
              {/* Top bar */}
              <Box textAlign="right" display="flex" alignItems="center">
                {/* Links */}
                {today && !isCanceled ? (
                  <Box display="inline-flex" alignItems="center">
                    <Chip
                      label="Today"
                      icon={<Timer />}
                      sx={{
                        backgroundColor: YELLOW[100],
                        marginRight: 3,
                      }}
                    />
                    <Tooltip title="Copy Member Video Link">
                      <IconButton onClick={copyMemberVideoLinkToClipboard}>
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open in new tab">
                      <IconButton
                        component={Link}
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`/visit/${appointment.id}`}
                      >
                        <Launch />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : null}
                {showStatus ? (
                  <Box marginRight={2.5} display="flex" alignItems="flex-end">
                    {STATUS_ICONS_MAP[appointment.status]
                      ? STATUS_ICONS_MAP[appointment.status]
                      : null}
                    <Typography fontSize="1.2rem" fontWeight="500" marginLeft={0.5}>
                      {appointment.status}
                    </Typography>
                  </Box>
                ) : null}
                {isCanceled ? (
                  <>
                    <Box display="flex">
                      <Box mr={1} display="flex">
                        <Block style={{ fontSize: '18px', color: '#4D5358' }} />
                        <Typography
                          ml={0.75}
                          sx={{ fontSize: '13px', fontWeight: 500, verticalAlign: 'center' }}
                          color="#071F3E"
                        >
                          Canceled by&nbsp;
                          {(appointment.canceledBy == 'patient'
                            ? 'patient'
                            : `${appointment.canceledByProviderName}`) ?? 'Unknown'}
                        </Typography>
                      </Box>
                    </Box>
                    <CustomTooltip
                      title={<div style={{ whiteSpace: 'pre-line' }}>{toolTipTitle}</div>}
                    >
                      <Info
                        style={{
                          fontSize: '18px',
                          color: '#878D96',
                          marginLeft: '1px',
                          marginRight: '6px',
                        }}
                      />
                    </CustomTooltip>
                  </>
                ) : null}
                <AppointmentCardMenu
                  appointment={{
                    id: appointment.id,
                    patient: props.patientId,
                    start: appointment.start,
                    duration: `0:${appointment.durationMinutes}`,
                    status: appointment.status,
                    physician: {
                      provider: {
                        pk: appointment.providerId,
                      },
                    },
                    cancelableStatus: appointment.cancelableStatus,
                  }}
                  selectAppointmentForCancel={props.selectAppointmentForCancel}
                />
                {today && !isCanceled ? (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => dispatch(setActiveAppointment(appointment.id))}
                    sx={{ borderRadius: 10 }}
                  >
                    Join
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Box
          paddingX={2}
          paddingY={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Bottom bar */}
          <Box display="flex" alignItems="center">
            {/* Left side */}
            <Typography variant="body2" marginRight={1} sx={{ color: GREY[400] }}>
              <CalendarToday fontSize="small" />
            </Typography>
            <Typography fontSize="1.4rem" sx={{ color: 'text.primary' }} marginRight={2.5}>
              {format(new Date(appointment.start), DATE_STR_FORMAT)}
            </Typography>
            <Typography fontSize="1.4rem" sx={{ color: 'text.primary' }}>
              {format(new Date(appointment.start), TIME_OF_DAY_STR_FORMAT)}&nbsp;&#8211;&nbsp;
              {format(new Date(appointment.end), TIME_OF_DAY_STR_FORMAT)}
            </Typography>
          </Box>
          <Box>
            {/* Right side */}
            <Typography fontSize="1.2rem" marginRight={1} sx={{ color: 'text.secondary' }}>
              Booked by&nbsp;
              {(appointment.scheduledBy == 'patient'
                ? 'patient'
                : appointment.scheduledByProviderName) ?? 'Unknown'}
              ,&nbsp;{format(new Date(appointment.scheduledAt), FULL_DATE_STR_FORMAT)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

const AppointmentsTab: React.FC<{
  patientId: number
  data: Awaited<ReturnType<typeof useGetPatientAppointmentsInfo>>['data']
  isLoading: boolean
  selectAppointmentForCancel: (appointment: Appointment) => void
}> = ({ patientId, isLoading, data, selectAppointmentForCancel }) => {
  if (isLoading || !data.appointments) return <Loader />

  if (!data.appointments.length) {
    return <Typography variant="h6">No appointments</Typography>
  }

  return (
    <Box>
      {data.appointments.map(appointment => (
        <AppointmentCard
          key={appointment.id}
          appointment={appointment}
          patientId={patientId}
          selectAppointmentForCancel={selectAppointmentForCancel}
        />
      ))}
    </Box>
  )
}

export default AppointmentsTab
