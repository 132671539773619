import { apiClient } from '~/api/rest'
import { useMutation } from '~/components/Providers/ApiProvider'
import { queueNotification } from '~/redux/actions/notifications'
import { useDispatch } from 'react-redux'

export interface CuratedProviderContactInformation {
  phone: string | null
  fax: string | null
  isVerified: boolean | null
  specialtyGroup: number | null
}

export interface CuratedProviderAvailability {
  numberOfDaysTillNextAvailability: number | null
  addedAt: string | null
  specialtyGroup: number | null
  doesProviderExist: boolean | null
}

export interface UpdateCuratedProvider {
  npi: string | null
  careOrgName: string | null
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  availability?: CuratedProviderAvailability | null
  contactInformation?: CuratedProviderContactInformation | null
}

/**
 * editCuratedProvider
 */
const editCuratedProvider = payload => {
  return apiClient.rest.post(`/network/curated-provider/`, payload)
}
export const useEditCuratedProvider = () => {
  const dispatch = useDispatch()
  return useMutation(
    (payload: UpdateCuratedProvider) => editCuratedProvider(payload),
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            message: `Provider details updated. Refresh browser to view.`,
            variant: 'success',
          })
        )
      },
    },
    { error: `Unable to update provider details` }
  )
}
