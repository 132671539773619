import { Switch, Redirect, Route } from 'react-router-dom'
import Messages from '~/screens/Messages'
import PatientList from '~/redux/containers/VisiblePatientList'
import PatientDetail from '~/screens/PatientDetail'
import CaseDetail from '~/screens/CaseDetail'
import Inbox from '~/screens/Inbox'
import Settings from '~/screens/Settings'
import Worklist from '~/screens/Worklist'
import PersonDetail from '~/screens/PersonDetail'
import { PatientDetailContextProvider } from '../PatientDetail/utils/patientDetailContext'
import PHIAccessBlock from '~/components/PHIAccessBlock'
import { Box } from '@mui/material'
import FeatureAccessRoute from '~/components/Router/FeatureAccessRoute'
import AddNewMember from '~/components/PersonDetail/AddNewMember'
import { Navigate } from 'react-router-dom-v5-compat'
import ProviderScheduleFullCalendar from '../Providers/ProviderScheduleFullCalendar'

const InterfaceSwitch = () => {
  const patientProfileTabDestination = 'todos'
  const personProfileTabDestination = 'profile'

  return (
    <Box
      sx={{
        flex: 1,
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <Switch>
        <Route
          path="/cases/:id"
          render={routeProps => <CaseDetail id={routeProps.match.params.id} />}
        />
        <FeatureAccessRoute path="/inbox" feature="tasks" component={Inbox} />
        <Route path="/tasks" render={() => <Navigate to={`/inbox`} />} feature="tasks" />
        <Route path="/messages" component={Messages} />
        <Route exact path="/patients" component={PatientList} />
        <FeatureAccessRoute exact path="/worklist" feature="worklists" component={Worklist} />
        <Route
          exact
          path="/patients/:id"
          render={route => (
            // This preserves search parameters when redirecting to the patient detail view
            <Redirect
              to={`/patients/${route.match.params.id}/${patientProfileTabDestination}${route.location.search}`}
            />
          )}
        />
        <Route
          path="/patients/:id"
          render={routeProps => (
            <PatientDetailContextProvider>
              <PHIAccessBlock userId={routeProps.match.params.id}>
                <PatientDetail />
              </PHIAccessBlock>
            </PatientDetailContextProvider>
          )}
        />
        <Route exact path="/people/add" component={AddNewMember} />
        <Route exact path="/schedule" component={ProviderScheduleFullCalendar} />
        <Route
          exact
          path="/people/:id"
          render={route => (
            // This is to preserve search parameters when redirecting to the person detail view
            <Redirect
              to={`/people/${route.match.params.id}/${personProfileTabDestination}${route.location.search}`}
            />
          )}
        />
        <Route
          path="/people/:id"
          render={routeProps => (
            <PersonDetail personId={routeProps.match.params.id} providers={[]} />
          )}
        />
        <Route
          exact
          path="/person/:id"
          render={route => (
            // This is to preserve search parameters when redirecting to the person detail view
            <Redirect
              to={`/person/${route.match.params.id}/${personProfileTabDestination}${route.location.search}`}
            />
          )}
        />
        <Route
          path="/person/:id"
          render={routeProps => (
            <PersonDetail personId={routeProps.match.params.id} providers={[]} />
          )}
        />
        <Route path="/settings" component={Settings} />
      </Switch>
    </Box>
  )
}

export default InterfaceSwitch
