import { AssigneeGroup, ProviderFields } from '~/legacy/core'
import { GET_ME, GET_ME_ERROR } from '../actions/actionTypes'

export interface MeState {
  id: number | null
  firstName: string
  lastName: string
  email: string
  avatar: string
  error: string | null
  providerFields: ProviderFields | null
  testOnly: boolean
  groups: string[]
  assigneeGroup: AssigneeGroup | null
}

const INITIAL_STATE: MeState = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  avatar: '',
  error: null,
  testOnly: true,
  providerFields: null,
  groups: [],
  assigneeGroup: null,
}

const me = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ME: {
      const { id, firstName, lastName, email, testOnly, providerFields, groups, assigneeGroup } =
        action.me
      return {
        ...state,
        id,
        firstName,
        lastName,
        email,
        groups,
        testOnly,
        avatar: providerFields.avatar,
        assigneeGroup: assigneeGroup as AssigneeGroup,
        providerFields: providerFields as ProviderFields,
      }
    }
    case GET_ME_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

export default me
