import type { FC } from 'react'
import { usePromise } from '~/legacy/core'
import { Button, Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import EditField from '../../Generic/EditField'
import { apiClient } from '~/api/rest'

export interface PatientReferralProgram {
  patientReferralProgram: string
  patient: number
}

interface EditMembershipFormProps {
  patientReferralProgram: PatientReferralProgram
  loading: boolean
  handleSubmit: (values: PatientReferralProgram) => void
}

export const EditMembershipForm: FC<EditMembershipFormProps> = props => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.patientReferralProgram,
    onSubmit: values => {
      props.handleSubmit(values)
    },
  })

  const { result: patientReferralPrograms } = usePromise(() =>
    apiClient.rest.get(`/patient-referral-programs/`)
  )

  const { patientReferralProgram } = formik.values

  // Shortcut for change handlers and values
  const setValues = (name: string, value) => ({
    name,
    onChange: e => {
      e.persist()
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    },
    disabled: props.loading,
    value,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <EditField
          {...setValues('patientReferralProgram', patientReferralProgram)}
          label="Patient Referral Program"
          select
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: true }}
        >
          <option value="">(none)</option>
          {(patientReferralPrograms ?? []).map(patientReferralProgram => (
            <option key={patientReferralProgram.uid} value={patientReferralProgram.uid}>
              {patientReferralProgram.label}
            </option>
          ))}
        </EditField>
      </Grid>
      <Grid container spacing={1} justify="flex-end">
        {formik.dirty && (
          <Grid item>
            <Button onClick={() => formik.resetForm()}>Cancel Membership Detail Changes</Button>
          </Grid>
        )}
        <Grid item>
          <Button type="submit" color="primary" variant="outlined">
            Save Membership Details
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default EditMembershipForm
