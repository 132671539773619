import { orderBy, uniqBy } from 'lodash/fp'

import { ChatThread } from '../redux/slices/chat/slice'
import { IChatMessage } from '~/components/Chat/Chat'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '~/redux/reducers'

export const getDefaultPatientThread = id => `${id}.default_v1`
export const getPatientIdFromThread = (threadName: string) => threadName.split('.')[0]
export const getPubnubChannelForThread = thread => `thread.${thread}`

export const isOnboardingMessage = message => {
  if (!message.messageTags) return false
  return !!message.messageTags.find(tag => tag.tag === 'onboarding')
}

// `sentAt` keys on messages loaded through batch history are strings
// `sentAt` keys on messages loaded through PubNub are transformed into Dates
const sortMessages = (messages: IChatMessage[]) =>
  orderBy(m => Number(new Date(m.sentAt)), 'asc', messages)
const dedupeMessages = (messages: IChatMessage[]) => uniqBy('uid', messages)
export const processMessages = (messages: IChatMessage[]) => sortMessages(dedupeMessages(messages))

/**
 * Check if chat thread contain any unread messages
 * for new messages check pubnubMessageUnread flag otherwise isUnread flag
 * isUnread: this flag set in backend
 * pubnubMessageUnread: this flag set on the fly, when we get message from pubnub
 * @param thread: Patient chat thread
 * @returns boolean read true or false
 */
export const getIsRead = (thread: ChatThread) => {
  return !(thread.pubnubMessageUnread != null ? thread.pubnubMessageUnread : thread.isUnread)
}

export const isClinical = message => {
  if (!message.messageTags) return false
  return !!message.messageTags.find(tag => tag.tag === 'clinical')
}

export const isUrgent = message => {
  return message.isUrgentMessage
}

export const isOperational = message => {
  if (!message.messageTags) return false
  return !!message.messageTags.find(tag => tag.tag === 'operational')
}

// Selector for patient threads
export const getPatientThreads = createSelector(
  (state: RootState) => state.chat.patientThreads,
  patientThreads => {
    return patientThreads ? (Object.values(patientThreads) as any[]) : []
  }
)
