import { useCallback, useState } from 'react'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Fade, Typography, useTheme } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import { apiClient } from '~/api/rest'
import { usePromise } from '~/utils/usePromise'
import { PatientUser } from '~/types'
import { getDefaultPatientThread, getPubnubChannelForThread } from '~/utils/chat'
import Button from '../Generic/Button'
import usePatientDetail from '../PatientDetail/utils/usePatientDetail'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { patientName } from '~/utils/users'

interface JoinChatBannerProps {
  announced: boolean
  showIntroductionReminder: boolean
}

const JoinChatBanner: FC<JoinChatBannerProps> = ({ announced, showIntroductionReminder }) => {
  const [sentArrival, setSentArrival] = useState(false)
  const { palette } = useTheme()
  const { id } = usePatientDetail()
  const patients = useSelector(state => state.patients.byId)
  const pubnub = useSelector(state => state.chat.pubnub)
  const me = useSelector(state => state.me)
  const { result: careTeam, error } = usePromise(() =>
    apiClient.rest.get(`/user/${id}/care-team/v2/`)
  )
  const featureAccess = useFeatureAccess()

  const handleAnnounceArrival = useCallback(() => {
    if (pubnub) {
      // Optimistically hide the banner
      setSentArrival(true)
      pubnub.publish(
        {
          message: {
            uid: uuidv4(),
            sender: me.id,
            patient_id: Number(id),
            event: 'arrival',
            tenant: me.providerFields?.tenant,
          },
          channel: getPubnubChannelForThread(getDefaultPatientThread(id)),
        },
        // In case of a publishing error, show the banner again
        status => {
          if (status.error) setSentArrival(false)
        }
      )
    }
  }, [pubnub, id])

  if (!id || !careTeam || error) return null

  const patient = patients[id] as PatientUser
  if (!patient) return null

  const notOnCareTeam = !careTeam.find(user => user.id === me.id)

  // The "Join chat" prompt takes precedence over the introduction prompt
  //
  // A tenant who does not use onboarding and care teams should not need to
  // announce their arrival in chat
  const showJoinPrompt =
    notOnCareTeam && !sentArrival && !announced && featureAccess?.tenant.onboarding
  const showIntroductionPrompt = !showJoinPrompt && showIntroductionReminder
  return (
    <Fade in={showJoinPrompt || showIntroductionPrompt} mountOnEnter unmountOnExit>
      <Box bgcolor={palette.primary.light} px={2} py={1.5}>
        {showJoinPrompt ? (
          <Typography variant="body2">
            You&apos;re not on {patientName(patient)} {patient.lastName}&apos;s care team.&nbsp;
            <Button onClick={handleAnnounceArrival}>
              <Typography variant="body2" color="primary">
                Join the chat
              </Typography>
            </Button>
          </Typography>
        ) : null}
        {showIntroductionPrompt ? (
          <Typography variant="body2">
            {`You haven't chatted with ${patientName(patient)} before. Say hello!`}
          </Typography>
        ) : null}
      </Box>
    </Fade>
  )
}

export default JoinChatBanner
