import Loader from '~/components/Loader'
import { AppointmentCard } from './AppointmentsTab'
import { AppointmentCancelation, useGetPatientAppointmentCancelationsInfo } from '.'
import { Box, Typography } from '@mui/material'

interface ICancellationTabProps {
  patientId: number
  data: Awaited<ReturnType<typeof useGetPatientAppointmentCancelationsInfo>>['data']
  isLoading: boolean
  selectAppointmentForCancel: (appointment: AppointmentCancelation) => void
}

const CancellationsTab = (props: ICancellationTabProps) => {
  if (props.isLoading || !props.data) return <Loader />

  if (!props.data.cancelationEvents.length) {
    return <Typography variant="h6">No cancellations</Typography>
  }

  return (
    <Box>
      {props.data.cancelationEvents.map(appointment => (
        <AppointmentCard
          key={appointment.id}
          appointment={appointment}
          patientId={props.patientId}
          selectAppointmentForCancel={props.selectAppointmentForCancel}
          isCanceled={true}
        />
      ))}
    </Box>
  )
}

export default CancellationsTab
