import {
  CREATE_TASK,
  UPDATE_TASK,
  UPDATE_MESSAGE_TASK,
  CREATE_TASK_SUCCESS,
  REMOVE_TASKS,
} from '../actions/actionTypes'
import { ITask } from '~/models/Task'

export interface State {
  loading: boolean
  tasks: ITask[]
  tasksByMessageId: { [id: number]: ITask[] }
}

const INITIAL_STATE: State = {
  loading: false,
  tasks: [],
  tasksByMessageId: {},
}

export default (state = INITIAL_STATE, action): State => {
  switch (action.type) {
    case CREATE_TASK: {
      return {
        ...state,
      }
    }

    case CREATE_TASK_SUCCESS: {
      const tasksByMessageId = { ...state.tasksByMessageId }
      tasksByMessageId[action.task.parentId] = [
        ...(tasksByMessageId[action.task.parentId] || []),
        action.task,
      ]

      return {
        ...state,
        tasksByMessageId: {
          ...state.tasksByMessageId,
          ...tasksByMessageId,
        },
      }
    }

    case UPDATE_TASK: {
      const tasks = [...state.tasks]
      const taskId = tasks.findIndex(task => task.id === action.task.id)
      tasks[taskId] = action.task
      return {
        ...state,
        tasks,
      }
    }
    case UPDATE_MESSAGE_TASK: {
      const tasksByMessageId = { ...state.tasksByMessageId }

      const tasksForMessage = [...tasksByMessageId[action.task.parentId]]
      const taskIndex = tasksForMessage.findIndex(task => task.id === action.task.id)
      tasksForMessage[taskIndex] = action.task

      tasksByMessageId[action.task.parentId] = tasksForMessage

      return {
        ...state,
        tasksByMessageId: {
          ...state.tasksByMessageId,
          ...tasksByMessageId,
        },
      }
    }
    case REMOVE_TASKS: {
      const taskIds = action.tasks.map(({ id }) => id)
      const tasks = [...state.tasks].filter(({ id }) => !taskIds.includes(id))
      return {
        ...state,
        tasks,
      }
    }
    default: {
      return state
    }
  }
}
