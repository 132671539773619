import { apiClient } from '~/api/rest'
import { useQuery } from '~/components/Providers/ApiProvider'

export interface ChurnedReason {
  value: number
  label: string
  uniqueKey: string
}

/**
 * getChurnedReasons
 * @returns available churned reason options
 */
const getChurnedReasons = async () => {
  const data = await apiClient.rest.get<ChurnedReason[]>('/onboarding/churned-discharged-reasons')
  return data
}

const listChurnedReasonsKey = 'listChurnedReasons'
export const useGetChurnedReasons = () => {
  return useQuery(
    [listChurnedReasonsKey],
    () => getChurnedReasons(),
    {
      staleTime: Infinity,
    },
    {
      error: 'Failed to fetch churned reasons',
    }
  )
}
