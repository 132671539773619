import { FC, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button, Box, Typography, IconButton } from '@mui/material'
import { FFModal } from '~/components/FFModal/FFModal'
import { Shift } from './utils'
import ShiftEditor from './ShiftEditor'

interface Props {
  shifts: Shift[] | null
  dayOfWeek: number
  fullDayName: string
  loading: boolean
  handleCancel: () => void
  handleSubmit: (shiftsData: Shift[], deletedShiftsData: Shift[]) => void
}

const EditShiftTimingsModal: FC<Props> = (props: Props) => {
  const [tempId, setTempId] = useState<number>(-1)
  const [shiftsData, setShiftsData] = useState<Shift[]>([])
  const [deletedShiftsData, setDeletedShiftsData] = useState<Shift[]>([])
  const [error, setError] = useState<boolean[]>([])
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const [displayError, setDisplayError] = useState<boolean>(false)

  useEffect(() => {
    if (props.loading || !props.shifts) return
    setShiftsData(props.shifts)
    setError(props.shifts.map(_ => false))
  }, [props.loading, props.shifts])

  const handleUpdateShifts = (editedShift: Shift) => {
    const prevShift = shiftsData.find(shift => shift.id === editedShift.id)
    if (prevShift) {
      const prevShiftIndex = shiftsData.indexOf(prevShift)
      setShiftsData([
        ...shiftsData.slice(0, prevShiftIndex),
        { ...editedShift, isEdited: true },
        ...shiftsData.slice(prevShiftIndex + 1),
      ])
      setIsEdited(true)
    }
  }

  const handleDeleteShifts = (deletedShift: Shift) => {
    setShiftsData(shiftsData.filter(shift => shift.id !== deletedShift.id))
    if (deletedShift.id > 0) {
      setDeletedShiftsData([...deletedShiftsData, deletedShift])
      setIsEdited(true)
    }
    setError(prevError => prevError.slice(0, prevError.length - 2))
  }

  const handleAddShifts = () => {
    const shift: Shift = {
      id: tempId,
      dayOfWeek: props.dayOfWeek,
      startTime: '',
      stopTime: '',
      effectivePeriod: { lower: '', upper: null, bounds: '[)' },
      isEdited: true,
    }
    setShiftsData([...shiftsData, shift])
    setDisplayError(false)
    setIsEdited(true)
    setTempId(prevTempId => prevTempId - 1)
    setError(prevError => {
      prevError.push(false)
      return prevError
    })
  }

  const handleUpdate = () => {
    if (error.reduce((val, acc) => acc || val, false)) setDisplayError(true)
    else {
      props.handleSubmit(shiftsData, deletedShiftsData)
      setDeletedShiftsData([])
      setDisplayError(false)
      setIsEdited(false)
    }
  }

  const handleCancel = () => {
    setDisplayError(false)
    setIsEdited(false)
    props.handleCancel()
  }

  if (props.loading || !props.shifts) return null

  return (
    <FFModal
      open={true}
      style={{ width: '125%', left: '-10%' }}
      header={
        <Box p={1}>
          <Typography variant="h6" align="left">
            Edit Shifts
          </Typography>
        </Box>
      }
      footer={
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button color="secondary" disabled={props.loading} onClick={handleCancel}>
            Cancel
          </Button>
          <Box ml={1}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={props.loading || !isEdited}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Box>
        </Box>
      }
    >
      <Box m={2}>
        <Typography
          align="left"
          sx={{ fontSize: '1.6rem', fontWeight: 500 }}
        >{`${props.fullDayName} Visit Shifts`}</Typography>
        {shiftsData.map((shift, i) => (
          <ShiftEditor
            key={i}
            shift={shift}
            displayError={displayError}
            onError={error =>
              setError(prevError => {
                prevError[i] = error
                return prevError
              })
            }
            onChange={handleUpdateShifts}
            onDelete={handleDeleteShifts}
          />
        ))}
        <IconButton
          color="primary"
          onClick={handleAddShifts}
          sx={theme => ({
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary[700],
            },
            borderRadius: 'unset',
            marginTop: 3,
          })}
        >
          <AddIcon color="inherit" sx={{ fontSize: '1.8rem', fontWeight: 500 }} />
          <Typography ml={1} sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
            Add a shift
          </Typography>
        </IconButton>
      </Box>
    </FFModal>
  )
}

export default EditShiftTimingsModal
