import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { useDispatch, useSelector, useStore } from 'react-redux'
import axios from 'axios'
import { Button } from '@material-ui/core'

import { queueNotification } from '~/redux/actions/notifications'
import { SERVICE_VERSION } from './config'
import { logger } from './logger'

// Check for updates every 15 minutes
const UPDATE_CHECK_INTERVAL = 1000 * 60 * 15

export const CheckForUpdates: FC = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const userId = useSelector(state => state.me.id)

  // Leverage update check to surface notification with reload button
  // so stale tabs have a refresh button to click
  const dispatch = useDispatch()
  useEffect(() => {
    if (updateAvailable) {
      dispatch(
        queueNotification({
          persist: true,
          variant: 'info',
          message: 'An update is available',
          action: (
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>
              Refresh
            </Button>
          ),
        })
      )
    }
  }, [updateAvailable])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (await checkForUpdate()) {
        setUpdateAvailable(true)
        clearInterval(interval)
      }
    }, UPDATE_CHECK_INTERVAL)

    return function cleanup() {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  // If an update is required, load the next view using a full page refresh
  // FIXME: Once react-router ships useNavigation, use that instead of useLocation
  // The difference would be doing the full reload before the navigation starts vs after.
  const location = useLocation()
  const store = useStore()

  // Only force a refresh if navigating to a different view
  // This is to prevent reloads when a clinician is doing
  // something like changing a worklist or task filter
  const currentRoute = getRootRoute(location.pathname)
  useEffect(() => {
    try {
      const state = store.getState()

      // Prevent a reload when a clinician is in an active video visit
      const activeAppointment = state.appointments.video.appointmentId !== null
      // Prevent a reload when a clinician is accessing the education chassis
      const educationModalOpen = state.education.showModal

      if (updateAvailable && !activeAppointment && !educationModalOpen) {
        logger.debug('[Session] Forced reload for User', userId, 'from CheckForUpdates')
        window.location.reload()
      }
    } catch (e) {
      // Bail out in case of any errors
      if (e instanceof Error) {
        console.error(e)
      }
    }
    // We're purposefully ignoring multiple dependencies so that this ONLY runs when the route changes
    // If we had eslint rules for hooks it would complain about this. If you find yourself enabling
    // those rules, please add an exclusion here. This is by design.
  }, [currentRoute])

  return null
}

// Fetch fresh copy of index.html and check its git hash against the current one
const checkForUpdate = async () => {
  // Add timestamp to prevent any response cache
  const { data } = await axios.get(`/?timestamp=${new Date().getTime()}`).catch()

  const html: string = data
  const newServiceVersion = html.match(/data-service-version\s*=\s*['"](\w*)['"]/)?.[1]

  return newServiceVersion && newServiceVersion !== SERVICE_VERSION
}

// Gets the top level route, i.e. '/patients/1754/careplans' => 'patients'
const getRootRoute = (route: string) => route.split('/').filter(Boolean)[0]
