import ResourceAPI from './base'
import { ProviderSearchResults, SearchAvailability } from '../../interfaces/referral'

/** @deprecated Use `@fireflyhealth/api` instead. */
export class ReferralResource extends ResourceAPI {
  searchRibbonProviders(
    person: string,
    address: string | null,
    specialtyGroup: number | null,
    distance: number,
    page: number,
    pageSize: number,
    npis: string[] | null,
    providerName: string | null,
    gender: string | null,
    cptCode: number | null,
    steerage: number | null,
    searchAvailabilityId: number | null
  ) {
    const payload: any = {
      person: person,
      address: address,
      specialtyGroup: specialtyGroup,
      distance: distance,
      page: page,
      page_size: pageSize,
      npis: npis,
      gender: gender,
      cptCode: cptCode,
      steerage: steerage,
      searchAvailabilityId: searchAvailabilityId,
    }
    if (providerName != null) {
      payload.name = providerName
    }
    return this.rest.post<ProviderSearchResults>(`/referral/search/ribbon/providers/`, payload)
  }

  searchRibbonFacilities(
    person: string,
    address: string | null,
    locationType: number | null,
    distance: number,
    page: number,
    pageSize: number,
    facilityName: string | null,
    steerage: number | null,
    searchAvailabilityId: number | null
  ) {
    const payload: any = {
      person: person,
      address: address,
      locationType: locationType,
      distance: distance,
      page: page,
      page_size: pageSize,
      steerage: steerage,
      searchAvailabilityId: searchAvailabilityId,
    }
    if (facilityName != null) {
      payload.facilityName = facilityName
    }
    return this.rest.post<ProviderSearchResults>(`/referral/search/ribbon/facilities/`, payload)
  }

  createSearchAvailability(
    personId: string,
    isProviderSearch?: boolean | null,
    isFacilitySearch?: boolean | null,
    zipCode?: string | null
  ) {
    const payload: any = {}
    if (isProviderSearch != null) {
      payload.isProviderSearch = isProviderSearch
    }
    if (isFacilitySearch != null) {
      payload.isFacilitySearch = isFacilitySearch
    }
    if (zipCode != null) {
      payload.zipCode = zipCode
    }
    return this.rest.post<SearchAvailability>(
      `/referral/search/person/${personId}/availability/`,
      payload
    )
  }
}
