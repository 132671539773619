import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Paper } from '@mui/material'
import Draggable, { DraggableBounds } from 'react-draggable'
import { debounce } from 'lodash'

import { AppointmentEntry } from './AppointmentEntry'

interface VideoKeys {
  session: string
  accessToken: string
  apiKey: string
  modal: boolean
  appointmentId: number | null
}

const MULTIPLIER = 0.8
const getHeight = () => Math.round(window.innerHeight * MULTIPLIER)
const getWidth = () => Math.round(getHeight() * MULTIPLIER)
const getBounds = (modalHeight: number, modalWidth: number): DraggableBounds => ({
  left: -((window.innerWidth - modalWidth) / 2),
  top: -((window.innerHeight - modalHeight) / 2),
  right: (window.innerWidth - modalWidth) / 2,
  bottom: (window.innerHeight - modalHeight) / 2,
})

export const DRAGGABLE_HANDLE_ID = 'VIDEO_VISIT_MODAL'

export const AppointmentModal: FC = () => {
  const video = useSelector<any, VideoKeys>(state => state.appointments.video)
  const [height, setHeight] = useState<number>(getHeight())
  const [width, setWidth] = useState<number>(getWidth())
  const [bounds, setBounds] = useState<DraggableBounds>(getBounds(height, width))

  const handleResize = debounce(
    () => {
      setHeight(getHeight())
      setWidth(getWidth())
    },
    250,
    { trailing: true }
  )
  // Change modal bounds when window size changes
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    if (video.appointmentId) setBounds(getBounds(height, width))
  }, [height, width, video.appointmentId])

  return video.appointmentId ? (
    <Modal disableAutoFocus disableEnforceFocus hideBackdrop open sx={{ pointerEvents: 'none' }}>
      <div>
        <Draggable
          bounds={bounds}
          handle={`#${DRAGGABLE_HANDLE_ID}`}
          positionOffset={{
            x: '-50%',
            y: '-50%',
          }}
        >
          <Paper
            elevation={10}
            sx={{
              height,
              width,
              pointerEvents: 'all',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              overflow: 'hidden',
            }}
          >
            <AppointmentEntry modal />
          </Paper>
        </Draggable>
      </div>
    </Modal>
  ) : null
}

export default AppointmentModal
