import React, { useMemo } from 'react'
import { Tooltip, Box } from '@mui/material'
import { red, green, yellow } from '@mui/material/colors'
import { SignalCellularAlt, Person, MicOff } from '@mui/icons-material'

import { SessionVideoTileAvatar } from './SessionVideoTileAvatar'

interface SessionVideoTileProps {
  quality: number | undefined
  muted: boolean | undefined
}

/**
 * Surfaces the quality of the connection to the user
 */
export const SessionVideoTile: React.FC<SessionVideoTileProps> = props => {
  const showColor = typeof props.quality !== 'undefined'
  const marker = useMemo(() => {
    if (!showColor) return
    if (props.quality! < 2) return { text: 'Poor connection', color: red[500] }
    if (props.quality! < 3) return { text: 'Ok connection', color: yellow[500] }
    return { text: 'Good connection', color: green[500] }
  }, [props.quality, showColor])
  return (
    <Box
      sx={theme => ({
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[500],
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <Tooltip title={marker?.text!} placement="top">
        <Box
          sx={{
            position: 'absolute',
            bottom: '0.5em',
            right: '0.5em',
            zIndex: 100,
            display: 'flex',
          }}
        >
          {props.muted ? (
            <SessionVideoTileAvatar>
              <MicOff fontSize="small" />
            </SessionVideoTileAvatar>
          ) : null}
          {showColor ? (
            <SessionVideoTileAvatar>
              <SignalCellularAlt fontSize="small" sx={{ color: marker?.color }} />
            </SessionVideoTileAvatar>
          ) : null}
        </Box>
      </Tooltip>
      <Person color="inherit" />
    </Box>
  )
}
