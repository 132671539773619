import { START_SYNC, STOP_SYNC } from '../actions/actionTypes'

export interface SyncState {
  synchronizing: boolean
}

const INITIAL_STATE: SyncState = {
  synchronizing: true,
}

const sync = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_SYNC: {
      return {
        ...state,
        synchronizing: true,
      }
    }
    case STOP_SYNC: {
      return {
        ...state,
        synchronizing: false,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default sync
