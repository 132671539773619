import { useQuery } from '~/components/Providers/ApiProvider'
import { apiClient } from '~/api/rest'
import { useSelector } from 'react-redux'
import { useMutation } from './useMutation'

const BASE_URI = '/phi_access_control'

interface AccessResult {
  access: boolean
  expiresAt: string | null
}

export const getPHIAccess = (patientId, myId) => {
  patientId = parseInt(patientId)
  return apiClient.rest.get<AccessResult>(
    BASE_URI + `/check_access/?user_id=${patientId}&requesting_user_id=${myId}`
  )
}

const usePHIAccessCheck = (patientId: number | null) => {
  const me = useSelector(state => state.me)

  const pHIAccessQuery = useQuery(
    ['pHIAccessQuery', me.id, patientId!],
    () => getPHIAccess(patientId, me.id),
    {
      enabled: !!patientId && !!me.id,
      staleTime: 12 * 60 * 60 * 1000, // 12 hours,
      refetchOnMount: 'always', // because access can expire on the back-end, always re-check
    },
    {
      error: `Unable to check access for patient ${patientId}`,
    }
  )

  const grantPHIAccessMutation = useMutation(accessReason =>
    apiClient.rest.post<AccessResult>(BASE_URI + '/', {
      requesting_user_id: me.id,
      user_id: patientId,
      accessReason,
    })
  )

  return { pHIAccessQuery, grantPHIAccessMutation }
}

export default usePHIAccessCheck
