import { Button, Paper } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import Moment from 'moment'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as ChatService from '~/api/ChatService'
import Loader from '~/components/Loader'
import { ITask } from '~/models/Task'
import { createTask, updateMessageTask, updateTask } from '~/redux/actions/tasks'
import chatSlice from '~/redux/slices/chat'
import { LimitedPatient } from '~/types'

import TaskEditor from './TaskEditor'

interface Props {
  createTask: Function
  classes: { [key: string]: string }
  handleClose: Function
  me: {
    [key: string]: any
  }
  type?: 'message' | 'careplan'
  relation?: number
  relationUid?: string
  patient: LimitedPatient
  canAssignToPatient?: boolean
  task?: ITask | null
  updateTask: (id: number, payload: any) => void
  updateMessageTask: (id: number, payload: any) => void
  // TEMP to allow creating message tasks for new messages
  setMessageId: (patientId: number, uid: string, id: number) => void
}

interface State {
  loading: boolean
  task: {
    ownerGroup?: number
    dueDate: string | Date
    title: string
    priority: number
    isComplete: boolean
  }
  [key: string]: any
}

const GET_INITIAL_STATE = (props: Props): State => ({
  loading: false,
  task: props.task
    ? {
        title: props.task.title,
        dueDate: props.task.dueDate || '',
        ownerGroup: props.task.ownerGroup || undefined,
        priority: props.task.priority,
        isComplete: false,
      }
    : {
        title: '',
        dueDate: Moment().format('YYYY-MM-DD'),
        ownerGroup: props.me.assigneeGroup.id || undefined,
        priority: 0,
        isComplete: false,
      },
})

export class SingleTaskEditor extends Component<Props, State> {
  state: State = GET_INITIAL_STATE(this.props)

  static defaultProps = {
    classes: {} as { [key: string]: string },
  }

  componentDidMount() {
    this.init()
  }

  // Enforce related prop requirements
  init = () => {
    const { handleClose } = this.props
    try {
      if (!this.props.task && !this.props.type)
        throw new Error('New tasks must be assigned either a message or careplan type')
      if (!this.props.task && !this.props.relation && !this.props.relationUid)
        throw new Error('Must pass relation object id to task dialog')
    } catch (e) {
      console.warn(e)
      handleClose()
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value })
  }

  handleUpdate = () => {
    const { handleClose, task, type, updateMessageTask, updateTask } = this.props
    const { task: editedTask } = this.state
    const updateAction = type === 'message' ? updateMessageTask : updateTask
    this.setState({ loading: true }, async () => {
      try {
        if (task && task.id) {
          await updateAction(task.id, {
            ...editedTask,
            dueDate: Moment(editedTask.dueDate).format(),
          })
          handleClose()
        } else {
          throw new Error('Cannot update null task')
        }
      } catch (e) {
        console.warn('Update task failed', e)
        this.setState({ loading: false })
      }
    })
  }

  handleAdd = () => {
    const { handleClose, patient, relationUid, type, createTask, setMessageId } = this.props
    let { relation } = this.props

    this.setState({ loading: true }, async () => {
      try {
        const { task: editedTask } = this.state
        if (type === 'message' && relationUid) {
          // HACK: Temporary fix to allow message tasks on newly loaded messages.
          const message = await ChatService.getMessage(relationUid)
          relation = message.id
          setMessageId(patient.id, message.uid, message.id)
        }

        const payload = {
          title: editedTask.title,
          ...{ owner_group: editedTask.ownerGroup ? editedTask.ownerGroup : undefined },

          due_date: Moment(editedTask.dueDate).format(),
          priority: editedTask.priority,
          patient: patient.id,
          relations: [
            {
              content_type: type === 'message' ? 'chatmessagev2' : 'careplan',
              object_id: relation,
            },
          ],
        }
        await createTask(payload)
        handleClose()
      } catch (e) {
        console.warn('Adding new task failed', e)
        this.setState({ loading: false })
      }
    })
  }

  render() {
    const { loading } = this.state
    const { classes, handleClose, patient, canAssignToPatient } = this.props
    // If a task was loaded, update the task. Otherwise, add a new one
    const isNew = this.props.task === undefined
    const taskAction = isNew ? this.handleAdd : this.handleUpdate
    const taskCta = isNew ? 'Add Task' : 'Update Task'

    return (
      <Paper>
        <div className={classes.container}>
          <TaskEditor
            task={this.state.task}
            onChange={newTask => this.setState({ task: newTask })}
            patient={patient}
            canAssignToPatient={canAssignToPatient}
          />
          <div className={classes.actions}>
            <Button disabled={loading} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              className={classes.save}
              disabled={loading || !this.state.task.title}
              variant="contained"
              color="primary"
              onClick={taskAction}
            >
              {loading && <Loader inline />}
              <span className={loading ? classes.saveDisabled : ''}>{taskCta}</span>
            </Button>
          </div>
        </div>
      </Paper>
    )
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  save: {
    marginLeft: theme.spacing(),
  },
  saveDisabled: {
    color: 'transparent',
  },
})

const mapStateToProps = state => ({
  me: state.me,
})

const mapDispatchToProps = dispatch => ({
  createTask: payload => dispatch(createTask(payload)),
  updateMessageTask: (id, payload) => dispatch(updateMessageTask(id, payload)),
  updateTask: (id, payload) => dispatch(updateTask(id, payload)),
  setMessageId: (patientId, uid, id) =>
    dispatch(chatSlice.actions.setMessageId({ patientId, uid, id })),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(SingleTaskEditor, styles))
