import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

type SelectPayload =
  | { type: 'add' }
  | { type: 'createForwardTask'; message: any; ownerGroup: any; timestamp: number }

interface ContextProps {
  setSelectedCaseId: (caseId: number | null, selectPayload?: SelectPayload) => void
  setSelectedCarePlanId: (carePlanId: number | null) => void
  selectedCaseId: number | null
  selectedCarePlanId: number | null
  // Context about why the ToDo is being selected
  // and any additional context related to the action which triggered the selection
  selectPayload: SelectPayload | null
}

/**
 * For use on Patient/Person charts, manage the currently-selected Case
 * and data associated with the selection
 *
 * Also persist information in query params to enable deep-linking
 */
export const SelectedTodoContext = React.createContext<ContextProps>({
  selectedCaseId: null,
  selectedCarePlanId: null,
  setSelectedCaseId: () => null,
  setSelectedCarePlanId: () => null,
  selectPayload: null,
})

export const useManageSelectedTodo = patientId => {
  const [selectedCaseId, _setSelectedCaseId] = useState<ContextProps['selectedCaseId']>(null)
  const [selectedCarePlanId, setSelectedCarePlanId] =
    useState<ContextProps['selectedCarePlanId']>(null)

  const [selectPayload, setSelectPayload] = useState<ContextProps['selectPayload']>(null)
  const history = useHistory()
  const location = useLocation()

  // Setting state from query params allows us to create shareable deep-links
  // and also deep-link from pages outside the context of the patient chart, like the Inbox
  const queryParamCareplanId = qs.parse(location.search).careplanId
  const queryParamCaseId = qs.parse(location.search).caseId

  useEffect(() => {
    if (!queryParamCareplanId) return
    setSelectedCarePlanId(parseInt(queryParamCareplanId as string))
  }, [queryParamCareplanId])

  useEffect(() => {
    if (!queryParamCaseId) return
    _setSelectedCaseId(parseInt(queryParamCaseId as string))
  }, [queryParamCaseId])

  const setSelectedCaseId = (caseId: typeof selectedCaseId, selectPayload?: SelectPayload) => {
    setSelectPayload(selectPayload ?? null)
    _setSelectedCaseId(caseId)
    let url = `/patients/${patientId}/todos?caseId=${caseId}`
    history.replace(url)
  }

  return {
    selectedCaseId,
    selectedCarePlanId,
    setSelectedCaseId,
    setSelectedCarePlanId,
    selectPayload,
  }
}
