import { Box } from '@mui/material'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import MakeshiftDrawer from '~/components/Generic/MakeshiftDrawer'

interface Props {
  sidebar?: JSX.Element | null
  toolbar?: JSX.Element | null
  collapseDisabled?: boolean
}

const InterfaceContent: React.FC<Props> = props => {
  const [collapseSidebar, setCollapseSidebar] = useState(false)
  const { classes } = useStyles()
  const { sidebar, toolbar } = props

  const handleSidebarCollapse = () => {
    setCollapseSidebar(current => !current)
  }

  return (
    <Box display="flex" sx={{ height: '100%' }}>
      {sidebar && (
        <Box
          className={`${
            collapseSidebar ? classes.containerMenuCollapsed : classes.containerMenuExpanded
          }`}
        >
          <MakeshiftDrawer
            collapseDisabled={props.collapseDisabled}
            open={!collapseSidebar}
            handleSidebarCollapse={handleSidebarCollapse}
            sidebar={sidebar}
          />
        </Box>
      )}
      <div className={classes.containerContent}>
        {toolbar && <div className={classes.containerContentToolbar}>{toolbar}</div>}
        <div className={classes.containerContentScroll}>{props.children}</div>
      </div>
    </Box>
  )
}

const useStyles = makeStyles()(theme => ({
  containerMenuExpanded: {
    height: '100%',
    width: '20%',
    borderRight: `0.1rem solid ${theme.palette.divider}`,
    overflow: 'auto',
  },
  containerMenuCollapsed: {
    height: '100%',
    width: '5rem',
    borderRight: `0.1rem solid ${theme.palette.divider}`,
  },
  containerContent: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    width: '80%',
    flexDirection: 'column',
  },
  containerContentToolbar: {
    padding: theme.spacing(),
    paddingRight: theme.spacing(3),
    borderBottom: `0.1rem solid ${theme.palette.divider}`,
  },
  containerContentScroll: {
    flex: 1,
    overflow: 'auto',
  },
}))

export default InterfaceContent
