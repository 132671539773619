import { useEffect, useRef } from 'react'

// Quick helper util to access previous state using hooks
export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
