import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getPreferences, SESSION_WORKLIST_PREFERENCES } from '~/utils/preference'

export interface WorklistVisibilityPreference {
  isVisible: boolean
}

export interface WorklistWidthPreference {
  width: number
}

export interface FilterItem {
  field: string
  operator: string
  value: string
}

export interface WorklistFilterPreference {
  linkOperator: string
  items: FilterItem[]
}

export interface WorklistPreferences {
  visibility?: { [field: string]: WorklistVisibilityPreference }
  filter?: WorklistFilterPreference
  width?: { [field: string]: WorklistWidthPreference }
}

export interface WorklistPreferencesState {
  byId: { [key: number]: WorklistPreferences }
  lastViewedWorklistId?: number
}
const INITIAL_STATE: WorklistPreferencesState = {
  byId: {},
}
const getInitialState = (): WorklistPreferencesState => {
  const preferences = getPreferences(SESSION_WORKLIST_PREFERENCES)
  if (preferences) {
    return JSON.parse(preferences)
  } else {
    return INITIAL_STATE
  }
}

export const worklistPreferencesSlice = createSlice({
  name: 'worklistPreference',
  initialState: getInitialState(),
  reducers: {
    setVisibility: (
      state,
      {
        payload: preference,
      }: PayloadAction<{ worklistId: number; field: string; isVisible: boolean }>
    ) => {
      if (!state.byId[preference.worklistId]) {
        state.byId[preference.worklistId] = {}
      }
      state.byId[preference.worklistId].visibility = {
        ...state.byId[preference.worklistId].visibility,
        [preference.field]: {
          isVisible: preference.isVisible,
        },
      }
    },
    setFilter: (
      state,
      {
        payload: worklistFilter,
      }: PayloadAction<{ worklistId: number; filter: WorklistFilterPreference }>
    ) => {
      if (!state.byId[worklistFilter.worklistId]) {
        state.byId[worklistFilter.worklistId] = {}
      }
      state.byId[worklistFilter.worklistId].filter = worklistFilter.filter

      if (worklistFilter.filter.items.length === 0) {
        delete state.byId[worklistFilter.worklistId].filter
      }
    },
    setWidth: (
      state,
      { payload: preference }: PayloadAction<{ worklistId: number; field: string; width: number }>
    ) => {
      if (!state.byId[preference.worklistId]) {
        state.byId[preference.worklistId] = {}
      }
      state.byId[preference.worklistId].width = {
        ...state.byId[preference.worklistId].width,
        [preference.field]: {
          width: preference.width,
        },
      }
    },
    setLastViewedWorklistId: (state, { payload: worklistId }: PayloadAction<number>) => {
      state.lastViewedWorklistId = worklistId
    },
    getPreferencesSuccess: (
      state,
      { payload: preference }: PayloadAction<WorklistPreferencesState>
    ) => {
      state = preference
    },
  },
})

export default worklistPreferencesSlice
