import { useState } from 'react'
import Moment from 'moment'
import { Box, Typography } from '@mui/material'
import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material'
import { getDueDateLabel } from '~/components/Tasks/utils'
import FFInput from '~/components/Generic/FFInput'

const NextActionDate = ({
  date,
  editable = false,
  onChange,
  newTodos = false,
}: {
  date?: string | Date | null
  editable?: boolean
  onChange?: (value: string) => void
  newTodos?: boolean
}) => {
  const readableDate = date ? getDueDateLabel(Moment(date).toDate(), newTodos) : ''
  const [isEditable, setIsEditable] = useState(false)

  return (
    <Box
      style={{ marginLeft: '2.2rem', minWidth: '109px', cursor: editable ? 'pointer' : 'default' }}
      onClick={e => {
        e.stopPropagation()
        if (!editable) {
          return
        }

        setIsEditable(true)
      }}
    >
      <Typography style={{ fontSize: '1.4rem' }}>
        {isEditable ? (
          <FFInput
            size="small"
            onBlur={event => {
              onChange?.(event.target.value)
              setIsEditable(false)
            }}
            defaultValue={Moment(date).format('YYYY-MM-DD')}
            type="date"
            placeholder="Next action date"
            InputProps={{
              style: {
                fontSize: 'inherit',
              },
              autoFocus: true,
            }}
          />
        ) : (
          <Box display="flex" alignItems="baseline">
            {readableDate}
            {editable && !newTodos ? (
              <CalendarTodayIcon fontSize="inherit" sx={{ marginLeft: 1 }} />
            ) : null}
          </Box>
        )}
      </Typography>
    </Box>
  )
}

export default NextActionDate
