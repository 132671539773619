import moment from 'moment'

export const getDueDateLabel = (dueDate: Date, newTodos?: boolean) => {
  const m = moment(dueDate)
  return m.calendar(undefined, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'MMM D, YYYY',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM D, YYYY',
    sameElse: (_, now) => {
      if (!m.isSame(now, 'year')) {
        if (!newTodos) return 'ddd, MMM D YYYY'
        else return 'MMM D, YYYY'
      }
      if (!newTodos) return 'ddd, MMM D'
      else return 'MMM D, YYYY'
    },
  })
}

export const getUpdatedAtLabel = (dueDate: Date) => {
  const m = moment(dueDate)
  return m.calendar(undefined, {
    sameDay: '[Today], h:mm A',
    nextDay: '[Tomorrow], h:mm A',
    nextWeek: 'MMM D, YYYY h:mm A',
    lastDay: '[Yesterday], h:mm A',
    lastWeek: 'MMM D, YYYY h:mm A',
    sameElse: 'MMM D, YYYY h:mm A',
  })
}
