import { useEffect, useState } from 'react'

import { usePromise, PromiseStateConfig } from '~/legacy/core'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'

export interface ResolveNotification {
  success?: string
  error?: string
}
export type UseMutationConfig = PromiseStateConfig & ResolveNotification

type PartialPromiseAny = Record<keyof Partial<ReturnType<typeof usePromise>>, any>
/**
 * This is an abstraction to handle both surfacing async operation feedback
 * to a user, and for reporting in the case of a failed call.
 * @param mutation Partial response from ~/legacy/core's useMutation including error, result, or both
 * @param notification Partial messaging information, making feedback optional for success and failure states
 */
export const useResolvedPromise = (
  mutation: PartialPromiseAny,
  notification?: ResolveNotification
) => {
  const dispatch = useDispatch()

  // This is a workaround to show feedback for handlers that are called multiple times
  // i.e. if an async operation completes, its result is stored, so the below useEffects
  // wouldn't be triggered again.
  const [resolved, setResolved] = useState(false)
  useEffect(() => {
    setResolved(!mutation.loading)
  }, [mutation.loading])

  // Report errors via apm and show notification if applicable
  useEffect(() => {
    if (resolved && mutation.error) {
      if (notification?.error) {
        dispatch(
          queueNotification({
            variant: 'error',
            message: notification.error,
          })
        )
      }
      console.error(mutation.error)
    }
  }, [resolved, mutation.error, notification?.error])

  // Show success notification if applicable
  useEffect(() => {
    // Checking typeof here in case of falsy return values
    if (resolved && typeof mutation.result !== 'undefined' && notification?.success) {
      dispatch(
        queueNotification({
          variant: 'success',
          message: notification.success,
        })
      )
    }
  }, [resolved, mutation.result, notification?.success])
}
