import { getAssigneeGroupsRequest } from '../../requests/assigneeGroups'
import { GET_ASSIGNEE_GROUPS, GET_ASSIGNEE_GROUPS_ERROR } from './actionTypes'
import { queueNotification } from './notifications'

export const getAssigneeGroupsRequestSuccess = response => ({
  type: GET_ASSIGNEE_GROUPS,
  assigneeGroups: response.data,
})

export const getAssigneeGroupsRequestError = error => ({
  type: GET_ASSIGNEE_GROUPS_ERROR,
  error,
})

export function getAssigneeGroups() {
  return async dispatch => {
    try {
      const response = await getAssigneeGroupsRequest()
      return dispatch(getAssigneeGroupsRequestSuccess(response))
    } catch (e) {
      console.log(e)
      dispatch(queueNotification({ variant: 'error', message: 'Error fetching "assignee groups"' }))
      dispatch(getAssigneeGroupsRequestError('Problem fetching assignee groups'))
      throw new Error(e)
    }
  }
}
