import { ICase, ICaseCategory } from '~/models/Case'

export type Grouped<T> = T & { group: string }

export type CaseWithTitle = ICase & { title: string }
export type CaseOrCategory = CaseWithTitle | ICaseCategory

export type GroupedCaseOrCategory = Grouped<CaseOrCategory>

export function isCase(caseOrCategory: CaseOrCategory): caseOrCategory is CaseWithTitle {
  return (caseOrCategory as CaseWithTitle).category !== undefined
}
