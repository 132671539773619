import { useEffect, useRef } from 'react'
import type { FC } from 'react'
import { MuiThemeProvider, Box, Grid, Paper, Typography, Button } from '@material-ui/core'

import { copyToClipboard } from '~/utils/clipboard'
import theme from '~/utils/theme'

interface LucianErrorScreenProps {
  error: any
}

const LucianErrorScreen: FC<LucianErrorScreenProps> = props => {
  const errorRef = useRef<HTMLTextAreaElement>(null)

  // Report error to APM
  useEffect(() => {
    console.error(props.error)
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        bgcolor="#071F3E"
        position="absolute"
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={11}
          sm={7}
          md={6}
          lg={5}
          xl={4}
          style={{ maxHeight: '90vh', overflow: 'auto', maxWidth: '90vh', position: 'absolute' }}
        >
          <Paper elevation={20}>
            <Box p={3}>
              <Typography variant="h4">Oh no! An error occurred.</Typography>
              <Typography variant="h6">
                Sorry about that. Please type a description of what happened and copy/paste the
                below into the #firefly-support Slack channel and we will fix ASAP.
              </Typography>
              <Box my={3} p={1} bgcolor="#eeeeee">
                <pre>
                  {props.error.message}
                  <br />
                  {props.error.stack}
                </pre>
                <Typography variant="srOnly">
                  <textarea
                    ref={errorRef}
                    value={`${props.error.message}\n${props.error.stack ?? ''}`.trim()}
                    readOnly
                  />
                </Typography>
              </Box>
              <Grid container spacing={1}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={() => location.reload()}>
                    Reload
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      localStorage.clear()
                      location.reload()
                    }}
                  >
                    Clear Cache &amp; Reload
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => copyToClipboard(errorRef)}
                  >
                    Copy to Clipboard
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Box>
    </MuiThemeProvider>
  )
}

export default LucianErrorScreen
