import React from 'react'
import { SnackbarProvider as NotistackProvider, useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

const SnackbarCloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <Close htmlColor="#fff" />
    </IconButton>
  )
}

const SnackBarProvider: React.FC = props => {
  return (
    <NotistackProvider
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={5000}
      action={key => <SnackbarCloseButton id={key} />}
    >
      {props.children}
    </NotistackProvider>
  )
}

export default SnackBarProvider
