import { useRef } from 'react'
import type { FC } from 'react'
import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { FileCopyOutlined } from '@material-ui/icons'

import { copyToClipboard } from '~/utils/clipboard'

interface PatientDetailPopoverLineProps {
  value: string
  icon: any
}

export const PatientDetailPopoverLine: FC<PatientDetailPopoverLineProps> = props => {
  const classes = useStyles()
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  return (
    <Button fullWidth onClick={() => copyToClipboard(textareaRef)} className={classes.container}>
      <Typography variant="srOnly">
        <textarea ref={textareaRef} value={props.value} />
      </Typography>
      <props.icon fontSize="small" />
      <Box mx={1} flex={1}>
        <Typography align="left" variant="caption" component="pre">
          {props.value}
        </Typography>
      </Box>
      <FileCopyOutlined color="action" fontSize="small" className={classes.inner} />
    </Button>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    textTransform: 'none',
    textAlign: 'left',
    '&:hover *': {
      opacity: 1,
    },
  },
  inner: {
    opacity: 0,
  },
}))

export default PatientDetailPopoverLine
