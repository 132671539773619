import { ExpandMore } from '@mui/icons-material'
import { AccordionSummary, Box, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => {
  return {
    contentTitle: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      display: 'inline-flex',
      cursor: 'pointer',
    },
  }
})

const CategoryHeader: FC<{
  categoryParentTitle: string
  countPatientTodo: number
}> = props => {
  const { categoryParentTitle, countPatientTodo } = props

  const { classes } = useStyles()

  useEffect(() => {}, [])

  return (
    <AccordionSummary
      aria-label="Expand"
      aria-controls={`categorized-todos-${categoryParentTitle}-content`}
      id={`categorized-todos-${categoryParentTitle}-header`}
      style={{ flexGrow: 1 }}
      expandIcon={<ExpandMore />}
      sx={{
        flexDirection: 'row-reverse',
        '.MuiAccordionSummary-expandIconWrapper': {
          transform: 'rotate(-90deg)',
          color: 'grey.400',
        },
        '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(0deg)',
          color: 'grey.400',
        },
      }}
    >
      <Box
        style={{ flexGrow: 1 }}
        onClick={e => {
          const titleClick = (e.target as HTMLInputElement).classList.value
            .split(' ')
            .filter(cls => cls.includes('contentTitle'))
          if (titleClick && titleClick.length == 0) {
            e.stopPropagation()
            e.preventDefault()
          }
        }}
      >
        <Typography className={classes.contentTitle} style={{ marginLeft: 1 }}>
          {categoryParentTitle + ' (' + countPatientTodo + ')'}
        </Typography>
      </Box>
    </AccordionSummary>
  )
}

export default CategoryHeader
