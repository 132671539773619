import { useCallback } from 'react'
import type { FC } from 'react'
import { Person } from '~/legacy/core'
import { Box, Typography } from '@mui/material'

import EditPersonForm from './EditPerson/EditPersonForm'
import { useAddNewMember } from '~/api/PersonService'
import { useHistory } from 'react-router'

export const AddNewMember: FC = () => {
  const patient: Person = {} as Person
  const { isLoading: isUpdatingPerson, mutateAsync: handleAddNewMember } = useAddNewMember()
  const isLoading = isUpdatingPerson
  const history = useHistory()

  const navigateToPersonView = (id: number) => {
    history.push(`/people/${id}`)
  }
  const handlePersonDetailsSubmit = useCallback(
    async (values: Person) => {
      const {
        firstName,
        lastName,
        dob,
        preferredName,
        employer,
        gender,
        sex,
        pronouns,
        requestAuthForPhi,
        phoneNumber,
        email,
      } = values
      try {
        const newMember = await handleAddNewMember({
          firstName,
          lastName,
          dob,
          preferredName,
          employer,
          gender,
          sex,
          pronouns,
          requestAuthForPhi,
          phoneNumber: phoneNumber ? phoneNumber.replace(/\D/g, '') : undefined,
          email,
        })
        navigateToPersonView(newMember.id)
      } catch (e) {
        console.error(e)
      }
    },
    [patient]
  )
  return (
    <Box p={2} border={1} borderLeft={0} borderRight={0} borderTop={0} borderColor="grey.300">
      <Box mb={1}>
        <Typography variant="h6">Add New Member</Typography>
      </Box>
      <EditPersonForm
        patient={patient}
        handleSubmit={handlePersonDetailsSubmit}
        loading={isLoading}
        isNewMember={true}
      />
    </Box>
  )
}

export default AddNewMember
