import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import {
  APP_ENV,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  SERVICE_VERSION,
  DATADOG_ENABLED,
  BASE_URL,
} from '~/utils/config'

/**
 * Initialize Datadog for RUM and logging
 */
export const useInitDatadog = () => {
  useEffect(() => {
    if (DATADOG_ENABLED && DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN) {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'lucian-crm',
        env: APP_ENV === 'dev' ? 'development' : APP_ENV,
        version: SERVICE_VERSION,
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        tracingSampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
        allowedTracingOrigins: [BASE_URL as string],
      })
      datadogRum.startSessionReplayRecording()
      datadogLogs.init({
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        env: APP_ENV === 'dev' ? 'development' : APP_ENV,
        service: 'lucian-crm',
        version: SERVICE_VERSION,
        telemetrySampleRate: 0,
      })
    }
  }, [])
}
