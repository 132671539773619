import type { Asset, ContentTypeLink, Entry, EntryCollection } from 'contentful'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Document } from '@contentful/rich-text-types'
import { EducationContext } from './EducationModalProvider'
import { educationSlice } from '~/redux/slices/education'
import keyBy from 'lodash/fp/keyBy'

export const EDUCATION_DRAGGABLE_HANDLE = 'EDUCATION_DRAGGABLE_HANDLE'

export const useEducationModal = () => useContext(EducationContext)

// To be used in usePatientDetail to keep redux in sync with open patients
export const useFocusPatient = (id: string | null | undefined = null) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) dispatch(educationSlice.actions.focusPatient(Number(id)))
    else dispatch(educationSlice.actions.blurPatient())
    // When leaving patient detail, blur patient
    return () => {
      dispatch(educationSlice.actions.blurPatient())
    }
  }, [id])
}

export const useFocusWorklist = (id: string | null | undefined = null) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) dispatch(educationSlice.actions.focusWorklist(Number(id)))
    else dispatch(educationSlice.actions.blurWorklist())
    // When leaving worklist detail, blur worklist
    return () => {
      dispatch(educationSlice.actions.blurWorklist())
    }
  }, [id])
}

export const useFocusCase = (id: string | null | undefined = null) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) dispatch(educationSlice.actions.focusCase(Number(id)))
    else dispatch(educationSlice.actions.blurCase())
    // When leaving case detail, blur Case
    return () => {
      dispatch(educationSlice.actions.blurCase())
    }
  }, [id])
}

interface EducationContent {
  body: Document
  title: string
  category: ContentTypeLink
}

interface TagTypeLink {
  sys: {
    type: 'Link'
    linkType: 'Tag'
    id: string
  }
}
interface Metadata {
  tags: Array<TagTypeLink>
}

export type ContentEntry = Entry<EducationContent> & { metadata?: Metadata }
export type ContentEntryCollection = EntryCollection<EducationContent>

/**
 * Linked entries are returned in an `includes` object in the contentful response.
 * Normalize them by id so they can be fetched more easily downstream.
 * TODO: Extend/port utilities like this to js core if applicable
 */
interface Includes {
  asset: Asset[]
  entry: Entry<any>[]
}
export interface NormalizedIncludes {
  asset: Record<string, Asset>
  entry: Record<string, Entry<any>>
}
export const mapIncludes = (response: ContentEntryCollection): NormalizedIncludes | null => {
  const includes = response.includes as Includes | undefined
  if (includes) {
    const normalizedIncludes = Object.keys(includes).reduce((prev, next) => {
      prev[next] = keyBy('sys.id', includes[next])
      return prev
    }, {}) as NormalizedIncludes
    return normalizedIncludes
  }
  return null
}
