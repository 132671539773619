import Moment from 'moment'
import { ITask } from '~/models/Task'

export const mapPreviewTasksToTasks = (
  taskPreviews: any[],
  options: { defaultAssigneeGroup: number }
): ITask[] => {
  return taskPreviews.map(taskPreview => {
    const dueDateInLocalTime = Moment(taskPreview.dueDate) || Moment()
    return {
      title: taskPreview.title,
      dueDate: dueDateInLocalTime.isValid() ? dueDateInLocalTime.format('YYYY-MM-DD') : '',
      ...{
        ownerGroup: taskPreview.assigneePk ? taskPreview.assigneePk : options.defaultAssigneeGroup,
      },
      isComplete: false,
      priority: taskPreview.priority || 0,
      taskTemplatePk: taskPreview.taskTemplatePk,
    }
  })
}
