import {
  CREATE_TASK_ERROR,
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  GET_TASKS_SUCCESS,
  UPDATE_TASK,
  UPDATE_MESSAGE_TASK,
  REMOVE_TASKS,
} from './actionTypes'
import * as TaskService from '~/api/TaskService'
import { queueNotification } from './notifications'
import { ITask } from '~/models/Task'

const initCreateTask = () => ({
  type: CREATE_TASK,
})

const createTaskSuccess = (task: ITask) => ({
  type: CREATE_TASK_SUCCESS,
  task,
})
export const getTasksSuccess = (tasks: ITask[]) => ({
  type: GET_TASKS_SUCCESS,
  tasks,
})

const updateTaskSuccess = (task: ITask) => ({
  type: UPDATE_TASK,
  task,
})

const updateMessageTaskSuccess = (task: ITask) => ({
  type: UPDATE_MESSAGE_TASK,
  task,
})

const removeTasksSuccess = tasks => ({
  type: REMOVE_TASKS,
  tasks,
})

export const removeTasks = tasks => async dispatch => {
  try {
    const calls = tasks.map(task => TaskService.deleteTask(task))
    await Promise.all([calls])
    return dispatch(removeTasksSuccess(tasks))
  } catch (e) {
    console.error('Failed to delete task(s)', e)
    return dispatch(
      queueNotification({
        variant: 'error',
        message: 'Failed to delete task',
      })
    )
  }
}

const createTaskError = () => ({ type: CREATE_TASK_ERROR })

export const createTask = (payload: any) => async dispatch => {
  try {
    dispatch(initCreateTask())
    const response = await TaskService.createTask(payload)
    return dispatch(createTaskSuccess(response))
  } catch (e) {
    alert('Failed to create task, full error can be seen in the console.')
    console.error('Failed to create task. Full error: ', e)
    return dispatch(createTaskError())
  }
}

export const updateMessageTask = (id: number, payload: any) => async dispatch => {
  try {
    const task = await TaskService.updateTask(id, payload)
    dispatch(updateMessageTaskSuccess(task))
    return dispatch(updateTaskSuccess(task))
  } catch (e) {
    console.error(`Failed to update task ${id}`, e)
    dispatch(
      queueNotification({
        variant: 'error',
        message: 'Failed to update task.',
      })
    )
    throw new Error()
  }
}

export const updateTask = (id: number, payload: any) => async dispatch => {
  try {
    const task = await TaskService.updateTask(id, payload)
    dispatch(updateTaskSuccess(task))
    return task
  } catch (e) {
    console.error(`Failed to update task ${id}`, e)
    dispatch(
      queueNotification({
        variant: 'error',
        message: 'Failed to update task.',
      })
    )
    throw new Error()
  }
}
