import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as OnCallService from '~/api/OnCallService'
import UserSelect from '../Generic/UserSelect'
import { queueNotification } from '~/redux/actions/notifications'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: '25rem',
    margin: 5,
  },
}))

const OnCallSettings = () => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const [onCall, setOnCall] = useState<number | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const onCallUser = await OnCallService.getOnCall()
      setOnCall(onCallUser.id)
    }

    fetchData()
  }, [])

  const saveOnCall = async () => {
    if (onCall) {
      await OnCallService.setOnCall({ provider: onCall })
      dispatch(
        queueNotification({
          variant: 'success',
          message: 'Updated on-call clinician.',
        })
      )
    }
  }

  return (
    <div className={styles.container}>
      <Typography variant="h6">On-Call Clinician</Typography>
      <div className={styles.selectWrapper}>
        <UserSelect
          value={onCall}
          className={styles.select}
          onChange={setOnCall}
          canAssignToPatient={false}
        />
        <Button color="primary" onClick={saveOnCall}>
          Save
        </Button>
      </div>
      <Typography>New on-call clinicians will be notified after saving.</Typography>
    </div>
  )
}

export default OnCallSettings
