import type { FC } from 'react'
import { PatientUser } from '~/legacy/core'
import ProviderSearch from '~/components/PatientDetail/ProviderSearch/ProviderSearch'
import { useProviderSearch } from './utils'
import Loader from '~/components/Loader'
import { useReferralDetails } from '../SteerageView/SteerageViewV3/utils'

export interface ProviderSearchTabProps {
  patient: PatientUser
}

const ProviderSearchTab: FC<ProviderSearchTabProps> = props => {
  const { isLoading: providerSearchIsLoading } = useProviderSearch()
  const { isLoading: referralDetailsIsLoading } = useReferralDetails()
  const isLoading = providerSearchIsLoading || referralDetailsIsLoading
  if (isLoading) return <Loader />
  return <ProviderSearch person={props?.patient.person} />
}

export default ProviderSearchTab
