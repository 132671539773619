import { IStateMachineFields } from './StateMachine'
import { ITask } from '~/models/Task'
import { IWorkUnitPersonMeta } from './WorkUnit'

export interface ICaseCategory {
  id: number
  title: string
  isSystemOnly?: boolean
  isDeprecated?: boolean
  parent?: string
  taskCollectionId?: number | null
  description?: string | null
  notes?: string | null
  uniqueKey?: string | null
  zone?: string | null
  isMemberFacing?: boolean
  usesExternalDescription?: boolean
}

export interface ITag {
  id: number
  displayName: string
}

export interface ICaseTag {
  id: number
  tag: ITag
}

interface CaseSummary {
  id: number
  content: string
}

export interface ICase extends IStateMachineFields {
  id: number
  user: number
  person: number
  category: number
  description: string
  externalDescription?: string | null
  notes?: string | null
  tasks: ITask[]
  relations: ICaseRelation[]
  updatedAt: string
  autoComplete?: boolean
  statusCategory?: StatusCategory
  createdAt?: string
  createdBy: string
  personMeta?: IWorkUnitPersonMeta
  dueDate?: string
  ownerGroup?: number
  isProposed?: boolean
  caseTags?: ICaseTag[]
  currentSummary?: CaseSummary
}

// This interface is used for steerage and we can't use Icase interface as the category type is number
export interface SteerageICaseWithCaseCategory extends IStateMachineFields {
  id: number
  user: number
  person: number
  category: ICaseCategory
  description: string
  notes?: string | null
  tasks: ITask[]
  relations: ICaseRelation[]
  updatedAt: string
  autoComplete?: boolean
  statusCategory?: StatusCategory
  createdAt?: string
  personMeta?: IWorkUnitPersonMeta
  dueDate?: string
  ownerGroup?: number
}

export interface ICaseRelation {
  contentType: ContentType
  objectId: number
  createdAt?: string
}
export type ContentType =
  | 'chatmessagev2'
  | 'phonecall'
  | 'careplan'
  | 'priorauthorization'
  | 'steerage'
  | 'waiver'
  | 'worklistitem'
  | 'measurereport'

type StatusCategory = 'not_started' | 'in_progress' | 'complete' | 'deferred' | 'pending'

export const workunitCompleteStates: Array<StatusCategory | undefined> = ['complete', 'deferred']

export const caseIsClosed = (c: ICase) => workunitCompleteStates.includes(c.statusCategory)
export const caseIsOpen = (c: ICase) => !caseIsClosed(c)

// Fields of task that are editable.
export const CASE_TASK_FIELDS = ['id', 'title', 'dueDate', 'ownerGroup', 'priority', 'isComplete']

export interface MessageIdentifier {
  // 'chatmessagev2-uid' is a workaround for real-time messaging
  // in which case we have a UID but not a DB ID
  type: ContentType | 'chatmessagev2-uid'
  id: number | string
}

// enum for status category
export const StatusCategoryEnum = {
  not_started: 'Not Started',
  in_progress: 'In progress',
  complete: 'Complete',
  deferred: 'Deferred',
  pending: 'Pending',
}

// enum for Case Status
// TODO: add more case status enum
export const CaseStatusEnum = {
  will_not_do: 'Will Not Do',
}

// enum for case category unique field
export const CaseCategoryUniqueEnum = {
  medication_request_for_refill: 'medication_request_for_refill',
  referrals_location_search_care_only: 'referrals_location_search_care_only',
  referrals_note_retrieval: 'referrals_note_retrieval',
  care_pass_referral_care_and_coverage: 'care_pass_referral_care_and_coverage',
  care_pass_provider_request: 'care_pass_provider_request',
  care_pass_prior_auth: 'care_pass_prior_auth',
  care_pass_member_request_coverage_only: 'care_pass_member_request_coverage_only',
  prior_auth_denial: 'prior_auth_denial',
  referrals_insurance_approvals: 'referrals_insurance_approvals',
  referrals_scheduling_follow_up: 'referrals_scheduling_follow_up',
}

// We just needed a small subset of ICase and hence creating one separate here
export interface IMeasureReportCase {
  category: number
  description: string
  status: string
  statusCategory: string
  updatedAt: string
  createdAt: string
  id: number
}
