import type { InternalAxiosRequestConfig } from 'axios'
import { datadogRum } from '@datadog/browser-rum'
import * as qs from 'query-string'

/**
 * To prevent the front end from accidentally spamming the server with requests, we can use a simple
 * interceptor to throttle requests. This will prevent the front end from sending more than X requests
 * per second to the same endpoint.
 */

// Create a map to store timestamps for each endpoint
export const TIMESTAMPS = new Map<string, number>()

// Define the rate limit (in milliseconds)
const REQUESTS_PER_SECOND = 5
const RATE_LIMIT = 1000 / REQUESTS_PER_SECOND

export const throttleRequestInterceptor = async (config: InternalAxiosRequestConfig) => {
  if (!config.method || !config.url) return config

  const now = Date.now()
  let url = `${config.method.toUpperCase()}:${config.url}`
  const params = config.params
  // We don't need to do any fancy serialization here, it just needs to be unique
  if (params) url = qs.stringifyUrl({ url, query: params })

  // Get the most recent timestamp for this endpoint
  const timestamp = TIMESTAMPS.get(url)
  // Update the timestamp for this endpoint
  TIMESTAMPS.set(url, now)

  if (timestamp) {
    const timeSinceLastRequest = now - timestamp

    if (timeSinceLastRequest < RATE_LIMIT) {
      // If the time since the last request is less than the limit, throw an error
      const ERROR_MESSAGE = `This endpoint is being called rapidly: ${url}`
      datadogRum.addError(ERROR_MESSAGE, { url: config.url })
      console.error(ERROR_MESSAGE)

      // TODO: Throw this error once all current disruptive errors have been documented and burned down
      // throw new Error(
      //   'You are sending too many requests to the same endpoint. This is likely unintentional, consider refactoring.'
      // )
    }
  }

  // Proceed with the request
  return config
}
