import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

function Loader(props) {
  const classes = useStyles(props)
  return (
    <div className={classes.container}>
      <CircularProgress
        variant="indeterminate"
        color={props.inline ? 'inherit' : 'primary'}
        size={props.inline ? '2rem' : '5rem'}
      />
    </div>
  )
}

const useStyles = makeStyles({
  container: {
    position: props => (props.inline ? 'relative' : 'absolute'),
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
  },
})

export default Loader
