import { ICase } from './Case'
import { CarePlanEducationResource } from './EducationResource'
import { MeasureCollectionMeasure } from './Measure'
import { IAction, IStateMachineStateWithCategory } from './StateMachine'
import { ITask } from './Task'
import { StatusCategory } from './WorkUnit'

/**
 * TODO: Update nested careplan tasks objects
 * assignedTasks and tasks objects are not returned in the same
 * format as in the tasks api; should be updated to match so
 * objects in redux look the same
 */

export interface ICarePlan {
  assignedTasks: ITask[]
  createdAt: Date
  createdBy: number
  id: number
  notes: string
  patient: number
  tasks: ITask[]
  title: string
  updatedAt: Date
  updatedBy: number
  typeId: number | null
  typeUid: string
  statusCategory: StatusCategory | null
  actions: IAction[]
  action: string
  status: string
  cases: ICase[]
  statesWithCategories: IStateMachineStateWithCategory[] | null
  isCaseBasedCarePlan: boolean | null
  carePlanEducationResources?: CarePlanEducationResource[]
  measureCollectionMeasures: MeasureCollectionMeasure[] | null
  carePlanDataTracking: string
}

export const CreateCarePlan = ({
  assignedTasks,
  createdAt,
  createdBy,
  id,
  notes,
  patient,
  tasks,
  title,
  updatedAt,
  updatedBy,
  typeId,
  typeUid,
  statusCategory,
  actions,
  action,
  status,
  cases,
  statesWithCategories,
  isCaseBasedCarePlan,
  carePlanEducationResources,
  measureCollectionMeasures,
  carePlanDataTracking,
}): ICarePlan => ({
  assignedTasks,
  createdAt,
  createdBy,
  id,
  notes,
  patient,
  tasks,
  title,
  updatedAt,
  updatedBy,
  typeId,
  typeUid,
  statusCategory,
  actions,
  action,
  status,
  cases,
  statesWithCategories,
  isCaseBasedCarePlan,
  carePlanEducationResources,
  measureCollectionMeasures,
  carePlanDataTracking,
})

export const CreateMockCarePlan = (payload: any = {}): ICarePlan =>
  CreateCarePlan({
    assignedTasks: payload.assignedTasks || [],
    createdAt: payload.createdAt || new Date().toISOString(),
    createdBy: payload.createdBy || 0,
    id: payload.id || 0,
    notes: payload.notes || 'TEST CAREPLAN NOTES',
    patient: payload.patient || 0,
    tasks: payload.tasks || [],
    title: payload.title || 'TEST CAREPLAN TITLE',
    updatedAt: payload.updatedAt || new Date().toISOString(),
    updatedBy: payload.updatedBy || 0,
    typeId: payload.typeId,
    statusCategory: payload.statusCategory || null,
    actions: payload.actions || [],
    action: payload.action,
    status: payload.status,
    cases: payload.cases,
    statesWithCategories: payload.statesWithCategories || null,
    isCaseBasedCarePlan: payload.isCaseBasedCarePlan || null,
    carePlanEducationResources: payload.carePlanEducationResources,
    measureCollectionMeasures: payload.measureCollectionMeasures || null,
    carePlanDataTracking: payload.carePlanDataTracking || null,
    typeUid: payload.typeUid,
  })
