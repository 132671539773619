import type { FC } from 'react'
import { Chip, Grid, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import { apiClient } from '~/api/rest'
import { usePromise } from '~/utils/usePromise'
import EditField from '../../Generic/EditField'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { ValueSetter } from '~/types'

interface EditPersonFieldsProps {
  firstName: string
  lastName: string
  dob: string
  preferredName: string
  employer: number | null
  employeeIdentifier: string | null
  gender: string[] | null
  sex: string
  pronouns: string | null
  loading: boolean
  isUser: boolean
  isNewMember?: boolean
  setValues: ValueSetter
  setFieldValue: (name: string, value: any) => any
  setFieldTouched: (name: string) => any
}

const GENDERS = ['Unknown', 'Female', 'Male']

export const EditPersonFields: FC<EditPersonFieldsProps> = props => {
  const init = usePromise(
    async () => {
      const [employers, pronouns, genders] = await Promise.all([
        apiClient.rest.get('/insurance/employer/'),
        apiClient.rest.get('/facts/pronouns/?is_deleted=false'),
        apiClient.rest.get('/facts/gender-identities/?is_deleted=false'),
      ])
      return {
        employers: employers.map(e => ({ name: e.name, id: e.id })),
        pronouns: pronouns.map(p => p.label),
        genders: genders.map(g => g.label),
      }
    },
    { error: 'Failed to fetch form option data' }
  )
  const featureAccess = useFeatureAccess()?.tenant
  const {
    firstName,
    lastName,
    dob,
    preferredName,
    employer,
    employeeIdentifier,
    sex,
    gender,
    pronouns,
    isUser,
    isNewMember,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = props

  function getFieldName(name: string) {
    if (isUser == true) name = 'person.' + name
    return name
  }

  return (
    <>
      <Grid container spacing={2}>
        <EditField
          {...setValues('firstName', firstName)}
          label="First Name"
          required={featureAccess?.personMatchingDuringSignup}
        />
        <EditField
          {...setValues('lastName', lastName)}
          label="Last Name"
          required={featureAccess?.personMatchingDuringSignup}
        />
        <EditField
          {...setValues(getFieldName('preferredName'), preferredName)}
          label="Preferred Name"
        />
        <EditField
          {...setValues(getFieldName('dob'), dob)}
          InputLabelProps={{ shrink: true }}
          label="Date of Birth"
          type="date"
          required={featureAccess?.personMatchingDuringSignup}
        />
        <EditField
          {...setValues(getFieldName('sex'), sex)}
          label="Sex Assigned at Birth"
          select
          SelectProps={{ native: true }}
          required={featureAccess?.personMatchingDuringSignup}
        >
          {GENDERS.map(v => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </EditField>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <EditField
              {...setValues(getFieldName('employer'), employer)}
              label="Employer"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              disabled={isNewMember}
            >
              <option value="">(none)</option>
              {init.result?.employers.map(employer => (
                <option key={employer.id} value={employer.id}>
                  {employer.name}
                </option>
              ))}
            </EditField>
            <EditField
              {...setValues('employeeIdentifier', employeeIdentifier)}
              label="Employee ID"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            // Only one value will be returned from the backend for the gender field.
            // Right now in DB the gender field is a array of varchar[],
            // once it is migrated to varchar the same will be updated here.
            value={gender && gender.length > 0 ? gender[0] : null}
            onChange={(_, val) => {
              setFieldValue(getFieldName('gender'), [val])
              setFieldTouched(getFieldName('gender'))
            }}
            options={init.result?.genders || []}
            disabled={props.loading}
            // @ts-ignore Unclear why this isn't lining up
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip size="small" label={option} {...getTagProps({ index })} key={index} />
              ))
            }
            renderInput={params => <TextField {...params} label="Gender Identity" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            onInputChange={(_, val) => {
              setFieldValue(getFieldName('pronouns'), val)
              setFieldTouched(getFieldName('pronouns'))
            }}
            value={pronouns ?? ''}
            disabled={props.loading}
            options={init.result?.pronouns || []}
            freeSolo
            renderInput={params => <TextField {...params} label="Preferred Pronouns" />}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EditPersonFields
