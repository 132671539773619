import { useMutation as useCoreMutation } from '~/legacy/core'

import { UseMutationConfig, useResolvedPromise } from './useResolvedPromise'

/**
 * Re-exports useMutation from ~/legacy/core with Lucian-specific
 * side effects via useResolvedPromise. This includes some code duplication
 * to get the types to match what's in the ~/legacy/core.
 *
 * TODO: Inline ~/legacy/core here.
 */
export function useMutation<P extends any[], S, E = any>(
  getPromise: (...args: P) => Promise<S>,
  config: UseMutationConfig = { initialLoading: false }
) {
  const mutation = useCoreMutation<P, S, E>(getPromise, config)
  useResolvedPromise(mutation, config)
  return mutation
}
