import { useDispatch } from 'react-redux'
import { useMutation } from '~/legacy/core'
import { transform, isEqual, isObject } from 'lodash'

import { apiClient } from '~/api/rest'
import { queueNotification } from '~/redux/actions/notifications'
import patientsSlice from '~/redux/slices/patients'

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
  return transform(object, (result: any, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value
    }
  })
}

export const sendOtpForEmailUpdate = async (id: string) => {
  await apiClient.rest.post('/user/provider/phone/verify/code/', { id: id })
}

export const verifyOtpForEmailUpdate = async (id?: string, otp?: string) => {
  await apiClient.rest.post('/user/provider/phone/verify/', {
    id: id,
    code: otp,
  })
}

export const triggerEmailVerification = async (id?: number) => {
  await apiClient.rest.post(`/user/${id}/email/send-verification/`)
}

export const useUpdateEmail = () => {
  const dispatch = useDispatch()
  return useMutation(async (patientId, emailRef, setEmailState, setError) => {
    try {
      if (emailRef) {
        await dispatch(
          patientsSlice.thunks.updatePatient({
            id: patientId,
            email: emailRef,
          })
        )
        await triggerEmailVerification(patientId)
        dispatch(queueNotification({ message: 'Email has been updated', variant: 'success' }))
        setEmailState({ title: 'Ask Member to Follow Verification Link', page: 'success' })
        setError(false)
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
      }
      setError(true)
      dispatch(queueNotification({ message: 'Email update Failed.', variant: 'error' }))
    }
  })
}

export const useOtpVerification = () => {
  const dispatch = useDispatch()
  return useMutation(async (patientId, Otp, setEmailState, setError) => {
    try {
      if (patientId && Otp) {
        await verifyOtpForEmailUpdate(patientId, Otp)
        dispatch(
          queueNotification({ message: 'OTP verification is successfull', variant: 'success' })
        )
        setEmailState({ title: 'Ask Member for New Email Address', page: 'update_email' })
        setError(false)
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
      }
      setError(true)
      dispatch(queueNotification({ message: 'OTP verification failed', variant: 'error' }))
    }
  })
}

export const useTriggerOtp = () => {
  const dispatch = useDispatch()
  return useMutation(async (patientId, setEmailState, setError) => {
    try {
      if (patientId) {
        await sendOtpForEmailUpdate(patientId)
        dispatch(queueNotification({ message: 'OTP has been sent', variant: 'success' }))
        setEmailState({ title: 'Ask Member to Confirm Security Code', page: 'verify_otp' })
        setError(false)
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
      }
      dispatch(queueNotification({ message: 'OTP Failed to send', variant: 'error' }))
    }
  })
}
