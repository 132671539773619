import { apiClient } from '~/api/rest'
import { keyBy } from 'lodash/fp'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

// Corresponds to Waffle Flags on backend
interface FeatureFlag {
  id: number
  name: string
  note: string
  users: number[]
  isActive: boolean
}

export const FEATURE_FLAG_QUERY_KEY = ['featureFlags']

const featureFlagsRequest = () => apiClient.rest.get<FeatureFlag[]>('/feature/flags/')

export const useFeatureFlags = () => {
  const me = useSelector(state => state.me)

  return useQuery(FEATURE_FLAG_QUERY_KEY, featureFlagsRequest, {
    staleTime: Infinity,
    enabled: me?.id != null,
    select: data => ({
      featureFlagsByName: keyBy('name', data),
    }),
  })
}

export const useFeatureFlag = name => {
  const { data } = useFeatureFlags()
  return !!data?.featureFlagsByName?.[name]?.isActive
}

// Quick hook factory to avoid code duplication for flag enrollment / unenrollment mutations
// These hook utilities are for adding/removing a user from a feature flag
const makeFlagEnrollmentHook = (enroll: boolean) => () => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload: { id: number; userId: number }) =>
      apiClient.rest.post<FeatureFlag>(
        `/feature/provider/flag/${payload.id}/${enroll ? 'add' : 'remove'}/${payload.userId}/`
      ),
    {
      onSuccess: (data, payload) => {
        queryClient.setQueryData<FeatureFlag[]>(FEATURE_FLAG_QUERY_KEY, (prev = []) => {
          const previousFlagIndex = prev.findIndex(a => a.id === payload.id)
          if (previousFlagIndex !== -1) {
            prev[previousFlagIndex] = data
          } else {
            prev.push(data)
          }
          return prev
        })
        // Invalidate the cache to ensure local update matches the server
        queryClient.invalidateQueries(FEATURE_FLAG_QUERY_KEY)
      },
    }
  )
}

export const useEnrollInFeatureFlag = makeFlagEnrollmentHook(true)
export const useUnenrollInFeatureFlag = makeFlagEnrollmentHook(false)
