import { PatientUser } from '~/legacy/core'
import { providerCanProvideClinicalCareToPatient } from './index'

export interface AssignableUserOption {
  label: string
  value: any // the Provider object
  disabledReason?: string | null
}

const DISABLED_REASONS = {
  out_of_state: "Not licensed in the patient's state",
  inactive: 'Inactive',
}

// Returns a list of grouped options, meant to be used in a react-select component
// Each individual option is a Provider who a Task may be assigned to
export const getAssignableUsers = (
  patient: any,
  providers: any[],
  canAssignToPatient: boolean,
  careTeam: number[] | undefined
): {
  label: string
  options: AssignableUserOption[]
}[] => {
  const disabledReason = (userOption: any): string | null => {
    if (patient && !providerCanProvideClinicalCareToPatient(userOption, patient)) {
      return DISABLED_REASONS['out_of_state']
    }
    if (!userOption.isActive) {
      return DISABLED_REASONS['inactive']
    }

    return null
  }

  careTeam = careTeam ?? []
  const patientCareTeam =
    careTeam.length > 0
      ? providers.filter(
          provider => !provider.testOnly && provider.isActive && careTeam!.includes(provider.id)
        )
      : []

  // Find all providers(physicians) with practicing states in patient state
  const providersInPatientState = patient
    ? providers.filter(
        provider =>
          providerCanProvideClinicalCareToPatient(provider, patient) &&
          !careTeam!.includes(provider.id)
      )
    : []

  let activeCoveringProviders = providers.filter(
    provider => !provider.testOnly && provider.isActive && !careTeam!.includes(provider.id)
  )
  if (patient) {
    activeCoveringProviders = activeCoveringProviders.filter(
      provider => !providerCanProvideClinicalCareToPatient(provider, patient)
    )
  }

  const inactiveProviders = providers.filter(provider => provider.testOnly || !provider.isActive)

  const orderedPatientCareTeam = patientCareTeam.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )

  const orderedProvidersInPatientState = providersInPatientState.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )

  const orderedCoveringProviders = activeCoveringProviders.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )
  const orderedInactiveProviders = inactiveProviders.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )

  const groupedCareTeam = careTeam
    ? [
        {
          label: 'CARE TEAM',
          options: orderedPatientCareTeam.map(provider => ({
            label: `${provider.firstName} ${provider.lastName}`,
            value: provider.id,
            disabledReason: disabledReason(provider),
          })),
        },
      ]
    : []

  const groupedProvidersInPatientState = providersInPatientState
    ? [
        {
          label: 'CLINICIANS IN STATE',
          options: orderedProvidersInPatientState.map(provider => ({
            label: `${provider.firstName} ${provider.lastName}`,
            value: provider.id,
            disabledReason: disabledReason(provider),
          })),
        },
      ]
    : []

  const groupedProviders = [
    {
      label: 'COVERING TEAM',
      options: orderedCoveringProviders.map(provider => ({
        label: `${provider.firstName} ${provider.lastName}`,
        value: provider.id,
        disabledReason: disabledReason(provider),
      })),
    },
    {
      label: 'INACTIVE CARE TEAM',
      options: orderedInactiveProviders.map(provider => ({
        label: `${provider.firstName} ${provider.lastName}`,
        value: provider.id,
        disabledReason: disabledReason(provider),
      })),
    },
  ]

  let groupedOptions = canAssignToPatient
    ? [
        {
          label: 'PATIENT',
          options: [
            {
              label: `${patient.firstName} ${patient.lastName}`,
              value: patient.id,
            },
          ],
        },
      ]
    : []

  groupedOptions = groupedOptions.concat(
    groupedCareTeam,
    groupedProvidersInPatientState,
    groupedProviders
  )
  return groupedOptions
}

/** Use the patient's preferred name if it exists */
export const patientName = (patient: PatientUser | undefined): string => {
  return patient?.person?.preferredName || patient?.firstName || ''
}
