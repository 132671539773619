import { createAction } from '@reduxjs/toolkit'
import { usePubNubUtils } from '~/utils/pubnub'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '~/utils/useMutation'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { logger } from './logger'

export const auth0Authenticate = createAction('auth0/authenticate')
export const auth0Deauthenticate = createAction('auth0/deauthenticate')

export const SESSION_STORAGE_TOKEN_KEY = 'sessionToken'
export const ID_TOKEN_KEY = 'idToken'

export const setSessionToken = (token: string) => {
  // Temporarily log when the session token is updated, and what the expiration
  // time is, for debugging purposes.
  try {
    const decoded = jwt_decode<JwtPayload>(token)
    logger.debug(`[Session] Updated session token with expiration ${decoded.exp}`)
  } catch (e) {
    logger.error(`[Session] Could not parse token: ${e}`)
  }
  sessionStorage.setItem(SESSION_STORAGE_TOKEN_KEY, token)
}

export const setIdToken = (token: string) => {
  sessionStorage.setItem(ID_TOKEN_KEY, token)
}
export const getSessionToken = () => sessionStorage.getItem(SESSION_STORAGE_TOKEN_KEY)
export const removeSessionToken = () => sessionStorage.removeItem(SESSION_STORAGE_TOKEN_KEY)
export const getIdToken = () => sessionStorage.getItem(ID_TOKEN_KEY)
export const removeIdToken = () => sessionStorage.removeItem(ID_TOKEN_KEY)

export const isSessionAuthenticated = () => getSessionToken() != null

export const useLogoutEffects = (options?) => {
  const auth0 = useAuth0()
  const { disconnectFromPubNub } = usePubNubUtils()
  return useMutation(() => {
    logger.debug('[Session] useLogoutEffects mutation')
    return Promise.all([
      removeSessionToken(),
      removeIdToken(),
      auth0.logout({ ...options?.auth0 }),
      disconnectFromPubNub(),
    ])
  })
}
