import React, { useCallback, useContext, useMemo, useReducer } from 'react'

const Context = React.createContext({
  get: (_key: string): string | null => '',
  set: (_key: string, _value: string | null) => {},
})

export const LocalStorageProvider: React.FC = ({ children }) => {
  // This is just to force another render if values change.
  const [version, incrementVersion] = useReducer(version => version + 1, 0)

  const get = useCallback(
    (key: string) => {
      return localStorage.getItem(key)
    },
    [version]
  )

  const set = useCallback(
    (key: string, value: string | null) => {
      value !== null && value != undefined
        ? localStorage.setItem(key, value)
        : localStorage.removeItem(key)
      incrementVersion()
    },
    [incrementVersion]
  )

  return (
    <Context.Provider
      value={{
        get,
        set,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useLocalStorage = (key: string, initial: string | null = null) => {
  const { get, set } = useContext(Context)

  const value = useMemo(() => {
    const persisted = get(key)
    return persisted !== null ? persisted : initial
  }, [get, initial, key])

  const setValue = useCallback(
    (newValue: string | null) => {
      set(key, newValue)
    },
    [set, key]
  )

  return [value, setValue] as const
}
