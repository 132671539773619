import { WorklistWorkUnit } from './PatientTodoItem'
import { Worklist } from '~/redux/slices/worklists'

export const getIsWorklistWithWorkUnits = (worklist: Worklist) => {
  // Should we expect the items in this list to be of type WorklistItem (vs. WorklistWorkUnit)?
  // If so, there is some extra Worklist UX functionality we can allow
  return worklist.dataSource && worklist.dataSource != 'WORK_UNIT_API'
}

export const getItemIsClosed = (item: WorklistWorkUnit) => {
  return item.statusCategory == 'complete' || item.statusCategory == 'deferred'
}
