import { Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { formatDateWithDay } from '~/utils/date'

interface IProps {
  responseData: IEligibilityCheckResult
}

export interface IEligibilityCheckResult {
  requestedByUser?: { firstName: string; lastName: string } | null
  latestResponseTime: Date
  isEligible: boolean
  rejections?: string[]
}

const EligibilityCheckResponseDetails = (props: IProps) => {
  if (!props.responseData) return null

  const { isEligible, rejections } = props.responseData
  const requesterDescription = props.responseData.requestedByUser?.firstName
    ? `Requested by ${props.responseData.requestedByUser.firstName} ${props.responseData?.requestedByUser.lastName}`
    : ''
  const formattedTime = props.responseData.latestResponseTime
    ? formatDateWithDay(props.responseData.latestResponseTime)
    : null

  if (isEligible) {
    return (
      <Alert severity="success">
        <Box display="block">
          <strong>Eligible</strong> - Checked on {formattedTime}
          {requesterDescription && <div>{requesterDescription}</div>}
        </Box>
      </Alert>
    )
  }

  if (rejections) {
    return (
      <Alert severity="warning">
        <Box display="block">
          <strong>{rejections.join(',')}</strong> - Checked on&nbsp;
          {formattedTime}
        </Box>
      </Alert>
    )
  }

  return (
    <Alert severity="error">
      <Box display="block">
        <strong>Ineligible</strong> - Checked on {formattedTime}
        {requesterDescription && <div>{requesterDescription}</div>}
      </Box>
    </Alert>
  )
}

export default EligibilityCheckResponseDetails
