import { apiClient } from '~/api/rest'
import { useQuery } from '~/components/Providers/ApiProvider'

export interface UserSegmentation {
  userSegmentationResponse: { question: string; answer: string }[]
  completedAt: string | number | Date
}

export const getPersonSegmentation = async (personId: number) => {
  const data = await apiClient.rest.get<UserSegmentation>(`/user-segmentation/${personId}/`)
  return data
}

export const getPersonSegmentationKey = 'getPersonSegmentation'
export const usePersonSegmentation = (personId: number) => {
  const { data, isLoading, error } = useQuery(
    [`getPersonSegmentationKey-${personId}`],
    () => getPersonSegmentation(personId),
    { retry: 1 }
  )
  return { result: data, isLoading, error }
}
