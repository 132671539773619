import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ITag } from '~/models/Case'

const useStyles = makeStyles()(theme => {
  return {
    contentTitle: {
      padding: '0 0.5rem',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      fontSize: '1.2rem',
      display: 'inline-flex',
      fontWeight: 500,
    },
  }
})

const CaseTag: FC<{
  tag: ITag
  key: string
}> = props => {
  const { key, tag } = props

  const { classes } = useStyles()

  return (
    <Box mb={0.25} fontSize={'1.2rem'} display={'inline-flex'} bgcolor="grey.100" key={key} mr={1}>
      <Typography className={classes.contentTitle}>{tag.displayName}</Typography>
    </Box>
  )
}

export default CaseTag
