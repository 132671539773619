import { getProvidersRequest } from '~/requests/providers'
import {
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_ERROR,
  UPDATE_PROVIDER_PRACTICING_STATES,
} from './actionTypes'

export function getProviders() {
  return async dispatch => {
    try {
      let response = await getProvidersRequest()
      for (let provider of response.data) {
        let pods = []
        if (provider.providerFields.podProviders) {
          for (let pod_provider of provider.providerFields.podProviders) {
            pods.push(pod_provider.pod.id)
          }
          provider.providerFields.pods = pods
          delete provider.providerFields.podProviders
        }
      }
      const providers = response.data
      return dispatch(getProvidersSuccess(providers))
    } catch (error) {
      console.log(error)
      return dispatch(getProvidersError('Problem fetching providers'))
    }
  }
}

const getProvidersSuccess = providers => ({
  type: GET_PROVIDERS_SUCCESS,
  providers,
})

const getProvidersError = error => ({
  type: GET_PROVIDERS_ERROR,
  error,
})

export const updateProviderPracticingStates = (id, practicingStates) => ({
  type: UPDATE_PROVIDER_PRACTICING_STATES,
  payload: {
    id,
    practicingStates,
  },
})
