import { datadogRum } from '@datadog/browser-rum'

export type EventType =
  // User enters a steerage case
  | 'STEERAGE_CASE_ENTRY'
  // User exits a steerage case
  | 'STEERAGE_CASE_EXIT'
  // User begins on the add provider workflow on a steerage case
  | 'STEERAGE_INITIATE_ADD_PROVIDER_WORKFLOW'
  // User begins the add exception provider workflow
  | 'STEERAGE_ADD_EXCEPTION'
  // User initiates the search workflow without a steerage
  | 'SEARCH_REQUEST'
  // User initiates the search workflow from a steerage
  | 'STEERAGE_SEARCH_REQUEST'
  // User adds a provider to the steerage on the search results screen
  | 'STEERAGE_ADD_PROVIDER_TO_STEERAGE_VIA_SEARCH'
  // User removes a provider from the steerage on the search result screen
  | 'STEERAGE_REMOVE_PROVIDER_FROM_STEERAGE_VIA_SEARCH'
  // User clicked on the edit availability button
  | 'EDIT_AVAILABILITY_BUTTON_CLICK'
  // User clicked on the edit contact details modal button
  | 'EDIT_PROVIDER_DETAILS_BUTTON_CLICK'
  // User updated availability for provider at a location
  | 'UPDATED_AVAILABILITY_PROVIDER_AT_A_LOCATION'
  // User updated provider details for provider at a location
  | 'UPDATED_PROVIDER_DETAILS_PROVIDER_AT_A_LOCATION'
  // User updated availability for all provider at a department
  | 'UPDATED_AVAILABILITY_ALL_PROVIDER_AT_A_DEPARTMENT'
  // User updated provider details for all provider at a department
  | 'UPDATED_PROVIDER_DETAILS_ALL_PROVIDER_AT_A_DEPARTMENT'
  // User updated availability for all provider at a location
  | 'UPDATED_AVAILABILITY_ALL_PROVIDER_AT_A_LOCATION'
  // User updated provider details for all provider at a location
  | 'UPDATED_PROVIDER_DETAILS_ALL_PROVIDER_AT_A_LOCATION'
  // User clicked on bin icon for showing the clearing availability modal
  | 'OPEN_MODAL_FOR_CLEARING_AVAILABILITY_CLICK'
  // User clicked on "Mark as clinical/operational"
  | 'UPDATED_MESSAGE_TYPE_FOR_CHAT_MESSAGE'
  // User clicked on "Mark as urgent/not urgent"
  | 'UPDATED_MESSAGE_URGENCY_FOR_CHAT_MESSAGE'
  // User unenrolls a member from Zoom SDK
  | 'ZOOM_SDK_UNENROLL_MEMBER'
  // This measures how many time we have reused the curated data for provider/facility
  | 'DATA_CURATION_USAGE'
  // Measures usage of Talon
  | 'TALON_SEARCH'
  // Measure interactions with items in the list of Inbox items
  | 'INBOX_ROW_SELECTED'

/**
 * Model attributes that should be present in all models
 * DataDog automatically adds the following attributes (list is limited to attributes that we might find useful):
 *   UserId
 *   SessionId
 *   EventStart
 *   PageUrl
 */
export interface BaseEventPayload {}

export interface SteerageEventPayload extends BaseEventPayload {
  steerageId: number
}

export interface EditProviderAndAvailabilityPayload extends BaseEventPayload {
  providerNpi?: string | number | null
  careOrganisationName?: string | null
  steerageId?: number | null
  isEditedInDirectory?: boolean
  doesProviderExist?: boolean | null
  isClearingAvailability?: boolean | null
  isVerified?: boolean | null
  isProviderSearch: boolean | null
}

export interface SearchEventPayload {
  searchRequestId: number | null
  pageSize: number
  numberOfProviders: number | null
  requestDurationInSeconds: number
  npi?: string[] | null
  providerName?: string | null
  procedureCode?: number | null
  specialtyId?: number | null
  facilityName?: string | null
  facilityType?: number | null
  SearchAvailabilityId: number | null
}

export interface SteerageSearchEventPayload extends SearchEventPayload {
  steerageId: number | null
}

export interface SteerageAddProviderFromSearchPayload extends SteerageEventPayload {
  positionOfProvider: number
  steerageProviderId: number
}

export interface SteerageRemoveProviderFromSearchPayload
  extends SteerageAddProviderFromSearchPayload {}

export interface UpdateMessageType extends BaseEventPayload {
  messageUid?: string | null
  previousMessageType?: string | null
  revisedMessageType?: string | null
  userRoleTitle?: string | null
}

export interface UpdateMessageUrgency extends BaseEventPayload {
  messageUid?: string | null
  previousMessageUrgency?: string | null
  revisedMessageUrgency?: string | null
  userRoleTitle?: string | null
}

export interface ZoomSdkEnrollmentPayload extends BaseEventPayload {
  memberId: number
}

export interface DataCurationUsagePayload extends BaseEventPayload {
  steerageId: number
  steerageProviderId: number
  isAvailabilityPresent: boolean
  isContactDetailsVerified: boolean
  isProviderSearch: boolean
}

export interface InboxRowSelectedPayload extends BaseEventPayload {
  pagePosition: number
  absolutePosition: number
  contentType: string
  contentObjectId: number
  urlType?: string
}

export interface TalonSearchPayload extends SteerageEventPayload {}

/**
 * Logs events using the full story and DataDog framework.
 * Modeling as a separate function so that the underlying
 * implementation can be made more complex / the libraries
 * used can be swapped out in the future
 */
export async function logEvent<EventPayload extends BaseEventPayload>(
  type: EventType,
  payload: EventPayload
) {
  // TODO: Error handling/ rate limiting
  datadogRum.addAction(type, payload)
}
