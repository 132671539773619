import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { useState } from 'react'
import EnrollMemberModel from './EnrollMemberModel'
import ChurnMemberModel from './ChurnMemberModel'
import {
  DISCHARGE_IN_PROGRESS_TEXT,
  PRIMARYCARE_PROGRAM_STATUSES,
} from '~/constants/programEnrollment'
import CancelDischrgeModel from './CancelDischargeModel'

const PrimaryCareProgramMenu = ({ enrollmentStatus, patient, churnedReason }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [showEnrollModel, setshowEnrollModel] = useState<boolean>(false)
  const [showChurnModel, setshowChurnModel] = useState<boolean>(false)
  const [showCancelDischargeModel, setShowCancelDischargeModel] = useState<boolean>(false)
  const [enrollmentLabel, setEnrollmentLabel] = useState<string>('Enroll')
  const [isChurned, setIsChurned] = useState<boolean>(true)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const getMenuList = () => {
    const MenuList: JSX.Element[] = []
    if (!enrollmentStatus) {
      MenuList.push(
        <MenuItem
          key="enroll"
          onClick={() => {
            setshowEnrollModel(true)
            setAnchorEl(null)
            setEnrollmentLabel('Enroll')
          }}
        >
          Enroll member
        </MenuItem>
      )
    } else if (churnedReason && churnedReason.includes(DISCHARGE_IN_PROGRESS_TEXT)) {
      MenuList.push(
        <MenuItem
          key="cancel-discharge"
          onClick={() => {
            setShowCancelDischargeModel(true)
            setAnchorEl(null)
          }}
        >
          Stop discharge process
        </MenuItem>
      )
    } else if (enrollmentStatus === PRIMARYCARE_PROGRAM_STATUSES.Churned) {
      MenuList.push(
        <MenuItem
          key="reenroll"
          onClick={() => {
            setshowEnrollModel(true)
            setAnchorEl(null)
            setEnrollmentLabel('Re-enroll')
          }}
        >
          Re-enroll member
        </MenuItem>
      )
    } else {
      MenuList.push(
        <MenuItem
          key="churn"
          onClick={() => {
            setshowChurnModel(true)
            setAnchorEl(null)
            setIsChurned(true)
          }}
        >
          Churn member
        </MenuItem>
      )
      if (enrollmentStatus === PRIMARYCARE_PROGRAM_STATUSES.Established) {
        MenuList.push(
          <MenuItem
            key="discharge"
            onClick={() => {
              setshowChurnModel(true)
              setAnchorEl(null)
              setIsChurned(false)
            }}
          >
            Discharge member
          </MenuItem>
        )
      }
    }
    return MenuList
  }

  return (
    <Box sx={{ ml: 2 }}>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{
          disablePadding: true,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getMenuList()}
      </Menu>
      <Box>
        {showEnrollModel && (
          <EnrollMemberModel
            patient={patient}
            handleClose={() => setshowEnrollModel(false)}
            open={showEnrollModel}
            enrollmentLabel={enrollmentLabel}
          />
        )}
        {showChurnModel && (
          <ChurnMemberModel
            patient={patient}
            handleClose={() => setshowChurnModel(false)}
            open={showChurnModel}
            isChurned={isChurned}
          />
        )}
        {showCancelDischargeModel && (
          <CancelDischrgeModel
            patient={patient}
            handleClose={() => setShowCancelDischargeModel(false)}
            open={showCancelDischargeModel}
          />
        )}
      </Box>
    </Box>
  )
}

export default PrimaryCareProgramMenu
