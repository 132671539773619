import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { FC } from 'react'
import { CanUserBookAppointmentsV2, VisitReason } from '~/api/AppointmentService'
import { Mapping } from './SelectAppointmentType'

type AppointmentTypesProps = {
  userTypes: CanUserBookAppointmentsV2
  selectedVisitReason: VisitReason | null
  onChange: (visitReason: VisitReason) => void
}

const AppointmentTypes: FC<AppointmentTypesProps> = ({
  userTypes,
  selectedVisitReason,
  onChange,
}) => {
  const handleChange = (_: any, value: any) => {
    if (value !== null) {
      onChange(value as VisitReason)
    }
  }
  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedVisitReason}
      exclusive
      onChange={handleChange}
      sx={{
        '.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
          padding: '7px 20px',
          textTransform: 'capitalize',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '.MuiToggleButton-root.Mui-selected': {
          backgroundColor: '#f5f5f5',
          color: '#021A39',
        },
      }}
    >
      {Mapping.map(([visitReason, apptType]) => {
        return userTypes[visitReason] ? (
          <ToggleButton value={visitReason}>{apptType}</ToggleButton>
        ) : null
      })}
    </ToggleButtonGroup>
  )
}

export default AppointmentTypes
