import { TextField, Typography } from '@mui/material'
import { AutocompleteRenderInputParams, Autocomplete } from '@mui/material'
import { HTMLAttributes } from 'react'
import { ICaseCategory } from '~/models/Case'
import { makeStyles } from 'tss-react/mui'
import MemberFacingCaseAlert from '../PatientCategorizedTodos/MemberFacingCaseAlert'

interface CaseCategoryInputParams extends AutocompleteRenderInputParams {
  category: ICaseCategory
  freeSolo?: boolean
}

interface CaseCategoryOptionParams extends HTMLAttributes<HTMLLIElement> {
  category: ICaseCategory
}

interface Props {
  categories: ICaseCategory[]
  category: ICaseCategory | null
  setCategory: (arg0: ICaseCategory | null) => void
  freeSolo?: boolean
  handleAddCase?: (arg0: ICaseCategory) => void
  onBlur?: () => void
}

const useStyles = makeStyles()(theme => {
  return {
    autoCompleteText: {
      fontSize: '1.4rem',
    },
  }
})

const CaseCategorySelect = ({
  categories,
  category,
  setCategory,
  freeSolo,
  handleAddCase,
  onBlur,
}: Props) => {
  const { classes } = useStyles()

  const CaseCategoryInput = (params: CaseCategoryInputParams) => {
    return (
      <>
        <TextField
          {...params}
          placeholder={params.freeSolo ? 'Add case, `esc` to cancel' : 'Case Category'}
          variant="outlined"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={params.freeSolo ? true : false}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.category?.isMemberFacing ? (
                  <MemberFacingCaseAlert
                    style={{ fontSize: '0.85em' }}
                    key={String(params.category?.id)}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': { padding: params.freeSolo ? 0 : 1 },
          }}
        />
      </>
    )
  }

  const CaseCategoryOption = (params: CaseCategoryOptionParams) => {
    return (
      <Typography {...params}>
        {params.category.title}
        {params.category?.isMemberFacing ? (
          <>
            &nbsp;&nbsp;
            <MemberFacingCaseAlert
              style={{ fontSize: '0.85em' }}
              key={String(params.category?.id)}
            />
          </>
        ) : null}
      </Typography>
    )
  }

  return freeSolo ? (
    <Autocomplete<ICaseCategory, undefined, undefined, true>
      id="grouped-category"
      openOnFocus
      freeSolo
      onBlur={onBlur}
      onKeyDown={e => {
        if (onBlur && e.key === 'Escape') {
          onBlur()
          e.stopPropagation()
        }
      }}
      options={categories
        .filter(category => !category.isSystemOnly && !category.isDeprecated)
        .sort((a, b) => -(b.parent || 'UNCATEGORIZED').localeCompare(a.parent || 'UNCATEGORIZED'))}
      groupBy={option => option.parent || 'UNCATEGORIZED'}
      getOptionLabel={option => option.title}
      value={category}
      renderInput={params => (
        <CaseCategoryInput {...params} category={category!} freeSolo={freeSolo} />
      )}
      renderOption={(props, option) => <CaseCategoryOption {...props} category={option!} />}
      onChange={(_event, newValue) => {
        if (handleAddCase) handleAddCase(newValue)
      }}
      classes={{ input: classes.autoCompleteText, option: classes.autoCompleteText }}
    />
  ) : (
    <Autocomplete<ICaseCategory>
      id="grouped-category"
      options={categories
        .filter(category => !category.isSystemOnly && !category.isDeprecated)
        .sort((a, b) => -(b.parent || 'UNCATEGORIZED').localeCompare(a.parent || 'UNCATEGORIZED'))}
      groupBy={option => option.parent || 'UNCATEGORIZED'}
      getOptionLabel={option => option.title}
      value={category}
      renderInput={params => <CaseCategoryInput {...params} category={category!} />}
      renderOption={(props, option) => <CaseCategoryOption {...props} category={option!} />}
      onChange={(_event, newValue) => {
        if (newValue) setCategory(newValue)
      }}
    />
  )
}

export default CaseCategorySelect
