import { useSelector } from 'react-redux'
import keyBy from 'lodash/fp/keyBy'
import { ProviderUser } from '~/legacy/core'

interface Props {
  patientId: number
  currentUser: { id: number }
}

/**
 * Show the initials of all providers who are currently typing to this same patient
 */
const ChatTypingIndicator = ({ patientId, currentUser }: Props) => {
  const providers = useSelector(state => state.providers)
  const providersById = keyBy('id', providers)
  const typingState = useSelector(state => state.chat.typing)
  const currentlyTypingForThisPatient = Object.keys(typingState).filter(
    providerId => typingState[providerId] == patientId && providerId != currentUser.id.toString()
  )

  if (currentlyTypingForThisPatient.length == 0) return null

  const providersToShow = currentlyTypingForThisPatient.reduce((arr, el) => {
    const provider = providersById[el]
    if (provider) {
      arr.push(provider)
    }
    return arr
  }, [] as ProviderUser[])

  if (providersToShow.length == 0) return null

  const providerJsx = providersToShow.map(provider => (
    <span title={`${provider.firstName} ${provider.lastName}`} key={provider.id}>
      {provider.firstName[0]}
      {provider.lastName[0]}
    </span>
  ))

  return (
    <div>
      {providerJsx} {providersToShow.length >= 2 ? 'are' : 'is'} typing...
    </div>
  )
}

export default ChatTypingIndicator
