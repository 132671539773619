import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import ClearIcon from '@mui/icons-material/Clear'
import { makeStyles } from 'tss-react/mui'
import {
  Select,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  FormLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input'
import { SpecialtyGroup } from '~/legacy/core'
import { ProviderDetailWithAdditionalData } from './ProviderListItem'
import Loader from '~/components/Loader'
import { UpdateCuratedProvider, useEditCuratedProvider } from '../../../api/UpdateProviderDetails'
import { EditProviderAndAvailabilityPayload, logEvent } from '~/utils/events'
import { formatPhoneNumber } from '~/utils'

const MuiTelInputStyled = styled(MuiTelInput)`
  & .MuiTelInput-IconButton + p {
    display: none;
  }
  & .MuiTelInput-IconButton {
    display: none;
  }
`

export interface UpdateProviderDetailsModalProps {
  provider: ProviderDetailWithAdditionalData | null
  specialtyGroups: SpecialtyGroup[] | null
  handleEditProviderDetailsClose: () => void
  steerageId?: number | null
  isProviderSearch: Boolean | null
  isEditedInDirectory?: boolean
}

const useStyles = makeStyles()(theme => {
  return {
    modalTitle: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '16px',
    },
    subTitle: {
      fontSize: '16px',
      marginTop: '-4px',
    },
    textFieldWidth: {
      width: '75%',
    },
    selectElementStyle: {
      maxHeight: '25px',
      marginLeft: '4px',
      marginRight: '5px',
    },
    cancelButton: {
      marginRight: '10px',
      borderRadius: '28px',
    },
    saveButton: {
      borderRadius: '28px',
    },
  }
})

export const UpdateProviderDetailsModal: FC<UpdateProviderDetailsModalProps> = props => {
  const { classes } = useStyles()
  const providerName = props?.provider?.name
  const facilityLabel = 'All providers at this location'
  const { isLoading: isCuratedProviderUpdating, mutateAsync: handleEditCuratedProvider } =
    useEditCuratedProvider()

  const providerLabel = 'Only ' + providerName + ' at this location'
  const location =
    props?.provider?.locations && props?.provider?.locations.length >= 1
      ? props?.provider?.locations[0]
      : null

  const careOrgName = props?.provider?.npi ? location?.name : props?.provider?.name
  const [providerLevel, setProviderLevel] = useState<string>(
    props?.isProviderSearch == true ? 'providerAtALocation' : 'allProvidersAtALocation'
  )
  let specialtyGroups: SpecialtyGroup[] | null = props?.specialtyGroups

  const [selectedSpecialtyGroup, setSelectedSpecialtyGroup] = useState<string | null>(
    specialtyGroups ? specialtyGroups[0]?.label : ''
  )
  const hasLocation = Boolean(props?.provider?.locations && props?.provider?.locations?.length >= 1)
  const phoneNumbers = hasLocation ? props?.provider?.locations[0]['phoneNumbers'] : null
  const phoneNumber = phoneNumbers && phoneNumbers?.length > 0 ? phoneNumbers[0]['phone'] : null

  const faxNumbers = hasLocation ? props?.provider?.locations[0]['faxNumbers'] : null
  const fax = faxNumbers && faxNumbers?.length > 0 ? faxNumbers[0]['fax'] : null

  const handleUpdateProviderDetailsChoice = event => {
    setProviderLevel(event?.target?.value)
  }

  // It contains formatted phone with country code
  const [phone, setPhone] = useState<string | undefined>(
    phoneNumber ? formatPhoneNumber(phoneNumber) : ''
  )
  // Contains phone number value
  const [phoneValue, setPhoneValue] = useState<string | null>(phoneNumber ?? null)

  // It contains formatted fax with country code
  const [formattedFax, setFormattedFax] = useState<string | undefined>(
    fax ? formatPhoneNumber(fax) : ''
  )
  // Contains fax number value
  const [faxValue, setFaxValue] = useState<string | null>(fax ?? null)

  const canUpdateSpecialty =
    props.isProviderSearch == true &&
    careOrgName != null &&
    specialtyGroups != null &&
    specialtyGroups.length > 0

  useEffect(() => {
    // for formatting the fax number and handling the value change
    // The formatting of the Fax won't happen in the mui-tel package
    // until we add country code to the Fax number
    // as that is how it find out, which country formatting need to be done
    if (!formattedFax) {
      setFormattedFax('+1 ')
    }

    if (formattedFax && !formattedFax.includes('+1')) {
      setFormattedFax('+1 ' + formattedFax)
    }
  }, [formattedFax])

  useEffect(() => {
    // for formatting the phone number and handling the value change
    // The formatting of the phone won't happen in the mui-tel package
    // until we add country code to the phone number
    // as that is how it find out, which country formatting need to be done
    if (!phone) {
      setPhone('+1 ')
    }

    if (phone && !phone.includes('+1')) {
      setPhone('+1 ' + phone)
    }
  }, [phone])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // As we want to keep the verified checkbox unchecked by default
      // so set the default value of isVerified as false
      isVerified: false,
    },
    onSubmit: async values => {
      let specialtyGroup: SpecialtyGroup | null | undefined
      if (specialtyGroups?.length == 1) {
        specialtyGroup = specialtyGroups ? specialtyGroups[0] : null
      } else {
        specialtyGroup = specialtyGroups?.find(
          specialtyGroup => specialtyGroup?.label == String(selectedSpecialtyGroup)
        )
      }

      // Dual write the contact details in CuratedProviders and Firefly source data
      let curatedProviderPayload: UpdateCuratedProvider = {
        npi: props?.provider?.npi ?? null,
        careOrgName: careOrgName ?? null,
        zipCode: location?.addressDetails?.zip ?? null,
        city: location?.addressDetails?.city ?? null,
        state: location?.addressDetails?.state ?? null,
        addressLine1: location?.addressDetails?.addressLine1 ?? null,
        addressLine2: location?.addressDetails?.addressLine2
          ? location?.addressDetails?.addressLine2
          : null,
        contactInformation: {
          isVerified: values?.isVerified,
          phone: phoneValue,
          fax: faxValue,
          specialtyGroup:
            specialtyGroup?.id && providerLevel != 'providerAtALocation'
              ? specialtyGroup?.id
              : null,
        },
      }
      handleEditCuratedProvider(curatedProviderPayload)
      if (providerLevel == 'providerAtALocation') {
        // Log provider contact details update for a provider at a location
        logEvent<EditProviderAndAvailabilityPayload>(
          'UPDATED_PROVIDER_DETAILS_PROVIDER_AT_A_LOCATION',
          {
            providerNpi: props?.provider?.npi ?? null,
            steerageId: props?.steerageId,
            isEditedInDirectory: true,
            isVerified: values?.isVerified,
            isProviderSearch: true,
          }
        )
      } else if (providerLevel == 'allProvidersInDepartmentAtALocation') {
        // Log provider contact details update for all provider at a department
        logEvent<EditProviderAndAvailabilityPayload>(
          'UPDATED_PROVIDER_DETAILS_ALL_PROVIDER_AT_A_DEPARTMENT',
          {
            careOrganisationName: careOrgName ?? '',
            steerageId: props?.steerageId,
            isEditedInDirectory: true,
            isVerified: values?.isVerified,
            isProviderSearch: props?.provider?.npi ? true : false,
          }
        )
      } else if (providerLevel == 'allProvidersAtALocation') {
        // Log provider contact details update for all provider at a location
        logEvent<EditProviderAndAvailabilityPayload>(
          'UPDATED_PROVIDER_DETAILS_ALL_PROVIDER_AT_A_LOCATION',
          {
            careOrganisationName: careOrgName ?? '',
            steerageId: props?.steerageId,
            isEditedInDirectory: true,
            isVerified: values?.isVerified,
            isProviderSearch: false,
          }
        )
      }
      // close the model
      props.handleEditProviderDetailsClose()
    },
  })

  return (
    <Box>
      {isCuratedProviderUpdating ? <Loader /> : null}

      <Box display="flex" justifyContent="space-between">
        <Typography mr={4} className={classes.modalTitle}>
          Edit Provider
        </Typography>
        <Box mt={-1}>
          <IconButton size="small" onClick={props.handleEditProviderDetailsClose}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
      <Typography className={classes.subTitle} mr={4}>
        {props?.provider?.name}
      </Typography>
      <Box mt={3}>
        <MuiTelInputStyled
          name="phone"
          label="Phone"
          size="small"
          variant="standard"
          value={phone}
          onChange={(value: string, info: MuiTelInputInfo) => {
            setPhone(value)
            setPhoneValue(info.nationalNumber ? info.nationalNumber : null)
          }}
          disableDropdown
          className={classes.textFieldWidth}
          onlyCountries={['US']}
          forceCallingCode
        />
      </Box>
      <Box mt={2}>
        <MuiTelInputStyled
          name="fax"
          label="Fax"
          size="small"
          variant="standard"
          value={formattedFax}
          onChange={(value: string, info: MuiTelInputInfo) => {
            setFormattedFax(value)
            setFaxValue(info.nationalNumber ? info.nationalNumber : null)
          }}
          disableDropdown
          className={classes.textFieldWidth}
          onlyCountries={['US']}
          forceCallingCode
        />
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={<Checkbox name="isVerified" onChange={formik.handleChange} />}
          label="I have verified the phone and fax information"
        />
      </Box>
      <Box mt={3}>
        <FormControl>
          <FormLabel style={{ color: '#071F3E' }}>Edit details for:</FormLabel>

          <RadioGroup
            onChange={handleUpdateProviderDetailsChoice}
            defaultValue={
              props?.isProviderSearch == true ? 'providerAtALocation' : 'allProvidersAtALocation'
            }
          >
            {props?.isProviderSearch == true ? (
              <FormControlLabel
                control={<Radio />}
                onChange={formik.handleChange}
                label={providerLabel}
                value="providerAtALocation"
                name="providerLevel"
              />
            ) : (
              <FormControlLabel
                value="allProvidersAtALocation"
                name="providerLevel"
                onChange={formik.handleChange}
                control={<Radio />}
                label={facilityLabel}
              />
            )}
            {/* The editing at the specialty level should not be visible in the facility search */}
            {canUpdateSpecialty && specialtyGroups ? (
              <FormControlLabel
                control={<Radio />}
                name="providerLevel"
                value="allProvidersInDepartmentAtALocation"
                label={
                  <Grid container direction={'row'} spacing={0.5}>
                    <Grid item>All</Grid>
                    {specialtyGroups.length > 1 ? (
                      <Grid item>
                        <Select
                          variant="standard"
                          name="specialtyGroup"
                          className={classes.selectElementStyle}
                          value={selectedSpecialtyGroup}
                          onChange={e => {
                            setSelectedSpecialtyGroup(e.target.value)
                          }}
                        >
                          {specialtyGroups.map(group => (
                            <MenuItem value={group.label} key={group.id}>
                              {group.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : (
                      <Grid item>{specialtyGroups[0].label}</Grid>
                    )}
                    <Grid item>providers</Grid>
                    <Grid item> at</Grid>
                    <Grid item> this</Grid>
                    <Grid item> location</Grid>
                  </Grid>
                }
              />
            ) : (
              ''
            )}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button className={classes.cancelButton} onClick={props.handleEditProviderDetailsClose}>
          <Typography>Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => formik.handleSubmit()}
          className={classes.saveButton}
        >
          <Typography>Save</Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default UpdateProviderDetailsModal
