import { Autocomplete, TextField, Checkbox as MuiCheckbox, Typography } from '@mui/material'
import {
  CheckBoxOutlineBlank as MuiIconCheckBoxOutlineBlank,
  CheckBox as MuiIconCheckBox,
} from '@mui/icons-material'

import { useEffect, useState } from 'react'

type TypeFilterOption = {
  type: 'Care Plan' | 'Case Category'
  id: number
  label: string
  count: number
}

interface Props {
  data: any
  currentSelections?: SelectedTypeFilters
  onClose: (selectedFilters: SelectedTypeFilters) => void
}

export type SelectedTypeFilters = Pick<TypeFilterOption, 'type' | 'id' | 'label'>[]

const checkboxIcon = <MuiIconCheckBoxOutlineBlank fontSize="small" />
const checkedCheckboxIcon = <MuiIconCheckBox fontSize="small" />

const TodoTypeSelect = ({ data, onClose, currentSelections }: Props) => {
  /**
   * Allow multi-selecting Types
   * Trigger a callback function when selection is complete
   * to avoid multiple API calls during multi-select
   */

  const [typeFilters, setTypeFilters] = useState<SelectedTypeFilters>(currentSelections || [])
  const onSelectionReady = (selected: SelectedTypeFilters) => {
    onClose(selected)
  }

  useEffect(() => {
    // Keep the temporary state here updated with the parent component's selections
    if (currentSelections) setTypeFilters(currentSelections)
  }, [currentSelections])

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple
      disableCloseOnSelect
      filterSelectedOptions
      size="small"
      options={[
        ...(data?.aggregates.caseCategory.map(
          option =>
            ({
              type: 'Case Category',
              id: option.categoryId,
              label: option.categoryTitle,
              count: option.count,
            } as TypeFilterOption)
        ) ?? []),
        ...(data?.aggregates.careplanAutocreatedFrom.map(
          option =>
            ({
              type: 'Care Plan',
              id: option.autocreatedFromId ?? 'None',
              label: option.autocreatedFromTitle ?? 'Other',
              count: option.count,
            } as TypeFilterOption)
        ) ?? []),
      ]}
      groupBy={option => option.type}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.type == value.type && option.id == value.id}
      renderInput={params => <TextField {...params} label={'Type'} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <MuiCheckbox
            icon={checkboxIcon}
            checkedIcon={checkedCheckboxIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}

          <Typography display="inline" color="secondary">
            &nbsp;{option.count}
          </Typography>
        </li>
      )}
      value={typeFilters?.map(filter => ({
        ...filter,
        count: 0, // Doesn't actually matter when rendering the value.
      }))}
      onChange={async (event, value) => {
        setTypeFilters(value)
        if (event.type == 'click' && ['path', 'svg'].includes((event.target as Node).nodeName)) {
          // Click events on a selected option's "x" element
          // Or the entire input's "X" icon to clear all selections
          // can be fired with the input unfocused
          // so we won't get a later blur event, and onClose wouldn't be fired
          onSelectionReady(value)
        }
      }}
      onClose={() => onSelectionReady(typeFilters)}
    />
  )
}

export default TodoTypeSelect
