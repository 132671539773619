import type { FC } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { makeStyles, Theme } from '@material-ui/core/styles'

const FFInput: FC<TextFieldProps> = props => {
  const classes = useStyles()

  const InputProps = {
    classes: {
      root: classes.root,
      input: classes.input,
      multiline: classes.multiline,
      disabled: classes.disabled,
    },
    ...props.InputProps,
  }

  return <TextField {...props} InputProps={InputProps} />
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    paddingTop: '0.75em',
    paddingBottom: '0.75em',
  },
  root: {
    paddingLeft: '1em',
    paddingRight: '1em',
    backgroundColor: theme.palette.blueGrey.lighter,
    transition: '100ms linear opacity',
    '&:before': {
      display: 'none',
    },
    '&:after': {
      borderColor: theme.palette.primary.main,
    },
  },
  multiline: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  disabled: {
    opacity: 0.75,
  },
}))

export default FFInput
