import type { FC } from 'react'
import Moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import Highlighter from 'react-highlight-words'
import Button from '@material-ui/core/Button'

import UserAvatar from '../Generic/UserAvatar'

export interface ChatSearchResult {
  createdAt: Date
  senderId: number
  senderName: string
  sentAt: Date
  text: string
  threadUid: string
  uid: string
}

interface ChatSearchResultSingleProps {
  id: string
  searchQuery: string
  result: ChatSearchResult
  handleResultClick: (key: string) => void
}

export const ChatSearchResultSingle: FC<ChatSearchResultSingleProps> = (
  props: ChatSearchResultSingleProps
) => {
  const { id, searchQuery, result, handleResultClick } = props
  const styles = useStyles()
  const sentDate = Moment(result.sentAt).format('MMM.DD [at] h:mma')
  return (
    <div className={styles.messageResult}>
      <Button className={styles.searchResults} onClick={() => handleResultClick(id)}>
        <div>
          <div className={styles.senderInfo}>
            <UserAvatar id={result.senderId} size="large" />
            <div className={styles.senderInfoText}>
              <div>
                <Highlighter
                  highlightTag="span"
                  highlightClassName={styles.searchQueryHighlight}
                  searchWords={[searchQuery]}
                  textToHighlight={result.senderName}
                />
              </div>
              <div>{sentDate}</div>
            </div>
          </div>
          <Typography className={styles.message}>
            <Highlighter
              highlightTag="span"
              highlightClassName={styles.searchQueryHighlight}
              searchWords={[searchQuery]}
              textToHighlight={result.text}
            />
          </Typography>
        </div>
      </Button>
    </div>
  )
}

const useStyles = makeStyles({
  messageResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1em',
    borderBottom: `0.1rem solid #e0e0e0`,
  },
  senderInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  senderInfoText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  message: {
    display: 'flex',
    margin: '0 1em',
    textAlign: 'left',
  },
  messageAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1em',
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: '#094A7A',
    borderRadius: '100%',
    color: '#fff',
    fontSize: '1em',
  },
  searchQueryHighlight: {
    backgroundColor: '#FFFFBF',
  },
  searchResults: {
    display: 'flex',
    justifyContent: 'left',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    width: '100%',
  },
})

export default ChatSearchResultSingle
