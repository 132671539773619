import { Box, Typography, Grid } from '@material-ui/core'
import EditField from '../../Generic/EditField'
import Alert from '@material-ui/lab/Alert'
import { formatDateWithDay } from '~/utils/date'

interface IProps {
  insuranceInfo: any
}

const FEE_FOR_SERVICE = 'FEE_FOR_SERVICE'

const ATTRIBUTION_TYPE_DESCRIPTIONS = {
  POC_FORM: 'P.O.C.',
  VISITS: 'Visits',
  PHONECALL: 'Phonecall',
  [FEE_FOR_SERVICE]: 'None (Fee For Service)',
}

const ATTRIBUTION_FAILURE_DESCRIPTIONS = {
  UNLICENSED_STATE: 'Patient lives in an unlicensed state.',
  NOT_PPO: 'P.P.O. plan required for this payer and plan.',
  NO_CONTRACT_MATCHES_PAYER_AND_STATE: 'Firefly is not contracted with this payer in this state.',
  INELIGIBLE_PLAN:
    'This insurance plan is marked as not accepted by Firefly. If this is a new plan, it may need to be reviewed.',
  INELIGIBLE_PAYER: 'This insurance payer is marked as not accepted by Firefly.',
  NO_CONTRACT_MATCHING_PAYER: 'Firefly does not have a contract with this payer.',
  NO_OOS_CONTRACT_ALLOWED: 'The contract for this plan does not allow out-of-state patients.',
}

const InsuranceContractInfo = (props: IProps) => {
  const type = props.insuranceInfo?.attributionRequirementType
  const ineligibleReasonType = props.insuranceInfo?.attributionFailureReason
  const contractName = props.insuranceInfo?.contractName

  const typeDescription = ATTRIBUTION_TYPE_DESCRIPTIONS[type] || type || 'No Result'
  const ineligibleReasonDescription =
    ATTRIBUTION_FAILURE_DESCRIPTIONS[ineligibleReasonType] || ineligibleReasonType
  let attributionState = props.insuranceInfo.insuranceAttributionState

  let transitionTimestamp = props.insuranceInfo.insuranceAttributionTransitionAt
  if (transitionTimestamp) {
    transitionTimestamp = formatDateWithDay(transitionTimestamp)
  }

  let timestamp: string | null = null
  if (props.insuranceInfo.insuranceAttributionTransitionAt) {
    timestamp = formatDateWithDay(props.insuranceInfo.insuranceAttributionTransitionAt)
  }

  const isContracted = !!props.insuranceInfo.contractId

  const eligibilityLabel = (() => {
    if (!isContracted) {
      return 'No Contract found'
    }
    if (props.insuranceInfo?.attributionRequirementType === FEE_FOR_SERVICE) {
      return 'FFS Eligible'
    }
    return 'PMPM Eligible'
  })()

  return (
    <Box>
      <Typography variant="h6">Contract Info</Typography>
      {!isContracted && ineligibleReasonDescription && (
        <Box mt={1}>
          <Alert severity="warning">
            <Box>
              <Typography component="span" variant="inherit">
                <strong>{ineligibleReasonDescription}</strong>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">({ineligibleReasonType})</Typography>
            </Box>
          </Alert>
        </Box>
      )}
      <Box mt={1}>
        <Alert severity={isContracted ? 'success' : 'warning'}>
          <Box display="block">
            <Typography component="span" variant="inherit">
              <strong>{eligibilityLabel}</strong>
            </Typography>
            {timestamp && (
              <Typography component="span" variant="inherit">
                &nbsp;- Changed {timestamp}
              </Typography>
            )}
          </Box>
        </Alert>
        <Box mt={3}>
          <Grid container spacing={2}>
            <EditField
              value={contractName ?? ' '}
              label="Contract Name"
              disabled={true}
              margin="none"
            />
            <EditField
              value={typeDescription ?? ' '}
              label="Attribution Requirement"
              disabled={true}
              margin="none"
            />
            <EditField
              value={attributionState ?? ' '}
              label="Attribution State"
              disabled={true}
              margin="none"
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default InsuranceContractInfo
