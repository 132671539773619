import { Box, Button, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import FFModal from '~/components/FFModal/FFModal'
import { removeTasks } from '~/redux/actions/tasks'
import CreateFromTemplateForm from './CreateFromTemplateForm'
import EditOrCustomCreateActions from './EditOrCustomCreateActions'
import CreateFromTemplateActions from './CreateFromTemplateActions'
import EditOrCustomCreateForm from './EditOrCustomCreateForm'
import CreateModeSelector from './CreateModeSelector'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useCarePlanModal, CREATE_MODES } from './useCarePlanModal'

interface ICarePlansModalProps {
  handleModalAction: (carePlan: any) => any
  carePlan: any
  me: any
  open: boolean
  closeModal: () => void
  patient: any
  existingCarePlanTypeIds?: { [key: number]: boolean }
}

const CarePlansModal = (props: ICarePlansModalProps) => {
  const {
    resetToDefaultState,
    loadCarePlan,
    resetTemplateWorkflow,
    setCreateMode,
    saving,
    setSaving,
    setSelectedTemplate,
    createMode,
    selectedTemplate,
    title,
    notes,
    setTitle,
    setNotes,
    tasks,
    setTasks,
    selectTemplate,
    previewData,
    previewDataLoading,
    previewDataError,
    carePlanTemplates,
    loadCarePlanTemplates,
    carePlanTemplatesLoading,
    readyToSaveFromTemplateId,
    allTemplateTasksComplete,
    setAllTemplateTasksComplete,
  } = useCarePlanModal(props.me)
  const dispatch = useDispatch()
  const classes = useStyles()
  const creatingFromTemplate = !props.carePlan && createMode === CREATE_MODES.TEMPLATE
  const modalTitle = props.carePlan ? 'Edit Care Plan' : 'New Care Plan'

  useEffect(() => {
    if (props.open) {
      resetToDefaultState()
      if (props.carePlan) {
        loadCarePlan(props.carePlan)
      }
    }
  }, [props.open, props.carePlan])

  if (!props.open) return <></>

  const handleCreateModeSelection = (mode: string) => {
    if (mode !== CREATE_MODES.TEMPLATE) {
      resetTemplateWorkflow()
    }
    setCreateMode(mode)
  }

  const closeModal = () => {
    resetToDefaultState()
    props.closeModal()
  }

  const dispatchRemoveTasks = tasks => dispatch(removeTasks(tasks))

  const formContents = (): JSX.Element => {
    if (creatingFromTemplate && props.existingCarePlanTypeIds) {
      return (
        <CreateFromTemplateForm
          patient={props.patient}
          me={props.me}
          tasks={tasks}
          setTasks={setTasks}
          saving={saving}
          title={title}
          setTitle={setTitle}
          setNotes={setNotes}
          notes={notes}
          selectTemplate={selectTemplate}
          selectedTemplate={selectedTemplate}
          previewData={previewData}
          previewDataLoading={previewDataLoading}
          previewDataError={previewDataError}
          carePlanTemplates={carePlanTemplates}
          loadCarePlanTemplates={loadCarePlanTemplates}
          carePlanTemplatesLoading={carePlanTemplatesLoading}
          setAllTemplateTasksComplete={setAllTemplateTasksComplete}
          existingCarePlanTypeIds={props.existingCarePlanTypeIds}
        />
      )
    }

    return (
      <EditOrCustomCreateForm
        patient={props.patient}
        me={props.me}
        tasks={tasks}
        setTasks={setTasks}
        saving={saving}
        title={title}
        notes={notes}
        setTitle={setTitle}
        setNotes={setNotes}
      />
    )
  }

  return (
    <FFModal
      fixedHeight={true}
      open={true}
      keepMounted={false}
      header={
        <>
          {selectedTemplate && (
            <Box m={1} mb={-1}>
              <Button onClick={() => setSelectedTemplate(null)}>
                <ArrowBackIcon />
                Back to template selection
              </Button>
            </Box>
          )}
          <Box m={2}>
            <Typography variant="h5">{modalTitle}</Typography>
          </Box>
        </>
      }
      footer={
        creatingFromTemplate ? (
          <CreateFromTemplateActions
            closeModal={closeModal}
            patient={props.patient}
            handleModalAction={props.handleModalAction}
            me={props.me}
            tasks={tasks}
            readyToSaveFromTemplateId={readyToSaveFromTemplateId}
            saving={saving}
            setSaving={setSaving}
            allTemplateTasksComplete={allTemplateTasksComplete}
            title={title}
            notes={notes}
            previewData={previewData}
          />
        ) : (
          <EditOrCustomCreateActions
            carePlan={props.carePlan}
            patient={props.patient}
            closeModal={closeModal}
            handleModalAction={props.handleModalAction}
            dispatchRemoveTasks={dispatchRemoveTasks}
            me={props.me}
            tasks={tasks}
            saving={saving}
            setSaving={setSaving}
            title={title}
            notes={notes}
          />
        )
      }
    >
      <Box m={2}>
        <IconButton disabled={saving} className={classes.close} onClick={props.closeModal}>
          <CloseIcon />
        </IconButton>
        <CreateModeSelector
          carePlan={props.carePlan}
          createMode={createMode}
          selectedTemplate={selectedTemplate}
          me={props.me}
          handleCreateModeSelection={handleCreateModeSelection}
        />
        <Box mt={2}>{formContents()}</Box>
      </Box>
    </FFModal>
  )
}

const useStyles = makeStyles(theme => ({
  close: {
    position: 'absolute',
    zIndex: 100,
    top: theme.spacing(),
    right: theme.spacing(),
  },
}))

export default CarePlansModal
