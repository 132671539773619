import type { FC } from 'react'
import { ThemeProvider as Mui5ThemeProvider } from '@mui/material/styles'
import { MuiThemeProvider as Mui4ThemeProvider } from '@material-ui/core/styles'

import theme from '~/utils/theme'
import { mui5theme } from './mui5theme'

/**
 * This component exists to port MUI4 theme values and overrides to MUI5
 * and make those values available to @mui/* components as we migrate the
 * codebase. There is duplication in mui5theme, and further additions to
 * overrides should be carefully implemented in both versions of the theme
 * so as to ensure backward compatibility as we migrate.
 *
 * When migration is completed, the '@material-ui/core/styles' should be
 * removed and only the Mui5ThemeProvider should remain.
 * TODO: Remove legacy theme provider when no longer needed
 */
export const ThemeProvider: FC = ({ children }) => (
  <Mui5ThemeProvider theme={mui5theme}>
    <Mui4ThemeProvider theme={theme}>{children}</Mui4ThemeProvider>
  </Mui5ThemeProvider>
)
