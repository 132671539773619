import React, { useEffect, useState } from 'react'

import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import { useMutation } from '~/utils/useMutation'
import * as ProviderService from '~/api/ProviderService'
import Loader from '../Loader'
import { listEventsForProvider } from '~/api/ScheduleService'
import { useLocalStorage } from './LocalStorageProvider'
import { useLocation } from 'react-router'
import { useQueryParam } from '~/screens/Inbox'
import { Switch } from '@mui/material'
import { useLocalStorageBackedToggle } from '~/utils/useLocalStorageBackedToggle'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import { makeStyles } from 'tss-react/mui'
export interface Props {}

const ProviderScheduleFullCalendar: React.FC<Props> = () => {
  const [isFullCalendarViewEnabled, setIsFullCalendarViewEnabled] = useState<boolean>(true)

  const [_, setIFrameRef] = useState<HTMLIFrameElement | null>(null)

  const [physicianFilter, setPhysicianFilter] = useState({})

  const [selectedPhysician, setSelectedPhysician] = useQueryParam<string | undefined>(
    'physician',
    undefined
  )
  const [__, setScheduleParams] = useLocalStorage('scheduleParams')
  const [calendarToLoad, setCalendarToLoad] = useState('')
  const [isTimezoneOverrideEnabled, setIsTimezoneOverrideEnabled] = useLocalStorageBackedToggle(
    'isTimezoneOverrideEnabled'
  )

  const [showLoader, setShowLoader] = useState(false)

  const location = useLocation()
  useEffect(() => {
    setScheduleParams(location.search)
    if (selectedPhysician && Object.keys(physicianFilter).length > 0) {
      onPhysicianChange(selectedPhysician)
    }
  }, [
    setScheduleParams,
    location.search,
    selectedPhysician,
    physicianFilter,
    isTimezoneOverrideEnabled,
  ])

  const { loading: loadingPhysicians, handler: loadPhysicians } = useMutation(async () => {
    const result = await ProviderService.getPhysicians()
    const data = result.filter(physician => physician.calendarUrl !== null)
    const physicianData: Record<string, ProviderService.Physician> = {}
    data.map(physician => {
      const physicianName = physician.firstName + ' ' + physician.lastName
      physicianData[physicianName] = physician
    })
    setPhysicianFilter(physicianData)
    return result
  })

  const onPhysicianChange = (selected: string) => {
    setShowLoader(true)
    if (selected && physicianFilter[selected]) {
      setSelectedPhysician(selected)
      let calendar = `https://calendar.google.com/calendar/embed?src=${physicianFilter[selected].calendarUrl}&mode=WEEK`
      // if override is not overridden by the user - default to the user timezone
      // else default to the NY timezone
      if (!isTimezoneOverrideEnabled) {
        calendar = `${calendar}&ctz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
      } else {
        calendar = `${calendar}&ctz=America%2FNew_York`
      }
      setCalendarToLoad(calendar)
    } else {
      setSelectedPhysician('')
      setCalendarToLoad('')
    }
    setShowLoader(false)
  }

  const onToggle = event => {
    setIsTimezoneOverrideEnabled(event.target.checked)
  }

  const onToggleFullCalendar = event => {
    setIsFullCalendarViewEnabled(event.target.checked)
  }

  useEffect(() => {
    loadPhysicians()
  }, [])

  const { classes } = useStyles()

  return (
    <>
      {(loadingPhysicians || showLoader) && <Loader />}
      <Box sx={{ display: 'flex', padding: 2 }}>
        <Box m={2} sx={{ flexGrow: 1 }}>
          <Autocomplete<string>
            size="small"
            options={Object.keys(physicianFilter).sort()}
            value={selectedPhysician}
            renderInput={params => <TextField {...params} label="Choose Provider" />}
            onChange={(_, selected) => {
              onPhysicianChange(selected || '')
            }}
            style={{ width: 280 }}
          />
        </Box>
        <Box m={2}>
          <Switch
            checked={isFullCalendarViewEnabled}
            onChange={onToggleFullCalendar}
            name="isFullCalendarViewEnabled"
          />
          View Calendar V2
        </Box>
        <Box m={2}>
          <Switch
            checked={isTimezoneOverrideEnabled}
            onChange={onToggle}
            name="isTimezoneOverrideEnabledSwitch"
          />
          View in ET
        </Box>
      </Box>
      {!loadingPhysicians && selectedPhysician == '' && (
        <Box ml={2}>
          <Typography>Please choose provider to display the appointment details</Typography>
        </Box>
      )}
      {!isFullCalendarViewEnabled && (
        <Box height="80vh" ml={1} mr={1}>
          <iframe
            ref={setIFrameRef}
            title="Appointment Calendar"
            id="appointment-calendar"
            width="100%"
            height="100%"
            style={{ padding: 10, border: 0 }}
            src={calendarToLoad}
            onLoad={() => setShowLoader(false)}
          />
        </Box>
      )}
      {isFullCalendarViewEnabled &&
        selectedPhysician &&
        physicianFilter[selectedPhysician] &&
        physicianFilter[selectedPhysician].provider &&
        physicianFilter[selectedPhysician].provider.pk && (
          <Box height="80vh" ml={1} mr={1}>
            <FullCalendar
              timeZone={!isTimezoneOverrideEnabled ? 'local' : 'America/New_York'}
              height={'100%'}
              initialView="timeGridWeek"
              defaultRangeSeparator="-"
              weekends={false}
              navLinks={true}
              nowIndicator={true}
              headerToolbar={{
                left: 'prev,next,today',
                center: 'title',
                right: 'timeGridDay,timeGridWeek,dayGridMonth',
              }}
              plugins={[timeGridPlugin, momentTimezonePlugin, dayGridPlugin]}
              eventClassNames={arg => {
                return classes[arg.event.title.replace(/\s/g, '')]
              }}
              events={async function (info, successCallback, _failureCallback) {
                // uses start and end from the calendar to fetch events from the backend
                successCallback(
                  await listEventsForProvider(
                    physicianFilter[selectedPhysician].provider.pk,
                    info.start,
                    info.end
                  )
                )
              }}
              eventClick={function (info) {
                // don't let the browser navigate
                // so that we can open the appointments tab in a new URL
                info.jsEvent.preventDefault()
                if (info.event.url) {
                  window.open(info.event.url)
                }
              }}
            />
          </Box>
        )}
    </>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    BHVisit: {
      borderColor: '#FFD714 !important',
      backgroundColor: '#FFFACF !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
    HGVisit: {
      borderColor: '#FFD714 !important',
      backgroundColor: '#FFFACF !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
    Established: {
      borderColor: '#FF6334 !important',
      backgroundColor: '#FFCEBF !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
    EstAWV: {
      borderColor: '#DC70D3 !important',
      backgroundColor: '#F7D7F4 !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
    NewPatient: {
      borderColor: '#469C5D !important',
      backgroundColor: '#A2D0BB !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
    NewAWV: {
      borderColor: '#FFD714 !important',
      backgroundColor: '#FFFACF !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
    Focused: {
      borderColor: '#5F67C0 !important',
      backgroundColor: '#CBD4FE !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
        lineHeight: 1,
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
        lineHeight: 1,
      },
    },
    VisitShift: {
      borderColor: '#8092E7 !important',
      backgroundColor: '#D8E7FF !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
    OOO: {
      borderColor: '#898D8B !important',
      backgroundColor: '#E6E8E7 !important',
      '& .fc-event-main': {
        color: '#071F3E !important',
      },
      '&.fc-event-past': {
        opacity: 0.5,
      },
      '&.fc-timegrid-event-short .fc-event-time': {
        '&:after': {
          content: '", "',
          whiteSpace: 'pre',
        },
      },
      '& .fc-event-time': {
        fontSize: '1rem',
        fontWeight: '700',
      },
      '& .fc-event-title.fc-sticky': {
        fontWeight: '600',
        fontSize: '1rem',
      },
    },
  }
})

export default ProviderScheduleFullCalendar
