import MessageBucketSelect, { Props as MessageBucketSelectProps } from './MessageBucketSelect'
import PodFilterSelect from './PodFilterSelect'
import { Props as PodFilterSelectProps } from './PodFilterSelect'
import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'

interface MessagesSidebarProps extends PodFilterSelectProps, MessageBucketSelectProps {}

const MessagesSidebar: React.FC<MessagesSidebarProps> = props => {
  // A temporary state which allows to user to select multiple Care Pods
  // before hitting "Apply" and triggering effects in the parent component
  const [tempSelectedPodIdsMap, setTempSelectedPodIdsMap] = useState<{
    [id: number]: boolean
  }>({})

  useEffect(() => {
    setTempSelectedPodIdsMap(props.selectedPodIdsMap)
  }, [props.selectedPodIdsMap])

  const handleApply = () => {
    props.setSelectedPodIdsMap(tempSelectedPodIdsMap)
  }

  const hasChangesToApply = () => {
    return (
      Object.keys(tempSelectedPodIdsMap).find(
        x => !!tempSelectedPodIdsMap[x] != !!props.selectedPodIdsMap?.[x]
      ) != undefined
    )
  }

  return (
    <Box display="flex" sx={{ flexFlow: 'column' }} height="100%" px={2}>
      <Typography variant="h6">Messages</Typography>
      <Box mt={2} flex="0 1 auto">
        <MessageBucketSelect
          activeFilter={props.activeFilter}
          setActiveFilter={props.setActiveFilter}
        />
      </Box>
      <Box mt={2} flex="1 1 auto">
        <Typography variant="subtitle2">Care Pod Filters</Typography>
        <Box mt={1}>
          <PodFilterSelect
            selectedPodIdsMap={tempSelectedPodIdsMap}
            setSelectedPodIdsMap={setTempSelectedPodIdsMap}
          />
        </Box>
      </Box>
      <Box flex="0 1 auto" mb={2}>
        <Box mb={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleApply}
            disabled={!hasChangesToApply()}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default MessagesSidebar
