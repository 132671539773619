import { queueNotification } from '~/redux/actions/notifications'
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ICase } from '~/models/Case'
import { TextField } from '@mui/material'
import { useUpdateCase } from '~/api/CaseService'
import { makeStyles } from 'tss-react/mui'
import { useUnsavedChanges } from '~/utils/useUnsavedChanges'

const useStyles = makeStyles()(theme => {
  return {
    textareaBox: {
      '&::placeholder': {
        color: 'black',
        fontWeight: 500,
      },
    },
  }
})

const InlineEditableField: FC<{
  defaultValue?: string
  selectedCase: number
  valueToBeUpdated: string
  placeholder: string
  isClosed: boolean
  focus?: Dispatch<SetStateAction<HTMLDivElement | null>>
  showError?: boolean
  setEditingCase: Function
}> = props => {
  const {
    defaultValue,
    selectedCase,
    valueToBeUpdated,
    placeholder,
    isClosed,
    focus,
    showError,
    setEditingCase,
  } = props

  const { mutateAsync: handleUpdatePatientCase } = useUpdateCase()
  const dispatch = useDispatch()

  const { classes } = useStyles()

  const [descriptionError, setDescriptionError] = useState<boolean>(false)

  const [despValue, setDespValue] = useState<string>(defaultValue || '')

  const { trackUnsavedChange, clearUnsavedChange } = useUnsavedChanges()

  useEffect(() => {
    if (showError == false) setDescriptionError(showError)
    setDespValue(defaultValue || '')
  }, [setDescriptionError, showError, defaultValue])

  const handleOnAfterEditCase = (pc: ICase) => {
    if (!pc) {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Case Updation Failed',
        })
      )
    }
  }

  const updatePatientCase = async e => {
    if (e.target.value == '' && valueToBeUpdated == 'description') {
      setDescriptionError(true)
    }
    if (e.target.value !== '' && defaultValue !== e.target.value) {
      setEditingCase(true)
      setDescriptionError(false)
      const updatedCase = await handleUpdatePatientCase({
        id: selectedCase,
        [valueToBeUpdated]: e.target.value,
      })
      clearUnsavedChange()

      handleOnAfterEditCase(updatedCase)
      setEditingCase(false)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDespValue(event.target.value)
    trackUnsavedChange()
  }

  return (
    <>
      <TextField
        hiddenLabel
        inputRef={focus}
        disabled={isClosed}
        placeholder={placeholder}
        value={despValue}
        defaultValue={defaultValue}
        error={descriptionError}
        helperText={descriptionError && 'Description required'}
        multiline
        onChange={handleChange}
        onClick={e => {
          e.stopPropagation()
        }}
        onBlur={updatePatientCase}
        InputProps={{ classes: { input: classes.textareaBox } }}
        sx={{
          marginLeft: '1.6rem',
          wordBreak: 'break-word',
          fontSize: '1.4rem',
          width: 'auto',
          '& .MuiFormHelperText-root.Mui-error': {
            marginLeft: 0,
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            padding: 0,
          },
          '& textarea': {
            padding: 0,
            paddingLeft: 1,
            paddingTop: 0.2,
            paddingBottom: 0.2,
            fontSize: '1.4rem',
            color: 'grey.500',
          },
          '& fieldset': {
            border: descriptionError ? '1px solid red' : 'none',
          },
          '& textarea:focus': {
            border: '1px solid black',
            borderColor: 'grey.500',
            borderRadius: 1,
          },
        }}
      />
    </>
  )
}

export default InlineEditableField
