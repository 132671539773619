import { Box, Link, Tooltip } from '@mui/material'
import { GREY } from '~/utils/theme'
import FFElation from '../Generic/FFElation'
import { makeStyles } from 'tss-react/mui'

interface Props {
  elationUrl: string | null // provided by back-end, null if patient is missing an Elation ID
}

const useStyles = makeStyles()(theme => {
  return {
    svg: {
      height: '2.5rem',
    },
  }
})

const ElationIcon: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { classes } = useStyles()
  const tooltipTitle = disabled ? 'Patient is not yet linked to Elation' : 'Open in Elation'
  const svgProps = disabled ? { fill: GREY[500] } : {}

  return (
    <Tooltip title={tooltipTitle}>
      <Box>
        <FFElation className={classes.svg} {...svgProps} />
      </Box>
    </Tooltip>
  )
}

const ElationLink: React.FC<Props> = props => {
  if (props.elationUrl) {
    return (
      <Link href={props.elationUrl} target="_blank" rel="noopener noreferrer">
        <ElationIcon />
      </Link>
    )
  }
  return <ElationIcon disabled={true} />
}

export default ElationLink
