import { format, isToday, isThisYear, isYesterday } from 'date-fns'
import Moment from 'moment-timezone'

export const formatDateWithoutDay = (date: Date) => {
  return format(new Date(date), 'MMM D, YYYY [at] h:mm A')
}

export const formatDateWithDay = (date: Date) => {
  return format(new Date(date), 'ddd. MMM D, YYYY [at] h:mm A')
}

export const formatReadAt = (date: Date) => {
  if (isToday(date)) {
    return `Today at ${format(date, 'h:mm A')}`
  } else if (isYesterday(date)) {
    return `Yesterday at ${format(date, 'h:mm A')}`
  } else if (isThisYear(date)) {
    return format(date, 'ddd, MMM D')
  } else {
    return format(date, 'ddd, MMM D, YYYY')
  }
}

/**
 * Returns the given date formatted in the RFC 3339 "full-date" format.
 * See https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
 * @param date Date to format
 * @returns string
 */
export const formatRFC3339FullDate = (date: Date) => {
  return format(new Date(date), 'YYYY-MM-DD')
}

/**
 * Returns the given date formatted in the RFC 3339 "date-time" format.
 * See https://datatracker.ietf.org/doc/html/rfc3339#section-5.6
 */
export const formatRFC3339DateTime = (date: Date) => {
  return format(new Date(date), 'YYYY-MM-DDTHH:mm:ssZ')
}

/**
 * Set due date time to 7PM EST(EOD) if due date feature flag on
 */
export const setDueDateToEOD = (date: string | Date) => {
  return Moment.tz(Moment(date).format('YYYY-MM-DD'), 'US/Eastern').add(19, 'hours').format()
}
