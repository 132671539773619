import { FormControl, ListSubheader, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { FC } from 'react'

export interface Props {
  activeFilter: string | null
  setActiveFilter: (filter: string | null) => void
}

interface MessageBucket {
  name: string
  value: string // Maps to backend logic
  showCount?: boolean
}

interface BucketGroup {
  groupName: string
  buckets: MessageBucket[]
}

// An ordered list of available filters
// Organized into groups
const GroupedBuckets: BucketGroup[] = [
  {
    groupName: 'Message status',
    buckets: [
      {
        name: 'Clinical',
        value: 'clinical',
      },
      {
        name: 'Operational',
        value: 'operational',
      },
    ],
  },
  {
    groupName: 'Message type',
    buckets: [
      {
        name: 'Unread',
        value: 'unread',
      },
    ],
  },
]

const renderSelectGroup = (group: BucketGroup, key) => {
  const bucketMenuItems = group.buckets.map(bucket => {
    return (
      <MenuItem key={bucket.value} value={bucket.value}>
        {bucket.name}
      </MenuItem>
    )
  })
  return [<ListSubheader key={key}>{group.groupName}</ListSubheader>, bucketMenuItems]
}

const MessageBucketSelect: FC<Props> = props => {
  const { activeFilter, setActiveFilter } = props

  const handleChange = (event: SelectChangeEvent) => {
    const filterValue = event.target.value
    setActiveFilter(filterValue)
  }

  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        size="small"
        value={activeFilter || ''}
        onChange={handleChange}
        defaultValue=""
      >
        <MenuItem value={''}>All Messages</MenuItem>
        {GroupedBuckets.map((bucketGroup, i) => renderSelectGroup(bucketGroup, i))}
      </Select>
    </FormControl>
  )
}

export default MessageBucketSelect
