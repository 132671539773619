import Moment from 'moment'

export function asDate(inputDate) {
  const value = Moment(inputDate).format('MMM. D, YYYY')
  if (value.match(/invalid/i)) {
    return inputDate
  } else {
    return value
  }
}

interface Name {
  firstName: string | null
  middleName?: string | null
  lastName: string | null
}

export function fullName(name: Name): string {
  return [name.firstName, name.middleName, name.lastName].filter(part => !!part).join(' ')
}
