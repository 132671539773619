import { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Modal, Paper } from '@mui/material'
import Draggable, { DraggableBounds } from 'react-draggable'

import { SessionHelp } from './SessionHelp'

const MULTIPLIER = 0.8
const getHeight = () => Math.round(window.innerHeight * MULTIPLIER)
const getWidth = () => Math.round(getHeight() * MULTIPLIER)
const getBounds = (modalHeight: number, modalWidth: number): DraggableBounds => ({
  left: -((window.innerWidth - modalWidth) / 2),
  top: -((window.innerHeight - modalHeight) / 2),
  right: (window.innerWidth - modalWidth) / 2,
  bottom: (window.innerHeight - modalHeight) / 2,
})

export const SessionHelpModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [height, setHeight] = useState<number>(getHeight())
  const [width, setWidth] = useState<number>(getWidth())
  const [bounds, setBounds] = useState<DraggableBounds>(getBounds(height, width))

  const handleResize = debounce(
    () => {
      setHeight(getHeight())
      setWidth(getWidth())
    },
    250,
    { trailing: true }
  )
  // Change modal bounds when window size changes
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    setBounds(getBounds(height, width))
  }, [height, width])

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      hideBackdrop
      open
      onClose={handleClose}
      sx={{ pointerEvents: 'none' }}
    >
      <div>
        <Draggable
          bounds={bounds}
          positionOffset={{
            x: '-50%',
            y: '-50%',
          }}
        >
          <Paper
            elevation={10}
            sx={{
              height,
              width,
              pointerEvents: 'all',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              overflow: 'hidden',
            }}
          >
            <SessionHelp onClose={handleClose} />
          </Paper>
        </Draggable>
      </div>
    </Modal>
  )
}
