import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { batch } from 'react-redux'
import worklistsSlice, {
  getWorklists,
  useTypedWorklistSelector,
  useWorklistDispatch,
  useWorklistSelector,
} from '~/redux/slices/worklists'
import worklistPreferencesSlice from '~/redux/slices/worklistpreferences'
import { WorklistCategory } from './WorklistTree/WorklistTreeCategory'
import groupBy from 'lodash/fp/groupBy'
import { SESSION_WORKLIST_PREFERENCES, setPreferences } from '~/utils/preference'
interface WorklistSidebarProps {
  worklistId: number | undefined
  setWorklistId: (number) => void
}

export const WorklistSidebar: FC<WorklistSidebarProps> = (props: WorklistSidebarProps) => {
  const worklists = useWorklistSelector(getWorklists)
  const worklistPreferences = useTypedWorklistSelector(state => state.worklistPreferences)
  const [worklistsLoaded, setWorklistsLoaded] = useState<boolean>(false)
  const [expandingNodes, setExpandingNodes] = useState<string[]>([])
  const worklistDispatch = useWorklistDispatch()
  useEffect(() => {
    if (worklists && Object.values(worklists).length > 0) {
      const activeWorklists = Object.values(worklists || {}).filter(worklist => worklist.active)
      const hasActiveLastViewedWorklistId =
        worklistPreferences.lastViewedWorklistId &&
        activeWorklists.find(worklist => worklist.id === worklistPreferences.lastViewedWorklistId)
      if (!props.worklistId && hasActiveLastViewedWorklistId) {
        // Auto select the last viewed worklist
        props.setWorklistId(worklistPreferences.lastViewedWorklistId)
      } else if (!props.worklistId && activeWorklists.length > 0) {
        // Auto select the first active worklist
        props.setWorklistId(Object.values(worklists)[0].id)
      }
    } else if (!worklistsLoaded) {
      setWorklistsLoaded(true)
      worklistDispatch(worklistsSlice.thunks.getAll())
    }
  }, [Object.values(worklists || {}).length])

  // Used for auto saving worklist preferences
  useEffect(() => {
    setPreferences(JSON.stringify(worklistPreferences), SESSION_WORKLIST_PREFERENCES)
  }, [worklistPreferences])

  useEffect(() => {
    batch(() => {
      if (props.worklistId) {
        // when a worklist is selected load its details, fields and items
        worklistDispatch(worklistsSlice.thunks.get(props.worklistId))
        worklistDispatch(worklistPreferencesSlice.actions.setLastViewedWorklistId(props.worklistId))
      }
    })
    const selectedWorklist = Object.values(worklists || {}).find(
      worklist => worklist.id === props.worklistId
    )
    if (selectedWorklist) {
      setExpandingNodes([
        ...expandingNodes,
        ...[selectedWorklist.category + ' ' + selectedWorklist.subCategory],
      ])
    }
  }, [props.worklistId, Object.values(worklists || {}).length])

  const categories = groupBy('category', worklists || [])
  return (
    <>
      {Object.keys(categories)
        .sort()
        .map(category => (
          <WorklistCategory
            key={category}
            category={category}
            categoryItems={categories[category]}
            expandingNodes={expandingNodes}
            setExpandingNodes={setExpandingNodes}
            selectWorklist={props.setWorklistId}
            worklistId={props.worklistId}
          ></WorklistCategory>
        ))}
    </>
  )
}
export default WorklistSidebar
