import { useState, Fragment } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Tooltip, Box } from '@mui/material'

function arrowGenerator(color) {
  return {
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
  }
}

const useStylesBootstrap = makeStyles()(theme => {
  return {
    arrow: {
      position: 'absolute',
      fontSize: 6,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
    popper: arrowGenerator(theme.palette.common.black),
    tooltip: {
      position: 'relative',
      backgroundColor: theme.palette.common.black,
      color: 'white',
    },
    tooltipPlacementLeft: {
      margin: '0 8px',
    },
    tooltipPlacementRight: {
      margin: '0 8px',
    },
    tooltipPlacementTop: {
      margin: '8px 0',
    },
    tooltipPlacementBottom: {
      margin: '8px 0',
    },
  }
})

const ArrowTooltip = props => {
  const { arrow, ...classes } = useStylesBootstrap()
  const [arrowRef, setArrowRef] = useState(null)

  return (
    <Tooltip
      classes={classes}
      placement="top"
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef,
            },
          },
        },
      }}
      {...props}
      title={
        props.title !== '' ? (
          <Fragment>
            {Array.isArray(props.title)
              ? props.title.map((element, index) => (
                  <Box component="div" key={index}>
                    {element}
                  </Box>
                ))
              : props.title}
            <span className={arrow} ref={setArrowRef} />
          </Fragment>
        ) : (
          ''
        )
      }
      enterDelay={500}
    />
  )
}

export default ArrowTooltip
