import { MouseEvent } from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ControlPosition } from 'react-draggable'

export const EDUCATION_MODAL_WIDTH = 400

export interface EducationSliceState {
  showModal: boolean
  inFocus: {
    type: 'patient' | 'worklist' | 'case' | undefined
    id: number | null
  }
  // If the modal is opened with a click event, pass coordinates
  // so that its starting position matches
  origin: ControlPosition | null
}

const initialState: EducationSliceState = {
  showModal: false,
  inFocus: {
    type: undefined,
    id: null,
  },
  origin: null,
}

const getFocusedId = state => state.education.inFocus.id
const getFocusedType = state => state.education.inFocus.type

const slice = createSlice({
  name: 'education',
  initialState,
  reducers: {
    open: (state, { payload }: PayloadAction<MouseEvent | undefined>) => {
      state.showModal = true
      if (payload) {
        state.origin = {
          x: payload.clientX - EDUCATION_MODAL_WIDTH,
          y: payload.clientY,
        }
      }
    },
    close: state => {
      state.showModal = false
      state.origin = null
    },
    focusPatient: (state, { payload }: PayloadAction<number>) => {
      // There are multiple patient related components trying to focus on patientId, when the components are loaded.
      // In few scenarios, we are running into a race condition where we are randomly focusing on either patientId/caseId.
      // Focusing on patientId multiple times from different components is overriding the caseId focus, though we are selecting a case.
      // So handling this scenario with the below if-condition, to retain focus on 'case' if it's already focused.
      // Note: Focus on caseId will be blurred once we switch the tab, hence wouldn't be a blocker to focus on patientId again
      if (state.inFocus.type != 'case') {
        state.inFocus.type = 'patient'
        state.inFocus.id = payload
      }
    },
    blurPatient: state => {
      state.inFocus.type = undefined
      state.inFocus.id = null
    },
    focusWorklist: (state, { payload }: PayloadAction<number>) => {
      state.inFocus.type = 'worklist'
      state.inFocus.id = payload
    },
    blurWorklist: state => {
      state.inFocus.type = undefined
      state.inFocus.id = null
    },
    focusCase: (state, { payload }: PayloadAction<number>) => {
      state.inFocus.type = 'case'
      state.inFocus.id = payload
    },
    blurCase: state => {
      state.inFocus.type = undefined
      state.inFocus.id = null
    },
  },
})

export const educationSlice = Object.assign({}, slice, {
  selectors: { getFocusedId, getFocusedType },
})
