import lodash from 'lodash'

import { lazy, Component, Suspense } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'

const ReactJson = lazy(() => import('react-json-view'))

// Strip arrays and nested objects to keep renderable information
const filterProps = obj => {
  try {
    return (
      Object.keys(obj)
        // Remove fields that we do not want to show
        .filter(key => !['updatedAt', 'updatedBy', 'createdAt', 'createdBy'].includes(key))
        .sort()
        .reduce((prev, next) => {
          if (typeof obj[next] !== 'object' || obj[next] === null) prev[next] = obj[next]
          return prev
        }, {})
    )
  } catch (e) {
    return {
      ERROR: 'Problem rendering patient meta. Likely object does not exist.',
    }
  }
}

export class PatientDetailMeta extends Component {
  renderPatientData = obj => {
    try {
      const { classes } = this.props
      const filteredObject = filterProps(obj)
      return Object.keys(filteredObject).map(key => (
        <div key={key} className={classes.row}>
          <Typography className={`${classes.rowKey} ${classes.rowText}`}>{`${key}:`}</Typography>
          <Typography className={classes.rowText}>{JSON.stringify(filteredObject[key])}</Typography>
        </div>
      ))
    } catch (e) {
      // continue regardless of error
    }
  }

  renderPatientDeviceData = () => {
    const { classes, patient } = this.props
    let patientDeviceData
    if (patient.userdevices != null && patient.userdevices.length > 0) {
      const userDevices = lodash.orderBy(patient.userdevices, ['updatedAt'], 'desc')

      patientDeviceData = userDevices.map(device => (
        <div key={device.deviceUniqueId}>
          <Typography className={classes.sectionTitle}>Device: {device.deviceType}</Typography>
          {this.renderPatientData(device)}
          {this.renderPatientData(device.permissions ?? {})}
        </div>
      ))
    }
    return patientDeviceData
  }

  render() {
    const { classes, hidden, patient } = this.props
    return (
      <div className={`${classes.container} ${hidden ? classes.containerHidden : ''}`}>
        <Typography className={classes.title}>Patient Environment Data</Typography>
        <Suspense fallback={null}>
          <Box mt={2}>
            <ReactJson
              name="user"
              collapsed
              displayDataTypes={false}
              src={patient ?? {}}
              onEdit={false}
              onAdd={false}
              onDelete={false}
            />
          </Box>
        </Suspense>
        <Typography className={classes.sectionTitle}>Environment Info</Typography>
        {this.renderPatientDeviceData()}
        <Typography className={classes.sectionTitle}>Patient Info</Typography>
        {this.renderPatientData({ ...patient.person, ...patient })}
        <Typography className={classes.sectionTitle}>Insurance Info</Typography>
        {this.renderPatientData(patient.person.insuranceInfo)}
      </div>
    )
  }
}

const style = theme => ({
  container: {
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'auto',
  },
  containerHidden: {
    display: 'none',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.darker,
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  row: {
    display: 'flex',
  },
  rowKey: {
    fontWeight: 'bold',
    marginRight: '0.5em',
  },
  rowText: {
    fontSize: '1.4rem',
  },
})

export default withStyles(style, { withTheme: true })(PatientDetailMeta)
