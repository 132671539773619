import { useEffect } from 'react'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import EditTemplateTasks from './EditTemplateTasks'
import { ITask } from '~/models/Task'
import FFInput from '~/components/Generic/FFInput'
import { ICarePlanTemplate } from './useCarePlanModal'
import SelectTemplate from './SelectTemplate'

interface ICreateFromTemplateProps {
  patient: any
  me: any
  tasks: ITask[]
  setTasks: (arg0: ITask[]) => void
  title: string
  notes: string
  setTitle: (arg0: string) => void
  setNotes: (arg0: string) => void
  selectedTemplate: ICarePlanTemplate | null
  selectTemplate: (carePlanTemplateId: number | null, arg1: any) => void
  previewData: any
  previewDataLoading: boolean
  previewDataError: any
  carePlanTemplatesLoading: boolean
  loadCarePlanTemplates: () => void
  carePlanTemplates: ICarePlanTemplate[]
  setAllTemplateTasksComplete: (arg0: boolean) => void
  saving: boolean
  existingCarePlanTypeIds: { [key: number]: boolean }
}

const CreateFromTemplate = (props: ICreateFromTemplateProps) => {
  const {
    selectedTemplate,
    selectTemplate,
    previewData,
    previewDataLoading,
    previewDataError,
    carePlanTemplatesLoading,
    loadCarePlanTemplates,
    carePlanTemplates,
    setAllTemplateTasksComplete,
  } = props

  useEffect(() => {
    if (!carePlanTemplates) {
      loadCarePlanTemplates()
    }
  }, [])

  if (!selectedTemplate) {
    return (
      <SelectTemplate
        carePlanTemplates={carePlanTemplates}
        selectedTemplate={selectedTemplate}
        carePlanTemplatesLoading={carePlanTemplatesLoading}
        selectTemplate={templateId => selectTemplate(templateId, props.patient)}
        existingCarePlanTypeIds={props.existingCarePlanTypeIds}
      />
    )
  }

  const handleUpdateNotes = (event: any) => {
    if (event) {
      props.setNotes(event.target?.value)
    }
  }

  const handleUpdateTitle = (event: any) => {
    if (event) {
      props.setTitle(event.target?.value)
    }
  }
  const taskPreviews = (): JSX.Element => {
    if (!previewData) return <></>
    if (previewDataError) {
      return <Typography>There was a problem loading the preview for this template</Typography>
    }
    if (!previewData.taskPreviews || !previewData.taskPreviews.length) {
      setAllTemplateTasksComplete(true)
      return <Typography>No tasks associated with this template</Typography>
    }
    return (
      <>
        <FFInput
          disabled={props.saving}
          onChange={e => handleUpdateTitle(e)}
          value={props.title}
          placeholder="Add care plan name"
          fullWidth
        />
        <Box mt={1}>
          <FFInput
            disabled={props.saving}
            onChange={e => handleUpdateNotes(e)}
            value={props.notes}
            placeholder="Add description"
            multiline={true}
            fullWidth
          />
        </Box>
        <EditTemplateTasks
          patient={props.patient}
          me={props.me}
          previewData={previewData}
          setAllTemplateTasksComplete={setAllTemplateTasksComplete}
          saving={props.saving}
          tasks={props.tasks}
          setTasks={props.setTasks}
        />
      </>
    )
  }

  return (
    <>
      <Box>
        {previewDataLoading && <CircularProgress />}
        {!previewDataLoading && <Box ml={2}>{taskPreviews()}</Box>}
      </Box>
    </>
  )
}

export default CreateFromTemplate
