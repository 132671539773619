import { useCallback, useEffect } from 'react'
import type { FC } from 'react'
import { Coverage, InsuranceInfo, PatientUser, Person, useMutation } from '~/legacy/core'
import { Box, Typography } from '@mui/material'

import EditPersonForm from './EditPersonForm'
import {
  PartialInsuranceInfo,
  useCreateOrUpdateInsurance,
  useUpdatePerson,
  useCreateOrUpdateInsuranceAddress,
  PatientInsuranceAddressPayload,
} from '~/api/PersonService'
import { difference } from '~/components/PatientDetail/EditPatient/utils'
import { identity, omit, pickBy, update } from 'lodash/fp'

import { apiClient } from '~/api/rest'
import EditInsuranceForm from '~/components/PatientDetail/EditPatient/EditInsuranceForm'
import EditAddressForm from '~/components/PatientDetail/EditPatient/EditAddressForm'

interface EditPersonProps {
  patient: Person
}

const ADDRESS_PATH = 'addresses[0]'

const cleanAddress = pickBy(identity)

export const EditPerson: FC<EditPersonProps> = props => {
  const { isLoading: isUpdatingPerson, mutateAsync: handleUpdatePerson } = useUpdatePerson()
  const { isLoading: isUpdatingInsurance, mutateAsync: handleCreateOrUpdateInsurance } =
    useCreateOrUpdateInsurance()
  const {
    isLoading: isUpdatingInsuranceAddress,
    mutateAsync: handleCreateOrUpdateInsuranceAddress,
  } = useCreateOrUpdateInsuranceAddress()

  const { handler, result } = useMutation<[void], Coverage[]>(() =>
    apiClient.rest.get('/user/coverage/statuses/')
  )
  // Load all possible coverage statuses
  useEffect(() => {
    handler()
  }, [])
  const patientUser: PatientUser = {} as PatientUser
  patientUser.person = props.patient
  const handlePersonDetailsSubmit = useCallback(
    async (values: Person) => {
      const {
        id,
        firstName,
        lastName,
        dob,
        preferredName,
        employer,
        gender,
        sex,
        pronouns,
        requestAuthForPhi,
        phoneNumber,
        email,
      } = values
      try {
        await handleUpdatePerson({
          id,
          firstName,
          lastName,
          dob,
          preferredName,
          employer,
          gender,
          sex,
          pronouns,
          requestAuthForPhi,
          phoneNumber,
          email,
        })
      } catch (e) {
        console.error(e)
      }
    },
    [props.patient]
  )

  const handleAddressSubmit = useCallback(
    async (values: PatientUser) => {
      const person: Person = values.person
      update(ADDRESS_PATH, cleanAddress, person.insuranceInfo as InsuranceInfo)

      try {
        const updatedInsuranceInfo = difference(person, props.patient)
        const payload: PatientInsuranceAddressPayload = {
          insuranceInfo: {
            id: person.insuranceInfo?.id,
            addresses: updatedInsuranceInfo.insuranceInfo.addresses,
          },
        }
        const ptInsurance = await handleCreateOrUpdateInsuranceAddress(payload)
        if (ptInsurance && person.insuranceInfo?.id == null) {
          await handleUpdatePerson({
            id: patientUser.person.id,
            insuranceInfo: ptInsurance,
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    [props.patient.insuranceInfo]
  )

  const handleInsuranceSubmit = useCallback(
    async (values: PatientUser) => {
      try {
        const insuranceInfo = values.person.insuranceInfo as InsuranceInfo
        const updatedPersonInfo = difference(values.person, props.patient)
        const payload: PartialInsuranceInfo = {
          insuranceInfo: {
            id: insuranceInfo.id ? insuranceInfo.id : null,
            ...omit(
              ['imageFront', 'imageBack'],
              updatedPersonInfo ? updatedPersonInfo.insuranceInfo : null
            ),
          },
        }
        const ptInsurance = await handleCreateOrUpdateInsurance(payload)
        if (ptInsurance && values.person.insuranceInfo?.id == null) {
          await handleUpdatePerson({
            id: patientUser.person.id,
            insuranceInfo: ptInsurance,
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    [props.patient.insuranceInfo]
  )

  // currently disabling this as eligibility run has user dependency
  const handleEligibilityCheck = () => {}

  const isLoading = isUpdatingPerson || isUpdatingInsurance || isUpdatingInsuranceAddress
  return (
    <Box p={2} border={1} borderLeft={0} borderRight={0} borderTop={0} borderColor="grey.300">
      <Box mb={1}>
        <Typography variant="h6">Basic Info</Typography>
      </Box>
      <EditPersonForm
        patient={props.patient}
        handleSubmit={handlePersonDetailsSubmit}
        loading={isLoading}
      />
      <Box mt={3} mb={1}>
        <Typography variant="h6">Address</Typography>
      </Box>
      <Box mt={3} mb={1}>
        <EditAddressForm
          handleSubmit={handleAddressSubmit}
          loading={isLoading}
          patient={patientUser}
        />
        <EditInsuranceForm
          existingCoverage={result || []}
          handleInsuranceSubmit={handleInsuranceSubmit}
          handleEligibilityCheck={handleEligibilityCheck}
          loading={isLoading}
          patient={patientUser}
          lastEligibilityCheckData={null}
        />
      </Box>
    </Box>
  )
}

export default EditPerson
