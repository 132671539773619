import { FC, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button, Box, Typography, IconButton } from '@mui/material'
import { FFModal } from '~/components/FFModal/FFModal'
import { ShiftException } from './utils'
import ShiftExceptionEditor from './ShiftExceptionEditor'

interface Props {
  shiftExceptions: ShiftException[] | null
  loading: boolean
  handleCancel: () => void
  ingestionJobId: number | null
  providerId: number
  handleSubmit: (
    shiftExceptionsData: ShiftException[],
    deletedShiftExceptionsData: ShiftException[]
  ) => void
}

const EditShiftExceptionTimingsModal: FC<Props> = (props: Props) => {
  const [tempId, setTempId] = useState<number>(-1)
  const [shiftExceptionsData, setShiftExceptionsData] = useState<ShiftException[]>([])
  const [deletedShiftExceptionsData, setDeletedShiftExceptionsData] = useState<ShiftException[]>([])
  const [error, setError] = useState<boolean[]>([])
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const [displayError, setDisplayError] = useState<boolean>(false)

  useEffect(() => {
    if (props.loading || !props.shiftExceptions) return
    setShiftExceptionsData(props.shiftExceptions)
    setError(props.shiftExceptions.map(_ => false))
  }, [props.loading, props.shiftExceptions])

  const handleUpdateShiftExceptions = (editedShiftException: ShiftException) => {
    const prevShiftException = shiftExceptionsData.find(
      shiftException => shiftException.id === editedShiftException.id
    )
    if (prevShiftException) {
      const prevShiftExceptionIndex = shiftExceptionsData.indexOf(prevShiftException)
      setShiftExceptionsData([
        ...shiftExceptionsData.slice(0, prevShiftExceptionIndex),
        { ...editedShiftException, isEdited: true },
        ...shiftExceptionsData.slice(prevShiftExceptionIndex + 1),
      ])
      setIsEdited(true)
    }
  }

  const handleDeleteShiftExceptions = (deletedShiftException: ShiftException) => {
    setShiftExceptionsData(
      shiftExceptionsData.filter(shiftException => shiftException.id !== deletedShiftException.id)
    )
    if (deletedShiftException.id > 0) {
      setDeletedShiftExceptionsData([...deletedShiftExceptionsData, deletedShiftException])
      setIsEdited(true)
    }
    setError(prevError => prevError.slice(0, prevError.length - 2))
  }

  const handleAddShiftExceptions = () => {
    const shiftException: ShiftException = {
      id: tempId,
      period: { lower: '', upper: '', bounds: '[)' },
      reason: null,
      isEdited: true,
    }
    setShiftExceptionsData([...shiftExceptionsData, shiftException])
    setDisplayError(false)
    setIsEdited(true)
    setTempId(prevTempId => prevTempId - 1)
    setError(prevError => {
      prevError.push(false)
      return prevError
    })
  }

  const handleUpdate = () => {
    if (error.reduce((val, acc) => acc || val, false)) setDisplayError(true)
    else {
      props.handleSubmit(shiftExceptionsData, deletedShiftExceptionsData)
      setDeletedShiftExceptionsData([])
      setDisplayError(false)
      setIsEdited(false)
    }
  }

  const handleCancel = () => {
    setDisplayError(false)
    setIsEdited(false)
    props.handleCancel()
  }

  if (props.loading || !props.shiftExceptions) return null

  return (
    <FFModal
      open={true}
      style={{ width: '140%', left: '-20%' }}
      header={
        <Box p={1}>
          <Typography variant="h6" align="left">
            Edit Exceptions and Time Off
          </Typography>
        </Box>
      }
      footer={
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button color="secondary" disabled={props.loading} onClick={handleCancel}>
            Cancel
          </Button>
          <Box ml={1}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={props.loading || !isEdited}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Box>
        </Box>
      }
    >
      <Box m={2}>
        {shiftExceptionsData.map((shiftException, i) => (
          <ShiftExceptionEditor
            key={i}
            shiftException={shiftException}
            displayError={displayError}
            onError={error =>
              setError(prevError => {
                prevError[i] = error
                return prevError
              })
            }
            onChange={handleUpdateShiftExceptions}
            onDelete={handleDeleteShiftExceptions}
          />
        ))}
        <IconButton
          color="primary"
          onClick={handleAddShiftExceptions}
          sx={theme => ({
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary[700],
            },
            borderRadius: 'unset',
            marginTop: 3,
          })}
        >
          <AddIcon color="inherit" sx={{ fontSize: '1.8rem', fontWeight: 500 }} />
          <Typography ml={1} sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
            Add a shift exception
          </Typography>
        </IconButton>
      </Box>
    </FFModal>
  )
}

export default EditShiftExceptionTimingsModal
