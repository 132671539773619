import { OptionsObject, SharedProps, SnackbarMessage } from 'notistack'

export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
import { datadogLogs } from '@datadog/browser-logs'

interface NotificationPayload extends OptionsObject, SharedProps {
  message: SnackbarMessage
}

export const queueNotification = (notification: NotificationPayload) => {
  const { message, ...payload } = notification

  if (payload.variant == 'error') {
    try {
      datadogLogs.logger.error(`Error notification: ${message}`, notification)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    type: ENQUEUE_NOTIFICATION,
    notification: {
      message,
      ...payload,
      key: payload?.key ?? new Date().getTime() + Math.random(),
    },
  }
}

export const closeNotification = key => ({
  type: CLOSE_NOTIFICATION,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
})

export const removeNotification = key => ({
  type: REMOVE_NOTIFICATION,
  key,
})
