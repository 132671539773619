// all requirements show in patient detail, but only some in list view
export const STATUS_TO_REQUIREMENTS = {
  initialized: [],
  signedup: [
    {
      id: 'downloadedApp',
      label: 'Downloaded App',
    },
    {
      id: 'insuranceEligibility',
      label: 'Insurance',
      detailLabel: 'Insurance Eligibility',
    },
  ],
  enrolled: [],
  member: [
    {
      id: 'careTeamSelection',
      label: 'Care Team',
      detailLabel: 'Care Team Selection',
    },
    {
      id: 'pocSent',
      label: 'POC Sent',
    },
    {
      id: 'pocCompleted',
      label: 'POC Completed',
    },
    {
      id: 'medicalRecordRelease',
      label: 'Medical Records',
      detailLabel: 'Medical Records Release',
    },
    {
      id: 'bookVisitDuringOnboardingYn',
      label: 'Book First Visit Screen Response',
      multichoice: true,
    },
  ],
  churned: [],
  discharged: [],
  deactivated: [],
}
