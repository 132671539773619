import { CSSProperties } from '@material-ui/core/styles/withStyles'
import theme from './theme'
import { defaults } from 'lodash/fp'

export const INPUT_STYLES: CSSProperties = {
  background: theme.palette.blueGrey.lighter,
  color: theme.palette.grey[600],
  border: 0,
  outline: 'none',
  boxShadow: 'none',
  resize: 'none',
  fontFamily: 'inherit',
  padding: '0.75em 2.25em 0 0.75em',
  '&:focus': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '&::placeholder': {
    color: theme.palette.grey[700],
  },
}

const getInputStyles = (additional: CSSProperties): CSSProperties => {
  return defaults(INPUT_STYLES, additional)
}

export default getInputStyles
