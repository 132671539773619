import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ChatSearchResultSingle from '~/components/Chat/ChatSearchResultSingle'

export const ChatSearchResults = props => {
  const { threadUid, searchQuery, handleResultClick } = props
  const searchResults = useSelector(
    state => state.chat.searchResults[`${threadUid},${searchQuery}`]
  )
  const styles = useStyles()

  return (
    <div>
      {searchResults ? (
        <div className={styles.resultInfo}>
          {searchResults.total} {searchResults.hits.length === 1 ? 'result' : 'results'}
        </div>
      ) : null}
      {searchResults && searchResults.hits
        ? searchResults.hits.map(result => (
            <ChatSearchResultSingle
              key={result.id}
              id={result.id}
              searchQuery={searchQuery}
              result={result.source}
              handleResultClick={handleResultClick}
            />
          ))
        : null}
    </div>
  )
}

const useStyles = makeStyles({
  resultInfo: {
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1em',
    borderBottom: `0.1rem solid #e0e0e0`,
  },
})

export default ChatSearchResults
