import { FC, useEffect, useState } from 'react'
import { useMutation } from '~/components/Providers/ApiProvider'

import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
} from '@mui/material'
import { FFModal } from '~/components/FFModal/FFModal'
import { apiClient } from '~/api/rest'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'
import { updateProviderPracticingStates } from '~/redux/actions/providers'
import { ProviderUser, State } from '~/types'

interface Props {
  stateOptions: State[]
  provider: ProviderUser | null
  loading: boolean
  handleCancel: () => void
}

const EditPracticingStatesModal: FC<Props> = (props: Props) => {
  const [selectedStates, setSelectedStates] = useState<{ [stateId: number]: boolean }>({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (!props.provider || props.loading || !props.stateOptions) return

    // Update the selection of states, based on the provider's current list
    const newSelectedStates = {}
    props.stateOptions.forEach(state => {
      newSelectedStates[state.id] = !!(props.provider!.providerFields?.practicingStates || []).find(
        practicingState => {
          return practicingState.id === state.id
        }
      )
    })
    setSelectedStates(newSelectedStates)
  }, [props.stateOptions, props.loading, props.provider?.providerFields])

  const handleSelection = (stateId: number, isChecked: boolean) => {
    setSelectedStates({
      ...selectedStates,
      [stateId]: isChecked,
    })
  }

  const updatePracticingStatesMutation = useMutation(
    async () =>
      apiClient.rest.patch(
        `/physician/${props.provider!.providerFields.physicianId}/practicing-states/`,
        {
          practicing_state_ids: Object.keys(selectedStates).filter(
            stateId => !!selectedStates[stateId]
          ),
        }
      ),
    {
      onSuccess: async response => {
        await dispatch(
          updateProviderPracticingStates(props.provider!.id, response.practicingStates)
        )
        await dispatch(
          queueNotification({ variant: 'success', message: 'Updated Practicing States' })
        )
        props.handleCancel()
      },
    },
    { error: 'Failed to update Practicing States' }
  )

  if (props.loading || !props.provider || !props.provider.providerFields?.physicianId) return null

  const nonTerritorialStates = props.stateOptions.filter(state => !state.territory)
  const easternStates = nonTerritorialStates.filter(state => state.timeZoneRegion === 'east')
  const westernStates = nonTerritorialStates.filter(state => state.timeZoneRegion === 'west')
  return (
    <FFModal
      open={true}
      header={
        <Box p={1}>
          <Typography variant="h6" align="left">
            {`Edit Licenses for ${props.provider.firstName} ${props.provider.lastName}`}
          </Typography>
        </Box>
      }
      footer={
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            disabled={updatePracticingStatesMutation.isLoading}
            onClick={() => props.handleCancel()}
          >
            Cancel
          </Button>
          <Box ml={1}>
            <Button
              color="primary"
              disabled={updatePracticingStatesMutation.isLoading}
              onClick={() => updatePracticingStatesMutation.mutate()}
            >
              Save
            </Button>
          </Box>
        </Box>
      }
    >
      <Box m={2}>
        <Box mt={2}>
          <FormControl component="fieldset" variant="standard" fullWidth>
            <Typography fontSize={16} fontWeight={500}>
              Eastern Practicing Licenses
            </Typography>
            <Box display={'flex'} paddingLeft={3} paddingTop={1}>
              <FormGroup style={{ flex: 1 }}>
                {easternStates
                  .filter((state, index) => index < easternStates.length / 2)
                  .map(state => {
                    return (
                      <FormControlLabel
                        key={state.id}
                        control={
                          <Checkbox
                            checked={!!selectedStates[state.id]}
                            onChange={e => handleSelection(state.id, e.target.checked)}
                            name={state.abbreviation}
                          />
                        }
                        label={`${state.name} (${state.abbreviation})`}
                      />
                    )
                  })}
              </FormGroup>
              <FormGroup style={{ flex: 1 }}>
                {easternStates
                  .filter((state, index) => index >= easternStates.length / 2)
                  .map(state => {
                    return (
                      <FormControlLabel
                        key={state.id}
                        control={
                          <Checkbox
                            checked={!!selectedStates[state.id]}
                            onChange={e => handleSelection(state.id, e.target.checked)}
                            name={state.abbreviation}
                          />
                        }
                        label={`${state.name} (${state.abbreviation})`}
                      />
                    )
                  })}
              </FormGroup>
            </Box>
          </FormControl>
          <FormControl component="fieldset" variant="standard" fullWidth style={{ marginTop: 20 }}>
            <Typography fontSize={16} fontWeight={500}>
              Western Practicing Licenses
            </Typography>
            <Box display={'flex'} paddingLeft={3} paddingTop={1}>
              <FormGroup style={{ flex: 1 }}>
                {westernStates
                  .filter((state, index) => index < westernStates.length / 2)
                  .map(state => {
                    return (
                      <FormControlLabel
                        key={state.id}
                        control={
                          <Checkbox
                            checked={!!selectedStates[state.id]}
                            onChange={e => handleSelection(state.id, e.target.checked)}
                            name={state.abbreviation}
                          />
                        }
                        label={`${state.name} (${state.abbreviation})`}
                      />
                    )
                  })}
              </FormGroup>
              <FormGroup style={{ flex: 1 }}>
                {westernStates
                  .filter((state, index) => index >= westernStates.length / 2)
                  .map(state => {
                    return (
                      <FormControlLabel
                        key={state.id}
                        control={
                          <Checkbox
                            checked={!!selectedStates[state.id]}
                            onChange={e => handleSelection(state.id, e.target.checked)}
                            name={state.abbreviation}
                          />
                        }
                        label={`${state.name} (${state.abbreviation})`}
                      />
                    )
                  })}
              </FormGroup>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </FFModal>
  )
}

export default EditPracticingStatesModal
