import type { FC } from 'react'
import CarePassRelatedView from '~/components/PatientDetail/CarePass/CarePassRelatedView'
import FormSubmissionRelatedView from '~/components/PatientDetail/PatientDetailForms/FormSubmissionRelatedView'
import SteerageRelatedView from '~/components/PatientDetail/SteerageView/SteerageRelatedView'
import { CarePass, Steerage } from '~/components/PatientDetail/SteerageView/types'
import { PriorAuthorizationItemType } from '~/components/PatientDetail/PriorAuthorization/types'
import PriorAuthorizationRelatedView from '~/components/PatientDetail/PriorAuthorization/PriorAuthorizationRelatedView'
import MeasureReportRelatedView from '~/components/Quality/MeasureReportRelatedView'
import { MeasureReport } from '~/models/Quality'
import { FormSubmission } from '~/legacy/core'
import { OnboardingState } from '~/models/OnboardingState'
import OnboardingRelatedView from '~/components/Onboarding/OnboardingRelatedView'

// ModelNameToType maps a model's content type name to a minimal type needed to render its components.
type ModelNameToType = {
  formsubmission: Pick<FormSubmission, 'id' | 'completedAt'> & { userId: number }
  priorauthorization: Pick<PriorAuthorizationItemType, 'pk'>
  steerage: Pick<Steerage, 'id'>
  waiver: Pick<CarePass, 'pk'>
  measurereport: MeasureReport
  onboardingstate: OnboardingState
  appointment: { id: number }
  scheduleingestionjob: { id: number }
}

type ModelName = keyof ModelNameToType
type ModelType = ModelNameToType[ModelName]

type ModelRelatedComponent<T = ModelType> = FC<{ model: T }>

type ModelMetadata<T = ModelType> = {
  // Describes the view of a model that should be rendered in the context of another related model's list view.
  related: {
    // Builds the component to render the model's view.
    // Implementation note: The component should take all props necessary to render the item and not fetch data itself,
    // to avoid inefficient queries in a loop.
    component: () => ModelRelatedComponent<T>
  }
  // TODO:
  // list: { component: () => ModelListComponent }
  // edit: { component: () => ModelEditComponent }
  // etc. for different views
}

// Use this component as a temporary placeholder for new relations.
const _EmptyRelatedView = (_props: { model: { id: number } }) => <></>

const MODEL_METADATA: { [key in ModelName]: ModelMetadata<ModelNameToType[key]> } = {
  priorauthorization: {
    related: {
      component: () => PriorAuthorizationRelatedView,
    },
  },
  formsubmission: {
    related: {
      component: () => FormSubmissionRelatedView,
    },
  },
  steerage: {
    related: {
      component: () => SteerageRelatedView,
    },
  },
  waiver: {
    related: {
      component: () => CarePassRelatedView,
    },
  },
  measurereport: {
    related: {
      component: () => MeasureReportRelatedView,
    },
  },
  onboardingstate: {
    related: {
      component: () => OnboardingRelatedView,
    },
  },
  appointment: {
    related: {
      component: () => _EmptyRelatedView,
    },
  },
  scheduleingestionjob: {
    related: {
      component: () => _EmptyRelatedView,
    },
  },
} as const

const metadataFor = (model: ModelName) => MODEL_METADATA[model]

// buildRelatedComponentForModel constructs and returns a React component to render the related view of the model
// having the given content type name.
export const buildRelatedComponentForModel = <T extends ModelName>(
  modelName: T
): ModelRelatedComponent<ModelNameToType[T]> => {
  const metadata = metadataFor(modelName)
  const { component } = metadata.related

  // Need the type assertion to narrow the type of component returned to just the one for this model name.
  const Component = component() as ModelRelatedComponent<ModelNameToType[T]>

  return Component
}

// TODO:
// buildListComponentForModel ...
// buildEditComponentForModel ...
// etc. for different views.
