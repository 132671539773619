import { Box, Link } from '@material-ui/core'
import red from '@material-ui/core/colors/red'
import yellow from '@material-ui/core/colors/yellow'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import lodash from 'lodash'
import Moment from 'moment'
import { Component } from 'react'
import Linkify from '~/components/Linkify'
import { getUserById, getUserName } from '~/utils'
import CheckBox from '../CheckBox/CheckBox'
import PriorityIcon from '../Generic/PriorityIcon'
import TaskDueDate from '../Tasks/TaskDueDate'
import * as CarePlanService from '~/api/CarePlanService'
export class CareplansSingle extends Component {
  state = {
    anchorEl: null,
    loading: [],
    showCompleted: false,
    isUpdating: false,
  }

  handleUserClick = e => {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleUserClose = () => {
    this.setState({ anchorEl: null })
  }

  handleOpenCareplan = () => {
    const { carePlan, openModal } = this.props
    this.handleUserClose()
    openModal(carePlan)
  }

  toggleTaskComplete = task => {
    const { carePlan, toggleTaskComplete } = this.props
    // Add task id to loading state
    this.setState({ loading: [...this.state.loading, task.id] }, async () => {
      // Remove current task id from the loading state
      const loading = this.state.loading.filter(taskId => taskId !== task.id)
      try {
        await toggleTaskComplete(carePlan.id, task.id, { isComplete: !task.isComplete })
        this.setState({ loading })
      } catch (__) {
        this.setState({ loading })
      }
    })
  }
  onCarePlanActionChange = async action => {
    this.setState({ isUpdating: true })
    const { carePlan, onCareplanCreateOrUpdate, dispatchNotification } = this.props
    try {
      const updatedCarePlan = await CarePlanService.updateCarePlanAction({
        id: parseInt(carePlan.id),
        action: action,
        tasks: carePlan.tasks,
        cases: carePlan.cases,
        patient: carePlan.patient,
      })
      onCareplanCreateOrUpdate(updatedCarePlan)
    } catch (__) {
      dispatchNotification({
        variant: 'error',
        message: 'Something went wrong. Please try again',
      })
      const updatedCarePlan = await CarePlanService.getCarePlan(carePlan.id)
      onCareplanCreateOrUpdate(updatedCarePlan)
    }
    this.setState({ isUpdating: false })
  }
  handleActionChange = action => {
    const { carePlan, setModalCarePlanAction } = this.props
    const destCategory = carePlan.statesWithCategories?.find(
      stateWithCategory => stateWithCategory.state.name === action.dest
    )
    // When a care plan is marked complete, then all the pending tasks gets deleted
    // and all the pending cases will get delinked from the care plan.
    // So before completing a care plan make sure that there are no pending tasks or cases.
    // If there are pending tasks or cases, then show the list to the user.
    // Only when the user acknowledge it, then mark the care plan as complete.
    if (
      destCategory &&
      (destCategory.category === 'complete' || destCategory.category === 'deferred') &&
      (carePlan.cases.length > 0 || carePlan.tasks.filter(task => !task.isComplete).length > 0)
    ) {
      setModalCarePlanAction({
        carePlan: carePlan,
        action: action.trigger,
        onCarePlanActionChange: this.onCarePlanActionChange,
      })
    } else {
      this.onCarePlanActionChange(action.trigger)
    }
  }

  renderTasks = orderedTasks =>
    orderedTasks.map((task, i) => {
      const { classes, patient, providers, assigneeGroups } = this.props
      const { dueDate, title } = task
      // Lots of variations for messaging and styles

      let assignee = ''

      assignee =
        [patient, ...providers].find(user => user.assigneeGroup?.id === task.ownerGroup)
          ?.assigneeGroup || {}
      if (Object.keys(assignee).length == 0) {
        assignee = assigneeGroups.find(group => group.id === task.ownerGroup) || {}
      }
      assignee = `${assignee.name}`

      let createdBy = providers.find(user => user.id === task.createdBy)
      if (createdBy === undefined) {
        createdBy = `N/A`
      } else {
        createdBy = `${createdBy.firstName} ${createdBy.lastName}`
      }

      // Disable if loading
      const taskClass = this.state.loading.find(taskId => taskId === task.id)
        ? `${classes.contentTask} ${classes.contentTaskDisabled}`
        : classes.contentTask

      return (
        <div key={`task_${i}`} className={taskClass}>
          <CheckBox value={task.isComplete} onChecked={() => this.toggleTaskComplete(task)} />
          <div className={classes.contentTaskText}>
            <Typography>
              <Linkify>
                <Box className={classes.titleHolder}>
                  <Box className={classes.priorityIconHolder}>
                    {task && task.priority !== 0 ? (
                      <PriorityIcon level={task.priority} variant="view" />
                    ) : null}
                  </Box>
                  {title}
                </Box>
              </Linkify>
            </Typography>
            <div className={classes.contentTaskListDue}>
              <span className={classes.boldLabel}>Assigned to</span>
              {assignee} | <TaskDueDate dueDate={dueDate} isComplete={task.isComplete} />
              <Typography className={classes.taskSubText}>
                — Created by {createdBy} on
                {` ${Moment(task.createdAt).format('llll')}`}
              </Typography>
            </div>
          </div>
        </div>
      )
    })

  renderHistory() {
    /**
     * Shows a different message depending on whether it's been updated.
     */
    const { carePlan, providers } = this.props
    const provider = getUserById(providers, carePlan.updatedBy) || null
    const createdAt = Moment(carePlan.createdAt).format('MMM. D, YYYY')
    const updatedAt = Moment(carePlan.updatedAt).format('MMM. D, YYYY')
    let content = `Created ${createdAt}`
    if (provider !== null) content += ` · Last edited ${updatedAt} by ${getUserName(provider)}`
    return content
  }

  renderShowCompletedLink = completedTasksCount => {
    const { classes } = this.props
    const { showCompleted } = this.state
    return completedTasksCount > 0 ? (
      <Box fontWeight="bold">
        <Link
          onClick={() => this.setState({ showCompleted: !showCompleted })}
          color="secondary"
          className={classes.addLink}
          underline="always"
        >
          {showCompleted
            ? `HIDE ${completedTasksCount} COMPLETED TASKS`
            : `SHOW ${completedTasksCount} COMPLETED TASKS`}
        </Link>
      </Box>
    ) : null
  }
  render() {
    const {
      classes,
      carePlan: { notes },
      hideShowCompletedToggle,
    } = this.props
    const { showCompleted } = this.state
    const completedTasksCount = this.props.carePlan.tasks.filter(task => task.isComplete).length
    const tasks =
      showCompleted || hideShowCompletedToggle
        ? this.props.carePlan.tasks
        : this.props.carePlan.tasks.filter(task => !task.isComplete)
    const orderedTasks = lodash.sortBy(tasks, ['isComplete', 'dueDate'])

    return (
      <div className={classes.container}>
        <div className={classes.containerContent}>
          <Typography className={classes.contentNote}>
            <Linkify>{notes}</Linkify>
          </Typography>
          <Typography className={classes.contentTaskTitle}>tasks</Typography>
          {this.renderTasks(orderedTasks)}
          {!hideShowCompletedToggle ? this.renderShowCompletedLink(completedTasksCount) : null}
        </div>
      </div>
    )
  }
}

const style = theme => ({
  addLink: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderBottom: `0.1rem solid ${theme.palette.grey[300]}`,
  },
  containerIcon: {
    color: theme.palette.blueGrey.light,
    marginTop: theme.spacing(0.5),
  },
  containerContent: {
    flex: 1,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  containerAction: {
    padding: theme.spacing(0.5),
  },
  boldLabel: {
    fontWeight: 800,
    padding: '0 .5em',
  },
  contentTitle: {
    fontWeight: 'bold',
  },
  contentMeta: {
    marginTop: '0.3rem',
    fontSize: '1.2rem',
  },
  contentNote: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  contentTaskTitle: {
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    marginBottom: '1rem',
    fontWeight: 800,
  },
  contentTaskList: {
    paddingLeft: theme.spacing(1.5),
    margin: 0,
  },
  contentTask: {
    position: 'relative',
    paddingLeft: '3.5rem',
    marginBottom: '3rem',
    transition: '100ms linear opacity',
    '& button': {
      position: 'absolute',
      top: '.5rem',
      left: '0rem',
    },
  },
  contentTaskDisabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  contentTaskText: {
    marginTop: '-0.5em',
    wordBreak: 'break-word',
  },
  contentTaskListDue: {
    fontSize: '1.4rem',
  },
  due: {
    color: yellow[700],
  },
  pastDue: {
    color: red[600],
  },
  taskSubText: {
    fontStyle: 'italic',
    color: theme.palette.grey[600],
    fontSize: '1.25rem',
  },
  titleHolder: {
    display: 'flex',
    paddingTop: '0.3rem',
  },
  priorityIconHolder: {
    marginRight: '1rem',
  },
})

export default withStyles(style)(CareplansSingle)
