import { Box, Link, Typography } from '@mui/material'
import { ICase, MessageIdentifier } from '~/models/Case'
import { IPhoneCallDisposition } from '~/models/PhoneCall'
import { PatientUser, ProviderUser } from '~/types'
import { useEffect, useState } from 'react'
import ChatReadReceipt from '../Chat/ChatReadReceipt'
import { Phone as PhoneIcon } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { styles } from '../Chat/styles'
import { useDrag } from 'react-dnd'
import MessageInfo from '../Chat/MessageInfo'
import MessageActions from '../Chat/MessageActions'
import { IPhoneCallMessage } from '../Chat/Chat'
import Loader from '../Loader'
import { messageDateInfo } from '../Chat/utils'

const useStyles = makeStyles()(theme => {
  return {
    message: styles.message(theme),
    messageDate: styles.messageDate(theme),
  }
})

const PhoneCallMessage = (props: {
  phoneCall: IPhoneCallMessage
  phoneCallCases?: ICase[]
  patient: PatientUser
  patientReadAt: string | null
  providers: ProviderUser[]
  higlightChatMessageContent?: boolean
  dispositions?: IPhoneCallDisposition[] | null
  isSelectingMultiple: boolean
  isLastMessage: boolean
}) => {
  const { phoneCall, patient, patientReadAt, providers, dispositions } = props
  const { classes } = useStyles()

  // Controls for the "More" menu in <MessageActions />
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const menuIsOpen = Boolean(menuAnchorEl)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  let callerOrCalleeNameOrNumber: string | undefined = ''
  /* If we have contact phone number then the call is from talkdesk and was manually linked 
     so checking against person phone number to determine whether to show phone number or patient name.
     Number is to be shown incases such as provider made a call regarding a patient which cannot 
     automatically be linked.
     If we do not have contact phone number then the call was created manually so show patient name.
  */
  if (
    (phoneCall.contactPhoneNumber &&
      patient.person.phoneNumber &&
      phoneCall.contactPhoneNumber?.slice(-10) == patient.person.phoneNumber?.slice(-10)) ||
    !phoneCall.contactPhoneNumber
  ) {
    callerOrCalleeNameOrNumber = patient.person.preferredName
      ? `${patient.person.preferredName} ${patient.lastName}`
      : `${patient.firstName} ${patient.lastName}`
  } else callerOrCalleeNameOrNumber = phoneCall.contactPhoneNumber

  const creator = providers.find(provider => provider.id === phoneCall.createdBy)
  const creatorName = creator ? `${creator.firstName} ${creator.lastName}` : 'Unknown User'

  let caller: string | undefined = ''
  let callee: string | undefined = ''
  if (phoneCall.direction === 'INBOUND') {
    caller = callerOrCalleeNameOrNumber
    callee = creatorName
  } else {
    caller = creatorName
    callee = callerOrCalleeNameOrNumber
  }
  let dispositionName: string | undefined = ''
  if (dispositions && phoneCall.disposition) {
    dispositionName = dispositions.find(
      disposition => disposition.id === phoneCall.disposition
    )?.title
  }

  const { messageDate, preciseDate } = messageDateInfo(phoneCall)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'messageIdentifier',
    item: (): MessageIdentifier | { type: 'phonecall'; id: string } => {
      let messageIdentifier: MessageIdentifier | { type: 'phonecall'; id: string }

      messageIdentifier = {
        type: 'phonecall',
        id: phoneCall.id,
      }

      return messageIdentifier
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [mouseOnChatText, setMouseOnChatText] = useState<boolean>(false)
  const [mouseDownOnChatBox, setMouseDownOnChatBox] = useState<boolean>(false)
  const [draggable, setDraggable] = useState<boolean>(true)

  // Avoid the hover menu from overlapping with the DOM snapshot
  // during drag-and-drop
  const showMessageActions = !(isDragging || mouseDownOnChatBox || props.isSelectingMultiple)

  useEffect(() => {
    if (!isDragging) {
      setMouseDownOnChatBox(false)
    }
  }, [isDragging])

  useEffect(() => {
    if (mouseDownOnChatBox && !mouseOnChatText) {
      setDraggable(true)
    } else {
      setDraggable(false)
    }
  }, [mouseOnChatText, mouseDownOnChatBox])

  return (
    <Box mt={2}>
      <Box
        className={classes.message}
        title={messageDate}
        data-id={`phonecall-${phoneCall.id}`}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        sx={{
          position: 'relative',
          '&:hover .message-actions-hover-control': {
            opacity: '1 !important',
          },
          borderRadius: 2,
          ...(props.higlightChatMessageContent && {
            backgroundColor: 'background.highlight',
          }),
        }}
      >
        {/* Left: avatar */}
        <Box>
          <PhoneIcon sx={{ marginLeft: 1 }} />
        </Box>
        {/* Right: message contents */}
        <Box flexGrow={1}>
          {isLoading && <Loader />}
          <Box display="flex" flexDirection={'row'} mb={0.5}>
            <Typography title={preciseDate} sx={{ fontSize: '1.2rem', marginLeft: 3 }}>
              {messageDate}
            </Typography>
          </Box>
          <Box
            sx={{
              opacity: menuIsOpen ? 1 : 0,
              position: 'absolute',
              right: 0,
              top: -0.5,
              border: '1px solid',
              padding: 0.5,
              borderColor: 'secondary.100',
              backgroundColor: 'white',
              borderRadius: '10px',
              display: showMessageActions ? 'initial' : 'none',
            }}
            className="message-actions-hover-control"
          >
            <MessageActions
              isLastMessage={props.isLastMessage}
              message={phoneCall}
              patient={patient}
              menuIsOpen={menuIsOpen}
              menuAnchorEl={menuAnchorEl}
              setMenuAnchorEl={setMenuAnchorEl}
              startMultiSelect={() => null}
              setIsSelectingMultiple={() => null}
              setIsLoading={setIsLoading}
            />
          </Box>
          <Box
            position="relative"
            tabIndex={0}
            marginLeft={3}
            ref={drag}
            draggable={draggable}
            onMouseDown={() => setMouseDownOnChatBox(!mouseOnChatText)}
            onMouseUp={() => setMouseDownOnChatBox(false)}
            sx={{
              justifyContent: 'flex-start',
              display: 'flex',
              flexWrap: 'wrap',
              opacity: isDragging ? 0.5 : 1,
              position: 'relative',
              justifyItems: 'center',
            }}
          >
            <Box
              sx={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 16,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                backgroundColor: 'primary.100',
                color: 'text.primary',
                ['& a']: {
                  color: 'primary.main',
                },
                position: 'relative',
                padding: 1.25,
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                fontSize: '1.6rem',
                cursor: 'move',
              }}
            >
              <Typography
                style={{ cursor: 'text' }}
                onMouseOver={() => setMouseOnChatText(true)}
                onMouseOut={() => setMouseOnChatText(false)}
                onFocus={() => setMouseOnChatText(true)}
                onBlur={() => setMouseOnChatText(false)}
              >
                {caller} called {callee}
              </Typography>
              <Typography variant="body2">{dispositionName}</Typography>
              {phoneCall.recordingUrl ? (
                <Link href={phoneCall.recordingUrl} target="_blank" rel="noopener noreferrer">
                  Recording
                </Link>
              ) : null}
              {phoneCall.notes ? (
                <Typography style={{ paddingTop: '1rem' }}>Notes: {phoneCall.notes}</Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box ml={6} mt={1}>
          <MessageInfo patient={patient} message={phoneCall} />
        </Box>
        {patientReadAt ? <ChatReadReceipt readAt={patientReadAt} /> : null}
      </Box>
    </Box>
  )
}

export default PhoneCallMessage
