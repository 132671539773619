import { Typography } from '@mui/material'
import { CaseOrCategory, isCase } from './types'
import { HTMLAttributes } from 'react'
import MemberFacingCaseAlert from '~/components/PatientCategorizedTodos/MemberFacingCaseAlert'
import { caseIsOpen } from '~/models/Case'

interface Props extends HTMLAttributes<HTMLLIElement> {
  option: CaseOrCategory
  group: string
}

export default function Option({ option, ...props }: Props) {
  const isMemberFacing = isCase(option) ? false : option.isMemberFacing

  return (
    <>
      <Typography {...props} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '1.4rem', alignSelf: 'flex-start' }}>
          {option.title}
          {props.group === 'Recently Updated'
            ? isCase(option) && caseIsOpen(option)
              ? ' (Open)'
              : ' (Closed)'
            : ''}
          {isMemberFacing ? (
            <>
              &nbsp;&nbsp;
              <MemberFacingCaseAlert style={{ fontSize: '0.85em' }} key={String(option.id)} />
            </>
          ) : null}
        </Typography>

        {props.group === 'Recently Updated' || props.group === 'Other Open Cases' ? (
          <Typography
            sx={{
              fontSize: '1.2rem',
              color: 'grey.500',
              alignSelf: 'flex-start',
            }}
          >
            {option.description}
          </Typography>
        ) : null}
      </Typography>
    </>
  )
}
