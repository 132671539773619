import { apiClient } from '~/api/rest'
import { useQuery } from '~/components/Providers/ApiProvider'
import { CreateCarePlan, ICarePlan } from '~/models/CarePlan'
import { EducationResource } from '~/models/EducationResource'
import { setDueDateToEOD } from '~/utils/date'

export const getAllCarePlans = async () => {
  const data: ICarePlan[] = await apiClient.rest.get('/care-plan/')
  return data.map(carePlan => CreateCarePlan(carePlan))
}
export const getCarePlan = async id => {
  const data: ICarePlan = await apiClient.rest.get(`/care-plan/${id}`)
  return CreateCarePlan(data)
}
// Incase of person profile we will not have the patient id
export const getPatientCarePlans = async patientId => {
  if (patientId) {
    const data: ICarePlan[] = await apiClient.rest.get(`/care-plan/?patient=${patientId}`)
    return data.map(carePlan => CreateCarePlan(carePlan))
  } else return []
}
export const createCarePlan = async payload => {
  const data: ICarePlan = await apiClient.rest.post('/care-plan/', {
    ...payload,
    tasks: payload.tasks?.map(task => ({
      ...task,
      // Due date will be Converted as selected date with 19:00 EST time
      due_date: setDueDateToEOD(task.due_date),
    })),
  })
  return CreateCarePlan(data)
}
export const updateCarePlan = async payload => {
  const data: ICarePlan = await apiClient.rest.patch(`/care-plan/${payload.id}`, {
    ...payload,
    tasks: payload.tasks?.map(task => ({
      ...task,
      // Due date will be Converted as selected date with 19:00 EST time
      due_date: setDueDateToEOD(task.due_date),
    })),
  })
  return CreateCarePlan(data)
}
export const updateCarePlanAction = async payload => {
  const data: ICarePlan = await apiClient.rest.patch(`/care-plan/${payload.id}/action`, payload)
  return CreateCarePlan(data)
}

export const getPatientCaseBasedCarePlans = async patientId => {
  if (patientId) {
    const data: ICarePlan[] = await apiClient.rest.get(`/care-plan/v2/?patient=${patientId}`)
    return data.map(carePlan => CreateCarePlan(carePlan))
  } else return []
}

export const listCarePlansKey = 'listCarePlans'
export const useCarePlans = (variables: { patientId: number | undefined }) => {
  const { patientId } = variables
  return useQuery(
    [listCarePlansKey, patientId],
    () => getPatientCarePlans(patientId),
    { enabled: !!patientId, retryDelay: 500, retry: 3 },
    {
      error: 'Failed to fetch care plans',
    }
  )
}

export const useCaseBasedCarePlans = (variables: { patientId: number | undefined }) => {
  const { patientId } = variables
  return useQuery(
    [listCarePlansKey, patientId],
    () => getPatientCaseBasedCarePlans(patientId),
    { enabled: !!patientId, retryDelay: 500, retry: 3 },
    {
      error: 'Failed to fetch care plans',
    }
  )
}

export const updateCaseBasedCarePlan = async payload => {
  const data: ICarePlan = await apiClient.rest.patch(`/care-plan/v2/${payload.id}`, payload)
  return CreateCarePlan(data)
}

export const listEducationResourcesKey = 'listEducationResources'
export const getEducationResources = async () => {
  const data: EducationResource[] = await apiClient.rest.get(`/care-plan/education-resource`)
  return data
}

export const useEducationResources = () => {
  return useQuery(
    [listEducationResourcesKey],
    () => getEducationResources(),
    { retryDelay: 500, retry: 3 },
    {
      error: 'Failed to fetch education resources',
    }
  )
}
