import * as Auth0 from '@auth0/auth0-react'

import { Auth0Effects } from './Auth0Effects'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '~/components/Providers/LocalStorageProvider'

export const Auth0Provider = ({ children }) => {
  const history = useHistory()
  const onRedirectCallback = appState =>
    history.push(appState?.next || window.location.pathname + '/messages')
  const [orgId] = useLocalStorage('organizationId')

  return (
    <Auth0.Auth0Provider
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      domain={process.env.REACT_APP_AUTH0_TENANT_URL ?? ''}
      authorizationParams={{
        redirect_uri: window.location.origin as string,
        organization: orgId ?? undefined,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0Effects>{children}</Auth0Effects>
    </Auth0.Auth0Provider>
  )
}
