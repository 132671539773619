import type { FC } from 'react'
import * as MUI from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import groupBy from 'lodash/fp/groupBy'

import { EducationModalRichText } from './EducationModalRichText'
import { useEducationModal } from './utils'

export const EducationModalContent: FC = () => {
  const { includes, result, page, setPage } = useEducationModal()

  // Group results by their category
  const groupedResults = groupBy('fields.category.sys.id', result?.items ?? [])

  return page ? (
    <MUI.Box p={2}>
      <MUI.Typography variant="h6">{page.fields.title}</MUI.Typography>
      <EducationModalRichText document={page.fields.body} />
    </MUI.Box>
  ) : (
    <MUI.Box>
      <MUI.Box mx={2} my={2} display="flex" alignItems="center">
        <Icons.List />
        <MUI.Box ml={1}>
          <MUI.Typography variant="h6">Results</MUI.Typography>
        </MUI.Box>
      </MUI.Box>
      <MUI.Divider />
      {result && result?.items?.length ? (
        Object.keys(groupedResults).map(group => (
          <MUI.List key={group}>
            <MUI.ListSubheader>
              {includes?.entry[group]?.fields.name ?? 'Uncategorized'}
            </MUI.ListSubheader>
            {groupedResults[group].map((data, i) => (
              <MUI.ListItem button key={i} onClick={() => setPage(data)}>
                <MUI.ListItemText>{data.fields.title}</MUI.ListItemText>
                <Icons.ChevronRight color="inherit" />
              </MUI.ListItem>
            ))}
          </MUI.List>
        ))
      ) : (
        <MUI.Box m={2}>
          <MUI.Typography>No results</MUI.Typography>
        </MUI.Box>
      )}
    </MUI.Box>
  )
}

export default EducationModalContent
