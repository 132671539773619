import ResourceAPI from './base'
import { LocationType, PreferredLanguage, SpecialtyGroup } from '../../interfaces/fact'

/** @deprecated Use `@fireflyhealth/api` instead. */
export class FactResource extends ResourceAPI {
  getLocationTypes() {
    return this.rest.get<LocationType[]>(`/facts/location-types/`)
  }
  getSpecialtyGroups() {
    return this.rest.get<SpecialtyGroup[]>(`/facts/specialty-groups/`)
  }

  getPreferredLanguages() {
    return this.rest.get<PreferredLanguage[]>(`/facts/preferred-languages/`)
  }
}
