import { useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CompatRouter } from 'react-router-dom-v5-compat'
import WebFont from 'webfontloader'
import { ErrorBoundary } from 'react-error-boundary'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SnackbarProvider from '~/components/Providers/SnackbarProvider'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import { createStore } from '~/redux/configureStore'
import history from '~/utils/history'
import Interface from '~/redux/containers/VisibleInterface'
import Login from '~/screens/Login'
import FullscreenVideo from '~/screens/FullscreenVideo/FullscreenVideo'
import ProtectedRoute from '~/components/Router/ProtectedRoute'
import Root from '~/components/Root'
import { MATERIALUI_2022_KEY } from '~/utils/config'
import { ApiProvider } from './components/Providers/ApiProvider'
import { Auth0Provider } from './components/Providers/Auth0Provider'
import { LocalStorageProvider } from './components/Providers/LocalStorageProvider'
import ErrorScreen from './components/ErrorScreen'
import { ThemeProvider } from './components/Providers/ThemeProvider/ThemeProvider'
import { CheckForUpdates } from '~/utils/checkForUpdates'
import { useInitDatadog } from './utils/datadog'
import { datadogLogs } from '@datadog/browser-logs'

// Load Roboto font
WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

const store = createStore()

const App = () => {
  // Initialize Datadog for RUM and logging
  useInitDatadog()

  // Add event listener to log when network connection changes.
  useEffect(() => {
    const listener = e => {
      datadogLogs.logger.info(`Network changed: ${e.target?.downlink} Mbit/s down`, e.target)
    }

    navigator?.connection?.addEventListener('change', listener)

    // Clean up event listener when component is unmounted.
    return () => {
      navigator?.connection?.removeEventListener('change', listener)
    }
  }, [])

  // Knowing the time the client thinks it is, can be useful for debugging.
  useEffect(() => {
    // This could fail if Datadog is not fully initialized, e.g., in tests. It should not be fatal.
    try {
      datadogLogs.logger.info(`DateTime: ${new Date()}`)
    } catch (e) {
      console.error('Could not log client time to DataDog', e)
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <ReduxProvider store={store}>
        <LocalStorageProvider>
          <ApiProvider>
            <ConnectedRouter history={history}>
              <CompatRouter>
                {process.env.NODE_ENV !== 'development' && <CheckForUpdates />}

                <Auth0Provider>
                  <ThemeProvider>
                    <SnackbarProvider>
                      <DndProvider backend={HTML5Backend}>
                        <Root />
                        <Switch>
                          <Route exact path="/" component={Login} />
                          <ProtectedRoute exact path="/visit/:id" component={FullscreenVideo} />
                          <ProtectedRoute exact path="/*" component={Interface} />
                        </Switch>
                      </DndProvider>
                    </SnackbarProvider>
                  </ThemeProvider>
                </Auth0Provider>
              </CompatRouter>
            </ConnectedRouter>
          </ApiProvider>
        </LocalStorageProvider>
      </ReduxProvider>
    </ErrorBoundary>
  )
}

export default App

// TODO: move initialization functions into general "bootstrap" files
if (MATERIALUI_2022_KEY) {
  LicenseInfo.setLicenseKey(MATERIALUI_2022_KEY)
} else {
  console.error('MATERIALUI_2022_KEY not set')
}
