import type { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { Avatar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { getUserGroupInitials, getUserGroupMembers } from '~/utils'

import ArrowTooltip from './ArrowTooltip'

interface UserGroupAvatarProps {
  id: number
  size?: 'small' | 'large'
  patientId?: number
  src?: string

  // FIXME: I'd love to not allow this, but currently we hardcode the margin to 1rem and expect that in a bunch of places.
  // We then override it back to 0 in (so far) TasksTableSingle.tsx
  avatarStyle?: Pick<React.CSSProperties, 'margin' | 'display' | 'order'>
}

export const UserGroupAvatar: FC<UserGroupAvatarProps> = props => {
  const { id, size = 'large', patientId, src, avatarStyle } = props

  const { classes } = useStyles()

  const me = useSelector(state => state.me)
  const providers = useSelector(state => state.providers)
  const assigneeGroups = useSelector(state => state.assigneeGroups)
  const users = useSelector<any, any>(state => state.users.byId)

  let assigneeGroup = [...providers].find(user => user.assigneeGroup?.id == id)?.assigneeGroup

  if (!assigneeGroup && patientId) {
    const patientUser = users[patientId]
    if (patientUser?.assigneeGroup?.id == id) {
      assigneeGroup = patientUser.assigneeGroup
    }
  }
  if (!assigneeGroup && Array.isArray(assigneeGroups)) {
    assigneeGroup = assigneeGroups.find(group => group.id == id)
  }

  if (!assigneeGroup) {
    return null
  }

  const groupInitials = getUserGroupInitials(assigneeGroup)
  const groupName = getUserGroupMembers(assigneeGroup, providers)

  let userTypeClass = classes.other
  if (id === me.assigneeGroup?.id) {
    userTypeClass = classes.me
  } else if (assigneeGroup.isProvider) {
    userTypeClass = classes.provider
  } else {
    userTypeClass = classes.patient
  }

  return (
    <ArrowTooltip title={groupName}>
      <Avatar
        className={classNames(classes.avatar, classes[size], userTypeClass)}
        style={avatarStyle}
        src={src}
      >
        {groupInitials}
      </Avatar>
    </ArrowTooltip>
  )
}

const useStyles = makeStyles()(theme => ({
  avatar: {
    textTransform: 'uppercase',
    margin: '1rem',
  },
  small: {
    fontSize: '1rem',
    width: '2.5rem',
    height: '2.5rem',
  },
  large: {
    fontSize: '1.5rem',
    width: '4.5rem',
    height: '4.5rem',
  },
  patient: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  provider: {
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.text.primary,
  },
  me: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  other: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary[300]}`,
    color: theme.palette.secondary[400],
  },
}))

export default UserGroupAvatar
