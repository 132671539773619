import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { ICarePlan } from '~/models/CarePlan'

export enum DataTracking {
  MANUAL = 'Manual',
  AUTOMATIC = 'Automatic',
}

const CarePlanDataTracking: FC<{
  setDataTracking: (arg0: string | null) => void
  carePlan: ICarePlan
  closeModal: () => void
  setBackClick: (arg0: boolean) => void
  saving: boolean
}> = props => {
  const [radioButtonValue, setRadioButtonValue] = useState<string>(
    props.carePlan.carePlanDataTracking == DataTracking.AUTOMATIC
      ? DataTracking.MANUAL
      : DataTracking.AUTOMATIC
  )
  return (
    <>
      <Box m={2}>
        <Typography variant="h6">{'Review Staying Healthy'}</Typography>
      </Box>
      <Box m={2} pl={2}>
        <Typography variant="subtitle2" fontWeight={'600'} mb={1}>
          {'Choose a care plan:'}
        </Typography>
        <RadioGroup
          sx={{ width: '80%' }}
          value={radioButtonValue}
          onChange={e => {
            setRadioButtonValue(e.target.value)
          }}
        >
          <FormControlLabel
            sx={{
              '& .MuiTypography-body1': {
                fontSize: '1.4rem',
                whiteSpace: 'pre-line',
              },
              marginBottom: 2,
            }}
            value={DataTracking.AUTOMATIC}
            control={<Radio />}
            label={
              <Typography>
                {
                  'Use the automated care plan with auto-tracking\nData is correct for this patient (or average risk for all gaps)'
                }
              </Typography>
            }
          />
          <FormControlLabel
            sx={{
              '& .MuiTypography-body1': {
                fontSize: '1.4rem',
                whiteSpace: 'pre-line',
              },
            }}
            value={DataTracking.MANUAL}
            control={<Radio />}
            label={
              <Typography>
                {
                  'I will create a manual care plan\nData does not align with patient needs (or high risk for any gap)'
                }
              </Typography>
            }
          />
        </RadioGroup>
      </Box>
      <Box m={2}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} marginTop={4}>
          <Button
            disabled={props.saving}
            onClick={() => {
              props.closeModal()
            }}
            variant="text"
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '20%',
              marginRight: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
          </Button>
          <Button
            disabled={props.saving}
            onClick={() => {
              props.setBackClick(false)
              props.setDataTracking(radioButtonValue)
            }}
            variant="contained"
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '20%',
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Save'}</Typography>
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default CarePlanDataTracking
