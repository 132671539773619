import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { Provider as ReduxProvider, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import qs from 'query-string'

import InterfaceContent from '~/components/Interface/InterfaceContent'
import WorklistSidebar from '~/components/Worklist/WorklistSidebar'
import { worklistContext, worklistStore } from '~/redux/slices/worklists'
import { WorklistFilterPreference } from '~/redux/slices/worklistpreferences'
import WorklistTableSelector from '../components/Worklist/WorklistTableSelector'

export interface WorklistFilterUrlParam {
  worklistId: number
  filters: WorklistFilterPreference
}
export const Worklist: FC = () => {
  const [worklistId, setWorklistId] = useState<number>()
  const [worklistFiltersUrlParam, setWorklistFiltersUrlParam] = useState<WorklistFilterUrlParam>()
  const location = useLocation()
  const path = location.pathname
  const urlParams = qs.parse(location.search)
  const providers = useSelector(state => state.providers)
  const assigneeGroups = useSelector(state => state.assigneeGroups)
  const history = useHistory()

  useEffect(() => {
    if (!urlParams.worklistId) return
    setWorklistId(parseInt(urlParams.worklistId as string))
  }, [urlParams.worklistId])

  useEffect(() => {
    if (!urlParams.worklistFilters || typeof urlParams.worklistFilters !== 'string') return
    try {
      const worklistFilters: WorklistFilterUrlParam = JSON.parse(urlParams.worklistFilters)
      setWorklistFiltersUrlParam({
        worklistId: worklistFilters.worklistId,
        filters: worklistFilters.filters,
      })
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
      }
    }
  }, [urlParams.worklistFilters])

  useEffect(() => {
    const params = {
      ...(worklistId && { worklistId: worklistId }),
      ...(worklistFiltersUrlParam?.filters && {
        worklistFilters: JSON.stringify(worklistFiltersUrlParam),
      }),
    }
    history.replace(`${path}?${qs.stringify(params)}`)
  }, [worklistId, worklistFiltersUrlParam?.filters])

  return (
    <ReduxProvider context={worklistContext} store={worklistStore}>
      <InterfaceContent
        sidebar={<WorklistSidebar worklistId={worklistId} setWorklistId={setWorklistId} />}
      >
        <WorklistTableSelector
          worklistId={worklistId}
          providers={providers}
          assigneeGroups={assigneeGroups}
          setWorklistFiltersUrlParam={setWorklistFiltersUrlParam}
          worklistFiltersUrlParam={worklistFiltersUrlParam}
        />
      </InterfaceContent>
    </ReduxProvider>
  )
}

export default Worklist
