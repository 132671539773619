import type { FC } from 'react'
import { components } from 'react-select'
import { makeStyles } from 'tss-react/mui'
import { Face, AccessTime } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Highlighter from 'react-highlight-words'
import { formatPhoneNumber, formatUserDateOfBirth } from '~/utils'
import { PersonDemographics, getSearchHighlights } from './PeopleSearchUtils'

const useStyles = makeStyles()(theme => ({
  results: {
    position: 'absolute',
    overflow: 'auto',
    maxHeight: '60vh',
    zIndex: 100,
    outline: `0.1rem solid ${theme.palette.grey[300]}`,
    top: '100%',
    left: 0,
    width: '100%',
    background: '#fff',
    boxShadow: `0 0.5rem 1rem 0 ${theme.palette.grey[300]}`,
  },
  resultsSingle: {
    display: 'flex',
    alignItems: 'center',

    '&:hover a': {
      transform: 'scaleX(1)',
    },
  },
  resultsSingleIcon: {
    marginRight: theme.spacing(),
  },
  resultsSingleSelected: {
    fontWeight: 'bold',
  },
  resultsSingleData: {
    fontSize: '1.2rem',
  },
}))

interface IPatientSearchOptionProps {
  data: PersonDemographics
  selectProps: any
}

// Options are used to show patient data from both the "recently-viewed patients list"
// and patient search results
export const PatientSearchOption: FC<any> = (props: IPatientSearchOptionProps) => {
  const { classes } = useStyles()
  const isSearching = props?.selectProps?.inputValue && props.selectProps.inputValue.length

  const name =
    `${props.data.firstName} ${props.data.lastName}` +
    `${props.data.requestAuthForPhi ? ' (VIP)' : ''}`
  let data = props.data.dob ? `${formatUserDateOfBirth(props.data.dob)} | ` : ''
  data = props.data.sex ? `${data}${props.data.sex} | ` : data
  data = `${data}${formatPhoneNumber(props.data.phoneNumber)} | ID: ${props.data.id}`

  let nameSearchHighlight: RegExpMatchArray = []
  let numberSearchHighlight: RegExpMatchArray = []
  if (isSearching) {
    ;[nameSearchHighlight, numberSearchHighlight] = getSearchHighlights(
      props.selectProps.inputValue,
      name,
      props.data.phoneNumber
    )
  }

  const PatientIcon = isSearching ? Face : AccessTime

  return (
    <components.Option {...(props as any)}>
      <div className={classes.resultsSingle}>
        <PatientIcon className={classes.resultsSingleIcon} />
        <div>
          <Typography>
            <Highlighter
              highlightTag="span"
              highlightClassName={classes.resultsSingleSelected}
              searchWords={nameSearchHighlight}
              textToHighlight={name}
            />
          </Typography>
          <Typography className={classes.resultsSingleData}>
            <Highlighter
              highlightTag="span"
              highlightClassName={classes.resultsSingleSelected}
              searchWords={numberSearchHighlight}
              textToHighlight={data}
            />
          </Typography>
        </div>
      </div>
    </components.Option>
  )
}

export default PatientSearchOption
