import type { FC } from 'react'
import { Person } from '~/legacy/core'
import ProviderSearch from '~/components/PatientDetail/ProviderSearch/ProviderSearch'
import { useProviderSearch } from '~/components/PatientDetail/ProviderSearch/utils'
import Loader from '~/components/Loader'
import { useReferralDetails } from '~/components/PatientDetail/SteerageView/SteerageViewV3/utils'

export interface ProviderSearchTabProps {
  // Note that the Tabs correctly pass in a PERSON even though the attribute
  // is named for the vestigial PATIENT.
  patient: Person
}

const ProviderSearchTab: FC<ProviderSearchTabProps> = props => {
  const { isLoading: providerSearchIsLoading } = useProviderSearch()
  const { isLoading: referralDetailsIsLoading } = useReferralDetails()
  const isLoading = providerSearchIsLoading || referralDetailsIsLoading
  if (isLoading) return <Loader />
  return <ProviderSearch person={props?.patient} />
}

export default ProviderSearchTab
