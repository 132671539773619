import { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconError from '@material-ui/icons/Error'

import ChatMessageModal from './ChatMessageModal'
import Loader from '~/components/Loader'
import * as ChatService from '~/api/ChatService'

export class ChatMessageAttachment extends Component {
  state = {
    error: false,
    image: null,
    loading: true,
    modal: false,
  }

  componentDidMount() {
    this.resolveMessageAttachment()
  }

  resolveMessageAttachment = async () => {
    const { attachment } = this.props
    try {
      const image = await ChatService.getMessageAttachment(attachment)
      // Remove loading state when thumbnail loads
      await new Promise((resolve, reject) => {
        const thumb = new Image()
        thumb.src = image.thumbnail
        thumb.onload = resolve
        thumb.onerror = reject
      })
      this.setState({ image, loading: false })
    } catch (e) {
      this.setState({ error: true, loading: false })
    }
  }

  openModal = () => {
    const { image } = this.state
    if (image) {
      this.setState({ modal: true })
    }
  }

  render() {
    const { classes } = this.props
    const { error, image, loading, modal } = this.state
    const link = image && !error ? image.file : '#'
    return (
      <>
        <ChatMessageModal
          open={modal}
          closeModal={() => this.setState({ modal: false })}
          image={link}
        />
        <button
          onClick={this.openModal}
          {...(error || loading ? {} : { target: '_blank' })}
          className={classes.container}
        >
          {image && (
            <div
              className={classes.containerImage}
              style={{ backgroundImage: `url(${image.thumbnail})` }}
            />
          )}
          {loading && <Loader inline />}
          {error && <IconError className={classes.containerError} />}
        </button>
      </>
    )
  }
}

const styles = theme => ({
  container: {
    display: 'block',
    cursor: 'pointer',
    position: 'relative',
    width: '25rem',
    height: '25rem',
    border: 0,
    padding: 0,
    borderRadius: '0.4rem',
    overflow: 'hidden',
    marginTop: theme.spacing(0.5),
    background: theme.palette.grey[400],
    color: theme.palette.grey[600],
  },
  containerImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  },
  containerError: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

export default withStyles(styles)(ChatMessageAttachment)
