import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useContext, useEffect } from 'react'
import { FullscreenExit, Fullscreen } from '@mui/icons-material'

/**
 * For use on Patient/Person charts which are split into a lefthand and righthand side.
 * Context for controlling and displaying the expanded state of the lefthand side
 */
export const ExpandedModeContext = React.createContext<{
  toggleExpandedMode?: () => void
  resetExpandedMode?: () => void
  expandedModeIsActive: boolean
}>({ expandedModeIsActive: false })

export const useExpandedModeControls = () => {
  const [expandedModeIsActive, setExpandedModeIsActive] = useState(false)
  const toggleExpandedMode = () => setExpandedModeIsActive(current => !current)
  const resetExpandedMode = () => setExpandedModeIsActive(false)

  // The props for the expandable component, which should enclose the lefthand side of the screen
  const LefthandGridContainerProps = expandedModeIsActive
    ? { width: '90vw', position: 'relative' }
    : { xs: 7 }

  return { LefthandGridContainerProps, toggleExpandedMode, resetExpandedMode, expandedModeIsActive }
}

export const ExpandedModeControls = () => {
  const { toggleExpandedMode, expandedModeIsActive, resetExpandedMode } =
    useContext(ExpandedModeContext)

  useEffect(() => {
    // Make sure to turn off expanded mode when the component unmounts
    // so that we don't have to click back to find the controls
    return () => {
      if (resetExpandedMode) resetExpandedMode()
    }
  }, [])

  return (
    <Button
      variant="text"
      size="small"
      color="inherit"
      startIcon={expandedModeIsActive ? <FullscreenExit /> : <Fullscreen />}
      onClick={() => {
        if (toggleExpandedMode) toggleExpandedMode()
      }}
    >
      {expandedModeIsActive ? 'Collapse' : 'Expand'}
    </Button>
  )
}
