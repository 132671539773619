import { useContext, useMemo } from 'react'
import { Box, IconButton, InputBase, makeStyles, Theme } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import qs from 'query-string'
import { useCaseCategoriesById } from '~/api/CaseService'
import { ICase } from '~/models/Case'
import { useHistory } from 'react-router'
import { PatientUser } from '~/types'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconButton: {
      alignSelf: 'center',
      fontSize: '1.5rem',
      marginLeft: '1rem',
      marginRight: '1rem',
      color: `${theme.palette.grey[500]}`,
      textTransform: 'none',
    },
  }
})

const CaseMessageSearch = (props: {
  patient: PatientUser
  isSearching: boolean
  lastSearchQuery: string
  case?: ICase | null
  caseRelationLength: number
  chatMessageIndex: number
  setChatMessageIndex: (index: number) => void
}) => {
  const { caseRelationLength, chatMessageIndex, setChatMessageIndex } = props
  const { setSelectedCaseId } = useContext(SelectedTodoContext)

  const classes = useStyles()
  const history = useHistory()

  const { result: caseCategoriesById } = useCaseCategoriesById()

  const caseCategory = useMemo(
    () => (props.case ? caseCategoriesById[props.case.category]?.title : undefined),
    [props.case, caseCategoriesById]
  )

  const handleClose = () => {
    setChatMessageIndex(0)
    setSelectedCaseId(null)
    const existingQS = qs.parse(location.search)
    delete existingQS['caseId']
    delete existingQS['action']
    history.push({
      search: qs.stringify(existingQS),
    })
  }

  const handleArrowSearchResults = async (offset: number) => {
    const newIndex = chatMessageIndex + offset
    setChatMessageIndex(newIndex)
  }

  if (!caseCategory) {
    return <Box></Box>
  }

  return (
    <>
      <IconButton
        className={classes.iconButton}
        style={{ backgroundColor: 'transparent', cursor: 'default' }}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        placeholder="Search thread"
        inputProps={{ 'aria-label': 'search thread' }}
        value={caseCategory}
        fullWidth
      />
      {caseRelationLength > 0 && (
        <Box justifyContent="center" display="flex">
          <IconButton disabled={chatMessageIndex == 0} onClick={() => handleArrowSearchResults(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            disabled={chatMessageIndex == caseRelationLength - 1}
            onClick={() => handleArrowSearchResults(1)}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}
      <IconButton className={classes.iconButton} onClick={() => handleClose()}>
        <CloseIcon />
      </IconButton>
    </>
  )
}

export default CaseMessageSearch
