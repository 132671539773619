import { apiClient } from '~/api/rest'
import { CreateTask, IServerTask, ITask, UpdateTaskParent } from '~/models/Task'
import { useMutation } from '~/components/Providers/ApiProvider'
import { QueryClient, useQueryClient } from 'react-query'
import { listPersonTodoKey } from './PatientToDoService'
import { listCasesKey, getCaseKey } from './CaseService'
import { getInboxKey } from './InboxService'
import { setDueDateToEOD } from '~/utils/date'

// Because ITask is a union type, we need to apply Omit distributively over it to build ITaskWithId.
// See https://davidgomes.com/pick-omit-over-union-types-in-typescript/
type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never

// Tasks coming back from the server should be guaranteed to have an ID and createdAt (among other fields).
type ITaskWithId = DistributiveOmit<ITask, 'id' | 'createdAt'> &
  Required<Pick<ITask, 'id' | 'createdAt'>>

export const updateTask = async (id: number, payload: ITask) => {
  const data = await apiClient.rest.patch<IServerTask>(`/task/${id}`, {
    ...payload,
    // Due date will be Converted as selected date with 19:00 EST time
    dueDate: payload.dueDate ? setDueDateToEOD(payload.dueDate) : undefined,
  })
  return CreateTask(data) as ITaskWithId
}

export const createTask = async (payload: ITask) => {
  const task = await apiClient.rest.post<IServerTask>('/task/', {
    ...payload,
    // Due date will be Converted as selected date with 19:00 EST time
    dueDate: payload.dueDate ? setDueDateToEOD(payload.dueDate) : undefined,
  })
  return CreateTask(task) as ITaskWithId
}

export const deleteTask = (task: ITask) => {
  const url = `/task/${task.id}`
  console.log(`[Req][DELETE][${url}]`, task)
  return apiClient.rest.delete(url)
}

export const updateTaskParent = async (id: number, payload: UpdateTaskParent) => {
  const data = await apiClient.rest.post<IServerTask>(`/task/v2/${id}/relation/update`, payload)
  return CreateTask(data) as ITaskWithId
}

export const useAddTask = () => {
  const client = useQueryClient()

  return useMutation(
    createTask,
    {
      onSuccess: async (payload: ITaskWithId) => {
        await invalidateTaskDependentQueries(client, payload)
      },
    },
    { error: 'Failed to add task' }
  )
}

export const useUpdateTask = () => {
  const client = useQueryClient()

  return useMutation(
    (payload: ITask): any => (payload.id ? updateTask(payload.id, payload) : null),
    {
      onSuccess: async (payload: ITask) => {
        await invalidateTaskDependentQueries(client, payload)
      },
    },
    { error: 'Failed to update task' }
  )
}

export const useUpdateTaskParent = () => {
  const client = useQueryClient()

  return useMutation(
    (payload: UpdateTaskParent) => updateTaskParent(payload.taskId, payload),
    {
      onSuccess: async (payload: ITask) => {
        await invalidateTaskDependentQueries(client, payload)
      },
    },
    { error: 'Failed to update task' }
  )
}

async function invalidateTaskDependentQueries(client: QueryClient, payload: ITask | ITaskWithId) {
  await client.cancelQueries([listCasesKey, payload.patient])
  await client.invalidateQueries([listCasesKey, payload.patient])
  await client.cancelQueries([listPersonTodoKey, payload.person])
  await client.invalidateQueries([listPersonTodoKey, payload.person])
  await client.cancelQueries([getInboxKey])
  await client.invalidateQueries([getInboxKey])
  await client.cancelQueries([getCaseKey])
  await client.invalidateQueries([getCaseKey])
}
