import { searchPatientsRequest } from '../../requests/search'

import { SEARCH_PATIENTS, SEARCH_PATIENTS_SUCCESS, SEARCH_CLEAR_RESULTS } from './actionTypes'

export const searchPatients = term => ({
  type: SEARCH_PATIENTS,
  term,
})

export const searchPatientsSuccess = (term, results) => ({
  type: SEARCH_PATIENTS_SUCCESS,
  term,
  results,
})

export const searchClearResults = () => ({
  type: SEARCH_CLEAR_RESULTS,
})

export function search(term) {
  return async dispatch => {
    try {
      dispatch(searchPatients(term))
      const { data } = await (<any>searchPatientsRequest({ search: term }))
      return dispatch(searchPatientsSuccess(term, data))
    } catch (e) {
      console.error('Error searching for patients', e)
    }
  }
}
