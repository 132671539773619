import { Box, Button } from '@material-ui/core'

export const FullNoteWrapper = props => {
  return (
    <Box display="flex" p={1}>
      {props.children}
      <Box ml={1}>
        <Button
          variant="contained"
          color="primary"
          disabled={props.disabled}
          onClick={props.onClick}
        >
          Add Note
        </Button>
      </Box>
    </Box>
  )
}

export const InlineNoteWrapper = props => {
  return (
    <Box display="flex" p={1}>
      {props.children}
      <Box></Box>
    </Box>
  )
}
