export const GET_PATIENTS = 'GET_PATIENTS'
export const GET_RECENTLY_ACTIVE_PATIENTS = 'GET_RECENTLY_ACTIVE_PATIENTS'
export const GET_NEW_PATIENTS = 'GET_NEW_PATIENTS'
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS'
export const GET_PATIENTS_ERROR = 'GET_PATIENTS_ERROR'

export const SEARCH_PATIENTS = 'SEARCH_PATIENTS'
export const SEARCH_PATIENTS_SUCCESS = 'SEARCH_PATIENTS_SUCCESS'
export const SEARCH_CLEAR_RESULTS = 'SEARCH_CLEAR_RESULTS'

export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS'
export const GET_PROVIDERS_ERROR = 'GET_PROVIDERS_ERROR'
export const UPDATE_PROVIDER_PRACTICING_STATES = 'UPDATE_PROVIDER_PRACTICING_STATES'

export const GET_PATIENT = 'GET_PATIENT'
export const GET_PATIENT_ERROR = 'GET_PATIENT_ERROR'

export const EXIT_APPOINTMENT = 'EXIT_APPOINTMENT'
export const SET_ACTIVE_VIDEO_APPOINTMENT = 'SET_ACTIVE_VIDEO_APPOINTMENT'

export const GET_PATIENT_TASKS = 'GET_PATIENT_TASKS'
export const CREATE_TASK_ERROR = 'CREATE_TASK_ERROR'
export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR'
export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_MESSAGE_TASK = 'UPDATE_MESSAGE_TASK'
export const REMOVE_TASKS = 'REMOVE_TASKS'

export const GET_ME = 'GET_ME'
export const GET_ME_ERROR = 'GET_ME_ERROR'

export const GET_PUBNUB_CREDENTIALS = 'GET_PUBNUB_CREDENTIALS'
export const GET_PUBNUB_CREDENTIALS_ERROR = 'GET_PUBNUB_CREDENTIALS_ERROR'
export const GET_PUBNUB_CHATROOM = 'GET_PUBNUB_CHATROOM'
export const PUBNUB_NEW_MESSAGE = 'PUBNUB_NEW_MESSAGE'
export const PUBNUB_BATCH_MESSAGES = 'PUBNUB_BATCH_MESSAGES'
export const PUBNUB_SIGN_ON = 'PUBNUB_SIGN_ON'
export const PUBNUB_SIGN_OFF = 'PUBNUB_SIGN_OFF'
export const PUBNUB_BATCH_HISTORY = 'PUBNUB_BATCH_HISTORY'

export const START_SYNC = 'START_SYNC'
export const STOP_SYNC = 'STOP_SYNC'

export const PREFERENCES_PATIENTLIST_ORDER = 'PREFERENCES_PATIENTLIST_ORDER'
export const PREFERENCES_PATIENTLIST_ORDERBY = 'PREFERENCES_PATIENTLIST_ORDERBY'

export const SET_FEATURE_ACCESS = 'SET_FEATURE_ACCESS'

export const GET_ASSIGNEE_GROUPS = 'GET_ASSIGNEE_GROUPS'
export const GET_ASSIGNEE_GROUPS_ERROR = 'GET_ASSIGNEE_GROUPS_ERROR'
