import { useEffect } from 'react'
import type { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { queueNotification } from '~/redux/actions/notifications'
import { AppointmentEntry } from '~/components/Appointment/AppointmentEntry'

export const FullscreenVideo: FC = () => {
  const match = useRouteMatch<{ id: string }>()
  const id = match.params.id
  const dispatch = useDispatch()

  // Start and track visit
  useEffect(() => {
    if (!id) {
      dispatch(queueNotification({ message: 'Appointment ID not found', variant: 'error' }))
    }
  }, [id])

  return id ? <AppointmentEntry id={id} modal={false} /> : null
}

export default FullscreenVideo
