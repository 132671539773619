import { removeIdToken, removeSessionToken } from '~/utils/auth'
import { getMeRequest } from '../../requests/me'
import { GET_ME, GET_ME_ERROR } from './actionTypes'
import { queueNotification } from './notifications'

export const getMeRequestSuccess = response => ({
  type: GET_ME,
  me: response.data,
})

export const getMeRequestError = error => ({
  type: GET_ME_ERROR,
  error,
})

export function getMe() {
  return async dispatch => {
    try {
      const response = await getMeRequest()
      if (response?.data.providerFields === null) {
        // Remove token from storage
        removeSessionToken()
        removeIdToken()
        throw new Error('User is a patient')
      }
      return dispatch(getMeRequestSuccess(response))
    } catch (e) {
      console.log(e)
      dispatch(queueNotification({ variant: 'error', message: 'Error fetching "me"' }))
      dispatch(getMeRequestError('Problem fetching me details'))
      throw new Error(e)
    }
  }
}
