import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { usePromise } from '~/legacy/core'
import { apiClient } from '~/api/rest'
import { formatPhoneNumber } from '~/utils'

const MAX_RECENTLY_VIEWED = 20

const RECENTLY_VIEWED_PEOPLE_KEY = 'RECENTLY_VIEWED_PEOPLE_KEY_V2'

export interface PersonDemographics {
  id: number
  firstName: string
  lastName: string
  phoneNumber: string
  dob: string
  sex: string
  requestAuthForPhi: boolean
  userId?: number
}

// Parses localStorage csv value into array of numbers, ensuring empty array if there's no value set
export const getViewedPeople = (): number[] => {
  const ids = localStorage.getItem(RECENTLY_VIEWED_PEOPLE_KEY) ?? false
  return ids ? JSON.parse(ids) : []
}

// Sets array of numbers as a csv
export const setViewedPeople = (ids: number[]): void => {
  localStorage.setItem(RECENTLY_VIEWED_PEOPLE_KEY, JSON.stringify(ids))
}

export const getSearchHighlights = (searchValue: string, name: string, phoneNumber: string) => {
  name = name.replaceAll('(', '\\(').replaceAll(')', '\\)')
  searchValue = searchValue.replace('(', '\\(').replace(')', '\\)')
  const nameMatch = name.toLowerCase().match(new RegExp(searchValue.toLowerCase(), 'gm')) || []
  const numberMatch = (JSON.stringify(phoneNumber).match(new RegExp(searchValue, 'gm')) || []).map(
    phoneNumber => formatPhoneNumber(phoneNumber).replace('(', '\\(').replace(')', '\\)')
  )
  return [nameMatch, numberMatch]
}

/**
 * LocalStorage-powered recently viewed people list
 * - Loads list of person ids on init and fetches people to store in state
 * - Backs up list of ids in localStorage when window closes
 * - Removes old people from list based on MAX_RECENTLY_VIEWED count
 *
 * The person data stored in state are a limited set of just demographic data
 * The set of complete people data is hydrated after selecting a patient/person
 */
export const useRecentlyViewedPeople = (): PersonDemographics[] => {
  // The list is powered by Person IDs
  // The order should match the order in which the People were viewed
  // Element 0 => most-recently viewed
  const [ids, setIds] = useState<number[]>(getViewedPeople())
  const [peopleMap, setPeopleMap] = useState<{ [id: number]: PersonDemographics }>({})
  const [currentPerson, setCurrentPerson] = useState<null | number>(null)
  const { pathname } = useLocation()

  // Fetch people on init
  const { loading } = usePromise(async () => {
    try {
      const ids = getViewedPeople()
      if (ids.length > 0) {
        const peopleResult = await apiClient.rest
          .get<PersonDemographics[]>(`/user/people/demographics/?id__in=${ids}`)
          .catch(e => console.error(e))
        // Set unique and valid people
        const newMap = {}
        if (peopleResult) {
          for (let person of peopleResult) {
            newMap[person.id] = person
          }
        }
        setPeopleMap(newMap)
      }
    } catch (_) {
      setPeopleMap({})
    }
  })

  const handleViewPerson = useCallback(
    async (id: number) => {
      try {
        // First add the ID
        if (!ids.includes(id)) {
          let newIds = ids.slice()
          if (ids.length > MAX_RECENTLY_VIEWED) {
            newIds = ids.slice(0, -1)
          }
          newIds.unshift(id)
          setIds([...newIds])
        } else {
          // If the ID is already in the list
          // just move it into to position 0
          let newIds = ids.slice()
          const inIdsIndex = newIds.indexOf(id)
          if (inIdsIndex != -1) {
            newIds = moveElementInArray(newIds, inIdsIndex, 0)
          }
          setIds([...newIds])
        }
        // If the person isn't in state, load them
        // This isn't guaranteed to align with the ID existing in our list or not
        // because the list is persisted in local storage
        if (!peopleMap[id]) {
          const person = await apiClient.rest
            .get<PersonDemographics[]>(`/user/people/demographics/?id__in=${id}`)
            .catch(() => {
              return false
            })
          if (person) {
            const newMap = Object.assign(peopleMap, { [id]: person[0] })
            setPeopleMap(newMap)
          }
        }
      } catch (_) {
        // continue
      }
    },
    [ids, loading]
  )

  // Update local state when patient profiles change, triggering side effects below
  useEffect(() => {
    // Extracts patient ID from location, i.e. "/patients/1618/careplans" => 1618
    const patientMatch = /\/patients\/(\d+)/.exec(pathname)
    if (!patientMatch) {
      // Extracts person ID from location, i.e. "/people/1618/profile" => 1618
      const peopleMatch = /\/people\/(\d+)/.exec(pathname)
      if (peopleMatch) {
        currentPersonSet(peopleMatch, false)
      }
    } else {
      currentPersonSet(patientMatch, true)
    }
  }, [pathname])

  const currentPersonSet = async (regexMatch: RegExpExecArray, isUser: boolean) => {
    const [_, idMatch] = regexMatch
    const id = parseInt(idMatch, 10)
    // if in patient view get the patient to retrieve person id
    if (isUser) {
      const patient = await apiClient.rest.get(`/user/${id}/`).catch(() => {
        return false
      })
      if (patient) {
        setCurrentPerson(patient.person.id)
      }
    } else setCurrentPerson(id)
  }

  // Separate effect from location change because redirect within PatientDetail
  // triggers multiple changes to the pathname
  useEffect(() => {
    if (currentPerson) handleViewPerson(currentPerson)
  }, [currentPerson])

  // Persist IDs in localStorage
  useEffect(() => {
    // Prevent race condition with usePromise and setting new ids
    if (!loading) setViewedPeople(ids)
  }, [ids, loading])

  return ids.reduce((acc, currentId) => {
    if (peopleMap[currentId]) {
      acc.push(peopleMap[currentId])
    }
    return acc
  }, [] as PersonDemographics[])
}

const moveElementInArray = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}
