import type { FC } from 'react'
import { useSelector } from 'react-redux'

import { EducationModalProvider } from './EducationModalProvider'
import { EducationModal } from './EducationModal'
import EducationFeedbackModal from './EducationFeedbackModal'

// Education modal entrypoint
export const EducationRoot: FC = () => {
  const { showModal } = useSelector(state => state.education)

  return showModal ? (
    <EducationModalProvider>
      <EducationModal />
      <EducationFeedbackModal />
    </EducationModalProvider>
  ) : null
}

export default EducationRoot
