import { useEffect, useState } from 'react'
import { SnackbarContent, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Snackbar from '@mui/material/Snackbar'
import { apiClient } from '~/api/rest'
import { ProviderUser } from '~/types'

interface BannerMessageProps {
  me: ProviderUser
}
interface HeaderMessage {
  text: string
}
export const BannerMessage = (props: BannerMessageProps) => {
  const { me } = props
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)
  const [bannerMessageData, setBannerMessageData] = useState<HeaderMessage | undefined>(undefined)
  const [snackBarBackgroundColor, setSnackbarBackgroundColor] = useState('')
  const snackBarBackgroundColors = ['#5B0FD5', '#00B580', '#F5C805', '#F5751F']
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarIsOpen(false)
  }

  const action = (
    <IconButton
      sx={{ display: 'inline-block' }}
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  useEffect(() => {
    if (bannerMessageData) {
      setSnackbarIsOpen(true)
      setSnackbarBackgroundColor(
        snackBarBackgroundColors[Math.floor(Math.random() * snackBarBackgroundColors.length)]
      )
    }
  }, [bannerMessageData])

  const getHeaderMessage = async () => {
    const data: HeaderMessage = await apiClient.rest.get<HeaderMessage>(
      `/bulletin-board/headermessage/random`
    )
    if (data) {
      setBannerMessageData(data)
    }
  }

  useEffect(() => {
    getHeaderMessage()
  }, [me])

  if (bannerMessageData) {
    return (
      <Snackbar
        sx={{ minWidth: '65%' }}
        open={snackbarIsOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          sx={{
            textAlign: 'center',
            width: '100%',
            fontSize: 16,
            display: 'inline-block',
            backgroundColor: snackBarBackgroundColor,
          }}
          message={
            <Box>
              {bannerMessageData?.text}
              {<Box sx={{ float: 'right' }}>{action}</Box>}
            </Box>
          }
        ></SnackbarContent>
      </Snackbar>
    )
  }
  return null
}
