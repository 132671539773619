import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { Typography, Box } from '@mui/material'
import NoteService, { Note } from '~/api/NoteService'
import SingleNote from './SingleNote'
import NoteInput from './NoteInput'
import { borderProps } from '~/utils/borderProps'
import InlineLoader from '~/components/InlineLoader'

interface NoteListProps {
  patientId: number | undefined
  personId?: number | undefined
  noteType?: string | undefined
  objectId?: number | undefined
  inline?: boolean | undefined
  editable?: boolean
  // Additional functions to call on note creation
  handleCreate?: (objectId?: number | undefined) => void
}

interface PatientNotePayload {
  patient: number | undefined
  person: number | undefined
  noteType: string | undefined
  objectId: null | number
}

const NoteList: FC<NoteListProps> = props => {
  const [notes, setNotes] = useState<Note[] | null>(null)
  const [loading, setLoading] = useState(true)

  async function refreshPatientNotes() {
    const payload: PatientNotePayload = {
      patient: props.patientId,
      person: props.personId,
      noteType: props.noteType,
      objectId: null,
    }
    if (props.objectId) {
      payload.objectId = props.objectId
    }
    const notesResponse = await NoteService.getAll(payload)
    setNotes(notesResponse)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    refreshPatientNotes()
  }, [])

  async function createNote(note) {
    setLoading(true)
    const payload: Omit<Note, 'id' | 'createdAt' | 'updatedAt'> = {
      content: note,
      noteType: props.noteType,
      objectId: props.objectId ?? undefined,
    }
    if (props.patientId !== undefined) {
      payload.patient = props.patientId
    }
    if (props.personId !== undefined) {
      payload.person = props.personId
    }
    await NoteService.create(payload)
    // refreshPatientNotes has an await because NoteInput waits until createNote
    // is completed to clear its input. This avoids losing that note until everything is set.
    await refreshPatientNotes()

    if (props.objectId && props.handleCreate) {
      await props.handleCreate(props.objectId)
    }
    setLoading(false)
  }

  const inlineStyles = props.inline
    ? { maxHeight: '300px', overflow: 'scroll', ...borderProps(['borderTop']) }
    : {}
  return (
    <Box>
      <Box p={3} pb={0}>
        <Typography>
          Notes {notes === null || loading ? <InlineLoader /> : `(${notes.length})`}
        </Typography>
      </Box>
      {!(props.editable === false) && (
        <Box p={2} pt={1}>
          <NoteInput onSubmit={createNote} loading={loading} />
        </Box>
      )}

      <Box p={3} pb={0} {...inlineStyles}>
        {notes === null
          ? null
          : notes.map(note => <SingleNote editable={props.editable} key={note.id} note={note} />)}
      </Box>
    </Box>
  )
}

export default NoteList
