import { useCallback } from 'react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Typography } from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

import { ProviderUser } from '~/types'
import Loader from '~/components/Loader'
import logo from '~/assets/images/logo.png'
import PatientInstantNav from '~/components/PatientSearch/PatientInstantNav'
import { isAdmin } from '~/utils'
import { useLogoutEffects } from '~/utils/auth'
import { useDispatch, useSelector } from 'react-redux'
import { educationSlice } from '~/redux/slices/education'
import { SERVICE_VERSION } from '~/utils/config'
import PeopleSearch from '../PatientSearch/PeopleSearch'
import { Tooltip } from '@mui/material'

interface InterfaceHeaderProps {
  synchronizing: boolean
  me: ProviderUser
  providers: any[]
}

const InterfaceHeader: FC<InterfaceHeaderProps> = props => {
  const { me, providers, synchronizing } = props
  const dispatch = useDispatch()
  const showModal = useSelector(state => state.education.showModal)
  const { handler: handleLogout } = useLogoutEffects({
    auth0: { returnTo: window.location.origin },
  })

  const handleOpenEducationModal = useCallback(
    // If the modal is opened with a keyboard event, the
    // event is not passed
    (e?: React.MouseEvent) => {
      if (e) e.persist()
      dispatch(educationSlice.actions.open(e))
      if (e) e.preventDefault()
      return false
    },
    [showModal]
  )

  const renderSync = () => {
    return synchronizing ? (
      <Tooltip placement="left" title="Synchronizing...">
        <Typography component="div" color="primary">
          <Box mr={2}>
            <Loader inline />
          </Box>
        </Typography>
      </Tooltip>
    ) : null
  }

  if (!me || !me.providerFields) return null

  // Find if user is in admin group
  const orderedStates = me.providerFields.practicingStates
    ? me.providerFields.practicingStates.sort((a, b) => a.name.localeCompare(b.name))
    : []
  let defaultState = 'All'
  if (!isAdmin(providers, me.id) && orderedStates.length > 0) {
    defaultState = orderedStates.map(location => location.abbreviation).join(',')
  }
  let currentAdminName
  if (me) {
    currentAdminName = me.firstName + ' ' + me.lastName
  }

  return (
    <Box
      sx={{
        height: 64,
        borderBottom: '0.1rem solid',
        borderColor: 'grey.100',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box flex={1} display="flex" alignItems="center">
        <Tooltip placement="right" enterDelay={1000} title={`Version: ${SERVICE_VERSION ?? ''}`}>
          <Link
            to="/messages"
            style={{
              display: 'block',
              width: 100,
              height: 24,
              marginRight: 24,
              background: `url(${logo}) center no-repeat`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Tooltip>

        <PeopleSearch />

        <Box ml={3}>
          <PatientInstantNav />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 2,
            color: 'navy.300',
            fontSize: '1.3rem',
          }}
        >
          <LocationOnOutlinedIcon fontSize="small" />
          {defaultState}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'grey.50',
          color: 'navy.500',
          borderRadius: '0 0 24px 24px',
          display: 'flex',
          marginTop: '-20px',
          marginRight: '20px',
          '& .MuiIconButton-root': {
            borderRadius: '0 0 24px 24px',
          },
        }}
      >
        <Tooltip title="Contentful">
          <IconButton
            disabled={showModal}
            onKeyPress={() => handleOpenEducationModal()}
            onClick={handleOpenEducationModal}
            color="inherit"
          >
            <LibraryBooksOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {renderSync()}

        <Box display="flex" flexDirection="column" mr={2} color="text.secondary">
          <Typography color="textPrimary">{currentAdminName}</Typography>
        </Box>

        <Box color="grey.400">
          {me && !me.providerFields?.isExternal ? (
            <Link to="/settings">
              <Tooltip title="Settings">
                <IconButton color="inherit">
                  <SettingsIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>
          ) : null}

          <Tooltip title="Log out">
            <IconButton onClick={handleLogout} color="inherit">
              <ExitToAppIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}

export default InterfaceHeader
