import { useState } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  Button,
  Paper,
  Box,
  TextField,
  Typography,
} from '@material-ui/core'
import { titleCase } from '~/utils'
import { apiClient } from '~/api/rest'

interface IUnEnrollProps {
  patientId: number
  programUid: string
  onToggle: (enrolled: boolean, programUid: string) => any
  setShowReasonComponent: (showReasonComponent: boolean) => void
}

const REASON_OPTIONS = {
  PATIENT_IS_STABLE: 'Patient is stable',
  DIAGNOSED_BY_MISTAKE: 'Diagnosed by mistake',
  PATIENT_NOT_RESPONDING: 'Patient is not responding',
  OTHER: 'Other',
}

const PROGRAM_UNENROLL_URLS = {
  DepressionAndAnxiety: '/programs/depression-anxiety/{patient_id}/unenroll-reason',
  Hypertension: '/programs/hypertension/{patient_id}/unenroll-reason',
  Diabetes: '/programs/diabetes/{patient_id}/unenroll-reason',
  AdhdAdd: '/programs/adhd-add/{patient_id}/unenroll-reason',
}

const CaptureProgramUnEnrollReason = (props: IUnEnrollProps) => {
  const [unenrollReason, setUnenrollReason] = useState<string>('')
  const [otherReasonNote, setOtherReasonNote] = useState<string>('')
  const [showOtherReasonNote, setShowOtherReasonNote] = useState<boolean>(false)

  const content = () => {
    const captureReason = async () => {
      await apiClient.rest.patch(
        PROGRAM_UNENROLL_URLS[props.programUid].replace('{patient_id}', props.patientId),
        {
          unenroll_reason:
            otherReasonNote == ''
              ? unenrollReason
              : unenrollReason.concat(' - ').concat(otherReasonNote),
        }
      )
      props.onToggle(false, props.programUid)
      props.setShowReasonComponent(false)
    }

    const otherReasonNoteComponent = (
      <Grid item sm={4} style={{ textAlign: 'left' }}>
        <TextField
          value={otherReasonNote}
          onChange={e => setOtherReasonNote(e.target.value as string)}
          id="standard-basic"
          label="Note"
          variant="standard"
          style={{ minWidth: '200px' }}
        />
      </Grid>
    )

    const setReason = (value: string) => {
      if (value == REASON_OPTIONS.OTHER) {
        setShowOtherReasonNote(true)
      } else {
        setShowOtherReasonNote(false)
      }

      setUnenrollReason(value)
    }

    return (
      <>
        <Box>
          <Grid container direction="row">
            <Grid item sm={8}>
              <Select
                value={unenrollReason}
                onChange={e => setReason(e.target.value as string)}
                placeholder="Reason"
                style={{ minWidth: '200px' }}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Select a reason</em>
                </MenuItem>
                {Object.entries(REASON_OPTIONS).map(([reasonKey, reasonValue]) => (
                  <MenuItem key={reasonKey} value={reasonValue}>
                    {titleCase(reasonValue)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={4} style={{ textAlign: 'left' }}>
              <Button type="submit" variant="contained" color="primary" onClick={captureReason}>
                Submit
              </Button>
            </Grid>
            {showOtherReasonNote ? otherReasonNoteComponent : null}
          </Grid>
        </Box>
      </>
    )
  }

  return (
    <Grid item xs={12}>
      <Typography variant="subtitle2" style={{ margin: '1rem' }}>
        Unenroll Patient
      </Typography>
      <Box mt={1} style={{ margin: '1rem' }}>
        <Paper elevation={5} style={{ padding: '1rem' }}>
          {content()}
        </Paper>
      </Box>
    </Grid>
  )
}

export default CaptureProgramUnEnrollReason
