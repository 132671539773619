import { Provider } from './ScheduleService'
import { apiClient } from './rest'

export type Physician = {
  firstName: string
  lastName: string
  calendarUrl: string | null
  provider: Provider
}

export const getPhysicians = async () => {
  const data = await apiClient.rest.get<Physician[]>(`/physician/`)
  return data
}
