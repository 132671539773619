import axiosRequest from '~/utils/axios'
import { stringify } from 'query-string'
import { snakeCaseObjectKeys } from '~/utils'

interface SearchParams {
  search: string
  type?: 'patient' | 'provider'
  includeOnboardingInitialized?: boolean
  limit?: number
}

export function searchPatientsRequest(options: SearchParams) {
  const params = {
    type: 'patient',
    ...snakeCaseObjectKeys(options),
  }
  return axiosRequest('GET', 'application/json', true, `/user/?${stringify(params)}`)
}

export function searchPeopleRequest(options: SearchParams) {
  const params = {
    type: 'patient',
    ...snakeCaseObjectKeys(options),
  }
  return axiosRequest('GET', 'application/json', true, `/user/people/search/?${stringify(params)}`)
}
