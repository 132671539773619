import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { PatientUser } from '~/legacy/core'
import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'
import { Popover, Tabs, Tab } from '@mui/material'
import {
  PatientDetailPopOverTabValueTypes,
  patientDetailsActiveTabsType,
} from './PatientDetailTopBar'
import { UserSegmentation } from '~/api/UserSegmentationService'

interface PatientDetailPopoverProps {
  patient: PatientUser
  userSegmentation?: UserSegmentation
  anchorEl: Element
  onClose: () => void
  patientReferralProgram: string | null
  activeTabs: patientDetailsActiveTabsType[]
}

interface TabPanelProps {
  children: ReactNode
  value: PatientDetailPopOverTabValueTypes
  selectedTab: PatientDetailPopOverTabValueTypes
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, selectedTab } = props
  const visible = value === selectedTab

  return (
    <div
      role="tabpanel"
      hidden={!visible}
      id={`appointment-tabpanel-${value}`}
      aria-labelledby={`appointment-tab-${value}`}
    >
      {visible && <Box p={3}>{children}</Box>}
    </div>
  )
}

export const PatientDetailPopover: FC<PatientDetailPopoverProps> = props => {
  const { classes } = useStyles()
  const [selectedTab, setSelectedTab] = useState<PatientDetailPopOverTabValueTypes>(
    props.activeTabs[0].value
  )

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Popover
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: classes.popover,
      }}
    >
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          minWidth: 80,
          minHeight: 0,
        }}
      >
        {props.activeTabs.map((v, i) => (
          <Tab
            label={v.label}
            value={v.value}
            key={i}
            sx={{
              minWidth: 80,
              minHeight: 0,
            }}
          />
        ))}
      </Tabs>
      {props.activeTabs.map((tabs, i) => (
        <TabPanel value={tabs.value} selectedTab={selectedTab} key={i}>
          <tabs.component patient={props.patient} userSegmentation={props.userSegmentation} />
        </TabPanel>
      ))}
    </Popover>
  )
}

const useStyles = makeStyles()(() => ({
  popover: {
    maxWidth: 450,
  },
}))

export default PatientDetailPopover
