import {
  ENQUEUE_NOTIFICATION,
  CLOSE_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../actions/notifications'

interface Notification {
  dismissed: boolean
  key: any
  message: string
}

export interface NotificationsState {
  notifications: Notification[]
}

const defaultState: NotificationsState = {
  notifications: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      }

    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      }

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key),
      }

    default:
      return state
  }
}
