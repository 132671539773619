import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tasksSlice from '~/redux/slices/tasks'
import InlineTask from '~/components/Tasks/InlineTask'

interface FormTaskProps {
  taskIds: number[]
}

const FormTasks = (props: FormTaskProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(tasksSlice.thunks.getAll({ id__in: props.taskIds.join(',') }))
  }, [...props.taskIds])

  const tasks = useSelector(state => props.taskIds.map(t => state.tasks2.byId[t]).filter(t => t))

  return (
    <Box>
      {tasks.map(task => (
        <InlineTask
          key={task.id}
          task={task}
          onChecked={isComplete => dispatch(tasksSlice.thunks.update(task.id!, { isComplete }))}
        />
      ))}
    </Box>
  )
}

export default FormTasks
