import { createContext } from 'react'
import { ReactReduxContextValue } from 'react-redux'
import { routerMiddleware } from 'connected-react-router'
import { AnyAction, getDefaultMiddleware, configureStore, PreloadedState } from '@reduxjs/toolkit'
import { enableMapSet } from 'immer'

import history from '~/utils/history'
import { auth0Deauthenticate } from '~/utils/auth'
import { rootReducer, RootState } from '~/redux/reducers'

export type { RootState }

// Enable Immer's MapSet plugin.
// This must be called on the same Immer that is used by @reduxjs/toolkit.
// Tests in redux.test.js should ensure this.
// Note that this is not recommended: see https://github.com/reduxjs/redux-toolkit/issues/466
// However, we currently store many unserializable values in the store aside from Maps and Sets, so setting
// `serializableCheck: true` at this point in time is unrealistic.
enableMapSet()

// When logging out, reset redux to its initial state
const reducer = (state, action) => {
  if (action.type === auth0Deauthenticate.type) {
    state = undefined
  }
  return rootReducer(state, action)
}

export const createStore = (preloadedState?: PreloadedState<RootState>, testReducer = reducer) =>
  configureStore({
    preloadedState,
    devTools: process.env.NODE_ENV === 'development' ? { serialize: true } : false,
    reducer: testReducer,
    middleware: getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: [
          'chat.pubnub', // Client stores some additional telemetry data.
        ],
      },
      serializableCheck: false,
    }).concat([routerMiddleware(history)]),
  })

export const globalContext = createContext({} as ReactReduxContextValue<any, AnyAction>)
