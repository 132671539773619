import { Box, Typography } from '@mui/material'
import { Coverage, InsuranceInfo, PatientUser, useMutation } from '~/legacy/core'
import EditMembershipForm, { PatientReferralProgram } from './EditMembershipForm'
import { batch, useDispatch } from 'react-redux'
import { identity, omit, pickBy, update } from 'lodash/fp'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '~/components/Providers/ApiProvider'
import { BehindProviderFeatureAccessCheck } from '~/legacy/core'
import EditAddressForm from './EditAddressForm'
import EditInsuranceForm from './EditInsuranceForm'
import EditMedicalEquipment from './EditMedicalEquipment'
import EditPatientCareTeam from './EditPatientCareTeam'
import EditPatientForm from './EditPatientForm'
import type { FC } from 'react'
import { IEligibilityCheckResult } from './EligibilityCheckResponseDetails'
import { apiClient } from '~/api/rest'
import { difference } from './utils'
import { patientsSlice } from '~/redux/slices/patients'
import { queueNotification } from '~/redux/actions/notifications'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { usePatientReferralProgram } from '../utils/usePatientReferralProgram'

interface EditPatientProps {
  patient: PatientUser
}

const ADDRESS_PATH = 'addresses[0]'

const cleanAddress = pickBy(identity)

const useEligibilityData = patientId => {
  return useQuery(
    ['useEligibilityData', patientId],
    () => apiClient.rest.get<IEligibilityCheckResult | null>(`/auto_eligibility/time/${patientId}`),
    {
      staleTime: Infinity,
    },
    {
      error: 'Failed to load eligibility',
    }
  )
}

export const EditPatient: FC<EditPatientProps> = props => {
  const { handler, result } = useMutation<[void], Coverage[]>(() =>
    apiClient.rest.get('/user/coverage/statuses/')
  )
  // Load all possible coverage statuses
  useEffect(() => {
    handler()
  }, [])
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const featureAccess = useFeatureAccess()
  const { data: lastEligibilityResult, refetch: refetchEligibilityData } = useEligibilityData(
    props.patient.id
  )

  const { isLoading: loadingPatientReferralProgram, data: patientReferralProgram } =
    usePatientReferralProgram(props.patient)

  const handleEligibilityCheck = useCallback(
    async (values: PatientUser) => {
      const { id, person } = values
      // Child form component chooses coverage by name, so only POST full object
      const coverage = (result || []).find(c => c.name === person.coverage.name)
      try {
        setLoading(true)
        await batch(async () => {
          if (person.insuranceInfo) {
            // Save any insurance changes before submitting eligibility
            await handleInsuranceSubmit(values)
            const updatedInsuranceInfo = difference(values, props.patient)
            await apiClient.rest.patch<InsuranceInfo>(
              `/insurance/member/${person.insuranceInfo.id}`,
              {
                id: person.insuranceInfo.id,
                ...omit(
                  ['imageFront', 'imageBack'],
                  updatedInsuranceInfo.person ? updatedInsuranceInfo.person.insuranceInfo : null
                ),
              }
            )
            await apiClient.rest.post<any>(`/user/${id}/coverage/check_eligibility/`, coverage)
            await refetchEligibilityData()

            batch(() => {
              dispatch(patientsSlice.thunks.getPatient(id))
              dispatch(
                queueNotification({
                  variant: 'success',
                  message: 'Patient insurance eligibility updated',
                })
              )
            })
          }
        })
      } catch (e) {
        console.error(e)
        dispatch(
          queueNotification({
            variant: 'error',
            message: 'Problem checking patient insurance coverage',
          })
        )
      } finally {
        setLoading(false)
      }
    },
    [props.patient, result]
  )

  // This is a partial update to insuranceInfo in the backend
  const handleAddressSubmit = useCallback(
    async (values: PatientUser) => {
      const { person } = values
      update(ADDRESS_PATH, cleanAddress, values.person.insuranceInfo as InsuranceInfo)

      try {
        setLoading(true)
        if (person.insuranceInfo) {
          const updatedAddress = difference(values, props.patient)
          await dispatch(
            patientsSlice.thunks.updatePatientInsuranceAddress({
              patientId: props.patient.id,
              insuranceInfo: {
                id: person.insuranceInfo.id,
                addresses: updatedAddress.person.insuranceInfo.addresses,
              },
            })
          )
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [props.patient, result]
  )

  const handleInsuranceSubmit = useCallback(
    async (values: PatientUser) => {
      const { id, person } = values
      // Child form component chooses coverage by name, so only POST full object
      const coverage = (result || []).find(c => c.name === person.coverage.name)
      try {
        setLoading(true)
        if (person.insuranceInfo) {
          const updatedInsuranceInfo = difference(values, props.patient)
          await dispatch(
            patientsSlice.thunks.updatePatientInsurance({
              patientId: id,
              // Omit fields from insurance object to allow safe submission
              // Omit 'addresses' b/c that data is updated in handleAddressSubmit
              insuranceInfo: {
                id: person.insuranceInfo.id,
                ...omit(
                  ['imageFront', 'imageBack', 'addresses'],
                  updatedInsuranceInfo.person ? updatedInsuranceInfo.person.insuranceInfo : null
                ),
              },
            })
          )
          if (coverage && coverage.status !== props.patient.person.coverage.status) {
            await dispatch(
              patientsSlice.thunks.updatePatientInsuranceCoverage(coverage as Coverage, id)
            )
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [props.patient, result]
  )

  const handlePatientDetailsSubmit = useCallback(
    async (values: PatientUser) => {
      const { id, firstName, lastName, person, phoneNumber, email } = values
      if (Array.isArray(person.gender) && !person.gender.length) {
        person.gender = null
      }

      try {
        setLoading(true)
        if (values.person.insuranceInfo) {
          const newPerson = { ...person, avatar: undefined, avatarLarge: undefined }
          await dispatch(
            patientsSlice.thunks.updatePatient({
              email,
              firstName,
              id,
              lastName,
              person: newPerson,
              phoneNumber,
            })
          )
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [props.patient, result]
  )

  const handleMembershipInfoSubmit = useCallback(
    async (values: PatientReferralProgram) => {
      const { patientReferralProgram } = values
      try {
        setLoading(true)
        if (values.patientReferralProgram) {
          const payload = {
            patientReferralProgram: patientReferralProgram,
          }
          await apiClient.rest.post(
            `/patient-referral-programs/patients/${props.patient.id}/`,
            payload
          )
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [props.patient, result]
  )

  return (
    <Box p={2} border={1} borderLeft={0} borderRight={0} borderTop={0} borderColor="grey.300">
      <Box mb={1}>
        <Typography variant="h6">Basic Info</Typography>
      </Box>
      <EditPatientForm
        handleSubmit={handlePatientDetailsSubmit}
        loading={loading}
        patient={props.patient}
      />
      <Box mb={1}>
        <Typography variant="h6">Membership Info</Typography>
      </Box>
      <EditMembershipForm
        handleSubmit={handleMembershipInfoSubmit}
        loading={loadingPatientReferralProgram}
        patientReferralProgram={patientReferralProgram ?? {}}
      />
      <Box mt={3} mb={1}>
        <Typography variant="h6">Address</Typography>
      </Box>
      <Box mt={3} mb={1}>
        <EditAddressForm
          handleSubmit={handleAddressSubmit}
          loading={loading}
          patient={props.patient}
        />
        <BehindProviderFeatureAccessCheck
          feature="insuranceAndEligibility"
          featureAccess={featureAccess}
        >
          <EditInsuranceForm
            existingCoverage={result || []}
            handleInsuranceSubmit={handleInsuranceSubmit}
            handleEligibilityCheck={handleEligibilityCheck}
            loading={loading}
            patient={props.patient}
            lastEligibilityCheckData={lastEligibilityResult || null}
          ></EditInsuranceForm>
        </BehindProviderFeatureAccessCheck>
      </Box>

      <BehindProviderFeatureAccessCheck feature="carePlans" featureAccess={featureAccess}>
        <Box mt={3} mb={1}>
          <EditPatientCareTeam patient={props.patient} />
        </Box>
      </BehindProviderFeatureAccessCheck>

      <EditMedicalEquipment patient={props.patient} />
    </Box>
  )
}

export default EditPatient
