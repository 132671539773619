import { formatDateWithDay } from '~/utils/date'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import {
  Typography,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from '@material-ui/core'

export interface IDirectMailerEvent {
  type: string
  user: number
  metadata: {
    recordId: string
    mailType: string
    status: string
    updatedBy: number | null
    timestamp: number
    reasonDescription: string
  }
}

const DirectMailerListItem = (props: {
  event: IDirectMailerEvent
  providerName: string | null
  timestampDate: any
}) => {
  const { palette } = useTheme()

  const notError = ['sent', 'acknowledged', 'requested']
  const isStatusError = !notError.some(status => status === props.event.metadata.status)
  const textColor =
    props.event.metadata.status === 'sent' || isStatusError
      ? palette.text.primary
      : palette.grey[500]

  const status =
    props.event.metadata.status.charAt(0).toUpperCase() + props.event.metadata.status.slice(1)

  const timestampString =
    status + ' by ' + props.providerName + ' on ' + formatDateWithDay(props.timestampDate.toDate())

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          title="Direct Mailer"
          alt="Direct Mailer"
          style={{
            background: 'transparent',
            borderColor: isStatusError ? palette.error.main : palette.grey[500],
            borderWidth: '2px',
            borderStyle: 'solid',
          }}
        >
          {isStatusError ? (
            <ErrorOutlineIcon style={{ color: palette.error.main }} />
          ) : (
            <MailOutlineIcon style={{ color: palette.grey[500] }} />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText style={{ color: textColor }}>
        <Typography variant="body1" title={props.event.metadata.mailType}>
          <strong>{props.event.metadata.mailType}</strong>&nbsp;
        </Typography>
        <Typography variant="caption">{timestampString}</Typography>
      </ListItemText>
    </ListItem>
  )
}

export default DirectMailerListItem
