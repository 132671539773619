import { Box, Button, ListItem, ListItemIcon, ListItemText, List } from '@mui/material'
import { ICarePlan } from '~/models/CarePlan'
import FFModal from '~/components/FFModal/FFModal'
import { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { useCaseCategoriesById } from '~/api/CaseService'
export interface ICarePlanActionModalProps {
  carePlan: ICarePlan
  action: string
  onCarePlanActionChange: (action: string) => void
  closeModal: () => void
}

const CarePlanActionModal = (props: ICarePlanActionModalProps) => {
  const { carePlan, onCarePlanActionChange, action, closeModal } = props
  const [loading, setLoading] = useState<boolean>(false)
  const activeTasks = carePlan.tasks.filter(task => !task.isComplete)
  const { result: caseCategoriesById } = useCaseCategoriesById()
  const openCases = carePlan.cases
  const acknowledged = () => {
    setLoading(true)
    try {
      onCarePlanActionChange(action)
      setLoading(false)
      closeModal()
    } catch (error) {
      setLoading(false)
    }
  }
  return (
    <FFModal open={true}>
      <>
        <Box m={2}>
          <h1>
            <b>Are you sure you want to deactivate this care plan?</b>
          </h1>
          {activeTasks.length > 0 && (
            <div>
              <h3>
                <b>{activeTasks.length} task(s) will be deleted:</b>
              </h3>
              <List>
                {activeTasks.map(task => {
                  return (
                    <ListItem key={task.id}>
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <b>{task.title}</b>
                      </ListItemText>
                    </ListItem>
                  )
                })}
              </List>
            </div>
          )}
          {openCases.length > 0 && (
            <div>
              <h3>
                <b>{openCases.length} case(s) will be unlinked:</b>
              </h3>
              <List>
                {openCases.map(openCase => {
                  return (
                    <ListItem key={openCase.id}>
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <b>{caseCategoriesById[openCase.category]?.title ?? ''}</b>
                      </ListItemText>
                    </ListItem>
                  )
                })}
              </List>
            </div>
          )}

          <Box m={2} display="flex" justifyContent="flex-end">
            <Button onClick={() => closeModal()} disabled={loading}>
              Cancel
            </Button>
            <Button
              onClick={() => acknowledged()}
              disabled={loading}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </Box>
        </Box>
      </>
    </FFModal>
  )
}

export default CarePlanActionModal
