import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pick, sortBy } from 'lodash/fp'
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
} from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'

import macros from '~/redux/slices/macros'
import { Alert, Box } from '@mui/material'

// Strip any leading periods and all whitespace from macro name.
const cleanMacroValue = value => ({
  ...value,
  name: value.name.replace(/^\.+/, '').replace(/\s/, ''),
})

const getNewMacro = () => ({
  name: '',
  template: '',
})

const MacroEditorRow = props => {
  const dispatch = useDispatch()

  const [value, setValue] = useState(props.value || getNewMacro())
  return (
    <TableRow style={{ verticalAlign: 'top' }}>
      <TableCell component="th" scope="row">
        <TextField
          placeholder=".macro"
          value={value.name}
          onChange={e => setValue({ ...value, name: e.target.value })}
        />
      </TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>
        <TextField
          fullWidth
          placeholder="Add content"
          multiline
          value={value.template}
          onChange={e => setValue({ ...value, template: e.target.value })}
        />
      </TableCell>

      <TableCell align="right">
        {props.isNew ? (
          <Button
            onClick={() => {
              dispatch(macros.thunks.create(cleanMacroValue(value)))
              setValue(getNewMacro())
            }}
          >
            Add
          </Button>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              onClick={async () => {
                dispatch(
                  macros.thunks.update(value.id, cleanMacroValue(pick(['name', 'template'], value)))
                )
                props.onFinish()
              }}
            >
              <SaveIcon />
            </IconButton>
            <Button onClick={props.onFinish}>Cancel</Button>
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

const MacroDisplayRow = props => {
  const dispatch = useDispatch()

  const row = props.macro
  return (
    <TableRow key={row.name} style={{ verticalAlign: 'top' }}>
      <TableCell component="th" scope="row" style={{ fontWeight: 700 }}>
        {'.'}
        {row.name}
      </TableCell>
      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{row.template}</TableCell>

      <TableCell align="right">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={props.onEdit}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => dispatch(macros.thunks.delete(row.id))}>
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  )
}

const MacroRow = props => {
  const [editing, setEditing] = useState(false)

  return !editing ? (
    <MacroDisplayRow {...props} onEdit={() => setEditing(true)} />
  ) : (
    <MacroEditorRow {...props} value={props.macro} onFinish={() => setEditing(false)} />
  )
}

const MacroSettings = () => {
  const dispatch = useDispatch()
  const meId = useSelector<any, number>(state => state.me.id)

  useEffect(() => {
    dispatch(macros.thunks.getAll({ createdBy: meId }))
  }, [])

  const macrosById = useSelector<any, any>(state => state.macros.byId)

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div style={{ display: 'flex' }}>
        <Typography style={{ flex: 1 }} variant="h6">
          My Macros
        </Typography>

        {/* <TextField placeholder="Filter" /> */}
      </div>

      <Box my={1}>
        <Alert color="info" variant="outlined">
          Tip: use &apos;&#123;patient.name&#125;&apos; in a macro to use the member&apos;s
          preferred name if one exists, or their first name if not
        </Alert>
      </Box>

      <div style={{ flex: 1, overflow: 'scroll' }}>
        {macrosById ? (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Text</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortBy('name', Object.values(macrosById)).map((row: any) => (
                <MacroRow key={row.id} macro={row} />
              ))}
              <MacroEditorRow isNew />
            </TableBody>
          </Table>
        ) : null}
      </div>
    </Box>
  )
}

export default MacroSettings
