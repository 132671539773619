import { apiClient, partialJsonParamSerializer } from '~/api/rest'
import { linkToCaseForPerson } from '~/components/Cases/utils'
import { WorklistWorkUnit } from '~/models/PatientTodoItem'
import { WorkUnit } from '~/models/WorkUnit'
import { queueNotification } from '~/redux/actions/notifications'
import { workUnitsSlice } from '.'
import { setDueDateToEOD } from '~/utils/date'

export const getWorklistWorkUnitItems = (worklistId: string, options: any = undefined) => {
  return async dispatch => {
    try {
      const { data } = await apiClient.rest.http.request({
        url: `/providers/me/work_units/worklists/${worklistId}/`,
        method: 'GET',
        params: options,
        paramsSerializer: partialJsonParamSerializer('filters', 'sort'),
      })
      const workUnitData = data.results ? (data.results as WorkUnit[]) : []
      let workUnits: WorklistWorkUnit[] = workUnitData.map(workUnit => {
        return {
          ...workUnit,
          worklist: parseInt(worklistId),
          id: workUnit.contentObject.id,
          pk: workUnit.contentObject.id,
        }
      })
      const count = data.count ? data.count : 0
      return dispatch(
        workUnitsSlice.actions.getWorkUnitsSuccess({
          worklistId: worklistId,
          items: workUnits,
          count: count,
        })
      )
    } catch (error) {
      console.log(error)
      dispatch(
        queueNotification({
          variant: 'error',
          message: `Worklist: Error fetching worklist items`,
        })
      )
    }
  }
}

export const updateWorklistWorkUnitItem = (workUnit: Partial<WorklistWorkUnit>) => {
  return async dispatch => {
    try {
      const updatedWorkUnitItem: WorkUnit = await apiClient.rest.patch(
        `/providers/me/work_units/${workUnit.id}/${workUnit.contentType}/`,
        {
          dueDate: workUnit.dueDate ? setDueDateToEOD(workUnit.dueDate) : undefined,
          ownerGroup: workUnit.ownerGroup,
          action: workUnit.action,
        }
      )
      const userId = updatedWorkUnitItem.contentObject.personMeta?.userId!
      const personId = updatedWorkUnitItem.contentObject.personMeta?.id!
      const caseId = updatedWorkUnitItem.contentObject.id
      const worklistWorkUnitItem: WorklistWorkUnit = {
        ...updatedWorkUnitItem,
        worklist: workUnit.worklist!,
        id: caseId,
        pk: caseId,
        caseUrl: linkToCaseForPerson(personId, caseId),
        statusCategory: updatedWorkUnitItem.contentObject.statusCategory!,
        personUser: userId,
      }
      return dispatch(
        workUnitsSlice.actions.patchWorkUnitSuccess({ workUnit: worklistWorkUnitItem })
      )
    } catch (error) {
      console.log(error)
      dispatch(
        queueNotification({
          variant: 'error',
          message: `Worklist: Error updating worklist WorkUnit item: ${workUnit.id}.`,
        })
      )
    }
  }
}
