import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
// eslint-disable-next-line
import * as MUI from '@material-ui/core'
import Select, { GroupedOptionsType } from 'react-select'

import { apiClient } from '~/api/rest'
import { useMutation } from '~/utils/useMutation'
import FFModal from '../FFModal/FFModal'
import PriorityToggle from '../Generic/PriorityToggle'
import { selectCustomStyles } from '../Generic/selectCustomStyles'
import { useEducationModal } from './utils'

type FeedbackOption = { label: string; value: string | null }
type FeedbackOptions = GroupedOptionsType<FeedbackOption>

const CATEGORY_OPTIONS: FeedbackOption[] = [
  'Additional information needed',
  'Information is incorrect',
  "Information isn't relevant for this patient",
  'Typo',
].map(t => ({ label: t, value: t }))

const EducationFeedbackForm: FC = () => {
  const { setShowFeedback, result, page } = useEducationModal()
  const classes = useStyles()
  const handleClose = useCallback(() => setShowFeedback(false), [])

  // Form state
  const [title, setTitle] = useState<FeedbackOption | null>(
    // If the modal is opened when viewing a piece of content, make it the title
    page ? { label: page.fields.title, value: page.sys.id } : null
  )
  const [urgency, setUrgency] = useState(0)
  const [text, setText] = useState('')
  const [category, setCategory] = useState<FeedbackOption | null>(null)
  const isValid = !!text.length && !!title && !!category

  // Submit feedback and close the modal
  const {
    handler,
    loading,
    result: resolved,
  } = useMutation(
    () =>
      apiClient.rest.post(`/education-chassis/feedback/`, {
        category: category?.value,
        contentId: title?.value,
        contentTitle: title?.label,
        text,
        urgency,
      }),
    { error: 'Failed to submit feedback', success: 'Feedback submitted' }
  )

  // Running handleClose inside of the useMutation instance causes the
  // component to unmount before success/error notifications can be fired,
  // so trigger it when the api call resolves
  useEffect(() => {
    if (resolved) {
      setTimeout(handleClose, 0)
    }
  }, [resolved])

  const topicOptions: FeedbackOptions = [
    { label: 'General', options: [{ label: 'General Feedback', value: null }] },
    {
      label: 'Articles',
      options: (result?.items ?? []).map(item => ({
        label: item.fields.title,
        value: item.sys.id,
      })),
    },
  ]

  return (
    <>
      <MUI.Box m={2}>
        <MUI.Typography variant="h6">Make a suggestion</MUI.Typography>
      </MUI.Box>
      <MUI.Divider />
      <MUI.Box m={2}>
        <MUI.Box display="flex" mb={2}>
          <Select
            disabled={loading}
            value={title}
            onChange={setTitle}
            options={topicOptions}
            styles={selectCustomStyles}
            className={classes.select}
            placeholder="Topic"
            menuPlacement="auto"
            menuPortalTarget={document.body}
          />
          <PriorityToggle priority={urgency} onChange={setUrgency} />
        </MUI.Box>
        <MUI.Box mb={2}>
          <MUI.TextField
            disabled={loading}
            value={text}
            onChange={e => setText(e.target.value)}
            multiline
            label="What can be improved? Be as descriptive as possible."
            variant="filled"
            fullWidth
            rows={6}
          />
        </MUI.Box>
        <Select
          disabled={loading}
          value={category}
          onChange={setCategory}
          options={CATEGORY_OPTIONS}
          styles={selectCustomStyles}
          placeholder="Feedback type"
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
      </MUI.Box>
      <MUI.Divider />
      <MUI.Box display="flex" alignItems="center" justifyContent="flex-end" p={2}>
        <MUI.Button variant="text" onClick={handleClose}>
          Cancel
        </MUI.Button>
        <MUI.Box ml={1}>
          <MUI.Button
            onClick={handler}
            disabled={!isValid || loading}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </MUI.Button>
        </MUI.Box>
      </MUI.Box>
    </>
  )
}

const useStyles = MUI.makeStyles(() => ({
  select: { width: '100%' },
}))

// Used as entrypoint for feedback form so state
// can be reset once the form is submitted
export const EducationFeedbackModal: FC = () => {
  const { showFeedback } = useEducationModal()
  return (
    <FFModal open={showFeedback} keepMounted={false}>
      <EducationFeedbackForm />
    </FFModal>
  )
}

export default EducationFeedbackModal
