import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { Button, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { format, addDays } from 'date-fns'

import DateAndRelativeDateInput from '~/components/Careplans/DateAndRelativeDateInput'
import FormSelect from '~/components/Generic/FormSelect'
import { getIsVirtualTask } from './utils'

interface FormSubmissionCreatorProps {
  patient: any
  careTeam: any
  loading: boolean
  onTaskTemplateSubmit: (template: any) => void
  onFormSubmit: (form: any) => void
}

// By default, the Task to complete a FormSubmission should be due today
const DEFAULT_DUE_DATE_OFFSET = 0

const FormSubmissionCreator: FC<FormSubmissionCreatorProps> = props => {
  const { classes } = useStyles()

  const [form, setForm] = useState<any | null>(null)
  const [dueDate, setDueDate] = useState<string>(
    format(addDays(new Date(), DEFAULT_DUE_DATE_OFFSET), 'YYYY-MM-DD')
  )

  useEffect(() => {
    if (!form) return
    const offset =
      form.defaultDueDateOffset != null ? form.defaultDueDateOffset : DEFAULT_DUE_DATE_OFFSET
    setDueDate(format(addDays(new Date(), offset), 'YYYY-MM-DD'))
  }, [form])

  // Shorthand to disable form-specific inputs
  const isVirtualTask = getIsVirtualTask(form)

  const onSubmit = () => {
    // If it's a virtual task, target a different prop callback
    if (isVirtualTask) {
      props.onTaskTemplateSubmit(form)
    } else {
      props.onFormSubmit({
        form,
        dueDate,
      })
    }
  }

  return (
    <Box>
      <h3>Assign new form</h3>
      <Box display="flex" flexWrap="wrap" mb={1}>
        <Box display="flex" minWidth={300} flex="1 1 auto" mb={1} mr={1}>
          <FormSelect className={classes.formSelect} value={form} onChange={setForm} />
        </Box>
        <Box display="flex" minWidth={200}>
          <DateAndRelativeDateInput classes={classes} value={dueDate} onChange={setDueDate} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={onSubmit}
          disabled={!form || !dueDate || props.loading}
          variant="contained"
          color="primary"
        >
          Assign to Patient
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()(theme => ({
  formSelect: {
    flex: 1,
  },
  taskSplitRelativeDate: {
    flex: 0.3,
  },
  taskSplitLeft: {
    flex: 0.5,
  },
  assigneeSelect: {
    minWidth: 250,
  },
}))

export default FormSubmissionCreator
