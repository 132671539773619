import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Autocomplete,
  TextField,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { makeStyles } from 'tss-react/mui'
import {
  getSchedules,
  useUpdateIngestionJobStatus,
  useCreateIngestionJob,
  useCreateOrUpdateStagingShift,
  useDeleteStagingShift,
  useGetIngestionJob,
  useCreateOrUpdateStagingShiftException,
  useDeleteStagingShiftException,
  getSchedulesForProvider,
  getAppointmentTypes,
  useCreateOrUpdateVisitMix,
  useUpdateVisitMix,
} from '~/api/ScheduleService'
import {
  Shift,
  StagingShift,
  Schedule,
  IngestionImpact,
  StagingShiftException,
  AppointmentType,
  VisitMixCreate,
  VisitMix,
  Provider,
  ShiftException,
  TAB_KEYS,
  EXCEPTIONSTAB,
  APPROVED_STATUS,
  REJECTED_STATUS,
  TABS,
  getShifts,
  sortShiftFunction,
  getShiftExceptions,
  sortShiftExceptionFunction,
  formatPeriodDate,
  formatPeriodTime,
  formatTime,
  formatEffectivePeriod,
  formatProviderStatus,
  getIsAllDay,
} from './ProviderShift/utils'
import { useMutation } from '~/utils/useMutation'
import Loader from '../Loader'
import EditShiftTimingsModal from './ProviderShift/EditShiftTimingsModal'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import EditShiftExceptionTimingsModal from './ProviderShift/EditShiftExceptionTimingsModal'
import EditVisitMixModal from './ProviderShift/EditVisitMixModal'
import { yellow } from '@mui/material/colors'
import { VISIT_REASON } from '~/constants/visitReasons'

const ProviderShiftSettings = () => {
  const editSchedule: boolean = useFeatureFlag('schedule.editShifts')

  const [providerFilter, setProviderFilter] = useState<Record<string, Schedule>>({})
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectedTab, setSelectedTab] = useState(TAB_KEYS[0])
  const [shiftsData, setShiftsData] = useState<Shift[]>([])
  const [shiftExceptionsData, setShiftExceptionsData] = useState<ShiftException[]>([])
  const [visitMixData, setVisitMixData] = useState({})
  const [appointmentTypesData, setAppointmentTypesData] = useState<AppointmentType[]>([])
  const [ingestionJobId, setIngestionJobId] = useState<number | null>(null)
  const [currentShiftsData, setCurrentShiftsData] = useState<Shift[]>([])
  const [editingShiftsData, setEditingShiftsData] = useState<Shift[] | null>(null)
  const [editingShiftExceptionsData, setEditingShiftExceptionsData] = useState<
    ShiftException[] | null
  >(null)
  const [editingVisitMixData, setEditingVisitMixData] = useState<{} | null>(null)
  const [isVisitMixEdited, setIsVisitMixEdited] = useState<boolean>(false)
  const [isIngestionInProgress, setIsIngestionInProgress] = useState<boolean>(false)
  const [providerData, setProviderData] = useState<Provider>()
  const [scheduleId, setScheduleId] = useState<number>()
  const [impactData, setImpactData] = useState<IngestionImpact>()
  const { classes } = useStyles()

  const { isLoading: isUpdatingIngestionJobStatus, mutateAsync: handleUpdateIngestionJobStatus } =
    useUpdateIngestionJobStatus()
  const { isLoading: isUpdatingVisitMix, mutateAsync: handleUpdateVisitMix } = useUpdateVisitMix()

  const { isLoading: isCreatingIngestionJob, mutateAsync: handleCreateIngestionJob } =
    useCreateIngestionJob()
  const { isLoading: isGettingIngestionJobImpact, mutateAsync: handleGetIngestionJobImpact } =
    useGetIngestionJob()

  const {
    isLoading: isUpdatingIngestionJobShift,
    mutateAsync: handleCreateOrUpdateIngestionJobShift,
  } = useCreateOrUpdateStagingShift()
  const { isLoading: isDeletingIngestionJobShift, mutateAsync: handleDeleteIngestionJobShift } =
    useDeleteStagingShift()

  const {
    isLoading: isUpdatingIngestionJobShiftException,
    mutateAsync: handleCreateOrUpdateIngestionJobShiftException,
  } = useCreateOrUpdateStagingShiftException()
  const {
    isLoading: isDeletingIngestionJobShiftException,
    mutateAsync: handleDeleteIngestionJobShiftException,
  } = useDeleteStagingShiftException()

  const { isLoading: isUpdatingVisitMixRatio, mutateAsync: handleCreateOrUpdateVisitMixRatio } =
    useCreateOrUpdateVisitMix()

  const handleTabChange = (_: any, newValue) => {
    setSelectedTab(newValue)
    if (newValue !== EXCEPTIONSTAB)
      setCurrentShiftsData(shiftsData.filter(shift => shift.dayOfWeek == TABS[newValue].dayOfWeek))
  }

  const { loading: loadingSchedules, handler: loadSchedules } = useMutation(async () => {
    const result = await getSchedules()
    const apptTypes = await getAppointmentTypes()
    // Excluding appointment types which shouldn't be allowed to add Visit Mix ratio,
    // as they would share the percentage of Video or Video-New
    const filteredAppointmentTypes = apptTypes.filter(
      ({ uniqueKey }) =>
        ![VISIT_REASON.focused, VISIT_REASON.awvEstablished, VISIT_REASON.awvNew].includes(
          uniqueKey
        )
    )

    setAppointmentTypesData(filteredAppointmentTypes)
    const providerFilterData: Record<string, Schedule> = {}
    result.forEach(schedule => {
      const providerName = schedule.provider.firstName + ' ' + schedule.provider.lastName
      providerFilterData[providerName] = schedule
    })
    setProviderFilter(providerFilterData)
  })

  const { loading: loadingScheduleForProvider, handler: loadSchedulesForProvider } = useMutation(
    async (provider: string) => {
      if (providerFilter) {
        const result = await getSchedulesForProvider(providerFilter[provider].provider.pk)
        await setProviderFilter(prevProviderFilter => {
          return { ...prevProviderFilter, [provider]: result[0] }
        })
      }
    }
  )

  const handleEditProvider = (provider: string) => {
    const data = providerFilter[provider]
    setProviderData(data.provider)
    setScheduleId(data.id)
    let allShifts: Shift[]
    let allShiftExceptions: ShiftException[]
    if (data.ingestionJobs.length > 0 && data.ingestionJobs[0].id) {
      if (data.ingestionJobs[0].reviewStatus == APPROVED_STATUS) {
        setIsIngestionInProgress(true)
        setIngestionJobId(null)
      } else {
        setIngestionJobId(data.ingestionJobs[0].id)
        setIsIngestionInProgress(false)
        updateIngestionJobImpact(data.ingestionJobs[0].id)
      }
      allShifts = getShifts(data.ingestionJobs[0].stagingShifts).sort(sortShiftFunction)
      allShiftExceptions = getShiftExceptions(data.ingestionJobs[0].stagingShiftExceptions).sort(
        sortShiftExceptionFunction
      )
    } else {
      setIngestionJobId(null)
      setIsIngestionInProgress(false)
      allShifts = data.shifts
        .map(shift => {
          return { ...shift, isEdited: false }
        })
        .sort(sortShiftFunction)
      allShiftExceptions = data.shiftExceptions
        .map(shiftException => {
          return {
            ...shiftException,
            isEdited: false,
            isAllDay: getIsAllDay(shiftException.period),
          }
        })
        .sort(sortShiftExceptionFunction)
    }
    setShiftsData(allShifts)
    setShiftExceptionsData(allShiftExceptions)
    setSelectedTab(TAB_KEYS[0])
    setCurrentShiftsData(allShifts.filter(shift => shift.dayOfWeek === TABS[TAB_KEYS[0]].dayOfWeek))
    const visitMixRatios: Record<number, Record<string, VisitMix>> = {}
    Object.keys(TABS).forEach(day => (visitMixRatios[TABS[day].dayOfWeek] = {}))
    data.appointmentTypeMappings.forEach(mapping => {
      const appointmentType = mapping.appointmentType.uniqueKey
      mapping.visitMixRatios.forEach(visitMix => {
        visitMixRatios[visitMix.dayOfWeek][appointmentType] = {
          id: visitMix.id,
          percentage: visitMix.percentageOfSlots,
          isEdited: false,
        }
      })
    })
    setVisitMixData(visitMixRatios)
  }

  const onProviderChange = (selected: string) => {
    if (selected && providerFilter[selected]) {
      setSelectedProvider(selected)
    } else {
      setSelectedProvider('')
    }
  }

  const handleUpdateIngestionJob = async (reviewStatus: string) => {
    if (
      isVisitMixEdited &&
      providerData &&
      appointmentTypesData &&
      reviewStatus == APPROVED_STATUS
    ) {
      const editedVisitData: VisitMixCreate[] = []
      Object.keys(visitMixData).forEach(dayOfWeek => {
        Object.keys(visitMixData[dayOfWeek]).forEach(apptType => {
          const apptId = appointmentTypesData.find(a => a.uniqueKey === apptType)?.id
          if (apptId && visitMixData[dayOfWeek][apptType].isEdited)
            editedVisitData.push({
              physicianId: providerData.physician,
              appointmentTypeId: apptId,
              dayOfWeek: parseInt(dayOfWeek),
              percentageOfSlots: visitMixData[dayOfWeek][apptType].percentage,
              id: visitMixData[dayOfWeek][apptType].id,
            })
        })
      })
      await Promise.all(
        editedVisitData.map(visitMix => handleCreateOrUpdateVisitMixRatio(visitMix))
      )
      if (!ingestionJobId) await handleUpdateVisitMix(providerData.pk)
    }
    if (ingestionJobId) {
      await handleUpdateIngestionJobStatus({ id: ingestionJobId, reviewStatus })
    }
    loadSchedulesForProvider(selectedProvider)
    setProviderData(undefined)
    setScheduleId(undefined)
    setImpactData(undefined)
  }

  const updateIngestionJobImpact = async (jobId: number) => {
    const ingestionJobImpact = await handleGetIngestionJobImpact(jobId)
    setImpactData(ingestionJobImpact.ingestionImpact)
    const allShifts = getShifts(ingestionJobImpact.stagingShifts).sort(sortShiftFunction)
    setShiftsData(allShifts)
    const allShiftExceptions = getShiftExceptions(ingestionJobImpact.stagingShiftExceptions).sort(
      sortShiftExceptionFunction
    )
    setShiftExceptionsData(allShiftExceptions)
    if (selectedTab !== EXCEPTIONSTAB)
      setCurrentShiftsData(
        allShifts.filter(shift => shift.dayOfWeek === TABS[selectedTab].dayOfWeek)
      )
  }

  const handleCreateOrUpdateShiftIngestionJob = async (
    allShiftsData: Shift[],
    editedShiftsData: Shift[],
    deletedShiftsData: Shift[]
  ) => {
    let jobId = ingestionJobId
    if (ingestionJobId && scheduleId) {
      await Promise.all([
        ...editedShiftsData
          .filter(shift => shift.isEdited)
          .map(shift => {
            let stagingShift: StagingShift = { ...shift, ingestionJobId, scheduleId }
            return handleCreateOrUpdateIngestionJobShift(stagingShift)
          }),
        ...deletedShiftsData.map(shift => handleDeleteIngestionJobShift(shift)),
      ])
    } else if (providerData) {
      const ingestionJob = await handleCreateIngestionJob({
        providerId: providerData.pk,
        stagingShifts: allShiftsData,
        stagingShiftExceptions: shiftExceptionsData,
      })
      setIngestionJobId(ingestionJob.id)
      jobId = ingestionJob.id
    }
    if (jobId) updateIngestionJobImpact(jobId)
  }

  const handleShiftSubmit = (editedShiftsData: Shift[], deletedShiftsData: Shift[]) => {
    const allShiftsData: Shift[] = [
      ...shiftsData.filter(shift => shift.dayOfWeek !== TABS[selectedTab].dayOfWeek),
      ...editedShiftsData,
    ]
    handleCreateOrUpdateShiftIngestionJob(allShiftsData, editedShiftsData, deletedShiftsData)
    setCurrentShiftsData(
      editedShiftsData.map(shift => {
        return { ...shift, isEdited: false }
      })
    )
    setEditingShiftsData(null)
  }

  const handleVisitMixSubmit = visitMix => {
    setVisitMixData(visitMix)
    setIsVisitMixEdited(true)
    setEditingVisitMixData(null)
  }

  const handleCreateOrUpdateShiftExceptionIngestionJob = async (
    editedShiftExceptionsData: ShiftException[],
    deletedShiftExceptionsData: ShiftException[]
  ) => {
    let jobId = ingestionJobId
    if (ingestionJobId && scheduleId) {
      await Promise.all([
        ...editedShiftExceptionsData
          .filter(shiftException => shiftException.isEdited)
          .map(shiftException => {
            let stagingShiftException: StagingShiftException = {
              ...shiftException,
              ingestionJobId,
              scheduleId,
            }
            return handleCreateOrUpdateIngestionJobShiftException(stagingShiftException)
          }),
        ...deletedShiftExceptionsData.map(shiftException =>
          handleDeleteIngestionJobShiftException(shiftException)
        ),
      ])
    } else if (providerData) {
      const ingestionJob = await handleCreateIngestionJob({
        providerId: providerData.pk,
        stagingShifts: shiftsData,
        stagingShiftExceptions: editedShiftExceptionsData,
      })
      setIngestionJobId(ingestionJob.id)
      jobId = ingestionJob.id
    }
    if (jobId) updateIngestionJobImpact(jobId)
  }

  const handleShiftExceptionSubmit = (
    editedShiftExceptionsData: ShiftException[],
    deletedShiftExceptionsData: ShiftException[]
  ) => {
    handleCreateOrUpdateShiftExceptionIngestionJob(
      editedShiftExceptionsData,
      deletedShiftExceptionsData
    )
    setShiftExceptionsData(
      editedShiftExceptionsData.map(shiftException => {
        return { ...shiftException, isEdited: false }
      })
    )
    setEditingShiftExceptionsData(null)
  }

  useEffect(() => {
    loadSchedules()
    setSelectedTab(TAB_KEYS[0])
  }, [])

  return (
    <div className={classes.container}>
      {loadingSchedules ? <Loader /> : null}
      {!providerData ? (
        <Box display="flex-start">
          <Box>
            <Typography variant="h5" fontWeight="500">
              Provider Shifts
            </Typography>
          </Box>
          <Box my={2}>
            <Autocomplete<string>
              size="small"
              disabled={loadingSchedules || loadingScheduleForProvider}
              options={Object.keys(providerFilter).sort()}
              value={selectedProvider}
              renderInput={params => <TextField {...params} label="Provider" />}
              onChange={(_, selected) => {
                onProviderChange(selected || '')
              }}
              style={{ width: 280 }}
            />
          </Box>
          <Box mt={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loadingSchedules || !selectedProvider}
              onClick={() => handleEditProvider(selectedProvider)}
            >
              Edit Provider
            </Button>
          </Box>
          <Box mt={4}>
            <TableContainer>
              <Table id="ingestion-jobs-table" className={classes.root}>
                <TableBody>
                  {Object.keys(providerFilter)
                    .filter(providerName => providerFilter[providerName].ingestionJobs.length)
                    .sort()
                    .map((providerName, i) => (
                      <TableRow key={i} sx={{ backgroundColor: yellow[50] }}>
                        <TableCell className={classes.cell}>
                          <Typography fontSize="1.6rem" fontWeight="500">
                            {formatProviderStatus(
                              providerName,
                              providerFilter[providerName].ingestionJobs[0].reviewStatus
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {providerFilter[providerName].ingestionJobs[0].reviewStatus ==
                          APPROVED_STATUS ? (
                            <Button
                              color="primary"
                              onClick={() => loadSchedulesForProvider(providerName)}
                            >
                              Refresh
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => handleEditProvider(providerName)}
                            >
                              Go to provider
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h5" fontWeight="500">
                {`${providerData.firstName} ${providerData.lastName}`}
              </Typography>
              {isIngestionInProgress ? (
                <Box display="flex" alignItems="center" ml={2} bgcolor={yellow[50]}>
                  <Typography variant="body1" fontWeight="500" mr={1}>
                    Ingestion job in progress
                  </Typography>
                  <Button
                    color="primary"
                    onClick={async () => {
                      await loadSchedulesForProvider(selectedProvider)
                      handleEditProvider(selectedProvider)
                    }}
                  >
                    Refresh
                  </Button>
                </Box>
              ) : null}
            </Box>
            <Box>
              <Button
                color="primary"
                disabled={
                  loadingSchedules ||
                  isUpdatingIngestionJobStatus ||
                  isUpdatingVisitMix ||
                  isUpdatingVisitMixRatio ||
                  isIngestionInProgress
                }
                onClick={() => handleUpdateIngestionJob(REJECTED_STATUS)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  loadingSchedules ||
                  !(isVisitMixEdited || ingestionJobId) ||
                  isUpdatingIngestionJobStatus ||
                  isUpdatingVisitMix ||
                  isUpdatingVisitMixRatio ||
                  isIngestionInProgress ||
                  (impactData ? impactData.validationErrors.length > 0 : false)
                }
                onClick={() => handleUpdateIngestionJob(APPROVED_STATUS)}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mt={1.5}>
            <Typography fontSize="2rem">Visit Template</Typography>
          </Box>
          <Box display="flex" alignItems="baseline" mt={3}>
            <Box>
              <Box display="flex">
                <Tabs
                  textColor="primary"
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={selectedTab}
                  onChange={handleTabChange}
                >
                  {TAB_KEYS.map((v, i) => (
                    <Tab
                      label={v}
                      value={v}
                      key={i}
                      sx={{
                        minWidth: 60,
                        minHeight: 0,
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
              {selectedTab == EXCEPTIONSTAB ? (
                <Box mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography fontSize="1.8rem" fontWeight="500">
                      Exceptions and Time Off
                    </Typography>
                    <Box ml={1}>
                      <IconButton
                        color="secondary"
                        onClick={() => setEditingShiftExceptionsData(shiftExceptionsData)}
                        disabled={loadingSchedules || !editSchedule || isIngestionInProgress}
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.secondary[700],
                          },
                          borderRadius: 'unset',
                        })}
                      >
                        <EditIcon color="inherit" sx={{ fontSize: '1.8rem', fontWeight: 500 }} />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box mt={1}>
                    <TableContainer>
                      <Table id="shift-exceptions-table" className={classes.root}>
                        <TableBody>
                          {shiftExceptionsData.map((v, i) => (
                            <TableRow key={i}>
                              <TableCell className={classes.date_cell}>
                                <Typography fontSize="1.6rem" fontWeight="500">
                                  {formatPeriodDate(v.period)}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.cell}>
                                <Typography fontSize="1.6rem">
                                  {formatPeriodTime(v.period)}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.cell}>
                                <Typography fontSize="1.4rem">{v.reason || 'OOO'}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              ) : (
                <Box mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography fontSize="1.8rem" fontWeight="500">
                      Visit Mix
                    </Typography>
                    <Box ml={1}>
                      <IconButton
                        color="secondary"
                        onClick={() => setEditingVisitMixData(visitMixData)}
                        disabled={loadingSchedules || !editSchedule || isIngestionInProgress}
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.secondary[700],
                          },
                          borderRadius: 'unset',
                        })}
                      >
                        <EditIcon color="inherit" sx={{ fontSize: '1.8rem', fontWeight: 500 }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <TableContainer>
                      {appointmentTypesData &&
                      visitMixData &&
                      selectedTab !== EXCEPTIONSTAB &&
                      visitMixData[TABS[selectedTab].dayOfWeek] ? (
                        <Table id="visit-mix-table" className={classes.root}>
                          <TableHead>
                            <TableRow>
                              {appointmentTypesData.map((apptType, i) => (
                                <TableCell key={i} className={classes.cell}>
                                  <Typography fontSize="1.6rem" fontWeight="500">
                                    {apptType.name}
                                  </Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              {appointmentTypesData.map((apptType, i) => (
                                <TableCell key={i} className={classes.cell}>
                                  <Typography fontSize="1.6rem">{`${
                                    visitMixData[TABS[selectedTab].dayOfWeek][apptType.uniqueKey]
                                      ?.percentage || '–'
                                  }%`}</Typography>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      ) : null}
                    </TableContainer>
                  </Box>
                  <Box mt={2} display="flex" alignItems="center">
                    <Typography fontSize="1.8rem" fontWeight="500">
                      Shifts
                    </Typography>
                    <Box ml={1}>
                      <IconButton
                        color="secondary"
                        onClick={() => setEditingShiftsData(currentShiftsData)}
                        disabled={loadingSchedules || !editSchedule || isIngestionInProgress}
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.secondary[700],
                          },
                          borderRadius: 'unset',
                        })}
                      >
                        <EditIcon color="inherit" sx={{ fontSize: '1.8rem', fontWeight: 500 }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <TableContainer>
                      <Table id="shifts-table" className={classes.root}>
                        <TableBody>
                          {currentShiftsData.map((v, i) => (
                            <TableRow key={i}>
                              <TableCell className={classes.cell}>
                                <Typography fontSize="1.6rem">
                                  {`${formatTime(v.startTime)} – ${formatTime(v.stopTime)}`}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.cell}>
                                <Typography fontSize="1.4rem">
                                  {formatEffectivePeriod(v.effectivePeriod)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              )}
            </Box>
            <Box ml={10}>
              {isGettingIngestionJobImpact ? <Loader /> : null}
              {impactData?.validationErrors.length ? (
                <Box mt={3}>
                  <Typography fontSize="1.8rem" fontWeight="500">
                    Validation Errors
                  </Typography>
                  <Box mt={3} bgcolor={yellow[50]}>
                    {impactData.validationErrors.map((error, i) => (
                      <Typography key={i} fontSize="1.4rem" fontWeight="500">
                        {error}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ) : null}
              <Box mt={4}>
                <Typography fontSize="1.8rem" fontWeight="500">
                  Cancellations
                </Typography>
                {impactData?.impactedAppointments.length ? (
                  <Box mt={3} bgcolor={yellow[50]}>
                    {impactData.impactedAppointments.map((appt, i) => (
                      <Box key={i} display="flex">
                        {appt.patientUrl ? (
                          <Link
                            href={appt.patientUrl}
                            target="blank"
                            sx={{
                              textDecoration: 'none',
                              cursor: 'pointer',
                              transition: '100ms linear color',
                              color: 'inherit',
                              '&:hover': {
                                color: 'primary.main',
                              },
                            }}
                          >
                            <Typography display="inline" fontSize="1.4rem" fontWeight="500">
                              {appt.affectedPatient}
                            </Typography>
                          </Link>
                        ) : (
                          <Typography fontSize="1.4rem" fontWeight="500">
                            {appt.affectedPatient}
                          </Typography>
                        )}
                        <Typography fontSize="1.4rem" fontWeight="500">
                          {`: ${appt.startTime}: ${appt.shortReason}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box mt={3}>
                    <Typography fontSize="1.4rem">None</Typography>
                  </Box>
                )}
                <Box mt={4}>
                  <Typography fontSize="1.8rem" fontWeight="500">
                    Unbooked Slot Deletions
                  </Typography>
                  {impactData?.impactedAppointmentSlots.length ? (
                    <Box mt={3} bgcolor={yellow[50]}>
                      {impactData.impactedAppointmentSlots.map((appt, i) => (
                        <Typography key={i} fontSize="1.4rem" fontWeight="500">
                          {`${appt.startTime}: ${appt.shortReason}`}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    <Box mt={3}>
                      <Typography fontSize="1.4rem">None</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {selectedTab == EXCEPTIONSTAB ? (
            <EditShiftExceptionTimingsModal
              shiftExceptions={editingShiftExceptionsData}
              loading={
                isCreatingIngestionJob ||
                isUpdatingIngestionJobShiftException ||
                isDeletingIngestionJobShiftException
              }
              handleCancel={() => setEditingShiftExceptionsData(null)}
              ingestionJobId={ingestionJobId}
              providerId={providerData.pk}
              handleSubmit={handleShiftExceptionSubmit}
            />
          ) : (
            <>
              <EditShiftTimingsModal
                shifts={editingShiftsData}
                dayOfWeek={TABS[selectedTab].dayOfWeek}
                fullDayName={TABS[selectedTab].fullDayName}
                loading={
                  isCreatingIngestionJob ||
                  isUpdatingIngestionJobShift ||
                  isDeletingIngestionJobShift
                }
                handleCancel={() => setEditingShiftsData(null)}
                handleSubmit={handleShiftSubmit}
              />
              <EditVisitMixModal
                appointmentTypes={appointmentTypesData}
                visitMix={editingVisitMixData}
                dayOfWeek={TABS[selectedTab].dayOfWeek}
                fullDayName={TABS[selectedTab].fullDayName}
                handleCancel={() => setEditingVisitMixData(null)}
                handleSubmit={handleVisitMixSubmit}
              />
            </>
          )}
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  root: {
    '& .MuiTableCell-root': {
      padding: '4px',
    },
  },
  cell: {
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
    paddingLeft: '1em',
    paddingRight: '1em!important',
  },
  date_cell: {
    paddingTop: '3.5em',
    paddingBottom: '3.5em',
    paddingLeft: '1em',
    paddingRight: '1em!important',
    maxWidth: '120px',
  },
}))

export default ProviderShiftSettings
