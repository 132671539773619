import { AssigneeGroup, ProviderUser } from '~/legacy/core'
import type { FC } from 'react'
import { getIsWorklistWithWorkUnits } from '../../models/Worklist'
import { useTypedWorklistSelector } from '../../redux/slices/worklists'
import { WorklistFilterUrlParam } from '../../screens/Worklist'
import WorklistTable from './WorklistTable'
import WorkUnitTable from './WorkUnitTable'

export interface WorklistProps {
  worklistId?: number
  providers?: ProviderUser[]
  assigneeGroups?: AssigneeGroup[]
  setWorklistFiltersUrlParam: (WorklistFilterUrlParam) => void
  worklistFiltersUrlParam?: WorklistFilterUrlParam
}

export const WorklistTableSelector: FC<WorklistProps> = (props: WorklistProps) => {
  const worklist = useTypedWorklistSelector(state =>
    props.worklistId ? state.worklists?.byId[props.worklistId] : null
  )

  if (worklist && !getIsWorklistWithWorkUnits(worklist)) {
    return <WorkUnitTable {...props} />
  } else {
    return <WorklistTable {...props} />
  }
}
export default WorklistTableSelector
