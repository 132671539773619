import { useEffect } from 'react'
import type { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiModal, { ModalProps } from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Close from '@material-ui/icons/Close'

type ModalPropsWithoutChildren = Omit<
  ModalProps,
  'children' | 'onEscapeKeyDown' | 'disableAutoFocus' | 'onBackdropClick'
>
interface ChatMessageModalProps extends ModalPropsWithoutChildren {
  closeModal: () => void
  image: string
}

export const ChatMessageModal: FC<ChatMessageModalProps> = (props: ChatMessageModalProps) => {
  const { closeModal, image, ...modalProps } = props
  const styles = useStyles()
  const listener = (e: KeyboardEvent) => {
    if (e.key === 'Escape') closeModal()
  }
  useEffect(() => {
    if (props.open) {
      window.addEventListener('keydown', listener)
      return () => window.removeEventListener('keydown', listener)
    }
  }, [props.open])
  return (
    <MuiModal
      {...modalProps}
      disableAutoFocus
      onBackdropClick={closeModal}
      className={styles.container}
    >
      <>
        <Close className={styles.close} onClick={closeModal} />
        <div className={styles.containerInner}>
          <a href={image} target="blank">
            <Fade in={true}>
              <img className={styles.image} src={image} alt="" />
            </Fade>
          </a>
        </div>
      </>
    </MuiModal>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  close: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    color: '#FFF',
  },
  containerInner: {
    overflow: 'auto',
    maxWidth: '92.5%',
  },
  image: {
    maxHeight: '100vh',
    maxWidth: '100%',
    display: 'block',
  },
})

export default ChatMessageModal
