import { ICareTeam, IPatientInsuranceDetails, IPubNubPatientMetadata } from '~/utils/pubnub'
import { getIsRead, isClinical, isOnboardingMessage, isOperational } from '~/utils/chat'
import { MessageEvent } from 'pubnub'

import Moment from 'moment'
import { PatientMetaData } from './index'

// Shorten timestamp
Moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ',
    s: '1s',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmos',
    y: 'y',
    yy: '%dy',
  },
})

export interface SingleMessageData {
  attachment: any
  sentAt: Date
  lastSender: any
  timestamp: string
  read: boolean
  text: string
  isUrgent?: boolean
  isUrgentMessage?: boolean
  messageTags: any[]
  enrolledInPrimaryCareProgram: boolean
  enrolledInBenefitProgram: boolean
  onboardingStatus: string
  addressState: string | null
  coverageState: any
  testOnly: boolean
  patientInitials: string
  patientName: string
  patientId: number | null
  personId: number | null
  patientInsuranceDetails: IPatientInsuranceDetails | null
  careTeam: ICareTeam
}

// This determines the data passed to the MessagesSingle component
// Patient metadata may come from the full Chat Thread record
// or from metadata from PubNub stored on the thread object
// (if we haven't loaded the full Chat Thread yet)
const messageDataFromThread = (
  thread: any,
  patientsById: { [key: number]: PatientMetaData }
): SingleMessageData | null => {
  // Messages are in chronological order by default
  const patientHistory = thread.messages || []
  let lastMessage: any = null

  // Some threads are empty, in which case we don't need to return anything
  if (!patientHistory.length) return null
  //ensure that last message on the list view is a text message or an attachment, not a phone call
  for (let i = patientHistory.length - 1; i >= 0; i--) {
    if (patientHistory[i].text != undefined || patientHistory[i].attachment != undefined) {
      lastMessage = patientHistory[i]
      break
    }
  }
  if (!lastMessage) return null

  if (lastMessage && thread.patientMetadata) {
    return messageDataFromThreadMetadata(lastMessage, thread)
  }
  const patient = patientsById[thread.patient]
  return messageDataFromCompleteThread(lastMessage, thread, patient)
}

const messageDataFromThreadMetadata = (lastMessage, thread): SingleMessageData => {
  const patientMetadata = thread.patientMetadata as IPubNubPatientMetadata
  const patient_insurance_details: IPatientInsuranceDetails = {
    coverageEndDate: patientMetadata.patient_insurance_details?.coverage_end_date,
    coverageStartDate: patientMetadata.patient_insurance_details?.coverage_start_date,
    insurancePayerName: patientMetadata.patient_insurance_details?.insurance_payer_name,
    insurancePayerShortName: patientMetadata.patient_insurance_details?.insurance_payer_short_name,
  }
  const care_team: ICareTeam = {
    ids: patientMetadata.care_team?.ids,
    nursePractitioner: patientMetadata.care_team?.nurse_practitioner,
  }
  return {
    attachment: lastMessage.attachment || null,
    timestamp: Moment(new Date(lastMessage.sentAt)).fromNow(),
    sentAt: new Date(lastMessage.sentAt),
    lastSender: lastMessage.sender,
    text: lastMessage.text,
    isUrgent: lastMessage.isUrgent,
    isUrgentMessage: lastMessage.isUrgentMessage,
    enrolledInPrimaryCareProgram: patientMetadata.enrolled_in_primary_care_program,
    enrolledInBenefitProgram: patientMetadata.enrolled_in_benefit_program,
    onboardingStatus: patientMetadata.onboarding_state_status || '',
    addressState: patientMetadata.address_state,
    coverageState: patientMetadata.coverage_state,
    read: getIsRead(thread),
    testOnly: patientMetadata.test_only,
    patientId: parseInt(patientMetadata.id),
    personId: patientMetadata.person_id ? patientMetadata.person_id : null,
    patientInitials: `${patientMetadata.first_name[0]}${patientMetadata.last_name[0]}`,
    patientName: `${patientMetadata.first_name} ${patientMetadata.last_name}`,
    patientInsuranceDetails: patient_insurance_details,
    messageTags: lastMessage.messageTags,
    careTeam: care_team,
  }
}
const messageDataFromCompleteThread = (lastMessage, thread, patient): SingleMessageData => {
  return {
    attachment: lastMessage.attachment || null,
    timestamp: Moment(new Date(lastMessage.sentAt)).fromNow(),
    sentAt: new Date(lastMessage.sentAt),
    lastSender: lastMessage.sender,
    text: lastMessage.text,
    isUrgent: lastMessage.isUrgent,
    isUrgentMessage: lastMessage.isUrgentMessage,
    enrolledInPrimaryCareProgram: thread.enrolledInPrimaryCareProgram,
    enrolledInBenefitProgram: thread.enrolledInBenefitProgram,
    onboardingStatus: thread.onboardingState?.status || '',
    addressState: thread.addressState,
    coverageState: thread.coverageState,
    read: getIsRead(thread),
    testOnly: thread.testOnly,
    patientId: patient?.id,
    personId: thread.personId,
    patientInitials: `${patient.firstName[0]}${patient.lastName[0]}`,
    patientName: `${patient.firstName} ${patient.lastName}`,
    messageTags: lastMessage.messageTags,
    patientInsuranceDetails: thread.patientInsuranceDetails,
    careTeam: thread.careTeam,
  }
}

// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

// TODO: this method may be unnecessary
// because we re-pull filtered messages from the back-end whenever we change filters
// and new incoming messages are sorted dynamically as they are loaded into redux
const getFilteredMessageObjects = (
  objects: (SingleMessageData | null)[],
  activeFilter: string | null
): SingleMessageData[] => {
  // The list can include null values because some threads are empty
  const messageObjects: SingleMessageData[] = objects.filter(notEmpty)
  // Filter message object based on activeFilter state
  switch (activeFilter) {
    case 'clinical': {
      return messageObjects.filter(message => isClinical(message) && !message.testOnly)
    }
    case 'operational': {
      return messageObjects.filter(message => isOperational(message) && !message.testOnly)
    }
    case 'unread': {
      return messageObjects.filter(
        message => !message.read && !isOnboardingMessage(message) && !message.testOnly
      )
    }
    default: {
      // Filter out test patients from All messages
      return messageObjects.filter(message => !message.testOnly)
    }
  }
}

export const patientIdFromPubNubMessage = (pubNubMessage: MessageEvent): number => {
  // e.g. 'thread.1721.default_v1'
  return parseInt(pubNubMessage.channel.split('.')[1])
}

export { messageDataFromThread, getFilteredMessageObjects }
