import 'date-fns'
import { useState } from 'react'
import type { ReactNode, ChangeEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import PhoneIcon from '@mui/icons-material/Phone'
import {
  Box,
  Button,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import FFModal from '../FFModal/FFModal'
import FFInput from '../Generic/FFInput'
import { IPhoneCallDisposition, PhoneCallDirectionEnum, IPhoneCall } from '../../models/PhoneCall'
import {
  IClientPhoneCall,
  useAddPhoneCall,
  useUpdatePhoneCall,
  usePhoneCallDispositions,
} from '~/api/PhoneCallService'
import Moment from 'moment'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import AddIcon from '@mui/icons-material/Add'
import Select from 'react-select'
import { selectCustomStyles } from '../Generic/selectCustomStyles'
import { useSelector } from 'react-redux'
import { useGetPhoneCallsForProvider } from '~/api/PhoneCallService'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

const AddPhoneCallModal = (props: {
  patient: { id: number; [key: string]: any }
  isOpen: boolean
  handleClose: () => void
  onAfterSave?: (p: IPhoneCall) => void
  patientPhoneCalls: IPhoneCall[] | undefined
}) => {
  const { patient, isOpen, handleClose, onAfterSave, patientPhoneCalls } = props
  const [phoneCallDirection, setPhoneCallDirection] = useState(PhoneCallDirectionEnum.INBOUND)
  const [phoneCallLinking, setPhoneCallLinking] = useState(true)
  const [date, setDate] = useState(Moment(new Date()).format('YYYY-MM-DD'))
  const [time, setTime] = useState(Moment(new Date()).format('HH:mm'))

  //The number here represents the selected tab
  //This tabs will be removed once we have a complete migration to talkdesk
  const [value, setValue] = useState(2)

  const meId = useSelector<any, number>(state => state.me.id)

  const { isLoading: isAddingPhoneCall, mutate: handleAddPhoneCall } = useAddPhoneCall(meId)
  const { isLoading: isLinkingPhoneCall, mutateAsync: handleUpdatePhoneCall } = useUpdatePhoneCall(
    patient.id ? patient.id : null,
    patient.person.id,
    false
  )

  const { result: dispositions, isLoading: isListingPhoneCallDispositions } =
    usePhoneCallDispositions()

  const [disposition, setDisposition] = useState<IPhoneCallDisposition | null>(null)
  // add new phone call
  const handleAdd = async () => {
    const phoneCallRequest: IClientPhoneCall = {
      user: patient.id ? patient.id : null,
      person: patient.person.id,
      direction: phoneCallDirection,
      calledAt: Moment(date + 'T' + time).format(),
      createdBy: meId,
    }

    if (disposition) {
      phoneCallRequest.disposition = disposition.id
    }
    handleAddPhoneCall(phoneCallRequest)
    handleClose()
  }

  //Link phonecall
  const handleLink = async () => {
    if (selectedPhoneCall) {
      const phoneCallRequest = await handleUpdatePhoneCall({
        id: selectedPhoneCall.id,
        user: patient.id ? patient.id : null,
        person: patient.person.id,
        direction: selectedPhoneCall.direction,
        calledAt: selectedPhoneCall.calledAt,
        callEndedAt: selectedPhoneCall.callEndedAt,
        externalCallId: selectedPhoneCall.externalCallId,
        contactPhoneNumber: selectedPhoneCall.contactPhoneNumber,
      })
      onAfterSave?.(phoneCallRequest)
      handleClose()
    }
  }
  // handle tab changes
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)

    setPhoneCallDirection(
      newValue === 0 ? PhoneCallDirectionEnum.INBOUND : PhoneCallDirectionEnum.OUTBOUND
    )

    setPhoneCallLinking(newValue === 2 ? true : false)
  }

  const isLoading = isAddingPhoneCall || isListingPhoneCallDispositions || isLinkingPhoneCall

  const [selectedPhoneCall, setSelection] = useState<IPhoneCall>()

  const setselectedPhoneCall = (phoneCallID: IPhoneCall['id']) => {
    if (phoneCallID == selectedPhoneCallID) {
      setselectedPhoneCallID(null)
    } else {
      setselectedPhoneCallID(phoneCallID)
    }
    const res = patientPhoneCalls?.find(phoneCall => phoneCall.id == phoneCallID)
    if (res) {
      setSelection(res)
    }
  }

  const [selectedPhoneCallID, setselectedPhoneCallID] = useState<IPhoneCall['id'] | null>(null)

  // used to create different modal for different tab panel
  const TabPanel = (props: TabPanelProps) => {
    const { value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {(index === 0 || index === 1) && (
          <>
            <Box paddingLeft={3} paddingTop={3} paddingRight={3}>
              {index === 0 && (
                <Select<IPhoneCallDisposition>
                  options={dispositions}
                  getOptionLabel={option => option.title}
                  getOptionValue={option => String(option.id)}
                  placeholder="Disposition"
                  defaultValue={dispositions?.find(c => c.id === disposition?.id)}
                  onChange={setDisposition}
                  styles={selectCustomStyles}
                  maxMenuHeight={80}
                />
              )}
            </Box>
            <Box p={3} display="inline-block" width="50%">
              {(index === 0 || index === 1) && (
                <FFInput
                  id="called_at_date"
                  type="date"
                  label="DATE"
                  defaultValue={date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setDate(e.target.value)}
                  fullWidth
                />
              )}
            </Box>
            <Box display="inline-block" width="45%">
              {(index === 0 || index === 1) && (
                <FFInput
                  id="called_at_time"
                  type="time"
                  label="TIME"
                  defaultValue={time}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e => setTime(e.target.value)}
                  fullWidth
                />
              )}
            </Box>
          </>
        )}
        {index === 2 && (
          <Box style={{ height: 400, width: '100%' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Phone number</TableCell>
                  <TableCell>Called at</TableCell>
                  <TableCell>Direction</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientPhoneCalls?.map(phonecall => {
                  return (
                    <TableRow
                      key={phonecall.id}
                      hover
                      onClick={() => {
                        setselectedPhoneCall(phonecall.id)
                      }}
                      selected={selectedPhoneCallID === phonecall.id}
                      sx={{
                        '&$selected, &$selected:hover': {
                          backgroundColor: 'primary.light',
                        },
                      }}
                    >
                      <TableCell>{phonecall.contactPhoneNumber}</TableCell>
                      <TableCell>
                        {Moment(new Date(phonecall.calledAt)).format(
                          'ddd. MMM. D, YYYY [at] h:mm a'
                        )}
                      </TableCell>
                      <TableCell>{phonecall.direction}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </div>
    )
  }

  return (
    <Box width="70%" marginLeft="auto" marginRight="auto">
      <FFModal
        open={isOpen}
        header={
          <Box mx={3} my={2}>
            <Typography variant="h5">{phoneCallLinking ? 'Link' : 'New'} Phone Call</Typography>
          </Box>
        }
        footer={
          <Box m={2} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button onClick={handleClose} disabled={isLoading}>
                Cancel
              </Button>
            </Box>

            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              onClick={phoneCallLinking ? handleLink : handleAdd}
            >
              {phoneCallLinking ? 'Link' : 'Add'} Call
            </Button>
          </Box>
        }
      >
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon tabs"
          >
            <Tab icon={<CallReceivedIcon />} label="Incoming Call" />
            <Tab icon={<CallMadeIcon />} label="Outgoing Call" />
            <Tab icon={<AddIcon />} label="Link Calls" />
          </Tabs>

          <TabPanel value={value} index={0}></TabPanel>
          <TabPanel value={value} index={1}></TabPanel>
          <TabPanel value={value} index={2}></TabPanel>
        </div>
      </FFModal>
    </Box>
  )
}

const AddPhoneCallButton = (props: { patient: { id: number } }) => {
  const { patient } = props

  const [isOpen, setIsOpen] = useState(false)

  const meId = useSelector<any, number>(state => state.me.id)
  const { data: patientPhoneCalls } =
    useGetPhoneCallsForProvider({
      created_by: meId,
      person_isnull: true,
    }) ?? []

  return (
    <div>
      <IconButton
        sx={
          patientPhoneCalls?.length
            ? {
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }
            : {}
        }
        onClick={() => setIsOpen(true)}
      >
        <PhoneIcon style={patientPhoneCalls?.length ? { color: 'white' } : undefined} />
      </IconButton>
      <AddPhoneCallModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        patient={patient}
        patientPhoneCalls={patientPhoneCalls}
      />
    </div>
  )
}

export default AddPhoneCallButton
