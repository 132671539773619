import { ChatMessage as ICoreChatMessage } from '~/legacy/core'
import { ChatThread } from '../redux/slices/chat/slice'
import { apiClient, apiClientWithoutRequestDecamelization } from '~/api/rest'
import { keyBy } from 'lodash/fp'
import axiosRequest from '~/utils/axios'
import { useMutation } from '~/components/Providers/ApiProvider'

export interface IMessageTag {
  tag: string
}

export interface IChatMessageV2Messagetags {
  messagetag?: IMessageTag
}
interface IChatMessage extends ICoreChatMessage {
  messageTags?: IMessageTag[]
  isUrgentMessage?: boolean
}

interface IChatMessageV2 extends ICoreChatMessage {
  chatmessagev2Messagetags: IChatMessageV2Messagetags[]
}

// required since the default camelization will attempt to
// add underscores before and after the numerical value 2
export interface IChatMessageV2WithoutDeCamelization extends ICoreChatMessage {
  is_urgent_message?: boolean
  chatmessagev2_messagetags?: IChatMessageV2Messagetags[]
}

export const uploadMessageAttachment = async (file: FormData) => {
  // TODO: use apiClient.rest for FormData
  const response: any = await axiosRequest(
    'POST',
    'multipart/form-data',
    true,
    '/chat/attachment',
    file
  )

  return response.data
}

export const getMessageAttachment = (attachmentId: number) => {
  return apiClient.rest.get(`/chat/attachment/${attachmentId}`)
}

const transformMessageTags = (data: IChatMessageV2[]) => {
  for (let message of data) {
    // Transform nested Tag data
    ;(message as IChatMessage).messageTags = ((message as any).chatmessagev2Messagetags || []).map(
      data => data.messagetag
    )
    delete (message as any).chatmessagev2Messagetags
  }
}

export const getThreadHistory = async (threadUid: string) => {
  const thread = await apiClient.rest.get(`/chat/v3/thread/${threadUid}/`)
  const recentMessages = await apiClient.rest
    .get(`/chat/v4/thread/${threadUid}/history/`)
    .then(data => {
      transformMessageTags(data.results)
      return data
    })
  return {
    thread,
    recentMessages,
  }
}

export const getChatMsgPhoneCallHistory = async (threadUid: string, personId: number) => {
  const thread = await apiClient.rest.get(`/chat/v3/thread/${threadUid}/`)
  const recentMessages = await apiClient.rest.get(
    `/providers/me/communications/people/${personId}/`
  )
  return {
    thread,
    recentMessages,
  }
}

export const getThreadHistoryFromCursor = cursor => {
  // Django rest framework returns a pagination cursor which is
  // a full URL; we only want the pathname and query params
  const cursorUrl = new URL(cursor)

  return apiClient.rest.get(cursorUrl.pathname + cursorUrl.search)
}

interface Threads {
  [id: number]: ChatThread
}

export const getThreads = async (params: {
  includeNoPod?: boolean
  podIds?: string
  filter?: string
  offset: number
  limit: number
}) => {
  if (params) {
    const response = await apiClient.rest.get<{
      results: Threads
      count: number
      next: string | null
    }>('/chat/v4/thread/', params)
    const data = keyBy('patient', response.results)
    for (let chatThread of Object.values(data)) {
      for (let message of chatThread.messages) {
        // Transform nested Tag data
        ;(message as IChatMessage).messageTags = (
          (message as any).chatmessagev2Messagetags || []
        ).map(data => data.messagetag)
        delete (message as any).chatmessagev2Messagetags
      }
    }
    return { threads: data, count: response.count }
  } else {
    throw new Error('Unable to fetch chat messages')
  }
}

export const markThreadRead = (threadUid: string, lastReadAt: string, lastReadBy: number) => {
  return apiClient.rest.patch(`/chat/v3/thread/${threadUid}/`, {
    last_read_at: lastReadAt,
    last_read_by: lastReadBy,
    is_unread: false,
  })
}

export const markThreadUnread = (threadUid: string) => {
  return apiClient.rest.patch(`/chat/v3/thread/${threadUid}/`, {
    last_read_at: null,
    last_read_by: null,
    is_unread: true,
  })
}

export const getMessageSearchResults = (threadUid: string, searchQuery: string) => {
  return apiClient.rest.get(`/chat/v2/thread/${threadUid}/search/?q=${searchQuery}`)
}

export const getChatThreadHistoryAt = (
  threadUid: string,
  msgId: number,
  numBefore: number,
  numAfter: number
) => {
  return apiClient.rest
    .get(`/chat/v3/thread/${threadUid}/history-at/${msgId}/`, {
      numBefore,
      numAfter,
    })
    .then(data => {
      transformMessageTags(data)
      return data
    })
}

export const getMessage = (uid: string) => {
  return apiClient.rest.get(`/chat/v2/message/${uid}/`)
}

const transformMessageTag = (data: IChatMessageV2WithoutDeCamelization) => {
  // Transform nested Tag data
  ;(data as IChatMessage).messageTags = ((data as any).chatmessagev2Messagetags || []).map(
    data => data.messagetag
  )
  delete (data as any).chatmessagev2Messagetags
}

const updateChatMessage = (
  payload: Required<Pick<IChatMessageV2WithoutDeCamelization, 'uid'>> &
    Partial<Omit<IChatMessageV2WithoutDeCamelization, 'uid'>>
) => {
  return apiClientWithoutRequestDecamelization.rest
    .patch<IChatMessageV2WithoutDeCamelization>(`/chat/v3/chat_message/${payload.uid}/`, payload)
    .then(data => {
      transformMessageTag(data)
      return data
    })
}

export const useUpdateChatMessage = () => {
  return useMutation(updateChatMessage, {}, { error: 'Failed to update chat message' })
}
