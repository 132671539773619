import type { FC } from 'react'
import { PatientUser } from '~/legacy/core'
import { Button, Grid } from '@material-ui/core'
import { useFormik } from 'formik'

import EditField from '../../Generic/EditField'

interface EditAddressFormProps {
  patient: PatientUser
  loading: boolean
  handleSubmit: (values: PatientUser) => void
}

export const EditAddressForm: FC<EditAddressFormProps> = props => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.patient,
    onSubmit: values => {
      props.handleSubmit(values)
    },
  })

  const {
    person: { insuranceInfo },
  } = formik.values

  // Shortcut for change handlers and values
  const setValues = (name: string, value) => ({
    name,
    onChange: e => {
      e.persist()
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    },
    disabled: props.loading,
    value,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <EditField
          {...setValues(
            'person.insuranceInfo.addresses[0].streetAddress',
            insuranceInfo?.addresses[0].streetAddress
          )}
          label="Street Address"
        />
        <EditField
          {...setValues(
            'person.insuranceInfo.addresses[0].streetAddress2',
            insuranceInfo?.addresses[0].streetAddress2
          )}
          label="Apt. or Unit #"
        />
        <EditField
          {...setValues('person.insuranceInfo.addresses[0].city', insuranceInfo?.addresses[0].city)}
          label="City"
        />
        <EditField
          {...setValues(
            'person.insuranceInfo.addresses[0].state',
            insuranceInfo?.addresses[0].state
          )}
          label="State"
        />
        <EditField
          {...setValues(
            'person.insuranceInfo.addresses[0].zipcode',
            insuranceInfo?.addresses[0].zipcode
          )}
          label="Zip Code"
        />
        <EditField value={insuranceInfo?.latitude} label="Latitude" disabled />
        <EditField value={insuranceInfo?.longitude} label="Longitude" disabled />
      </Grid>
      <Grid container spacing={1} justify="flex-end">
        {formik.dirty && (
          <Grid item>
            <Button onClick={() => formik.resetForm()}>Cancel Address Changes</Button>
          </Grid>
        )}
        <Grid item>
          <Button type="submit" color="primary" variant="outlined">
            Save Address
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default EditAddressForm
