import { Dispatch, SetStateAction } from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { PodFilterOption, usePodFilterOptions } from '~/utils/usePodFilterOptions'

export interface Props {
  selectedPodIdsMap: { [id: number]: boolean }
  setSelectedPodIdsMap: Dispatch<SetStateAction<{ [id: number]: boolean }>>
}

const PodFilterSelect: React.FC<Props> = props => {
  let podFilterOptions = usePodFilterOptions()

  podFilterOptions = podFilterOptions.filter(pod => pod.id !== 0)

  const togglePod = (option: PodFilterOption, isSelected: boolean) => {
    props.setSelectedPodIdsMap(current => {
      return { ...current, [option.id]: isSelected }
    })
  }

  const toggleIncludeNoPod = (isSelected: boolean) => {
    props.setSelectedPodIdsMap(current => {
      return { ...current, [0]: isSelected }
    })
  }

  return (
    <>
      <Box>
        {podFilterOptions.map((option, i) => (
          <Box key={i}>
            <FormControlLabel
              label={<Typography variant="body2">{option.name}</Typography>}
              control={
                <Checkbox
                  size="small"
                  sx={{ paddingTop: 0.25, paddingBottom: 0.25 }}
                  checked={!!props.selectedPodIdsMap[option.id]}
                  onChange={e => togglePod(option, e.target.checked)}
                />
              }
            />
          </Box>
        ))}
        <FormControlLabel
          label={<Typography variant="body2">No Care Team</Typography>}
          control={
            <Checkbox
              size="small"
              sx={{ paddingTop: 0.25, paddingBottom: 0.25 }}
              checked={!!props.selectedPodIdsMap[0]}
              onChange={e => toggleIncludeNoPod(e.target.checked)}
            />
          }
        />
      </Box>
    </>
  )
}

export default PodFilterSelect
