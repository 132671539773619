import type { FC } from 'react'
import Select, { NamedProps } from 'react-select'
import { Typography } from '@mui/material'

import { apiClient } from '~/api/rest'
import { usePromise } from '~/utils/usePromise'
import { selectCustomStyles } from './selectCustomStyles'

const formsResource = apiClient.resource<any>('/form/')

const FormSelect: FC<NamedProps> = props => {
  const { result } = usePromise(
    async () => {
      // Fetch both virtual task templates and available forms
      let [forms, taskCollections] = await Promise.all([
        formsResource.getAll().catch(() => []),
        apiClient.rest.get('/task/templates?source_type=virtual').catch(() => []),
      ])
      // If there are any virtual tasks tied to forms, prefer the virtual
      // task template so its metadata can be accessed downstream
      const virtualTaskTitles = taskCollections.map(v => v.title)
      // Sorting
      forms = forms
        .filter(f => !virtualTaskTitles.includes(f.title))
        .sort((a, b) => a.title.localeCompare(b.title))
      taskCollections = taskCollections.sort((a, b) => a.title.localeCompare(b.title))
      // Format responses to fit react-select grouped options
      return [
        { label: 'Forms', options: forms },
        { label: 'Virtual Tasks', options: taskCollections },
      ]
    },
    { error: 'Failed to load form and task template info' }
  )

  return (
    <Select<any>
      options={result}
      getOptionLabel={option => {
        // If it's a collection, prefer uid
        if (option.collectionId) return option.uid ?? option.title
        return option.title
      }}
      getOptionValue={option => option.id}
      formatGroupLabel={group => <Typography>{group.label}</Typography>}
      styles={selectCustomStyles}
      placeholder="Select Form"
      {...props}
    />
  )
}

export default FormSelect
