import { Box, Link, Typography } from '@mui/material'
import { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ICarePlan } from '~/models/CarePlan'
import { IMeasureReportCase } from '~/models/Case'
import { MeasureReport } from '~/models/Measure'
import WarningIcon from '@mui/icons-material/Warning'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useHistory } from 'react-router'
import cx from 'classnames'
import { Article, Timelapse } from '@mui/icons-material'
import { MeasureStatus } from './CarePlans'

const useStyles = makeStyles()(theme => {
  return {
    contentTitle: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      display: 'inline-flex',
      cursor: 'pointer',
    },
    measureStatus: {
      fontSize: '1.2rem',
      color: 'navy.500',
      marginBottom: 8,
    },
    careGapStatus: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 0.5,
      paddingTop: 0.5,
      borderRadius: 16,
      height: '30px',
      alignItems: 'center',
    },
    outstandingStatus: {
      backgroundColor: '#FFE6E5',
    },
    completeStatus: {
      backgroundColor: '#E5F9F4',
    },
    dueSoonStatus: {
      backgroundColor: '#FFF7D0',
    },
  }
})

const CaseBasedCarePlanContent: FC<{
  carePlan: ICarePlan
  categorizedMeasureCollection
}> = props => {
  const { carePlan, categorizedMeasureCollection } = props

  const { classes } = useStyles()
  const history = useHistory()

  const orderedMeasureStatuses: MeasureStatus[] = ['Outstanding', 'Complete']

  const openLinkedCase = e => {
    const caseId = e.currentTarget.getAttribute('data-case-id')

    if (caseId) {
      const path = location.pathname
      const basePath = path.substring(0, path.lastIndexOf('/'))
      history.replace(`${basePath}/todos?caseId=${caseId}`)
    } else {
      console.log('Case not found ', caseId)
    }
  }

  const renderStatusIcon = status => {
    switch (status) {
      case 'Overdue':
        return <WarningIcon color="error" fontSize="small"></WarningIcon>
      case 'Due Soon':
        return <Timelapse color="warning" fontSize="small"></Timelapse>
      default:
        return <CheckBoxIcon color="success" fontSize="small"></CheckBoxIcon>
    }
  }

  return (
    <>
      <Box ml={3}>
        {Object.keys(categorizedMeasureCollection).length > 0 ? (
          orderedMeasureStatuses.map(measureStatus => {
            return (
              <Box key={measureStatus}>
                {measureStatus in categorizedMeasureCollection ? (
                  <>
                    <Typography className={classes.measureStatus}>{measureStatus}</Typography>
                    {/* border box */}
                    <Box>
                      {categorizedMeasureCollection[measureStatus].map((measureDetails, i) => {
                        return (
                          <Box key={i}>
                            {measureDetails.measureReports.map((report: MeasureReport) => {
                              return (
                                <Box
                                  key={report.id}
                                  sx={{
                                    width: '100%',
                                    border: '1px solid #E0E0E0',
                                    borderRadius: 1,
                                    marginBottom: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      padding: 2,
                                    }}
                                  >
                                    {/* first column */}
                                    <Box
                                      sx={{
                                        display: 'inline-flex',
                                        flexWrap: 'wrap',
                                        width: '40%',
                                      }}
                                    >
                                      <Box width={'100%'}>
                                        <Typography sx={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                                          {measureDetails.measure.name}
                                        </Typography>
                                        <Box
                                          sx={{
                                            display: 'inline-flex',
                                            justifyContent: 'flex-start',
                                            fontSize: '1.2rem',
                                          }}
                                        >
                                          {report.cases.map((measureCase: IMeasureReportCase) => {
                                            return (
                                              <>
                                                <Link
                                                  color="inherit"
                                                  data-case-id={measureCase.id}
                                                  onClick={openLinkedCase}
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                >
                                                  {`Case`}
                                                </Link>
                                                <Typography sx={{ fontSize: '1.2rem' }}>
                                                  {`: ` + measureCase.status}
                                                </Typography>
                                              </>
                                            )
                                          })}
                                        </Box>
                                      </Box>
                                    </Box>
                                    {/* second column */}
                                    <Box
                                      sx={{
                                        display: 'inline-flex',
                                        flexWrap: 'wrap',
                                        width: '30%',
                                      }}
                                    >
                                      <Box flexDirection={'column'} display={'flex'} width={'100%'}>
                                        <Typography sx={{ fontSize: '1.2rem' }}>
                                          {report.lastActionDate || report.lastAction
                                            ? `Last: `
                                            : ''}
                                          {report.lastActionDate
                                            ? report.lastActionDate + `, `
                                            : ''}
                                          {report.lastAction ? report.lastAction : ''}
                                        </Typography>
                                        <Typography sx={{ fontSize: '1.2rem' }}>
                                          {report.nextActionDate
                                            ? `Satisfied Until: ` + report.nextActionDate
                                            : ''}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {/* third column */}
                                    <Box
                                      sx={{
                                        display: 'inline-flex',
                                        flexWrap: 'wrap',
                                        width: '30%',
                                        paddingLeft: 12,
                                      }}
                                    >
                                      <Box
                                        className={cx(
                                          classes.careGapStatus,
                                          report.careGapStatus === 'Overdue'
                                            ? classes.outstandingStatus
                                            : report.careGapStatus === 'Due Soon'
                                            ? classes.dueSoonStatus
                                            : classes.completeStatus
                                        )}
                                      >
                                        {renderStatusIcon(report.careGapStatus)}
                                        <Typography
                                          sx={{
                                            marginLeft: 0.5,
                                            fontWeight: '500',
                                            fontSize: '1.4rem',
                                          }}
                                        >
                                          {report.careGapStatus}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            })}
                          </Box>
                        )
                      })}
                    </Box>
                  </>
                ) : null}
              </Box>
            )
          })
        ) : (
          <Typography className={classes.measureStatus}>
            {'Will be ready for review 24 hours after the first visit'}
          </Typography>
        )}
        {carePlan.status == 'active' ? (
          <>
            <Box>
              {carePlan.notes ? (
                <>
                  <Typography
                    sx={{
                      fontSize: '1.2rem',
                      color: 'navy.500',
                      marginBottom: 1,
                      marginTop: 4,
                    }}
                  >
                    {'From Your Clinician'}
                  </Typography>
                  <Typography sx={{ fontSize: '1.4rem', whiteSpace: 'pre-wrap' }}>
                    {carePlan.notes}
                  </Typography>
                </>
              ) : null}
            </Box>
            <Box>
              {(carePlan.carePlanEducationResources || []).length > 0 ? (
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    color: 'navy.500',
                    marginBottom: 1,
                    marginTop: 4,
                  }}
                >
                  {'Resources'}
                </Typography>
              ) : null}
              {(carePlan.carePlanEducationResources || []).map(resource => {
                return (
                  <Box
                    alignSelf={'center'}
                    key={resource.educationResource.title}
                    display={'flex'}
                    marginBottom={1}
                  >
                    <Article color="inherit" sx={{ fontSize: '2rem', marginRight: 1 }} />
                    <Typography sx={{ fontSize: '1.4rem' }}>
                      {resource.educationResource.title}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </>
        ) : null}
      </Box>
    </>
  )
}

export default CaseBasedCarePlanContent
