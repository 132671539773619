import FFInput from '~/components/Generic/FFInput'
import TasksForm from './TasksForm'
import { makeStyles } from 'tss-react/mui'
import { ITask } from '~/models/Task'
import { format } from 'date-fns'

interface IEditOrCustomCreateForm {
  me: any
  patient: any
  tasks: ITask[]
  setTasks: (arg0: ITask[]) => void
  saving: boolean
  title: string
  notes: string
  setTitle: (arg0: string) => void
  setNotes: (arg0: string) => void
}

const EditOrCustomCreateForm = (props: IEditOrCustomCreateForm) => {
  const { classes } = useStyles()

  const addTask = () => {
    const newTasks = [
      ...props.tasks,
      {
        title: '',
        dueDate: format(new Date(), 'YYYY-MM-DD'),
        ...{ ownerGroup: props.me.assigneeGroup.id },
        priority: 0,
        isComplete: false,
      },
    ]
    props.setTasks(newTasks)
  }

  /**
   * @param {number} i - index of the array of tasks
   */
  const updateTask = (i, update) => {
    const newTasks = [...props.tasks]
    newTasks[i] = { ...newTasks[i], ...update }
    props.setTasks(newTasks)
  }

  const removeTask = i => {
    const newTasks = [...props.tasks]
    newTasks.splice(i, 1)
    props.setTasks(newTasks)
  }

  const handleUpdateNotes = event => {
    if (event) {
      props.setNotes(event.target?.value)
    }
  }

  const handleUpdateTitle = event => {
    if (event) {
      props.setTitle(event.target?.value)
    }
  }

  return (
    <>
      <FFInput
        disabled={props.saving}
        onChange={e => handleUpdateTitle(e)}
        value={props.title}
        className={classes.textField}
        inputProps={{ maxLength: 255 }}
        placeholder="Add care plan name"
        fullWidth
      />
      <FFInput
        disabled={props.saving}
        onChange={e => handleUpdateNotes(e)}
        value={props.notes}
        className={classes.textField}
        placeholder="Add description"
        multiline={true}
        fullWidth
      />
      <TasksForm
        patient={props.patient}
        tasks={props.tasks}
        addTask={addTask}
        updateTask={updateTask}
        removeTask={removeTask}
        saving={props.saving}
      />
    </>
  )
}

const useStyles = makeStyles()(theme => ({
  textField: {
    marginBottom: theme.spacing(),
  },
}))

export default EditOrCustomCreateForm
