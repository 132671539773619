import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import FFModal from '~/components/FFModal/FFModal'
import CloseIcon from '@mui/icons-material/Close'
import * as AppointmentService from '~/api/AppointmentService'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'
import { queueNotification } from '~/redux/actions/notifications'
import usePatientDetail from '../utils/usePatientDetail'
import { Appointment } from '.'

export const RescheduleMapping = {
  now: 'Yes, reschedule now',
  rescheduled: 'Yes, already rescheduled',
  patient_to_reschedule: 'I’ve asked the patient to reschedule',
  cancel: 'No, just cancel',
}

const ConfirmCancelApptModal: FC<{
  appointment: Appointment
  onClose: (continueToBooking: boolean) => void
  cancellationReasonIds: Array<number>
  otherReasonForPatient: string
  otherReasonForFirefly: string
}> = props => {
  const [radioButtonValue, setRadioButtonValue] = useState<string>('')
  const dispatch = useDispatch()
  const client = useQueryClient()
  const [saving, setSaving] = useState<boolean>(false)
  const { id: patientId } = usePatientDetail()

  const handleConfirmCancellation = async () => {
    setSaving(true)
    const payload = {
      id: props.appointment.id,
      cancellationReasonIds: props.cancellationReasonIds,
      patientOtherReason: props.otherReasonForPatient,
      fireflyOtherReason: props.otherReasonForFirefly,
      rescheduleAction: radioButtonValue !== 'cancel' ? radioButtonValue : null,
    }

    const apptCancelData = await AppointmentService.cancelAppointmentV2(payload)
    if (apptCancelData.id) {
      dispatch(
        queueNotification({
          message: `Appointment cancelled successfully`,
          variant: 'success',
        })
      )
    } else {
      dispatch(
        queueNotification({
          message: `Failed to cancel the appointment`,
          variant: 'error',
        })
      )
    }
    await client.invalidateQueries(['getPatientAppointmentsInfo', props.appointment.patient])
    await client.invalidateQueries(['useAppointmentLogs', props.appointment.patient])
    setSaving(false)
    props.onClose(radioButtonValue === 'now')
  }

  if (!patientId) return null

  return (
    <FFModal open={true} style={{ width: '65%', marginLeft: 'auto', marginRight: 'auto' }}>
      <>
        <Box m={2}>
          <Typography variant="h6">{'Confirm Cancellation'}</Typography>
        </Box>
        <Box m={2}>
          <Typography variant="subtitle1">
            {'This appointment will be cancelled. Will it be rescheduled?'}
          </Typography>
          <Box>
            <RadioGroup
              value={radioButtonValue}
              onChange={e => {
                setRadioButtonValue(e.target.value)
              }}
            >
              {Object.keys(RescheduleMapping).map(key => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={<Typography>{RescheduleMapping[key]}</Typography>}
                />
              ))}
            </RadioGroup>
          </Box>
        </Box>
        <Box m={2}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
            <Button
              onClick={() => props.onClose(false)}
              variant="text"
              size="small"
              sx={{
                borderRadius: 10,
                fontSize: '1.4rem',
                maxHeight: '75%',
                width: '20%',
                marginRight: 1,
              }}
              disabled={saving}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Exit'}</Typography>
            </Button>
            <Button
              disabled={radioButtonValue == '' || saving}
              variant="contained"
              size="small"
              onClick={() => {
                handleConfirmCancellation()
              }}
              sx={{
                borderRadius: 10,
                fontSize: '1.4rem',
                maxHeight: '75%',
                width: '45%',
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
                {'Confirm Cancellation'}
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box m={2}>
          <IconButton
            onClick={() => props.onClose(false)}
            sx={{
              position: 'absolute',
              zIndex: 100,
              top: 2,
              right: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    </FFModal>
  )
}

export default ConfirmCancelApptModal
