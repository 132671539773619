import { Autocomplete, Box, Button, IconButton, TextField, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { FC, useEffect, useState } from 'react'
import FFModal from '~/components/FFModal/FFModal'
import CloseIcon from '@mui/icons-material/Close'
import { ICarePlan } from '~/models/CarePlan'
import AddIcon from '@mui/icons-material/Add'
import { EducationResource } from '~/models/EducationResource'
import { DeleteOutline } from '@mui/icons-material'
import * as CarePlanService from '~/api/CarePlanService'
import CarePlanDataTracking, { DataTracking } from './CarePlanDataTracking'
import DeactivateExistingCarePlanModal from './DeactivateExistingCarePlanModal'

type EducationResourceOption = Omit<EducationResource, 'createdAt' | 'updatedAt'>
type UnsavedEducationResource = Pick<EducationResource, 'title' | 'url'>
type AnyEducationResource = EducationResource | UnsavedEducationResource

const NEW_RESOURCE: UnsavedEducationResource = {
  title: '',
  url: '',
}

const STAYING_HEALTHY = [
  'stayingHealthyMenAbove45',
  'stayingHealthyMen18To44',
  'stayingHealthyWomenAbove50',
  'stayingHealthyWomen40To49',
  'stayingHealthyWomen30To39',
  'stayingHealthyWomen18To29',
]

function appendNewResource(resources: AnyEducationResource[]): AnyEducationResource[] {
  return [...resources, NEW_RESOURCE]
}

function replaceResource(
  resources: AnyEducationResource[],
  resource: AnyEducationResource,
  index: number
) {
  const clone = [...resources]
  clone.splice(index, 1, resource)
  return clone
}

function removeResource(resources: AnyEducationResource[], index: number) {
  const clone = [...resources]
  clone.splice(index, 1)
  return clone
}

type ResourceRowProps = {
  index: number
  options: EducationResourceOption[]
  resource: AnyEducationResource
  onChange: (resource: AnyEducationResource, index: number) => void
  onRemove: (index: number) => void
  saving: boolean
}

const ResourceRow: FC<ResourceRowProps> = ({
  index,
  options,
  resource,
  onChange,
  onRemove,
  saving,
}) => {
  return (
    <Box display={'flex'} flexDirection={'row'} mb={2}>
      <Box>
        <Autocomplete
          disabled={saving}
          value={resource}
          options={options}
          onChange={(_, value) => {
            onChange(value!, index)
          }}
          isOptionEqualToValue={(option: EducationResource, value: AnyEducationResource) => {
            if ('id' in value) {
              return option.id === value.id
            }

            return false
          }}
          disableClearable={true}
          sx={{ width: 400 }}
          getOptionLabel={option => option.title}
          renderOption={(props, option) => {
            return (
              <li {...props} style={{ wordBreak: 'break-word' }}>
                <Typography fontWeight={'bold'}>
                  {option.title}
                  <Typography>{option.url}</Typography>
                </Typography>
              </li>
            )
          }}
          renderInput={params => <TextField {...params} label="Choose a Resource" />}
        />
      </Box>
      <Box mt={1} ml={1}>
        <IconButton onClick={() => onRemove(index)} disabled={saving}>
          <DeleteOutline />
        </IconButton>
      </Box>
    </Box>
  )
}

const CaseBasedCarePlanModal: FC<{
  carePlan: ICarePlan
  closeModal: () => void
  onCareplanCreateOrUpdate
  educationResourceOptions
  allCarePlans: ICarePlan[]
  openModal
  edit: boolean
}> = props => {
  const { classes } = useStyles()
  const { carePlan, closeModal } = props
  const [notes, setNotes] = useState<string>(carePlan.notes)
  const [saving, setSaving] = useState<boolean>(false)
  const [dataTracking, setDataTracking] = useState<string | null>(null)
  const [backClick, setBackClick] = useState<boolean>(false)
  const [updatedCarePlan, setUpdatedCarePlan] = useState<ICarePlan>()

  const [resources, setResources] = useState<AnyEducationResource[]>(
    (carePlan.carePlanEducationResources || []).map(r => r.educationResource)
  )

  const [resourceOptions, _] = useState<EducationResourceOption[]>(
    props.educationResourceOptions /* [] */
  )

  const resourceIds = resources
    .map(r => {
      if ('id' in r) {
        return r.id
      }
    })
    .filter(x => !!x)

  const unusedResourceOptions = resourceOptions.filter(o => !resourceIds.includes(o.id))

  const otherStayingHealthyCarePlan = props.allCarePlans.filter(
    carePlan => STAYING_HEALTHY.includes(carePlan.typeUid) && carePlan.status == 'active'
  )

  const handleUpdateNotes = (event: any) => {
    if (event) {
      setNotes(event.target?.value)
      if (event.target.value !== '') {
        setNotesError(false)
      } else {
        setNotesError(true)
      }
    }
  }

  const onAddMoreButtonClick = () => {
    setResources(prev => appendNewResource(prev))
  }

  const onResourceChange = (resource: AnyEducationResource, index: number) => {
    setResources(prev => replaceResource(prev, resource, index))
  }

  const onResourceDelete = (index: number) => {
    setResources(prev => removeResource(prev, index))
  }

  const [notesError, setNotesError] = useState<boolean>(false)

  const updateNotes = e => {
    if (e.target.value == '') {
      setNotesError(true)
    }
  }

  const handleSaving = async (_, sendToMember, action) => {
    if (sendToMember && notes.trim() == '') {
      setNotesError(true)
      return
    }

    setSaving(true)

    const carePlanEducationResources = resources
      .filter(resource => resource.title !== '')
      .map(resource => ({ educationResource: resource }))

    var updatedCarePlan = await CarePlanService.updateCaseBasedCarePlan({
      id: carePlan.id,
      notes,
      patient: carePlan.patient,
      title: carePlan.title,
      carePlanEducationResources,
      carePlanDataTracking: dataTracking ? dataTracking : carePlan.carePlanDataTracking,
    })
    if (sendToMember && action !== carePlan.status) {
      updatedCarePlan = await CarePlanService.updateCarePlanAction({
        id: parseInt(carePlan.id.toString()),
        action: action ? action : 'active',
        patient: carePlan.patient,
      })
    }
    props.onCareplanCreateOrUpdate(updatedCarePlan)
    setSaving(false)
    setUpdatedCarePlan(updatedCarePlan)
    if (
      (otherStayingHealthyCarePlan.length > 0 &&
        otherStayingHealthyCarePlan[0].status !== 'active') ||
      (action && otherStayingHealthyCarePlan.length > 0) ||
      (sendToMember && otherStayingHealthyCarePlan.length == 0 && !action)
    ) {
      closeModal()
    }
  }

  useEffect(() => {
    if (dataTracking == DataTracking.MANUAL) {
      handleSaving('', true, 'draft')
      if (otherStayingHealthyCarePlan.length == 0) props.openModal(null)
    }
  }, [dataTracking])

  return (
    <FFModal open={true}>
      {(dataTracking == null || backClick || dataTracking == DataTracking.MANUAL) && !props.edit ? (
        <CarePlanDataTracking
          setDataTracking={setDataTracking}
          carePlan={carePlan}
          closeModal={closeModal}
          setBackClick={setBackClick}
          saving={saving}
        ></CarePlanDataTracking>
      ) : null}
      {(dataTracking == DataTracking.AUTOMATIC && !backClick && updatedCarePlan == undefined) ||
      props.edit ? (
        <>
          <Box m={2}>
            <Typography variant="h6">{'Edit Care Plan'}</Typography>
            <Typography variant="subtitle1" mt={1}>
              {'Staying Healthy'}
            </Typography>
          </Box>
          <Box m={2}>
            <Typography variant="subtitle2" mt={3} mb={1}>
              {'From Your Clinician'}
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={notes}
              onChange={e => handleUpdateNotes(e)}
              multiline={true}
              fullWidth
              placeholder="Add goals or a personalized message"
              disabled={saving}
              error={notesError}
              helperText={notesError && 'Goals or personalized message required'}
              onBlur={updateNotes}
            />
          </Box>
          <Box m={2}>
            <Typography variant="subtitle2" mt={3} mb={1}>
              {'Resources'}
            </Typography>
            <>
              {resources.map((resource, index) => (
                <ResourceRow
                  key={`educationResource_${index}`}
                  index={index}
                  options={unusedResourceOptions}
                  resource={resource}
                  onChange={onResourceChange}
                  onRemove={onResourceDelete}
                  saving={saving}
                />
              ))}
            </>
            <Box alignSelf={'center'}>
              <IconButton
                color="primary"
                disabled={saving}
                onClick={e => onAddMoreButtonClick(e)}
                classes={{ root: classes.actionsButton }}
                sx={theme => ({
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.primary[700],
                  },
                  borderRadius: 'unset',
                })}
              >
                <AddIcon color="inherit" sx={{ fontSize: '1.4rem', fontWeight: 500 }} />
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>Add more</Typography>
              </IconButton>
            </Box>
          </Box>
          <Box m={2}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
              {carePlan.carePlanDataTracking == null ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'inline-table',
                  }}
                >
                  <Button
                    onClick={() => {
                      setBackClick(true)
                    }}
                    variant="text"
                    disabled={saving}
                    size="small"
                    sx={{
                      borderRadius: 10,
                      fontSize: '1.4rem',
                      maxHeight: '75%',
                      width: '20%',
                      marginRight: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Back'}</Typography>
                  </Button>
                </Box>
              ) : null}
              <Button
                onClick={closeModal}
                variant="text"
                disabled={saving}
                size="small"
                sx={{
                  borderRadius: 10,
                  fontSize: '1.4rem',
                  maxHeight: '75%',
                  width: '20%',
                  marginRight: 1,
                }}
              >
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
              </Button>
              <Button
                disabled={saving}
                onClick={e => {
                  handleSaving(e, true)
                }}
                variant="contained"
                size="small"
                sx={{
                  borderRadius: 10,
                  fontSize: '1.4rem',
                  maxHeight: '75%',
                  width: '30%',
                }}
              >
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
                  {'Send to member'}
                </Typography>
              </Button>
            </Box>
          </Box>
        </>
      ) : null}
      {updatedCarePlan?.carePlanDataTracking == DataTracking.AUTOMATIC &&
      otherStayingHealthyCarePlan.length > 0 ? (
        <DeactivateExistingCarePlanModal
          carePlanToBeDeactivated={otherStayingHealthyCarePlan[0]}
          closeModal={closeModal}
          onCareplanCreateOrUpdate={props.onCareplanCreateOrUpdate}
          setUpdatedCarePlan={setUpdatedCarePlan}
        ></DeactivateExistingCarePlanModal>
      ) : null}

      <Box m={2}>
        <IconButton className={classes.close} onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </Box>
    </FFModal>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    close: {
      position: 'absolute',
      zIndex: 100,
      top: theme.spacing(),
      right: theme.spacing(),
    },
    actionsButton: {
      marginLeft: '-1rem!important',
      marginBottom: theme.spacing(0.5),
    },
  }
})

export default CaseBasedCarePlanModal
