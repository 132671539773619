import React from 'react'
import { FeatureAccess, Features } from '../interfaces'

interface AccessCheckProps
  extends React.PropsWithChildren<{
    feature: Features
    featureAccess: FeatureAccess | null | undefined
  }> {}

// Renders the child component if the User has access to the feature
// This component should not be exported. Instead, expect to use
// either the patient- or provider- specific component
const BehindFeatureAccessCheck: React.FC<
  AccessCheckProps & {
    isProvider: boolean
  }
> = props => {
  const { feature, featureAccess, children } = props

  if (!featureAccess) {
    return null
  }

  const hasAccess = featureAccess && featureAccess.tenant && featureAccess.tenant[feature] === true

  if (!hasAccess) {
    return null
  }

  return <>{children}</>
}

export const BehindProviderFeatureAccessCheck: React.FC<AccessCheckProps> = props => {
  return <BehindFeatureAccessCheck {...props} isProvider={true} />
}
