import React, { useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  AutoAwesome as AutoAwesomeIcon,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material'
import invariant from 'tiny-invariant'
import { useFormik } from 'formik'
import { format } from 'date-fns'

import {
  EditSteerageInterface,
  GetElationLetterTemplateInterface,
  useEditSteerage,
  useGetElationLetterTemplate,
} from '~/api/SteerageService'
import { ElationReferralTempate, Service, ReferralPriority, Steerage } from '../types'
import { useReferralDetails } from './utils'
import PersonPreference from '../PersonPreference'
import ServiceAutocomplete from './ServiceAutocomplete'

export const ReferralRequest: React.FC<{ steerage: Steerage }> = props => {
  const steerage = props.steerage
  const { isLoading: referralDetailsIsLoading, ...referralDetails } = useReferralDetails()
  const editSteerage = useEditSteerage()
  const getElationTemplate = useGetElationLetterTemplate()
  const isLoading = referralDetailsIsLoading || editSteerage.isLoading
  const [serviceBasedOnSpecialty, setServiceBasedOnSpecialty] = useState<Service[] | []>([])

  // Build a map of services by ID so we can reference it in the Autocomplete change handler
  // and avoid nested loops
  const servicesMap = new Map(referralDetails?.services.map(service => [service.id, service]))

  // Prepopulate the form with the specialtyGroup or locationType
  const initialSpecialtyLocationType = (steerage?.specialtyGroup || steerage?.locationType) ?? 0
  const initialSpecialtyLocationsource = steerage?.specialtyGroup
    ? 'SpecialtyGroup'
    : steerage?.locationType
    ? 'LocationType'
    : null

  const selectedInitialSpecialtyLocationType =
    referralDetails?.specialtiesAndLocationTypes?.find(
      s => s.id === initialSpecialtyLocationType && s?.source === initialSpecialtyLocationsource
    ) ?? null
  const initialSpecialtyLocationTypeLabel = selectedInitialSpecialtyLocationType?.label ?? null
  let virtualAppropriateBasedOnSpecialty = selectedInitialSpecialtyLocationType
    ? selectedInitialSpecialtyLocationType.doesVirtualPartnerExist
    : false

  // Prepopulate isLastMile and notifyClinicianYn so they render as a string
  let initialIsLastMile: null | string =
    initialSpecialtyLocationTypeLabel === 'Firefly Nearby'
      ? 'YES'
      : initialSpecialtyLocationTypeLabel
      ? 'NO'
      : null
  let initialNotifyClinicianYn: null | string = null
  if (steerage?.notifyClinicianYn === true) initialNotifyClinicianYn = 'YES'

  const isWaiverOnly = steerage?.isEffectivelyWaiverOnly

  const formik = useFormik({
    // This allows formik to reinitialize when steerage changes
    enableReinitialize: true,
    initialValues: {
      requestedByMember: steerage?.requestedByMember ?? false,
      requestType: steerage?.requestType ?? 'broad',
      preferredProviderOrFacility: steerage?.preferredProviderOrFacility ?? '',
      memberPreferredProvider: steerage?.memberRequestedData?.providerName ?? '',
      specialtyGroup: initialSpecialtyLocationTypeLabel,
      priority: steerage?.priority ?? '',
      typeOfVisit: steerage?.typeOfVisit ?? 'Any',
      isLastMile: initialIsLastMile,
      services: steerage?.services ?? [],
      notifyClinicianYn: initialNotifyClinicianYn,
      sendToElation: false,
      referralBody: steerage?.referral?.body ?? '',
    },
    onSubmit: values => {
      // Coerce the isLastMile value to a boolean
      let isLastMile: boolean | null = null
      if (values.isLastMile === 'YES') isLastMile = true
      else if (values.isLastMile === 'NO') isLastMile = false

      // Coerce the notifyClinicianYn value to a boolean
      let notifyClinicianYn: boolean | null = null
      if (values.notifyClinicianYn === 'YES') notifyClinicianYn = true
      else if (values.notifyClinicianYn === 'NO') notifyClinicianYn = false

      // Fetch the ServiceIds
      let serviceIds: number[] = []
      serviceIds = values.services.map(service => service.id)

      let field = 'specialtyGroup'
      let payload: EditSteerageInterface = {
        steerageId: steerage.id,
        requestedByMember: values.requestedByMember,
        requestType: values.requestType,
        priority: values.priority,
        typeOfVisit: virtualAppropriateBasedOnSpecialty == true ? values.typeOfVisit : 'Any',
        specialtyGroup: null, // these should be set in the if condition below, null is the default to clear
        locationType: null,
        memberRequestedData: {
          providerName: values?.memberPreferredProvider,
        },
        serviceIds: serviceIds,
      }

      if (!isWaiverOnly) {
        payload['preferredProviderOrFacility'] = values.preferredProviderOrFacility
        payload['notifyClinicianYn'] = notifyClinicianYn
        // TODO: Update this type to be Partial<Referral>
        // @ts-ignore
        payload['referral'] = {
          isLastMile,
          body: values.referralBody,
        }
        payload['sendToElation'] = values?.sendToElation
      }
      if (values.specialtyGroup) {
        // Check whether the specialtyGroup value is a location or a specialty
        // Default to specialtyGroup just to make the types happy

        const chosenSpecialty = referralDetails?.specialtiesAndLocationTypes!.find(
          s => s.label === values.specialtyGroup
        )
        invariant(chosenSpecialty, 'Chosen specialty not found')
        if (chosenSpecialty.source === 'SpecialtyGroup') field = 'specialtyGroup'
        if (chosenSpecialty.source === 'LocationType') field = 'locationType'
        payload[field] = chosenSpecialty.id
      }

      // Update the API and reset the form values
      editSteerage.mutateAsync(payload, {
        // Use new initial values to reset the form
        onSuccess: () => formik.resetForm(),
      })
    },
  })

  const handleCreateReferralTemplateClick = () => {
    if (steerage?.person) {
      let isLastMile: boolean | null = null
      let field = 'specialtyGroup'
      if (formik.values.isLastMile === 'YES') isLastMile = true
      else if (formik.values.isLastMile === 'NO') isLastMile = false

      let payload: GetElationLetterTemplateInterface = {
        personId: steerage?.person,
        isLastMile: isLastMile,
        priority: formik.values.priority,
        locationType: null,
        specialtyGroup: null,
        typeOfVisit: virtualAppropriateBasedOnSpecialty == true ? formik.values.typeOfVisit : 'Any',
        preferredProvider: formik.values.preferredProviderOrFacility,
        memberPreferredProvider: formik.values.memberPreferredProvider,
      }

      if (isLastMile) {
        const serviceDescriptions = formik.values.services
          .map(service => service.description)
          .toString()
        payload['services'] = serviceDescriptions
      }

      if (formik.values.specialtyGroup) {
        // Check whether the specialtyGroup value is a location or a specialty
        // Default to specialtyGroup just to make the types happy

        const chosenSpecialty = referralDetails?.specialtiesAndLocationTypes!.find(
          s => s.label === formik.values.specialtyGroup
        )
        invariant(chosenSpecialty, 'Chosen specialty not found')
        if (chosenSpecialty.source === 'SpecialtyGroup') field = 'specialtyGroup'
        if (chosenSpecialty.source === 'LocationType') field = 'locationType'
        payload[field] = chosenSpecialty.label
      }
      getElationTemplate.mutateAsync(payload, {
        onSuccess: (response: ElationReferralTempate) => {
          formik.setFieldValue('referralBody', response.body)
        },
      })
    }
  }

  /**
   * Start Form States
   */
  const isDisabled =
    isLoading ||
    Boolean(steerage.isLocked) ||
    Boolean(steerage.wasElationLetterCreatedFromLucian) ||
    steerage.segment !== null

  /**
   * When this component is not in read-only mode, we need to make sure that deprecated
   * Specialty Groups are not used. Read-only Steerages may still reference them.
   */
  let specialtyGroupsAndLocationTypeOptions = referralDetails?.specialtiesAndLocationTypes ?? []
  if (!isDisabled) {
    specialtyGroupsAndLocationTypeOptions = specialtyGroupsAndLocationTypeOptions.filter(
      option => !option.isDeprecated
    )
  }

  /**
   * Separate "locked" uneditable state from potential loading spinner type state
   * It's appropriate to disable inputs for loading state, but not appropriate to
   * reorganize grid column sizing based on it
   */
  const isLocked = !!steerage.isLocked

  const isSendToElationDisabled =
    !formik.values?.requestType ||
    !formik.values?.specialtyGroup ||
    !formik.values?.priority ||
    (!formik.values?.typeOfVisit && virtualAppropriateBasedOnSpecialty == true) ||
    !formik.values?.isLastMile ||
    (formik.values.services.length == 0 &&
      serviceBasedOnSpecialty &&
      serviceBasedOnSpecialty.length > 1)

  // Render the readonly referral if the body exists *or* it was generated in Elation
  const shouldRenderReferralTemplate =
    Boolean(steerage?.referral?.body) || steerage.isInitiatedFromLucian === false
  // Allow generating a referral template if the referral was *not* generated in Elation
  const shouldRenderGenerateReferralTemplate =
    Boolean(steerage.isInitiatedFromLucian) && steerage.segment === null

  const shouldRenderSaveButton =
    (!steerage.isInitiatedFromLucian || isWaiverOnly) && steerage.segment === null

  const shouldRenderSaveAndSendToElationButton =
    Boolean(steerage.isInitiatedFromLucian && !isWaiverOnly) && steerage.segment === null
  /**
   * End Form States
   */

  /**
   * Collapsible state for the form
   * Keep separate state for collapsing section, but defer to isLocked state so it
   * expands if the steerage is unlocked
   */
  const [collapse, setCollapse] = useState(isLocked)
  const shouldCollapse = !collapse || !isLocked
  let collapseChiplabel: string | null = null
  const sentDate = steerage?.referral?.vendorCreateDate
  if (sentDate) {
    collapseChiplabel = `Sent to Elation on ${format(
      new Date(sentDate),
      'MMM D, YYYY'
    )} at ${format(new Date(sentDate), 'h:mma')}`
  }

  const updateServicesList = (specialtyGroup: string) => {
    // fetch the matching services from Specialty Group or Location Type
    let specialty = specialtyGroupsAndLocationTypeOptions.find(s => s.label === specialtyGroup)
    if (specialty?.services) {
      setServiceBasedOnSpecialty(specialty?.services)
      if (specialty?.services?.length == 1) {
        formik.setFieldValue('services', specialty?.services)
      }
    } else {
      setServiceBasedOnSpecialty([])
      formik.setFieldValue('services', [])
    }
  }

  // Based on specialty value, decide when to show or hide the virtual appropriate dropdown
  let specialty = referralDetails?.specialtiesAndLocationTypes!.find(
    s => s.label === formik.values?.specialtyGroup
  )
  virtualAppropriateBasedOnSpecialty = specialty ? specialty?.doesVirtualPartnerExist : false

  return (
    <Box my={1} mx={1}>
      <form
        onSubmit={e => {
          formik.handleSubmit()
          // Prevent the page from refreshing
          e.preventDefault()
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Grid sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            {isLocked ? (
              <IconButton edge="start" onClick={() => setCollapse(c => !c)}>
                {shouldCollapse ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
            ) : null}
            <Typography fontSize={18} fontWeight="500">
              Referral Request
            </Typography>
          </Grid>
          {collapseChiplabel ? (
            <Chip color="success" size="small" label={collapseChiplabel} />
          ) : null}
        </Box>
        <Collapse in={shouldCollapse}>
          <Grid container columnSpacing={2} rowSpacing={isLocked ? 1 : 3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.requestedByMember}
                    name="requestedByMember"
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                  />
                }
                label="Referral requested by member"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel disabled={isDisabled} required sx={{ ml: -1.5 }}>
                  Member Request Type
                </InputLabel>
                <Select
                  label="Member Request Type"
                  name="requestType"
                  onChange={e => {
                    return formik.handleChange({
                      target: { name: 'requestType', value: e.target.value } as any,
                    } as React.ChangeEvent)
                  }}
                  value={formik.values.requestType}
                  disabled={isDisabled}
                  variant="standard"
                  fullWidth
                >
                  <MenuItem value="broad">Firefly Recommendation</MenuItem>
                  <MenuItem value="specific">Member Preference</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {formik.values.requestType === 'specific' && (
              <Grid item xs={6}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Member preferred provider"
                  name="memberPreferredProvider"
                  onChange={formik.handleChange}
                  value={formik.values.memberPreferredProvider}
                  variant="standard"
                />
              </Grid>
            )}
            {steerage?.person && (
              <Grid item xs={12}>
                <PersonPreference personId={steerage.person} showTitle={false} />
              </Grid>
            )}
            <Grid item xs={6}>
              <Autocomplete
                disabled={isDisabled}
                onInputChange={(_, val) => {
                  if (val === 'Firefly Nearby') {
                    formik.handleChange({
                      target: { name: 'isLastMile', value: 'YES' } as any,
                    } as React.ChangeEvent)
                  } else if (val) {
                    formik.handleChange({
                      target: { name: 'isLastMile', value: 'NO' } as any,
                    } as React.ChangeEvent)
                  } else {
                    formik.handleChange({
                      target: { name: 'isLastMile', value: null } as any,
                    } as React.ChangeEvent)
                  }
                  // Update service list based on the specialty or Location Type
                  updateServicesList(val)

                  return formik.handleChange({
                    target: { name: 'specialtyGroup', value: val } as any,
                  } as React.ChangeEvent)
                }}
                onChange={formik.handleChange}
                value={formik.values.specialtyGroup}
                options={specialtyGroupsAndLocationTypeOptions}
                freeSolo
                renderInput={params => (
                  <TextField required variant="standard" {...params} label="Specialty" />
                )}
              />
            </Grid>
            {/* Only show the serviceBasedOnSpecialty if the length is greater then one as we will auto select the only service*/}
            {serviceBasedOnSpecialty && serviceBasedOnSpecialty.length > 1 ? (
              <Grid item xs={6}>
                <ServiceAutocomplete
                  disabled={isDisabled}
                  services={serviceBasedOnSpecialty ?? []}
                  onChangeService={value => {
                    // This onChange handler emits the selected values *by category*
                    // We need to reassemble the list by ID
                    let newServices: Service[] = []
                    value.forEach(v => {
                      const found = servicesMap.get(v.id)
                      if (found) newServices.push(found)
                    })
                    return formik.handleChange({
                      target: {
                        name: 'services',
                        value: newServices,
                      } as any,
                    } as React.ChangeEvent)
                  }}
                  selectedServices={formik.values.services}
                />
                {formik.errors.services ? (
                  <FormHelperText error>{formik.errors.services}</FormHelperText>
                ) : null}
              </Grid>
            ) : (
              <Grid item xs={6}></Grid>
            )}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel disabled={isDisabled} required sx={{ ml: -1.5 }}>
                  Referral priority
                </InputLabel>
                <Select
                  label="Referral priority"
                  name="priority"
                  value={formik.values.priority}
                  onChange={e => {
                    formik.setFieldValue('priority', e.target.value)
                    formik.setFieldTouched('priority')
                  }}
                  variant="standard"
                  disabled={isDisabled}
                  fullWidth
                  required
                >
                  {Object.keys(ReferralPriority).map(priorityKey => (
                    <MenuItem key={priorityKey} value={priorityKey}>
                      {ReferralPriority[priorityKey]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {virtualAppropriateBasedOnSpecialty ? (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel disabled={isDisabled} required sx={{ ml: -1.5 }}>
                    Virtual appropriate
                  </InputLabel>
                  <Select
                    label="Virtual appropriate"
                    name="typeOfVisit"
                    onChange={e => {
                      return formik.handleChange({
                        target: { name: 'typeOfVisit', value: e.target.value } as any,
                      } as React.ChangeEvent)
                    }}
                    value={formik.values.typeOfVisit}
                    variant="standard"
                    disabled={isDisabled}
                    fullWidth
                    required
                  >
                    <MenuItem value="Any">Yes</MenuItem>
                    <MenuItem value="In Person">No</MenuItem>
                    {/**
                     * The following items are deprecated, but necessary in order to render values from legacy data.
                     * If the API-side choices are removed and a backfill is done, these can be removed.
                     */}
                    <MenuItem sx={{ display: 'none' }} disabled value="Virtual">
                      Virtual
                    </MenuItem>
                    <MenuItem sx={{ display: 'none' }} disabled value="In Person Or Virtual">
                      In Person Or Virtual
                    </MenuItem>
                    <MenuItem sx={{ display: 'none' }} disabled value="Home">
                      In Home
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={6}></Grid>
            )}
            {!isWaiverOnly ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    disabled={isDisabled}
                    fullWidth
                    label="Clinician preferred partner or provider"
                    name="preferredProviderOrFacility"
                    onChange={formik.handleChange}
                    value={formik.values.preferredProviderOrFacility}
                    variant="standard"
                  />
                </Grid>
                {isLocked ? null : (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel disabled={isDisabled} required sx={{ ml: -1.5 }}>
                        Notify clinician if the member doesn&apos;t schedule
                      </InputLabel>
                      <Select
                        error={!!formik.errors.notifyClinicianYn}
                        disabled={isDisabled}
                        value={formik.values.notifyClinicianYn}
                        name="notifyClinicianYn"
                        onChange={e => {
                          return formik.handleChange({
                            target: { name: 'notifyClinicianYn', value: e.target.value } as any,
                          } as React.ChangeEvent)
                        }}
                        label="Notify clinician if the member doesn't schedule"
                        variant="standard"
                        fullWidth
                        required
                      >
                        <MenuItem value="YES">Yes</MenuItem>
                        <MenuItem value="NO">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {shouldRenderReferralTemplate ? (
                  <>
                    <Grid item xs={12}>
                      <Typography fontWeight="500">Referral body</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {steerage?.referral?.body?.length ? (
                        <Typography sx={{ whiteSpace: 'break-spaces' }}>
                          {steerage?.referral?.body}
                        </Typography>
                      ) : (
                        <Typography color="GrayText" fontStyle="italic">
                          No referral body entered in Elation
                        </Typography>
                      )}
                    </Grid>
                  </>
                ) : shouldRenderGenerateReferralTemplate ? (
                  <>
                    <Grid item xs={12}>
                      <Typography fontWeight="500">Referral body</Typography>
                    </Grid>
                    <Grid item xs={12} ml={-1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        name="createReferralTemplate"
                        disabled={isLoading || isSendToElationDisabled}
                        onClick={handleCreateReferralTemplateClick}
                        endIcon={
                          getElationTemplate.isLoading ? (
                            <CircularProgress size="1em" color="inherit" />
                          ) : null
                        }
                      >
                        <Chip
                          icon={<AutoAwesomeIcon />}
                          label={'Generate Referral Template'}
                          variant="outlined"
                          size="small"
                          sx={{
                            color: 'primary.dark',
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                          }}
                          disabled={isLoading || isSendToElationDisabled}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        disabled={isDisabled || getElationTemplate.isLoading}
                        multiline
                        fullWidth
                        label="Referral body"
                        name="referralBody"
                        variant="standard"
                        onChange={formik.handleChange}
                        value={formik.values.referralBody}
                      />
                    </Grid>
                  </>
                ) : null}
              </>
            ) : null}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {shouldRenderSaveAndSendToElationButton ? (
                  <>
                    <Button
                      variant="outlined"
                      type="submit"
                      name="sendToElation"
                      onClick={() => {
                        formik.setFieldValue('sendToElation', true)
                        formik.setFieldTouched('sendToElation')
                      }}
                      disabled={isLoading || isSendToElationDisabled}
                      endIcon={
                        editSteerage.isLoading ? (
                          <CircularProgress size="1em" color="inherit" />
                        ) : null
                      }
                    >
                      Save + Send to Elation
                    </Button>
                  </>
                ) : null}
                {shouldRenderSaveButton ? (
                  <>
                    <Button
                      variant="outlined"
                      type="submit"
                      name="saveReferral"
                      disabled={isLoading}
                      endIcon={
                        editSteerage.isLoading ? (
                          <CircularProgress size="1em" color="inherit" />
                        ) : null
                      }
                    >
                      Save
                    </Button>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </form>
    </Box>
  )
}
