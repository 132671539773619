import { useRef, useState } from 'react'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Search } from '@mui/icons-material'
import { withRouter } from 'react-router'
import debounce from 'debounce-promise'
import { GlobalHotKeys } from 'react-hotkeys'
import Fuse from 'fuse.js'
import { searchPeopleRequest } from '~/requests/search'
import PatientSearchOption from './PatientSearchOption'
import { useRecentlyViewedPeople } from './PeopleSearchUtils'
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Typography,
} from '@mui/material'
import IconSettings from '@mui/icons-material/Settings'
import { useLocalStorage } from '../Providers/LocalStorageProvider'

const Control = props => {
  return (
    <components.Control {...props}>
      <Search style={{ marginLeft: 8, color: '#7b8f9a' }} />
      {props.children}
    </components.Control>
  )
}

const fuseOptions = {
  isCaseSensitive: false,
  distance: 100,
  findAllMatches: false,
  includeMatches: false,
  includeScore: false,
  location: 0,
  minMatchCharLength: 0,
  shouldSort: true,
  threshold: 0.6,
  useExtendedSearch: false,
  keys: ['phoneNumber', 'label', 'id'],
}

const makeSelectOptions = people =>
  people.map(person => ({
    ...person,
    value: person.id,
    label: `${person.firstName} ${person.lastName}`,
    id: person.userId != null ? person.userId : person.id,
  }))

const MAX_RESULTS = 50
const SEARCH_DEBOUNCE_TIME = 300

const debouncedSearch = debounce(
  async (searchTermInput, includeOnboardingInitialized) => {
    const response: any = await searchPeopleRequest({
      search: searchTermInput,
      limit: MAX_RESULTS,
      includeOnboardingInitialized,
    })
    const options = makeSelectOptions(response.data.results)

    const fuse = new Fuse(options, fuseOptions)
    return fuse.search(searchTermInput)
  },
  SEARCH_DEBOUNCE_TIME,
  { leading: true }
)

const PeopleSearch = props => {
  const people = useRecentlyViewedPeople()
  const selectRef = useRef<any>(null)
  const [includeOnboardingInitialized, setIncludeOnboardingInitialized] = useLocalStorage(
    'includeOnboardingInitialized',
    'no'
  )
  const [showOptions, setShowOptions] = useState(false)
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState<HTMLElement | null>(null)

  const hotkeys = {
    FOCUS_SEARCH: event => {
      event.preventDefault()
      if (selectRef && selectRef.current) {
        selectRef.current.focus()
      }
    },
  }

  const loadOptions = input => {
    setShowOptions(false)
    return debouncedSearch(input, includeOnboardingInitialized == 'yes')
  }

  const toggleShowOptions = () => setShowOptions(setShowOptions => !setShowOptions)

  return (
    <>
      <GlobalHotKeys handlers={hotkeys} />
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'inline-flex' }}>
          <AsyncSelect
            value={null}
            onChange={person =>
              person?.userId != null
                ? props.history.push(`/patients/${person?.userId}`)
                : props.history.push(`/people/${person?.id}`)
            }
            placeholder="Search patients"
            components={{ Control, Option: PatientSearchOption }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                display: 'inline-flex',
                width: '32rem',
                height: 38,
                borderRadius: 5,
                backgroundColor: '#f3f3f3',
                border: 'none',
                padding: '0 6px',
              }),
              indicatorSeparator: () => ({ display: 'none' }),
              dropdownIndicator: () => ({ display: 'none' }),
            }}
            defaultOptions={[
              {
                label: 'Recently Viewed',
                options: makeSelectOptions(people),
              },
            ]}
            loadOptions={loadOptions}
            openMenuOnClick
            openMenuOnFocus
            tabSelectsValue={false}
            ref={selectRef}
          />
        </Box>
        <IconButton
          onClick={event => {
            toggleShowOptions()
            setOptionsMenuAnchorEl(event.currentTarget)
          }}
        >
          <IconSettings />
        </IconButton>
        <Popover
          anchorEl={optionsMenuAnchorEl}
          open={showOptions}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setShowOptions(false)}
        >
          <Card>
            <CardContent>
              <Typography variant="caption">Search Options</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeOnboardingInitialized == 'yes'}
                      onChange={() =>
                        setIncludeOnboardingInitialized(
                          includeOnboardingInitialized == 'yes' ? 'no' : 'yes'
                        )
                      }
                    />
                  }
                  label="Include patients in sign-up?"
                />
              </FormGroup>
            </CardContent>
          </Card>
        </Popover>
      </Box>
    </>
  )
}
export default withRouter(PeopleSearch)
