import { Worklist } from '~/redux/slices/worklists'
import { WorklistGridRow, workUnitFields, WorkUnitGridRow } from './WorklistTable'
import { AssigneeGroup, PatientUser, ProviderUser } from '~/legacy/core'
import { WorklistWorkUnit } from '~/models/PatientTodoItem'
import Moment from 'moment'
import { ICaseCategory, ITag } from '~/models/Case'
import { Dictionary } from '@reduxjs/toolkit'
import {
  getGridStringOperators,
  GridFilterInputDate,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro'
import { getAssigneeGroupById, getAssigneeGroupName } from '~/utils'

interface WorklistRowBuilderConstructor {
  worklist: Worklist
  items: WorklistWorkUnit[]
  providers: ProviderUser[] | undefined
  assigneeGroups: AssigneeGroup[] | undefined
  getUserById: (arg0: ProviderUser[], arg1: string) => ProviderUser | PatientUser | undefined
  caseCategoriesById: Dictionary<ICaseCategory>
}

interface WorklistEditorFieldBuilderConstructor {
  row: WorklistGridRow
  isWorklistWithWorkUnits: boolean
}

interface WorklistEditorField {
  displayName: string
  fieldName: string
  id?: number
}

/**
 * Worklists can have rows of 2 types depending on the source data: Worklist Items or Work Units.
 * Use createRows() to build the appropriate type
 */
export class WorklistRowBuilder {
  constructorArgs: WorklistRowBuilderConstructor

  constructor(args: WorklistRowBuilderConstructor) {
    this.constructorArgs = args
  }

  createRows(): WorklistWorkUnit[] {
    // Rows look different for WorklistItem vs. WorkUnit Worklists
    const rows: any[] = []
    const { items } = this.constructorArgs

    for (const item of items) {
      const row = this._createWorkUnitRow(item)

      row.data = { ...row }

      rows.push(row)
    }

    return rows
  }

  _createWorkUnitRow = (item: WorklistWorkUnit): WorkUnitGridRow => {
    const partialRow = this._createPartialRow(item)
    const { providers, getUserById, caseCategoriesById } = this.constructorArgs
    const careTeam = providers
      ? (item.contentObject.personMeta?.careTeam || [])
          .map(providerId => {
            const user: ProviderUser = getUserById(providers, providerId.toString()) as ProviderUser
            if (user) {
              return user.firstName
            }
          })
          .join(', ')
      : null
    const person = `${item.contentObject.personMeta?.firstName} ${item.contentObject.personMeta?.lastName}`
    const createdAt = Moment(item.contentObject.createdAt).format('YYYY-MM-DD')
    const dueDate = item.dueDate ? Moment(item.dueDate).format('YYYY-MM-DD') : null
    const caseTags = item.contentObject.caseTags
    const tags: ITag[] = []
    if (caseTags != null) {
      for (const caseTag of caseTags) {
        tags.push(caseTag.tag)
      }
    }
    const row: WorkUnitGridRow = {
      ...partialRow,
      ownerGroup: item.ownerGroup ? item.ownerGroup.toString() : null,
      pk: item.objectId.toString(),
      links: {
        caseUrl: item.caseUrl,
      },
      description: item.contentObject.description,
      notes: item.contentObject.notes,
      patientId: item.personUser,
      status: item.contentObject.status,
      createdAt: createdAt,
      careTeam: careTeam,
      podNames: item.contentObject.personMeta?.pods
        ? item.contentObject.personMeta.pods.map(pod => pod.name).join(', ')
        : null,
      elationUrl: item.contentObject.personMeta?.elationUrl,
      person: person,
      dueDate: dueDate,
      contentType: item.contentType,
      category: caseCategoriesById[item.contentObject.category]?.title,
      actions: item.contentObject.actions,
      contentObject: item.contentObject, // TODO: Refactor so that any WorkUnit object can be used here. It only works for cases now.
      tags: tags,
    }

    return row
  }

  /**
   * Basic row data required by Worklist rows of all types
   */
  _createPartialRow = (item: WorklistWorkUnit): any => {
    const { worklist, providers, assigneeGroups } = this.constructorArgs

    const partialRow: any = {
      id: item.id,
      worklist: worklist,
      links: null,
    }

    partialRow.ownerGroup = item.ownerGroup ? item.ownerGroup.toString() : undefined

    let assignedToAssigneeGroup =
      providers && item.ownerGroup ? getAssigneeGroupById(providers, item.ownerGroup) : null
    if (!assignedToAssigneeGroup && item.ownerGroup) {
      assignedToAssigneeGroup = assigneeGroups?.find(group => group.id == item.ownerGroup)
    }
    partialRow.assignedToName = assignedToAssigneeGroup
      ? getAssigneeGroupName(assignedToAssigneeGroup)
      : ''

    return partialRow
  }
}

export class WorklistEditorFieldBuilder {
  constructorArgs: WorklistEditorFieldBuilderConstructor

  constructor(args: WorklistEditorFieldBuilderConstructor) {
    this.constructorArgs = args
  }

  createFields(): WorklistEditorField[] {
    return this._createWorkUnitFields()
  }

  _createWorkUnitFields = (): WorklistEditorField[] => {
    return workUnitFields
      .filter(field => field.prop !== 'person')
      .map(field => {
        return {
          displayName: field.headerName,
          fieldName: field.prop,
        } as WorklistEditorField
      })
  }
}
/**
 * buildApplyFilterFn
 * @param filterItem: take seletected filter
 * @param compareFn: take cmpare function
 * @returns filter out items based on compare function
 */
function buildApplyFilterFn(filterItem, compareFn) {
  if (!filterItem.value) {
    return null
  }
  return ({ value }) => {
    if (!value) {
      return false
    }
    return compareFn(new Date(value).getTime(), new Date(filterItem.value).getTime())
  }
}

// TODO: Revisit to enable other operators
export const worklistCareTeamFilterOperators: GridFilterOperator[] =
  getGridStringOperators().filter(operator => operator.value === 'contains')
// TODO: revisit to enable isNot filter
export const worklistNextActionDateFilterOperators: GridFilterOperator<Date>[] = [
  {
    value: 'is',
    getApplyFilterFn: (filterItem: GridFilterItem): any => {
      return buildApplyFilterFn(filterItem, (value1, value2) => value1 === value2)
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: {
      type: 'date',
    },
  },
  {
    value: 'after',
    getApplyFilterFn: filterItem => {
      return buildApplyFilterFn(filterItem, (value1, value2) => value1 > value2)
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: {
      type: 'date',
    },
  },
  {
    value: 'onOrAfter',
    getApplyFilterFn: filterItem => {
      return buildApplyFilterFn(filterItem, (value1, value2) => value1 >= value2)
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: {
      type: 'date',
    },
  },
  {
    value: 'before',
    getApplyFilterFn: filterItem => {
      return buildApplyFilterFn(filterItem, (value1, value2) => value1 < value2)
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: {
      type: 'date',
    },
  },
  {
    value: 'onOrBefore',
    getApplyFilterFn: filterItem => {
      return buildApplyFilterFn(filterItem, (value1, value2) => value1 <= value2)
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: {
      type: 'date',
    },
  },
  {
    value: 'isEmpty',
    getApplyFilterFn: () => {
      return ({ value }) => {
        return value == null
      }
    },
  },
  {
    value: 'isNotEmpty',
    getApplyFilterFn: () => {
      return ({ value }) => {
        return value != null
      }
    },
  },
  {
    label: 'is today',
    value: 'today',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }

      return (params): boolean => {
        return Moment(params.value).toDate() <= Moment().endOf('day').toDate()
      }
    },
  },
  {
    label: 'is overdue',
    value: 'overdue',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }

      return (params): boolean => {
        return Moment(params.value).toDate() < Moment().subtract(1, 'days').endOf('day').toDate()
      }
    },
  },
  {
    label: 'is next 7 days',
    value: 'next_7_days',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null
      }

      return (params): boolean => {
        return (
          Moment(params.value).toDate() >= Moment().subtract(1, 'days').endOf('day').toDate() &&
          Moment(params.value).toDate() <= Moment().add('7', 'days').endOf('day').toDate()
        )
      }
    },
  },
]
