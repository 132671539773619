import { useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import Moment from 'moment'
import { SimpleForm } from '~/legacy/core'
import { Link, Route } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { apiClient } from '~/api/rest'
import SingleFormViewer from './FormViewer/SingleFormViewer'
import { sortBy } from 'lodash/fp'

const formsResource = apiClient.resource<SimpleForm>('/form/')

const FormViewerSettings = () => {
  const [forms, setForms] = useState<SimpleForm[] | null>(null)
  const { classes } = useStyles()

  useEffect(() => {
    const fetchForms = async () => {
      const data = await formsResource.getAll()
      const sortedForms = sortBy('title', data)
      setForms(sortedForms)
    }
    fetchForms()
  }, [])

  if (forms == null) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">Form Viewer</Typography>
      <Box p={2}>
        <Route exact path="/settings/form-viewer">
          <h3>All Forms</h3>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {forms.map(form => (
                <TableRow key={form.id}>
                  <TableCell>
                    {/* Prevent loading Zus forms till we have a viewer implemented for those forms */}
                    {form.vendor === 'Firefly' ? (
                      <Link to={`/settings/form-viewer/${form.id}`}>{form.title}</Link>
                    ) : (
                      form.title
                    )}
                  </TableCell>
                  <TableCell>{form.vendor ?? 'N/A'}</TableCell>
                  <TableCell>{Moment(form.updatedAt).format('LLL')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Route>

        <Route path="/settings/form-viewer/:formId">
          <SingleFormViewer />
        </Route>
      </Box>
    </div>
  )
}

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(2),
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: '25rem',
  },
}))
export default FormViewerSettings
