import { useCallback } from 'react'
import type { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, makeStyles, Typography, Tooltip, useTheme } from '@material-ui/core'
import CheckBox from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import { formatReadAt } from '~/utils/date'
import chatSlice from '~/redux/slices/chat'
import { getIsRead } from '~/utils/chat'

interface ChatMarkReadProps {
  thread: any
}

export const ChatMarkRead: FC<ChatMarkReadProps> = props => {
  const dispatch = useDispatch()
  const { palette } = useTheme()
  const classes = useStyles()
  const providers = useSelector(state => state.providers)
  const me = useSelector(state => state.me)
  const provider = providers.find(p => p.id === props.thread.lastReadBy)
  const providerName = provider ? `${provider.firstName} ${provider.lastName}` : 'Unknown'

  const read = getIsRead(props.thread)
  const lastReadAt = props.thread.lastReadAt
  const timestamp = lastReadAt ? formatReadAt(new Date(lastReadAt)) : null

  const handleMarkRead = useCallback(() => {
    dispatch(
      chatSlice.thunks.markThreadRead({
        patient: props.thread.patient,
        currentReadAt: lastReadAt,
        clinician: me.id,
      })
    )
  }, [props.thread.patient, lastReadAt])

  const handleMarkUnread = useCallback(
    () => dispatch(chatSlice.thunks.markThreadUnread(props.thread.patient, lastReadAt)),
    [props.thread.patient, lastReadAt]
  )

  return (
    <Box mt={1} display="flex" alignItems="center">
      {read ? (
        <>
          <Box height={1} flex={1} mr={1} borderTop={`0.1rem solid ${palette.divider}`} />
          <Typography className={classes.text} color="secondary">
            Read by {providerName} {timestamp}
          </Typography>
          <Tooltip title="Mark unread">
            <IconButton onClick={handleMarkUnread}>
              <CheckBox color="secondary" />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Box height={1} flex={1} borderTop={`0.1rem dashed ${palette.divider}`} />
          <Typography className={classes.text} color="secondary">
            Unread
          </Typography>
          <Tooltip title="Mark read">
            <IconButton onClick={handleMarkRead}>
              <CheckBoxOutlineBlank color="secondary" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  )
}

// This is to match formatting in the ChatReadReceipt component
const useStyles = makeStyles(() => ({ text: { fontSize: '1.1rem' } }))

export default ChatMarkRead
