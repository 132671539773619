import axiosRequest from '~/utils/axios'

export function getPatientsRequest() {
  return axiosRequest('GET', 'application/json', true, '/user/?type=patient&detail=false')
}

export function getPatientRequest(patientId) {
  return axiosRequest('GET', 'application/json', true, '/user/' + patientId + '/')
}

export function patchPatientRequest(editedPatient) {
  return axiosRequest(
    'PATCH',
    'application/json',
    true,
    '/user/' + editedPatient.id + '/',
    editedPatient
  )
}
