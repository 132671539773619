// Pubnub
export const PUBNUB_PUBLISH_KEY = process.env.REACT_APP_PUBNUB_PUBLISH_KEY
export const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const APP_ENV = process.env.REACT_APP_APP_ENV || 'dev'
export const GIT_HASH = process.env.REACT_APP_GIT_HASH || ''

export const FEATURE_VIDEO_MODAL = process.env.REACT_APP_FEATURE_VIDEO_MODAL

export const SERVICE_VERSION = process.env.REACT_APP_SERVICE_VERSION
export const MATERIALUI_KEY = process.env.REACT_APP_MATERIALUI_KEY
export const MATERIALUI_2022_KEY = process.env.REACT_APP_MATERIALUI_2022_KEY

export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
export const DATADOG_ENABLED = process.env.REACT_APP_DATADOG_ENABLED === 'true'
export const AUTH0_ORG_LOGIN_ENABLED = process.env.REACT_APP_AUTH0_ORG_LOGIN_ENABLED === 'true'
