import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { addDays, addMonths, addWeeks, addYears, format, differenceInDays } from 'date-fns'
import FFInput from '~/components/Generic/FFInput'
import Moment from 'moment'
const LETTER_TO_DATE_FN = { d: addDays, w: addWeeks, m: addMonths, y: addYears }
const DATE_FORMAT = 'YYYY-MM-DD'

// Add a relative offset to a date, to return another date.
// Offset is specified as a string with optional sign and units (e.g. '3w', '-2d')
// The default unit is days (d)
const offsetToDate = (offset: string): Date | null => {
  const start = new Date()

  if (!offset.toLowerCase().match(/[dwmy]$/)) {
    offset += 'd'
  }

  const intOffset = parseInt(offset.slice(0, -1), 10)
  if (isNaN(intOffset)) return null

  return LETTER_TO_DATE_FN[offset[offset.length - 1].toLowerCase()](start, intOffset)
}

const dateToOffset = (toStr): string => {
  // Find the difference between the two dates
  // Assuming we were talking about the beginning of today -> beginning of selected day
  // in local timezone
  const from = new Date()
  from.setHours(0, 0, 0, 0)
  // Make sure that 2021-08-05 is parsed to
  // Date Thu Aug 05 2021 00:00:00 (local time)
  const to = Moment(toStr).toDate()
  to.setHours(0, 0, 0, 0)

  let offsetStr
  const dayDifference = differenceInDays(to, from)
  const absDayDifference = Math.abs(dayDifference)
  const prefix = dayDifference >= 0 ? '+' : '-'

  if (absDayDifference === 365) {
    offsetStr = `${Math.floor(dayDifference / 365)}y`
  } else if (absDayDifference === 30) {
    offsetStr = `${Math.floor(absDayDifference / 30)}m`
  } else {
    offsetStr = `${Math.floor(absDayDifference)}d`
  }

  return `${prefix}${offsetStr}`
}

interface DateAndRelativeDateInputProps {
  disabled?: boolean
  value: string // Empty string or YYYY-MM-DD (@ 00:00:00 local time)
  onChange: (arg0: string) => any // arg0 is (YYYY-MM-DD @ 00:00:00 local time)
  classes?: any
  minDate?: string // YYYY-MM-DD or null
}

// Show local timezone, so that picking dates makes sense to the user
// Callback with YYYY-MM-DD in local timezone. Up to the caller to interpret this
const DateAndRelativeDateInput: FC<DateAndRelativeDateInputProps> = props => {
  const [offset, setOffset] = useState('')

  useEffect(() => {
    if (props.value) {
      setOffset(dateToOffset(props.value))
    } else {
      setOffset('')
    }
  }, [props.value])

  const handleOffsetSelection = () => {
    const offsetDate = offsetToDate(offset)
    const newDateStr = offsetDate ? format(offsetDate, DATE_FORMAT) : ''
    props.onChange(newDateStr)
  }

  const handleTextInputSelection = (event: any) => {
    // When a user picks a date, interpret the choice as having been selected in local time
    const dateStr: string = event.target.value // will be formatted as YYYY-MM-DD
    props.onChange(dateStr)
  }

  return (
    <>
      <FFInput
        disabled={props.disabled}
        onChange={e => setOffset(e.target.value)}
        onBlur={handleOffsetSelection}
        value={offset}
        type="text"
        className={props.classes.taskSplitRelativeDate}
        placeholder="T+"
      />
      <FFInput
        disabled={props.disabled}
        onChange={handleTextInputSelection}
        value={props.value}
        type="date"
        className={props.classes.taskSplitLeft}
        placeholder="Add due date"
        InputProps={{ inputProps: { min: props?.minDate } }}
      />
    </>
  )
}

DateAndRelativeDateInput.defaultProps = {
  classes: {},
}

export default DateAndRelativeDateInput
