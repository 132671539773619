import { omit } from 'lodash/fp'

// TODO: Workaround for https://github.com/mui-org/material-ui/issues/16995
export const borderProps = props =>
  omit(props, {
    border: 1,
    borderBottom: 0,
    borderRight: 0,
    borderLeft: 0,
    borderTop: 0,
    borderColor: 'divider',
  })
