import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import {
  INTERNAL_ROLES_LIMITED_BY_PRACTICING_STATES,
  INTERNAL_ROLE_TO_ABBREVIATED,
} from '~/constants/careTeamRoles'
import { sortBy } from 'lodash/fp'
import { useQuery } from '~/components/Providers/ApiProvider'
import Edit from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { getProviders } from '~/redux/actions/providers'
import { ProviderUser } from '~/legacy/core'
import { apiClient } from '~/api/rest'
import EditPracticingStatesModal from './EditPracticingStatesModal'
import UserAvatar from '../Generic/UserAvatar'
import { State } from '~/types'

interface Pod {
  name: string
  uid: string
  id: number
}

const usePods = () => {
  return useQuery(
    ['pods'],
    () => apiClient.rest.get<Pod[]>('/pods/'),
    { staleTime: Infinity },
    {
      error: 'Failed to load care pods',
    }
  )
}

const providerHasEditablePracticingStates = (provider: ProviderUser) =>
  !!provider.providerFields.physicianId

const EmpanelmentSettings = () => {
  const [selectedRole, setSelectedRole] = useState<string | null>(null)
  const [selectedState, setSelectedState] = useState<State | null>(null)

  const providers = useSelector(state => state.providers)
  const { data: states, isLoading: statesIsLoading } = useQuery('states', () =>
    apiClient.rest.get(`/states/`)
  )
  const { data: podData } = usePods()
  const dispatch = useDispatch()

  const [editingPracticingStatesProvider, setEditingPracticingStatesProvider] =
    useState<ProviderUser | null>(null)

  const editPracticingStates = (provider: ProviderUser) => {
    if (providerHasEditablePracticingStates(provider)) {
      setEditingPracticingStatesProvider(provider)
    }
  }

  useEffect(() => {
    if (providers === null) {
      dispatch(getProviders())
    }
  }, [])

  if ((providers || []).length === 0) return <CircularProgress />

  // This page should only show active clinicians who are affected by licensing requirements
  const activeProviders = providers.filter(
    provider =>
      provider.isActive &&
      provider.providerFields?.internalRole &&
      INTERNAL_ROLES_LIMITED_BY_PRACTICING_STATES.has(provider.providerFields.internalRole)
  )
  return (
    <Box overflow="hidden">
      <Box style={{ padding: '10px', marginLeft: '10px', marginTop: '10px' }}>
        <Typography variant="h6">State Licenses</Typography>
      </Box>

      <Box style={{ padding: '10px', marginLeft: '10px' }} display={'flex'}>
        <Box style={{ width: 110 }}>
          <Autocomplete
            size="small"
            options={Array.from(INTERNAL_ROLES_LIMITED_BY_PRACTICING_STATES)}
            getOptionLabel={role => INTERNAL_ROLE_TO_ABBREVIATED[role]}
            onChange={(event, newValue) => {
              setSelectedRole(newValue)
            }}
            renderInput={params => <TextField {...params} size="small" label="Role" />}
          />
        </Box>
        <Box style={{ width: 200, marginLeft: 10 }}>
          <Autocomplete
            size="small"
            options={states as State[]}
            getOptionLabel={state => `${state.name} (${state.abbreviation})`}
            renderInput={params => <TextField {...params} size="small" label="State" />}
            onChange={(event, newValue) => {
              setSelectedState(newValue)
            }}
            disabled={statesIsLoading}
          />
        </Box>
      </Box>
      <EmpanelmentTable
        podData={podData}
        providers={activeProviders}
        selectedState={selectedState}
        selectedRole={selectedRole}
        editPracticingStates={editPracticingStates}
      />
      <EditPracticingStatesModal
        stateOptions={states}
        loading={statesIsLoading}
        provider={editingPracticingStatesProvider}
        handleCancel={() => setEditingPracticingStatesProvider(null)}
      />
    </Box>
  )
}

const EmpanelmentTable = (props: {
  providers: ProviderUser[]
  selectedState: State | null
  selectedRole: string | null
  editPracticingStates: (ProviderUser) => void
  podData: Pod[] | undefined
}) => {
  const { classes } = useStyles()
  const HEADER_COLUMNS = [
    { id: 'accordionIcon', label: '', class: classes.cell },
    { id: 'lastName', label: 'Name', class: classes.headerName },
    { id: 'role', label: 'Role', class: classes.cell },
    { id: 'pod', label: 'Care Pod', class: classes.cell },
    { id: 'practicingStates', label: 'State Licenses', class: classes.cell },
  ]
  const { providers, selectedState, selectedRole, editPracticingStates } = props

  const filterProviders = provider => {
    const providerHasState = selectedState
      ? (provider.providerFields?.practicingStates || []).find(
          state => state.abbreviation === selectedState.abbreviation
        )
      : true
    if (!providerHasState) return false
    return selectedRole ? provider.providerFields.internalRole === selectedRole : true
  }
  const filteredProviders =
    selectedState || selectedRole ? providers.filter(filterProviders) : providers

  const empanelment = sortBy('firstName', filteredProviders)
  return (
    <TableContainer sx={{ maxHeight: '90vh' }}>
      <Table id="empanelment-table" stickyHeader className={classes.root}>
        <TableHead>
          <TableRow>
            {HEADER_COLUMNS.map(headerColumn => (
              <TableCell className={headerColumn.class} key={headerColumn.id}>
                {headerColumn.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {empanelment.map(provider => (
            <EmpanelmentRow
              key={provider.id}
              provider={provider}
              editPracticingStates={editPracticingStates}
              selectedState={selectedState}
              podData={props.podData}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const EmpanelmentRow = (props: {
  provider: ProviderUser
  editPracticingStates: (ProviderUser) => void
  selectedState: State | null
  podData: Pod[] | undefined
}) => {
  const { provider, editPracticingStates, selectedState } = props
  const [openStates, setOpenStates] = useState(false)
  const { classes } = useStyles()

  const practicingStates = provider.providerFields?.practicingStates || []

  const practicingFilteredStates = selectedState
    ? practicingStates.filter(state => state.abbreviation === selectedState.abbreviation)
    : practicingStates

  let podNames: string[] = []
  // TODO: update ProviderFields interface
  if (props.podData && (provider.providerFields as any).pods) {
    podNames = props.podData.reduce((previous, current) => {
      if ((provider.providerFields as any).pods!.includes(current.id)) {
        previous.push(current.name)
      }
      return previous
    }, podNames)
  }

  return (
    <TableRow
      key={provider.id}
      id={`empanelment-provider-${provider.id}`}
      style={{ verticalAlign: 'baseline' }}
    >
      <TableCell width={10}>
        <IconButton aria-label="expand row" size="small" onClick={() => setOpenStates(!openStates)}>
          {openStates ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
        </IconButton>
      </TableCell>
      <TableCell>
        <Box display={'flex'} alignItems="center" flexWrap="wrap">
          <UserAvatar id={provider.id} size="small" />
          <Typography>
            {`${provider.firstName} ${provider.lastName}`}
            {provider.providerFields?.title?.trim()
              ? ` (${provider.providerFields.title.trim()})`
              : ''}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography>
          {provider.providerFields.internalRole &&
            INTERNAL_ROLE_TO_ABBREVIATED[provider.providerFields.internalRole]}
        </Typography>
      </TableCell>
      <TableCell>
        {podNames.length ? <Typography>{podNames.join(', ')}</Typography> : null}
      </TableCell>
      <TableCell>
        <TableCell style={{ borderBottom: 'none' }}>
          <Typography>{practicingStates.length}</Typography>
        </TableCell>
        <Collapse in={openStates} timeout="auto" unmountOnExit>
          {practicingFilteredStates.map(practicingState => (
            <TableRow key={practicingState.abbreviation}>
              <TableCell style={{ borderBottom: 'none', paddingTop: '8px', paddingBottom: '8px' }}>
                <Typography>{practicingState.abbreviation}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </Collapse>
      </TableCell>
      <TableCell style={{ textAlign: 'end' }}>
        {providerHasEditablePracticingStates(provider) && (
          <IconButton onClick={() => editPracticingStates(provider)}>
            <Box className={classes.editIconContainer}>
              <Edit />
            </Box>
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}
const useStyles = makeStyles()(theme => ({
  cell: {
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
    paddingLeft: '1em',
    paddingRight: '1em!important',
  },
  headerName: {
    paddingLeft: '14px!important',
    paddingRight: '1em!important',
  },
  editIconContainer: {
    color: theme.palette.grey[500],
  },
  root: {
    '& .MuiTableCell-root': {
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.grey[100],
      padding: '4px',
    },
  },
}))

export default EmpanelmentSettings
