import { Box, Chip, Typography } from '@mui/material'
import { parse } from 'date-fns'
import { groupBy, partition } from 'lodash'
import { FC, Fragment } from 'react'
import { AvailablePhysicianSlot } from '~/api/AppointmentService'
import { DYNAMIC_SCHEDULE_V2 } from '~/components/Appointment/utils'
import { useFeatureFlag } from '~/utils/useFeatureFlag'

const NoAvailability = () => <p>No availability</p>

type PhysicianSlotPickerGroupProps = {
  slots: AvailablePhysicianSlot[]
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const physicianDisplayName = (slot: AvailablePhysicianSlot) =>
  `${slot.physicianFirstName} ${slot.physicianLastName}, ${slot.physicianTitle}`

const PhysicianSlotPickerGroup: FC<PhysicianSlotPickerGroupProps> = ({
  slots,
  value,
  onChange,
}) => {
  const isDynamicSchedulingV2Enabled: boolean = useFeatureFlag(DYNAMIC_SCHEDULE_V2)

  let slotsByPhysician
  if (isDynamicSchedulingV2Enabled) {
    slotsByPhysician = slots.reduce((app, physician) => {
      if (physician.physicianName && physician.slots) {
        app[physician.physicianName] = {
          slots: physician.slots,
          physicianId: physician.physicianId,
          reason: physician.reason,
        }
      }
      return app
    }, {})
  } else {
    slotsByPhysician = groupBy(slots, physicianDisplayName)
  }

  const sorted = Object.keys(slotsByPhysician).sort() // alphabetical order of physicians

  return (
    <>
      {sorted.map(displayName => {
        const physicianSlots = isDynamicSchedulingV2Enabled
          ? slotsByPhysician[displayName].slots
          : slotsByPhysician[displayName]
        const physicianId = isDynamicSchedulingV2Enabled
          ? slotsByPhysician[displayName].physicianId
          : null
        const reason = isDynamicSchedulingV2Enabled ? slotsByPhysician[displayName].reason : null
        const slotsEl = physicianSlots.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginBottom: 2,
              marginTop: 1,
              flexWrap: 'wrap',
              rowGap: 1,
            }}
          >
            <PhysicianSlotPicker
              slots={physicianSlots}
              physicianId={physicianId}
              reason={reason}
              value={value}
              onChange={onChange}
            />
          </Box>
        ) : (
          <NoAvailability />
        )
        return (
          <Fragment key={displayName}>
            <Typography sx={{ fontSize: '1.4rem', marginTop: 2, fontWeight: 500 }}>
              {displayName}
            </Typography>
            {slotsEl}
          </Fragment>
        )
      })}
    </>
  )
}

type PhysicianSlotPickerProps = {
  slots: AvailablePhysicianSlot[] | string[]
  physicianId: number | null
  reason: string | null
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const PhysicianSlotPicker: FC<PhysicianSlotPickerProps> = ({
  slots,
  physicianId,
  reason,
  value,
  onChange,
}) => {
  const isDynamicSchedulingV2Enabled: boolean = useFeatureFlag(DYNAMIC_SCHEDULE_V2)

  return (
    <>
      {slots.map(slot => {
        const slotDetails = isDynamicSchedulingV2Enabled ? slot : slot.scheduledDate
        const time = parse(slotDetails).toLocaleTimeString(undefined, {
          hour12: true,
          hour: '2-digit',
          minute: '2-digit',
        })
        return (
          <Fragment key={isDynamicSchedulingV2Enabled ? `${physicianId}-${slot}` : slot.id}>
            <Chip
              label={time}
              onClick={() =>
                onChange(
                  isDynamicSchedulingV2Enabled
                    ? {
                        scheduledDate: slot,
                        physicianId: physicianId,
                        reason: reason,
                      }
                    : slot
                )
              }
              color={
                value &&
                (value === slot ||
                  (value.physicianId === physicianId && value.scheduledDate === slot))
                  ? 'primary'
                  : 'default'
              }
              variant="outlined"
              sx={{
                padding: 0,
                marginRight: 1,
                '&.MuiButtonBase-root.MuiChip-colorPrimary': {
                  backgroundColor: '#071F3E',
                  color: 'white',
                  borderColor: '#071F3E',
                },
                '&.MuiButtonBase-root.MuiChip-colorDefault': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Fragment>
        )
      })}
    </>
  )
}

type PhysicianSlotPickerGroupsProps = {
  slots: AvailablePhysicianSlot[]
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const PhysicianSlotPickerGroups: FC<PhysicianSlotPickerGroupsProps> = props => {
  const slots = props.slots
  const [careTeamSlots, othersSlots] = partition(slots, slot => slot.inCareTeam)

  const careTeamEl = careTeamSlots.length ? (
    <Box
      sx={{
        marginTop: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: '#4D5358',
        }}
      >
        {"Patient's care team:"}
      </Typography>
      <PhysicianSlotPickerGroup {...props} slots={careTeamSlots} />
    </Box>
  ) : null

  const othersEl = othersSlots.length ? (
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: '#4D5358',
        }}
      >
        {"Other providers licensed in patient's state:"}
      </Typography>
      <PhysicianSlotPickerGroup {...props} slots={othersSlots} />
    </Box>
  ) : null

  return (
    <>
      {slots.length == 0 ? (
        <Typography sx={{ marginTop: 1 }}>No clinician available</Typography>
      ) : (
        <Box>
          {careTeamEl}
          {othersEl}
        </Box>
      )}
    </>
  )
}

export default PhysicianSlotPickerGroups
