export const MD_ROLE = 'role.MD'
export const NP_ROLE = 'role.NP'
export const RN_ROLE = 'role.RN'
export const HEALTH_GUIDE_ROLE = 'role.HealthGuide'
export const BEHAVIORAL_HEALTH_ROLE = 'role.BehavioralHealth'
export const MEMBER_GUIDE_ROLE = 'role.MemberGuide'
export const REFERRAL_COORDINATOR_ROLE = 'role.ReferralCoordinator'

export const MD_ABBR = 'Dr.' // Not all physicians are MDs (some are DOs, etc.).
export const NP_ABBR = 'NP'
export const RN_ABBR = 'RN'
export const EXTERNAL_CLINICIAN_ABBR = 'External'
export const HEALTH_GUIDE_ABBR = 'HG'
export const BEHAVIORAL_HEALTH_ABBR = 'BH'
export const MEMBER_GUIDE_ABBR = 'MG'
export const REFERRAL_COORDINATOR_ABBR = 'RC'
export const INTERNAL_ROLE_EXTERNAL_CLINICIAN = 'External Clinician'
export const INTERNAL_ROLE_BH_SPECIALIST = 'Behavioral Health Specialist'
export const INTERNAL_ROLE_REGISTERED_NURSE = 'Registered Nurse'
export const INTERNAL_ROLE_NURSE_PRACTITIONER = 'Nurse Practitioner'
export const INTERNAL_ROLE_PHYSICIAN = 'Physician'

export const INTERNAL_ROLE_PHYSICIAN_ABBR = 'Dr.'

export const CARE_TEAM_CAPTION = [
  NP_ROLE,
  MD_ROLE,
  RN_ROLE,
  HEALTH_GUIDE_ROLE,
  BEHAVIORAL_HEALTH_ROLE,
  MEMBER_GUIDE_ROLE,
]
export const ROLES_LIMITED_BY_PRACTICING_STATES = new Set([
  MD_ROLE,
  NP_ROLE,
  RN_ROLE,
  BEHAVIORAL_HEALTH_ROLE,
])

export const CARE_TEAM_ROLE_GROUPS = new Set([
  MD_ROLE,
  NP_ROLE,
  RN_ROLE,
  HEALTH_GUIDE_ROLE,
  BEHAVIORAL_HEALTH_ROLE,
  MEMBER_GUIDE_ROLE,
])

export const ROLE_TO_ABBREVIATED = {
  [MD_ROLE]: MD_ABBR,
  [NP_ROLE]: NP_ABBR,
  [RN_ROLE]: RN_ABBR,
  [HEALTH_GUIDE_ROLE]: HEALTH_GUIDE_ABBR,
  [BEHAVIORAL_HEALTH_ROLE]: BEHAVIORAL_HEALTH_ABBR,
  [MEMBER_GUIDE_ROLE]: MEMBER_GUIDE_ABBR,
  [REFERRAL_COORDINATOR_ROLE]: REFERRAL_COORDINATOR_ABBR,
}

export const INTERNAL_ROLES_LIMITED_BY_PRACTICING_STATES = new Set([
  INTERNAL_ROLE_EXTERNAL_CLINICIAN,
  INTERNAL_ROLE_BH_SPECIALIST,
  INTERNAL_ROLE_REGISTERED_NURSE,
  INTERNAL_ROLE_NURSE_PRACTITIONER,
  INTERNAL_ROLE_PHYSICIAN,
])

export const INTERNAL_ROLE_TO_ABBREVIATED = {
  [INTERNAL_ROLE_EXTERNAL_CLINICIAN]: EXTERNAL_CLINICIAN_ABBR,
  [INTERNAL_ROLE_BH_SPECIALIST]: BEHAVIORAL_HEALTH_ABBR,
  [INTERNAL_ROLE_REGISTERED_NURSE]: RN_ABBR,
  [INTERNAL_ROLE_NURSE_PRACTITIONER]: NP_ABBR,
  [INTERNAL_ROLE_PHYSICIAN]: INTERNAL_ROLE_PHYSICIAN_ABBR,
}
