import { useEffect, useState } from 'react'
import { useZusSessionHandler } from '../../Providers/ZusSessionProvider'
import Loader from '../../Loader'
import { Box, Paper } from '@mui/material'
import { ExpandedModeControls } from '~/utils/useExpandedMode'
import { logger } from '~/utils/logger'
import { Props } from '.'

interface ZapMessageEvent {
  data: {
    type: string
  }
}
const ZapIFrameConfigMessageType = 'ZusAggregatedProfileIFrameConfig'
const ZapIFrameReadyMessageType = 'ZusAggregatedProfileIFrameReady'

/**
 * An embedded view of Zus' Aggregated Profile
 * showing health information for the patient
 * Docs: https://docs.zushealth.com/docs/embeddable-ui-components-implementation-guide
 */
const ZusAggregatedProfileEmbed: React.FC<Props> = props => {
  const zusSession = useZusSessionHandler()
  const [iFrameRef, setIFrameRef] = useState<HTMLIFrameElement | null>(null)
  const [hostedZapReady, setHostedZapReady] = useState(false)

  useEffect(() => {
    // listener to know when iframe ZAP is ready
    const onMessageReady = ({ data }: ZapMessageEvent) => {
      if (data.type === ZapIFrameReadyMessageType) {
        logger.info('Received ZAP message: ZapIFrameReadyMessageType', data)
        setHostedZapReady(true)
      }
    }
    window.addEventListener('message', onMessageReady)
    return () => {
      window.removeEventListener('message', onMessageReady)
    }
  }, [])

  const loadConfig = async () => {
    const CTWProviderProps = {
      authToken: zusSession.accessToken,
      env: process.env.REACT_APP_ZUS_ENV,
      ehr: 'Firefly',
    }

    const PatientProviderProps = {
      patientID: props.patient.person.id,
      systemURL: process.env.REACT_APP_ZUS_PATIENT_SYSTEM,
    }
    const config = {
      type: ZapIFrameConfigMessageType,
      payload: {
        CTWProviderProps: CTWProviderProps,
        PatientProviderProps: PatientProviderProps,
        ZusAggregatedProfileProps: {
          patientConditionsAllProps: {
            onlyAllowAddOutsideConditions: true,
          },
        },
      },
    }
    iFrameRef!.contentWindow!.postMessage(config, process.env.REACT_APP_ZUS_ZAP_URL as string)
  }

  useEffect(() => {
    if (hostedZapReady && iFrameRef?.contentWindow) {
      loadConfig()
    }
  }, [hostedZapReady, process.env.REACT_APP_ZUS_ZAP_URL, iFrameRef?.contentWindow])

  const isLoading = !zusSession

  if (isLoading) return <Loader />

  return (
    <Box paddingX={1} height="75vh" position="relative">
      <Box position="sticky" display="inline-block" top={0} component={Paper} marginBottom={-0.5}>
        <ExpandedModeControls />
      </Box>
      <iframe
        title="Embedded ZAP"
        id="zus-aggregated-profile"
        src={process.env.REACT_APP_ZUS_ZAP_URL}
        ref={setIFrameRef}
        width="100%"
        height="100%"
        style={{ overflow: 'scroll' }}
      />
    </Box>
  )
}

export default ZusAggregatedProfileEmbed
