import type { FC } from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core'

// Inherit default HTML button props
interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

/**
 * This is a bare HTML button with style overrides for general use
 */
export const Button: FC<ButtonProps> = props => {
  const { button } = useStyles()
  return <button {...props} className={cx(props.className, button)} />
}

export default Button

const useStyles = makeStyles(() => ({
  button: {
    background: 0,
    border: 0,
    cursor: 'pointer',
    font: 'inherit',
    padding: 0,
  },
}))
