import { FC, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TreeItem } from '@material-ui/lab'
import { Worklist } from '~/redux/slices/worklists'
import { Typography } from '@material-ui/core'
import { useWorkListsById } from '../../../api/WorkListsService'

interface WorklistNameProps {
  worklist: Worklist
  selectWorklist: (number) => void
  worklistId: number | undefined
}

export const WorklistName: FC<WorklistNameProps> = (props: WorklistNameProps) => {
  const styles = useStyles()
  // TODO: use count api
  const { result: workListsById } = useWorkListsById()
  const workList = useMemo(
    () => (props.worklist ? workListsById[props.worklist.id] : undefined),
    [props.worklist, workListsById]
  )

  return (
    <TreeItem
      onClick={() => {
        props.selectWorklist(props.worklist.id)
      }}
      classes={{
        content: styles.treeItemContent,
        root: styles.treeItemRoot,
      }}
      className={props.worklist.id === props.worklistId ? styles.selected : ''}
      nodeId={props.worklist.id.toString()}
      label={
        <div className={styles.labelRoot}>
          <Typography variant="body1" color="inherit" className={styles.labelText}>
            {props.worklist.name}
          </Typography>
          <Typography variant="caption" color="inherit" className={styles.countText}>
            {workList && workList.itemCount}
          </Typography>
        </div>
      }
    ></TreeItem>
  )
}
const useStyles = makeStyles(theme => ({
  treeItemRoot: {
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(-2),
  },
  treeItemContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    cursor: 'pointer',
  },
  labelText: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  countText: {
    width: '1em',
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
}))
export default WorklistName
