import { apiClient } from '~/api/rest'
import { useQuery } from '../Providers/ApiProvider'
import { Message } from './Chat'
import { IChatMessage } from './Chat'

const getMessageRequest = message => {
  return apiClient.rest.get<{ text: string }>(`/chat/v2/message/${message.uid}/`)
}

// Real-time message events are always redacted for VIP patients
// because the publisher of the event doesn't know which users are subscribing
// and so can't test whether or not users have access to the VIP patient data
//
// As a workaround: poll and attempt to fetch the un-redacted message from the back-end
// Because of the asynchronous nature of persisting the message in the back-end, it is not guaranteed
// to be present at the time of receiving the real-time message event
//
// This polling is potentially expensive
// but OK for now b/c it is limited to patients sitting and waiting on a VIP patient profile
// We intentionally don't do this for all messages coming in at the top level e.g. in redux
const useUnRedactMessages = (message: Message) => {
  const needsToBeUnRedacted = !!(
    message.kind != 'phonecall' &&
    (message.isRedacted || message.text == '*****') &&
    !message.id
  )
  const getUnRedactedMessageTextQuery = useQuery(
    ['fetchUnRedactedMessage', (message as IChatMessage).uid],
    () => getMessageRequest(message),
    { enabled: needsToBeUnRedacted, retry: 2, retryDelay: 500 }
  )

  return {
    isLoading: !!(needsToBeUnRedacted && !getUnRedactedMessageTextQuery.data),
    unRedactedText: getUnRedactedMessageTextQuery.data?.text || null,
  }
}

export default useUnRedactMessages
