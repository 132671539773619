import { FC, useEffect, useState } from 'react'
import { Button, Box, Typography } from '@mui/material'
import { FFModal } from '~/components/FFModal/FFModal'
import { AppointmentType, VisitMix } from './utils'
import VisitMixEditor from './VisitMixEditor'

interface Props {
  appointmentTypes: AppointmentType[]
  visitMix: Record<number, Record<string, VisitMix>> | null
  dayOfWeek: number
  fullDayName: string
  handleCancel: () => void
  handleSubmit: (visitMix: Record<number, Record<string, VisitMix>>) => void
}

const EditVisitMixModal: FC<Props> = (props: Props) => {
  const [visitMixData, setVisitMixData] = useState<Record<number, Record<string, VisitMix>>>({})
  const [appointmentTypesData, setAppointmentTypesData] = useState<AppointmentType[]>([])
  const [isEdited, setIsEdited] = useState<boolean>(false)
  const [error, setError] = useState<boolean[]>([])
  const [displayError, setDisplayError] = useState<boolean>(false)
  const [totalError, setTotalError] = useState<boolean>(false)

  useEffect(() => {
    if (!props.visitMix) return
    setVisitMixData(props.visitMix)
    setAppointmentTypesData(props.appointmentTypes)
    setError(props.appointmentTypes.map(_ => false))
  }, [props.visitMix, props.appointmentTypes])

  const handleUpdateVisitMix = (visitMixPercentage, apptUniqueKey) => {
    setVisitMixData(prevVisitMixData => {
      return {
        ...prevVisitMixData,
        [props.dayOfWeek]: {
          ...prevVisitMixData[props.dayOfWeek],
          [apptUniqueKey]: {
            ...prevVisitMixData[props.dayOfWeek][apptUniqueKey],
            percentage: visitMixPercentage,
            isEdited: true,
          },
        },
      }
    })
    const total = Object.keys(visitMixData[props.dayOfWeek])
      .map(apptType =>
        apptType === apptUniqueKey
          ? visitMixPercentage
          : visitMixData[props.dayOfWeek][apptType].percentage
      )
      .reduce((val, acc) => acc + val, 0.0)
    if (total < 99 || total > 101) setTotalError(true)
    else setTotalError(false)
    setIsEdited(true)
  }

  const handleUpdate = () => {
    if (error.reduce((val, acc) => acc || val, false)) setDisplayError(true)
    else {
      props.handleSubmit(visitMixData)
      setDisplayError(false)
      setIsEdited(false)
    }
  }

  const handleCancel = () => {
    setDisplayError(false)
    setIsEdited(false)
    props.handleCancel()
  }

  if (!props.visitMix) return null

  return (
    <FFModal
      open={true}
      header={
        <Box p={1}>
          <Typography variant="h6" align="left">
            Edit Visit Mix
          </Typography>
        </Box>
      }
      footer={
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Box ml={1}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={handleUpdate}
              disabled={!isEdited}
            >
              Update
            </Button>
          </Box>
        </Box>
      }
    >
      <Box m={2}>
        <Typography align="left" sx={{ fontSize: '1.6rem', fontWeight: 500 }}>
          {props.fullDayName}
        </Typography>
        <Box mt={2} display="flex">
          {appointmentTypesData.map((apptType, i) => (
            <Box ml={2} key={i} width="25%" minWidth="120px">
              <VisitMixEditor
                totalError={totalError}
                apptType={apptType}
                visitMixPercentage={
                  visitMixData[props.dayOfWeek][apptType.uniqueKey]?.percentage || null
                }
                displayError={displayError}
                onChange={visitMixPercentage =>
                  handleUpdateVisitMix(visitMixPercentage, apptType.uniqueKey)
                }
                onError={error =>
                  setError(prevError => {
                    prevError[i] = error
                    return prevError
                  })
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
    </FFModal>
  )
}

export default EditVisitMixModal
