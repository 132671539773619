import { useState } from 'react'
import { TextareaAutosize, makeStyles } from '@material-ui/core'
import getInputStyles from '~/utils/getInputStyles'
import { FullNoteWrapper, InlineNoteWrapper } from './NoteInputWrappers'

const NoteInput = props => {
  const classes = useStyles()
  const [note, setNote] = useState('')
  const handleChange = event => {
    setNote(event.target.value)
  }
  const NoteInputWrapper = props.inline ? InlineNoteWrapper : FullNoteWrapper
  async function handleSubmit() {
    await props.onSubmit(note)
    setNote('')
  }
  return (
    <NoteInputWrapper disabled={note.length === 0 || props.loading} onClick={handleSubmit}>
      <TextareaAutosize
        className={classes.noteInput}
        name="Note"
        aria-label="note textarea"
        placeholder="Add note"
        value={note}
        disabled={props.loading}
        onChange={handleChange}
      />
    </NoteInputWrapper>
  )
}

const useStyles = makeStyles({
  noteInput: getInputStyles({
    fontSize: '1.4rem',
    flex: '1 1 auto',
  }),
})

export default NoteInput
