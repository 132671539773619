import type { FC } from 'react'
import FFInput from '~/components/Generic/FFInput'
import { useState, useEffect } from 'react'
import { AppointmentType } from './utils'
import { InputAdornment } from '@mui/material'

export interface VisitMixEditorProps {
  apptType: AppointmentType
  visitMixPercentage: number | null
  displayError: boolean
  totalError: boolean
  onChange: (visitMixPercentage: number) => any
  onError: (error: boolean) => any
}

const VisitMixEditor: FC<VisitMixEditorProps> = props => {
  const { visitMixPercentage, apptType, displayError, totalError } = props
  const [boundErrorText, setBoundErrorText] = useState<string>('')

  useEffect(() => {
    let error = false
    if (visitMixPercentage && (visitMixPercentage > 100 || visitMixPercentage < 0)) {
      if (displayError) setBoundErrorText('Between 0 and 100')
      error = true
    } else if (visitMixPercentage && totalError) {
      if (displayError) setBoundErrorText('Total not 100')
      error = true
    } else setBoundErrorText('')
    props.onError(error)
  }, [visitMixPercentage, displayError, totalError])

  return (
    <FFInput
      error={!!boundErrorText}
      variant="outlined"
      onChange={event => props.onChange(parseFloat(event.target.value))}
      value={visitMixPercentage || ''}
      label={apptType.name}
      type="number"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        inputProps: {
          min: 0,
          max: 100,
        },
      }}
      helperText={boundErrorText}
    />
  )
}

export default VisitMixEditor
