import { makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
  className?: string
  titleAccess?: string
  fill?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    height: '1em',
    width: '1em',
  },
}))

const FFElation = (props: Props) => {
  const classes = useStyles()
  return (
    <svg viewBox="0 0 28 34" className={props.className || classes.svg}>
      <path
        fill={props.fill || 'currentColor'}
        d="M13.5,6.9 C13.5,7.3 13.2,7.6 12.8,7.6 L11.6,7.6 C11.2,7.6 10.9,7.3 10.9,6.9 L10.9,0.7 C10.9,0.3 11.2,0 11.6,0 L12.8,0 C13.2,0 13.5,0.3 13.5,0.7 L13.5,6.9 Z"
        fillRule="nonzero"
      />
      <path
        fill={props.fill || 'currentColor'}
        d="M27.8,33.5 L11,33.5 C11,26 11,18.4 11,10.9 L27.8,10.9 L27.8,15 L15.2,15 L15.2,20.2 L27.4,20.2 L27.4,24.2 L15.2,24.2 L15.2,29.3 L27.8,29.3 L27.8,33.5 Z"
        fillRule="nonzero"
      />
      <path
        fill={props.fill || 'currentColor'}
        d="M0.7,13.5 C0.3,13.5 0,13.2 0,12.8 L0,11.6 C0,11.2 0.3,10.9 0.7,10.9 L6.9,10.9 C7.3,10.9 7.6,11.2 7.6,11.6 L7.6,12.8 C7.6,13.2 7.3,13.5 6.9,13.5 L0.7,13.5 Z"
        fillRule="nonzero"
      />
      <path
        fill={props.fill || 'currentColor'}
        d="M16.8,9.4 C16.5,9.7 16.1,9.7 15.8,9.4 L14.9,8.5 C14.6,8.2 14.6,7.8 14.9,7.6 L19.3,3.2 C19.6,2.9 20,2.9 20.3,3.2 L21.2,4.1 C21.5,4.4 21.5,4.8 21.2,5.1 L16.8,9.4 Z"
        fillRule="nonzero"
      />
      <path
        fill={props.fill || 'currentColor'}
        d="M5,21.2 C4.7,21.5 4.3,21.5 4,21.2 L3.1,20.3 C2.8,20 2.8,19.6 3.1,19.4 L7.6,15 C7.9,14.7 8.3,14.7 8.6,15 L9.5,15.9 C9.8,16.2 9.8,16.6 9.5,16.9 L5,21.2 Z"
        fillRule="nonzero"
      />
      <path
        fill={props.fill || 'currentColor'}
        d="M3.2,5 C2.9,4.7 2.9,4.3 3.2,4 L4.1,3.1 C4.4,2.8 4.8,2.8 5,3.1 L9.4,7.5 C9.7,7.8 9.7,8.2 9.4,8.5 L8.5,9.4 C8.2,9.7 7.8,9.7 7.5,9.4 L3.2,5 Z"
        fillRule="nonzero"
      />
      {props.titleAccess ? <title>{props.titleAccess}</title> : null}
    </svg>
  )
}
FFElation.displayName = 'FFElation'

export default FFElation
