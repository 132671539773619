import { useState, useEffect } from 'react'
import type { FC } from 'react'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import Linkify from '~/components/Linkify'
import ChatReadReceipt from './ChatReadReceipt'
import { styles } from './styles'
import { PatientUser, ProviderUser } from '~/legacy/core'
import { ICase, MessageIdentifier } from '~/models/Case'
import { useDrag } from 'react-dnd'
import UserAvatar from '../Generic/UserAvatar'
import { makeStyles } from 'tss-react/mui'
import { Badge, Box, Checkbox } from '@mui/material'
import useGetUnRedactedMessage from './useGetUnRedactedMessage'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { patientName } from '~/utils/users'
import MessageInfo from './MessageInfo'
import MessageActions from './MessageActions'
import { SelectedChatMessage } from '~/models/Communication'
import Loader from '../Loader'
import { IChatMessage } from './Chat'
import { findMessageIdentifier, messageDateInfo, useMessageCaseInfo } from './utils'
import { isUrgent } from '~/utils/chat'

const useStyles = makeStyles()(theme => {
  return {
    ['@keyframes fadeAnimation']: {
      from: { backgroundColor: theme.palette.background.highlight },
      to: { backgroundColor: 'white' },
    },
    message: {
      ...styles.message(theme),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      messageInner: {
        maxWidth: '90%',
      },
    },
    highlightChatMessage: {
      padding: '1rem',
      borderRadius: '1rem',
      animation: '2s $fadeAnimation',
    },
    messageText: {
      position: 'relative',
      padding: theme.spacing(1.25, 3),
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      fontSize: '1.6rem',
    },
    messageTextMine: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      ['& a, & a[href].sw-phone:link']: {
        color: `${theme.palette.common.white} !important`, // Dialpad makes their links unreadable blue and uses !important :rolls-eyes:
        textDecoration: 'underline',
      },
    },
    messageTextProvider: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(2),
      backgroundColor: theme.palette.primary[100],
      color: theme.palette.text.primary,
      ['& a']: {
        color: theme.palette.primary.main,
      },
    },
    messageTextPatient: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: theme.spacing(2),
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.text.primary,
      ['& a']: {
        color: theme.palette.primary.main,
      },
    },
    messageOutlined: {
      outline: 'solid black',
    },
    messageName: {
      width: '100%',
      fontSize: '1.2rem',
      marginBottom: '0.25rem',
      textAlign: 'left',
    },
    messageDate: styles.messageDate(theme),
    messageActionContainer: {
      display: 'flex',
      alignItems: 'center',
      ['&:last-child']: {
        marginRight: '4rem',
      },
    },
    higlightContent: {
      borderRadius: '1rem',
      backgroundColor: theme.palette.background.highlight,
    },
    urgentMessage: {
      backgroundColor: '#EA5329',
    },
  }
})

interface Props {
  message: IChatMessage
  user: PatientUser | ProviderUser
  mine: boolean
  highlightChatMessage: boolean
  higlightChatMessageContent?: boolean
  patient: PatientUser
  messageCases: ICase[]
  patientReadAt: string | null
  isLastMessage: boolean
  isSelectingMultiple: boolean
  setIsSelectingMultiple: (arg0: boolean) => void
  multiSelectedMessages: SelectedChatMessage[]
  toggleMultiSelectMessage: (message: SelectedChatMessage) => void
}

const ChatMessage: FC<Props> = props => {
  const {
    children,
    user,
    mine,
    message,
    highlightChatMessage,
    higlightChatMessageContent,
    patient,
    patientReadAt,
    isLastMessage,
    setIsSelectingMultiple,
  } = props
  const { text, id } = message

  const { classes } = useStyles()
  const { unRedactedText, isLoading: unRedactedMessageIsLoading } = useGetUnRedactedMessage(message)
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: props.isSelectingMultiple ? 'multiselectedMessages' : 'messageIdentifier',
      item: (): MessageIdentifier | MessageIdentifier[] => {
        if (props.isSelectingMultiple) {
          return props.multiSelectedMessages.map(message => findMessageIdentifier(message))
        }
        return findMessageIdentifier(message)
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop()) {
          props.setIsSelectingMultiple(false)
        }
      },
    }),
    [props.isSelectingMultiple, props.multiSelectedMessages, message.id, message.uid]
  )

  /*
    While using the drag and drop library, we cannot select the text within the drag component, as it drags the component.
    To overcome this, we manually set the `mouseEnablesDrag` attribute based on the cursor position and where it is clicked.
    If the cursor is on the chat bubble and not on the text, then the mouseEnablesDrag attribute is set to true else false.
  */
  const [mouseOnChatText, setMouseOnChatText] = useState<boolean>(false)
  const [mouseDownOnChatBox, setMouseDownOnChatBox] = useState<boolean>(false)
  const [mouseEnablesDrag, setMouseEnablesDrag] = useState<boolean>(true)

  const draggable =
    mouseEnablesDrag &&
    (props.isSelectingMultiple
      ? props.multiSelectedMessages.map(el => el.uid).includes(message.uid)
      : true)

  const preferredName = user.isPatient ? patientName(user) : user.firstName
  const name = `${preferredName} ${user.lastName}` || ''
  const avatarSrc =
    (user.isPatient ? props.patient.person.avatar : user.providerFields.avatar) ?? undefined

  const { messageDate, preciseDate } = messageDateInfo(message)
  let messageClass = classes.messageText

  // Controls for the "More" menu in <MessageActions />
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const menuIsOpen = Boolean(menuAnchorEl)

  const { messageIdToCaseInfo } = useMessageCaseInfo(patient.id, patient.person.id)
  const hasLinkedCases = ((messageIdToCaseInfo || {})[message.id] || []).length > 0

  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Avoid the hover menu from overlapping with the DOM snapshot
  // during drag-and-drop
  const showMessageActions = !(isDragging || mouseDownOnChatBox || props.isSelectingMultiple)
  const messageIsIncludedInMultiSelect = props.multiSelectedMessages
    .map(el => el.uid)
    .includes(message.uid)
  // UI indication of "I'm dragging multiple messages"
  const showMultiSelectHint =
    props.multiSelectedMessages.length != 0 && messageIsIncludedInMultiSelect
  const multiSelectOtherCountText = (props.multiSelectedMessages || []).length.toString()

  if (mine) {
    messageClass += ` ${classes.messageTextMine}`
  } else {
    if (user.isProvider) {
      messageClass += ` ${classes.messageTextProvider}`
    } else if (user.isPatient) {
      messageClass += ` ${classes.messageTextPatient}`
    }
  }

  const chatMessageClasses = classNames({
    [classes.highlightChatMessage]: highlightChatMessage,
    [classes.higlightContent]: higlightChatMessageContent,
  })

  useEffect(() => {
    if (!isDragging) {
      setMouseDownOnChatBox(false)
    }
  }, [isDragging])

  useEffect(() => {
    if (mouseDownOnChatBox && !mouseOnChatText) {
      setMouseEnablesDrag(true)
    } else {
      setMouseEnablesDrag(false)
    }
  }, [mouseOnChatText, mouseDownOnChatBox])

  const messageContents = unRedactedMessageIsLoading ? (
    <Typography>
      <MoreHorizIcon />
    </Typography>
  ) : (
    <>
      <Typography
        style={{ cursor: 'text' }}
        onMouseOver={() => setMouseOnChatText(true)}
        onMouseOut={() => setMouseOnChatText(false)}
        onFocus={() => setMouseOnChatText(true)}
        onBlur={() => setMouseOnChatText(false)}
      >
        {unRedactedText || text}
      </Typography>
    </>
  )

  return (
    <Box mt={2}>
      <Box
        className={chatMessageClasses}
        data-id={id}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        sx={{
          position: 'relative',
          '&:hover .message-actions-hover-control': {
            opacity: '1 !important',
          },
        }}
      >
        {/* Left: avatar */}
        <Box>
          <UserAvatar
            id={user.id}
            avatarStyle={{
              margin: '0 1rem 0 0',
              order: -2,
            }}
            src={avatarSrc}
          />
        </Box>
        {/* Right: message contents */}
        <Box flexGrow={1}>
          <Box display="flex" flexDirection={'row'} mb={0.5}>
            {!mine && (
              <Typography sx={{ fontSize: '1.2rem' }} mr={2}>
                {name}
              </Typography>
            )}
            <Typography title={preciseDate} sx={{ fontSize: '1.2rem' }}>
              {messageDate}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
              flex: '0 0 100%',
            }}
          >
            {props.isSelectingMultiple && findMessageIdentifier(message) ? (
              <Box ml={-1}>
                <Checkbox
                  checked={messageIsIncludedInMultiSelect}
                  onChange={_event => props.toggleMultiSelectMessage(message)}
                  sx={{
                    '& svg': {
                      color: 'grey.500',
                    },
                  }}
                />
              </Box>
            ) : null}
            {isLoading && <Loader />}
            <Box
              sx={{
                opacity: menuIsOpen || !hasLinkedCases ? 1 : 0,
                position: 'absolute',
                right: 0,
                top: -10,
                border: '1px solid',
                padding: 0.5,
                borderColor: 'secondary.100',
                backgroundColor: 'white',
                borderRadius: '10px',
                display: showMessageActions ? 'initial' : 'none',
                zIndex: 99,
              }}
              className="message-actions-hover-control"
            >
              <MessageActions
                isLastMessage={isLastMessage}
                message={message}
                patient={patient}
                menuIsOpen={menuIsOpen}
                menuAnchorEl={menuAnchorEl}
                setMenuAnchorEl={setMenuAnchorEl}
                startMultiSelect={() => setIsSelectingMultiple(true)}
                setIsSelectingMultiple={setIsSelectingMultiple}
                setIsLoading={setIsLoading}
              />
            </Box>
            <Box
              ref={drag}
              draggable={draggable}
              role="button"
              tabIndex={0}
              onMouseDown={() => setMouseDownOnChatBox(!mouseOnChatText)}
              onMouseUp={() => setMouseDownOnChatBox(false)}
              onDragStart={event => {
                // The `draggable` prop allows dragging even when the value is false
                // Block drag events here instead
                if (!draggable) event.preventDefault()
              }}
              sx={{
                justifyContent: 'flex-start',
                display: 'flex',
                flexWrap: 'wrap',
                opacity: isDragging ? 0.5 : 1,
                position: 'relative',
                justifyItems: 'center',
              }}
            >
              <Box>
                <Badge
                  color="secondary"
                  badgeContent={multiSelectOtherCountText}
                  aria-label={multiSelectOtherCountText}
                  invisible={!showMultiSelectHint}
                  sx={{
                    flex: 1,
                    marginRight: '1rem',
                    display: 'block',
                  }}
                >
                  {isUrgent(message) ? (
                    <Badge
                      variant="dot"
                      classes={{ badge: classes.urgentMessage }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Linkify>
                        {text && text.length ? (
                          <Box
                            className={messageClass}
                            sx={{
                              cursor: 'move',
                            }}
                          >
                            {messageContents}
                          </Box>
                        ) : null}
                      </Linkify>
                    </Badge>
                  ) : (
                    <Linkify>
                      {text && text.length ? (
                        <Box
                          className={messageClass}
                          sx={{
                            cursor: 'move',
                          }}
                        >
                          {messageContents}
                        </Box>
                      ) : null}
                    </Linkify>
                  )}
                  {children}
                </Badge>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box ml={6} mt={1}>
        <MessageInfo patient={patient} message={message} />
      </Box>
      {patientReadAt ? <ChatReadReceipt readAt={patientReadAt} /> : null}
    </Box>
  )
}

export default ChatMessage
