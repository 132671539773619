import { Grid, IconButton } from '@mui/material'
import TaskEditor, { TaskEditorProps } from '~/components/Tasks/TaskEditor'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import type { FC } from 'react'
import { memo } from 'react'

interface TaskEditorRowProps {
  i: number
  patient: any // TODO: Patient object
  task: TaskEditorProps['task'] & { isComplete: boolean }
  classes: { [key: string]: string }
  disabledEdit: boolean
  disabledRemove: boolean
  onChange: (i: number, value: any) => any
  onRemove: (i: number) => any
}

// A single row in the care plan modal.
const TaskEditorRow: FC<TaskEditorRowProps> = props => {
  const { i, patient, task, classes, disabledEdit, disabledRemove, onChange, onRemove } = props
  const TaskIcon = task.isComplete ? CheckCircleOutlineIcon : CloseIcon
  return (
    <Grid container>
      <Grid item xs={11}>
        <TaskEditor
          className={classes.taskEditor}
          disabled={disabledEdit}
          task={task}
          onChange={value => onChange(i, value)}
          patient={patient}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          disabled={disabledRemove}
          onClick={() => onRemove(i)}
          className={classes.formAction}
        >
          <TaskIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default memo(TaskEditorRow)
