import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from 'tss-react/mui'
import SearchIcon from '@mui/icons-material/Search'
import { TextField, InputAdornment, Box, FormControlLabel, Grid } from '@mui/material'
import { CPTCode } from '~/legacy/core'

import { LocationTypeSearchCategory, SpecialtyGroupSearchCategory } from './ProviderSearch'
import EditField from '~/components/Generic/EditField'
import { Checkbox } from '@mui/material'
import { SpecialtyDropdownOption } from '../SteerageView/types'
const useStyles = makeStyles()(theme => {
  return {
    selectAutoComplete: {
      width: '100%',
    },
  }
})

interface SpecialtyGroupSearchOptionsPickerProps {
  specialtyGroups: SpecialtyDropdownOption[]
  cptCodes: CPTCode[]
  onSelect: (
    locationType: LocationTypeSearchCategory | null,
    specialtyGroup: SpecialtyGroupSearchCategory | null,
    npi: string | null,
    providerName: string | null,
    gender: string | null,
    facilityName: string | null,
    procedureCode: CPTCode | null
  ) => void
  disabled: boolean
  loading: boolean
  selectedSpecialtyGroup: SpecialtyGroupSearchCategory | null
  selectedNPI: string | null
  selectedProviderName: string | null
  selectedGender: string | null
  selectedProcedureCode: CPTCode | null
  setSearchRadius: (val: number) => void
}

// Set gender to default F for OBGYN
export const SPECIALTY_TO_GENDER = {
  '(OBGYN) Obstetrics and Gynecology': 'F',
}

export const ProviderSearchOptionsPicker: FC<SpecialtyGroupSearchOptionsPickerProps> = (
  props: SpecialtyGroupSearchOptionsPickerProps
) => {
  const [specialtyGroup, setSpecialtyGroup] = useState<SpecialtyGroupSearchCategory | null>(
    props.selectedSpecialtyGroup
  )
  const [searchString, setSearchString] = useState<string>('')
  const [cptCodeSearchString, setCPTCodeSearchString] = useState<string>('')
  const [npi, setNPI] = useState<string | null>(props.selectedNPI)
  const [providerName, setProviderName] = useState<string | null>(props.selectedProviderName)
  const [procedureCode, setProcedureCode] = useState<CPTCode | null>(props.selectedProcedureCode)
  const isFemale = props.selectedGender == null || props.selectedGender === 'F'
  const isMale = props.selectedGender == null || props.selectedGender === 'M'

  const handleCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newGender: string | null = null
      if (props.selectedGender == null) newGender = e.target.value === 'F' ? 'M' : 'F'
      props.onSelect(null, specialtyGroup, npi, providerName, newGender, null, procedureCode)
    },
    [props.selectedGender, props.onSelect, specialtyGroup, npi, providerName, procedureCode]
  )

  const { classes } = useStyles()

  let specialtyGroupLabels: SpecialtyGroupSearchCategory[] = props.specialtyGroups?.map(
    specialtyGroup => {
      return {
        id: specialtyGroup.id,
        label: specialtyGroup.label,
        type: 'SpecialtyGroup',
        category: 'Specialty',
      }
    }
  )
  specialtyGroupLabels = specialtyGroupLabels || []

  const setSearchTerms = (event, newValue: SpecialtyGroupSearchCategory | null) => {
    let specialtyGroupFromValue: SpecialtyGroupSearchCategory | null = null
    if (newValue != null && newValue.type === 'SpecialtyGroup') {
      specialtyGroupFromValue = newValue
    }
    setSpecialtyGroup(specialtyGroupFromValue)
    props.onSelect(
      null,
      specialtyGroupFromValue,
      npi,
      providerName,
      specialtyGroupFromValue != null
        ? SPECIALTY_TO_GENDER[specialtyGroupFromValue.label]
        : props.selectedGender,
      null,
      procedureCode
    )
  }
  const setSearchTermsForProcedureSearch = (event, newValue: CPTCode | null) => {
    let codeFromValue: CPTCode | null = null
    if (newValue != null) {
      codeFromValue = newValue
    }
    setProcedureCode(codeFromValue)
    props.onSelect(
      null,
      specialtyGroup,
      npi,
      providerName,
      props.selectedGender,
      null,
      codeFromValue
    )
  }

  const setNPIParam = event => {
    const npi = event.target.value
    setNPI(npi)
    props.onSelect(
      null,
      specialtyGroup,
      npi,
      providerName,
      props.selectedGender,
      null,
      procedureCode
    )
  }

  const setProviderNameParam = event => {
    const providerNameFromEvent = event.target.value
    setProviderName(providerNameFromEvent)
    props.onSelect(
      null,
      specialtyGroup,
      npi,
      providerNameFromEvent,
      props.selectedGender,
      null,
      procedureCode
    )

    // set distance to 25miles if it's name search else 10miles for other searches
    providerNameFromEvent ? props?.setSearchRadius(25) : props?.setSearchRadius(10)
  }

  useEffect(() => {
    props.onSelect(
      null,
      specialtyGroup,
      npi,
      providerName,
      props.selectedGender,
      null,
      procedureCode
    )
  }, [])

  return (
    <Box style={{ gap: 20 }} display="flex" width="80vh" flexDirection="column">
      <Grid item xs={6}>
        <Autocomplete
          value={specialtyGroup}
          onChange={setSearchTerms}
          inputValue={searchString}
          onInputChange={(event, newSearchString) => {
            setSearchString(newSearchString)
          }}
          id="specialty-group-picker"
          options={specialtyGroupLabels}
          getOptionLabel={option => `${option.label}`}
          className={classes.selectAutoComplete}
          size="small"
          disabled={props.disabled}
          loading={props.loading}
          forcePopupIcon={false}
          renderOption={(props, option: SpecialtyGroupSearchCategory) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label="Specialty"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={<Checkbox value="F" checked={isFemale} onChange={handleCheckbox} />}
          label="Female Providers"
        />
        <FormControlLabel
          control={<Checkbox value="M" checked={isMale} onChange={handleCheckbox} />}
          label="Male Providers"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={procedureCode}
          onChange={setSearchTermsForProcedureSearch}
          inputValue={cptCodeSearchString}
          onInputChange={(event, newSearchString) => {
            setCPTCodeSearchString(newSearchString)
          }}
          id="cpt-code-picker"
          options={props.cptCodes || []}
          getOptionLabel={option => `${option.code} - ${option.description}`}
          className={classes.selectAutoComplete}
          size="small"
          disabled={props.disabled}
          loading={props.loading}
          forcePopupIcon={false}
          renderOption={(props, option: CPTCode) => (
            <li {...props} key={option.id}>
              {option.code} - {option.description}
            </li>
          )}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label="Procedure Code"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )
          }}
        />
      </Grid>
      <EditField
        InputLabelProps={{ shrink: true }}
        label="Provider Name"
        type="text"
        name="providerName"
        value={providerName}
        onChange={setProviderNameParam}
        disabled={props.disabled}
      />
      <EditField
        InputLabelProps={{ shrink: true }}
        label="NPI"
        type="number"
        name="npi"
        value={npi}
        onChange={setNPIParam}
        disabled={props.disabled}
      />
    </Box>
  )
}

export default ProviderSearchOptionsPicker
