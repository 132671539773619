import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PatientList from '~/screens/PatientList'
import { setPrefsPatientListOrder, setPrefsPatientListOrderBy } from '../actions/preferences'

const mapStateToProps = state => ({
  patients: state.patients.list,
  order: state.preferences.PatientList.order,
  orderBy: state.preferences.PatientList.orderBy,
})

const mapDispatchToProps = dispatch => ({
  setPrefsPatientListOrder: order => dispatch(setPrefsPatientListOrder(order)),
  setPrefsPatientListOrderBy: orderBy => dispatch(setPrefsPatientListOrderBy(orderBy)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientList))
