import { useState } from 'react'
import type { ReactNode } from 'react'
import { Box, Avatar, Typography, Button } from '@mui/material'
import { IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'

import { getUserInitials } from '~/utils'
import { formatDateWithoutDay, formatDateWithDay } from '~/utils/date'

import FFInput from '../Generic/FFInput'
import Loader from '../Loader'
import NoteService from '~/api/NoteService'

const useStyles = makeStyles()(theme => ({
  editNoteButton: {
    marginLeft: '60%',
  },
  editIcon: {
    color: theme.palette.secondary.light,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  save: {
    marginLeft: theme.spacing(),
  },
  saveDisabled: {
    color: 'transparent',
  },
  noteSubText: {
    marginLeft: theme.spacing(),
    color: theme.palette.grey[600],
    fontSize: '1.2rem',
  },
  noteContent: {
    marginTop: theme.spacing(),
    fontSize: '1.6rem',
    color: theme.palette.secondary.dark,
  },
  noteHeader: {
    fontSize: '1.2rem',
    color: theme.palette.secondary.dark,
  },
}))

const Note = props => {
  const [note, setNote] = useState(props.note)
  const { classes } = useStyles()
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleUpdate = async () => {
    setLoading(true)
    try {
      if (note !== null) {
        setNote(
          await NoteService.update(note.id, {
            content: note.content,
            patient: props.patientId,
          })
        )
        setEditing(false)
      } else {
        throw new Error('Cannot update null task')
      }
    } catch (e) {
      console.warn('Update task failed', e)
    }
    setLoading(false)
  }

  let editHistorySubtextHTML: ReactNode = ''
  if (note.updatedBy) {
    const updatedBy = note.updatedBy
    editHistorySubtextHTML = (
      <Typography className={classes.noteSubText}>
        (Edited by {updatedBy.firstName} {updatedBy.lastName} on
        {` ${formatDateWithoutDay(note.updatedAt)}`})
      </Typography>
    )
  }

  return editing ? (
    <div>
      <FFInput
        placeholder="Note Content"
        value={note.content}
        fullWidth
        onChange={e => {
          setNote({ ...note, content: e.target.value })
        }}
      />
      <div className={classes.actions}>
        <Button
          disabled={loading}
          onClick={() => {
            setEditing(false)
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          className={classes.save}
          variant="contained"
          color="primary"
          onClick={() => {
            handleUpdate()
          }}
        >
          {loading && <Loader inline />}
          <span className={loading ? classes.saveDisabled : ''}>UPDATE NOTE</span>
        </Button>
      </div>
    </div>
  ) : (
    <Box mb={3}>
      <Box display="flex" alignItems="center" flexDirection="row" mx={-1}>
        {note.createdBy && (
          <Box m={1} alignSelf="baseline">
            <Avatar src={note.createdBy.avatar} alt="createdBy avatar">
              {getUserInitials(note.createdBy)}
            </Avatar>
          </Box>
        )}
        <Box m={1}>
          {note.createdBy && (
            <Typography variant="body2" className={classes.noteHeader}>
              {note.createdBy?.firstName} {note.createdBy?.lastName}
            </Typography>
          )}
          <Box display="flex" flexDirection="row">
            <Typography variant="caption" className={classes.noteHeader}>
              {formatDateWithDay(note.createdAt)}
            </Typography>
            <Typography variant="caption" className={classes.noteHeader}>
              {editHistorySubtextHTML}
            </Typography>
          </Box>
          <Typography variant="body2" className={classes.noteContent}>
            {note.content}
          </Typography>
        </Box>
        {!(props.editable === false) && (
          <Box m={1} className={classes.editNoteButton}>
            <IconButton onClick={() => setEditing(true)} value={note.id}>
              <EditIcon className={classes.editIcon} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Note
