import { useEffect } from 'react'
import TaskEditorRow from './TaskEditorRow'
import { IconButton, Typography, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import AddIcon from '@mui/icons-material/Add'
import { anyIncompleteTask } from './utils'
import { ITask } from '~/models/Task'
import { mapPreviewTasksToTasks } from '~/utils/tasks'
import { format } from 'date-fns'

interface ITasksFormProps {
  patient: any
  me: any
  previewData: any
  setAllTemplateTasksComplete: (arg0: boolean) => void
  saving: boolean
  tasks: ITask[]
  setTasks: (arg0: ITask[]) => void
}

// Given a preview of the tasks that *would* be created from a template
// allow the user to view and edit those task data
const EditTemplateTasks = (props: ITasksFormProps) => {
  const { setAllTemplateTasksComplete, saving, tasks, setTasks } = props
  const { classes } = useStyles()
  const defaultAssigneeGroup = props.me.assigneeGroup?.id

  useEffect(() => {
    setAllTemplateTasksComplete(!anyIncompleteTask(tasks))
  }, [tasks])

  useEffect(() => {
    // Transform the preview data to editable tasks
    if (!(props.previewData && props.previewData.taskPreviews)) {
      return
    }
    setTasks(
      mapPreviewTasksToTasks(props.previewData.taskPreviews, {
        defaultAssigneeGroup: defaultAssigneeGroup,
      })
    )
  }, [props.previewData])

  const addTask = () => {
    const newTasks = [
      ...tasks,
      {
        title: '',
        dueDate: format(new Date(), 'YYYY-MM-DD'),
        ...{ ownerGroup: props.me.assigneeGroup.id },
        priority: 0,
        isComplete: false,
      },
    ]
    setTasks(newTasks)
  }

  /**
   * @param {number} i - index of the array of tasks
   */
  const updateTask = (i, update) => {
    const newTasks = [...tasks]
    newTasks[i] = { ...newTasks[i], ...update }
    setTasks(newTasks)
  }

  const removeTask = i => {
    const newTasks = [...tasks]
    newTasks.splice(i, 1)
    setTasks(newTasks)
  }

  const taskEditorRows = tasks.map((task, i) => {
    return (
      <Box mb={3} key={`task_${i}`}>
        <TaskEditorRow
          i={i}
          task={task}
          disabledEdit={false}
          disabledRemove={false}
          onChange={updateTask}
          onRemove={removeTask}
          patient={props.patient}
          classes={classes}
        />
      </Box>
    )
  })

  const editingLastTask =
    tasks.length && (!tasks[tasks.length - 1].title || !tasks[tasks.length - 1].dueDate)
  const disabledAdd = editingLastTask || saving

  return (
    <div className={classes.formGroup}>
      <Typography className={classes.formGroupTitle}>Tasks</Typography>
      {taskEditorRows}
      <IconButton disabled={disabledAdd} onClick={addTask} className={classes.formActionBottom}>
        <AddIcon />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles()(theme => ({
  formActionBottom: {
    padding: theme.spacing(0.5),
  },
  formGroup: {
    marginTop: theme.spacing(3),
  },
  formGroupTitle: {
    marginBottom: theme.spacing(),
  },
}))

export default EditTemplateTasks
