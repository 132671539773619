import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik'
import { ValueSetter } from '~/types'
import EditField from '../Generic/EditField'
import { ICase } from '~/models/Case'

type FormValues = Pick<ICase, 'externalDescription'>

interface CaseMemberFacingInfoProps {
  selectedCase: FormValues
  handleSubmit: (values: FormValues) => Promise<void>
  loading: boolean
}

const CaseMemberFacingInfo = ({
  selectedCase,
  handleSubmit,
  loading,
}: CaseMemberFacingInfoProps) => {
  const theme = useTheme()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedCase,
    onSubmit: handleSubmit,
  })

  const { externalDescription } = formik.values

  const setValues: ValueSetter = (name, value) => ({
    name,
    onChange: e => {
      e.persist()
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    },
    disabled: loading,
    value,
  })

  return (
    <Box bgcolor="background.external" px={2} py={1}>
      <Typography fontSize="12px" fontWeight={500} color="grey.500">
        Member-Facing Information
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid mt={1} container spacing={2}>
          <EditField
            {...setValues('externalDescription', externalDescription)}
            label="Member-Facing Title"
            // Assume that if external description is set, it is required on
            // this case. This may not be true of all member-facing case
            // categories in the future.
            required
            variant="outlined"
            size="small"
            InputProps={{
              style: { backgroundColor: `${theme.palette.background.default}` },
            }}
          />
        </Grid>
        <Box display="flex" justifyContent="flex-end" my={2}>
          {formik.dirty && (
            <Box mr={3}>
              <Button onClick={() => formik.resetForm()}>Cancel</Button>
            </Box>
          )}

          <Button
            disabled={!formik.dirty}
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              borderRadius: 24,
            }}
          >
            Save + Update App
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default CaseMemberFacingInfo
