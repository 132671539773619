import { usePromise as useCorePromise } from '~/legacy/core'

import { UseMutationConfig, useResolvedPromise } from './useResolvedPromise'

/**
 * Re-exports usePromise from ~/legacy/core with Lucian-specific
 * side effects via useResolvedPromise. This includes some code duplication
 * to get the types to match what's in the ~/legacy/core.
 *
 * TODO: Inline ~/legacy/core here.
 */
const DEFAULT_CONFIG = { initialLoading: true }
export function usePromise<P, E = any>(
  getPromise: () => Promise<P>,
  config: UseMutationConfig = DEFAULT_CONFIG
) {
  const promise = useCorePromise<P, E>(getPromise, config)
  useResolvedPromise(promise, { ...DEFAULT_CONFIG, ...config })
  return promise
}
