import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { groupBy } from 'lodash/fp'
import { WorklistWorkUnit } from '~/models/PatientTodoItem'

import * as thunks from './actions'

interface WorkUnitState {
  byId: { [key: number]: WorklistWorkUnit[] }
  count: number
}

const INITIAL_STATE: WorkUnitState = {
  byId: {},
  count: 0,
}

const workUnitsBaseSlice = createSlice({
  name: 'workUnits',
  initialState: INITIAL_STATE,
  reducers: {
    getWorkUnitsSuccess: (
      state,
      {
        payload: workUnits,
      }: PayloadAction<{
        worklistId: string
        items: WorklistWorkUnit[]
        count: number
      }>
    ) => {
      if (workUnits.items.length > 0) {
        state.byId = groupBy('worklist', workUnits.items)
      }
      state.count = workUnits.count
    },
    getWorkUnitSuccess: (
      state,
      { payload: workUnitPayload }: PayloadAction<{ workUnit: WorklistWorkUnit }>
    ) => {
      const itemIndex = state.byId[workUnitPayload.workUnit.worklist].findIndex(
        workUnit => workUnit.id === workUnitPayload.workUnit.id
      )
      state.byId[workUnitPayload.workUnit.worklist][itemIndex] = workUnitPayload.workUnit
    },
    patchWorkUnitSuccess: (
      state,
      { payload: workUnitPayload }: PayloadAction<{ workUnit: WorklistWorkUnit }>
    ) => {
      const itemIndex = state.byId[workUnitPayload.workUnit.worklist].findIndex(
        workUnit => workUnit.id === workUnitPayload.workUnit.id
      )
      state.byId[workUnitPayload.workUnit.worklist][itemIndex] = workUnitPayload.workUnit
    },
  },
})

export const workUnitsSlice = Object.assign(workUnitsBaseSlice, { thunks })
export default workUnitsSlice
