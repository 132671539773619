import { SingleMessageData, getFilteredMessageObjects, messageDataFromThread } from './utils'
import { reverse, sortBy, throttle } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import InterfaceContent from '~/components/Interface/InterfaceContent'
import LinearProgress from '@mui/material/LinearProgress'
import { FixedSizeList as List } from 'react-window'
import qs from 'query-string'
import MessagesSingle from '~/components/Messages/MessagesSingle'
import MoreMessagesLoader from './MoreMessagesLoader'
import { useFeatureFlags } from '~/utils/useFeatureFlag'
import { useLocalStorage } from '~/components/Providers/LocalStorageProvider'
import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '~/components/Providers/ApiProvider'
import MessagesSidebar from '~/components/Messages/MessagesSidebar'
import { FilterParams } from '~/redux/slices/chat/slice'
import chatSlice from '~/redux/slices/chat'
import { logger } from '~/utils/logger'

const FALLBACK_MESSAGE_HEIGHT = 73

const PAGE_SIZE = 100

// Patient data might actually include additional info (patientFields)
// but not all items are guaranteed to be hydrated with that data
export interface PatientMetaData {
  firstName: string
  lastName: string
  id: number
  isPatient: boolean
  person?: object
}

export const findSelectedPodIdsFromMap = (selectedPodIdsMap: { [id: number]: boolean }) => {
  return Object.entries(selectedPodIdsMap).reduce((accumulator, keyValue) => {
    const [key, value] = keyValue
    if (value) accumulator.push(key)
    return accumulator
  }, [] as string[])
}

const Messages = () => {
  const history = useHistory()
  const [filterParams, setFilterParams] = useState<FilterParams>({
    activeFilter: null,
    selectedPodIdsMap: {},
  })
  const [loadThreadParams, setLoadThreadParams] = useState<
    Parameters<typeof chatSlice.thunks.loadBatchHistory>[0]
  >({ offset: 0, limit: PAGE_SIZE })
  const location = useLocation()
  const [_, setMessageInboxParams] = useLocalStorage('messageInboxParams')
  const { isLoading } = useFeatureFlags()
  const [paramsLoaded, setParamsLoaded] = useState(false)
  const dispatch = useDispatch()
  const patientsById = useSelector(state => state.patients.byId)
  const patientThreads = useSelector(state => state.chat.patientThreads)
  const patientIdsByMessageFilter = useSelector(state => state.chat.patientIdsByMessageFilter)
  const threadCount = useSelector(state => state.chat.threadCount)

  const refilterForMessageUuid = useSelector(state => state.chat.refilterForMessageUuid)
  const selectedPodIds = () => findSelectedPodIdsFromMap(filterParams.selectedPodIdsMap)

  const useLoadThreads = () => {
    const { activeFilter, selectedPodIdsMap } = filterParams
    const batchParams: any = {
      offset: 0,
      limit: PAGE_SIZE,
    }
    if (activeFilter) {
      batchParams.filter = activeFilter
    }
    const podIds = selectedPodIds()
    if (podIds.length > 0) {
      batchParams.podIds = podIds.join(',')
    }
    // Selecting a Care Pod of ID 0 is used as a code for "show me patients without pods"
    if (selectedPodIdsMap[0]) {
      batchParams.includeNoPod = true
    }

    return useMutation(
      (extraParams?: any) => {
        const params = { ...batchParams, ...(extraParams || {}) }
        setLoadThreadParams(params)
        return dispatch(chatSlice.thunks.loadBatchHistory(params))
      },
      {
        mutationKey: ['useLoadThreads', JSON.stringify(filterParams)],
      }
    )
  }

  const loadThreadsMutation = useLoadThreads()

  const handleRefilterSignal = useCallback(
    throttle(
      () => {
        // When there is a new message for which we need to refilter
        // e.g. if we learn that a message's urgency tag has changed
        // refetch threads from the backend
        logger.info('[Messages] handleRefilterSignal')
        loadThreadsMutation.mutateAsync(loadThreadParams)
      },
      5000,
      { leading: false, trailing: true }
    ),
    []
  )

  useEffect(() => {
    if (refilterForMessageUuid) handleRefilterSignal()
  }, [refilterForMessageUuid])

  useEffect(() => {
    // On load, update batch params from URL
    // Block other message loading until this is done to avoid unnecessary requests
    setParamsLoaded(false)
    ;(async () => {
      const newParams: FilterParams = {
        activeFilter: null,
        selectedPodIdsMap: {},
      }
      const filter = qs.parse(location.search).f
      if (typeof filter == 'string') {
        newParams.activeFilter = filter
      }
      const podIds = qs.parse(location.search).pod_ids
      if (typeof podIds == 'string') {
        const newMap = podIds.split(',').reduce((map, id) => {
          map[id] = true
          return map
        }, {})
        newParams.selectedPodIdsMap = newMap
      }
      await setFilterParams(newParams)
    })()
    setParamsLoaded(true)
  }, [])

  const encodeParamsToString = () => {
    const params = new URLSearchParams()
    const { activeFilter } = filterParams
    if (activeFilter) {
      params.set('f', activeFilter)
    }
    const podIds = selectedPodIds()
    if (podIds.length > 0) {
      params.set('pod_ids', podIds.join(','))
    }
    return params.toString()
  }

  useEffect(() => {
    if (!paramsLoaded) return
    // When params change, encode the params in the URL
    // and fetch new results
    const newUrl = '/messages'
    history.push(`${newUrl}?${encodeParamsToString()}`)
    // Update the params in local storage so that they remain "sticky".
    setMessageInboxParams(encodeParamsToString())
    loadThreadsMutation.mutate({})
    dispatch(chatSlice.actions.setCurrentFilterParams(filterParams))
  }, [filterParams, paramsLoaded])

  const setFilterAndRefresh = async (filter: any = null) => {
    await setFilterParams(current => ({ ...current, activeFilter: filter }))
  }

  const getMessageObjects = () => {
    const threadIds = patientIdsByMessageFilter[filterParams.activeFilter || 'all'] || []
    const threads = Array.from(threadIds).map((id: any) => patientThreads?.[id])

    const mappedThreads = threads.map(thread => {
      return messageDataFromThread(thread, patientsById)
    })

    // Return list of patients and their last message, with most recent first.
    return reverse(sortBy(mappedThreads, 'sentAt'))
  }

  /**
   * Choose render method based on ENV
   * Virtualized list fails to render rows in test environment,
   * so workaround is to do a full render
   */
  const renderMessages = messages => {
    if (process.env.NODE_ENV === 'test') {
      return renderTestMessages(messages)
    } else {
      return renderBrowserMessages(messages)
    }
  }

  const loadNextPage = async startIndex => {
    if (!paramsLoaded || loadThreadsMutation.isLoading) return
    const offset = Math.max(0, startIndex - 10)
    if (offset == 0) return
    loadThreadsMutation.mutate({ offset, limit: PAGE_SIZE })
  }

  const renderBrowserMessages = (messages: SingleMessageData[]) => {
    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = index => index < messages.length

    const Item = ({ index, style }) => {
      if (index === messages.length) {
        return <div></div>
      } else {
        const message = messages[index]
        return isItemLoaded(index) ? (
          <MessagesSingle message={message} style={style} key={index} />
        ) : (
          <MoreMessagesLoader style={style} />
        )
      }
    }

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = threadCount > messages.length ? messages.length + 1 : messages.length

    return (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadNextPage}
          >
            {({ onItemsRendered, ref }) => (
              <List
                ref={ref}
                onItemsRendered={onItemsRendered}
                height={height}
                width={width}
                itemCount={itemCount}
                itemSize={FALLBACK_MESSAGE_HEIGHT}
              >
                {Item}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    )
  }

  const renderTestMessages = (messages: SingleMessageData[]) =>
    messages.map((message, i) => <MessagesSingle message={message} key={i} style={{}} />)

  const threads = !patientThreads
    ? []
    : getFilteredMessageObjects(getMessageObjects(), filterParams.activeFilter)

  const setSelectedPodIdsMap = newMap => {
    setFilterParams(current => ({ ...current, selectedPodIdsMap: newMap }))
  }

  const sidebarComponent = isLoading ? null : (
    <MessagesSidebar
      activeFilter={filterParams.activeFilter}
      setActiveFilter={setFilterAndRefresh}
      selectedPodIdsMap={filterParams.selectedPodIdsMap}
      setSelectedPodIdsMap={setSelectedPodIdsMap}
    />
  )

  return (
    <InterfaceContent sidebar={sidebarComponent} collapseDisabled={true}>
      {loadThreadsMutation.isLoading && <LinearProgress />}
      {patientThreads && renderMessages(threads)}
    </InterfaceContent>
  )
}

export default Messages
