import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { MoreVert, Launch, HelpOutline } from '@mui/icons-material'
import { subMinutes, addMinutes } from 'date-fns'
import { SessionHelpButton } from '~/components/Appointment/Zoom/SessionHelpButton'

interface Appointment {
  start: string
  duration: string
  status: string
  patient: number
  id: number
  physician: {
    provider: {
      pk: number
    }
  }
  cancelableStatus: {
    canCancel: boolean
    reason: string | null
  }
}

export const AppointmentCardMenu: React.FC<{
  appointment: Appointment
  selectAppointmentForCancel: (appointment: Appointment) => void
}> = ({ appointment, selectAppointmentForCancel }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  // Links to DataDog for member and clinician surrounding this visit, padded by 10 minutes
  const datadogLinks = useMemo(() => {
    try {
      const startTime = subMinutes(new Date(appointment.start), 10).getTime().toString()
      const [hours, minutes] = appointment.duration.split(':')
      const endTime = addMinutes(
        new Date(appointment.start),
        Number(hours) * 60 + Number(minutes) + 10
      )
        .getTime()
        .toString()
      const datadogParams = new URLSearchParams()
      datadogParams.set(
        'query',
        `service:(lucian-crm OR Firefly-App OR members-portal) env:(prod OR production) @usr.id:(${appointment.patient} OR ${appointment.physician.provider.pk})`
      )
      datadogParams.set('from_ts', startTime)
      datadogParams.set('to_ts', endTime)
      datadogParams.set('live', Boolean(false).toString())
      const params = datadogParams.toString()
      return {
        ['Datadog Logs']: `https://app.datadoghq.com/logs?${params}`,
        ['Datadog RUM Sessions']: `https://app.datadoghq.com/rum/explorer?${params}`,
        ['Datadog Errors']: `https://app.datadoghq.com/rum/error-tracking?${params}`,
      }
    } catch (e) {
      console.error('Error generating DataDog links', e)
      return null
    }
  }, [appointment])

  return (
    <>
      <SessionHelpButton />
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ListItem>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Chip label={appointment.id} size="small" color="secondary" sx={{ mr: 5 }} />
              <Tooltip title="Log appointment to console" placement="top">
                <Button size="small" onClick={() => console.log('Appointment', appointment)}>
                  Log Appointment
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </ListItem>
        <MenuItem>
          {appointment.cancelableStatus?.canCancel ? (
            <Button
              size="small"
              color="inherit"
              onClick={() => {
                selectAppointmentForCancel(appointment)
                handleClose()
              }}
            >
              <Typography sx={{ fontSize: '1.6rem' }}>Cancel appointment</Typography>
            </Button>
          ) : (
            <Tooltip title={appointment.cancelableStatus?.reason || JSON.stringify(appointment)}>
              <span>
                <Button disabled size="small" color="inherit" endIcon={<HelpOutline />}>
                  Not cancelable
                </Button>
              </span>
            </Tooltip>
          )}
        </MenuItem>
        <Divider />
        <MenuItem>
          <Typography variant="overline">Developer Tools</Typography>
        </MenuItem>
        <MenuItem>
          <Button
            href="https://zoom.us/account/metrics/pastmeetings"
            target="_blank"
            rel="noreferrer"
            endIcon={<Launch />}
            size="small"
          >
            Zoom Dashboard
          </Button>
        </MenuItem>

        {datadogLinks
          ? Object.keys(datadogLinks).map(key => (
              <MenuItem key={key}>
                <Button
                  href={datadogLinks[key]}
                  target="_blank"
                  rel="noreferrer"
                  endIcon={<Launch />}
                  size="small"
                >
                  {key}
                </Button>
              </MenuItem>
            ))
          : null}
      </Menu>
    </>
  )
}
