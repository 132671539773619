import { useEffect, useState } from 'react'

import { usePrograms } from '~/api/ProgramService'
import { Box } from '@mui/material'
import BaselineProgram from './BaselineProgram'
import ClinicalProgram from './ClinicalProgram'
import { IProgram } from '~/models/ProgramEnrollment'
import { READABLE_PROGRAM_NAMES } from '~/constants/programEnrollment'
import PatientRiskScore from './PatientRiskScore'

export const readableProgramName = programUid => READABLE_PROGRAM_NAMES[programUid] || programUid

const ProgramTab = ({ patient }) => {
  const [clinicalPrograms, setClinicalPrograms] = useState<IProgram[]>([])
  const [baselinePrograms, setBaselinePrograms] = useState<IProgram[]>([])

  const { result: programs, isLoading } = usePrograms()
  useEffect(() => {
    const mappedPrograms = programs
      .map(program => {
        program.displayName = readableProgramName(program.uid)
        return program
      })
      .sort((p1, p2) =>
        p1.displayName && p2.displayName && p1.displayName > p2.displayName ? 1 : -1
      )
    const clinicalPrograms = mappedPrograms.filter(p => p.isClinical === true)
    const baselinePrograms = mappedPrograms.filter(p => p.isClinical === false)
    setClinicalPrograms(clinicalPrograms)
    setBaselinePrograms(baselinePrograms)
  }, [isLoading])

  return (
    <Box>
      {!isLoading && (
        <Box ml={3} mt={2}>
          <BaselineProgram programs={baselinePrograms} patient={patient} />
          <Box mt={4}>
            <ClinicalProgram programs={clinicalPrograms} patient={patient} />
          </Box>
          <Box mt={4}>
            <PatientRiskScore patient={patient} />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ProgramTab
