import { Box, Typography } from '@material-ui/core'
import { useBenefitProgram } from '~/api/ProgramService'

const DisplayBenefitProgramCard = ({ patient }) => {
  const { data: benefitProgram, isLoading } = useBenefitProgram({
    patientId: patient.id,
  })

  let latestCardPrintDate: string | undefined

  if (benefitProgram && benefitProgram.cardPrintDates) {
    latestCardPrintDate = new Date(
      Math.max.apply(
        null,
        benefitProgram.cardPrintDates.map(function (cd) {
          return new Date(cd).getTime()
        })
      )
    ).toDateString()
  }

  return (
    <>
      {!isLoading && latestCardPrintDate && (
        <Box display="flex" marginTop="1rem" justifyContent="space-between" padding="1rem">
          <Box display="block" m={1}>
            <Typography variant="subtitle2">Last Card Print Date</Typography>
            <Typography variant="caption">{latestCardPrintDate}</Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default DisplayBenefitProgramCard
