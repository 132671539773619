import { IPubNubPatientMetadata } from '~/utils/pubnub'
import { PayloadAction, createSlice, createAction } from '@reduxjs/toolkit'
import PubNub, { MessageEvent } from 'pubnub'
import { getPatientIdFromThread, processMessages } from '~/utils/chat'
import { keys, setWith } from 'lodash/fp'
import { logger } from '~/utils/logger'

import { UpdateChatReadParams } from './thunks'
import { camelizeKeys } from 'humps'
import { formatRFC3339DateTime } from '~/utils/date'
import { patientIdFromPubNubMessage } from '~/screens/Messages/utils'
import { ChatSearchResult } from '~/components/Chat/ChatSearchResultSingle'
import { IChatMessage as ChatMessage } from '~/components/Chat/Chat'

export interface FilterParams {
  activeFilter: string | null
  selectedPodIdsMap: { [id: number]: boolean }
}

// TODO: redux typing complains if we try to import this
// Need to createAction here separately
const auth0Deauthenticate = createAction('auth0/deauthenticate')

// ClientChatMessage represents a message fetched from PubNub and displayed on the client before it
// has necessarily been persisted on the backend.
type ClientChatMessage = Omit<ChatMessage, 'timetoken'> & { timetoken?: any }

export interface ChatThread {
  uid: string
  messages: ChatMessage[]
  patient: number
  lastReadAt?: null | string
  lastReadBy?: null | number
  isUnread?: boolean // comes from back-end
  pubnubMessageUnread?: boolean // this flag set on the fly, when we get message from PubNub based on message sender
  // patientMetadata is present when a new message comes from PubNub. PubNub fetches patient metadata from the back-end
  // and then we store that metadata on the front-end thread object
  // It will *not* be present when a thread is loaded directly from the back-end
  // and in that case, patient data can be found on corresponding stored patient object
  patientMetadata?: IPubNubPatientMetadata
  updatedBy?: any
  messageCursor?: string | null
  clinicianHasChattedWithPatient?: boolean
}

interface SearchResultsData {
  [key: string]: { total: number; hits: { id: string; source: ChatSearchResult }[] }
}

export interface ChatState {
  patientThreads: {
    [x: number]: ChatThread
  }
  // The total count of messages, according to the last backend fetch and accounting for the selected filters
  threadCount: number
  unreadThreadCount: number
  threadsRequestTimestamp: null | number // Used to avoid race conditions between different thread filter requests
  patientIdsByMessageFilter: Record<string, Set<string>> // This contains message bucket filters map and corresponding thread patientids set
  pubnub: PubNub | null
  // Maps provider IDs to the ID of the patient they are typing to or viewing
  // The source of these data is PubNub events
  typing: { [providerUserId: string]: number | null }
  viewing: { [providerUserId: string]: number | null }
  // Search results
  isSearching: boolean
  searchResults: SearchResultsData
  searchResultIndex: number | null
  historicalMessagesThreadUid?: string | null
  historicalMessages?: ChatMessage[] | null
  noMorePreviousMessages: boolean
  noMoreNextMessages: boolean
  // The filters currently being applied to incoming messages
  // This is stored in redux because it is used outside the context of the Messages screen
  // to filter incoming message events coming from PubNub
  currentFilterParams: FilterParams | null
  // Keep track of the last message for which we needed to refilter messages
  // This can be as an effect dependency to refetch messages
  refilterForMessageUuid: null | string
}

const INITIAL_STATE: ChatState = {
  pubnub: null,
  patientThreads: {},
  threadsRequestTimestamp: null,
  patientIdsByMessageFilter: {
    all: new Set(),
    unread: new Set(),
    clinical: new Set(),
    operational: new Set(),
  },
  typing: {},
  viewing: {},
  isSearching: false,
  noMorePreviousMessages: false,
  noMoreNextMessages: false,
  currentFilterParams: null,
  searchResultIndex: null,
  searchResults: {},
  refilterForMessageUuid: null,
  threadCount: 0,
  unreadThreadCount: 0,
}

const MESSAGE_BUCKET_FILTERS = ['unread', 'all', 'clinical', 'operational']

// This ensures that batch history requests completing in the background don't replace
// shared state with the patient detail view. A user might have loaded historical history
// etc. and we want to preserve that.
const mergePatientThreads = (
  oldThreads: ChatState['patientThreads'],
  newThreads: ChatState['patientThreads']
): ChatState['patientThreads'] => {
  if (!oldThreads) return newThreads
  if (!newThreads) return {}

  Object.keys(newThreads!).forEach(id => {
    if (oldThreads[id]?.messages) {
      // Maintain any downstream mutations but update messages key
      newThreads[id] = Object.assign({}, oldThreads[id], newThreads[id], {
        messages: processMessages(oldThreads[id].messages.concat(newThreads[id].messages)),
      })
    }
  })
  return { ...oldThreads, ...newThreads }
}

const chatSlice = createSlice({
  name: 'chat',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentFilterParams: (
      state,
      { payload: currentFilterParams }: PayloadAction<FilterParams | null>
    ) => {
      state.currentFilterParams = currentFilterParams
    },
    setPubnubClient: (state, { payload: pubnub }: PayloadAction<PubNub>) => {
      state.pubnub = pubnub
    },
    initBatchHistoryRequest: (state, { payload: { now } }) => {
      state.threadsRequestTimestamp = now
    },
    loadBatchHistorySuccess: (state, { payload: { patientThreads, params, timestamp, count } }) => {
      // Avoid race conditions between different fetches of history
      // E.g. request filter 1, then request filter 2...response for filter 2 comes in before response for filter 1
      if (state.threadsRequestTimestamp && timestamp < state.threadsRequestTimestamp) return
      const mergedThreads = mergePatientThreads(state.patientThreads, patientThreads)
      state.patientThreads = mergedThreads

      state.threadCount = count
      const filter = params.filter || 'all'

      // If refetching from top of list, reset.
      if (params.offset === 0) {
        state.patientIdsByMessageFilter[filter] = new Set()
      }

      // iterate for all message bucket filter to intialize patient threads
      for (const messageFilter of MESSAGE_BUCKET_FILTERS) {
        const set = new Set([
          ...Array.from(state.patientIdsByMessageFilter[messageFilter]),
          ...(keys(patientThreads) as any),
        ])
        state.patientIdsByMessageFilter[messageFilter] = set
        if (messageFilter == filter) {
          // Find the # of unread messages
          // A white lie because there might be more unloaded messages which are unread
          // This is really just the count of visible unread messages
          // It will also only be updated when we refetch threads
          // e.g. not by separate user actions to mark a thread as read which do not also refetch threads
          // We think this is a good tradeoff vs. having to reconcile backend and frontend state
          // or refetching all threads more often
          let unreadCount = 0
          set.forEach(value => {
            if (state.patientThreads[value].isUnread) unreadCount += 1
          })
          state.unreadThreadCount = unreadCount
        }
      }
    },
    loadThreadHistorySuccess: (state, { payload }) => {
      let recentMsgList: any[] = []
      let messageList = payload.recentMessages.results
      for (const msg of messageList) {
        if (msg.contentType === 'phonecall') {
          msg.contentObject.sentAt = msg.contentObject.calledAt
          msg.contentObject.uid = msg.contentObject.id + ''
        }
        msg.contentObject.kind = msg.contentType
        recentMsgList.push(msg.contentObject)
      }
      const thread = {
        ...payload.thread,
        messages: processMessages(recentMsgList),
        messageCursor: payload.recentMessages.next,
      }

      return setWith(Object, ['patientThreads', thread.patient as number], thread, state)
    },
    loadThreadMoreHistorySuccess: (state, { payload }) => {
      if (!state.patientThreads) return

      let recentMsgList: any[] = []
      let messageList = payload.results

      for (const msg of messageList) {
        if (msg.contentType === 'phonecall') {
          msg.contentObject.sentAt = msg.contentObject.calledAt
          msg.contentObject.uid = msg.contentObject.id + ''
        }
        msg.contentObject.kind = msg.contentType
        recentMsgList.push(msg.contentObject)
      }

      const threadUpdates = {
        messages: processMessages(
          recentMsgList.concat(state.patientThreads[payload.patient].messages)
        ),
        messageCursor: payload.next,
      }

      return setWith(
        Object,
        ['patientThreads', payload.patient],
        {
          ...state.patientThreads[payload.patient],
          ...threadUpdates,
        },
        state
      )
    },

    deletePhoneCallFromChatThread: (state, { payload }) => {
      if (!state.patientThreads) return state
      let patientId = payload.user
      let messages = state.patientThreads[patientId].messages
      if (messages) {
        for (var i = messages.length - 1; i >= 0; --i) {
          if (messages[i].id == payload.id) {
            messages.splice(i, 1)
          }
        }
      }
    },

    appendPhoneCallToChatThread: (state, { payload }) => {
      if (!state.patientThreads) return state
      let newThreadHistory: ChatMessage[]
      let patientId = payload.user
      let phoneCall = payload
      payload.sentAt = payload.calledAt
      payload.uid = payload.id + ''
      payload.kind = 'phonecall'
      if (!state.patientThreads[patientId]) {
        state.patientThreads[patientId] = {
          messages: [],
          patient: patientId,
          lastReadAt: null,
          uid: '',
        }
        newThreadHistory = [phoneCall]
      } else {
        // We do have a message history for this patient
        // Append this new message to the existing history
        newThreadHistory = processMessages(
          state.patientThreads[patientId].messages.concat(phoneCall)
        )
      }

      state.patientThreads[patientId].messages = newThreadHistory
    },
    refilterThread: (state, { payload }) => {
      logger.info('[MessageRefilter] slice refilterThread received', payload)
      state.refilterForMessageUuid = payload.uuid
    },
    loadNewPubNubMessage: (state, { payload }) => {
      const messageEvent: MessageEvent = payload.messageEvent
      const patientMetadata: IPubNubPatientMetadata | null = payload.patientMetadata
      const pubNubMessage: MessageEvent['message'] = camelizeKeys(messageEvent.message)
      const channelParts: string[] = pubNubMessage.channel.split('.')
      const patientId = channelParts[1]
      const threadUid = channelParts.slice(1).join('.')

      if (!state.patientThreads) return state

      let newThreadHistory: ChatMessage[]

      const newMessage: ChatMessage = {
        ...(pubNubMessage as ClientChatMessage),
        timetoken: parseInt(messageEvent.timetoken),
        sentAt: formatRFC3339DateTime(new Date(parseInt(messageEvent.timetoken, 10) / 10e3)),
      }

      // SUP-596
      logger.info('loadNewPubNubMessage for patientId: ', patientId, ', message: ', newMessage.uid)

      if (!state.patientThreads[patientId]) {
        // We haven't loaded the patient's thread history yet
        // e.g. a new message might have just been received,
        // but we haven't clicked in to load historical messages
        // First build up a default thread state
        const patientId = patientIdFromPubNubMessage(pubNubMessage)
        state.patientThreads[patientId] = {
          messages: [],
          patient: patientId,
          lastReadAt: null,
          pubnubMessageUnread: patientId === messageEvent.message.sender,
          uid: '',
        }
        newThreadHistory = [newMessage]
      } else {
        // We do have a message history for this patient
        // Append this new message to the existing history
        newThreadHistory = processMessages(
          state.patientThreads[patientId].messages.concat(newMessage)
        )
      }

      state.patientThreads[patientId].messages = newThreadHistory

      // If we're looking at the last page of historical messages, append new messages to it.
      if (
        state.historicalMessagesThreadUid === threadUid &&
        state.historicalMessages &&
        state.noMoreNextMessages
      ) {
        state.historicalMessages = processMessages(state.historicalMessages.concat(newMessage))
      }

      if (patientMetadata) {
        state.patientThreads[patientId].patientMetadata = patientMetadata
      }
    },

    setIsSearching: (state, { payload }) => {
      state.isSearching = payload
    },

    setSearchResultIndex: (state, { payload }) => {
      state.searchResultIndex = payload
    },

    setPresenceState: (state, { payload: { providerUserId, presenceState } }) => {
      const { patientId, typing, viewing } = presenceState
      if (typing == true) {
        state.typing[providerUserId] = patientId
      } else if (typing == false) {
        state.typing[providerUserId] = null
      }
      if (viewing == true) {
        state.viewing[providerUserId] = patientId
      } else if (viewing == false) {
        state.viewing[providerUserId] = null
      }
    },

    loadMessageSearchResultsSuccess: (state, { payload }) => {
      const { data, threadUid, searchQuery } = payload
      const cacheKey = `${threadUid},${searchQuery}`
      state.searchResults[cacheKey] = data
    },

    loadThreadHistoryAtSuccess: (state, { payload }) => {
      state.noMorePreviousMessages = false
      state.noMoreNextMessages = false
      state.historicalMessagesThreadUid = payload.threadUid
      state.historicalMessages = payload.data
    },

    loadThreadBeforeHistoryAtSuccess: (state, { payload }) => {
      if (payload.data.length > 0) {
        state.historicalMessages = payload.data.concat(state.historicalMessages)
      } else {
        state.noMorePreviousMessages = true
      }
    },

    loadThreadAfterHistoryAtSuccess: (state, { payload }) => {
      const historicalMessages = state.historicalMessages || []
      // TODO: The API includes the current message in its response.
      const firstMessageRemoved = payload.data.slice(1, payload.data.length)

      if (firstMessageRemoved.length > 0) {
        state.historicalMessages = historicalMessages.concat(firstMessageRemoved)
      } else {
        state.noMoreNextMessages = true
      }
    },

    clearHistoricalMessages: state => {
      state.noMorePreviousMessages = false
      state.noMoreNextMessages = false
      state.historicalMessagesThreadUid = null
      state.historicalMessages = null
    },

    clearSearchResults: state => {},

    setMessageTags: (state, { payload }) => {
      const { thread, uid: messageUid, messageTags } = payload
      const patientId = getPatientIdFromThread(thread)

      if (!state.patientThreads || !state.patientThreads[patientId]) {
        return state
      }

      const patientMessages = state.patientThreads[patientId].messages
      const newMessages = patientMessages.map(message =>
        message.uid === messageUid
          ? {
              ...message,
              messageTags: messageTags,
            }
          : message
      )

      return setWith(Object, ['patientThreads', patientId, 'messages'], newMessages, state)
    },

    setMessageUrgency: (state, { payload }) => {
      const { thread, uid: messageUid, isUrgentMessage } = payload
      const patientId = getPatientIdFromThread(thread)

      if (!state.patientThreads || !state.patientThreads[patientId]) {
        return state
      }

      const patientMessages = state.patientThreads[patientId].messages
      const newMessages = patientMessages.map(message =>
        message.uid === messageUid
          ? {
              ...message,
              isUrgentMessage: isUrgentMessage,
            }
          : message
      )

      return setWith(Object, ['patientThreads', patientId, 'messages'], newMessages, state)
    },
    setMessageId: (state, { payload }) => {
      // Given a patient and message UID, update message in Redux cache with provided id.
      const { patientId, uid: messageUid, id: messageId } = payload
      if (!state.patientThreads || !state.patientThreads[patientId]) {
        return state
      }

      const patientMessages = state.patientThreads[patientId].messages
      const newMessages = patientMessages.map(message =>
        message.uid === messageUid
          ? {
              ...message,
              id: messageId,
            }
          : message
      )

      return setWith(Object, ['patientThreads', patientId, 'messages'], newMessages, state)
    },
    setThreadRead: (state, { payload }: PayloadAction<UpdateChatReadParams>) => {
      if (state.patientThreads && state.patientThreads[payload.patient]) {
        state.patientThreads[payload.patient].lastReadAt = new Date().toISOString()
        state.patientThreads[payload.patient].lastReadBy = payload.clinician
        state.patientThreads[payload.patient].pubnubMessageUnread = false
      }
    },
    setThreadUnread: (state, { payload }: PayloadAction<number>) => {
      if (state.patientThreads && state.patientThreads[payload]) {
        state.patientThreads[payload].lastReadAt = null
        state.patientThreads[payload].lastReadBy = null
        state.patientThreads[payload].pubnubMessageUnread = true
      }
    },
    updateThread: (state, { payload }) => {
      if (state.patientThreads !== null) {
        const thread = state.patientThreads[payload.patient]
        // Payloads need to be merged here because they are mutated
        // downstream from here to add the `messages` key
        state.patientThreads[payload.patient] = Object.assign({}, thread, payload)
      }
    },
  },
  extraReducers: {
    // Unsubscribe from all pubnub events and kill the client
    [auth0Deauthenticate.type]: state => {
      state.pubnub?.unsubscribeAll() // eslint-disable-line
      state.pubnub?.stop() // eslint-disable-line
      return {
        ...state,
        pubnub: null,
      }
    },
    '@@router/LOCATION_CHANGE': state => {
      return {
        ...state,
        isSearching: false,
        searchResults: {},
        searchResultIndex: null,
        noMorePreviousMessages: false,
        noMoreNextMessages: false,
        historicalMessagesThreadUid: null,
        historicalMessages: null,
      }
    },
  },
})

export default chatSlice
