import type { FC } from 'react'
import Modal, { ModalProps } from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import Draggable from 'react-draggable'

interface FFModalProps extends ModalProps {
  header?: React.ReactChild
  footer?: React.ReactChild
  fixedHeight?: boolean | undefined
}

/**
 * This modal component provides an abstraction on top of Material UI's
 * Modal component with some default styles
 * Fixed to center of the page
 * Uses <Paper /> component for container
 * Responsive
 * Handles child content overflow
 * Allows for fixed header and footer content
 * Draggable around the screen by the header (if a header is provided; making an element draggable prevents copying
 *   text; we assume this is okay for header content)
 */
export const FFModal: FC<FFModalProps> = props => {
  const classes = useStyles(props)
  const { fixedHeight, header, footer, ...rest } = props
  return (
    <Modal {...rest}>
      <Draggable handle={`.${classes.dragHandle}`}>
        <div className={classes.container}>
          <Paper
            className={cx(classes.paper, {
              [classes.fixedHeightPaper]: fixedHeight,
            })}
          >
            {header && <div className={cx(classes.dragHandle, classes.header)}>{header}</div>}
            <div className={classes.children}>{props.children}</div>
            {footer && <div className={classes.footer}>{footer}</div>}
          </Paper>
        </div>
      </Draggable>
    </Modal>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '60%',
      maxHeight: '90%',
      overflow: 'auto',
      boxShadow: `0 1rem 3rem 0 ${theme.palette.grey[900]}`,
    },
    fixedHeightPaper: {
      height: '90%',
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      paper: {
        width: '50%',
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
      paper: {
        width: '33%',
      },
    },
    header: {
      borderBottom: `0.1rem solid ${theme.palette.grey[200]}`,
    },
    footer: {
      borderTop: `0.1rem solid ${theme.palette.grey[200]}`,
    },
    children: {
      flex: 1,
      overflow: 'auto',
    },
    dragHandle: {
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing',
      },
    },
  })
)

export default FFModal
