import { FolderOpen as FolderIcon } from '@mui/icons-material'
import qs from 'query-string'
import { Box, Button, Chip } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { BLUE } from '~/utils/theme'
import { useContext, type FC } from 'react'

import { ICase } from '~/models/Case'
import { PatientUser } from '~/types'
import { IChatMessage } from './Chat'
import { IPhoneCall } from '~/models/PhoneCall'

import { useCaseCategoriesById, useUpdateCase } from '~/api/CaseService'
import { useHistory } from 'react-router-dom'
import { MessageIdToCaseInfoDict, findMessageIdentifier, useMessageCaseInfo } from './utils'
import { pick } from 'lodash'
import { ITask, VIEWABLE_TASK_FIELDS } from '~/models/Task'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'

type Message = IChatMessage | IPhoneCall

interface Props {
  patient: PatientUser
  message: Message
}

export const CaseInfoChips: FC<{
  messageIdToCaseInfo: MessageIdToCaseInfoDict
  message: Message
}> = props => {
  const { setSelectedCaseId } = useContext(SelectedTodoContext)
  const { result: caseCategoriesById } = useCaseCategoriesById()

  const navigateToCase = (id: ICase['id']) => {
    setSelectedCaseId(id)
  }

  const caseInfo = (props.messageIdToCaseInfo || {})[props.message.id]
  if (!caseInfo?.length) return null

  return (
    <Box flexDirection="column" display="flex" alignItems="flex-start" position="relative">
      {caseInfo.map((info, i) => {
        const category = caseCategoriesById?.[info.categoryId]
        return (
          <Chip
            key={i}
            icon={<FolderIcon style={{ color: BLUE[600] }} />}
            label={category?.title}
            variant="outlined"
            size="small"
            onClick={() => navigateToCase(info.caseId)}
            sx={{
              color: 'primary.600',
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              display: 'flex',
              padding: 0.5,
            }}
          />
        )
      })}
    </Box>
  )
}

const MessageInfo: FC<Props> = props => {
  // Message information and controls for the actions a user can take on a message
  // e.g. triaging to Clinical vs. Operational, adding to a Case
  const { patient } = props
  const { selectedCaseId } = useContext(SelectedTodoContext)

  // To display Case information, we need:
  // 1) Case Category data
  // 2) A mapping of Case IDs mapped to their relations
  // 3) This message's id/type, to check if it is included in any Cases' relations
  const { messageIdToCaseInfo, patientCases } = useMessageCaseInfo(patient.id, patient.person.id)
  const { mutateAsync: handleUpdatePatientCase } = useUpdateCase()
  const { currentCaseMatch, messageIdentifier } = (() => {
    let currentCaseMatch: ICase | null = null
    const messageIdentifier = findMessageIdentifier(props.message)
    if (selectedCaseId) {
      if (messageIdToCaseInfo[props.message.id]?.find(info => info.caseId == selectedCaseId)) {
        currentCaseMatch =
          patientCases?.find(patientCase => patientCase.id == selectedCaseId) ?? null
      }
    }
    return { currentCaseMatch, messageIdentifier }
  })()

  const history = useHistory()

  // This function is used to clear search results and yellow highlighting
  // once the last message linked to case has been removed
  const handleCloseCaseSearch = () => {
    const existingQS = qs.parse(location.search)
    delete existingQS['caseId']
    delete existingQS['action']
    history.push({
      search: qs.stringify(existingQS),
    })
  }

  const handleUnlinkCase = () => {
    if (!currentCaseMatch) return
    if (currentCaseMatch.relations.length == 1) {
      handleCloseCaseSearch()
    }
    return handleUpdatePatientCase({
      ...currentCaseMatch,
      tasks: currentCaseMatch.tasks.map(t => pick(t, ...VIEWABLE_TASK_FIELDS) as ITask),
      relations: currentCaseMatch.relations.filter(
        relation =>
          !(
            relation.contentType === messageIdentifier.type &&
            relation.objectId === messageIdentifier.id
          )
      ),
    })
  }

  return (
    <Box display="flex" position="relative" flexGrow={1}>
      {/* If already cased
      Display the case names (not parent)
      If not already cased
      Show a "Case" button */}
      {!currentCaseMatch ? (
        <CaseInfoChips messageIdToCaseInfo={messageIdToCaseInfo} message={props.message} />
      ) : null}
      {/* If there is a selected Case and this message is linked to it, offer the option to un-link it */}
      {currentCaseMatch ? (
        <Box marginLeft={1}>
          <Button
            onClick={handleUnlinkCase}
            size="small"
            startIcon={<ClearIcon />}
            sx={{
              padding: '0',
              opacity: '1',
              color: 'secondary.light',
              background: 'none',
              border: 'none',
            }}
          >
            Remove from Case
          </Button>
        </Box>
      ) : (
        // For visual consistency, take up the space that would have been taken up
        // by Case chips
        <Box mb={2.6} />
      )}
    </Box>
  )
}

export default MessageInfo
