import { useState } from 'react'
import { Typography, Button } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { GridCellValue } from '@mui/x-data-grid-pro'
import UserDataEditPopper from './UserDataEditPopper'
import { WorkUnitGridRow } from './WorkUnitTable'

interface EditableUserDataCellProps {
  field: string
  validValues?: string[] | undefined
  value: GridCellValue | undefined
  worklistRowData: WorkUnitGridRow
  editable?: boolean
  handleOnEdit: (WorklistWorkUnit, string, any) => void
}

const EditableUserDataCell = (props: EditableUserDataCellProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const currentValue = props.value
  const open = Boolean(anchorEl)

  const onEditClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const onPopperClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography>{currentValue || '-'}</Typography>
      {!(props.editable === false) && (
        <Button onClick={onEditClick}>
          <Edit titleAccess="Edit" />
        </Button>
      )}
      <UserDataEditPopper
        anchorEl={anchorEl}
        open={open}
        field={props.field}
        validValues={props.validValues}
        handleOnEdit={props.handleOnEdit}
        currentValue={currentValue}
        worklistRowData={props.worklistRowData}
        onClose={onPopperClose}
      />
    </div>
  )
}

export default EditableUserDataCell
