import { useState, useEffect, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useDispatch, useSelector } from 'react-redux'
import { orderBy } from 'lodash/fp'
import AddIcon from '@mui/icons-material/Add'
import {
  IconButton,
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Accordion,
  AccordionDetails,
} from '@mui/material'
import * as CarePlanService from '~/api/CarePlanService'
import CarePlansModal from '~/components/Careplans/CarePlansModal'
import Loader from '~/components/Loader'
import { updateTask, getTasksSuccess } from '~/redux/actions/tasks'
import CareplansSingle from './CareplansSingle'
import { useMutation } from '~/utils/useMutation'
import { ICarePlan } from '~/models/CarePlan'
import { queueNotification } from '~/redux/actions/notifications'
import CarePlanActionModal from './CarePlansModal/CarePlanActionModal'
import NewCarePlanHeader from './NewCarePlanHeader'
import CaseBasedCarePlanContent from './CaseBasedCarePlanContent'
import CaseBasedCarePlanModal from './CaseBasedModal/CaseBasedCarePlanModal'
import { useEducationResources } from '~/api/CarePlanService'
interface ICarePlansProps {
  patient: any
}

export type MeasureStatus = 'Outstanding' | 'Complete'

const TABS = {
  active: 'active' as const,
  complete: 'complete' as const,
} as const

const CarePlans = (props: ICarePlansProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [carePlans, setCarePlans] = useState<ICarePlan[]>([])
  const [modalCarePlan, setModalCarePlan] = useState<ICarePlan | null>(null)
  const [tab, setTab] = useState<string>(TABS.active)
  const dispatch = useDispatch()
  const providers = useSelector(state => state.providers)
  const assigneeGroups = useSelector(state => state.assigneeGroups)
  const me = useSelector(state => state.me)

  const [modalCarePlanAction, setModalCarePlanAction] = useState<{
    carePlan: ICarePlan
    action: string
    onCarePlanActionChange: (action: string) => void
  } | null>(null)
  const { classes } = useStyles()
  const [edit, saveEdit] = useState<boolean>(false)

  const { loading: loadingCarePlans, handler: loadCarePlans } = useMutation(async () => {
    var result
    result = await CarePlanService.getPatientCaseBasedCarePlans(props.patient.id)
    setCarePlans(result)
    return result
  })

  const { data: educationResourceOptions } = useEducationResources()

  useEffect(() => {
    loadCarePlans()
  }, [props.patient.id])

  /**
   * Filter care plans by checking statusCategory
   * @param {Boolean} Active
   */
  const getFilteredCarePlans = (active = true, carePlans: ICarePlan[]) => {
    if (active) {
      return carePlans.filter(
        carePlan => carePlan.statusCategory !== 'complete' && carePlan.statusCategory !== 'deferred'
      )
    } else {
      return carePlans.filter(
        carePlan => carePlan.statusCategory === 'complete' || carePlan.statusCategory === 'deferred'
      )
    }
  }

  const activeLength = useMemo(() => {
    return getFilteredCarePlans(true, carePlans).length
  }, [carePlans])

  const completeLength = useMemo(() => {
    return getFilteredCarePlans(false, carePlans).length
  }, [carePlans])

  // Sort care plans - last updated first for non case based and case based will be on the top
  const sortedCarePlans = useMemo(() => {
    var updateAtSortedCarePlans = orderBy(
      carePlan => carePlan.updatedAt,
      ['desc'],
      getFilteredCarePlans(tab === TABS.active, carePlans)
    )

    var caseBasedSortedCarePlans = orderBy(
      carePlan => !!carePlan.isCaseBasedCarePlan,
      ['desc'],
      getFilteredCarePlans(tab === TABS.active, updateAtSortedCarePlans)
    )

    return caseBasedSortedCarePlans
  }, [carePlans, tab])

  const dispatchGetTasksSuccess = (tasks: any[]) => dispatch(getTasksSuccess(tasks))

  const existingCarePlanTypeIds: { [key: number]: boolean } = (() => {
    const typeIds = {}
    for (const carePlan of carePlans) {
      if (carePlan.typeId) {
        typeIds[carePlan.typeId] = true
      }
    }
    return typeIds
  })()

  const openModal = (carePlan = null, editClick = false) => {
    setModalOpen(true)
    setModalCarePlan(carePlan)
    if (editClick) saveEdit(editClick)
    else saveEdit(false)
  }

  const closeModal = () => {
    setModalOpen(false)
    setModalCarePlan(null)
  }

  // Callback for modal actions. Add careplan if it's new,
  // otherwise update existing
  const onCareplanCreateOrUpdate = updatedCarePlan => {
    let newCarePlans = [...carePlans]
    const carePlanIndex = newCarePlans.findIndex(c => c.id === updatedCarePlan.id)
    if (carePlanIndex > -1) {
      newCarePlans[carePlanIndex] = updatedCarePlan
    } else {
      newCarePlans = carePlans.concat(updatedCarePlan)
    }
    setCarePlans(newCarePlans)
    dispatchGetTasksSuccess(updatedCarePlan.tasks)
  }

  // Local reducer for toggling tasks complete
  const toggleTaskComplete = async (carePlanId, taskId, payload) => {
    const updatedTask = await dispatch(updateTask(taskId, payload))
    const newCarePlans = [...carePlans]
    const carePlanIndex = newCarePlans.findIndex(c => c.id === carePlanId)
    const taskIndex = newCarePlans[carePlanIndex].tasks.findIndex(t => t.id === taskId)
    newCarePlans[carePlanIndex].tasks[taskIndex] = updatedTask
    setCarePlans(newCarePlans)
  }

  const dispatchNotification = notification => {
    dispatch(queueNotification(notification))
  }
  const renderCarePlans = () => {
    if (loadingCarePlans) return <Loader />
    return (sortedCarePlans || []).map((carePlan, i) => {
      var categorizedMeasureCollection = {}
      if (carePlan.measureCollectionMeasures) {
        categorizedMeasureCollection = carePlan.measureCollectionMeasures.reduce((acc, mcm) => {
          let key: MeasureStatus
          for (const measureReport of mcm.measureReports) {
            if (measureReport.isCareGap) {
              key = 'Outstanding'
            } else {
              key = 'Complete'
            }
            if (key) {
              acc[key] ||= []
              acc[key].push(mcm)
            }
          }

          return acc
        }, {})
      }

      return (
        <Accordion
          // expanded={!!accordionState[key]}
          // onChange={(_, expanded) => setAccordionState(key, expanded)}
          defaultExpanded={i === 0}
          sx={{
            boxShadow: 'none',
            '&::before': {
              backgroundColor: 'transparent',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
            '&.Mui-expanded .MuiButtonBase-root.MuiAccordionSummary-root.Mui-focusVisible': {
              backgroundColor: 'transparent',
            },
            '&.Mui-expanded .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: 'auto',
              cursor: 'default',
            },
            '& .MuiButtonBase-root.MuiAccordionSummary-root': {
              minHeight: 'auto',
              cursor: 'default',
            },
            '& .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded':
              {
                margin: '6px 0',
              },
            '& .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content': {
              margin: '6px 0',
              paddingTop: 0.75,
            },
          }}
          key={i}
        >
          <NewCarePlanHeader
            providers={providers}
            carePlan={carePlan}
            openModal={openModal}
            setModalCarePlanAction={setModalCarePlanAction}
            onCareplanCreateOrUpdate={onCareplanCreateOrUpdate}
            dispatchNotification={dispatchNotification}
            categorizedMeasureCollection={categorizedMeasureCollection}
          ></NewCarePlanHeader>
          <AccordionDetails>
            {carePlan.isCaseBasedCarePlan ? (
              <CaseBasedCarePlanContent
                carePlan={carePlan}
                categorizedMeasureCollection={categorizedMeasureCollection}
              ></CaseBasedCarePlanContent>
            ) : (
              <CareplansSingle
                patient={props.patient}
                providers={providers}
                openModal={openModal}
                toggleTaskComplete={toggleTaskComplete}
                carePlan={carePlan}
                key={`carePlan_${i}`}
                hideShowCompletedToggle={tab == TABS.complete}
                onCareplanCreateOrUpdate={onCareplanCreateOrUpdate}
                dispatchNotification={dispatchNotification}
                setModalCarePlanAction={setModalCarePlanAction}
                assigneeGroups={assigneeGroups}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )
    })
  }
  const carePlansModal = () => {
    return (
      <>
        {modalCarePlan && modalCarePlan.isCaseBasedCarePlan ? (
          <CaseBasedCarePlanModal
            carePlan={modalCarePlan}
            closeModal={closeModal}
            onCareplanCreateOrUpdate={onCareplanCreateOrUpdate}
            educationResourceOptions={educationResourceOptions}
            allCarePlans={carePlans}
            openModal={openModal}
            edit={edit}
          />
        ) : (
          <CarePlansModal
            closeModal={closeModal}
            carePlan={modalCarePlan}
            open={modalOpen}
            patient={props.patient}
            me={me}
            handleModalAction={onCareplanCreateOrUpdate}
            existingCarePlanTypeIds={existingCarePlanTypeIds}
          />
        )}
        {modalCarePlanAction == null ? null : (
          <CarePlanActionModal
            carePlan={modalCarePlanAction.carePlan}
            onCarePlanActionChange={modalCarePlanAction.onCarePlanActionChange}
            action={modalCarePlanAction.action}
            closeModal={() => setModalCarePlanAction(null)}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" m={2}>
        <Box alignSelf={'center'}>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={(e, nextTab) => {
              // clearHistory()
              setTab(nextTab)
            }}
            size="small"
            aria-label="Status"
            sx={{
              '.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
                padding: '7px 20px',
                textTransform: 'capitalize',
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '.MuiToggleButton-root.Mui-selected': {
                backgroundColor: '#f5f5f5',
                color: '#021A39',
              },
            }}
          >
            <ToggleButton value={TABS.active}>{`Active (${activeLength})`}</ToggleButton>
            <ToggleButton value={TABS.complete}>{`Inactive (${completeLength})`}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box alignSelf={'center'}>
          <IconButton
            color="primary"
            onClick={() => openModal(null)}
            classes={{ root: classes.actionsButton }}
            sx={theme => ({
              '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary[700],
              },
              borderRadius: 'unset',
            })}
          >
            <AddIcon color="inherit" sx={{ fontSize: '1.4rem', fontWeight: 500 }} />
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>Add care plan</Typography>
          </IconButton>
        </Box>
      </Box>
      {renderCarePlans()}
      {carePlansModal()}
    </>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    actionsButton: {
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  }
})

export default CarePlans
