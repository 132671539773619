export const selectCustomStyles = {
  menuPortal: base => ({
    ...base,
    zIndex: 9999,
  }),
  control: (base, state) => ({
    backgroundColor: '#F3F3F3',
    display: 'flex',
    fontSize: '1.6rem',
    height: '100%',
    borderBottom: `2px solid #F3F3F3`,
    borderColor: state.isFocused ? '#0086EB' : '#F3F3F3',
  }),
  group: () => ({
    fontSize: '1.6rem',
  }),
  groupHeading: () => ({
    fontSize: '1.6rem',
    color: '#0077DC',
    margin: '1rem',
  }),
}
