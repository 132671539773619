import { Close } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { FC, useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { AvailablePhysicianSlot, VisitReason } from '~/api/AppointmentService'
import * as AppointmentService from '~/api/AppointmentService'
import FFModal from '~/components/FFModal/FFModal'
import Loader from '~/components/Loader'
import { queueNotification } from '~/redux/actions/notifications'
import BookAppointment from './BookAppointment'
import BookingReason from './BookingReason'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import { ApptTypes } from '..'
import PrimaryConcern, { SymptomItem } from './PrimaryConcern'
import { DYNAMIC_SCHEDULE_V2 } from '~/components/Appointment/utils'

type Step = 'book' | 'reason' | 'selectConcern'

type Props = {
  patientId: number
  personId: number
  onClose: () => void
  openModal?: boolean
  visitType: VisitReason | null
  visitTypeTitle: ApptTypes | ''
}

const BookAppointmentModalWindow: FC<Props> = ({
  patientId,
  personId,
  onClose,
  openModal,
  visitType,
  visitTypeTitle,
}) => {
  const isDynamicSchedulingV2Enabled: boolean = useFeatureFlag(DYNAMIC_SCHEDULE_V2)
  const enableLucianTriageFlow: boolean = useFeatureFlag('appointments.enableLucianTriageFlow')
  let modalTitle: string = ''
  const now = new Date()
  const [visitReason, setVisitReason] = useState<VisitReason | null>(visitType)
  const [allSymptoms, setAllSymptoms] = useState<any[]>([])
  const [categories, setCategories] = useState<any[]>([])
  const [step, setStep] = useState<Step>(
    visitReason == 'video' && enableLucianTriageFlow ? 'selectConcern' : 'book'
  )
  const [date, setDate] = useState(now)
  const [slot, setSlot] = useState<AvailablePhysicianSlot | null>(null)
  const [symptomsSelected, setSymptomsSelected] = useState<SymptomItem[]>([])
  const [otherSymptomsSelected, setOtherSymptomsSelected] = useState<string[]>([])
  const [apptReason, setApptReason] = useState<string>('')
  const [saving, setSaving] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { data: symptomData } = AppointmentService.useGetSymptoms()
  const dispatch = useDispatch()
  const client = useQueryClient()

  useEffect(() => {
    if (symptomData) {
      setIsLoading(false)
      setAllSymptoms(symptomData.allSymptoms)
      setCategories(symptomData.homePageCategories)
    }
  }, [symptomData])

  const handleBookAppointment = async () => {
    if (apptReason === '' && visitReason && !['video', 'focused'].includes(visitReason)) {
      return
    }
    setSaving(true)
    let payload
    if (isDynamicSchedulingV2Enabled) {
      payload = {
        description: apptReason,
        reason: slot?.reason,
        patientId: patientId,
        symptomIds:
          symptomsSelected && symptomsSelected.length ? symptomsSelected.map(({ id }) => id) : [],
        otherSymptoms: otherSymptomsSelected.length ? otherSymptomsSelected.join(', ') : null,
        scheduledDate: slot?.scheduledDate,
        physicianId: slot?.physicianId,
      }
    } else {
      payload = {
        description: apptReason,
        patientId: patientId,
        appointmentId: slot?.id,
        symptomIds:
          symptomsSelected && symptomsSelected.length ? symptomsSelected.map(({ id }) => id) : [],
        otherSymptoms: otherSymptomsSelected.length ? otherSymptomsSelected.join(', ') : null,
      }
    }

    try {
      const apptBookData = isDynamicSchedulingV2Enabled
        ? await AppointmentService.bookAppointmentV2(payload)
        : await AppointmentService.bookAppointment(payload)
      if (apptBookData.patient !== null) {
        dispatch(
          queueNotification({
            message: `Appointment booked successfully`,
            variant: 'success',
          })
        )
      } else {
        dispatch(
          queueNotification({
            message: `Failed to book the appointment`,
            variant: 'error',
          })
        )
      }
    } catch (error) {
      dispatch(
        queueNotification({
          message: `Failed to book the appointment`,
          variant: 'error',
        })
      )
    }
    await client.invalidateQueries(['getPatientAppointmentsInfo', patientId])
    await client.invalidateQueries(['useAppointmentLogs', patientId])
    setSaving(false)
    onClose()
  }

  const stepBook = visitReason => {
    return (
      <Box my={1} mx={2} display="flex" justifyContent="flex-end">
        {visitReason == 'video' && enableLucianTriageFlow ? (
          <Box
            sx={{
              flexGrow: 1,
              display: 'inline-table',
            }}
          >
            <Button
              onClick={() => {
                setStep('selectConcern')
              }}
              variant="text"
              disabled={saving}
              size="small"
              sx={{
                borderRadius: 10,
                fontSize: '1.4rem',
                maxHeight: '75%',
                width: '20%',
                marginRight: 1,
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Back'}</Typography>
            </Button>
          </Box>
        ) : null}
        <Button
          onClick={() => {
            onClose()
          }}
          variant="text"
          // disabled={saving}
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
            maxHeight: '75%',
            width: '20%',
            marginRight: 1,
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
        </Button>
        <Button
          disabled={slot ? false : true}
          onClick={() => {
            setStep('reason')
          }}
          variant="contained"
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Next'}</Typography>
        </Button>
      </Box>
    )
  }

  const stepReason = () => {
    return (
      <Box my={1} mx={2} display="flex" justifyContent="flex-end">
        <Box
          sx={{
            flexGrow: 1,
            display: 'inline-table',
          }}
        >
          <Button
            onClick={() => {
              setStep('book')
            }}
            variant="text"
            disabled={saving}
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '20%',
              marginRight: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Back'}</Typography>
          </Button>
        </Box>
        <Button
          onClick={() => {
            onClose()
          }}
          variant="text"
          disabled={saving}
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
            maxHeight: '75%',
            width: '20%',
            marginRight: 1,
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
        </Button>
        <Button
          disabled={
            saving ||
            (apptReason === '' &&
              visitReason !== null &&
              !['video', 'focused'].includes(visitReason))
          }
          onClick={handleBookAppointment}
          variant="contained"
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Book'}</Typography>
        </Button>
      </Box>
    )
  }

  const stepSelectConcern = () => {
    return (
      <Box my={1} mx={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={() => {
            onClose()
          }}
          variant="text"
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
            maxHeight: '75%',
            width: '20%',
            marginRight: 1,
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Cancel'}</Typography>
        </Button>
        <Button
          disabled={symptomsSelected.length || otherSymptomsSelected.length ? false : true}
          onClick={() => {
            setStep('book')
          }}
          variant="contained"
          size="small"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
          }}
        >
          <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Next'}</Typography>
        </Button>
      </Box>
    )
  }

  switch (step) {
    case 'book': {
      modalTitle = enableLucianTriageFlow ? 'Book ' + visitTypeTitle : 'Book Appointment'
      break
    }
    case 'selectConcern': {
      modalTitle = 'Add Primary Concerns'
      break
    }
    case 'reason': {
      modalTitle = enableLucianTriageFlow ? 'Reason for Visit' : 'Add Reason'
      break
    }
    default: {
      modalTitle = 'Book Appointment'
      break
    }
  }

  return (
    <FFModal
      open={openModal == true || openModal == null}
      style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
      footer={
        visitReason ? (
          step == 'book' ? (
            stepBook(visitReason)
          ) : step == 'selectConcern' ? (
            stepSelectConcern()
          ) : (
            stepReason()
          )
        ) : (
          <></>
        )
      }
    >
      {isLoading && <Loader />}
      <Box m={2}>
        <Typography variant="h6">{modalTitle}</Typography>
      </Box>
      <Box m={2}>
        <IconButton
          onClick={() => {
            onClose()
          }}
          sx={{
            position: 'absolute',
            zIndex: 100,
            top: 2,
            right: 2,
          }}
        >
          <Close />
        </IconButton>
      </Box>
      {step === 'book' ? (
        <BookAppointment
          patientId={patientId}
          personId={personId}
          visitReason={visitReason}
          setVisitReason={setVisitReason}
          now={now}
          date={date}
          setDate={setDate}
          slot={slot}
          setSlot={setSlot}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          symptomsSelected={symptomsSelected}
          otherSymptomsSelected={otherSymptomsSelected}
        />
      ) : step === 'selectConcern' ? (
        <PrimaryConcern
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          symptomsSelected={symptomsSelected}
          setSymptomsSelected={setSymptomsSelected}
          otherSymptomsSelected={otherSymptomsSelected}
          setOtherSymptomsSelected={setOtherSymptomsSelected}
          setVisitReason={setVisitReason}
          patientId={patientId}
          categories={categories}
          allSymptoms={allSymptoms}
          setAllSymptoms={setAllSymptoms}
        />
      ) : (
        <BookingReason
          apptReason={apptReason}
          setApptReason={setApptReason}
          visitReason={visitReason}
          saving={saving}
          symptomsSelected={symptomsSelected}
          otherSymptomsSelected={otherSymptomsSelected}
        />
      )}
    </FFModal>
  )
}

export default BookAppointmentModalWindow
