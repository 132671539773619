import type { FC } from 'react'
import { Avatar, Box, CardHeader, Typography, colors } from '@material-ui/core'
import { LockOutlined, AccessTimeOutlined, ForumOutlined } from '@material-ui/icons'

const CONTENT = [
  {
    Icon: ForumOutlined,
    color: colors.green[100],
    body: 'Message us here with questions or issues before they become a big deal. This is the best way to reach us.',
  },
  {
    Icon: AccessTimeOutlined,
    color: colors.purple[100],
    body: 'We’ll get back to you quick if it’s urgent or within a day for non-urgent stuff.',
  },
  {
    Icon: LockOutlined,
    color: colors.yellow[100],
    body: 'Your privacy is our priority. To get faster, better care sometimes a Firefly team member outside of your care team may reach out.',
  },
]

export const ChatWelcomeTips: FC = () => {
  return (
    <Box p={3}>
      <Typography variant="subtitle2" color="inherit">
        Visible to member
      </Typography>
      {CONTENT.map(({ Icon, color, body }, i) => (
        <Box key={i} mt={1}>
          <Box border={`2px solid ${colors.grey[200]}`} borderRadius={10}>
            <CardHeader
              avatar={
                <Avatar style={{ backgroundColor: color, color: colors.blueGrey[900] }}>
                  <Icon color="inherit" />
                </Avatar>
              }
              subheader={body}
              subheaderTypographyProps={{ color: 'inherit' }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default ChatWelcomeTips
