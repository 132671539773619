import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import React, { ReactNode, createContext, useContext, useState } from 'react'

const Topics: React.FC = () => {
  return (
    <TopicList>
      <Topic title="Patient is not showing up">
        <List>
          <Item>
            Double check you are logged into the right video visit, patient, and date of
            appointment.
          </Item>
        </List>
      </Topic>

      <Topic title="You cannot hear the patient">
        <List>
          <Item>
            When the patient is looking at another app on their phone, you will not be able to hear
            them.
          </Item>
          <Item>
            Make sure that your speakers are connected and that your volume is up. If you use
            headphones, make sure they are connected, or disconnect them if you need to.
          </Item>
          <Item>
            Make sure that the patient has granted permission for the app to use their microphone,
            and that it is not muted.
          </Item>
          <Item>Disconnect and reconnect to the visit.</Item>
          <Item>
            If the above steps do not resolve the issue, report it in{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://firefly-health.slack.com/archives/CCLC0NJLE"
            >
              #firefly-support
            </a>
            .
          </Item>
        </List>
      </Topic>

      <Topic title="The patient's audio is garbled, some words are dropped">
        <List>
          <Item>
            Usually this is because the patient has their hand on the mic of their phone (the bottom
            normally), they are outside and it is windy, their mic is rubbing against something like
            their shirt, or the mic is too far away.
          </Item>
          <Item>
            Ensure your Internet is stable and the speed is adequate. Run a{' '}
            <a target="_blank" rel="noreferrer" href="https://www.google.com/search?q=speed+test">
              speed test
            </a>
            . Zoom recommends at least 3.0 Mbps down and 3.8 Mbps up for the best video quality, but
            keep in mind that other applications on your computer besides Zoom are likely using
            additional bandwidth.
          </Item>
          <Item>
            If your Internet is not working well: Restart your router, restart and update your
            computer, and close unnecessary windows. Contact your manager if your speed or stability
            of your Internet is chronically challenging.
          </Item>
        </List>
      </Topic>

      <Topic title="You cannot see the patient">
        <List>
          <Item>
            When the patient is looking at another app on their phone, you will not be able to see
            them.
          </Item>
          <Item>
            Make sure that the patient has granted permission for the app to use their camera, and
            that their camera is turned on.
          </Item>
          <Item>Disconnect and reconnect to the visit.</Item>
          <Item>
            If the above steps do not resolve the issue, report it in{' '}
            <a href="https://firefly-health.slack.com/archives/CCLC0NJLE">#firefly-support</a>.
          </Item>
        </List>
      </Topic>

      <Topic title="The patient cannot see you">
        <List>
          <Item>
            Make sure that you have selected the correct camera, and that it is turned on and not
            obstructed.
          </Item>
          <Item>Disconnect and reconnect to the visit.</Item>
          <Item>
            If the above steps do not resolve the issue, report it in{' '}
            <a href="https://firefly-health.slack.com/archives/CCLC0NJLE">#firefly-support</a>.
          </Item>
        </List>
      </Topic>

      <Topic title="The patient cannot hear you">
        <List>
          <Item>
            Make sure that your headphones are connected if you are using them. Disconnect them if
            you need to.
          </Item>
          <Item>
            Make sure that you are not muted. There are two places you computer may be on mute - the
            controls on a Mac and the mute in the video visit screen. Ensure both are unmuted.
          </Item>
          <Item>Disconnect and reconnect to the visit.</Item>
          <Item>
            If the above steps do not resolve the issue, report it in{' '}
            <a href="https://firefly-health.slack.com/archives/CCLC0NJLE">#firefly-support</a>.
          </Item>
        </List>
      </Topic>
    </TopicList>
  )
}

const Context = createContext<{
  title: string | null
  setTitle: any
  content: ReactNode | null
  setContent: any
}>({
  title: null,
  setTitle: null,
  content: null,
  setContent: null,
})

const Header: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { title, setTitle, setContent } = useContext(Context)

  return (
    <>
      <Box p={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {title ? (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setTitle(null)
              setContent(null)
            }}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <ChevronLeftIcon color="inherit" />
            <Typography variant="h6">Back</Typography>
          </IconButton>
        ) : (
          <Typography variant="h6">Help</Typography>
        )}
      </Box>
      <IconButton color="inherit" onClick={() => onClose()}>
        <CloseIcon color="inherit" />
      </IconButton>
    </>
  )
}

const Body: React.FC = () => {
  const { title, content } = useContext(Context)

  if (content) {
    return (
      <>
        <Title>{title}</Title>
        {content}
      </>
    )
  }

  return <Topics />
}

const Title: React.FC = ({ children }) => {
  return <Typography variant="h6">{children}</Typography>
}

const TopicList: React.FC = ({ children }) => {
  return <List>{children}</List>
}

const Topic: React.FC<{ title: string }> = ({ title, children }) => {
  const { setTitle, setContent } = useContext(Context)
  return (
    <ListItem
      disablePadding
      disableGutters
      sx={{
        padding: 0,
      }}
    >
      <ListItemButton
        disableGutters
        onClick={() => {
          setTitle(title)
          setContent(children)
        }}
      >
        <ListItemText>{title}</ListItemText>
        <ChevronRightIcon />
      </ListItemButton>
    </ListItem>
  )
}

const Item: React.FC = ({ children }) => {
  return (
    <ListItem>
      <Typography paragraph>{children}</Typography>
    </ListItem>
  )
}

const Inner: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={theme => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.grey[100],
          backgroundColor: theme.palette.grey[700],
        })}
      >
        <Header onClose={onClose} />
      </Box>
      <Box m={2} sx={{ overflow: 'scroll' }}>
        <Body />
      </Box>
    </Box>
  )
}

export const SessionHelp: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [title, setTitle] = useState<string | null>(null)
  const [content, setContent] = useState<ReactNode | null>(null)

  return (
    <Context.Provider
      value={{
        title,
        setTitle,
        content,
        setContent,
      }}
    >
      <Inner onClose={onClose} />
    </Context.Provider>
  )
}
