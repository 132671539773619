import { createMuiTheme } from '@material-ui/core/styles'

// Refer to Figma for the full Firefly Palette:
// figma.com/file/bPOkpSm7oWIKMZ0nNhwUT6/Firefly-Palette

export const BLUE = {
  50: '#E5F3FD',
  100: '#CCE7FB',
  200: '#99CFF7',
  300: '#66B6F3',
  400: '#339EEF',
  500: '#0086EB',
  600: '#0077DC',
  700: '#0059CD',
  800: '#004ABE',
  900: '#004AAF',
  lighter: '#E5F3FD',
  light: '#99CFF7',
  main: '#0077DC',
}

export const NAVY = {
  50: '#E6E9EC',
  100: '#CDD2D8',
  200: '#9CA5B2',
  300: '#6A798B',
  400: '#394C65',
  500: '#071F3E',
  600: '#021A39',
  700: '#021A39',
  800: '#000D2C',
  900: '#000827',
}

export const YELLOW = {
  50: '#FFFBE7',
  100: '#FFF7D0',
  200: '#FFEFA1',
  300: '#FFE772',
  400: '#FFDF43',
  500: '#FFD714',
  600: '#F5C805',
  700: '#E6B900',
  800: '#DCA000',
  900: '#D29100',
}

export const GREY = {
  A50: '#FAFAFA',
  A100: '#F9F9F9',
  50: '#F3F3F3',
  100: '#DDE1E6',
  200: '#C1C7CD',
  300: '#A2A9B0',
  400: '#878D96',
  500: '#697077',
  600: '#4D5358',
  700: '#343A3F',
  800: '#21272A',
  900: '#121619',
  main: '#878D96',
}

// Material UI customized theme and overrides
export default createMuiTheme({
  typography: {
    // useNextVariants: true,
    // Using index.css to set the <html> font size to 62.5%, resulting in a 10px font-size in most circumstances
    // MUI takes the htmlFontSize property to properly calculate rems
    htmlFontSize: 10,
    body1: {
      fontSize: '1.6rem',
    },
    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, "sans-serif"',
  },
  palette: {
    background: {
      default: '#fff',
      external: '#f0e8fc', // Highlights external-facing information
    },
    brand: {
      darker: '#D75701',
      dark: '#E1610B',
      main: '#EA5329', // Firefly Orange
      light: '#FAD4C9',
      lighter: '#fef6f4',
    },
    primary: BLUE,
    secondary: GREY,
    navy: NAVY,
    grey: GREY,
    blueGrey: {
      lighter: '#F3F3F3', // Input background color
      light: '#4A4A4A', // Inactive Label, Inactive Icon
      main: '#7b8f9a',
      dark: '#094A7A',
      darker: '#1d2d3e',
    },
    brown: {
      main: '#613510',
    },
    text: {
      primary: NAVY[700],
      secondary: GREY[500],
    },
    divider: GREY[100],
    icons: {
      successGreen: '#8CD839',
      errorRed: '#E12F2F',
    },
  },

  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#F3F3F3',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        animation: 'none',
        backgroundColor: GREY[50],
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: GREY[100],
        },
      },
      containedPrimary: {
        // backgroundColor: BLUE[100],
        // color: NAVY[00],
      },
      outlined: {
        borderRadius: 24,
      },
      outlinedPrimary: {
        borderColor: BLUE[600],
        '&:hover': {
          borderColor: BLUE[900],
          backgroundColor: BLUE[50],
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: GREY[100],
        color: GREY[600],
        fontWeight: 500,
      },
      clickable: {
        padding: 10,
        '&:hover, &:focus': {
          backgroundColor: GREY[200],
        },
      },
      colorPrimary: {
        backgroundColor: BLUE[50],
        color: BLUE[600],
      },
      clickableColorPrimary: {
        '&:hover, &:focus': {
          backgroundColor: BLUE[100],
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked': {
          color: BLUE[500],
        },
        '&$checked + $track': {
          backgroundColor: BLUE[200],
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        '&:hover': {
          color: BLUE[500],
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `0.1rem solid ${GREY[100]}`,
      },
      scrollButtons: {
        '&.Mui-disabled': {
          display: 'none',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.4rem',
      },
    },
  },
})
