import { CrudResource } from '~/legacy/core'
import { apiClient } from '~/api/rest'

import { crudSlice } from './crud'

const macrosResource = new CrudResource<any>(apiClient.rest, '/macros/')

export interface TextMacro {
  id: number
  name: string
  template: string
}

const macrosSlice = crudSlice<TextMacro>('macros', macrosResource)

export default macrosSlice
