import { Box } from '@mui/material'
import { Route } from 'react-router-dom'
import NotePane from '~/components/Notes'
import { borderProps } from '~/utils/borderProps'

const PatientNotes = props => {
  const { patient } = props
  const path_name = patient.id ? '/patients/:id/notes' : '/people/:id/notes'

  return (
    <Box>
      <Route exact path={path_name}>
        <Box {...borderProps(['borderTop'])}>
          <NotePane patientId={patient.id} personId={patient.person.id} />
        </Box>
      </Route>
    </Box>
  )
}

export default PatientNotes
