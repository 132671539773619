import { useCallback, useEffect, useState } from 'react'

/**
 * Tracks unsaved changes on a form, and pops up a confirmation dialog if the
 * user attempts to navigate away with unsaved changes.
 *
 * For example:
 *
 *   const { trackUnsavedChange, clearUnsavedChange } = useUnsavedChanges()
 *
 *   <input
 *     onChange={() => trackUnsavedChange()}
 *     onBlur={async () => {
 *       await handleSave()
 *       clearUnsavedChange()
 *     }}
 *   />
 */
export const useUnsavedChanges = () => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const listener = useCallback(
    (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        // See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
        event.preventDefault()
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true
      }
    },
    [hasUnsavedChanges]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', listener)

    // Remove listener when component is unmounted.
    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [listener])

  const trackUnsavedChange = useCallback(() => {
    setHasUnsavedChanges(true)
  }, [setHasUnsavedChanges])

  const clearUnsavedChange = useCallback(() => {
    setHasUnsavedChanges(false)
  }, [setHasUnsavedChanges])

  return {
    trackUnsavedChange,
    clearUnsavedChange,
  }
}
