import { PureComponent } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

class PatientListItem extends PureComponent {
  render() {
    const { classes, name, email, phone, patientId } = this.props
    return (
      <TableRow>
        <TableCell className={classes.cell}>
          <Typography>{name}</Typography>
        </TableCell>
        <TableCell className={classes.cell}>
          <Typography>{email}</Typography>
        </TableCell>
        <TableCell className={classes.cell}>
          <Typography>{phone}</Typography>
        </TableCell>
        <TableCell className={classes.cell}>
          <Typography>{patientId}</Typography>
        </TableCell>
        <TableCell className={classes.cell}>
          <Button
            id="open-patient-btn"
            fullWidth
            component={Link}
            variant="outlined"
            to={`/patients/${patientId}`}
            color="primary"
          >
            Open Patient
          </Button>
        </TableCell>
      </TableRow>
    )
  }
}

const styles = theme => ({
  cell: {
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
    paddingLeft: '1em',
    paddingRight: '1em!important',
  },
})

export default withStyles(styles)(PatientListItem)
