import { apiClient } from '~/api/rest'
import { useQuery } from '~/components/Providers/ApiProvider'

export const usePatientReferralProgram = patient =>
  useQuery(
    ['patientReferralProgram', patient?.id],
    () => {
      if (!patient?.id) return Promise.resolve()
      return apiClient.rest.get(`/patient-referral-programs/latest/patients/${patient.id}/`)
    },
    { staleTime: Infinity }
  )
