import type { FC } from 'react'
import * as MUI from '@material-ui/core'

import { useEducationModal } from './utils'

export const EducationModalError: FC = () => {
  const { handler } = useEducationModal()
  return (
    <MUI.Box bgcolor={MUI.colors.red[50]} p={2} m={2} borderRadius={5}>
      <MUI.Typography color="error" variant="h6">
        Whoops! Something went wrong
      </MUI.Typography>
      <MUI.Typography>
        Try fetching content again. If this keeps happening, please report it to the support
        channel.
      </MUI.Typography>
      <MUI.Box mt={2}>
        <MUI.Button variant="outlined" onClick={handler}>
          Ok
        </MUI.Button>
      </MUI.Box>
    </MUI.Box>
  )
}

export default EducationModalError
