import { apiClient } from '~/api/rest'

import { queueNotification } from '../../actions/notifications'

import { settingsSlice } from './'

export function getSettings() {
  return async dispatch => {
    try {
      dispatch(settingsSlice.actions.fetchSettingsStart())
      const results = await apiClient.settings.getSettings()
      return dispatch(settingsSlice.actions.fetchSettingsSuccess(results))
    } catch (e) {
      console.error('Error fetching settings', e)
    }
  }
}

export function updateSettings(changes) {
  return async dispatch => {
    try {
      dispatch(settingsSlice.actions.fetchSettingsStart())
      const results = await apiClient.settings.updateSettings(changes)
      dispatch(
        queueNotification({
          variant: 'success',
          message: 'Saved',
        })
      )
      return dispatch(settingsSlice.actions.fetchSettingsSuccess(results))
    } catch (e) {
      console.error('Error updating settings', e)
    }
  }
}
