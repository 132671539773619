import { useQuery } from 'react-query'
import { apiClient } from '~/api/rest'

export interface PodFilterOption {
  name: string
  id: number
}

const getPodFilterOptions = () => apiClient.rest.get<PodFilterOption[]>('/chat/pod-filter-options/')

const usePodFilterOptions = () => {
  const { data: podFilterOptions } = useQuery(['podFilterOptions'], getPodFilterOptions, {
    staleTime: Infinity,
  })
  return podFilterOptions || []
}

export { usePodFilterOptions }
