import axios from 'axios'
import { BASE_URL } from '~/utils/config'
import { camelizeKeys } from 'humps'

function axiosRequest(httpAction, contentType, auth, endpoint, payload: any = null) {
  return new Promise(resolve => {
    resolve(newRequest(httpAction, contentType, auth, endpoint, payload))
  })
}

export async function newRequest(httpAction, contentType, auth, endpoint, payload) {
  let headers
  if (auth) {
    headers = {
      'Content-Type': contentType,
      Authorization: 'Bearer ' + sessionStorage.getItem('sessionToken'),
    }
  } else {
    headers = {
      'Content-Type': contentType,
    }
  }
  let request
  if (payload) {
    request = {
      method: httpAction,
      headers,
      data: payload,
    }
  } else {
    request = {
      method: httpAction,
      headers,
    }
  }
  return axios({
    ...request,
    url: `${BASE_URL}${endpoint}`,
  }).then(response => ({
    ...response,
    data: camelizeKeys(response.data),
  }))
}

export default axiosRequest
