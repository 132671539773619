import { Box, Typography, makeStyles, Theme } from '@material-ui/core'
import Markdown from 'markdown-to-jsx'
import FormTypeOptions from './FormTypeOptions'

const READABLE_CONDITIONALS = {
  has: 'has',
  equals: 'equals',
  not_equals: 'does not equal',
  gte: '>=',
  gt: '>',
  lte: '<=',
  lt: '<',
  sum: 'has a sum',
}

// Includes Description, Content (if there is one), whether to show the sections, and section titles
const FormDetails = props => {
  const { form } = props
  return (
    <Box mb={1}>
      <p>
        <b>Description: </b> {form.description}
      </p>
      <p>
        <b>Learn More: </b>
      </p>
      {form.content ? (
        <Box mx={2}>
          <Typography variant="h5">{form.content.title}</Typography>
          <Markdown>{form.content.text}</Markdown>
        </Box>
      ) : null}
      <p>
        <b>Show Overview: </b> {form.showOverview.toString()}
      </p>
      {form.showOverview ? (
        <p>
          <b>Sections: </b>
          {form.sections.map(section => section.title).join(', ')}
        </p>
      ) : null}
    </Box>
  )
}

// Show Question text, label(optional), type, required, show if(condition, optional)
const QuestionDetails = props => {
  const { question } = props

  const styles = useStyles()
  const withNumber = question.type !== 'info'
  return (
    <Box mb={2} my={4}>
      <p>
        <b id={question.uid} className={withNumber ? styles.withNumber : ''}>
          [{question.uid}] {question.text}
        </b>
      </p>
      <Box mx={2} my={-1}>
        {question.description ? <p>{question.description}</p> : null}
        <div>
          {question.label ? (
            <>
              <b>Label: </b> {question.label} {` `}
            </>
          ) : null}
          <b>Type: </b> {question.type} {` `}
          <b>Required: </b> {question.required.toString()} {` `}
          {question.isTerminal ? (
            <>
              <b>Terminal: </b> {question.isTerminal.toString()}
            </>
          ) : null}
        </div>
        <div>
          {question.condition ? (
            <>
              <b>Show if: </b>
              <FormCondition condition={question.condition} />
            </>
          ) : null}
        </div>
      </Box>
      <Box my={2} mx={4}>
        <FormTypeOptions question={question} />
      </Box>
    </Box>
  )
}

const FormCondition = props => {
  const { condition } = props
  // condition with a formula has a formula type (ex. sum), args (question uids)
  if (condition.formula) {
    return (
      <>
        {condition.formula.args.map(arg => (
          <a href={'#' + arg} key={arg}>
            {arg}
            {', '}
          </a>
        ))}{' '}
        {READABLE_CONDITIONALS[condition.formula.type]} {READABLE_CONDITIONALS[condition.type]}{' '}
        {condition.value?.toString()}
      </>
    )
  }

  if (condition.type === 'or' || condition.type === 'and') {
    return (
      <>
        {condition.args.map((arg, i) => (
          <span key={i}>
            <FormCondition condition={arg} />

            {i < condition.args.length - 1 ? <b> {condition.type} </b> : null}
          </span>
        ))}
      </>
    )
  }

  // condition with field (question uid)
  return (
    <>
      <a href={'#' + condition.field.split('.')[0]}>{condition.field} </a>{' '}
      {READABLE_CONDITIONALS[condition.type]} {condition.value?.toString()}
    </>
  )
}

const FormOverview = props => {
  const { form } = props
  const styles = useStyles()

  return (
    <Box my={2} className={styles.numberReset}>
      <Typography variant="h4">{form.title}</Typography>
      <FormDetails form={form} />
      {form.sections.map(section => (
        <Box mb={2} key={section.id}>
          <Typography variant="h6">{section.title}</Typography>
          {section.questions.map(question => (
            <QuestionDetails key={`question_${question.id}`} question={question} />
          ))}
        </Box>
      ))}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  numberReset: {
    counterReset: 'section 0',
  },
  withNumber: {
    '&::before': {
      counterIncrement: 'section',
      content: 'counters(section, "") ". "',
    },
  },
}))

export default FormOverview
