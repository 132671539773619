import { useState } from 'react'
import type { FC } from 'react'
import { FormControl, MenuItem, Select } from '@mui/material'
import FFInput from '~/components/Generic/FFInput'
import { useHistory } from 'react-router'

import { makeStyles } from 'tss-react/mui'

export type SearchByOption = 'user' | 'people'
export const PatientInstantNav: FC = () => {
  const [query, setQuery] = useState('')
  const [searchBy, setSearchBy] = useState<SearchByOption>('user')
  const history = useHistory()

  const { classes } = useStyles()
  const handleChange = e => {
    const query = e.target.value.replace(/\D+/g, '')
    setQuery(query)
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (searchBy == 'user') history.push(`/patients/${query}`)
      else history.push(`/people/${query}`)
      setQuery('')
      setSearchBy('user')
    }
  }

  return (
    <div className={classes.container}>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, paddingTop: '0.25em' }}>
        <Select
          value={searchBy}
          onChange={e => setSearchBy(e.target.value as SearchByOption)}
          style={{ border: 'none' }}
        >
          <MenuItem value="user">Go to user</MenuItem>
          <MenuItem value="people">Go to person</MenuItem>
        </Select>
      </FormControl>
      <FFInput
        fullWidth
        placeholder="ID"
        InputProps={{
          classes: {
            input: classes.input,
            focused: classes.inputFocused,
          },
        }}
        value={query}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    </div>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    container: {
      marginLeft: '1rem',
      display: 'flex',
    },
    input: {
      paddingTop: '0.75em',
      paddingBottom: '0.75em',
      width: '5em',
    },
    inputFocused: {
      backgroundColor: '#fff',
      outline: `0.1rem solid ${theme.palette.grey[300]}`,
      '&:after': {
        display: 'none',
      },
    },
  }
})
export default PatientInstantNav
