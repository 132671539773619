import { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { LibraryBooksOutlined as LibraryBooksOutlinedIcon } from '@mui/icons-material'
import { SessionHelpModal } from './SessionHelpModal'

export const SessionHelpButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Tooltip title="Help">
        <IconButton onClick={() => setIsOpen(true)}>
          <LibraryBooksOutlinedIcon />
        </IconButton>
      </Tooltip>
      {isOpen ? <SessionHelpModal onClose={() => setIsOpen(false)} /> : null}
    </>
  )
}
