import { AutocompleteRenderInputParams, TextField } from '@mui/material'

type Params = AutocompleteRenderInputParams

export default function Input(params: Params) {
  return (
    <>
      <TextField
        {...params}
        placeholder={`'#' for new cases, 'esc' to exit`}
        variant="outlined"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        sx={{
          '& .MuiOutlinedInput-root': { padding: 0 },
        }}
      />
    </>
  )
}
