import { FormSubmission } from '~/legacy/core'
import { Link } from '@mui/material'

const FormSubmissionRelatedView = ({
  model,
}: {
  model: Pick<FormSubmission, 'id' | 'completedAt'> & { userId: number }
}) => (
  <>
    <Link href={`/patients/${model.userId}/forms/submissions/${model.id}`}>
      View {model.completedAt ? 'completed' : 'incomplete'} form
    </Link>
  </>
)

export default FormSubmissionRelatedView
