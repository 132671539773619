import axios from 'axios'
import { getIdToken } from '~/utils/auth'

export interface AccessToken {
  access_token: string
  issued_token_type: string
  token_type: string
  expires_in: number
}

export const getAccessToken = async tenant => {
  const idToken = await getIdToken()
  /* TODO: this will need to be updated and part of the app config once we finalize our approach for app configs */
  const tokenExchangeUrl = process.env.REACT_APP_ZUS_API_URL as string
  return axios.post<AccessToken>(`${tokenExchangeUrl}/auth/token`, {
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    audience: tokenExchangeUrl,
    requested_token_type: 'urn:ietf:params:oauth:token-type:access_token',
    subject_token_type: 'urn:ietf:params:oauth:token-type:id_token',
    subject_token: idToken,
    custom_unique_identifier: tenant,
  })
}
