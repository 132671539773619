import { makeStyles } from 'tss-react/mui'
import { Check } from '@mui/icons-material'

const CheckBox = (props: {
  value?: boolean
  onChecked: (value: boolean, event?: any) => void
  disabled?: boolean
}) => {
  // Users of CheckBox must provide:
  // 1) value - true/false that sets state of then checkbox
  // 2) onChecked - a callback function that runs every time a user clicks on the checkbox

  const { value, onChecked, disabled } = props
  const { classes } = useStyles()

  const iconClass = value
    ? `${classes.check} ${classes.checkComplete} ${disabled ? classes.checkDisabledComplete : ''}`
    : `${classes.check} ${classes.checkIncomplete} ${
        disabled ? classes.checkDisabledIncomplete : ''
      }`

  return (
    <button
      className={classes.contentTaskButton}
      onClick={event => onChecked(!value, event)}
      disabled={disabled}
    >
      <Check className={iconClass} />
    </button>
  )
}

const useStyles = makeStyles({ name: { CheckBox } })(theme => ({
  contentTaskButton: {
    background: 'inherit',
    border: 0,
    cursor: 'pointer',
    padding: 0,
    '&:focus': {
      outline: '0',
    },
  },
  check: {
    borderRadius: '0.5rem',
    marginRight: theme.spacing(),
    fontSize: '2.5rem',
    padding: '.1rem',
    width: '.8em',
    height: '.8em',
  },
  checkIncomplete: {
    border: `0.2rem solid ${theme.palette.primary.main}`,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
    },
  },
  checkComplete: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  checkDisabledComplete: {
    backgroundColor: theme.palette.secondary.light,
    color: '#fff',
  },
  checkDisabledIncomplete: {
    borderColor: theme.palette.secondary.light,
    backgroundColor: '#fff',
    color: '#fff',
  },
}))

export default CheckBox
