import { useState } from 'react'
import type { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import AttachmentIcon from '@mui/icons-material/AttachFile'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { useDropzone } from 'react-dropzone'
import FFModal from '../../FFModal/FFModal'

import IMG from './img.png'
import FileUploadPreview from './FileUploadPreview'
import { red } from '@mui/material/colors'
import { visuallyHidden } from '@mui/utils'

// Separate types to distinguish between endpoints upstream
export interface FileWithType {
  data: File
  type: 'image' | 'document'
}

interface FileUploadProps {
  file: FileWithType | null
  supportedImageTypes: Array<string>
  supportedDocTypes: Array<string>
  supportMessage: string
  handleFile: (file: FileWithType) => void
  handleClear: () => void
}

const maxFileNameLength = 63

export const FileUpload: FC<FileUploadProps> = props => {
  const [showModal, setShowModal] = useState(false)
  const [file, setFile] = useState<FileWithType | null>(null)
  const {
    getRootProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
    getInputProps,
  } = useDropzone({
    accept: props.supportedImageTypes.concat(props.supportedDocTypes),
    maxFiles: 1,
    maxSize: 1e6 * 100, // 100MB upload max
    multiple: false,
    validator: file => {
      if (file.name.length > maxFileNameLength) {
        setFile(null)
        return {
          code: 'name-too-large',
          message: `Ensure the filename has no more than ${maxFileNameLength} characters`,
        }
      }
      return null
    },
    onDropAccepted: file =>
      setFile({
        data: file[0],
        type: props.supportedImageTypes.includes(file[0].type) ? 'image' : 'document',
      }),
  })
  return (
    <>
      <FFModal
        open={showModal}
        footer={
          <Box p={1} display="flex" justifyContent="flex-end">
            <Button variant="text" onClick={props.handleClear} color="secondary">
              Cancel
            </Button>
            <Box ml={1}>
              <Button
                disabled={!file}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (file) props.handleFile(file)
                  setShowModal(false)
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        }
      >
        <div {...getRootProps()}>
          <Box p={5}>
            <Box
              border="0.3rem dashed"
              borderRadius="4px"
              borderColor={
                isDragReject ? red[500] : `grey.${isFocused || isDragAccept ? 700 : 400}`
              }
              py={10}
              px={5}
            >
              <Typography align="center">
                <img width={220} src={IMG} alt="Upload file" />
                <br />
                Drop your file here or{' '}
                <Typography component="span" color="primary">
                  browse
                </Typography>
                <br />
                {props.supportMessage}
              </Typography>
            </Box>
            {acceptedFiles.length ? (
              <Box mt={2} bgcolor="grey.100" borderRadius="5px" p={1}>
                {acceptedFiles.map((v, i) => (
                  <Box key={i} display="flex" alignItems="center">
                    <InsertDriveFileIcon color="secondary" />
                    <Box ml={1} overflow="hidden">
                      <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {v.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : null}
            {fileRejections.length ? (
              <Box mt={2} bgcolor="grey.100" borderRadius="5px" p={1}>
                {fileRejections.map(fileRejection => (
                  <Box ml={1} key={fileRejection.file.name} overflow="hidden">
                    {fileRejection.errors.map(e => (
                      <Typography
                        key={e.code}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', color: red[500] }}
                      >
                        {e.message}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        </div>
      </FFModal>
      <div style={visuallyHidden}>
        <Typography>
          <input {...getInputProps()} />
        </Typography>
      </div>
      {file && !showModal ? (
        <FileUploadPreview file={file} onClick={props.handleClear} />
      ) : (
        <IconButton onClick={() => setShowModal(true)} size="large">
          <AttachmentIcon />
        </IconButton>
      )}
    </>
  )
}

export default FileUpload
