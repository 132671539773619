import { Box, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import {
  AppointmentTypeMapping,
  AvailablePhysicianSlot,
  VisitReason,
} from '~/api/AppointmentService'
import { format } from 'date-fns'
import SelectAppointmentType from './SelectAppointmentType'
import SelectDate from './SelectDate'
import SelectSlot from './SelectSlot'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import { useAppointmentTypeByReason, useGetSymptomVisitType } from '~/api/ScheduleService'
import { useGetPerson } from '~/api/PersonService'
import { SymptomItem } from './PrimaryConcern'

const DATE_STR_FORMAT = 'MMM D, YYYY'

type BookAppointmentProps = {
  patientId: number
  personId: number
  visitReason: VisitReason | null
  setVisitReason: (value: VisitReason | null) => void
  now: Date
  date: Date
  setDate: (value: Date) => void
  slot: AvailablePhysicianSlot | null
  setSlot: (value: AvailablePhysicianSlot) => void
  setIsLoading: (e: boolean) => void
  isLoading: boolean
  symptomsSelected: SymptomItem[]
  otherSymptomsSelected: string[]
}

const Mapping = {
  Video: 'video',
  'Video-New': 'videoNew',
  'Annual Wellness': 'annualWellness',
  'Health Guide Consult': 'healthGuideConsult',
  'Behavioral Health': 'behavioralHealth',
  Focused: 'focused',
}

const BookAppointment: FC<BookAppointmentProps> = ({
  patientId,
  personId,
  visitReason,
  setVisitReason,
  date,
  setDate,
  now,
  slot,
  setSlot,
  setIsLoading,
  isLoading,
  symptomsSelected,
  otherSymptomsSelected,
}) => {
  const enableLucianTriageFlow: boolean = useFeatureFlag('appointments.enableLucianTriageFlow')
  const { result: appointmentTypeByReason } = useAppointmentTypeByReason()
  const { data: person } = useGetPerson({ personId: personId! })
  const { result: symptomVisitType } = useGetSymptomVisitType({
    symptom_ids: symptomsSelected.map(({ id }) => id),
    other_symptoms: otherSymptomsSelected.length ? otherSymptomsSelected.join(', ') : undefined,
    patient_id: patientId,
  })
  const date_of_last_awv =
    person && person['primaryCareProgramInfo'] && person['primaryCareProgramInfo']['dateOfLastAwv']
  const date_of_last_awv_as_date = new Date(date_of_last_awv)
  const today = new Date()
  const due_beginning =
    visitReason === 'annualWellnessVisitEstablished' && date_of_last_awv
      ? date_of_last_awv_as_date.setFullYear(date_of_last_awv_as_date.getFullYear() + 1)
      : null
  // If the selected visit type is Medical issue (meaning visitReason === 'video'),
  // only then we need to consider the result of symptomVisitType mapping to determine Video or Focused
  // based on the symptom selected
  useEffect(() => {
    if (symptomVisitType && visitReason && ['video', 'focused'].includes(visitReason)) {
      setVisitReason(Mapping[symptomVisitType.reason ? symptomVisitType.reason : 'Video'])
    }
  }, [symptomVisitType, visitReason])

  return (
    <>
      {/* no default appt reason and hence have to play with display */}
      <Box m={2} display={visitReason && !enableLucianTriageFlow ? 'block' : 'none'}>
        <SelectAppointmentType
          patientId={patientId}
          visitReason={visitReason}
          setVisitReason={setVisitReason}
          setIsLoading={setIsLoading}
        />
      </Box>
      {enableLucianTriageFlow && visitReason ? (
        <Box m={2}>
          <Typography fontSize={16} fontWeight={400} sx={{ color: '#071F3E' }}>
            {'Appt length: '}
            {appointmentTypeByReason[AppointmentTypeMapping[visitReason]]?.duration} {'min'}
          </Typography>
          <Typography fontSize={16} fontWeight={400} sx={{ color: '#071F3E' }}>
            {(!date_of_last_awv && visitReason === 'annualWellnessVisitEstablished') ||
            (due_beginning && new Date(due_beginning) < today)
              ? 'Overdue'
              : due_beginning
              ? `Due beginning: ${format(due_beginning, DATE_STR_FORMAT)}`
              : null}
          </Typography>
        </Box>
      ) : null}
      {visitReason ? (
        <Box m={2}>
          <SelectDate date={date} setDate={setDate} now={now} />

          <SelectSlot
            date={date}
            patientId={patientId}
            visitReason={visitReason}
            slot={slot}
            setSlot={setSlot}
            setIsLoading={setIsLoading}
          />
        </Box>
      ) : !isLoading ? (
        <Box m={2}>
          <Typography>{'Patient is not eligible to book appointment.'}</Typography>
        </Box>
      ) : null}
    </>
  )
}

export default BookAppointment
