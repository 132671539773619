import { Box } from '@mui/material'
import { PatientTodo } from '~/models/PatientTodoItem'
import InlineEditableField from './InlineEditableField'

interface Props {
  patientTodo: PatientTodo
  isClosed: boolean
  setIsTodoNoteUpdating: Function
}

const ToDoNotes = (props: Props) => {
  if (props.patientTodo.contentType !== 'case') {
    return null
  }

  return (
    <Box
      mb={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .MuiFormControl-root.MuiTextField-root': {
          marginLeft: 0,
        },
      }}
    >
      {props.patientTodo.contentObject?.notes ? (
        <InlineEditableField
          defaultValue={props.patientTodo.contentObject?.notes}
          selectedCase={props.patientTodo.contentObject.id}
          valueToBeUpdated={'notes'}
          placeholder={'+ Add notes'}
          isClosed={props.isClosed}
          setEditingCase={props.setIsTodoNoteUpdating}
        />
      ) : props.patientTodo.contentObject ? (
        <InlineEditableField
          selectedCase={props.patientTodo.contentObject?.id}
          valueToBeUpdated={'notes'}
          placeholder={'+ Add notes'}
          isClosed={props.isClosed}
          setEditingCase={props.setIsTodoNoteUpdating}
        />
      ) : null}
    </Box>
  )
}

export default ToDoNotes
