import { Alert, LinearProgress } from '@mui/material'

import { ErrorBoundary } from 'react-error-boundary'
import ErrorScreen from '~/components/ErrorScreen'
import InterfaceContent from '~/components/Interface/InterfaceContent'
import { Redirect } from 'react-router-dom'
import { useCase } from '~/api/CaseService'

const CaseDetail = ({ id }: { id: number }) => {
  const { data, isLoading, error } = useCase(id)

  if (data?.person) {
    return <Redirect to={`/people/${data.person}/todos?caseId=${id}`} />
  }

  return (
    <InterfaceContent>
      {isLoading && !error ? <LinearProgress /> : null}
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        {error ? <Alert severity="info">Could not locate case with ID {id}</Alert> : null}
      </ErrorBoundary>
    </InterfaceContent>
  )
}

export default CaseDetail
