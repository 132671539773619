import { Box, Button } from '@mui/material'
import Loader from '~/components/Loader'
import { makeStyles } from 'tss-react/mui'
import Moment from 'moment'
import * as CarePlanService from '~/api/CarePlanService'
import { anyIncompleteTask } from './utils'
import { ITask } from '~/models/Task'

interface IEditOrCustomCreateActionsProps {
  closeModal: () => void
  carePlan: any
  patient: any
  me: any
  handleModalAction: (any) => void
  dispatchRemoveTasks: (arg0: any[]) => void
  saving: boolean
  setSaving: (arg0: boolean) => void
  tasks: ITask[]
  title: string
  notes: string
}

const EditOrCustomCreateActions = (props: IEditOrCustomCreateActionsProps) => {
  const { tasks, saving, setSaving, title, notes } = props

  const { classes } = useStyles()

  const saveIsDisabled = () => {
    // Save is disabled if:
    // 1) Currently saving
    // 2) Care plan title is empty
    // 3) Any task is incomplete. (Empty tasks will be thrown out on save).
    return saving || title === '' || anyIncompleteTask(tasks)
  }

  const newCarePlan = async () => {
    const {
      closeModal,
      handleModalAction,
      patient: { id },
    } = props
    const payload = {
      title: title,
      patient: id,
      notes: notes,
      tasks: tasks.map(task => ({
        title: task.title,
        ...{ ownerGroup: task.ownerGroup },
        due_date: Moment(task.dueDate, 'YYYY-MM-DD').format(),
        priority: task.priority,
      })),
    }
    const carePlan = await CarePlanService.createCarePlan(payload)
    await handleModalAction(carePlan)
    closeModal()
  }

  /**
   * Complicated task to patch careplans since PATCHing
   * can't be used to remove tasks from a care plan, we
   * delete the ones not present in state
   */
  const patchCarePlan = async () => {
    const { closeModal, handleModalAction, carePlan } = props
    // Find any omitted tasks, call the api to remove them, and remove them from redux
    const omittedTasks = carePlan.tasks.filter(({ id }) => !tasks.find(task => task.id === id))
    if (omittedTasks.length) await props.dispatchRemoveTasks(omittedTasks)
    const updatedCarePlan = await CarePlanService.updateCarePlan({
      id: parseInt(carePlan.id),
      title: title,
      notes: notes,
      tasks: tasks
        // Remove any empty (invalid) tasks
        .filter(task => !!task.title && !!task.dueDate && !!task.ownerGroup)
        .map(task => ({
          ...(task.id ? { id: task.id } : {}),
          title: task.title,
          ...{ ownerGroup: task.ownerGroup },
          due_date: Moment(task.dueDate, 'YYYY-MM-DD').format(),
          priority: task.priority,
        })),
    })
    await handleModalAction(updatedCarePlan)
    var updatedCarePlanData = await CarePlanService.getCarePlan(carePlan.id)
    await handleModalAction(updatedCarePlanData)
    closeModal()
  }

  const handleSave = () => {
    const { carePlan } = props
    const saveAction = carePlan ? patchCarePlan : newCarePlan
    setSaving(true)
    try {
      saveAction()
    } catch (e) {
      setSaving(false)
    }
  }

  const buttonContent = saving ? <Loader inline /> : 'Save'

  return (
    <Box m={2} display="flex" justifyContent="flex-end">
      <Button
        onClick={props.closeModal}
        disabled={saving}
        className={`${classes.formSubmissionButton} ${classes.formSubmissionCancel}`}
      >
        Cancel
      </Button>
      <Button
        disabled={saveIsDisabled()}
        className={`${classes.formSubmissionButton} ${classes.formSubmissionSave}`}
        color="primary"
        variant="contained"
        onClick={handleSave}
      >
        {buttonContent}
      </Button>
    </Box>
  )
}

const useStyles = makeStyles()(theme => ({
  formSubmissionButton: {
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  formSubmissionCancel: {
    marginRight: theme.spacing(),
  },
  formSubmissionSave: {
    boxShadow: 'none',
  },
}))

export default EditOrCustomCreateActions
