import { useCallback } from 'react'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import Draggable from 'react-draggable'
// eslint-disable-next-line
import * as MUI from '@material-ui/core'
// eslint-disable-next-line
import * as Icons from '@material-ui/icons'

import { EDUCATION_MODAL_WIDTH } from '~/redux/slices/education'
import Button from '../Generic/Button'
import { EducationModalContent } from './EducationModalContent'
import { EducationModalSkeleton } from './EducationModalSkeleton'
import { EducationModalError } from './EducationModalError'
import { EducationModalHeader } from './EducationModalHeader'
import { EDUCATION_DRAGGABLE_HANDLE, useEducationModal } from './utils'

export const EducationModal: FC = () => {
  const { origin } = useSelector(state => state.education)
  const theme = MUI.useTheme()
  const { error, loading, setShowFeedback } = useEducationModal()

  const handleOpenFeedback = useCallback(() => {
    setShowFeedback(true)
  }, [])

  return (
    <MUI.Box position="absolute" top={0} left={0} height="100%" width="100%">
      <Draggable
        bounds="parent"
        handle={`#${EDUCATION_DRAGGABLE_HANDLE}`}
        defaultPosition={
          origin ?? {
            // If the modal was opened without a click event, place it in
            // the upper middle part of the screen
            x: window.innerWidth / 2 - EDUCATION_MODAL_WIDTH / 2,
            y: window.innerHeight / 10,
          }
        }
      >
        <MUI.Box position="absolute" zIndex={theme.zIndex.modal} width={EDUCATION_MODAL_WIDTH}>
          <MUI.Fade in>
            <MUI.Paper elevation={5}>
              <MUI.Box maxHeight="85vh" display="flex" flexDirection="column">
                <EducationModalHeader />
                <MUI.Box overflow="auto" flex={1}>
                  {error ? (
                    <EducationModalError />
                  ) : loading ? (
                    <EducationModalSkeleton />
                  ) : (
                    <ErrorBoundary FallbackComponent={EducationModalError}>
                      <EducationModalContent />
                      <Button onClick={handleOpenFeedback}>
                        <MUI.Box
                          display="flex"
                          alignItems="center"
                          px={1.5}
                          py={1.5}
                          fontSize={10}
                          color={theme.palette.secondary.main}
                        >
                          <Icons.Flag fontSize="small" />
                          <MUI.Box ml={0.5}>
                            <MUI.Typography variant="caption">Make a suggestion</MUI.Typography>
                          </MUI.Box>
                        </MUI.Box>
                      </Button>
                    </ErrorBoundary>
                  )}
                </MUI.Box>
              </MUI.Box>
            </MUI.Paper>
          </MUI.Fade>
        </MUI.Box>
      </Draggable>
    </MUI.Box>
  )
}

export default EducationModal
