import { Box, CircularProgress, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { Pagination } from '@material-ui/lab'
import { decamelize } from 'humps'
import { useMemo } from 'react'
import PatientListItem from '~/components/PatientList/PatientListItem'
import { patientsResource } from '~/redux/slices/patients/actions'
import { borderProps } from '~/utils/borderProps'
import { usePagination } from '~/utils/usePagination'

const PatientList = () => {
  const patientsGetter = useMemo(
    () => options => {
      return patientsResource.getAll({
        type: 'patient',
        detail: 'false',
        limit: options.limit,
        offset: (options.page - 1) * options.limit,
        ordering: `${options.order.direction === 'asc' ? '' : '-'}${decamelize(options.order.key)}`,
      }) as any
    },
    []
  )
  const {
    page,
    pageCount,
    order,
    setPage,
    loading,
    sortHandler,
    data: patients,
  } = usePagination<any>(patientsGetter, {
    defaultOrder: { key: 'id', direction: 'desc' },
    limit: 50,
  })
  const classes = useStyles()

  if (patients === null) return <LinearProgress />

  const rows = [
    { id: 'lastName', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'phoneNumber', label: 'Phone' },
    { id: 'id', label: 'Patient ID' },
  ]

  return (
    <Box>
      <Box display="flex" alignItems="center" p={2} {...borderProps(['borderBottom'])}>
        <Pagination count={pageCount} page={page} onChange={(event, value) => setPage(value)} />
        {loading ? <CircularProgress size={24} /> : null}
      </Box>
      <Table id="patient-table">
        <TableHead>
          <TableRow>
            {rows.map(row => (
              <TableCell
                className={classes.cell}
                key={row.id}
                sortDirection={order.key === row.id ? order.direction : false}
              >
                <TableSortLabel
                  active={order.key === row.id}
                  direction={order.direction}
                  onClick={sortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.map(patient => (
            <PatientListItem
              key={patient.id}
              patientId={patient.id}
              name={patient.firstName + ' ' + patient.lastName}
              email={patient.email}
              phone={patient.phoneNumber}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  cell: {
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
    paddingLeft: '1em',
    paddingRight: '1em!important',
  },
}))

export default PatientList
