import type { FC } from 'react'
import PriorityIcon from './PriorityIcon'
import { Box, Button } from '@mui/material'
import { makeStyles, Theme } from '@material-ui/core/styles'

const PRIORITY_LEVELS = {
  0: 'defaultPriority',
  1: 'mediumPriority',
  2: 'highPriority',
}

const useStyles = makeStyles((theme: Theme) => ({
  defaultPriority: {
    '& svg:hover > path:nth-of-type(1)': {
      fill: '#A2A9B0',
    },
  },
  mediumPriority: {
    '& svg:hover > path:nth-of-type(1)': {
      fill: '#E1610B',
    },
  },
  highPriority: {
    '& svg:hover > path:nth-of-type(1)': {
      fill: '#AF0400',
    },
  },
  disabled_defaultPriority: {
    '& svg > path:nth-of-type(1)': {
      fill: '#DDE1E6',
    },
  },
  disabled_mediumPriority: {
    '& svg > path:nth-of-type(1)': {
      fill: '#FFCCA9',
    },
  },
  disabled_highPriority: {
    '& svg > path:nth-of-type(1)': {
      fill: '#FF9B99',
    },
  },
}))

interface PriorityToggleProps {
  priority: number
  onChange: (e: React.MouseEvent, priority: number) => any
  disabled?: boolean
}

const PriorityToggle: FC<PriorityToggleProps> = props => {
  const { onChange, priority, disabled } = props
  const styles = useStyles(props)

  return (
    <Box
      sx={{
        '& button': {
          minWidth: '20px',
          padding: 0,
          marginRight: '6px',
        },
      }}
    >
      <Button
        size="small"
        onClick={e => onChange(e, (priority + 1) % 3)}
        disabled={disabled}
        className={
          disabled
            ? `${styles['disabled_' + PRIORITY_LEVELS[priority]]}`
            : `${styles[PRIORITY_LEVELS[priority]]}`
        }
      >
        <PriorityIcon level={priority} variant="view" />
      </Button>
    </Box>
  )
}

export default PriorityToggle
