import { useEffect, useState } from 'react'
import { Box, Typography, MenuItem, Select, Grid } from '@mui/material'
import { Person } from '~/legacy/core'
import { apiClient } from '~/api/rest'
import { queueNotification } from '~/redux/actions/notifications'
import { useDispatch } from 'react-redux'
import Moment from 'moment'
import PriorAuthorizationItem from './PriorAuthorizationItem'
import { PriorAuthorizationItemType, ServiceCategory } from './types'

interface PriorAuthorizationListProps {
  person: Person
}

// API for fetching all PriorAuth data
const fetchPriorAuthorizations = (personId): Promise<any> => {
  return apiClient.rest.get(`/referral/prior_authorization/person/${personId}/`)
}

function asDate(inputDate) {
  const value = Moment(inputDate).format('MMM. D, YYYY')
  if (value.match(/invalid/i)) {
    return inputDate
  } else {
    return value
  }
}

function shorten_with_ellipsis(word: String, length: number) {
  if (word.length < length) {
    return word
  } else {
    return word.slice(0, length) + '...'
  }
}

function priorAuthDropdownDisplay(priorAuth: PriorAuthorizationItemType) {
  const categories = (priorAuth.serviceCategories || [])
    .map((category: ServiceCategory) => category.label)
    .join(', ')

  const priorAuthStatus =
    priorAuth.status && priorAuth.determination ? `(${priorAuth.status})` : priorAuth.status

  const label = `${asDate(priorAuth.vendorCreateDate)} - ${
    priorAuth.determination ? priorAuth.determination : ''
  } ${priorAuthStatus} for ${categories}`
  return shorten_with_ellipsis(label, 80)
}

const PriorAuthorizationList = (props: PriorAuthorizationListProps) => {
  const { person } = props
  const [priorAuthorizations, setPriorAuthorizations] = useState<Array<PriorAuthorizationItemType>>(
    []
  )
  const [selectedPriorAuth, setSelectedPriorAuth] = useState<PriorAuthorizationItemType | null>()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchPriorAuthorizations(person.id)
      .then(data => {
        setPriorAuthorizations(data)
      })
      .catch(e => {
        console.error(
          `Failed to fetch priorAuthorization list for personId: ${props.person.id} Exception:${e}`
        )
        dispatch(
          queueNotification({
            message: `Unable to fetch Prior Authorizations for the person`,
            variant: 'error',
          })
        )
      })
  }, [])

  function choosePriorAuthFromMenu(event) {
    const selectedId = event.target.getAttribute('data-value')
    const priorAuth = priorAuthorizations.find(item => item?.pk == selectedId)
    setSelectedPriorAuth(priorAuth)
  }

  return (
    <Box>
      <Box p={3} pb={0}>
        <Typography>
          <strong>Prior Authorization List</strong>
        </Typography>
        <br />
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select style={{ minWidth: '200px' }} size="small" displayEmpty>
                <MenuItem value="" onClick={() => setSelectedPriorAuth(null)}>
                  <em>List of Prior Authorizations</em>
                </MenuItem>
                {priorAuthorizations.map(priorAuth => (
                  <MenuItem
                    key={priorAuth.pk}
                    value={priorAuth.pk}
                    onClick={choosePriorAuthFromMenu}
                  >
                    {priorAuthDropdownDisplay(priorAuth)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: '30px' }}>
          {selectedPriorAuth && <PriorAuthorizationItem priorAuth={selectedPriorAuth} />}
        </Box>
      </Box>
    </Box>
  )
}

export default PriorAuthorizationList
