import { PatientUser } from '~/legacy/core'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useGetChurnedReasons } from '~/api/OnboardingService'
import { listProgramEnrollmentsKey, useProgramEnrollmentEvent } from '~/api/ProgramService'
import FFModal from '~/components/FFModal/FFModal'
import { PRIMARY_CARE_PROGRAM_UID, ProgramEnrollmentEvents } from '~/constants/programEnrollment'
import { apiClient } from '~/api/rest'
import { useQueryClient } from 'react-query'

interface ChurnMemberModelProps {
  patient: PatientUser
  handleClose: () => void
  open: boolean
  isChurned: boolean
}

const ChurnMemberModel: FC<ChurnMemberModelProps> = props => {
  const date = new Date()
  const [churnDate, setChurnDate] = useState<string>(
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  )
  const [churnedReason, setChurnedReason] = useState<string | undefined>(undefined)
  const client = useQueryClient()
  const { mutateAsync: handleEnrollmentEvent } = useProgramEnrollmentEvent()
  const { data: churnedReasons } = useGetChurnedReasons()

  const handleTextInputSelection = (event: any) => {
    const dateStr: string = event.target.value
    setChurnDate(dateStr)
  }
  const handleChurn = async () => {
    // Below two calls to update onboarding status, we can remove this once we remove onboarding tab
    if (props.isChurned) {
      let reason = ''
      if (churnedReasons && churnedReason) {
        const selectedReason = churnedReasons.find(reason => reason.uniqueKey === churnedReason)
        reason = selectedReason ? `Churned:${selectedReason.label}` : ''
      }

      await handleEnrollmentEvent({
        programUid: PRIMARY_CARE_PROGRAM_UID,
        event: ProgramEnrollmentEvents.PROGRAM_UNENROLLED,
        personId: props.patient.person.id,
        eventDate: churnDate,
        reason: reason,
      })
    } else {
      await apiClient.rest.patch(
        `/programs/primary-care/${props.patient.id}/pending-discharge/v2`,
        {
          pendingDischargeReason: churnedReason,
        }
      )
      await client.cancelQueries([listProgramEnrollmentsKey, props.patient.person.id])
      await client.invalidateQueries([listProgramEnrollmentsKey, props.patient.person.id])
    }
    props.handleClose()
  }

  return (
    <FFModal open={props.open} style={{ width: '60%' }}>
      <Box>
        <Box display="flex" flexDirection="column" mt={3} ml={4}>
          <Typography variant="h6">
            {props.isChurned ? 'Churn Member' : 'Begin Discharge'}
          </Typography>
          <Box mt={4}>
            <Autocomplete
              size="small"
              id="churn-reasons"
              value={
                churnedReasons
                  ? churnedReasons.find(reason => reason.uniqueKey === churnedReason)
                  : { value: 0, label: '', uniqueKey: '' }
              }
              onChange={(_, value) =>
                value?.uniqueKey ? setChurnedReason(value.uniqueKey) : setChurnedReason('')
              }
              options={churnedReasons ? churnedReasons : []}
              getOptionLabel={option => option.label}
              style={{ width: '70%' }}
              renderInput={params => (
                <TextField {...params} label="Select Reason" variant="outlined" />
              )}
            />
          </Box>
          <Box mt={2}>
            <TextField
              value={churnDate}
              onChange={handleTextInputSelection}
              label={props.isChurned ? 'Churn date' : 'Begin discharge on date'}
              variant="outlined"
              style={{ width: '70%' }}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" px={4} py={4}>
          <Box mr={1}>
            <Button disabled={false} onClick={props.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={false}
              variant="contained"
              color="primary"
              onClick={handleChurn}
            >
              {props.isChurned ? 'Churn' : 'Begin Discharge'}
            </Button>
          </Box>
        </Box>
      </Box>
    </FFModal>
  )
}

export default ChurnMemberModel
