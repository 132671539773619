import { Box, Typography } from '@mui/material'
import ProgramDetail from './ProgramDetail'
import { useListProgramEnrollmentByProgram } from '~/api/ProgramService'

const BaselineProgram = ({ programs, patient }) => {
  const { result: programEnrollments, isLoading } = useListProgramEnrollmentByProgram(
    patient.person.id
  )
  const updatedPrograms = programs
  return (
    <Box>
      <Typography variant="h6">Baseline Programs</Typography>
      {!isLoading &&
        updatedPrograms.map((program, i) => {
          return (
            <ProgramDetail
              key={i}
              program={program}
              enrollments={programEnrollments.get(program.uid)}
              patient={patient}
            />
          )
        })}
    </Box>
  )
}

export default BaselineProgram
