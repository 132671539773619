import type { FC } from 'react'
import { Button } from '@material-ui/core'
import PriorityIcon from './PriorityIcon'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  priority: {
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px',
    minWidth: 0,
    border: '0.1rem solid transparent',
  },
  defaultPriority: {
    border: `0.1rem solid ${theme.palette.grey[200]}`,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mediumPriority: {
    backgroundColor: '#F58220',
    color: 'white',
    '&:hover': {
      backgroundColor: '#F58220',
    },
  },
  highPriority: {
    backgroundColor: '#E12F2F',
    color: 'white',
    '&:hover': {
      backgroundColor: '#E12F2F',
    },
  },
}))

const PRIORITY_LEVELS = {
  0: 'defaultPriority',
  1: 'mediumPriority',
  2: 'highPriority',
}

interface PriorityToggleProps {
  priority: number
  onChange: (priority: number) => any
}

const PriorityToggle: FC<PriorityToggleProps> = props => {
  const { onChange, priority } = props

  const styles = useStyles(props)

  return (
    <Button
      size="small"
      className={`${styles.priority} ${styles[PRIORITY_LEVELS[priority]]}`}
      onClick={() => onChange((priority + 1) % 3)}
    >
      <PriorityIcon level={priority} variant="button" />
    </Button>
  )
}

export default PriorityToggle
