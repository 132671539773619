import type { FC } from 'react'
import * as MUI from '@material-ui/core'
import * as Lab from '@material-ui/lab'

export const EducationModalSkeleton: FC = () => {
  return (
    <MUI.Box m={2}>
      <MUI.Box mb={1}>
        <Lab.Skeleton animation="wave" variant="rect" height={30} />
      </MUI.Box>
      <MUI.Box mb={1}>
        <Lab.Skeleton animation="wave" variant="rect" height={20} />
      </MUI.Box>
      <Lab.Skeleton animation="wave" variant="rect" height={10} />
    </MUI.Box>
  )
}

export default EducationModalSkeleton
