import { User, Appointment } from '~/legacy/core'

import { apiClient } from '~/api/rest'
import { usePromise } from '~/utils/usePromise'

type VideoSession = {
  sdk: 'zoom'
  sessionName: string
  token: string
}

export const useInitAppointment = id =>
  usePromise(
    async () => {
      const [user, appointment, session] = await Promise.all([
        apiClient.rest.get<User>('/user/me/'),
        apiClient.rest.get<Appointment>(`/appointment/${id}`),
        apiClient.rest.get<VideoSession>(`/appointment/${id}/join/`),
      ])
      return { user, appointment, session }
    },
    { initialLoading: true, error: 'Failed to load appointment' }
  )

export const DYNAMIC_SCHEDULE_V2 = 'schedule.dynamicSchedulingV2'
