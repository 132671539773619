import { IconButton, makeStyles, Typography } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import classNames from 'classnames'
import Moment from 'moment'
import { useState } from 'react'
import type { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import SingleTaskEditor from '~/components/Tasks/SingleTaskEditor'
import CheckBox from '../CheckBox/CheckBox'
import PriorityIcon from '../Generic/PriorityIcon'
import TaskDueDate from './TaskDueDate'

const useStyles = makeStyles((theme: any) => ({
  containerLoading: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  editMessageTaskButton: {
    position: 'absolute',
    top: '0 !important',
    right: '0',
    left: 'initial !important',
  },
  tasksContainer: {
    position: 'relative',
    background: '#fff',
    margin: '1rem 0 0 0rem',
    padding: '1rem 0 1rem 6rem',
    border: `solid ${theme.palette.secondary.lighter} .1rem`,
    borderRadius: '.5rem',
    '& button': {
      position: 'absolute',
      top: '1.4rem',
      left: '2rem',
    },
  },
  editIcon: {
    color: theme.palette.secondary.light,
  },
  assigneeText: {
    fontSize: '1.25rem',
    marginBottom: '.3rem',
  },
  bold: {
    fontWeight: 800,
    color: theme.palette.common.black,
    paddingLeft: '.3rem',
  },
  taskSubText: {
    fontStyle: 'italic',
    color: theme.palette.grey[600],
    fontSize: '1.25rem',
  },
  messageTaskTitle: {
    paddingRight: '4rem',
  },
  taskTitleComplete: {
    textDecoration: 'line-through',
  },
}))

const InlineTask = props => {
  const { task } = props
  const classes = useStyles()
  const users = useSelector<any, any>(state => state.users.byId)
  const providers = useSelector(state => state.providers)
  const assigneeGroups = useSelector(state => state.assigneeGroups)
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const createdBy = users[task.createdBy] || {}
  const patient = users[task.patient] || {}

  let assignee =
    [patient, ...providers].find(user => user.assigneeGroup?.id === task.ownerGroup)
      ?.assigneeGroup || {}

  if (Object.keys(assignee).length == 0) {
    assignee = assigneeGroups.find(group => group.id === task.ownerGroup) || {}
  }

  let completedAtHtml: ReactNode = ''
  if (task.completedBy) {
    const completedBy = users[task.completedBy] || {}
    completedAtHtml = (
      <Typography className={classes.taskSubText}>
        — Completed by {completedBy.firstName} {completedBy.lastName} at
        {` ${Moment(task.completedOn).format('llll')}`}
      </Typography>
    )
  }

  const taskTitleClasses = classNames(classes.messageTaskTitle, {
    [classes.taskTitleComplete]: task.isComplete,
  })

  const onChecked = async value => {
    setLoading(true)
    await props.onChecked(value)
    setLoading(false)
  }

  return editing ? (
    <SingleTaskEditor
      type="careplan"
      patient={patient}
      task={task}
      handleClose={() => setEditing(false)}
    />
  ) : (
    <div className={classNames(classes.tasksContainer, { [classes.containerLoading]: loading })}>
      <CheckBox value={task.isComplete} onChecked={onChecked} />
      <Typography className={taskTitleClasses}>
        {task && task.priority !== 0 ? <PriorityIcon level={task.priority} variant="view" /> : null}
        {task.title}
      </Typography>
      <Typography className={classes.assigneeText}>
        <span className={classes.bold}>Assigned to </span> {assignee.name}{' '}
        <TaskDueDate dueDate={task.dueDate} isComplete={task.isComplete} />
      </Typography>
      <Typography className={classes.taskSubText}>
        — Created by {createdBy.firstName + ' ' + createdBy.lastName} on
        {` ${Moment(task.createdAt).format('llll')}`}
      </Typography>
      {completedAtHtml}
      {!task.isComplete ? (
        <IconButton
          className={classes.editMessageTaskButton}
          onClick={() => setEditing(true)}
          value={task.id}
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
      ) : null}
    </div>
  )
}

export default InlineTask
