import { formatDateWithDay } from '~/utils/date'
import AddAlertIcon from '@material-ui/icons/AddAlertOutlined'
import {
  Typography,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from '@material-ui/core'

export interface IPushNotificationEvent {
  type: string
  user: number
  metadata: {
    communicationType: string
    timestamp: number
    sentByProviderId: number | null
    messageText: string
    messageTitle: string
  }
}

const PushNotificationListItem = (props: {
  event: IPushNotificationEvent
  providerName: string | null
  timestampDate: any
}) => {
  const { palette } = useTheme()

  let abbreviatedMessageText = props.event.metadata.messageText.substring(0, 47)
  if (abbreviatedMessageText.length < props.event.metadata.messageText.length) {
    abbreviatedMessageText += '...'
  }

  const timestampString =
    'By ' + props.providerName + ' on ' + formatDateWithDay(props.timestampDate.toDate())

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          title="Push Notification"
          alt="Push Notification"
          style={{
            background: 'transparent',
            borderColor: palette.grey[500],
            borderWidth: '2px',
            borderStyle: 'solid',
          }}
        >
          <AddAlertIcon style={{ color: palette.grey[500] }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText>
        <Typography variant="body1" title={props.event.metadata.messageText}>
          <strong>{props.event.metadata.messageTitle}</strong>&nbsp;
          {abbreviatedMessageText}
        </Typography>
        <Typography variant="caption">{timestampString}</Typography>
      </ListItemText>
    </ListItem>
  )
}

export default PushNotificationListItem
