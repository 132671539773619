import { useSelector } from 'react-redux'
import { apiClient } from '~/api/rest'
import { useQuery } from 'react-query'
import { keyBy } from 'lodash/fp'
import { useMutation } from '~/components/Providers/ApiProvider'

// Corresponds to Waffle Switches on backend.
interface FeatureSwitch {
  id: number
  name: string
  note: string
  active: boolean
}

const featureSwitchesRequest = () => apiClient.rest.get<FeatureSwitch[]>('/feature/switches/')
const unauthFeatureSwitchesRequest = () =>
  apiClient.rest.get<FeatureSwitch[]>('/feature/member/switches/')

export const useFeatureSwitches = () => {
  const me = useSelector(state => state.me)
  const authenticated = me?.id != null
  const request = authenticated ? featureSwitchesRequest : unauthFeatureSwitchesRequest

  return useQuery(['featureSwitches'], request, {
    staleTime: Infinity,
    enabled: authenticated ? me?.id != null : undefined,
    select: data => {
      return {
        featureSwitches: data,
        featureSwitchesByName: keyBy('name', data),
      }
    },
    onSuccess: data => {
      console.log('useFeatureSwitches onSuccess', data)
    },
  })
}

export const useFeatureSwitch = name => {
  const { data } = useFeatureSwitches()
  return data?.featureSwitchesByName?.[name] || null
}

const updateFeatureSwitchRequest = (payload: {
  id: number
  active: boolean
}): Promise<FeatureSwitch> => {
  return apiClient.rest.patch(`/feature/switches/${payload.id}/`, { active: payload.active })
}

export const useUpdateFeatureSwitch = () => {
  return useMutation(
    (payload: { id: number; active: boolean }) => updateFeatureSwitchRequest(payload),
    {},
    { success: 'Updated Feature Switch', error: 'Failed to update Feature Switch' }
  )
}
