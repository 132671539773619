import { PriorAuthorizationItemType } from './types'
import { Box, Grid } from '@mui/material'
import { asDate, fullName } from './helpers'

interface LocalProps {
  priorAuth: PriorAuthorizationItemType
}

const PriorAuthorizationItem = (props: LocalProps) => {
  const { priorAuth } = props
  return (
    <Box>
      <Box>
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={6}>
            <div>
              <strong>Determination</strong>
            </div>
            {priorAuth.determination ? (
              <div>
                {priorAuth.determination} on {asDate(priorAuth.vendorUpdateDate)}
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <ul style={{ listStyleType: 'none', paddingLeft: 0, marginTop: 0, marginBottom: 0 }}>
              <strong>Service Categories</strong>
              {priorAuth.serviceCategories.map((serviceCategory, index) => (
                <li key={`${index} ${serviceCategory.label}`}>{serviceCategory.label}</li>
              ))}
            </ul>
          </Grid>

          <Grid item xs={6}>
            <div>
              <strong>Service Quantity</strong>
            </div>
            {!priorAuth.serviceQuantity ? (
              <div>(none)</div>
            ) : (
              <div>{`${priorAuth.serviceQuantity.value} ${priorAuth.serviceQuantity.unit}`}</div>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <div>
              <strong>Effective Date</strong>
            </div>
            <div>{asDate(priorAuth.effectiveFrom)}</div>
          </Grid>

          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <div>
              <strong>Expiration Date</strong>
            </div>
            <div>{asDate(priorAuth.effectiveThrough)}</div>
          </Grid>

          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <div>
              <strong>Originator System Id</strong>
            </div>
            <div>{priorAuth.originatorSystemId}</div>
          </Grid>

          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <div>
              <strong>Status</strong>
            </div>
            {!priorAuth.status ? <div>-</div> : <div>{`${priorAuth.status}`}</div>}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {priorAuth.providers.map((provider, index) => (
            <Grid item key={`${index} ${provider.npi}`} xs={6} style={{ marginTop: '10px' }}>
              <div>
                <strong>{index > 0 ? `Provider (${index + 1})` : 'Provider'}</strong>
              </div>
              <div>{fullName(provider)}</div>
              <div>{provider.addressLine1}</div>
              <div>{provider.addressLine2}</div>
              <div>
                {provider?.city}, {provider.state?.abbreviation} {provider?.zipCode}
              </div>
              {provider.npi ? <div>NPI: {provider.npi}</div> : null}
            </Grid>
          ))}
          <Grid item xs={6} style={{ marginTop: '10px' }}>
            <div>
              <strong>Updated Date</strong>
            </div>
            <div>{asDate(priorAuth?.updatedAt)}</div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            {priorAuth.procedureCodes.length === 0 ? null : (
              <>
                <strong>Procedure Codes</strong>
                <ul
                  style={{ listStyleType: 'none', paddingLeft: 0, marginTop: 0, marginBottom: 0 }}
                >
                  {priorAuth.procedureCodes.map((procedureCode, index) => (
                    <li key={index}>
                      {procedureCode.system}-{procedureCode.version}, {procedureCode.code},{' '}
                      {procedureCode.contentObject?.description}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={12}>
            {priorAuth.procedureCodes.length === 0 ? null : (
              <>
                <strong>Diagnosis Codes</strong>
                <ul
                  style={{ listStyleType: 'none', paddingLeft: 0, marginTop: 0, marginBottom: 0 }}
                >
                  {priorAuth.diagnosisCodes.map((diagnosisCode, index) => (
                    <li key={index}>
                      {diagnosisCode.system}-{diagnosisCode.version}, {diagnosisCode.code}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PriorAuthorizationItem
