import { Box, Typography } from '@mui/material'
import { Route } from 'react-router-dom'
import OnboardingStatusStepper from './OnboardingStatusStepper'
import usePatientDetail from '~/components/PatientDetail/utils/usePatientDetail'
import { useGetChurnedReasons } from '~/api/OnboardingService'
import { ONBOARDING_STATES } from '~/constants/onboardingStates'

const STATUS = [
  'Initialized',
  'Signed Up',
  'Enrolled',
  'Member',
  'Churned',
  'Discharged',
  'Deactivated',
]
const INDEX_TO_KEY = [
  ONBOARDING_STATES.initialized,
  ONBOARDING_STATES.signedup,
  ONBOARDING_STATES.enrolled,
  ONBOARDING_STATES.member,
  ONBOARDING_STATES.churned,
  ONBOARDING_STATES.discharged,
  ONBOARDING_STATES.deactivated,
]

const PatientDetailOnboarding = props => {
  const { hidden } = props
  const { onboardingState, updateOnboardingData } = usePatientDetail()
  const { data: churnedReasons } = useGetChurnedReasons()
  if (hidden) return null

  const selectedStep = INDEX_TO_KEY.indexOf(onboardingState.status)
  return (
    <Box>
      <Route exact path="/patients/:id/onboarding">
        <Box display="flex" p={2}>
          <Box flex={1} mt={2} ml={2}>
            <Typography variant="h6">Onboarding Progress</Typography>
          </Box>
        </Box>
        <Box>
          <OnboardingStatusStepper
            steps={STATUS}
            indexToKey={INDEX_TO_KEY}
            data={onboardingState}
            churnedReasons={churnedReasons}
            statusTimeStamps={[
              onboardingState.initializedAt,
              onboardingState.signedupAt,
              onboardingState.enrolledAt,
              onboardingState.memberAt,
              onboardingState.churnedAt,
              onboardingState.dischargedAt,
              onboardingState.deactivatedAt,
            ]}
            selectedStep={selectedStep}
            setCurrentOnboardingState={data => updateOnboardingData(data)}
          />
        </Box>
      </Route>
    </Box>
  )
}

export default PatientDetailOnboarding
