import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import FFModal from '~/components/FFModal/FFModal'
import CloseIcon from '@mui/icons-material/Close'
import { FC, useState } from 'react'
import * as AppointmentService from '~/api/AppointmentService'
import { queueNotification } from '~/redux/actions/notifications'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'

const DeleteAppointmentModal: FC<{
  appointment
  onClose
}> = props => {
  const [isDeleteReasonModal, setIsDeleteReasonModal] = useState<boolean>(false)
  const [reasonForDeletion, setReasonForDeletion] = useState<string>('')
  const [hasReasonError, setHasReasonError] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const dispatch = useDispatch()
  const client = useQueryClient()

  const handleReasonForDeletion = (event: any) => {
    if (event) {
      setReasonForDeletion(event.target?.value)
      if (event.target.value !== '') {
        setHasReasonError(false)
      } else {
        setHasReasonError(true)
      }
    }
  }

  const updateReason = e => {
    if (e.target.value == '') {
      setHasReasonError(true)
    }
  }

  const handleDeleteAppointment = async () => {
    if (reasonForDeletion == '') {
      setHasReasonError(true)
      return
    }

    setSaving(true)
    const payload = {
      id: props.appointment.id,
      reason: reasonForDeletion,
    }
    const apptCancelData = await AppointmentService.cancelAppointment(payload)
    if (apptCancelData.patient == null) {
      dispatch(
        queueNotification({
          message: `Appointment cancelled successfully`,
          variant: 'success',
        })
      )
    } else {
      dispatch(
        queueNotification({
          message: `Failed to cancel the appointment`,
          variant: 'error',
        })
      )
    }
    await client.invalidateQueries(['getPatientAppointmentsInfo', props.appointment.patient])
    await client.invalidateQueries(['useAppointmentLogs', props.appointment.patient])
    setSaving(false)
    props.onClose()
  }

  return (
    <FFModal open={true} style={{ width: '65%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Box m={2}>
        <Typography variant="h6">{'Cancel Appointment'}</Typography>
      </Box>
      <Box m={2}>
        {isDeleteReasonModal ? (
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={reasonForDeletion}
            onChange={e => handleReasonForDeletion(e)}
            multiline={true}
            fullWidth
            placeholder="Reason for appointment deletion"
            disabled={saving}
            error={hasReasonError}
            helperText={hasReasonError && 'Reason for appointment deletion required'}
            onBlur={updateReason}
          />
        ) : (
          <Typography variant="subtitle2" mt={3} mb={1} fontWeight={400}>
            {
              'Appointment will be deleted and a cancellation notification will be sent to the patient'
            }
          </Typography>
        )}
      </Box>
      <Box m={2}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button
            onClick={() => props.onClose()}
            variant="text"
            disabled={saving}
            size="small"
            sx={{
              borderRadius: 10,
              fontSize: '1.4rem',
              maxHeight: '75%',
              width: '20%',
              marginRight: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>{'Exit'}</Typography>
          </Button>
          {isDeleteReasonModal ? (
            <Button
              disabled={saving}
              onClick={handleDeleteAppointment}
              variant="contained"
              size="small"
              sx={{
                borderRadius: 10,
                fontSize: '1.4rem',
                maxHeight: '75%',
                width: '45%',
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
                {'Confirm Cancellation'}
              </Typography>
            </Button>
          ) : (
            <Button
              disabled={saving}
              onClick={() => {
                setIsDeleteReasonModal(true)
              }}
              variant="contained"
              size="small"
              sx={{
                borderRadius: 10,
                fontSize: '1.4rem',
                maxHeight: '75%',
                width: '45%',
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
                {'Cancel Appointment'}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      <Box m={2}>
        <IconButton
          onClick={() => props.onClose()}
          sx={{
            position: 'absolute',
            zIndex: 100,
            top: 2,
            right: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </FFModal>
  )
}

export default DeleteAppointmentModal
