import type { FC } from 'react'
import { Person } from '~/legacy/core'
import { Button, Box, Grid, Switch, Typography } from '@mui/material'
import { useFormik } from 'formik'

import EditField from '../../Generic/EditField'
import EditPersonFields from './EditPersonFields'
import { useFeatureAccess } from '~/utils/useFeatureAccess'

interface EditPersonFormProps {
  patient: Person
  loading: boolean
  isNewMember?: boolean
  handleSubmit: (values: Person) => void
}

type ValueSetter = (
  name: string,
  value: any
) => {
  name: string
  onChange: (e: React.ChangeEvent) => void
  disabled: boolean
  value: any
}

export const EditPersonForm: FC<EditPersonFormProps> = props => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.patient,
    onSubmit: props.handleSubmit,
  })
  const featureAccess = useFeatureAccess()?.tenant

  const {
    firstName = '',
    lastName = '',
    dob = '',
    preferredName = '',
    employer = null,
    gender = null,
    employeeIdentifier = null,
    sex = 'Unknown',
    pronouns = null,
    requestAuthForPhi = false,
    phoneNumber = '',
    email = '',
  } = formik.values

  // Shortcut for change handlers and values
  const setValues: ValueSetter = (name, value) => ({
    name,
    onChange: e => {
      e.persist()
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    },
    disabled: props.loading,
    value,
  })

  const submitButtonText = props.isNewMember ? 'Add New Person' : 'Save Person Details'
  return (
    <form onSubmit={formik.handleSubmit}>
      <EditPersonFields
        employeeIdentifier={employeeIdentifier}
        firstName={firstName}
        lastName={lastName}
        dob={dob}
        preferredName={preferredName}
        employer={employer}
        gender={gender}
        sex={sex}
        pronouns={pronouns}
        loading={props.loading}
        isUser={false}
        setValues={setValues}
        setFieldValue={formik.setFieldValue}
        setFieldTouched={formik.setFieldTouched}
        isNewMember={props.isNewMember}
      />
      <Grid container spacing={2}>
        {/* Disabling for now. will re-enable once email updation on person is fixed,
        as currently using email on person serializer in api is causing issues with
        existing patient profile update */}
        <EditField
          {...setValues('email', email)}
          label="Email"
          required={featureAccess?.personMatchingDuringSignup}
        />
        <EditField
          {...setValues('phoneNumber', phoneNumber)}
          label="Phone Number"
          required={featureAccess?.personMatchingDuringSignup}
        />
      </Grid>

      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3}>
        <Switch
          {...setValues('requestAuthForPhi', requestAuthForPhi)}
          edge="start"
          checked={!!formik.values.requestAuthForPhi}
          color="primary"
        />
        <Box ml={1}>
          <Typography variant="body2">Patient is VIP</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" my={3}>
        {formik.dirty && (
          <Box mr={3}>
            <Button onClick={() => formik.resetForm()}>Cancel Person Detail Changes</Button>
          </Box>
        )}

        <Button type="submit" variant="outlined" color="primary">
          {submitButtonText}
        </Button>
      </Box>
    </form>
  )
}

export default EditPersonForm
