import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, CircularProgress } from '@mui/material'
import { useRouteMatch } from 'react-router'

import { queueNotification } from '~/redux/actions/notifications'
import { leaveVideoAppointment } from '~/redux/actions/appointments'
import { SessionProvider } from './Zoom/SessionProvider'
import { useInitAppointment } from './utils'

const ZOOM_NOTIFICATION_KEY = 'ZOOM_NOTIFICATION_KEY'

const handleError = (dispatch, error) => {
  dispatch(
    queueNotification({
      message: error,
      variant: 'error',
      persist: false,
      key: ZOOM_NOTIFICATION_KEY,
    })
  )
  dispatch(leaveVideoAppointment())
}

export const AppointmentEntry: React.FC<{ modal: boolean; id?: string }> = props => {
  const match = useRouteMatch<{ id: string }>()
  const dispatch = useDispatch()
  const appointmentId =
    (useSelector(state => state.appointments.video.appointmentId) as number) || match.params.id
  const init = useInitAppointment(props.id || appointmentId)
  const session = init.result?.session

  // This component should only ever be mounted if there is an appointmentId
  if (init.loading)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Box>
    )

  if (init.error || !appointmentId || !session) {
    handleError(dispatch, 'Failed to load appointment')
  }

  return (
    <SessionProvider
      modal={props.modal}
      sessionName={session.sessionName}
      token={session.token}
      appointment={init.result.appointment}
    />
  )
}
