import { connect } from 'react-redux'

import Interface from '~/components/Interface/Interface'
import { search, searchClearResults } from '../actions/search'

const mapStateToProps = state => ({
  patients: state.patients.list,
  search: state.search,
  synchronizing: state.sync.synchronizing,
  me: state.me,
  providers: state.providers,
  preferences: state.preferences,
})

const mapDispatchToProps = dispatch => ({
  searchPatients: term => dispatch(search(term)),
  searchClearResults: () => dispatch(searchClearResults()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Interface)
