import { ICaseRelation } from '~/models/Case'
import * as ChatService from '~/api/ChatService'
import { Props as CaseEditorProps } from './CaseEditor'

/**
 * getMessageCaseRelation
 * @returns case relation
 */
export const getMessageCaseRelation = async (
  messageIdentifier: NonNullable<CaseEditorProps['messageIdentifier']>,
  patientId: number,
  setMessageId?: (patientId: number, messageUid: string, messageId: number) => void
): Promise<ICaseRelation> => {
  let contentType: ICaseRelation['contentType']
  let objectId: ICaseRelation['objectId']
  if (messageIdentifier.type === 'chatmessagev2-uid') {
    // HACK: Temporary fix to allow message cases on newly loaded messages.
    // In order to save a relation between a Message and a Case, the Message needs to have a DB ID
    // New real-time message, vs. messages loaded in the Thread object, do not have an ID
    // so conditionally fetch those message IDs now, using UID as a lookup
    const message = await ChatService.getMessage(messageIdentifier.id as string)
    contentType = 'chatmessagev2'
    objectId = message.id
    if (setMessageId) {
      setMessageId(patientId, message.uid, message.id)
    }
  } else {
    contentType = messageIdentifier.type
    objectId = messageIdentifier.id as number
  }
  return {
    contentType: contentType,
    objectId: objectId,
  }
}

export const linkToCaseForPerson = (personId: number, caseId: number) =>
  `/people/${personId}/todos?caseId=${caseId}`
