import type { FC } from 'react'
import {
  useQuery as useReactQuery,
  useInfiniteQuery as useReactInfiniteQuery,
  useMutation as useReactMutation,
  QueryClient,
  QueryClientProvider,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunction,
  QueryKey,
} from 'react-query'
import { ResolveNotification, useResolvedPromise } from '~/utils/useResolvedPromise'

const queryClient = new QueryClient()

/**
 * ApiProvider shares the application's singleton QueryClient with child components. Children may
 * access the client's capabilities with the useQuery and useMutation hooks.
 *
 * This provider is an alternative to using Redux as a shared cache for API data, without the
 * overhead of boilerplate actions and reducers for common API operations.
 */
export const ApiProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

/**
 * useQuery is a hook for fetching and caching data using the ApiProvider's QueryClient.
 *
 * This is a thin wrapper around https://react-query.tanstack.com/reference/useQuery that also
 * invokes `useResolvedPromise` for things like error handling.
 */
export const useQuery = <TQueryFnData,>(
  key,
  fn: () => Promise<TQueryFnData>,
  options?: UseQueryOptions<TQueryFnData>,
  notification?: ResolveNotification
) => {
  const result = useReactQuery<TQueryFnData>(key, fn, options)

  useResolvedPromise(
    {
      result: result.data,
      loading: result.isLoading,
      error: result.error,
    },
    notification
  )

  return result
}

/**
 * useInfiniteQuery is a hook for progressively fetching and caching data using the ApiProvider's QueryClient.
 *
 * This is a thin wrapper around https://react-query.tanstack.com/reference/useInfiniteQuery that also
 * invokes `useResolvedPromise` for things like error handling.
 */
export const useInfiniteQuery = <
  TQueryFnData,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  key: TQueryKey,
  fn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
  notification?: ResolveNotification
) => {
  const result = useReactInfiniteQuery(key, fn, options)

  useResolvedPromise(
    {
      result: result.data,
      loading: result.isLoading,
      error: result.error,
    },
    notification
  )

  return result
}

/**
 * useMutation is a hook for invoking mutative API operations using the ApiProvider's QueryClient.
 *
 * This is a thin wrapper around https://react-query.tanstack.com/reference/useMutation that also
 * invokes `useResolvedPromise` for things like error handling.
 */
export const useMutation = <TData, TError = unknown, TVariables = void>(
  fn: (variables: TVariables) => Promise<TData>,
  options?: UseMutationOptions<TData, TError, TVariables>,
  notification?: ResolveNotification
) => {
  const result = useReactMutation<TData, TError, TVariables>(fn, options)

  useResolvedPromise(
    {
      result: result.data,
      loading: result.isLoading,
      error: result.error,
    },
    notification
  )

  return result
}
