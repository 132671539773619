import type { FC } from 'react'

interface PriorityIconProps {
  level: number
  variant: 'view' | 'button' // view in Tasks tab vs button in creating/editing tasks
}

const MARGIN_BOTTOM = { view: '-0.5rem', button: '0rem' }

export const PriorityIcon: FC<PriorityIconProps> = (props: PriorityIconProps) => {
  const { level, variant } = props

  if (level === 0) {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
          width: 20,
          marginBottom: MARGIN_BOTTOM[variant],
          borderRadius: variant == 'view' ? '0.5rem' : '',
        }}
        xmlSpace="preserve"
      >
        <path fill={variant == 'view' ? '#C1C7CD' : 'transparent'} d="M0,0h24v24H0V0z" />
        <g>
          <path
            fill={variant == 'view' ? '#FFFFFF' : '#C1C7CD'}
            d={
              variant == 'view'
                ? 'M17,13H7v-2h10V13z'
                : 'M13.6,19.9h-3.1v-3.1h3.1V19.9z M13.6,13.6h-3.1V4.1h3.1V13.6z'
            }
          />
        </g>
      </svg>
    )
  } else if (level === 1) {
    const mediumColor = variant === 'view' ? '#F5751F' : 'white'
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
          width: 20,
          marginBottom: MARGIN_BOTTOM[variant],
          borderRadius: variant == 'view' ? '0.5rem' : '',
        }}
        xmlSpace="preserve"
      >
        <path fill={variant == 'view' ? mediumColor : 'none'} d="M0,0h24v24H0V0z" />
        <g>
          <path
            fill={variant == 'view' ? '#FFFFFF' : mediumColor}
            d="M13.6,19.9h-3.1v-3.1h3.1V19.9z M13.6,13.6h-3.1V4.1h3.1V13.6z"
          />
        </g>
      </svg>
    )
  } else if (level === 2) {
    const highColor = variant === 'view' ? '#D70000' : 'white'
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
          width: 20,
          marginBottom: MARGIN_BOTTOM[variant],
          borderRadius: variant == 'view' ? '0.5rem' : '',
        }}
        xmlSpace="preserve"
      >
        <path fill={variant == 'view' ? highColor : 'none'} d="M0,0h24v24H0V0z" />
        <g>
          <path
            fill={variant == 'view' ? '#FFFFFF' : highColor}
            d="M10.4,19.9H7.3v-3.1h3.1V19.9z M10.4,13.6H7.3V4.1h3.1V13.6z"
          />
          <path
            fill={variant == 'view' ? '#FFFFFF' : highColor}
            d="M16.7,19.9h-3.1v-3.1h3.1V19.9z M16.7,13.6h-3.1V4.1h3.1V13.6z"
          />
        </g>
      </svg>
    )
  }
  return null
}

export default PriorityIcon
