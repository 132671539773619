import { PatientUser, InsuranceAddress } from '~/legacy/core'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy, merge, uniqBy } from 'lodash/fp'

import * as thunks from './actions'

export interface PatientsState {
  list: PatientUser[]
  byId: { [key: number]: PatientUser }
}

export interface PatientInsurancePayload {
  insuranceInfo: any // this could be a partial payload of InsuranceInfo
  patientId: number
}

export interface PatientInsuranceAddressPayload {
  patientId: number
  insuranceInfo: { id: number; addresses: InsuranceAddress[] }
}

const INITIAL_STATE: PatientsState = {
  list: [],
  byId: {},
}

const patientsSliceBase = createSlice({
  name: 'patients',
  initialState: INITIAL_STATE,
  reducers: {
    getPatientsSuccess: (state, { payload: patients }: PayloadAction<PatientUser[]>) => {
      state.byId = merge(keyBy('id', patients), state.byId)
      state.list = Object.values(state.byId)
    },
    getPatientsError: state => {},
    getPatientSuccess: (state, { payload: patient }: PayloadAction<PatientUser>) => {
      // Override the current entry in patient list, or add if missing.
      state.list = uniqBy('id', [patient].concat(state.list))
      state.byId[patient.id] = patient
    },
    getPatientError: state => {},
    updatePatientInsuranceSuccess: (state, { payload }: PayloadAction<PatientInsurancePayload>) => {
      const patientIndex = state.list.findIndex(p => p.id === payload.patientId)
      state.list[patientIndex].person.insuranceInfo = payload.insuranceInfo
      state.byId[payload.patientId].person.insuranceInfo = payload.insuranceInfo
    },
  },
})

export const patientsSlice = Object.assign(patientsSliceBase, { thunks })
export default patientsSlice
