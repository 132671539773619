import Moment from 'moment'
import type { AssigneeGroup, User } from '~/legacy/core'
import { ROLES_LIMITED_BY_PRACTICING_STATES } from '~/constants/careTeamRoles'

const ADMIN = 'Admin'

/**
 * Return a user from list of users, either patients or providers
 * @param {array} users | patient or provider list from store
 * @param {number} id | user's ID
 */
export const getUserById = <T extends NamedUser>(users: T[], id: number | string) =>
  users.find(patient => patient.id === (typeof id === 'number' ? id : parseInt(id)))

type NamedUser = Pick<User, 'id' | 'firstName' | 'lastName'>
type NamedAssigneeGroup = Pick<AssigneeGroup, 'id' | 'name'>

export const getUserName = (user?: NamedUser) => {
  if (!user) {
    return 'Unknown User'
  }

  const { firstName, lastName } = user
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  if (!firstName && !lastName) {
    return ''
  }
  return firstName || lastName
}

export const getUserInitials = (user: NamedUser) => {
  const { firstName = '', lastName = '' } = user
  return firstName.substr(0, 1) + lastName.substr(0, 1)
}

export const getUserGroupInitials = (group: NamedAssigneeGroup) => {
  const { name = '' } = group
  const splitGroupName = name.split(' ')
  if (!splitGroupName[0]) {
    splitGroupName[0] = ''
  }
  if (!splitGroupName[1]) {
    splitGroupName[1] = ''
  }
  return splitGroupName[0].substr(0, 1) + splitGroupName[1].substr(0, 1)
}

export const getUserGroupMembers = (assigneeGroup, providers) => {
  if (assigneeGroup?.assigneeGroupUsers?.length > 1) {
    return assigneeGroup.assigneeGroupUsers
      .map(element => providers.find(pr => pr.id == element)?.assigneeGroup?.name)
      .filter(element => element !== undefined)
  }
  return assigneeGroup.name
}

export const getAssigneeGroupById = (providers, id) => {
  const assigneeGroup = providers.find(provider => provider.assigneeGroup?.id == id)?.assigneeGroup
  return assigneeGroup ? assigneeGroup : undefined
}

export const getAssigneeGroupName = (group: NamedAssigneeGroup) => {
  if (!group) {
    return 'Unknown User'
  }

  const { name } = group
  if (name) {
    return `${name}`
  }
  return ''
}

export const isAdmin = (providers, id) => {
  const foundProvider = providers.find(provider => provider.id === parseInt(id))
  return !!(foundProvider && foundProvider.groups.find(group => group.name === ADMIN))
}

export const providerCanProvideClinicalCareToPatient = (provider, patient) => {
  // If provider does not have to have practicing states (ex, HG or OG)
  // then no restrictions should apply
  if (!hasLicensedRole(provider)) {
    return true
  }
  const providerStates = provider.providerFields?.practicingStates
  const addresses = patient.person?.insuranceInfo?.addresses || []
  // Default to allowing care if we're missing info
  if (addresses.length == 0) {
    return true
  }
  const patientState = addresses[0].state
  if (providerStates && patientState) {
    return !!providerStates.find(state => state.abbreviation === patientState.toUpperCase())
  }
  return true
}

export const hasLicensedRole = (provider: { groups: Array<{ name: string }> }): boolean =>
  !!(provider?.groups || []).find(g => ROLES_LIMITED_BY_PRACTICING_STATES.has(g.name))

export const formatPhoneNumber = phoneNumber => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  const number = match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber
  return number
}

// Shared function for returning proper patient age
export const getUserAge = dob => Moment().diff(Moment(dob, 'YYYY-MM-DD'), 'years')
export const formatUserDateOfBirth = (dob: string | null) =>
  dob ? Moment(dob).format('MM/DD/YYYY') : ''

export const titleCase = str =>
  str
    .replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
    .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`)

const snakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const snakeCaseObjectKeys = obj => {
  const newObj = {}
  for (const key of Object.keys(obj)) {
    newObj[snakeCase(key)] = obj[key]
  }
  return newObj
}

// We only have 2 environments for the members web app
export const membersWebAppUrl =
  process.env.NODE_ENV == 'production'
    ? 'https://members.firefly.health'
    : 'https://dev.members.firefly.health'
