import type { FC } from 'react'
import { PatientUser } from '~/legacy/core'
import { Email, Phone, Home } from '@mui/icons-material'
import { Box, Divider, Fade, Typography } from '@mui/material'
import { format } from 'date-fns'

import PatientDetailPopoverLine from './PatientDetailPopoverLine'
import { formatPhoneNumber } from '~/utils'
import usePatientDetail from './utils/usePatientDetail'

interface PatientContactInfoProps {
  patient: PatientUser
}

export const PatientContactInfo: FC<PatientContactInfoProps> = props => {
  const { onboardingState } = usePatientDetail()
  // Default to null in case the user hasn't chosen a signup reason
  const reasons = onboardingState?.signupReasonsInfo ?? []

  const address = props.patient.person.insuranceInfo?.addresses[0]
  let formattedAddress: string | null = null
  if (address && address.streetAddress !== null) {
    formattedAddress = [
      address.streetAddress,
      address.streetAddress2,
      `${address.city}, ${address.state} ${address.zipcode}`,
    ]
      .filter(v => !!v)
      .join('\n')
  }
  return (
    <Box maxHeight={800} overflow="auto" data-testid="patientDetailPopover">
      <Box py={2}>
        <Box mx={2}>
          <Typography>Contact Info</Typography>
        </Box>
        <Box mx={1} mt={1}>
          <PatientDetailPopoverLine value={props.patient.email || ''} icon={Email} />
          <PatientDetailPopoverLine
            value={formatPhoneNumber(props.patient.phoneNumber)}
            icon={Phone}
          />
          {formattedAddress && <PatientDetailPopoverLine value={formattedAddress} icon={Home} />}
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <Fade in={!!onboardingState}>
          <Typography variant="caption">
            <strong>Signed Up: </strong>
            {format(new Date(onboardingState?.signedupAt), 'MMM D, YYYY')} <br />
            {reasons.length ? (
              <>
                <strong>Signed up for:</strong>
                <br />
                {reasons.map(reason => (
                  <Typography variant="caption" key={reason.id}>
                    <strong>· </strong>
                    {reason.label}
                  </Typography>
                ))}
              </>
            ) : null}
          </Typography>
        </Fade>
      </Box>
      {props?.patientReferralProgram && (
        <Box p={2} pt={0}>
          <Typography variant="caption">
            <strong>Patient Referral Program: </strong>
            {props?.patientReferralProgram}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
