import { useState } from 'react'
import {
  makeStyles,
  Paper,
  Button,
  Box,
  Theme,
  TextField,
  Popper,
  ClickAwayListener,
} from '@material-ui/core'
import { GridCellValue } from '@mui/x-data-grid-pro'
import { WorkUnitGridRow } from './WorkUnitTable'

interface UserDataEditPopperProps {
  anchorEl: any
  onClose: () => void
  open: boolean
  field: string
  validValues?: string[] | undefined
  handleOnEdit: (WorklistWorkUnit, string, any) => void
  currentValue: GridCellValue
  worklistRowData: WorkUnitGridRow
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    height: '100%',
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    '& button:not(:last-child)': {
      marginRight: '1rem',
    },
  },
  select: {
    width: '100%',
  },
}))

const UserDataEditPopper = (props: UserDataEditPopperProps) => {
  const workUnit = props.worklistRowData
  const [val, setVal] = useState<GridCellValue>(props.currentValue)
  const classes = useStyles()

  const onSubmit = async () => {
    props.handleOnEdit(workUnit, props.field, val)
    props.onClose()
  }

  const handleClose = () => props.onClose()

  let field: JSX.Element

  field = <TextField value={val} onChange={e => setVal(e.target.value)} placeholder="New value" />
  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      style={{ zIndex: 100 }}
      placement="bottom-end"
      modifiers={{
        preventOverflow: {
          enabled: true,
        },
        flip: {
          enabled: false,
        },
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper style={{ padding: '1rem' }}>
          <Box>{field}</Box>
          <Box className={classes.actions}>
            <Button onClick={e => onSubmit()} variant="contained" color="primary">
              Submit
            </Button>
            <Button onClick={e => handleClose()} variant="contained">
              Cancel
            </Button>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}

export default UserDataEditPopper
