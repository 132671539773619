import { FC, useState } from 'react'
import { Box, Typography, colors } from '@mui/material'
import PersonAdd from '@mui/icons-material/PersonAdd'

import { useSelector } from 'react-redux'
import { IChatMessage } from './Chat'
import MessageActions from './MessageActions'
import { chatMessageText } from './utils'

interface ChatMessageEventProps {
  message: IChatMessage
  patient: any
}

export const ChatMessageEvent: FC<ChatMessageEventProps> = ({ message, patient }) => {
  const clinicians = useSelector(state => state.providers)
  const sender = clinicians.find(c => c.id === message.sender)
  // Controls for the "More" menu in <MessageActions />
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const menuIsOpen = Boolean(menuAnchorEl)

  if (!sender) return null

  const text = chatMessageText(message, sender)

  return (
    <Box mb={3}>
      <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: colors.blueGrey[50],
              color: colors.blueGrey[800],
              py: 2,
              px: 3,
              borderRadius: 1,
              '&:hover .message-actions-hover-control': {
                opacity: '1 !important',
              },
            }}
          >
            <PersonAdd color="inherit" fontSize="small" />
            <Box ml={1}>
              <Typography variant="caption">{text}</Typography>
            </Box>
            <Box
              sx={{
                opacity: menuIsOpen ? 1 : 0,
                position: 'absolute',
                right: 0,
                top: -10,
                border: '1px solid',
                padding: 0.5,
                borderColor: 'secondary.100',
                backgroundColor: 'white',
                borderRadius: '10px',
                zIndex: 99,
              }}
              className="message-actions-hover-control"
            >
              {/* Allows deleting the event, but won't show the usual casing controls */}
              <MessageActions
                isLastMessage={true}
                message={message}
                patient={patient}
                menuIsOpen={menuIsOpen}
                menuAnchorEl={menuAnchorEl}
                setMenuAnchorEl={setMenuAnchorEl}
                startMultiSelect={() => null}
                setIsSelectingMultiple={() => null}
                setIsLoading={() => null}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatMessageEvent
