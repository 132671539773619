import { GlobalHotKeys } from 'react-hotkeys'

/*
THIS FILE IS WHERE ALL GLOBAL HOTKEYS ARE TO BE DEFINED.

Think of it sort of like redux, where you come up with a canonical name
for the action you're describing, and then associate that name to a function
(see FFInput for an example)
*/

const GLOBAL_HOTKEYS = {
  FOCUS_SEARCH: ['ctrl+/', 'command+/'],
}

const FFHotkeys = props => <GlobalHotKeys {...props} keyMap={GLOBAL_HOTKEYS} />

export default FFHotkeys
