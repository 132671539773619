import { EXIT_APPOINTMENT, SET_ACTIVE_VIDEO_APPOINTMENT } from './actionTypes'

export const setActiveAppointment = appointmentId => ({
  type: SET_ACTIVE_VIDEO_APPOINTMENT,
  payload: {
    appointmentId,
  },
})

export const leaveVideoAppointment = () => ({
  type: EXIT_APPOINTMENT,
})
