import appointments from './appointments'
import me from './me'
import notifications from './notifications'
import preferences from './preferences'
import providers from './providers'
import search from './search'
import sync from './sync'
import tasks from './tasks'
import { patientsSlice } from '../slices/patients'
import usersSlice from '../slices/users'
import macrosSlice from '../slices/macros'
import chatSlice from '../slices/chat'
import settingsSlice from '../slices/settings'
import tasksSlice from '../slices/tasks'
import { educationSlice } from '../slices/education'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import history from '~/utils/history'
import assigneeGroups from './assigneeGroups'

export const rootReducer = combineReducers({
  appointments,
  education: educationSlice.reducer,
  me,
  notifications,
  preferences,
  providers,
  search,
  settings: settingsSlice.reducer,
  sync,
  tasks,
  chat: chatSlice.reducer,
  patients: patientsSlice.reducer,
  users: usersSlice.reducer,
  macros: macrosSlice.reducer,
  router: connectRouter(history),
  // TODO(martin): This is a temporary state slice while we refactor
  // existing task Redux state to use slices.
  tasks2: tasksSlice.reducer,
  assigneeGroups,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
