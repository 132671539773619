import { useEffect } from 'react'

import { useMutation } from './useMutation'
import { PromiseStateConfig } from './usePromiseState'

export function usePromise<P, E = any>(
  getPromise: () => Promise<P>,
  config: PromiseStateConfig = { initialLoading: true }
) {
  const { error, handler, loading, result } = useMutation<[], P, E>(getPromise, config)
  useEffect(() => {
    handler()
  }, [])
  return { error, loading, result }
}
